import { Component, OnInit, OnDestroy } from "@angular/core";
// import { NbThemeService } from "@nebular/theme";
import { DashboardService } from "src/app/service/dashboard.service";
import { GoogleChartInterface } from "ng2-google-charts";

interface CardSettings {
  title: string;
  counts: number;
  iconClass: string;
  type: string;
}

@Component({
  selector: "ngx-state-map-chart",
  templateUrl: "./state-map-chart.component.html",
  styleUrls: ["./state-map-chart.component.scss"],
})
export class StateMapChartComponent implements OnInit {
  public ownFormData!: FormData;
  public percentageOrAbsoluteCounts: any[] = [
    {
      id: 2,
      value: "Absolute Counts",
    },
    {
      id: 1,
      value: "Percentage",
    },
  ];
  public stateMapChartData: any = [];
  public stateMapChartOptions: any;
  public totalNativeMapStateChartCounts: any = 0;
  public stateMapWisePercentOrCount: any = 2;
  public monthOrYear: any = "year";
  public stateMapChartLoader: boolean = true;
  public geoChart: GoogleChartInterface = {
    chartType: 'GeoChart',
    dataTable: [],
    //firstRowIsData: true,
    formatters: [
      {
        columns: [1],
        type: "NumberFormat",
        options: {
          pattern: '',
          suffix: ''
        },
      },
    ]
  };

  constructor(private dashboardService: DashboardService) { }

  /**
   *
   */
  ngOnInit() {
    // Show all charts with no filter
    this.getDashboardChartData("state_map", "");
  }

  /**
   *
   * @param type
   * @param formData
   * @param isPercentSelected
   * @param monthOrYearFilter
   * @param isUpdate
   */
  getDashboardChartData(type: string, formData: any, isPercentSelected: any = 2, monthOrYearFilter = "year", isUpdate = 0) {
    this.stateMapChartLoader = true;
    this.dashboardService
      .getDashboardChartData(type, formData, monthOrYearFilter, isPercentSelected)
      .subscribe((result: any) => {
        if (result.data.length > 1) {
          this.nativeStateMapChart(result.data, isPercentSelected, isUpdate);
        } else {
          this.totalNativeMapStateChartCounts = 
            this.stateMapChartLoader = false;
        }
      });
  }

  /**
   *
   * @param stateChartData
   * @param isPercentSelected
   * @param isUpdate
   */
  nativeStateMapChart(stateChartData: any, isPercentSelected: any, isUpdate: any) {
    let showColorAxis: any;
    this.stateMapChartLoader = false;
    if (stateChartData !== "") {
      this.totalNativeMapStateChartCounts = 1;
    }
    showColorAxis = { colors: ["#ffc107", "#dc3545"] };
    if (isUpdate) {
      this.geoChart.dataTable = stateChartData;
      this.geoChart.formatters?.forEach((element: any) => {
        element.options.columns = [1];
        element.options["pattern"] = isPercentSelected == 1 ? '#0.00' : '#0';
        element.options["suffix"] = isPercentSelected == 1 ? "%" : "";
      });
    } else {
      this.geoChart = {
        chartType: "GeoChart",
        dataTable: stateChartData,
        // firstRowIsData: true,
        formatters: [
          {
            columns: [1],
            type: "NumberFormat",
            options: {
              pattern: isPercentSelected == 1 ? '#0.00' : '#0',
            },
          },
        ],
        options: {
          height: 460,
          region: "IN",
          colorAxis: showColorAxis,
          backgroundColor: "#fff",
          datalessRegionColor: "#fff",
          defaultColor: "#6c757d",
          chartArea: {
            width: "100%",
            height: "100%",
            left: "0",
            right: "0",
            top: "0",
          },
          domain: "IN",
          displayMode: "regions",
          resolution: "provinces",
          keepAspectRatio: true,
          tooltip: { isHtml: true },
        },
      };
    }

    if (this.geoChart && this.geoChart.component) {
      const ccComponent = this.geoChart.component;
      ccComponent.draw();
    }
  }

  /**
   *
   * @param chartType
   * @param isPercentSelected
   */
  showRecordsInPercentOrAbsoluteCount(chartType: string, isPercentSelected: any = 2) {
    this.getDashboardChartData(chartType, this.ownFormData, isPercentSelected.id, this.monthOrYear, 1);
  }

  /**
   *
   * @param isRemoveItemMethod
   */
  filterDashboardData(parentFormData: FormData) {
    this.ownFormData = parentFormData;

    this.getDashboardChartData(
      "state_map",
      parentFormData,
      this.stateMapWisePercentOrCount,
      "year",
      1
    );
  }
}
