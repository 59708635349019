import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabDataService  extends CrudService {

  constructor(public override http: HttpClient) {
    super('lab-data', http);
  }

  /**
   * @author Jaydeep Patel
   * This function is used to Make QC
   * @param id
   */
   importHtrData(htrData: any) {
    return this.http.post(this.baseUrl + '/import', htrData);
  }
}
