<!-- based on selection desiease show hide here start -->
<div class="col-lg-12" [formGroup]="diseaseDetailForm">
    <div class="card-wrape" formArrayName="DIABETES">
        <div class="card-head">
            <h4 class="left-text">Diabetes Mellitus (DM) Details</h4>
        </div>
        <div class="card-body" [ngClass]="isViewDisease ? 'view-mode' : 'edit-mode'" id="diseaseForm">
            <!-- Diabeties Melitas que section start -->
            <div class="dm-detail-que">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.errors && (diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.pristine || diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.invalid)}">
                            <mat-label><i>*</i>Were You Diagnosed with Diabetes Mellitus (DM) by a
                                Physician?:</mat-label>
                            <mat-radio-group aria-label="Select an option"
                                formControlName="is_dm_diagnosed_by_clinician">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.errors || (diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.pristine && diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.invalid)">
                                This field is required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('is_dm_diagnosed_by_clinician')?.value == 'yes'"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.errors && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.pristine || diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.invalid)}">
                            <mat-label><i>*</i>Photo of Doctor's Prescription/Note Confirming the
                                Diagnosis:</mat-label>
                            <div class="icon-wrape-preview thumb-upload"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.value == '') || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.value == null)">
                                <span class="icon img"></span>
                                <div class="upload-wrape">
                                    <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                        (change)="onSelectFile($event,'photo_dm_prescription_note')">
                                    <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                        mat-raised-button class="btn" color="primary"> Upload</button>
                                </div>
                                <span class="type">Upload</span>
                            </div>
                            <div class="img-wrape"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.value != '') && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.value != null)">
                                <span mat-button appNoRightClick>
                                    <img src="{{diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note_url')?.value}}"
                                        alt=""
                                        (click)="showImage(diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note_url')?.value)">
                                </span>
                                <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.value,'photo_dm_prescription_note')"
                                    class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.errors || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.pristine && diseaseDetailForm.controls['DIABETES'].get('photo_dm_prescription_note')?.invalid)">
                                This field is required
                            </mat-error>
                            <i class="ruler"></i>
                        </div>

                        <h6>Fasting Glucose <span>OR</span> HbA1c (Glycosylated Hemoglobin) <span>OR</span>
                            Random Blood Glucose Report</h6>
                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.invalid)}">
                            <mat-label><i>*</i>Fasting Glucose Report with Levels Greater than 126 mg/dL
                                Available?:</mat-label>
                            <mat-radio-group aria-label="Select an option" formControlName="have_dm_glucose_report" (change)="checkValidation()" >
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <mat-radio-button value="not available">Not Available</mat-radio-button>
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.errors || (diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.pristine && diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.invalid)">
                                This field is required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_glucose_report')?.value == 'yes'"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.invalid)}">
                            <mat-label><i>*</i>Photo of Fasting Glucose Report:</mat-label>
                            <div class="icon-wrape-preview thumb-upload"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.value == '') || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.value == null)">
                                <span class="icon img"></span>
                                <div class="upload-wrape">
                                    <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                        (change)="onSelectFile($event,'photo_dm_glucose_report')">
                                    <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                        mat-raised-button class="btn" color="primary"> Upload</button>
                                </div>
                                <span class="type">Upload</span>
                            </div>
                            <div class="img-wrape"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.value != '') && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.value != null)">
                                <span mat-button appNoRightClick>
                                    <img src="{{diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report_url')?.value}}"
                                        alt=""
                                        (click)="showImage(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report_url')?.value)">
                                </span>
                                <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.value,'photo_dm_glucose_report')"
                                    class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.errors || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.pristine && diseaseDetailForm.controls['DIABETES'].get('photo_dm_glucose_report')?.invalid)">
                                This field is required
                            </mat-error>
                            <i class="ruler"></i>
                        </div>

                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.invalid)}">
                            <mat-label><i>*</i>HbA1c Report with Levels Greater than 6.5%
                                Available?:</mat-label>
                            <mat-radio-group aria-label="Select an option" formControlName="have_dm_hba1c_report" (change)="checkValidation()">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <mat-radio-button value="not available">Not Available</mat-radio-button>
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.errors || (diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.pristine && diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.invalid)">
                                This field is required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_hba1c_report')?.value == 'yes'"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.invalid)}">
                            <mat-label><i>*</i>Photo of HbA1c Report:</mat-label>
                            <div class="icon-wrape-preview thumb-upload"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.value == '') || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.value == null)">
                                <span class="icon img"></span>
                                <div class="upload-wrape">
                                    <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                        (change)="onSelectFile($event,'photo_dm_hba1c_report')">
                                    <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                        mat-raised-button class="btn" color="primary"> Upload</button>
                                </div>
                                <span class="type">Upload</span>
                            </div>
                            <div class="img-wrape"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.value != '') && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.value != null)">
                                <span mat-button appNoRightClick>
                                    <img src="{{diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report_url')?.value}}"
                                        alt=""
                                        (click)="showImage(diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report_url')?.value)">
                                </span>
                                <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.value,'photo_dm_hba1c_report')"
                                    class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.errors || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.pristine && diseaseDetailForm.controls['DIABETES'].get('photo_dm_hba1c_report')?.invalid)">
                                This field is required
                            </mat-error>
                            <i class="ruler"></i>
                        </div>

                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.invalid)}">
                            <mat-label><i>*</i>Random Blood Glucose Report with Levels Greater than 200
                                mg/dL Available?:</mat-label>
                            <mat-radio-group aria-label="Select an option"
                                formControlName="have_dm_random_blood_glucose_report" (change)="checkValidation()">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <mat-radio-button value="not available">Not Available</mat-radio-button>
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.errors || (diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.pristine && diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.invalid)">
                                This field is required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_random_blood_glucose_report')?.value == 'yes'"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.invalid)}">
                            <mat-label><i>*</i>Photo of Random Blood Glucose Report:</mat-label>
                            <div class="icon-wrape-preview thumb-upload"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.value == '') || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.value == null)">
                                <span class="icon img"></span>
                                <div class="upload-wrape">
                                    <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                        (change)="onSelectFile($event,'photo_dm_random_blood_glucose_report')">
                                    <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                        mat-raised-button class="btn" color="primary"> Upload</button>
                                </div>
                                <span class="type">Upload</span>
                            </div>
                            <div class="img-wrape"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.value != '') && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.value != null)">
                                <span mat-button appNoRightClick>
                                    <img src="{{diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report_url')?.value}}"
                                        alt=""
                                        (click)="showImage(diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report_url')?.value)">
                                </span>
                                <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.value,'photo_dm_random_blood_glucose_report')"
                                    class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.errors || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.pristine && diseaseDetailForm.controls['DIABETES'].get('photo_dm_random_blood_glucose_report')?.invalid)">
                                This field is required
                            </mat-error>
                            <i class="ruler"></i>
                        </div>

                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.invalid)}">
                            <mat-label><i>*</i>Glutamic Acid Decarboxylase (GAD) Antibody Test Report
                                Available?:</mat-label>
                            <mat-radio-group aria-label="Select an option"
                                formControlName="have_dm_glumatic_acid_report">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <mat-radio-button value="not available">Not Available</mat-radio-button>
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.invalid)">
                                This field required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_glumatic_acid_report')?.value == 'yes'"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.invalid)}">
                            <mat-label><i>*</i>Photo of Glutamic Acid Decarboxylase (GAD) Antibody Test
                                Report:</mat-label>
                            <div class="icon-wrape-preview thumb-upload"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.value == '') || (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.value == null)">
                                <span class="icon img"></span>
                                <div class="upload-wrape">
                                    <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                        (change)="onSelectFile($event,'photo_dm_glumatic_acid_report')">
                                    <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                        mat-raised-button class="btn" color="primary"> Upload</button>
                                </div>
                                <span class="type">Upload</span>
                            </div>
                            <div class="img-wrape"
                                *ngIf="(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.value != '') && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.value != null)">
                                <span mat-button appNoRightClick>
                                    <img src="{{diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report_url')?.value}}"
                                        alt=""
                                        (click)="showImage(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report_url')?.value)">
                                </span>
                                <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.value,'photo_dm_glumatic_acid_report')"
                                    class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.errors && (diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.pristine || diseaseDetailForm.controls['DIABETES'].get('photo_dm_glumatic_acid_report')?.invalid)">
                                This field required
                            </mat-error>
                            <i class="ruler"></i>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <h6>Do you have any of the following diseases along with diabetes?</h6>
                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_dm_along_with_other_disease')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_along_with_other_disease')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_along_with_other_disease')?.invalid)}">
                            <mat-label><i>*</i>Diseases along with Diabetes?</mat-label>
                            <div class="control-pad">
                                <ng-select [multiple]="true" bindValue="option" bindLabel="option"
                                    formControlName="have_dm_along_with_other_disease"
                                    (change)="changeDiseasesWithDiabetes($event)"
                                    placeholder="Enter diseases along with diabetes">
                                    <ng-option *ngFor="let option of diseasesAlongWithDiabetes" [value]="option">{{
                                        option | titlecase
                                        }}</ng-option>
                                </ng-select>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_dm_along_with_other_disease')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_dm_along_with_other_disease')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_dm_along_with_other_disease')?.invalid)">
                                This field required
                            </mat-error>
                        </div>

                        <h6>Do You Have Any of These Symptoms Appearing along with Diabetes Mellitus?</h6>
                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.invalid)}">
                            <mat-label><i>*</i>Chronic Foot Ulcers:</mat-label>
                            <mat-radio-group aria-label="Select an option" formControlName="have_chronic_foot_ulcers">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.invalid)">
                                This field required
                            </mat-error>
                        </div>
                        
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.value == 'yes'">
                            <mat-label>For How Long?:</mat-label>
                            <div class="three-controls">
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select year"
                                            formControlName="how_long_have_chronic_foot_ulcers_year">
                                            <mat-option *ngFor="let year of years"
                                                value="{{year}}">{{year}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select month"
                                            formControlName="how_long_have_chronic_foot_ulcers_month">
                                            <mat-option *ngFor="let month of months"
                                                value="{{month}}">{{month}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select day"
                                            formControlName="how_long_have_chronic_foot_ulcers_day">
                                            <mat-option *ngFor="let day of days" value="{{day}}">{{day}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                            </div>
                            <i class="ruler"></i>
                        </div>

                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.invalid)}">
                            <mat-label><i>*</i>Chronic Non-healing Skin Wounds:</mat-label>
                            <mat-radio-group aria-label="Select an option"
                                formControlName="have_chronic_non_healing_skin_wound">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.invalid)">
                                This field required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.value == 'yes'">
                            <mat-label>For How Long?:</mat-label>
                            <div class="three-controls">
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select year"
                                            formControlName="how_long_have_chronic_non_healing_skin_wound_year">
                                            <mat-option *ngFor="let year of years"
                                                value="{{year}}">{{year}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select month"
                                            formControlName="how_long_have_chronic_non_healing_skin_wound_month">
                                            <mat-option *ngFor="let month of months"
                                                value="{{month}}">{{month}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select day"
                                            formControlName="how_long_have_chronic_non_healing_skin_wound_day">
                                            <mat-option *ngFor="let day of days" value="{{day}}">{{day}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                            </div>
                            <i class="ruler"></i>
                        </div>

                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.invalid)}">
                            <mat-label><i>*</i>Numbness, Tingling or Burning Sensation in Feet, Toes
                                or Hands?:</mat-label>
                            <mat-radio-group aria-label="Select an option"
                                formControlName="have_numbness_tingling_burning_sensation">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.errors && (diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.pristine || diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.invalid)">
                                This field required
                            </mat-error>
                        </div>
                        <div class="input-wrape tree one"
                            *ngIf="diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.value == 'yes'">
                            <mat-label>For How Long?:</mat-label>
                            <div class="three-controls">
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select year"
                                            formControlName="how_long_numbness_tingling_burning_sensation_year">
                                            <mat-option *ngFor="let year of years"
                                                value="{{year}}">{{year}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select month"
                                            formControlName="how_long_numbness_tingling_burning_sensation_month">
                                            <mat-option *ngFor="let month of months"
                                                value="{{month}}">{{month}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="input-wrape threes">
                                    <mat-form-field appearance="outline">
                                        <mat-select placeholder="Select day"
                                            formControlName="how_long_numbness_tingling_burning_sensation_day">
                                            <mat-option *ngFor="let day of days" value="{{day}}">{{day}}</mat-option>
                                        </mat-select>
                                        <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                    </mat-form-field>
                                </div>
                            </div>
                            <i class="ruler"></i>
                        </div>

                        <!-- <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('do_you_have_diabetes_related_eye_kidney_problems')?.errors && (diseaseDetailForm.controls['DIABETES'].get('do_you_have_diabetes_related_eye_kidney_problems')?.pristine || diseaseDetailForm.controls['DIABETES'].get('do_you_have_diabetes_related_eye_kidney_problems')?.invalid)}">
                            <mat-label><i>*</i>Do You Have Diabetes Related Eye or Kidney
                                Problems?:</mat-label>
                            <mat-radio-group aria-label="Select an option"
                                formControlName="do_you_have_diabetes_related_eye_kidney_problems">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('do_you_have_diabetes_related_eye_kidney_problems')?.errors && (diseaseDetailForm.controls['DIABETES'].get('do_you_have_diabetes_related_eye_kidney_problems')?.pristine || diseaseDetailForm.controls['DIABETES'].get('do_you_have_diabetes_related_eye_kidney_problems')?.invalid)">
                                This field required
                            </mat-error>
                        </div> -->

                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('do_you_have_eye_problems')?.errors && (diseaseDetailForm.controls['DIABETES'].get('do_you_have_eye_problems')?.pristine || diseaseDetailForm.controls['DIABETES'].get('do_you_have_eye_problems')?.invalid)}">
                            <mat-label><i>*</i>Do you have any of the following eye/ vision problems?</mat-label>
                            <div class="control-pad">
                                <ng-select [multiple]="true" bindValue="option" bindLabel="option"
                                    formControlName="do_you_have_eye_problems"
                                    (change)="changeDiseasesEyeroblems($event)"
                                    placeholder="Select eye/ vision problems">
                                    <ng-option *ngFor="let option of diabetesEyeProblems" [value]="option">{{
                                        option | titlecase
                                        }}</ng-option>
                                </ng-select>
                            </div>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('do_you_have_eye_problems')?.errors && (diseaseDetailForm.controls['DIABETES'].get('do_you_have_eye_problems')?.pristine || diseaseDetailForm.controls['DIABETES'].get('do_you_have_eye_problems')?.invalid)">
                                This field required
                            </mat-error>
                        </div>                       
                        <div class="input-wrape"
                            [ngClass]="{ 'has-error': diseaseDetailForm.controls['DIABETES'].get('do_you_have_kidney_problem')?.errors && (diseaseDetailForm.controls['DIABETES'].get('do_you_have_kidney_problem')?.pristine || diseaseDetailForm.controls['DIABETES'].get('do_you_have_kidney_problem')?.invalid)}">
                            <mat-label><i>*</i>do you have any kidney problems?: </mat-label>
                            <mat-radio-group aria-label="Select an option" formControlName="do_you_have_kidney_problem">
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                                <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                            </mat-radio-group>
                            <mat-error
                                *ngIf="diseaseDetailForm.controls['DIABETES'].get('do_you_have_kidney_problem')?.errors && (diseaseDetailForm.controls['DIABETES'].get('do_you_have_kidney_problem')?.pristine || diseaseDetailForm.controls['DIABETES'].get('do_you_have_kidney_problem')?.invalid)">
                                This field required
                            </mat-error>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            <!-- Diabeties Melitas que section end -->
        </div>
    </div>
</div>
<!-- based on selection desiease show hide here end -->
