import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';


@Component({
  selector: 'app-matrix-report',
  templateUrl: './matrix-report.component.html',
  styleUrls: ['./matrix-report.component.scss']
})
export class MatrixReportComponent implements AfterViewInit {
  ngAfterViewInit() {
  }
  constructor() { }
  ngOnInit(): void {
  }
}
