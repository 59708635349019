import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CityService } from 'src/app/service/city.service';
import { StateService } from 'src/app/service/state.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-city-form',
    templateUrl: './city-form.component.html',
    styleUrls: ['./city-form.component.scss']
  })

  export class CityFormComponent implements OnInit {

    public cityForm!: FormGroup;
    public title: any;
    public cityId: any;
    public isAddMode: boolean = true;
    public isCurate: boolean = false;
    public disableSubmitBtn: boolean = false;
    public buttonText: string = "";
    public cancelLink: string = "/dictionary-management/city";
    public userId: any = "";
    public isApproved: number = 1;
    public selectMapOption: boolean = false;
    public showLoading: boolean = false;
    public cityNameMinLength: number = 3;
    public cityDetails: any = [];
    public stateList: any = [];
    public cityList: any = [];

    constructor(
      private cityService: CityService,
      private stateService: StateService,
      public commonService: CommonService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      protected router: Router
    ) {
      this.cityId = this.route.snapshot.paramMap.get("id");
      if (this.cityId !== 0 && this.cityId !== null) {
        this.title = "Edit City";
        this.buttonText = "Save";
      } else {
        this.title = "Add City";
        this.buttonText = "Add";
        this.cityId = 0;
      }
    }

    ngOnInit(): void {

      // Get State Dictionary
      this.getStateList();

        if (this.cityId !== 0 && this.cityId !== null) {
            this.setEditData();
        }

      this.isAddMode = !this.cityId;
      this.route.queryParams.subscribe((queryParams: any) => {
        if(this.router.url.indexOf('curation-pending') >= 0){
          this.isCurate = true;
          this.title = "Curate City";
          this.cancelLink = '/dictionary-management/city/curation-pending';
          // Get City Dictionary
          this.getCityList();
        }
      });

      this.userId = localStorage.getItem("userId");
      this.cityForm = this.formBuilder.group({
        'name': ['', [Validators.required, Validators.minLength(this.cityNameMinLength), Validators.maxLength(255)]],
        'stateId':[null,  [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
        'locality':['',  []],
        'isApproved':[this.isApproved,  []],
        'mapWithOtherCity':[null,  []]
      });

    }

     /**
       * Get all city for validate city
       * @returns object
       */
     // Get City Dictionary
     getCityList(){
      this.cityService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.cityList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    /**
       * Get all State for map with State
       * @returns object
       */
     // Get State Dictionary
     getStateList(){
      this.stateService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.stateList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    setEditData() {
      this.cityService.getRow(this.cityId)
      .subscribe({
        next: (result: any) => {
          if (result.status !== AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
          }
        this.cityDetails = result.data;
        setTimeout(() => {
            this.cityForm.patchValue({
                name: this.cityDetails.name,
                stateId: this.cityDetails.state_id,
                locality: (this.cityDetails.locality && this.cityDetails.locality != 'null') ? this.cityDetails.locality : '',
              });
        }, 300);

        const keyExists =  this.stateList.some((item: any) => item.name.includes(this.cityDetails.state.name));
          if(keyExists == false){
            this.stateList.push({ id: this.cityDetails.state.id, name: this.cityDetails.state.name });
            this.stateList = this.stateList.slice(0);      
          }
      },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    getCityNameError() {
      if (this.cityForm.controls['name'].errors!['required']) {
        return 'Name is required.';
      }
      if (this.cityForm.controls['name'].errors!['minlength']) {
        return 'Min length is ' + this.cityNameMinLength + ' characters.';
      }
      if (this.cityForm.controls['name'].errors!['maxlength']) {
        return 'Max length is 255 characters.';
      }
      if (this.cityForm.controls['name'].errors!['unique']) {
        return this.cityForm.get("name")!.errors!['unique'] ;
      }
      return '';
    }

    getSelectStateError() {
      if (this.cityForm.controls['stateId'].errors!['required']){
        return 'State is required';
      }
      if(this.cityForm.controls['stateId'].errors!['pattern']) {
        return 'Please select valid state';
      }
      return '';
    }

    /**
     * submit form
     * @returns null
     */
    onSubmit(): void {
      if (this.cityForm.invalid) {
        this.commonService.validateAllFormFields(this.cityForm);
        this.commonService.showErrorToast(
          'OOPS! Please enter correct values'
        );
        return;
      }
      this.disableSubmitBtn = true;
      const formData: FormData = new FormData();
      formData.append('name', this.cityForm.controls['name'].value);
      formData.append('state_id', this.cityForm.controls['stateId'].value ?? 0);
      formData.append('is_approved', this.cityForm.controls['isApproved'].value);
      formData.append('locality', this.cityForm.controls['locality'].value);
      formData.append('map_with_other_city', (this.cityForm.controls['mapWithOtherCity'].value) ? this.cityForm.controls['mapWithOtherCity'].value : 0);
        if (this.isAddMode) {
            this.createCity(formData);
        } else {
            this.updateCity(formData);
        }
    }

    /**
     * call Create City api
     * @returns null
     */
    private createCity(formData: any): void {
      this.cityService.store(formData)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/dictionary-management/city"]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error:(err: any) => {
          console.log(err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }

    /**
     * call Update City api
     * @returns null
     */
    private updateCity(formData: any): void {
      formData.append('updated_by', this.userId);
      this.cityService.update(formData, this.cityId)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            let errorMessage = '';
            if(result.data.name){
              errorMessage = result.data.name;
            }else if (result.data.state_id){
              errorMessage = 'Please select valid state';
            }else {
              errorMessage = result.message;
            }
            this.commonService.showErrorToast(errorMessage);
          }
        },
        error:(err: any) => {
          console.log(err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }

    /**
     * autopopulate city values
     * Select Approved Option
     *
     * @returns object
     */
     selectOption(value: boolean) {
      this.selectMapOption = value;
      if(value){
        this.cityForm
          .get('mapWithOtherCity')!
          .setValidators([
            Validators.required,
            Validators.pattern('^[1-9][0-9]*$'),
          ]);
        this.cityForm.controls['stateId'].disable();
      }else{
        this.cityForm.controls['stateId'].enable();
        this.cityForm.patchValue({
           stateId: this.cityDetails.state_id,
           locality: (this.cityDetails.locality && this.cityDetails.locality != 'null') ? this.cityDetails.locality : '',
           mapWithOtherCity: null
        });
        this.cityForm.get('mapWithOtherCity')!.setValidators(null);
        this.cityForm.get('stateId')!
          .setValidators([
            Validators.required,
            Validators.pattern("^[1-9][0-9]*$")
        ]);
      }
      this.cityForm.controls['mapWithOtherCity'].updateValueAndValidity()
      this.cityForm.controls['stateId'].updateValueAndValidity();
      this.getMapWithCityError();
    }

    getMapWithCityError() {
        if(this.cityForm.controls['mapWithOtherCity'].invalid){
        if (this.cityForm.controls['mapWithOtherCity'].errors!['required']) {
          return 'Select city';
        }
        if(this.cityForm.controls['mapWithOtherCity'].errors!['pattern']) {
          return 'Please select valid city';
        }
      }
      return '';
    }

    /**
     * Check city field is existed or not
     * @returns null
     */
     checkUniqueCityFields(event: any) {
      const cityFieldValue = this.cityForm.controls['name'].value;
      if (cityFieldValue.length > 255) {
        return false;
      }

        if (cityFieldValue.length < this.cityNameMinLength) {
          return false;
        }

      if (cityFieldValue !== "") {
          this.cityService
          .checkUniqueCityFields(
            cityFieldValue,
            this.cityId,
            this.cityForm.controls['stateId'].value ?? 0
          )
          .subscribe({
            next:(result: any) => {
              if (result.status === 1 || result.status === 200) {
                this.cityForm.get("name")!.setErrors(null);
              } else {
                this.cityForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
              }
            },
            error: (error: any) => {
              if (error.status === 400) {
                this.cityForm
                  .get("name")!
                  .setErrors({ unique: "Name already exists" });
              } else {
                console.log(
                  "Something went wrong. Please contact to administrator."
                );
              }
            }
          });
      }
      return null;
    }

    mapWithCityChange(event: any){
      if(event && event.id){
        this.cityService.getRow(event.id)
        .subscribe({
        next: (result: any) => {
          if (result.status == AppConstants.serverSuccessStatus && result.data) {
            setTimeout(() => {
              this.cityForm.patchValue({
                stateId: result.data.state_id,
                locality: (result.data.locality && result.data.locality != 'null') ? result.data.locality : '',
              });
            }, 200);
          }
        },
        error: (e: any) => {
          console.log(
            e.error
            );
          },
        });
      } else {
        this.cityForm.patchValue({
          locality: '',
          stateId: null
        });
      }
    }

  }


