import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { map, startWith } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { LabDataService } from 'src/app/service/lab-data.service';

@Component({
  selector: 'app-lab-data-import',
  templateUrl: './lab-data-import.component.html',
  styleUrls: ['./lab-data-import.component.scss']
})
export class LabDataImportComponent implements OnInit {

  public labDataImportForm!: FormGroup;
  public labFile!: File;
  public selectedFileName: string = '';

  public labFileType: any = [
    { label: 'Quality Quantity Record', value: 'quality_quantity_record' },
    { label: 'Gel Analysis', value: 'gel_analysis' },
    { label: 'Y-STS Record', value: 'ysts' },
    { label: 'Storage Record', value: 'storage' },
  ];

  constructor(
    public commonService: CommonService,
    protected router: Router,
    protected labDataService: LabDataService,
  ) { }

  ngOnInit(): void {

    this.labDataImportForm = new FormGroup({
      lab_data_file: new FormControl('', [Validators.required]),
      labFileType: new FormControl(null, [Validators.required]),
    });

  }

  /**
   * Validate form fields recursivly
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSelectLabFile(event: any) {

    this.labFile = event.target.files[0];

    this.selectedFileName = this.labFile.name;

    // console.log('here');
    this.labDataImportForm.controls['labFileType'].setErrors({ 'serverError': null });
    this.labDataImportForm.controls['labFileType'].updateValueAndValidity();

    if (
      (this.labFile !== undefined)
      && (
        this.labFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {

      this.labDataImportForm.controls['lab_data_file'].setErrors({ 'fileInvalidType': 'Please select a valid file' });
      // this.toastr.danger('Please fill all the fields', 'OOPS!');
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    } else if ((this.labFile !== undefined) && ((this.labFile.size / 1024 / 1024) > 10)) {

      this.labDataImportForm.controls['lab_data_file'].setErrors({ 'fileInvalidSize': 'Files size limit exceeded. Max file size is 10 MB' });
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    }
  }

  onSubmit() {

    if (this.labDataImportForm.invalid) {
      this.validateAllFormFields(this.labDataImportForm);
      return;
    }

    if (
      (this.labFile !== undefined)
      &&
      (
        this.labFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.labDataImportForm.controls['lab_data_file'].setErrors({ 'fileInvalidType': 'Please select a valid file' });
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;

    } else if ((this.labFile !== undefined) && ((this.labFile.size / 1024 / 1024) > 10)) {
      this.labDataImportForm.controls['lab_data_file'].setErrors({ 'fileInvalidSize': 'Files size limit exceeded. Max file size is 10 MB' });
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    }
    //Validation Ends here

    const formData: FormData = new FormData();
    formData.append('lab_data_file', this.labFile, this.labFile.name);
    formData.append('labFileType', this.labDataImportForm.controls['labFileType'].value);

    this.labDataService.importHtrData(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {

            if (result.data.is_error === true) {
              this.labDataImportForm.controls['lab_data_file'].setErrors({ 'missingPIDs': 'PIDs not exists into ggcCORE: ' + result.data.new_pids, 'missingPIDsNote': 'NOTE: Rest of the data synced successfully!' });
              this.commonService.showErrorToast(
                "Some PIDs are missing from the database"
              );
            } else {
              this.commonService.showSuccessToast(
                "Lab data imported successfully!"
              );
              this.router.navigate(["/lab-data"]);
            }
          } else {
            this.labDataImportForm.controls['labFileType'].setErrors({ 'serverError': result.message });
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }
}
