<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="{{cancelLink}}" title="Project" class="only-link">Project</a></li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="row justify-content-md-center">
        <div class="col-xl-8 col-md-12 col-sm-12">
            <form [formGroup]="projectForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
                <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text">{{title}}</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-12 col-md-12">
                              <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.projectForm, 'name')">
                                  <mat-label><i>*</i> Name</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput #input 
                                        formControlName="name" 
                                        placeholder="Enter name" 
                                        type="text"
                                        (focusout)="
                                        checkUniqueProject($event)"
                                        >
                                    </mat-form-field>
                                    <mat-error *ngIf="this.projectForm.controls['name'].invalid && (this.projectForm.controls['name'].dirty || this.projectForm.controls['name'].touched)">
                                        {{getProjectError()}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="upload-thumb-wrape full">
                                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.projectForm, 'projectSops')">
                                        <mat-label>Project SOPs</mat-label>
                                        <div class="upload-wrape side-info">
                                            <input id="fileInput" size="24" type="file" class="ng-hide" placeholder="upload"
                                                formControlName="projectSops" (change)="selectProjectSopsFile($event)" accept=".pdf" multiple>
                                            <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button
                                                    class="btn" color="primary"> Upload</button>
                                            <div class="info-pad popup-over">
                                                <button class="info-icon"></button>
                                                <div class="info-section popupcontent">
                                                    Please choose .pdf file only
                                                </div>
                                            </div>
                                        </div>
                                            <span *ngIf="projectDetails.project_sops?.length > 0" class="upload-filename">
                                                    <a *ngFor="let file of projectDetails?.project_sops; let i=index;" href="{{file.url}}"
                                                    download="download" target="_blank" title="{{file.name}}">
                                                    File{{i + 1}}
                                                </a>
                                            </span>
                                            <span *ngIf="projectSopsFiles.length > 0 && this.projectForm.controls['projectSops']?.valid" class="upload-filename">
                                                <a *ngFor="let file of projectSopsFiles; let i=index;" title="{{file}}" href="JavaScript:void(0);">
                                                    File{{i + 1}}
                                                </a>
                                            </span>
                                        <mat-error *ngIf="this.projectForm.controls['projectSops']?.invalid && (this.projectForm.controls['projectSops']?.dirty || this.projectForm.controls['projectSops']?.touched)">
                                            {{getPDFFileError('projectSops')}}
                                        </mat-error>                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="upload-thumb-wrape full">
                                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.projectForm, 'note')">
                                        <mat-label>Note</mat-label>
                                        <div class="upload-wrape side-info">
                                            <input id="fileInput" type="file" class="ng-hide" placeholder="upload" formControlName="note" 
                                                (change)="selectNoteFile($event)" accept=".pdf">
                                            <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button
                                                class="btn" color="primary"> Upload</button>
                                            <div class="info-pad popup-over">
                                                <button class="info-icon"></button>
                                                <div class="info-section popupcontent">
                                                    Please choose .pdf file only
                                                </div>
                                            </div>
                                        </div>
                                        <span *ngIf="projectDetails?.note?.length>0 && !noteFile" class="upload-filename">
                                            <a title="{{projectDetails?.note}}"
                                            href="{{projectDetails?.note_url}}" download="download"
                                            target="_blank">File</a>
                                        </span>
                                        <span *ngIf="noteFile && this.projectForm.controls['note']?.valid" class="upload-filename">
                                            <a title="{{noteFile.name}}"
                                            href="JavaScript:void(0);">File</a>
                                        </span>
                                        <mat-error *ngIf="this.projectForm.controls['note']?.invalid && (this.projectForm.controls['note']?.dirty || this.projectForm.controls['note']?.touched)">
                                            {{getPDFFileError('note')}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="upload-thumb-wrape full">
                                    <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.projectForm, 'inclusionExclusionNote')">
                                        <mat-label>Inclusion/Exclusion Notes</mat-label>
                                        <div class="upload-wrape side-info">
                                            <input id="fileInput" type="file" class="ng-hide" placeholder="upload" formControlName="inclusionExclusionNote" 
                                                (change)="selectInclusionExclusionNoteFile($event)" accept=".pdf">
                                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
                                            <div class="info-pad popup-over">
                                                <button class="info-icon"></button>
                                                <div class="info-section popupcontent">
                                                    Please choose .pdf file only
                                                </div>
                                            </div>
                                        </div>
                                        <span *ngIf="projectDetails?.inclusion_exclusion_note?.length>0 && !incExcNoteFile" class="upload-filename ">
                                            <a
                                            href="{{projectDetails?.inclusion_exclusion_note_url}}"
                                            download="download" title="{{projectDetails?.inclusion_exclusion_note}}"
                                            target="_blank">File</a>
                                        </span>
                                        <span *ngIf="incExcNoteFile && this.projectForm.controls['inclusionExclusionNote']?.valid" class="upload-filename">
                                            <a
                                            href="JavaScript:void(0);" title="{{incExcNoteFile.name}}"
                                            >File</a>
                                        </span>
                                        <mat-error *ngIf="this.projectForm.controls['inclusionExclusionNote']?.invalid && (this.projectForm.controls['inclusionExclusionNote']?.dirty || this.projectForm.controls['inclusionExclusionNote']?.touched)">
                                            {{getPDFFileError('inclusionExclusionNote')}}
                                        </mat-error> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" type="submit" color="secoundary"
                            title="{{buttonText}}">{{buttonText}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>