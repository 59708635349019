import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/service/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CampDataVerificationElement } from 'src/app/interfaces/camp-data-verification';
import { CampDataVerificationService } from 'src/app/service/camp-data-verification.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';

const ELEMENT_DATA: CampDataVerificationElement[] = [];

@Component({
  selector: 'app-camp-data-verification',
  templateUrl: './camp-data-verification.component.html',
  styleUrls: ['./camp-data-verification.component.scss']
})
export class CampDataVerificationComponent implements OnInit {

  public displayedColumns: string[] = [
    'participant_id',
    'collection_date',
    'disease_question',
    'barcode',
    'pmr',
    'profile',
    'pc_signature',
    'icf_form',
    'cv_signature',
    'comment'
  ];


  public dataSource = new MatTableDataSource<CampDataVerificationElement>(ELEMENT_DATA);

  public filterColumns!: string;
  public tableColumns: TableColumn[] = [];
  public loading: boolean = false;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public startDate: string = '';
  public endDate: string = '';
  public minDate: any;
  public is_hidden: number = 1;
  public campDataVerificationList: any = [];
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public matNoDataRowTitle: string = 'Loading Data...';
  public currentMenuPermission: any;

  constructor(
    public campDataVerificationService: CampDataVerificationService,
    public commonService: CommonService,
    private menuService: MenuService,
  ) { }

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
    this.filterColumns = this.displayedColumns.join(", ");
    this.displayedColumns.push('action');

    this.loading = true;
    this.minDate = this.commonService.transformDate('2021-11-29');
    this.getAllCampData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchFilter(event: Event) {
    const searchFilterValue = (event.target as HTMLInputElement).value;
    this.filterValue = searchFilterValue.trim().toLowerCase();
    this.currentPage = 0;
    this.getAllCampData();
  }

  sortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAllCampData();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllCampData();
  }

  toggleHiddenData() {
    this.is_hidden = (this.is_hidden == 0) ? 1 : 0;
    this.getAllCampData();
  }

  toggleIsHiddenValue(is_hidden: boolean, participant_id: any) {
    this.loading = true;
    this.campDataVerificationService.toggleIsHiddenValue(!is_hidden, participant_id)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.getAllCampData();
            this.loading = false;
          }
        },
        error: (e: any) => {
          console.log('Toggle Is Hideen Data Verification error :: ', e);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  compareTwoDates(dateType: any, event: any) {
    let eventDate = event.target.value._d;
    if (!dateType && !eventDate) {
      return false;
    }

    if (dateType === "startDate") {
      this.startDate = eventDate;
    }

    if (dateType === "endDate") {
      this.endDate = eventDate;
    }

    if (this.startDate == null || this.endDate == null) {
      return false;
    }

    let startDate = this.commonService.transformDate(this.startDate) as string;
    let endDate = this.commonService.transformDate(this.endDate) as string;

    if (startDate !== undefined && endDate !== undefined && endDate < startDate) {
      this.commonService.showErrorToast("'Start Date' should be less than 'End Date'");
      this.startDate = '';
      this.endDate = '';
      return false;
    }
    return true;
  }

  clearDate(type: string) {
    if (type == 'startDate') {
      this.startDate = '';
    } else {
      this.endDate = '';
    }
  }

  refreshParticipantData(id = 0) {
    this.loading = true;
    const start = this.commonService.transformDate(this.startDate) as unknown as Blob;
    const end = this.commonService.transformDate(this.endDate) as unknown as Blob;
    this.campDataVerificationService.refreshParticipantData(id, start, end)
      .subscribe({
        next: (result: any) => {
          this.getAllCampData();
          this.loading = false;
        },
        error: (e: any) => {
          console.log('Refresh Participant Data err :: ', e);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  storeComment(event: any, id: number) {
    if (this.currentMenuPermission.edit == true) {
      const commentValue = (event.target as HTMLInputElement).value;
      this.campDataVerificationService.storeComment(commentValue, id)
        .subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.loading = false;
            }
            this.getAllCampData();
            this.loading = false;
          },
          error: (e: any) => {
            console.log('Store Comment err :: ', e);
            this.loading = false;
          },
          complete: () => {
            this.loading = false;
          },
        });
    }
  }

  /**
   * This function is use to get all Camp Data Verification Listing
   * @author Farhan Shaikh
   */
  getAllCampData() {
    this.loading = true;
    this.campDataVerificationService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.is_hidden
      )
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus && result.data) {
            this.dataSource = new MatTableDataSource(result.data.data);
            this.dataSource.paginator = this.paginator;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = result.data.total;
            });
          }
          if (this.dataSource != undefined && this.dataSource.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
          this.loading = false;
        },
        error: (e: any) => {
          console.log('Camp Data Verification Listing err :: ', e);
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }
}
