import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class CampDataVerificationService  extends CrudService {

  constructor(public override http: HttpClient) {
    super('camp-data-verification', http);
  }

  /**
   * @author Farhan Shaikh
   * Refresh Participant Data
   * @param id
   * @param startDate
   * @param endDate
   */
  refreshParticipantData(id: any, startDate: any, endDate: any) {
    return this.http.get(`${this.baseUrl}/refresh/${id}/${startDate}/${endDate}`);
  }

  /**
   * @author Farhan Shaikh
   * Participant Hidden Toggle Value 
   * @param is_hidden
   * @param participant_id
   */
  toggleIsHiddenValue(is_hidden: boolean, participant_id: any) {
    return this.http.post(`${this.baseUrl}/toggleIsHiddenValue/${participant_id}/${is_hidden}`, []);
  }

  /**
   * @author Farhan Shaikh
   * Store Comment
   * @param value
   * @param id
   */
  storeComment(value: any, id: any) {
    const body = new HttpParams()
      .set(`comment`, value)
      .set(`participant_id`, id);

    return this.http.post<any>(`${this.baseUrl}/storeComment`, body);
  }
}
