import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoincCodeService extends CrudService {

  constructor(public override http: HttpClient) {
    super('loinc-code', http);
  }

  /**
   * Get all active list
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @returns object
   */
  getDiagnosticLab() {
    return this.http.get(`${this.baseUrl}/get-diagnostic-lab`);
  }
}