<div class="page-title-box">
  <h1>{{title}}</h1>
  <ul class="bradcrumb">
    <li>{{title}}</li>
    <li><a routerLink="{{cancelLink}}" title="Allergic Reaction/Symptoms" class="only-link">Allergic Reaction/Symptoms</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xl-8 col-md-12 col-sm-12">
      <form [formGroup]="allergicReactionForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text">{{title}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape"
                  [class]="this.commonService.displayFieldCss(this.allergicReactionForm, 'allergic_reaction_symptoms')">
                  <mat-label><i>*</i>Allergic reaction/symptom</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput #input formControlName="allergic_reaction_symptoms" placeholder="Enter allergic Reaction" type="text"
                      (focusout)="
                    checkUniqueAllergicReactionFields('allergic_reaction_symptoms', $event)">
                  </mat-form-field>
                  <mat-error
                    *ngIf="this.allergicReactionForm.controls['allergic_reaction_symptoms'].invalid && ( this.allergicReactionForm.controls['allergic_reaction_symptoms'].touched || this.allergicReactionForm.controls['allergic_reaction_symptoms'].dirty)">
                    {{getReactionError()}}
                  </mat-error>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" *ngIf="isCurate">
                  <mat-label><i>*</i> Is Approved?:</mat-label>
                  <mat-radio-group aria-label="Select an option" class="example-radio-group"
                    formControlName="isApproved">
                    <mat-radio-button class="example-radio-button" id="radio_yes" (click)="selectOption(false)"
                      [value]="true" checked>
                      Approved</mat-radio-button>
                    <mat-radio-button class="example-radio-button" id="radio_no" (click)="selectOption(true)"
                      [value]="false">
                      Map with others</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="input-wrape" *ngIf="selectMapOption"
                  [class]="this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].invalid && ( this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].touched || this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].dirty) ? 'has-error' : '' ">
                  <mat-label><i>*</i> Map With:</mat-label>
                  <div class="control-pad">
                    <ng-select matInput [items]="allergicReactionList" [disabled]="true"
                      formControlName="mapWithOtherAllergicReaction" bindLabel="allergic_reaction_symptoms" bindValue="id"
                      labelForId="mapWithOtherAllergicReaction" (change)="mapWithAllergicReactionChange($event)"
                      placeholder="Select Allergic Reaction" appendTo="body">
                    </ng-select>
                  </div>
                  <mat-error
                    *ngIf="this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].invalid && ( this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].touched || this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].dirty)">
                    {{getMapWithAllergicReactionError()}}
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
              title="Cancel">Cancel</button>
            <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
              title="{{buttonText}}">{{buttonText}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
