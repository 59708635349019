<!-- Chronic Kidney Disease que section start -->
<div class="col-lg-12" [formGroup]="diseaseDetailForm">
    <div class="card-wrape" formArrayName="CHRONIC_KIDNEY_DISEASE">
        <div class="card-head">
            <h4 class="left-text">Chronic Kidney Disease (CKD) Details</h4>
        </div>
        <div class="card-body" [ngClass]="isViewDisease ? 'view-mode' : 'edit-mode'">
            <div class="ckd-detail-que row">
                <div class="col-lg-6">
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.errors && (diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.pristine || diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.invalid)}">
                        <mat-label>Estimated Glomerular Filtration Rate (eGFR/Estimated GFR) Report
                            Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_egfr_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.errors || (diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.pristine && diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.errors && (diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.pristine || diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.invalid)}"
                        *ngIf="diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('have_egfr_report')?.value =='yes'">
                        <mat-label>Photo of Estimated Glomerular Filtration Rate Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.value == '') || (diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_egfr_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.value != '') && (diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img
                                    src="{{diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report_url')?.value}}"
                                    alt="" (click)="showImage(diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report_url')?.value)">
                                </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.value,'photo_egfr_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.errors && (diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.pristine || diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('photo_egfr_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="input-wrape">
                        <mat-label>Do You Need Dialysis?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="do_you_need_dialysis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('do_you_need_dialysis')?.value =='yes'">
                        <mat-label>For How Long?:</mat-label>
                        <div class="three-controls">
                            <div class="input-wrape threes">
                                <mat-form-field appearance="outline">
                                    <mat-select placeholder="Select year"
                                        formControlName="how_long_do_you_need_dialysis_year">
                                        <mat-option *ngFor="let year of years" value="{{year}}">{{year}}</mat-option>
                                    </mat-select>
                                    <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                </mat-form-field>
                            </div>
                            <div class="input-wrape threes">
                                <mat-form-field appearance="outline">
                                    <mat-select placeholder="Select month"
                                        formControlName="how_long_do_you_need_dialysis_month">
                                        <mat-option *ngFor="let month of months"
                                            value="{{month}}">{{month}}</mat-option>
                                    </mat-select>
                                    <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                </mat-form-field>
                            </div>
                            <div class="input-wrape threes">
                                <mat-form-field appearance="outline">
                                    <mat-select placeholder="Select day"
                                        formControlName="how_long_do_you_need_dialysis_day">
                                        <mat-option *ngFor="let day of days" value="{{day}}">{{day}}</mat-option>
                                    </mat-select>
                                    <!-- <mat-hint align="end">Here's the dropdown arrow ^</mat-hint> -->
                                </mat-form-field>
                            </div>
                        </div>
                        <i class="ruler"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Chronic Kidney Disease que section end -->
