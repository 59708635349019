<div class="page-title-box">
    <h1> Site Listing for Matrix </h1>
    <ul class="bradcrumb">
      <li> Site Matrix</li>
      <li><a routerLink="/site/matrix" title="Ctate" class="only-link">Manage Matrix</a></li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

  <section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12 m-b-0">
            <div class="stat-wrape four">
                <div class="stat-pad">
                    <p>PARTICIPANTS</p>
                    <h2><i class="theme-icon camp-held"></i> {{totalPatricipants ? totalPatricipants : 0}}</h2>
                </div>
                <!-- <div class="stat-pad">
                    <p>AUDIOS</p>
                    <h2><i class="theme-icon audio"></i> {{totalAudios ? totalAudios : 0}}</h2>
                </div> -->
                <div class="stat-pad">
                    <p>IMAGES</p>
                    <h2><i class="theme-icon image"></i> {{totalImages ? totalImages : 0}}</h2>
                </div>
                <div class="stat-pad">
                    <p>DISEASES</p>
                    <h2><i class="theme-icon disease"></i> {{totalDiseases ? totalDiseases : 0}}</h2>
                </div>
            </div>
        </div>
      </div>
  </section>

  <section class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-md-12">
              <div class="card-wrape">
                  <div class="card-head p-t-5 p-b-5">
                      <h4 class="left-text" style="line-height: -10px;">{{siteMatrixData?siteMatrixData.site_name:""}} -
                        {{siteMatrixData?siteMatrixData.site_code:""}}</h4>
                      <div class="right-control">
                          <div class="calendar-pad" *ngIf="displayDateFilter">
                              <div class="input-wrape calendar-wrape">
                                  <mat-label>Date Filter:</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <input matInput [matDatepicker]="pickerStartDate"
                                            [value]="startDate"
                                            [min]="minDate"
                                            [max]="maxDate"
                                            placeholder="Select start date"
                                            (click)="pickerStartDate.open()"
                                            (dateChange)="compareTwoDates('startDate', $event)" readonly>
                                        <mat-datepicker-toggle matSuffix
                                            [for]="pickerStartDate">
                                            <mat-icon matDatepickerToggleIcon
                                                class="calendar-icon"></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerStartDate></mat-datepicker>
                                        <button *ngIf="startDate" type="button" class="fa fa-clear-icons clear-button-calender" (click)="clearDate('startDate')">×</button>
                                    </mat-form-field>
                             </div>
                            <div class="input-wrape calendar-wrape">
                                <mat-label>To</mat-label>
                              <mat-form-field class="example-full-width" appearance="outline">
                                  <input matInput [matDatepicker]="pickerEndDate"
                                      [value]="endDate"
                                      [min]="minDate"
                                      [max]="maxDate"
                                      placeholder="Select end date"
                                      (click)="pickerEndDate.open()"
                                      (dateChange)="compareTwoDates('endDate', $event)" readonly>
                                  <mat-datepicker-toggle matSuffix
                                      [for]="pickerEndDate">
                                      <mat-icon matDatepickerToggleIcon
                                          class="calendar-icon"></mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #pickerEndDate></mat-datepicker>
                                  <button *ngIf="endDate" type="button" class="fa fa-clear-icons clear-button-calender" (click)="clearDate('endDate')">×</button>
                              </mat-form-field>
                            </div>
                          </div>

                          <div class="dd-wrapes" *ngIf="!tableDetail">
                              <button type="button" class="border-icon-btn" (click)="inputToggle()"><i class="info-icon"></i> Add Custom Email ID</button>
                              <button class="primary btn" mat-raised-button color="secoundary" style="padding: 3px 14px;" [disabled]="disableReportBtn" (click)="sendReport()">Send Report</button>
                              <div class="toggle-popup-wrape" *ngIf="inputTogglePad">
                                <div class="input-wrape">
                                    <mat-form-field appearance="outline">
                                        <input #emailInput matInput (keyup.enter)="[addEmail(emailInput.value),emailId?.invalid && (emailId?.dirty || emailId?.touched) ? null : emailInput.value='']"
                                            type="text" class="form-control form-control-sm" ngModel #emailId="ngModel" placeholder="Enter email ID" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                                    </mat-form-field>
                                    <mat-error *ngIf="emailId?.errors && emailId?.invalid && (emailId?.dirty || emailId?.touched)"
                                      >
                                        Please enter valid email address
                                    </mat-error>
                                </div>
                                <div class="custom-id-wrape">
                                    <h6>Email ID(s)</h6>
                                    <p *ngFor="let email of emails; let myIndex=index;">
                                      <button (click)="removeEmail(myIndex)">×</button> {{email}}
                                    </p>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="card-wrape">
          <div class="card-body p-0">
            <a href="javascript:;" title="Sync Site Matrix" class="indi-icon sync-icon" *ngIf="siteMatrixData?.number_of_live_pids > (siteMatrixData?.number_of_pids ? siteMatrixData?.number_of_pids : 0)" (click)="syncSiteMatrix(siteMatrixData?.current_site_id)"></a>
                      <mat-tab-group animationDuration="500ms" (selectedTabChange)="tabClick($event)">
                          <!-- tab 1 start here -->
                          <mat-tab label="Site Matrix">
                            <mat-spinner *ngIf="siteMatrixLoader"></mat-spinner>
                              <div class="row equal">
                                <div class="col-lg-4">
                                  <div class="site-info-wrape">
                                    <div class="card-wrape">
                                      <div class="card-head">
                                        <h4 class="left-text">Site Information</h4>
                                      </div>
                                      <div class="card-body">
                                        <p>
                                          <b>Site Date:</b>
                                          <span>{{siteMatrixData?siteMatrixData.start_date:"-"}} -
                                            {{siteMatrixData?siteMatrixData.end_date:"-"}}</span>
                                        </p>
                                        <p>
                                          <b>Site Name:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.site_name !==0 ? siteMatrixData.site_name: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Total No. of Devices Used:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_device_used !==0 ? siteMatrixData.no_of_device_used: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Types of Devices Used:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.type_of_device_used !=='' ? siteMatrixData.type_of_device_used: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Total Counselors:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.total_counselors !==0 ? siteMatrixData.total_counselors: "-"}}
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-4">
                                  <div class="site-info-wrape">
                                    <div class="card-wrape">
                                      <div class="card-head">
                                        <h4 class="left-text">Participant's Information</h4>
                                      </div>
                                      <div class="card-body">
                                        <p>
                                          <b>Number of PID (Total Enrollments):</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.number_of_pids !==0 ? siteMatrixData.number_of_pids: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Average Time Taken for PID:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.avg_time_taken_by_pid || siteMatrixData.highet_time_taken_by_pid ? siteMatrixData.avg_time_taken_by_pid + " Min": "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Highest Time Taken for PID:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{ siteMatrixData.highet_time_taken_by_pid  ? siteMatrixData.highet_time_taken_by_pid + " Min": "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Lowest Time Taken for PID:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.lowest_time_taken_by_pid || siteMatrixData.highet_time_taken_by_pid ? siteMatrixData.lowest_time_taken_by_pid + " Min": "-"}}
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-4">
                                  <div class="site-info-wrape">
                                    <div class="card-wrape">
                                      <div class="card-head">
                                        <h4 class="left-text">Images</h4>
                                      </div>
                                      <div class="card-body">
                                        <p>
                                          <b>Total No. of Images:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_images !==0 ? siteMatrixData.no_of_images: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                            <b>Total No. of Dieases Images:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_disease_images !==0 ? siteMatrixData.no_of_disease_images: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                            <b>Total No. of PMR Images:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_pmr_images !==0 ? siteMatrixData.no_of_pmr_images: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                            <b>Average Size of Image:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.avg_size_of_images !==0 ? siteMatrixData.avg_size_of_images: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                            <b>Largest Size of Image:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.bigest_size_of_image !==0 ? siteMatrixData.bigest_size_of_image: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Smallest Size of Image:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.smallest_size_of_image !==0 ? siteMatrixData.smallest_size_of_image: "-"}}
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-4">
                                  <div class="site-info-wrape">
                                    <div class="card-wrape">
                                      <div class="card-head">
                                        <h4 class="left-text">Gender</h4>
                                      </div>
                                      <div class="card-body">
                                        <p>
                                          <b>Total No. of Male Participants:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_male_participant !==0 ? siteMatrixData.no_of_male_participant: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Total No. of Female Participants:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_female_participant !==0 ? siteMatrixData.no_of_female_participant: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Total No. of Transgender Participants:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_other_participant !==0 ? siteMatrixData.no_of_other_participant: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Total No. of Prefer Not to Say Participants:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.no_of_prefer_not_to_say_participant !==0 ? siteMatrixData.no_of_prefer_not_to_say_participant: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Average Age of Male Participant:</b>
                                            <span *ngIf="siteMatrixData && siteMatrixData.avg_age_of_male_participant">
                                                {{siteMatrixData.avg_age_of_male_participant !==0 ? siteMatrixData.avg_age_of_male_participant + " Years": "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Average Age of Female Participant:</b>
                                            <span *ngIf="siteMatrixData && siteMatrixData.avg_age_of_female_participant">
                                                {{siteMatrixData.avg_age_of_female_participant !==0 ? siteMatrixData.avg_age_of_female_participant + " Years": "-"}}
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-4">
                                  <div class="site-info-wrape">
                                    <div class="card-wrape">
                                      <div class="card-head">
                                        <h4 class="left-text">Diseases</h4>
                                      </div>
                                      <div class="card-body">
                                        <p>
                                          <b>Total Disease Count:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.total_disease_count !==0 ? siteMatrixData.total_disease_count: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Highest Disease Recorded for Participants:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.highest_no_of_diseases_recorded_for_a_participant !==0 ? siteMatrixData.highest_no_of_diseases_recorded_for_a_participant: "-"}}
                                            </span>
                                        </p>
                                        <p>
                                          <b>Lowest Disease Recorded for Participants:</b>
                                            <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.lowest_no_of_diseases_recorded_for_a_participant !==0 ? siteMatrixData.lowest_no_of_diseases_recorded_for_a_participant: "-"}}
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-4">
                                  <div class="site-info-wrape">
                                    <div class="card-wrape">
                                      <div class="card-head">
                                        <h4 class="left-text">ICF</h4>
                                      </div>
                                      <div class="card-body">
                                        <p>
                                          <b>Total No. of ICF Files:</b>
                                          <span *ngIf="siteMatrixData">
                                                {{siteMatrixData.number_of_icf_files !==0 ? siteMatrixData.number_of_icf_files: "-"}}
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </mat-tab>
                          <!-- tab 1 end here -->

                          <!-- tab 2 start here -->
                            <mat-tab label="Participant Matrix">
                                <section class="container-fluid">
                                    <div class="row">
                                        <div class="col-xl-12 col-md-12" *ngIf="tableShow">
                                            <div class="card-wrape">
                                                <mat-spinner *ngIf="loading"></mat-spinner>
                                                <app-table [tableData]="participantList" [tableColumns]="displayedColumns" [isFilterable]="true"
                                                    [isPageable]="true" [paginationSizes]="pageSizeOptions" [defaultPageSize]="10"
                                                    [totalRows]="totalRows" [pageIndex]="currentPage" [rowActionIcon]="'Action'"
                                                    (sort)="sortFilter($event)" (viewAction)="showInfo($event)"
                                                    (pageChanged)="pageChanged($event)" [is_data_curation_access]="is_data_curation_access" [disableSyncBtn]="disableSyncBtn" (syncCohortDMData)="syncCohortDMData()" [exportOrangeDmCohortLink]="exportLink" [isMatrixParticipant]="true" [isMatrix]="true" (searchFilter)="searchFilter($event)" [pageTitle]="title" [currentMenuPermission]="currentMenuPermission">
                                                </app-table>
                                            </div>
                                        </div>
                                    </div>
                                  </section>
                               <section class="container-fluid p-0">
                                <div class="row">
                                  <div class="col-xl-12 col-md-12">
                                  <div class="card-wrape border-enable back-page" *ngIf="tableDetail">
                                      <div class="card-head">
                                      <h4 class="left-text"><a href="javascript:;" title="" class="back-btn" (click)="showTable()"></a> {{participantMatrixData?participantMatrixData.participant_id_number:"-"}}</h4>
                                    </div>
                                    <div class="card-body participant-matrix-inner">
                                        <div class="row equal">
                                          <div class="col-lg-4">
                                            <div class="site-info-wrape">
                                              <div class="card-wrape">
                                                <div class="card-head">
                                                  <h4 class="left-text">Participant Collections Details</h4>
                                                </div>
                                                <div class="card-body">
                                                  <p>
                                                    <b>PID:</b>
                                                    <span>{{participantMatrixData ?participantMatrixData.participant_id_number:"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Recollection ID:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.participant_matrix?participantMatrixData.participant_matrix.recollection_id:"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Counselor:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.counsellor?participantMatrixData.counsellor.first_name+"
                                                      "+participantMatrixData.counsellor.last_name:"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Date of Collection:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.participant_matrix? datePipeFormat.transform(participantMatrixData.participant_matrix.date_of_collection,'dd MMM YYYY'):"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Medical Records File:</b>
                                                    <span *ngIf="participantMatrixData && participantMatrixData.participant_matrix">{{participantMatrixData.participant_matrix.pmr_files_count !=0 ?participantMatrixData.participant_matrix.pmr_files_count:"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Disease History File:</b>
                                                    <span *ngIf="participantMatrixData && participantMatrixData.participant_matrix">{{participantMatrixData.participant_matrix.pmh_files_count !=0 ?participantMatrixData.participant_matrix.pmh_files_count:"-"}}</span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-lg-4">
                                            <div class="site-info-wrape">
                                              <div class="card-wrape">
                                                <div class="card-head">
                                                  <h4 class="left-text">Time Taken to Fill Module </h4>
                                                  <span class="right-text">Total time: {{ totalTimeTaken && totalTimeTaken!=0 ? totalTimeTaken + ' Min' : '-' }} </span>
                                                </div>
                                                <div class="card-body">
                                                  <p>
                                                    <b>PI:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.pi_total_time_taken ?participantTimeTaken.pi_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Disease History:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.di_total_time_taken ?participantTimeTaken.di_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Medication History:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.medication_total_time_taken ?participantTimeTaken.medication_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Surgeries:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.ops_total_time_taken ?participantTimeTaken.ops_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Allergies/Drug Reactions:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.adr_total_time_taken ?participantTimeTaken.adr_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>Recreational Habits:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.rh_total_time_taken ?participantTimeTaken.rh_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <!-- Physiology Measurement: was known as Clinial BioChemical Data -->
                                                  <!-- <p>
                                                    <b>Physiology Measurement:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.cbd_total_time_taken ?participantTimeTaken.cbd_total_time_taken+" Min":"-"}}</span>
                                                  </p> -->
                                                  <!-- Medical Report: was known as PMR (Past Medical Records) -->
                                                  <p>
                                                    <b>Medical Record:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.pmr_total_time_taken ?participantTimeTaken.pmr_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>FMH:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.fmh_total_time_taken ?participantTimeTaken.fmh_total_time_taken+" Min":"-"}}</span>
                                                  </p>
                                                  <!-- <p>
                                                    <b>STI:</b>
                                                    <span>{{participantTimeTaken && participantTimeTaken.sti_total_time_taken ?participantTimeTaken.sti_total_time_taken+" Min":"-"}}</span>
                                                  </p> -->
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="col-lg-4">
                                            <div class="site-info-wrape">
                                              <div class="card-wrape">
                                                <div class="card-head">
                                                  <h4 class="left-text">Device Details</h4>
                                                </div>
                                                <div class="card-body">
                                                  <p>
                                                    <b>Device Used:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.participant_matrix?(participantMatrixData.participant_matrix.device_used?participantMatrixData.participant_matrix.device_used:"-"):"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>OS Type:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.participant_matrix?(participantMatrixData.participant_matrix.os_type?participantMatrixData.participant_matrix.os_type:"-"):"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>OS Version:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.participant_matrix?(participantMatrixData.participant_matrix.os_version?participantMatrixData.participant_matrix.os_version:"-"):"-"}}</span>
                                                  </p>
                                                  <p>
                                                    <b>PHENO App Version:</b>
                                                    <span>{{participantMatrixData && participantMatrixData.participant_matrix?(participantMatrixData.participant_matrix.ggcpheno_app_version?participantMatrixData.participant_matrix.ggcpheno_app_version:"-"):"-"}}</span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </section>
                          </mat-tab>
                          <!-- tab 2 end here -->
                      </mat-tab-group>
                  </div>
              </div>
          </div>
      </div>
  </section>
