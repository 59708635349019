
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhysiologicalMeasurementValidationService extends CrudService {

  constructor(public override http: HttpClient) {
    super('physiological-measurements-validation', http);
  }

  /**
     * @author Dhaval Bera
     * @param formData
     */
  getAllPMValidations(pageNo: any, sortField: string, sortOrder: string, globalFilter: string, rows: any, pm_flags: any, siteCodes: any) {

    let params: FormData = new FormData();
    if (!Number.isNaN(pageNo) && pageNo != null) {
      params.append("page", pageNo);
    }

    if (sortField !== undefined) {
      params.append("sortField", sortField);
      params.append("sortOrder", sortOrder);
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      params.append("filters[global]", globalFilter);
    }
    if (pm_flags !== null && pm_flags !== undefined && pm_flags != '') {
      params.append("pm_flags", pm_flags);
    }
    if (siteCodes !== null && siteCodes !== undefined && siteCodes != '') {
      params.append("siteCodes", siteCodes);
    }

    if (rows !== 0) {
      params.append("perPage", rows);
    }
    return this.http.post(`${this.baseUrl}/list`, params)
      .pipe(catchError(this.handleError));
  }

  /**
   * This function is used to Make QC
   * @param id
   */
  getPMValidationStatistics() {
    return this.http.get(this.baseUrl + '/get-statistics');
  }
  /**
  * This function is used to Make QC
  * @param formData
  */
  updatePMValidationStatus(formData: any) {
    return this.http.post(this.baseUrl + '/update-status', formData);
  }

}
