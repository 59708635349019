import { Component, OnInit } from '@angular/core';

import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { Disease } from 'src/app/interfaces/Disease';
import { CommonService } from 'src/app/service/common.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { OrganSiteService } from 'src/app/service/organ-site.service';

@Component({
  selector: 'app-organ-site-form',
  templateUrl: './organ-site-form.component.html',
  styleUrls: ['./organ-site-form.component.scss'],
})
export class OrganSiteFormComponent implements OnInit {
  public disease = new FormControl('');
  public diseases: Disease[] = [];
  public diseasesControl = new FormControl([]);
  public title: any;
  public loading: Boolean = false;
  public userId: any = '';
  public organSiteId: any = '';
  public buttonText: string;
  public organSiteForm!: FormGroup;
  public nameMinLength: number = 3;
  public disableSubmitBtn: boolean = false;
  public isAddMode: boolean = true;
  public organSiteDetails: any = [];
  public selectedDiseaseList: any = [];

  constructor(
    private organSiteService: OrganSiteService,
    private diseaseService: DiseaseService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.organSiteId = this.route.snapshot.paramMap.get('id');

    if (this.organSiteId !== 0 && this.organSiteId !== null) {
      this.setEditData();
      this.title = 'Edit Organ/Site';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add Organ/Site';
      this.buttonText = 'Add';
      this.organSiteId = 0;
    }
  }

  ngOnInit() {
    this.isAddMode = !this.organSiteId;
    this.userId = localStorage.getItem('userId');
    this.getDiseases();

    this.organSiteForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      diseaseId: [null, [Validators.required]],
      description: ['', []],
    });
  }

  /**
   * Add and update organ site records
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  onSubmit() {
    if (this.organSiteForm.invalid) {
      this.commonService.validateAllFormFields(this.organSiteForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    
    formData.append('name', this.organSiteForm.controls['name'].value);
    formData.append(
      'disease_id',
      this.organSiteForm.controls['diseaseId'].value
    );
    formData.append(
      'description',
      this.organSiteForm.controls['description'].value
    );

    if (this.organSiteId === 0) {
      formData.append('created_by', this.userId);
    } else {
      formData.append('updated_by', this.userId);
    }
    if (this.isAddMode) {
      this.createOrganSite(formData);
    } else {
      this.updateOrganSite(formData);
    }
  }

  /**
   * call Create Organ site api
   * @returns null
   */
  private createOrganSite(formData: any): void {
    this.organSiteService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast('Organ site added successfully');
          this.router.navigate(['/dictionary-management/organ-site']);
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (err: any) => {
        console.log('error from create Organ site', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * call Update Organ Site api
   * @returns null
   */
  private updateOrganSite(formData: any): void {
    this.organSiteService
      .update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(
              'Organ site updated successfully'
            );
            this.router.navigate(['/dictionary-management/organ-site']);
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err: any) => {
          this.disableSubmitBtn = false;
          console.log('error from update country', err);
        },
      });
  }

  setEditData() {
    this.organSiteService.getRow(this.organSiteId).subscribe((result: any) => {
      if (result.status !== AppConstants.serverSuccessStatus) {
        this.router.navigate(['page-not-found']);
      }
      this.organSiteDetails = result['data'];
      let diseasesIds: any[] = [];
      this.organSiteDetails.diseases.forEach((res: any) => {
        this.selectedDiseaseList.push(res.name);
        diseasesIds.push(res.id);
      });
      this.organSiteForm.patchValue({
        name: this.organSiteDetails.name,
        description: this.organSiteDetails.description,
        diseaseId: diseasesIds,
      });
    });
  }

  /**
   * Check Organ/site field is existed or not
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  checkUniqueRecord(event: any) {
    let name = (event.target as HTMLInputElement).value;
    if (name !== '' && name.length > 3 && name.length < 255) {
      this.organSiteService.checkUniqueName(name, this.organSiteId).subscribe({
        next: (result: any) => {
          if (result.status === 1 || result.status === 200) {
            this.organSiteForm.controls['name'].setErrors(null);
          } else {
            this.organSiteForm.controls['name'].setErrors({
              unique: 'Organ/site name already exists',
            });
          }
        },
        error: (error: any) => {
          if (error.status === 400) {
            this.organSiteForm.controls['name'].setErrors({
              unique: 'Organ/site name already exists',
            });
          } else {
            this.commonService.showErrorToast(
              'Something went wrong. Please contact to administrator.'
            );
          }
        },
      });
    }
  }

  /**
   * Get Diseases
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  getDiseases() {
    this.diseaseService.getActiveList().subscribe((result: any) => {
      this.diseases = result.data;
    });
  }
}
