import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ParticipantService } from 'src/app/service/participant.service';
import { LoincCodeService } from 'src/app/service/loinc-code.service';
import { CommonService } from 'src/app/service/common.service';
import { LoincCodeElement } from 'src/app/interfaces/loinc-code';
import { MenuService } from 'src/app/service/menu.service';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { AppConstants } from 'src/app/app.constant';

const ELEMENT_DATA: LoincCodeElement[] = [];

@Component({
  selector: 'app-loinc-codes',
  templateUrl: './loinc-codes.component.html',
  styleUrls: ['./loinc-codes.component.scss']
})
export class ManageLoincCodesComponent implements OnInit {

    public htrFilterForm!: FormGroup;
  
    public displayedColumns: string[] = [
      'id',
      'diagnostic_lab',
      'lab_code',
      'hba1c',
      'glucose',
      'action'
    ];
  
    public dataSource = new MatTableDataSource<LoincCodeElement>(ELEMENT_DATA);
    public totalRows = 0;
    public pageSize = 10;
    public currentPage = 0;
    public filterValue = '';
    public sortField = '';
    public sortOrder = '';
    public pageSizeOptions: number[] = [10, 25, 50, 100];
    public currentMenuPermission: any;
  
    public show_qc_options = false;
    public hide_review_options = true;
    public filterData: any;
  
    constructor(
      public participantService: ParticipantService,
      public loincCodeService: LoincCodeService,
      private menuService: MenuService,
      public commonService: CommonService,
      protected router: Router
    ) {
    }
  
    @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort!: MatSort;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  
    ngOnInit(): void {
  
      this.getAllLoincCode();
  
      this.menuService.currentMenu.subscribe((currentMenu) => {
        this.currentMenuPermission = currentMenu;
      });
    }
  
    searchFilter(event: Event) {
      this.filterValue = (event.target as HTMLInputElement).value;
      this.currentPage = 0;
      this.getAllLoincCode();
    }
  
    SortFilter(sortState: Sort) {
      if (sortState.active !== '' && sortState.direction !== '') {
        this.sortField = sortState.active;
        if (sortState.active == 'created_date_formatted') {
          this.sortField = 'created_at';
        }
        this.sortOrder = sortState.direction;
        this.getAllLoincCode();
      }
    }
  
    pageChanged(event: PageEvent) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.getAllLoincCode();
    }
  
    /**
     * This function is user to get all User Listing
     * @author Dhaval Bera
     */
    getAllLoincCode() {
      
      this.loincCodeService
        .getRecordByFilter(
          this.currentPage + 1,
          this.sortField,
          this.sortOrder,
          this.filterValue,
          this.pageSize,
        )
        .subscribe({
          next: (result: any) => {
            // console.log(result.data.data);
            this.dataSource = new MatTableDataSource(result.data.data);
            this.dataSource.paginator = this.paginator;
            setTimeout(() => {
              this.paginator.pageIndex = this.currentPage;
              this.paginator.length = result.data.total;
            });
          },
          error: (e: any) => {
            console.log('Loinc Code Listing err :: ', e);
          },
          complete: () => { },
        });
    }

    /**
   * call Create Organ site api
   * @returns null
   */
  deleteLoincCode(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Loinc Code?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.loincCodeService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                result.message
              );
              this.getAllLoincCode();
            } else {
              this.commonService.showErrorToast(
                result.message
              );
            }
          },
          error: (e: any) => {
            this.commonService.showErrorToast('Loinc Code not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  }
  