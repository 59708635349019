import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import {map, startWith} from 'rxjs/operators';
import { HtrService } from 'src/app/service/htr.service';
import { AppConstants } from 'src/app/app.constant';

@Component({
  selector: 'app-htr-import',
  templateUrl: './htr-import.component.html',
  styleUrls: ['./htr-import.component.scss']
})
export class HtrImportComponent implements OnInit {

  public htrImportForm!: FormGroup;
  public htrFile!: File;
  public selectedFileName: string = '';
  htrFileType: any = [
    {label: 'GGC Template', value: 'ggc'},
    {label: 'Lal Path Template', value: 'lalpath'},
    {label: 'SRL Template', value: 'srl'},
  ];

  constructor(
    public commonService: CommonService,
    protected router: Router,
    protected htrService: HtrService,
  ) { }

  /**
   * Validate form fields recursivly
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
   validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  ngOnInit(): void {

    this.htrImportForm = new FormGroup({
      htr_file: new FormControl('', [Validators.required]),
      fileTypes: new FormControl(null, [Validators.required]),
    });
  }

  onSelectHtrFile(event: any) {

    this.htrFile = event.target.files[0];
    
    this.selectedFileName = this.htrFile.name;

    if (
      (this.htrFile !== undefined) 
      && (
          this.htrFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        )
      ) {
        
        this.htrImportForm.controls['htr_file'].setErrors({ 'fileInvalidType': 'Please select a valid file' });
      // this.toastr.danger('Please fill all the fields', 'OOPS!');
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    } else if((this.htrFile !== undefined) && ((this.htrFile.size / 1024 / 1024) > 10)) {

      this.htrImportForm.controls['htr_file'].setErrors({ 'fileInvalidSize': 'Files size limit exceeded. Max file size is 10 MB' });
      // this.htrImportForm.controls['htr_file'].setErrors({ 'fileInvalidSize': 'Files size limit exceeded. Max file size is 10 MB' });
      // this.toastr.danger('Please fill all the fields', 'OOPS!');
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    }
}

  onSubmit(){

    if (this.htrImportForm.invalid) {
      this.validateAllFormFields(this.htrImportForm);
      return;
    }



    if (
      (this.htrFile !== undefined) 
      && 
      (
        this.htrFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
      ) 
    ) {
      this.htrImportForm.controls['htr_file'].setErrors({ 'fileInvalidType': 'Please select a valid file' });
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    } else if ((this.htrFile !== undefined) && ((this.htrFile.size / 1024 / 1024) > 10)) {
      this.htrImportForm.controls['htr_file'].setErrors({ 'fileInvalidSize': 'Files size limit exceeded. Max file size is 10 MB' });
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    }

    const formData: FormData = new FormData();
    formData.append('htr_data_file', this.htrFile, this.htrFile.name);
    formData.append('htrFileType', this.htrImportForm.controls['fileTypes'].value);

    this.htrService.importHtrData(formData)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(["/htr"]);
          this.commonService.showSuccessToast(
            "HTR data imported successfully!"
          );
        }else{

          this.htrImportForm.controls['fileTypes'].setErrors({ 'serverError': result.message });
          // this.commonService.showErrorToast(
          //   result.message
          // );
        }
      },
      error:(err: any) => {
        console.log(err);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  
    
  }
}
