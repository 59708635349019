<div class="login-wrape">
  <div class="card-wrape">
    <div class="card-head">
      <h4 class="left-text">Invalid Token</h4>
    </div>
    <div class="error-wrap">
      <h4>Register token has been expired. Please contact your administrator via email</h4>
    </div>
  </div>
</div>
