import { filter } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2'
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { AllergicToWhatService } from 'src/app/service/allergic-to-what.service';
import { AllergicReactionService } from 'src/app/service/allergic-reaction.service';

export interface AllergyTable {
  allergy_type: string
  // allergy_description: string
  allergy_description_name: string
  // allergic_reaction: string
  allergic_reaction_name: string
  action: any
}

let ELEMENT_DATA: AllergyTable[] = [];

@Component({
  selector: 'app-allergies-drug-reactions',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss']
})
export class AllergiesComponent implements OnInit {

  @Input() currentMenuPermission: any;

  public PID: any;
  public allergyDetails: any = [];
  public allergyForm!: FormGroup;
  public allergyDetailForm!: FormGroup;
  public allergyArray!: FormArray;
  public allergyFormStatus: boolean = false;
  public allergyUpdate: boolean = false;
  public allergyAdd: boolean = false;
  public allergyUpdateIndex: any;
  public allergyTypes: any;
  public loading: boolean = true;
  public allergyDescriptionList: any = [];
  public allergyReactionList: any = [];
  togglepopup: boolean = false;

  constructor(
    private participantService: ParticipantService,
    private allergicToWhatService: AllergicToWhatService,
    private allergicReactionService: AllergicReactionService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  displayedColumns: string[] = ['allergy_type', 'allergy_description_name', 'allergic_reaction_name', 'action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  ngOnInit(): void {

    let allergyTypes = JSON.parse(localStorage.getItem('constants') as string);
    this.allergyTypes = allergyTypes['ALLERGY_TYPES'];

    this.allergyForm = new FormGroup({});
    this.allergyForm.setControl('option', new FormControl(null, [Validators.required]));
    this.allergyForm.setControl('details', new FormArray([]));
    this.allergyArray = <FormArray>this.allergyForm.controls["details"];

    this.allergyDetailForm = this.formBuilder.group({
      allergy_type: [null, [Validators.required]],
      // allergy_description: [null, [Validators.required]],
      allergy_description: [{ value: null, disabled: true }, Validators.required],
      allergy_description_name: [null, []],
      allergic_reaction: [null, []],
      allergic_reaction_name: [null, []],
    });
    this.getAllergy();
  }

  onChangeAllergyType(event: any) {
    this.allergyDescriptionList = [];
    if (this.allergyDetailForm.controls['allergy_type'].value !== '') {
      this.allergyDetailForm.controls['allergy_description'].setValue(null);
      this.allergyDetailForm.controls['allergy_description'].enable();
    }
  }
  searchAllergicToWhat(event: any) {
    let fieldValue = event.term;
    if (fieldValue !== undefined && fieldValue.length >= 3) {
      this.loading = true;
      this.allergicToWhatService.getActiveList(fieldValue, this.allergyDetailForm.controls['allergy_type'].value).subscribe({
        next: (result: any) => {
          this.allergyDescriptionList = result.data;
          if (this.allergyDescriptionList.length === 0) {
            this.loading = false;
            return;
          }
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.log('Allergic To What err :: ', e);
        }
      })
    }
  }
  searchAllergicReaction(event: any) {
    let fieldValue = event.term;
    if (fieldValue !== undefined && fieldValue.length >= 3) {
      this.loading = true;
      this.allergicReactionService.getActiveList(fieldValue).subscribe({
        next: (result: any) => {
          this.allergyReactionList = result.data;
          if (this.allergyReactionList.length === 0) {
            this.loading = false;
            return;
          }
          this.loading = false;
        }, error: (e) => {
          this.loading = false;
          console.log('Allergic Reaction err :: ', e);
        }
      })
    }
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to get Surgery and set Value
   */
  getAllergy() {
    this.loading = true;
    this.participantService.getAllergy(this.PID).subscribe({
      next: (result: any) => {
        this.allergyDetails = result.data;
        if (this.allergyDetails.length === 0) {
          this.loading = false;
          return;
        }
        this.allergyForm.controls['option'].setValue(this.allergyDetails?.option);
        this.dataSource = new MatTableDataSource(result.data.details);
        this.allergyDetails.details.forEach((allergy: any) => {
          this.allergyArray.push(
            new FormGroup({
              allergy_type: new FormControl(allergy.allergy_type),
              allergy_description: new FormControl(allergy.allergy_description),
              allergy_description_name: new FormControl(allergy.allergy_description_name),
              allergic_reaction: new FormControl(allergy.allergic_reaction),
              allergic_reaction_name: new FormControl(allergy.allergic_reaction_name),
            })
          );
        });
        this.loading = false;
      }, error: (e) => {
        this.loading = false;
        console.log('Surgery err :: ', e);
      }
    })
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to add Surgery and set value
   */
  addAllergy() {
    if (this.allergyDetailForm.invalid) {
      this.commonService.validateAllFormFields(this.allergyDetailForm);
      return;
    }
    const allergy_description_name = this.allergyDetailForm.controls['allergy_description']?.value.map((pair: { allergic_to_what: any; }) => pair.allergic_to_what);
    const allergic_reaction_name = this.allergyDetailForm.controls['allergic_reaction'].value != null ? this.allergyDetailForm.controls['allergic_reaction']?.value.map((pair: { allergic_reaction_symptoms: any; }) => pair.allergic_reaction_symptoms) : [];


    const addForm = this.allergyForm.get('details') as FormArray;
    let newFormGroup = new FormGroup({
      allergy_type: new FormControl(this.allergyDetailForm.controls['allergy_type']?.value !== "" ? this.allergyDetailForm.controls['allergy_type']?.value : null, []),
      allergy_description: new FormControl(this.allergyDetailForm.controls['allergy_description']?.value !== "" ? this.allergyDetailForm.controls['allergy_description']?.value : null, []),
      allergy_description_name: new FormControl(this.allergyDetailForm.controls['allergy_description']?.value !== "" ? allergy_description_name.join(', ') : null, []),
      allergic_reaction: new FormControl(this.allergyDetailForm.controls['allergic_reaction']?.value !== "" ? this.allergyDetailForm.controls['allergic_reaction']?.value : null, []),
      allergic_reaction_name: new FormControl(this.allergyDetailForm.controls['allergic_reaction'].value != null ? allergic_reaction_name.join(', ') : null, []),
    });
    addForm.push(newFormGroup);
    this.dataSource = new MatTableDataSource(this.allergyForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.allergyAdd = true;
    this.allergyUpdate = false;
    this.allergyDetailForm.reset();
  }


  allergyDescriptionChange(event: any) {
    if ((event != '') && (event != null)) {
      let displayTerm = ((event.allergic_to_what != '') && (event.allergic_to_what != null)) ? event.allergic_to_what : event.name;
      this.allergyDetailForm.controls['allergy_description_name'].setValue(displayTerm);
    }
  }
  allergyReactionChange(event: any) {
    if ((event != '') && (event != null)) {
      let displayTerm = ((event.allergic_reaction_symptoms != '') && (event.allergic_reaction_symptoms != null)) ? event.allergic_reaction_symptoms : event.name;
      this.allergyDetailForm.controls['allergic_reaction_name'].setValue(displayTerm);
    }
  }


  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to update Medication update form value
   */
  updateAllergy() {
    if (this.allergyDetailForm.invalid) {
      this.commonService.validateAllFormFields(this.allergyDetailForm);
      return;
    }
    const allergy_description_name = this.allergyDetailForm.controls['allergy_description']?.value.map((pair: { allergic_to_what: any; }) => pair.allergic_to_what);
    const allergic_reaction_name = this.allergyDetailForm.controls['allergic_reaction']?.value.map((pair: { allergic_reaction_symptoms: any; }) => pair.allergic_reaction_symptoms);

    const updateForm = (<FormArray>this.allergyForm.get("details")).at(this.allergyUpdateIndex);
    updateForm.patchValue({
      allergy_type: this.allergyDetailForm.controls['allergy_type']?.value,
      allergy_description: this.allergyDetailForm.controls['allergy_description']?.value,
      allergy_description_name: allergy_description_name.join(', '),
      allergic_reaction: this.allergyDetailForm.controls['allergic_reaction']?.value,
      allergic_reaction_name: allergic_reaction_name.join(', '),
    });
    this.dataSource = new MatTableDataSource(this.allergyForm.controls['details']?.value);
    this.togglepopup = !this.togglepopup;
    this.allergyAdd = false;
    this.allergyDetailForm.reset();

  }

  addTagFn(name: any) {
    return { allergic_to_what: name, id: 0 };
  }
  addTagFnReaction(name: any) {
    return { allergic_reaction_symptoms: name, id: 0 };
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This funtion is used to get updated value based on index
   * @param id
   */
  editAllergy(id: any) {
    this.allergyUpdateIndex = id;
    this.allergyUpdate = true;
    this.togglepopup = !this.togglepopup;


    const allergyType = (<FormArray>this.allergyForm.get("details")).at(id).get('allergy_type')?.value;
    const allergyDescription = (<FormArray>this.allergyForm.get("details")).at(id).get('allergy_description')?.value;
    const allergyDescriptionName = (<FormArray>this.allergyForm.get("details")).at(id).get('allergy_description')?.value;
    const allergicReaction = (<FormArray>this.allergyForm.get("details")).at(id).get('allergic_reaction')?.value;
    const allergicReactionName = (<FormArray>this.allergyForm.get("details")).at(id).get('allergic_reaction_name')?.value;


    if (allergyDescription != 0 || allergyDescription != null || allergyDescription != '') {
      allergyDescription.forEach((element: any) => {
        this.allergyDescriptionList.push(element);
      });
      this.allergyDescriptionList = this.allergyDescriptionList.slice(0);
    }

    if (allergicReaction != 0 || allergicReaction != null || allergicReaction != '') {
      allergicReaction.forEach((element: any) => {
        this.allergyReactionList.push(element);
      });
      this.allergyReactionList = this.allergyReactionList.slice(0);
    }

    // console.log(allergyType, allergyDescription, allergicReaction);
    this.allergyDetailForm.controls['allergy_type'].setValue(allergyType);
    this.allergyDetailForm.controls['allergy_description'].setValue(allergyDescription);
    this.allergyDetailForm.controls['allergy_description_name'].setValue(allergyDescriptionName);
    this.allergyDetailForm.controls['allergic_reaction'].setValue(allergicReaction);
    this.allergyDetailForm.controls['allergic_reaction_name'].setValue(allergicReactionName);

    if (this.allergyDetailForm.controls['allergy_type'].value !== '') {
      this.allergyDetailForm.controls['allergy_description'].enable();
      this.allergyDetailForm.controls['allergic_reaction'].enable();
    }
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function used to close and update value
  */
  closePopup() {
    this.allergyAdd = false;
    this.allergyUpdate = false;
    this.allergyUpdateIndex = null;
    this.togglepopup = !this.togglepopup;
    this.allergyDetailForm.reset();
  }

  // allergyInfoOption: string[] = ['Drug', 'Food', 'Environmental'];

  /**
    * @author Pramod Pramod Kushwaha
    * This function is used to delete element
    * @param option
    */
  allergyDelete(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Allergy?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // this.allergyUpdate = true;
        (<FormArray>this.allergyForm.get("details"))?.removeAt(id);
        this.dataSource = new MatTableDataSource(this.allergyForm.controls['details'].value);
        this.allergySubmit();
      }
      console.log(result);
    });
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form edit
  */
  clickOnEdit() {
    this.allergyFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close
  */
  clickOnCancel() {
    this.allergyFormStatus = false;
    this.allergyAdd = false;
    this.allergyUpdate = false;
    this.allergyUpdateIndex = null;
    this.allergyDetailForm.reset();
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to Open Surgery and hide Update button
  */
  openAllergy() {
    // this.allergyAdd = true;
    // this.allergyUpdate = false;
    this.togglepopup = !this.togglepopup;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to submit form to server side
  */
  allergySubmit() {
    console.log(this.allergyForm.value);
    this.loading = true;
    this.participantService.updateAllergy(this.allergyForm.value, this.PID).subscribe({
      next: (result: any) => {
        console.log(result);
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.allergyForm.setControl('details', new FormArray([]));
          this.allergyArray = <FormArray>this.allergyForm.controls["details"];
          this.getAllergy();
          this.allergyAdd = false;
          this.allergyUpdate = false;
          this.allergyUpdateIndex = null;
          this.allergyDetailForm.reset();
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to reset Form
   * @param option
   */
  resetForm(option: string) {
    console.log(option);
    this.allergyForm.setControl('details', new FormArray([]));
    this.dataSource = new MatTableDataSource(this.allergyForm.controls['details'].value);
    if (option == 'yes') {
      this.allergyAdd = true;
      this.togglepopup = !this.togglepopup;
    } else {
      this.allergyUpdate = true;
    }
    this.allergyUpdateIndex = null;
    this.allergyDetailForm.reset();
  }
}

