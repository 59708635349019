<div class="tabbing-content-wrape">
  <mat-spinner *ngIf="loading"></mat-spinner>
  <form [ngClass]="surgeryFormStatus ? 'edit-mode' : 'view-mode'" id="surgeryForm" [formGroup]="surgeryForm">
    <div class="container-fluid">
      <div class="card-wrape">
        <div class="card-body p-b-0">
          <div class="input-wrape title-controller">
            <mat-label>Did You Have Any Surgeries/Biopsies/Procedures?</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="option">
              <mat-radio-button value="yes" (click)="resetForm('yes')">Yes</mat-radio-button>
              <mat-radio-button value="no" (click)="resetForm('no')">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <button mat-raised-button class="btn add-btn" *ngIf="currentMenuPermission.create == true &&  surgeryForm.controls['option'].value === 'yes'"
            color="secoundary" [disabled]="surgeryUpdateIndex!=null || surgeryAdd || surgeryUpdate" (click)="openSurgery()" style="float: right;">Add</button>
        </div>
      </div>
    </div>

    <!-- no record found wrape start -->
    <div class="No record" *ngIf="surgeryForm.controls['option'].value === 'no'" class="no-record-pad">
      <img src="../../../../../../assets/images/edit-participant-no-data/surgery-no-data.svg" alt="Surgery">
      <span>No Past Surgeries/Biopsies/Procedures Found</span>
    </div>
    <!-- no record found wrape end -->

    <!-- popup code start  *ngIf="surgerySelectwrape == 'Yes'" -->
    <section class="popup-wrape success" [ngClass]="togglepopup ? 'success' : 'danger'" [formGroup]="surgeryDetailForm">
      <div class="overlays"></div>
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text"> Other Past Surgeries/Biopsies/Procedures</h4>
          <a href="javascript:;" class="popup-close-btn" (click)="closePopup()">×</a>
        </div>
        <div class="card-body">
          <div class="input-wrape">
            <mat-label>Type of Surgery/Biopsy/Procedures:</mat-label>
            <div class="control-pad">
                <ng-select [items]="surgeries" placeholder="Enter type of surgery/biopsy/procedures" bindLabel="name"
                    bindValue="id" formControlName="surgery_id" [addTag]="addTagFn"
                    (change)="surgerySelect($event)">
                </ng-select>
            </div>
            <!-- <mat-form-field appearance="outline">
              <input matInput #input placeholder="Enter type of surgery/biopsy/procedures" type="text"
                formControlName="type_of_surgery">
            </mat-form-field> -->
          </div>
          <div class="input-wrape">
            <mat-label>Year of Surgery/Biopsy/Procedures:</mat-label>
            <div class="control-pad">
              <ng-select placeholder="Select year" formControlName="year_of_surgery" [searchable]="false"> <ng-option
                  value="{{year}}" *ngFor="let year of getYearRanges()">{{year}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="input-wrape">
              <mat-label>Participant Data Declared Through: </mat-label>
              <div class="control-pad">
                  <ng-select formControlName="declared_through" bindLabel="label" bindValue="value"
                      [dropdownPosition]="'bottom'" [searchable]="true" [multiple]="false"
                      [closeOnSelect]="true" [items]="declared_through_options" placeholder="Participant Data Declared Through">
                  </ng-select>
              </div>
          </div>
        </div>
        <div class="card-footer">
          <button mat-raised-button class="btn" color="secoundary" *ngIf="!surgeryUpdate"
            [disabled]="!surgeryDetailForm.controls['type_of_surgery'].value" (click)="addSurgery()">Add</button>
          <button mat-raised-button class="btn" color="secoundary" *ngIf="surgeryUpdate"
            [disabled]="!surgeryDetailForm.controls['type_of_surgery'].value" (click)="updateSurgery()">Update</button>
        </div>
      </div>
    </section>
    <!-- popup code end -->

    <!-- Table start -->
    <div class="container-fluid" *ngIf="surgeryForm.controls['option'].value === 'yes'">
      <div class="row">
        <div class="col-xl-12">
          <div class="card-wrape">
            <div class="card-head">
              <h4 class="left-text">Other Past Surgeries/Biopsies/Procedures List</h4>
            </div>
            <div class="card-body">
              <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="type_of_surgery">
                  <mat-header-cell *matHeaderCellDef> Type of Surgery/Biopsy/Procedures </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.type_of_surgery }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="year_of_surgery">
                  <mat-header-cell *matHeaderCellDef> Year of Surgery/Biopsy/Procedures </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.year_of_surgery}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                  <mat-cell *matCellDef="let element; let i=index;">
                    <div class="icons-wrape">
                      <a (click)="editSurgery(i)" class="theme-icon edit-icon" title="Edit Surgery" *ngIf="currentMenuPermission.edit == true"></a>
                      <a (click)="surgeryDelete(i)" class="theme-icon delete-icon edit-surgery-mode" title="Delete Surgery" *ngIf="currentMenuPermission.delete == true"></a>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <div *matNoDataRow>No records found</div>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Table end -->

    <div class="container-fluid">
      <!-- no record found start -->
      <div class="no-record-wrape" style="display: none;">
        <h4>No Medical Record Found </h4>
      </div>
      <!-- no record found end -->
    </div>
    <div class="stic-form-btn-wrape" *ngIf="currentMenuPermission?.create == true || currentMenuPermission?.edit == true">
      <button type="button" (click)="clickOnEdit()" *ngIf="!surgeryFormStatus" mat-raised-button class="btn"
        color="secoundary">Edit</button>
      <button type="button" (click)="clickOnCancel()" *ngIf="surgeryFormStatus" mat-raised-button class="btn"
        color="warn">Cancel</button>
      <button type="button" (click)="surgerySubmit()"  [disabled]="(!surgeryAdd && !surgeryUpdate) || loading" *ngIf="surgeryFormStatus" mat-raised-button class="btn"
        color="secoundary">Save</button>
    </div>
  </form>
</div>
