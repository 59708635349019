import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class ParticipantDataFilterService extends CrudService {

  constructor(public override http: HttpClient) {
    super('', http);
  }

  /**
   * @author Jaydeep Patel
   * This function is Used Filer Participant Data
   * @param id
   */
   filterParticipant(formData: any) {
    return this.http.post(`${this.cronUrl}participant-module-export`, formData);
  }
}
