import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { GenderValidationService } from 'src/app/service/gender.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-gender-validation',
  templateUrl: './gender-validation.component.html',
  styleUrls: ['./gender-validation.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GenderValidationComponent implements OnInit {

  public title: string = 'Gender Validation';
  public lastThreeDaysData: any = [];
  public genderValidationFilters: any = [
    { label: 'Stage 1: Validation Pending', value: 'pending' },
    { label: 'Stage 2: Review Pending', value: 'review_pending' },
    { label: 'Validated Data', value: 'validated' },
  ];
  public genderTypes: any = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'TransGender', value: 'transgender' },
    { label: 'Prefer Not To Say', value: 'prefer not to say' },
  ];

  public flag: any = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Review Pending', value: 'REVIEW_PENDING' },
    { label: 'Validated', value: 'VALIDATED' },
  ];
  public reclassifiedGenderFilters: any;
  public selectedGenderValidationFilter: string = 'pending';
  public selectedStage = 'pending';
  public dataSources = new MatTableDataSource<GenderTable>(ELEMENT_DATA);
  public expandedElement: GenderTable | undefined;
  public columnsToDisplay = 
  [ 'select',
    'participant_id',
    'new_pid',
    'new_recollection_id',
    'site_code',
    'first_name',
    'middle_name',
    'last_name',
    'gender',
    'collection_date',
  ];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public tableData: any = {}
  public loading: boolean = true;
  public totalRecord: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  selectedParticipants = new SelectionModel<GenderTable>(true, []);
  public matNoDataRowTitle: string = 'Loading Data...';
  public currentMenuPermission: any;

  constructor(
    private genderValidationService: GenderValidationService,
    private commonService: CommonService,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });

    this.getValidationStats();
    this.getAllGenderValidations();
  }


  /**
   * It will search Participant
   * @param event
   */
  getAllGenderValidations() {
    this.matNoDataRowTitle = 'Loading Data...';
    this.selectedParticipants = new SelectionModel<GenderTable>(true, []);
    let gender_flags = '';
    let siteCodes = '';
    if (this.reclassifiedGenderFilters !== undefined) {
      gender_flags = this.reclassifiedGenderFilters.value;
    }

    this.loading = true;
    this.genderValidationService.getAllGenderValidations(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      gender_flags,
      siteCodes
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        result.data.data.forEach((res: any) => {
          res.validated_gender = (res.validated_gender == null) ? res.gender : res.validated_gender;
        })
        this.dataSources = new MatTableDataSource(result.data.data);
        this.totalRecord = result.data.data.length;
        this.dataSources.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = result.data.total;
        });
        if (this.dataSources != undefined && this.dataSources.data.length == 0) {
          this.matNoDataRowTitle = 'No Record Found';
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }


  isAllSelected() {
    const numSelected = this.selectedParticipants.selected.length;
    const numRows = this.dataSources.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selectedParticipants.clear() :
      this.dataSources.data.forEach(row => this.selectedParticipants.select(row));
  }


  /**
   * Get Reclassification Stats
   *
   * @returns object
   */
  getValidationStats() {
    this.genderValidationService.getGenderValidationStatistics()
      .subscribe({
        next: (result: any) => {
          this.lastThreeDaysData = result.data;
        }
      });
  }

  updateReviewed(event: any) {
    let validatedGenderStatus = event;
    if (this.selectedParticipants.selected.length <= 0) {
      this.commonService.showErrorToast('Please select at least one Participant');
      return;
    }
    this.loading = true;

    let participant_ids: any[] = [];
    this.selectedParticipants.selected.forEach((res: any) => {
      participant_ids.push(res.participant_id);
    })
    this.genderValidationService.updateReviewed(participant_ids, validatedGenderStatus).subscribe({
      next: (result: any) => {
        this.loading = false;
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast(validatedGenderStatus == 'validated' ? 'Selected records marked as Reviewed' : 'Selected records moved to Review Pending');
          this.getValidationStats();
          this.getAllGenderValidations();
        }else{
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error: () => {
        this.loading = false;
        this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
      }
    });
  }

  /**
  * Get filtered values
  * @param event
  */
  changeGenderFilter(event: any) {
    if (event != undefined) {
      this.selectedStage = event.value;
      this.reclassifiedGenderFilters = event;
    } else {
      this.selectedStage = '';
      this.reclassifiedGenderFilters = '';
    }
    this.getAllGenderValidations();
  }
  
  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAllGenderValidations();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllGenderValidations();
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllGenderValidations();
  }

  clickEventTableForm(event: any) {
    this.dataSources.data.find(res => {
      if (res.participant_id == event.participant_id) {
        res.editing = true;
      }
    })
  }
  cancelEvent(event: any) {
    this.dataSources.data.find(res => {
      if (res.participant_id == event.participant_id) {
        res.editing = false;
      }
    })
  }
  saveEvent(genderValidations: any) {
    if (genderValidations.participant_id !== '') {
      if(!genderValidations.validated_gender){
        this.commonService.showErrorToast('Please select Validated Gender');
        return;
      }
      if(!genderValidations.validated_gender_status){
        this.commonService.showErrorToast('Please select Flag');
        return;
      }
      
      this.loading = true;
      this.genderValidationService.updateGenderValidationStatus(genderValidations).subscribe({
        next: (result: any) => {
          this.loading = false;
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast('Data has been updated successfully');
            this.getValidationStats();
            this.getAllGenderValidations();
          }else{
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: () => {
          this.loading = false;
          this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        }
      });
    } else {
      this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
    }
  }

  // changeFlag(ele: any) {
  //   if (ele.validated_gender_status == "PENDING") {
  //       ele.validated_gender = null;
  //       // ele.ysts_gender = null;
  //   }
  // }

}
export interface GenderTable {
  select: any;
  participant_id: any;
  editing: boolean;
  new_pid: string;
  new_recollection_id: string;
  site_code: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  age: number;
  collection_date: string;
}
const ELEMENT_DATA: GenderTable[] = [];
