<div class="tabbing-content-wrape">
  <div class="head-bg-img"></div>
  <mat-spinner *ngIf="loading"></mat-spinner>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 m-b-10">
        <div class="head-bg-wrape">
          <div class="upload-img-wrape">
            <div class="img-wrape">
              <span mat-button (click)="showProfile()" appNoRightClick>
                <img *ngIf="dashboardInformation?.id >= 50764" class="photo" src="{{dashboardInformation?.photo_thumb}}"
                  alt="Participant Profile Picture">

                <img *ngIf="dashboardInformation?.id <= 50764" class="photo" src="../assets/images/patient-pic-1.jpg"
                  alt="Participant Profile Picture">
              </span>
            </div>
            <div class="img-wrape bar-code-frame">
              <span mat-button (click)="showBarcode()" appNoRightClick>
                <img *ngIf="dashboardInformation?.id >= 50764" src="{{dashboardInformation?.photo_pin_thumb}}"
                  alt="Photo Pin"><img *ngIf="dashboardInformation?.id <= 50764"
                  src="../assets/images/sample-barcode.jpg" alt="Photo Pin" /></span>

            </div>
          </div>
        </div>

        <!-- right wrape start -->
        <div class="right-wrape">
          <div class="profile-infor">
            <h6>{{dashboardInformation?.full_name}}</h6>
            <p><span>DOB:</span> {{dashboardInformation?.date_of_birth}}</p>
            <p><span>Age:</span> {{dashboardInformation?.age}} Years</p>
            <p><span>Gender:</span> {{dashboardInformation?.gender | titlecase}}</p>
          </div>
          <div class="status-wrape">
            <div class="btn-detail">
              <div class="status" *ngIf="dashboardInformation?.is_phf_reviewed">
                <p>
                  <span>PHF Reviewed by {{ dashboardInformation?.reviewed_by }}</span>
                  ({{ dashboardInformation?.phf_reviewed_at }})
                </p>
              </div>
              <button mat-raised-button class="btn"
                *ngIf="!dashboardInformation?.is_phf_reviewed && currentMenuPermission.edit == true"
                (click)="markPHFReviewed(dashboardInformation?.id)" color="primary">
                Mark as PHF Reviewed
              </button>
            </div>
            <div class="btn-detail">
              <div class="status" *ngIf="dashboardInformation?.is_phf_qced">
                <p><span>PHF QC'ed by {{ dashboardInformation?.qced_by }}</span>
                  ({{ dashboardInformation?.phf_qced_at }})
                </p>
              </div>
              <button mat-raised-button class="btn" color="warn"
                *ngIf="dashboardInformation?.is_phf_qced && currentMenuPermission.edit == true"
                (click)="markQCRejected(dashboardInformation?.id)">Mark as PHF QC'ed Rejected</button>
              <button mat-raised-button class="btn" color="basic"
                *ngIf="!dashboardInformation?.is_phf_qced && currentMenuPermission.edit == true"
                (click)="markQCApproved(dashboardInformation?.id)">Mark as PHF QC'ed Approved</button>
            </div>


            <div class="other-details">
              <a routerLink="/lab-data/view/{{ dashboardInformation?.lab_data_id }}" target="_blank"
                [ngClass]="dashboardInformation?.lab_data_id === 0 ? 'disable' : ''" title="Lab Data"
                class="indi-icon lab-icons"></a>
              <div *ngIf="currentMenuPermission.view== true && currentMenuPermission.edit== false; else editHtr">
                <a routerLink="/htr/view/{{ dashboardInformation?.htr_informations_id }}" target="_blank"
                  [ngClass]="dashboardInformation?.htr_informations_id === 0 ? 'disable' : ''" title="HTR"
                  class="indi-icon report-icon"></a>
              </div>
              <ng-template #editHtr>
                <a routerLink="/htr/edit/{{ dashboardInformation?.htr_informations_id }}" target="_blank"
                  [ngClass]="dashboardInformation?.htr_informations_id === 0 ? 'disable' : ''" title="HTR"
                  class="indi-icon report-icon"></a>
              </ng-template>

              <span class="text-white">Reports Status:</span>
            </div>
          </div>
        </div>
        <!-- right wrape end -->
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> Participant Information - For Anuva</h4>
          </div>
          <div class="card-body p-0">
            <ul class="p-detail-wrape">
              <li class="detail">
                <b>New Participant ID</b> <span class="detail">{{dashboardInformation?.new_pid}}</span>
              </li>
              <li class="detail">
                <b>Core ID</b> <span class="detail">{{dashboardInformation?.id}}</span>
              </li>
              <li class="detail">
                <b>Site Code</b> <span class="detail">{{dashboardInformation?.site_code}}</span>
              </li>
              <li class="detail">
                <b>Site Name</b> <span class="detail">{{dashboardInformation?.site_name}}</span>
              </li>
              <li class="detail">
                <b>Collection Date</b> <span class="detail">{{dashboardInformation?.collation_date}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> Participant Information</h4>
            <div class="right-control">
              <a (click)="showPI()" title="More..">View All..</a>
            </div>
          </div>
          <div class="card-body p-0">
            <ul class="p-detail-wrape">
              <li class="detail">
                <b>Current Age</b> <span class="detail">{{dashboardInformation?.current_age}}
                  Years</span>
              </li>
              <li class="detail">
                <b>Phone Number</b> <span class="detail">+91
                  {{dashboardInformation?.phone_mobile}}</span>
              </li>
              <li class="detail">
                <b>Email Address</b> <span class="detail">{{dashboardInformation?.email}}</span>
              </li>
              <li class="detail">
                <b>New Recollection ID</b> <span class="detail"
                  *ngIf="dashboardInformation?.new_recollection_id == ''">N/A</span> <span class="detail"
                  *ngIf="dashboardInformation?.new_recollection_id != ''">{{dashboardInformation?.new_recollection_id}}</span>
              </li>
              <li class="detail">
                <b>Age at Sample Collection (T1)</b> <span class="detail">{{dashboardInformation?.age_collation_date}}
                  Year</span>
              </li>

              <!-- <li class="more"><a (click)="showPI()" title="More..">View All..</a></li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> Diesease History </h4>
            <div class="right-control">
              <a (click)="showDH()" title="More.." *ngIf="dashboardInformation?.diesease_history?.length >= 1">View
                All..</a>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="no-data-found" *ngIf="dashboardInformation?.diesease_history?.length == 0">
              <div class="no-data-container">
                <div class="no-data-icon"></div>
                <div class="no-data-text">NO DATA FOUND</div>
              </div>
            </div>
            <ul class="p-detail-wrape" *ngIf="dashboardInformation?.diesease_history?.length >= 1">
              <li class="detail"
                *ngFor="let disease of dashboardInformation?.diesease_history.slice(0, 5); index as i;">
                <b>{{disease?.display_term}}</b>
                <span class="detail">{{disease?.diagnose_year}}</span>
              </li>
              <!-- <li class="more" *ngIf="dashboardInformation?.diesease_history?.length >= 1">
                                <a (click)="showDH()" title="More..">View All..</a>
                            </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> Medication</h4>
            <div class="right-control">
              <a (click)="showMedication()" title="More.." *ngIf="dashboardInformation?.medications?.length >= 1">View
                All..</a>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="no-data-found" *ngIf="dashboardInformation?.medications?.length == 0">
              <div class="no-data-container">
                <div class="no-data-icon"></div>
                <div class="no-data-text">NO DATA FOUND</div>
              </div>
            </div>
            <ul class="p-detail-wrape">
              <li class="detail" *ngFor="let medication of dashboardInformation?.medications; let i = index">
                <b><span>Medication- {{i+1}}</span> Medication Name</b> <span class="detail"><br>
                  {{medication?.medication_name}} </span>
              </li>
              <!-- <li class="more" *ngIf="dashboardInformation?.medications?.length >= 1">
                                <a (click)="showMedication()" title="More..">View All..</a>
                            </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> Sample & Test Information</h4>
            <div class="right-control">
              <a (click)="showSTI()" title="More.."
                *ngIf="dashboardInformation?.sample_test_information?.length >= 1">View All..</a>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="no-data-found" *ngIf="dashboardInformation?.sample_test_information?.length == 0">
              <div class="no-data-container">
                <div class="no-data-icon"></div>
                <div class="no-data-text">NO DATA FOUND</div>
              </div>
            </div>
            <ul class="p-detail-wrape">
              <li class="detail" *ngFor="let test of dashboardInformation?.sample_test_information;">
                <b>
                  <span *ngIf="test?.type == 'anuva'">For Anuva</span>
                  <span *ngIf="test?.type == 'diagnostic_labs'">For Lab</span>
                  Sample Type<br> No. of Samples</b> <span class="detail"><br>{{test?.sample_type
                  |
                  titlecase}}<br>
                  {{test?.no_of_samples_collected}} {{test?.sample_unit_type}}</span>
              </li>
              <!-- <li class="more" *ngIf="dashboardInformation?.sample_test_information?.length >= 1">
                                <a (click)="showSTI()" title="More..">View All..</a>
                            </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text"> Counselors</h4>
          </div>
          <div class="card-body p-0">
            <ul class="p-detail-wrape">
              <li class="detail">
                <b>Counselor Name<br> Date and Time<br> Location</b> <span
                  class="detail">{{dashboardInformation?.counselor_name}}<br>
                  {{dashboardInformation?.date_time}}<br>
                  {{dashboardInformation?.counselor_location}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
