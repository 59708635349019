import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { PopulationReclassificationService } from 'src/app/service/population-reclassification.service';
import { SiteService } from 'src/app/service/site.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MenuService } from 'src/app/service/menu.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { PopulationService } from 'src/app/service/population.service';

@Component({
  selector: 'app-population-validation',
  templateUrl: './population-validation.component.html',
  styleUrls: ['./population-validation.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PopulationValidationComponent implements OnInit {

  public title: string = 'Population Re-Classification';
  public reclassificationStats: any = [];
  public reclassificationFilters: any = [
    { label: 'Stage 1: Re-classification Pending', value: 'pending' },
    { label: 'Stage 2: QC Pending', value: 'qc_pending' },
    { label: 'Stage 3: Review Pending', value: 'review_pending' },
    { label: 'Reviewed Data', value: 'reviewed' },
  ];
  public flag: any = [
    { label: 'Re-Classified', value: 'Re-Classified' },
    { label: 'No Need To Re-Classify', value: 'No Need To Re-Classify' },
    { label: 'Non Re-Classifiable', value: 'Non Re-Classifiable' },
    { label: 'Low Probability', value: 'Low Probability' },
  ];
  public reclassifiedPopulationFilters: any;
  public siteCodesFilter: any;
  public selectedPopulationFilter: string = 'pending';
  public sites: any = [];
  public selectedStage = '';
  public dataSources = new MatTableDataSource<PopulationTable>(ELEMENT_DATA);
  public expandedElement: PopulationTable | undefined;
  public columnsToDisplay = [
    'select',
    'new_pid',
    'site_code',
    'first_name',
    'last_name',
    'gender',
    'age',
  ];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public tableData: any = {}
  public loading: boolean = true;
  public totalRecord: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  selectedParticipants = new SelectionModel<PopulationTable>(true, []);
  public matNoDataRowTitle: string = 'Loading Data...';
  public currentMenuPermission: any;
  public religions: any = [];
  public states: any = [];
  public populations: any = [];

  constructor(
    private populationReclassificationService: PopulationReclassificationService,
    private siteService: SiteService,
    private commonService: CommonService,
    private menuService: MenuService,
    private populationService: PopulationService,
    private dictionaryService: DictionaryService
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });

    this.getReclassificationStats();
    this.getSites();
    this.getAllPopulation();
    this.getReligions();
    this.getPopulation();
    this.getStates();
  }


  /**
   * It will search Participant
   * @param event
   */
  getAllPopulation() {
    this.matNoDataRowTitle = 'Loading Data...';
    this.selectedParticipants = new SelectionModel<PopulationTable>(true, []);
    let phf_flags = '';
    let siteCodes = '';
    if (this.reclassifiedPopulationFilters !== undefined) {
      phf_flags = this.reclassifiedPopulationFilters.value;
    }
    if (this.siteCodesFilter !== undefined) {
      siteCodes = this.siteCodesFilter;
    }

    this.loading = true;
    this.populationReclassificationService.getAllPopulation(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      phf_flags,
      siteCodes
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.dataSources = new MatTableDataSource(result.data.data);
        this.totalRecord = result.data.data.length;
        this.dataSources.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = result.data.total;
        });
        if (this.dataSources != undefined && this.dataSources.data.length == 0) {
          this.matNoDataRowTitle = 'No Record Found';
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }


  isAllSelected() {
    const numSelected = this.selectedParticipants.selected.length;
    const numRows = this.dataSources.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selectedParticipants.clear() :
      this.dataSources.data.forEach(row => this.selectedParticipants.select(row));
  }


  /**
   * Get Reclassification Stats
   *
   * @returns object
   */
  getReclassificationStats() {
    this.populationReclassificationService.getReclassificationStatistics()
      .subscribe({
        next: (result: any) => {
          this.reclassificationStats = result.data;
        }
      });
  }
  /**
   * Get Site List
   *
   *
   * @returns object
   */
  getSites() {
    this.siteService.getAllList()
      .subscribe((result: any) => { this.sites = result.data; });
  }

  getReligions() {
    this.dictionaryService.getReligionList().subscribe({
      next: (result: any) => {
        this.religions = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  getStates() {
    this.dictionaryService.getStateList().subscribe({
      next: (result: any) => {
        this.states = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  getPopulation() {
    this.dictionaryService.getPopulationList().subscribe({
      next: (result: any) => {
        this.populations = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  searchSubpopulation(filterValue: any) {
    if (filterValue && filterValue.length > 2) {
      this.populationService.searchSubpopulation(filterValue).subscribe({
        next: (result: any) => {
          console.log(result.status);
          if (result.status == AppConstants.serverSuccessStatus && result.data) {
            this.populations = result.data;
            // if (result.data.length > 0) {
            //   this.participantForm.controls['population_id'].setValue(null);
            // }
          }
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
        complete: () => { },
      });
    }
  }

  updateQCed() {
    if (this.selectedParticipants.selected.length <= 0) {
      this.commonService.showErrorToast('Please select at least one Participant');
      return;
    }
    this.loading = true;

    let participant_ids: any[] = [];
    this.selectedParticipants.selected.forEach((res: any) => {
      participant_ids.push(res.participant_id);
    })
    this.populationReclassificationService.updateQCed(participant_ids).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.commonService.showSuccessToast('Selected records marked as QC\'ed');
        this.getAllPopulation();
      },
      error: () => {
        this.loading = false;
        this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
      }
    });
  }
  updateReviewed() {
    if (this.selectedParticipants.selected.length <= 0) {
      this.commonService.showErrorToast('Please select at least one Participant');
      return;
    }
    this.loading = true;

    let participant_ids: any[] = [];
    this.selectedParticipants.selected.forEach((res: any) => {
      participant_ids.push(res.participant_id);
    })
    this.populationReclassificationService.updateReviewed(participant_ids).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.commonService.showSuccessToast('Selected records marked as Reviewed');
        this.getAllPopulation();
      },
      error: () => {
        this.loading = false;
        this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
      }
    });
  }

  /**
  * Get filtered values
  * @param event
  */
  changePopulationFilter(event: any) {
    if (event != undefined) {
      this.selectedStage = event.value;
      this.reclassifiedPopulationFilters = event;
    } else {
      this.selectedStage = '';
      this.reclassifiedPopulationFilters = '';
    }
    this.getAllPopulation();
  }
  /**
  * Get filtered values
  * @param event
  */
  changeSiteCodeFilter() {
    this.getAllPopulation();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAllPopulation();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllPopulation();
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllPopulation();
  }

  clickEvent(event: any) {
    this.dataSources.data.find(res => {
      if (res.sr_no == event.sr_no) {
        res.editing = true;
      }
    })
  }
  cancelEvent(event: any) {
    this.dataSources.data.find(res => {
      if (res.sr_no == event.sr_no) {
        res.editing = false;
      }
    })
  }
  saveEvent(populations: any) {

    if (populations.participant_id !== '') {
      this.loading = true;
      this.populationReclassificationService.updateReclassificationStatus(populations).subscribe({
        next: (result: any) => {
          this.loading = false;
          if (populations.reclassified_pop_flag === 'Re-Classified') {
            populations.accountable_subpopulation = populations.reclassified_population;
          } else {
            populations.accountable_subpopulation = populations.sub_population;
          }
          this.commonService.showSuccessToast('Data has been updated successfully');
          this.getReclassificationStats();
          this.getAllPopulation();
        },
        error: () => {
          this.loading = false;
          this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        }
      });
    } else {
      this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
    }
  }

  changeFlag(ele: any) {
    if (ele.reclassified_pop_flag !== "Re-Classified") {
      ele.re_classified_native_state = null;
      ele.reclassified_population = null;
      ele.reclassified_religion_id = null;
    }
  }

}
export interface PopulationTable {
  select: any;
  sr_no: any;
  editing: boolean;
  site_code: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  age: number;
  native_city: string;
  religion: string;
  mother_tongue: string;
  collection_date: string;
  occupation_type: string;
  native_state: string;
  sub_population: string;
  accountable_subpop: string;
}
const ELEMENT_DATA: PopulationTable[] = [];
