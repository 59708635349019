import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class BioBankSequencingService extends CrudService {

  constructor(public override http: HttpClient) {
    super('bio-bank-sequencing', http);
  }

  getAllBioBank(year: any) {
    return this.http.get(`${this.baseUrl}/${year}`);
  }

  downloadBioBank(year: any, questionKey: any) {
    return this.http.get(`${this.baseUrl}/${year}/${questionKey}`);
  }

}
