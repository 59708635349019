import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class GenericService extends CrudService {
  constructor(public override http: HttpClient) {
    super('generic', http);
  }

  /**
   * Check Generic field is unique
   *
   * @author Dhaval Bera
   *
   * @param string fieldValue
   * @param number id
   *
   * @returns object
   */
   checkUniqueGeneric(fieldValue: string, id: number) {
    return this.http.get(`${ this.baseUrl }/check-unique/${ fieldValue }/${ id }`);
  }

  getMedicineCategory() {
    return this.http.get(`${this.baseUrl}/get-medicine-category`);
  }
  
}
