import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardService } from "src/app/service/dashboard.service";


@Component({
  selector: "ngx-latest-sites",
  templateUrl: "./latest-sites.component.html",
  styleUrls: ["./latest-sites.component.scss"],
})
export class LatestSitesComponent implements OnInit {
  public latestSitesChartLoader: boolean = true;
  public latestSites: any = "";
  public siteLink: any = "site/dashboard/";
  public perPage: any = 10;

  constructor(private dashboardService: DashboardService, protected router: Router,
  ) { }

  /**
   *
   */
  ngOnInit() {
    // Show all charts with no filter
    this.getLatestSites({ 'per_page': this.perPage });
  }

  /**
   *
   * @param body
   */
  getLatestSites(body: any) {
    this.latestSitesChartLoader = true;
    this.dashboardService.getLatestSites(body).subscribe((result: any) => {
      this.latestSitesChartLoader = false;
      this.latestSites = result.data;
    });
  }

  /**
   *
   * @param parentFormData
   */
  filterDashboardData(parentFormData: FormData) {
    parentFormData.append(
      "per_page",
      this.perPage
    );
    this.getLatestSites(parentFormData);
  }
  gotSite(id: any) {
    this.router.navigate([this.siteLink + id]);
  }
}
