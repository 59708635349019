<div class="page-title-box">
  <h1>{{title}}</h1>
  <ul class="bradcrumb">
    <li>{{title}}</li>
    <li><a routerLink="/manage-role" title="Manage Role" class="only-link">Manage Role</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <form [formGroup]="roleForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit()">
    <mat-spinner *ngIf="showLoading"></mat-spinner>
    <div class="progress-overlay"></div>
    <div class="row justify-content-md-center">
      <div class="col-xl-12 col-md-12">
        <div class="card-wrape">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape"
                  [ngClass]="{ 'has-error': roleForm.controls['roleName'].invalid && (roleForm.controls['roleName'].dirty || roleForm.controls['roleName'].touched)}">
                  <mat-label><i>*</i>Role Name</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput #input placeholder="Enter Role Name" type="text" formControlName="roleName" required
                      (focusout)="checkRole($event)">
                  </mat-form-field>
                  <span
                    *ngIf="roleForm.controls['roleName'].invalid && (roleForm.controls['roleName'].dirty || roleForm.controls['roleName'].touched)">
                    <mat-error *ngIf="roleForm.controls['roleName'].errors?.['required']">
                      Please enter Role Name
                    </mat-error>
                    <mat-error *ngIf="roleForm.controls['roleName'].errors?.['unique']">
                      {{roleForm.controls['roleName'].errors?.['unique']}}
                    </mat-error>
                  </span>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape"
                  [ngClass]="{ 'has-error': roleForm.controls['roleKey'].invalid && (roleForm.controls['roleKey'].dirty || roleForm.controls['roleKey'].touched)}">
                  <mat-label><i>*</i>Role Key</mat-label>
                  <div class="control-pad">
                    <ng-select id="roleKey" formControlName="roleKey" [items]="roleKeyList" bindLabel="name"
                      bindValue="id" labelForId="roleKey" placeholder="Select Role Key" (change)="getRoleBasedPermission($event)">
                    </ng-select>
                  </div>
                  <span
                    *ngIf="roleForm.controls['roleKey'].invalid && (roleForm.controls['roleKey'].dirty || roleForm.controls['roleKey'].touched)">
                    <mat-error *ngIf="roleForm.controls['roleKey'].errors?.['required']">
                      Please Select Role Key
                    </mat-error>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card-head">
                <h4 class="left-text">Manage Role Permissions</h4>
              </div>
              <div class="ui-table-wrapper">
                <table class="table-wrapes ui-table">
                  <thead class="ui-table-thead">
                    <tr>
                      <th>Module Name</th>
                      <th *ngFor="let role of roles" class="text-center" width="100px">
                        {{role | titlecase}}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="ui-table-tbody">
                    <tr *ngFor="let module of moduleArray; let i = index" class="example-element-row"
                      [ngClass]="{'first_parent_tr': module.levelNumber == 0}" width="100px">
                      <td class="info-panel"><label
                          [ngClass]="{'first_parent': module.levelNumber == 0, 'second_parent' : module.levelNumber == 1, 'third_parent' : module.levelNumber == 2, 'forth_parent' : module.levelNumber > 2 }">{{module.displayName}}</label>
                      </td>

                      <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.view === true"
                            (change)="changeRole(i , 'view' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.view}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="view_{{module.moduleId}}"></label>
                      </td>
                      <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.create === true"
                            (change)="changeRole(i , 'create' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.create}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="create_{{module.moduleId}}"></label>
                      </td>
                      <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.edit === true"
                            (change)="changeRole(i , 'edit' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.edit}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="edit_{{module.moduleId}}"></label>
                      </td>
                      <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.delete === true"
                            (change)="changeRole(i , 'delete' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.delete}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="delete_{{module.moduleId}}"></label>
                      </td>
                      <!-- <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.download === true"
                            (change)="changeRole(i , 'download' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.download}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="download_{{module.moduleId}}"></label>
                      </td>
                      <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.import === true"
                            (change)="changeRole(i , 'import' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.import}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="import_{{module.moduleId}}"></label>
                      </td>
                      <td class="info-panel text-center">
                        <mat-checkbox-groups class="checkbox-section">
                          <mat-checkbox [checked]="module.export === true"
                            (change)="changeRole(i , 'export' , module, $event, module.parentList, module.childList);"
                            class="example-margin" value="{{module.export}}"></mat-checkbox>
                        </mat-checkbox-groups>
                        <label for="export_{{module.moduleId}}"></label>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button mat-raised-button class="btn" color="warn" routerLink="/manage-role" title="Cancel">Cancel</button>
            <button type="submit" mat-raised-button class="btn" color="secoundary"
              title="{{buttonTitle}}">{{buttonTitle}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
