<div class="page-title-box">
  <h1>{{title}}</h1>
  <ul class="bradcrumb">
    <li>{{title}}</li>
    <li><a routerLink="{{cancelLink}}" title="Allergic To What" class="only-link">Allergic To What</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xl-8 col-md-12 col-sm-12">
      <form [formGroup]="allergicToWhatForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text">{{title}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape"
                  [class]="this.commonService.displayFieldCss(this.allergicToWhatForm, 'allergic_to_what')">
                  <mat-label><i>*</i>Allergic To What</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput #input formControlName="allergic_to_what" placeholder="Enter allergic to what" type="text"
                      (focusout)="
                    checkUniqueAllergicToWhatFields('allergic_to_what', $event)">
                  </mat-form-field>
                  <mat-error
                    *ngIf="this.allergicToWhatForm.controls['allergic_to_what'].invalid && ( this.allergicToWhatForm.controls['allergic_to_what'].touched || this.allergicToWhatForm.controls['allergic_to_what'].dirty)">
                    {{getIsWhatError()}}
                  </mat-error>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape"
                  [ngClass]="{ 'has-error': allergicToWhatForm.controls['allergy_type'].invalid && (allergicToWhatForm.controls['allergy_type'].dirty || allergicToWhatForm.controls['allergy_type'].touched)}">
                  <mat-label><i>*</i>Allergy Type:</mat-label>
                  <div class="control-pad">
                    <ng-select bindValue="option" bindLabel="option" formControlName="allergy_type"
                      placeholder="Select allergy type" (change)="getAllergicToWhatList()" appendTo="body">
                      <ng-option *ngFor="let option of allergyTypes" [value]="option">
                        {{ option | titlecase }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <mat-error
                    *ngIf="allergicToWhatForm.controls['allergy_type'].invalid && (allergicToWhatForm.controls['allergy_type'].dirty || allergicToWhatForm.controls['allergy_type'].touched)">
                    Please Enter Allergy Type
                  </mat-error>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" *ngIf="isCurate">
                  <mat-label><i>*</i> Is Approved?:</mat-label>
                  <mat-radio-group aria-label="Select an option" class="example-radio-group"
                    formControlName="isApproved">
                    <mat-radio-button class="example-radio-button" id="radio_yes" (click)="selectOption(false)"
                      [value]="true" checked>
                      Approved</mat-radio-button>
                    <mat-radio-button class="example-radio-button" id="radio_no" (click)="selectOption(true)"
                      [value]="false">
                      Map with others</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" *ngIf="selectMapOption"
                  [class]="this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].invalid && ( this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].touched || this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].dirty) ? 'has-error' : '' ">
                  <mat-label><i>*</i> Map With:</mat-label>
                  <div class="control-pad">
                    <ng-select matInput [items]="allergicToWhatList" [disabled]="true"
                      formControlName="mapWithOtherAllergicToWhat" bindLabel="allergic_to_what" bindValue="id"
                      labelForId="mapWithOtherAllergicToWhat" (change)="mapWithAllergicToWhatChange($event)"
                      placeholder="Select Allergic To What" appendTo="body">
                    </ng-select>
                  </div>
                  <mat-error
                    *ngIf="this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].invalid && ( this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].touched || this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].dirty)">
                    {{getMapWithAllergicToWhatError()}}
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
              title="Cancel">Cancel</button>
            <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
              title="{{buttonText}}">{{buttonText}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
