<div class="page-title-box">
	<h1>{{ title }}</h1>
	<ul class="bradcrumb">
		<li>{{ title }}</li>
		<li><a routerLink="/manage-user" title="Manage User" class="only-link">Manage User</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<div class="col-xl-9 col-md-9">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{ title }}</h4>
				</div>
				<form [formGroup]="userForm" #form="ngForm"
					[ngClass]="type === 'edit' ? 'edit-mode' : 'view-mode untouched'"
					(ngSubmit)="onSubmit(userForm.value)">
					<div class="card-body" *ngIf="userDetail">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched)}">
									<mat-label><i>*</i> First Name</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input formControlName="first_name"
											placeholder="Enter first name" type="text"
											value="{{ userDetail?.first_name }}">
									</mat-form-field>
									<span
										*ngIf="userForm.controls['first_name'].invalid && (userForm.controls['first_name'].dirty || userForm.controls['first_name'].touched)">
										<mat-error *ngIf="userForm.controls['first_name'].errors?.['required']">
											Please Enter First name
										</mat-error>
									</span>
								</div>
								<div class="input-wrape">
									<mat-label>Middle Name</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input formControlName="middle_name"
											placeholder="Enter middle name" type="text"
											value="{{ userDetail.middle_name }}">
									</mat-form-field>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched)}">
									<mat-label><i>*</i> Last Name</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input formControlName="last_name" placeholder="Enter last name"
											type="text" value="{{ userDetail?.last_name }}">
									</mat-form-field>
									<span
										*ngIf="userForm.controls['last_name'].invalid && (userForm.controls['last_name'].dirty || userForm.controls['last_name'].touched)">
										<mat-error *ngIf="userForm.controls['last_name'].errors?.['required']">
											Please Enter Last name
										</mat-error>
									</span>
								</div>
								<div class="input-wrape calendar-wrape">
									<mat-label>Date of Birth</mat-label>
									<mat-form-field class="example-full-width" appearance="outline">
										<input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate"
											placeholder="Enter date of birth" (click)="picker.open()"
											[value]="this.userDetail.date_of_birth" formControlName="date_of_birth">
										<mat-datepicker-toggle matSuffix [for]="picker">
											<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
										</mat-datepicker-toggle>
										<mat-datepicker #picker></mat-datepicker>
									</mat-form-field>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['gender'].invalid && (userForm.controls['gender'].dirty || userForm.controls['gender'].touched)}">
									<mat-label><i>*</i> Gender</mat-label>
									<mat-radio-group aria-label="Select an option" formControlName="gender">
										<mat-radio-button [value]="option.value" [checked]="option.checked"
											*ngFor="let option of genderOptions" (change)="genderChange($event)">
											{{ option.name | titlecase }}
										</mat-radio-button>
									</mat-radio-group>
									<span
										*ngIf="userForm.controls['gender'].invalid && (userForm.controls['gender'].dirty || userForm.controls['gender'].touched)">
										<mat-error *ngIf="userForm.controls['gender'].errors?.['required']">
											Please Select gender
										</mat-error>
									</span>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">
								<div class="contact-no-pad">
									<div class="input-wrape code"
										[ngClass]="{ 'has-error': userForm.controls['phone_country_code'].invalid && (userForm.controls['phone_country_code'].dirty || userForm.controls['phone_country_code'].touched)}">
										<mat-label>Country Code</mat-label>
										<div class="control-pad">
											<ng-select [items]="countryCodes" bindLabel="name" bindValue="id"
												placeholder="Country Code" formControlName="phone_country_code">
												<ng-template ng-label-tmp let-item="item">
													{{item.phone_code}}
												</ng-template>
												<ng-template ng-option-tmp let-item="item">
													<span class="c-code">{{ item.phone_code }}</span>
													{{item.name}}
												</ng-template>
											</ng-select>
										</div>
										<span
											*ngIf="userForm.controls['phone_country_code'].invalid && (userForm.controls['phone_country_code'].dirty || userForm.controls['phone_country_code'].touched)">
											<mat-error
												*ngIf="userForm.controls['phone_country_code'].errors?.['required']">
												Please Select Phone Number Code
											</mat-error>
										</span>
									</div>
									<div class="input-wrape number">
										<mat-label>Phone Number</mat-label>
										<mat-form-field appearance="outline">
											<input matInput formControlName="mobile" #input
												placeholder="Enter phone number" type="number"
												value="{{ userDetail?.mobile }}" (keypress)="onlyNumeric($event)">
										</mat-form-field>
									</div>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)}">
									<mat-label>Email ID</mat-label>
									<mat-form-field appearance="outline" class="disabled">
										<input matInput formControlName="email" #input placeholder="Enter email ID"
											value="{{ userDetail?.email}}" type="text">
									</mat-form-field>
									<span
										*ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)">
										<mat-error *ngIf="userForm.controls['email'].errors?.['required']">Please enter
											email address
										</mat-error>
										<mat-error *ngIf="userForm.controls['email'].errors?.['email']">Please enter
											valid email address
										</mat-error>
									</span>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['roleName'].invalid && (userForm.controls['roleName'].dirty || userForm.controls['roleName'].touched)}">
									<mat-label><i>*</i> Role Name</mat-label>
									<div class="control-pad">
										<ng-select [multiple]="true" placeholder="Please Select"
											formControlName="roleName" [items]="roleList" bindLabel="role_name"
											bindValue="id" labelForId="role_id">
										</ng-select>
									</div>
									<span
										*ngIf="userForm.controls['roleName'].invalid && (userForm.controls['roleName'].dirty || userForm.controls['roleName'].touched)">
										<mat-error *ngIf="userForm.controls['roleName'].errors?.['required']">
											Please select user role
										</mat-error>
									</span>
								</div>
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox class="example-margin" formControlName="is_deactivate"
											[checked]="userDetail?.is_deactivate">
											Is Deactivated?
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox class="example-margin" formControlName="is_hide_personal_info">
											Hide Personal Identifications
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox class="example-margin" formControlName="is_data_curation_access">
											Data Curation Access
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer" *ngIf="type === 'edit'">
						<button mat-raised-button class="btn" color="warn" routerLink="/manage-user"
							title="Cancel">Cancel</button>
						<button type="submit" mat-raised-button class="btn" color="secoundary"
							title="Update">Update</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>