import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ParticipantService {
  dictionaryUrl: string = `${environment.baseURL}` + 'dictionary';
  baseUrl: string = `${environment.baseURL}` + 'participant';
  constructor(private http: HttpClient) { }

  /**
   * This function is used to get record by filter
   * @param pageNo
   * @param sortField
   * @param sortOrder
   * @param globalField
   * @author Dhaval Bera
   */
  getRecordByFilter(
    IsInternalParticipant: boolean,
    pageNo: number,
    sortField: string,
    sortOrder: string,
    globalFilter: string,
    rows: number
  ) {
    const IsInternalParticipantVal = IsInternalParticipant == false ? 0 : 1;
    let sortFieldName = '';
    let page = '';
    let globalSearch = '';
    let perPage = '';

    if (pageNo != null) {
      page = '?page=' + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    }

    if (globalFilter != null && globalFilter !== undefined) {
      globalSearch = '&filters[global]=' + globalFilter;
    }

    if (rows !== 0) {
      perPage = '&perPage=' + rows;
    }

    const finalFilter = page + sortFieldName + globalSearch + perPage;

    return this.http.get(`${this.baseUrl}/${IsInternalParticipantVal}${finalFilter}`);
  }

  /**
   * This funtion is used to get participant by site id
   * @param Id
   */
  getParticipantBySiteId(
    siteId: any,
    pageNo: number,
    sortField: string,
    sortOrder: string,
    globalFilter: string,
    rows: number
  ) {
    let sortFieldName = '';
    let page = '';
    let globalSearch = '';
    let perPage = '';

    if (pageNo != null) {
      page = '?page=' + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    }

    if (globalFilter != null && globalFilter !== undefined) {
      globalSearch = '&filters[global]=' + globalFilter;
    }

    if (rows !== 0) {
      perPage = '&perPage=' + rows;
    }

    const finalFilter = page + sortFieldName + globalSearch + perPage + "&siteId=" + siteId;

    return this.http.get(this.baseUrl + '/get-participant-by-site-id' + finalFilter);
  }

  getAllParticipantStatistics(isLive: boolean = true) {
    return this.http.get(this.baseUrl + '/statistics/' + isLive);
  }

  getParticipantInformation(PID: number) {
    return this.http.get(this.baseUrl + '/dash-pi/' + PID);
  }

  /**
   * Update Participant Information
   * @param module
   * @author Pramod Kushwaha
   */
  updateParticipantInformation(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-pi/" + id, formData);
  }

  dashboard(PID: number) {
    return this.http.get(this.baseUrl + '/dashboard/' + PID);
  }

  getMedications(PID: number) {
    return this.http.get(this.baseUrl + '/dash-medication/' + PID);
  }

  /**
   * Update Participant Medication
   * @param module
   * @author Pramod Kushwaha
   */
  updateMedication(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-medication/" + id, formData);
  }

  getSurgery(PID: number) {
    return this.http.get(this.baseUrl + '/dash-surgeries/' + PID);
  }

  getDiseases(PID: number) {
    return this.http.get(this.baseUrl + '/dash-disease/' + PID);
  }

  getAllergy(PID: number) {
    return this.http.get(this.baseUrl + '/dash-allergies/' + PID);
  }
  getAllMedicalReports(PID: number) {
    return this.http.get(this.baseUrl + '/medical-reports/' + PID);
  }

  getRecreationalHabits(PID: number) {
    return this.http.get(this.baseUrl + '/dash-recreational-habits/' + PID);
  }

  getEyeProblems(PID: number) {
    return this.http.get(this.baseUrl + '/dash-eye-problems/' + PID);
  }

  getAllParticipantDocuments(PID: number) {
    return this.http.get(this.baseUrl + '/dash-participant-documents/' + PID);
  }

  getCurationNotes(PID: number) {
    return this.http.get(this.baseUrl + '/dash-curation-notes/' + PID);
  }

  /**
   * Update Participant surgeries
   * @param module
   * @author Pramod Kushwaha
   */
  updateSurgery(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-surgeries/" + id, formData);
  }

  /**
  * Update Participant disease
  * @param module
  * @author Pramod Kushwaha
  * This funtion is used to get participant by Id
  * @param Id
  */
  updateDiseases(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-disease/" + id, formData);
  }

  /**
   * Update Participant allergy
   * @param module
   * @author Pramod Kushwaha
   */
  updateAllergy(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-allergies/" + id, formData);
  }

  /**
  *
  * @param formData
  * @returns
  */
  uploadDiseaseQuestionReport(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/upload-disease-questions-report/" + id, formData);
  }


  /**
  * Update Participant update Recreational Habits
  * @param module
  * @author Pramod Kushwaha
  */
  updateRecreationalHabit(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-recreational-habits/" + id, formData);
  }

   /**
  * Update Participant update Recreational Habits
  * @param module
  * @author Pramod Kushwaha
  */
   updatEeyeProblems(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-eye-problems/" + id, formData);
  }

  /**
   * Update Participant allergy
   * @param module
   * @author Pramod Kushwaha
   */
  updateFD(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-family-diseases/" + id, formData);
  }

  /**
   * Update Participant Curation Notes
   * @param module
   * @author Farhan Shaikh
   */
  updateCurationNotes(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/dash-curation-notes/" + id, formData);
  }


  /**
  *
  * @param fileName
  * @returns
  */
  deleteDiseaseQuestionReport(fileName: string) {
    return this.http.delete(
      this.baseUrl + "/delete-disease-question-report/" + fileName
    );
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get participant by Id
   * @param Id
   */
  getParticipantByPID(pid: any, type: any = 'htr') {
    return this.http.get(this.baseUrl + "/get/pid/" + pid + '/' + type);
  }
  deletePastMedicalRecord(fileName: any) {
    return this.http.delete(this.baseUrl + "/delete-pmr/" + fileName);
  }
  uploadPastMedicalRecord(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/upload-pmr/" + id, formData);
  }

  getSampleTestInfoList(id: any) {
    return this.http.post(this.baseUrl + "/sample-tests/" + id, []);
  }

  /**
   * Update Participant Sample Info
   * @param module
   * @author Pramod Kushwaha
   */
  updateSampleInfo(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/sample-test-update/" + id, formData);
  }
  /**
   * This function is used to Make QC
   * @param id
   * @return
   */
  getSampleItems() {
    const sampleItems = [];

    for (let i = 1; i <= 10; i++) {
      sampleItems.push(i);
    }

    return sampleItems;
  }
  getParticipantDiseasesList(pid: any) {
    return this.http.get(this.baseUrl + "/participant-diseases/" + pid);
  }

  getFamilyDiseases(pid: any) {
    return this.http.get(this.baseUrl + "/dash-family-diseases/" + pid);
  }

  /**
  * @author Pramod Kushwaha
  * This function is used to Make QC Rejected
  * @param id
  */
  updateQCedRejected(id: any) {
    return this.http.post(this.baseUrl + "/phfQCRejected/" + id, []);
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to Make QC
   * @param id
   */
  updateQCed(id: any) {
    return this.http.post(this.baseUrl + "/phfQCed/" + id, []);
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to mark as PHF Reviewed
   * @param id
   */
  markPHFReviewed(id: any) {
    return this.http.post(this.baseUrl + "/phfReviewed/" + id, []);
  }

  /**
   * @author Dhaval Bera
   * This function is used to get Clinical BioClinical Data
   * @param id
   */
  getCBIDetails(id: any) {
    return this.http.get(this.baseUrl + "/clinical-biochemical-history/" + id);
  }
  /**
   * @author Dhaval Bera
   * This function is used to update Clinical BioClinical Data
   * @param formData
   * @param id
   */
  updateCBIDetails(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/update-clinical-biochemical-history/" + id, formData);
  }

  deleteDiseaseHistory(id: any) {
    return this.http.delete(this.baseUrl + "/delete-disease-history/" + id);
  }

  /**
   * @author Dhaval Bera
   * This function is used to delete Participant
   * @param id
   */
  deleteParticipant(id: any) {
    return this.http.delete(this.baseUrl + "/delete/" + id);
  }

  /**
  * @author Dhaval Bera
  * This function is used to Download Medical Record Docs
  * @param formData
  * @param id
  */
  downloadMedicalRecordDoc(formData: any, id: any) {
    return this.http.post(this.baseUrl + "/download-medical-record/" + id, formData);
  }

  getAllergyDescriptionList() {
    return this.http.get(`${this.dictionaryUrl}/get?dictionary_type=allergy_description`);
  }
  getAllergyReactionList() {
    return this.http.get(`${this.dictionaryUrl}/get?dictionary_type=allergy_reaction`);
  }

}
