import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-disease-nafld-questions',
  templateUrl: './disease-nafld-questions.component.html',
  styleUrls: ['./disease-nafld-questions.component.scss']
})
export class DiseaseNafldQuestionsComponent implements OnChanges {

  @Input() diseaseForm!: FormGroup;
  @Input() diseaseDetailForm!: FormGroup;
  @Input() diseaseUpdateIndex: any;
  @Input() diseaseDetails: any = [];
  @Input() infectionStatus: any = [];
  @Input() isViewDisease: any;

  public nafldForm!: FormGroup;
  public participantID: any;
  public NAFLD: any = [];
  public isNAFDCancer: boolean = false;
  public currentMenuPermission: any;

  constructor(
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private menuService: MenuService
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  ngOnChanges(): void {

    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });

    if (![null, undefined].includes(this.diseaseUpdateIndex)) {
      this.NAFLD = this.diseaseDetails['details'][this.diseaseUpdateIndex]['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'];
    }


    console.log(this.diseaseDetails['details'])

    this.diseaseDetails['details'].forEach((disease:any) => {
      if(disease.disease_type_flag === 'CANCER'){
         console.log(disease);
        this.isNAFDCancer = true;
      }
    });

    // NON_ALCOHOLIC_FATTY_LIVER_DISEASE Questions
    this.nafldForm = this.formBuilder.group({
      have_nafld_hepatic_ultrasound_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_hepatic_ultrasound_report, [Validators.required]),
      have_nafld_albumin_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_albumin_report, [Validators.required]),
      have_nafld_alt_sgpt_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_alt_sgpt_report, [Validators.required]),
      have_nafld_any_history_blood_transfusion: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_any_history_blood_transfusion, [Validators.required]),
      have_nafld_any_history_of_cirrhosis: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD.have_nafld_any_history_of_cirrhosis, [Validators.required]),
      have_nafld_any_history_or_currently_suffering_from_cancer: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_any_history_or_currently_suffering_from_cancer, [Validators.required]),
      have_nafld_any_infection_in_past_week: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_any_infection_in_past_week, [Validators.required]),
      have_nafld_ast_sgot_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_ast_sgot_report, [Validators.required]),
      have_nafld_complete_blood_count_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_complete_blood_count_report, [Validators.required]),
      have_nafld_fasting_sugar_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_fasting_sugar_report, [Validators.required]),
      have_nafld_fibroscan_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_fibroscan_report, []),
      have_nafld_hepatitis_a_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_hepatitis_a_report, []),
      have_nafld_hepatitis_b_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_hepatitis_b_report, []),
      have_nafld_hepatitis_c_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_hepatitis_c_report, []),
      have_nafld_hiv_infection: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_hiv_infection, [Validators.required]),
      have_nafld_nfs_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_nfs_report, [Validators.required]),
      have_nafld_previouly_received_currently_dialysis: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.have_nafld_previouly_received_currently_dialysis, [Validators.required]),
      photo_nafld_albumin_report_1: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_albumin_report_1, []),
      photo_nafld_albumin_report_1_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_albumin_report_1_url, []),
      photo_nafld_albumin_report_2: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_albumin_report_2, []),
      photo_nafld_albumin_report_2_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_albumin_report_2_url, []),
      photo_nafld_alt_sgpt_report_1: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_alt_sgpt_report_1, []),
      photo_nafld_alt_sgpt_report_1_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_alt_sgpt_report_1_url, []),
      photo_nafld_alt_sgpt_report_2: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_alt_sgpt_report_2, []),
      photo_nafld_alt_sgpt_report_2_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_alt_sgpt_report_2_url, []),
      photo_nafld_ast_sgot_report_1: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_ast_sgot_report_1, []),
      photo_nafld_ast_sgot_report_1_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_ast_sgot_report_1_url, []),
      photo_nafld_ast_sgot_report_2: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_ast_sgot_report_2, []),
      photo_nafld_ast_sgot_report_2_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_ast_sgot_report_2_url, []),
      photo_nafld_complete_blood_count_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_complete_blood_count_report, []),
      photo_nafld_complete_blood_count_report_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_complete_blood_count_report_url, []),
      photo_nafld_fasting_sugar_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_fasting_sugar_report, []),
      photo_nafld_fasting_sugar_report_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_fasting_sugar_report_url, []),
      photo_nafld_fibroscan_report_1: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_fibroscan_report_1, []),
      photo_nafld_fibroscan_report_1_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_fibroscan_report_1_url, []),
      photo_nafld_fibroscan_report_2: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_fibroscan_report_2, []),
      photo_nafld_fibroscan_report_2_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_fibroscan_report_2_url, []),
      photo_nafld_hepatic_ultrasound_report_1: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatic_ultrasound_report_1, []),
      photo_nafld_hepatic_ultrasound_report_1_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatic_ultrasound_report_1_url, []),
      photo_nafld_hepatic_ultrasound_report_2: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatic_ultrasound_report_2, []),
      photo_nafld_hepatic_ultrasound_report_2_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatic_ultrasound_report_2_url, []),
      photo_nafld_hepatitis_a_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatitis_a_report, []),
      photo_nafld_hepatitis_a_report_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatitis_a_report_url, []),
      photo_nafld_hepatitis_b_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatitis_b_report, []),
      photo_nafld_hepatitis_b_report_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatitis_b_report_url, []),
      photo_nafld_hepatitis_c_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatitis_c_report, []),
      photo_nafld_hepatitis_c_report_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_hepatitis_c_report_url, []),
      photo_nafld_nfs_report: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_nfs_report, []),
      photo_nafld_nfs_report_url: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this.NAFLD?.photo_nafld_nfs_report_url, []),
      what_type_of_infection_in_past_week: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this?.NAFLD.what_type_of_infection_in_past_week, []),
      nafld_cap_score: new FormControl([null, undefined, ''].includes(this.NAFLD) ? null : this?.NAFLD.nafld_cap_score, [Validators.min(100), Validators.max(400)]),
    });

    this.diseaseDetailForm.setControl('NON_ALCOHOLIC_FATTY_LIVER_DISEASE', this.nafldForm);

    // NON_ALCOHOLIC_FATTY_LIVER_DISEASE Dynamic Validation
    const nafldForm = this.diseaseDetailForm.get('NON_ALCOHOLIC_FATTY_LIVER_DISEASE') as FormArray;
    nafldForm.get('have_nafld_nfs_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_nfs_report'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_nfs_report'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_nfs_report'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_fasting_sugar_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_fasting_sugar_report'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_fasting_sugar_report'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_fasting_sugar_report'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_complete_blood_count_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_complete_blood_count_report'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_complete_blood_count_report'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_complete_blood_count_report'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_ast_sgot_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_ast_sgot_report_1'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_ast_sgot_report_1'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_ast_sgot_report_1'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_alt_sgpt_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_alt_sgpt_report_1'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_alt_sgpt_report_1'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_alt_sgpt_report_1'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_albumin_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_albumin_report_1'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_albumin_report_1'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_albumin_report_1'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_any_infection_in_past_week')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['what_type_of_infection_in_past_week'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['what_type_of_infection_in_past_week'].clearValidators();
      }
      this.nafldForm.controls['what_type_of_infection_in_past_week'].updateValueAndValidity();
    });

    nafldForm.get('have_nafld_hepatic_ultrasound_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.nafldForm.controls['photo_nafld_hepatic_ultrasound_report_1'].setValidators([Validators.required]);
      } else {
        this.nafldForm.controls['photo_nafld_hepatic_ultrasound_report_1'].clearValidators();
      }
      this.nafldForm.controls['photo_nafld_hepatic_ultrasound_report_1'].updateValueAndValidity();
    });

  }

  /**
* This funtion is used to upload report
* @param event
* @param key
*/
  onSelectFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0]
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('disease_type', 'CHRONIC_KIDNEY_DISEASE');
      formData.append('previous_file', [null, undefined, ''].includes(this.NAFLD.key) ? '' : this.NAFLD.key);
      this.participantService.uploadDiseaseQuestionReport(formData, this.participantID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            console.log(key);
            this.nafldForm.controls[key]?.setValue(result.data.file);
            this.nafldForm.controls[key + '_url']?.setValue(result.data.file_with_path);
          }
        },
        error: (e: any) => {
          console.log('err :: ', e);
        },
        complete: () => {

        },
      });
    }
  }

  /**
   * Delete Disease Question Report File
   * @param fileName
   * @param index
   */
  deleteDiseaseQuestionReport(fileName: string, key: string) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the file?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.participantService.deleteDiseaseQuestionReport(fileName).subscribe({
          next: (result: any) => {
            if (result.status === 1) {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Report deleted successfully.', icon: 'success', });
              this.nafldForm.controls[key]?.setValue(null);
              this.diseaseDetails['details'][this.diseaseUpdateIndex]['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'][key + '_url'] = null;
              this.nafldForm.controls[key].setValidators([Validators.required]);
              this.nafldForm.controls[key].updateValueAndValidity();
            } else {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: result.message, icon: 'warning', });
            }
          },
          error: (e: any) => {
            console.log('err :: ', e);
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: e, icon: 'warning', });
          },
          complete: () => {

          },
        });
      }
    });
  }

  showImage(fileUrl: string) {
    Swal.fire({
      imageUrl: fileUrl,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

}
