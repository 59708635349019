
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ReligionService } from 'src/app/service/religion.service';
import { PopulationService } from 'src/app/service/population.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { dataTool } from 'echarts';

@Component({
  selector: 'app-population-form',
  templateUrl: './population-form.component.html',
  styleUrls: ['./population-form.component.scss']
})

export class PopulationFormComponent implements OnInit {

    public populationForm!: FormGroup;
    public title: any;
    public populationId: any;
    public disableSubmitBtn: boolean = false;
    public isAddMode: boolean = true;
    public isCurate: boolean = false;
    public buttonText: string = "";
    public cancelLink: string = "/dictionary-management/sub-population";
    public userId: any = "";
    public isApproved: number = 1;
    public selectMapOption: boolean = false;
    public mappedPopulations: any;
    public mappedPopulationLists: any = [];
    public loading: boolean = false;
    public populationMinLength: number = 3;
    public populationMaxLength: number = 255;
    public populationDetails: any = [];
    public religionList: any = [];
    public populationList: any = [];
    
    constructor(
      private populationService: PopulationService,
      private religionService: ReligionService,
      public commonService: CommonService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      protected router: Router
    ) {
      this.populationId = this.route.snapshot.paramMap.get("id");
      if (this.populationId !== 0 && this.populationId !== null) {
        this.title = "Edit Sub Population";
        this.buttonText = "Save";
      } else {
        this.title = "Add Sub Population";
        this.buttonText = "Add";
        this.populationId = 0;
      }
    }
  
    ngOnInit(): void {
        
      // Get Religion List
      this.getReligionList();

        if (this.populationId !== 0 && this.populationId !== null) {
            this.setEditData();
        }

      this.isAddMode = !this.populationId;
      if(this.router.url.indexOf('curation-pending') >= 0){
        this.isCurate = true;
        this.title = "Curate Sub Population";
        this.cancelLink = '/dictionary-management/sub-population/curation-pending';
      }
  
      this.userId = localStorage.getItem("userId");
      this.populationForm = this.formBuilder.group({
        'name': ['', [Validators.required, Validators.minLength(this.populationMinLength), Validators.maxLength(this.populationMaxLength)]],
        'religionId':[null ,  [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
        'sectDenomination':['',  []],
        'populationEthnicGroup':['',  []],
        'populationLinguisticGroup':['',  []],
        'populationSocialHierarchy':['',  []],
        'isApproved':[this.isApproved,  []],
        'mapWithOtherPopulation':[null,  []],
        'mapped_population_id':[null,  []]
      });
  
    }
  
     /**
       * Get all religion for validate religion
       * @returns object
       */
     // Get religion Dictionary
     getReligionList(){
      this.religionService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.religionList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }
  
    setEditData() {
      this.populationService.getRow(this.populationId)
      .subscribe({
        next: (result: any) => {
          if (result.status !== AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
          }
        this.populationDetails = result.data;
        setTimeout(() => {
            this.populationForm.patchValue({
                name: this.populationDetails.name,
                religionId: this.populationDetails.religion_id,
                sectDenomination: (this.populationDetails.sect_denomination_name && this.populationDetails.sect_denomination_name != 'null') ? this.populationDetails.sect_denomination_name : '',
                populationEthnicGroup: (this.populationDetails.population_ethnic_group_name && this.populationDetails.population_ethnic_group_name != 'null') ? this.populationDetails.population_ethnic_group_name : '',
                populationLinguisticGroup: (this.populationDetails.population_linguistic_group_name && this.populationDetails.population_linguistic_group_name != 'null') ? this.populationDetails.population_linguistic_group_name : '',
                populationSocialHierarchy: (this.populationDetails.population_social_hierarchy_name && this.populationDetails.population_social_hierarchy_name != 'null') ? this.populationDetails.population_social_hierarchy_name : '',
              });
        }, 300);
      },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
      this.setMappedPopulationData();
    }

    setMappedPopulationData() {
      this.loading = true;
      this.populationService.getMappedPopulation(this.populationId).subscribe((result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.router.navigate(['page-not-found']);
        }
        
        this.mappedPopulations = result['data'];
        
        this.mappedPopulationLists = this.mappedPopulations;
  
        let mappedPopulationIds: any[] = [];
        
        this.mappedPopulations.forEach((res: any) => {
          mappedPopulationIds.push(res.id);
        });
  
        this.populationForm.patchValue({
          mapped_population_id: mappedPopulationIds,
        });
        this.loading = false;
      });
    }
  
    getPopulationError() {
      if (this.populationForm.controls['name'].errors!['required']) {
        return 'Name is required.';
      }
      if (this.populationForm.controls['name'].errors!['minlength']) {
        return 'Min length is '+ this.populationMinLength +' characters.';
      }
      if (this.populationForm.controls['name'].errors!['maxlength']) {
        return 'Max length is '+ this.populationMaxLength +' characters.';
      }
      if (this.populationForm.controls['name'].errors!['unique']) {
        return this.populationForm.get("name")!.errors!['unique'];
      }
      return ''; 
    } 
  
    getSelectReligionError() {
      if (this.populationForm.controls['religionId'].errors!['required']){ 
        return 'Religion is required';
      }
      if(this.populationForm.controls['religionId'].errors!['pattern']) {
        return 'Please select valid religion';
      }
      return ''; 
    } 
  
    /**
     * submit form
     * @returns null
     */
    onSubmit(): void {  
      if (this.populationForm.invalid) {
        this.commonService.validateAllFormFields(this.populationForm);
        this.commonService.showErrorToast(
          'OOPS! Please enter correct values'
        );
        return;
      }
      this.disableSubmitBtn = true;
      const formData: FormData = new FormData();
      formData.append('name', this.populationForm.controls['name'].value);
      formData.append('religion_id', this.populationForm.controls['religionId'].value);
      formData.append('sect_denomination_name', this.populationForm.controls['sectDenomination'].value);
      formData.append('population_ethnic_group_name', this.populationForm.controls['populationEthnicGroup'].value);
      formData.append('population_linguistic_group_name', this.populationForm.controls['populationLinguisticGroup'].value);
      formData.append('population_social_hierarchy_name', this.populationForm.controls['populationSocialHierarchy'].value);
      formData.append('is_approved', this.populationForm.controls['isApproved'].value);
      formData.append('map_with_other_population', (this.populationForm.controls['mapWithOtherPopulation'].value) ? this.populationForm.controls['mapWithOtherPopulation'].value : 0);
      formData.append('mapped_population_id',this.populationForm.controls['mapped_population_id'].value);
        if (this.isAddMode) {
            this.createPopulation(formData);
        } else {
            this.updatePopulation(formData);
        }
    }
    
    /**
     * call Create Population api
     * @returns null
     */
    private createPopulation(formData: any): void {
      this.populationService.store(formData)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error:(err: any) => {
          console.log(err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }
    
    /**
     * call Update Population api
     * @returns null
     */
    private updatePopulation(formData: any): void {
      formData.append('updated_by', this.userId);
      this.populationService.update(formData, this.populationId)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error:(err: any) => {
          console.log(err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }
  
    /**
     * Select Approved Option
     *
     * @returns object
     */
     selectOption(value: boolean) {
      this.selectMapOption = value;
      if(value){
        this.populationForm.get("mapWithOtherPopulation")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
        this.populationForm.controls['religionId'].disable();
      }else{
        this.populationForm.controls['religionId'].enable();
        this.populationForm.patchValue({
          religionId: this.populationDetails.religion_id,
          sectDenomination: (this.populationDetails.sect_denomination_name && this.populationDetails.sect_denomination_name != 'null') ? this.populationDetails.sect_denomination_name : '',
          populationEthnicGroup: (this.populationDetails.population_ethnic_group_name && this.populationDetails.population_ethnic_group_name != 'null') ? this.populationDetails.population_ethnic_group_name : '',
          populationLinguisticGroup: (this.populationDetails.population_linguistic_group_name && this.populationDetails.population_linguistic_group_name != 'null') ? this.populationDetails.population_linguistic_group_name : '',
          populationSocialHierarchy: (this.populationDetails.population_social_hierarchy_name && this.populationDetails.population_social_hierarchy_name != 'null') ? this.populationDetails.population_social_hierarchy_name : '',
          mapWithOtherPopulation: null
        });
        this.populationForm.get('mapWithOtherPopulation')!.setValidators(null);
      }
      this.populationForm.controls['mapWithOtherPopulation'].updateValueAndValidity()
      this.getMapWithPopulationError();
    }
    
    getMapWithPopulationError() {
      if(this.populationForm.controls['mapWithOtherPopulation'].invalid){
        if (this.populationForm.controls['mapWithOtherPopulation'].errors!['required']) {
          return 'Select population';
        }
        if(this.populationForm.controls['mapWithOtherPopulation'].errors!['pattern']) {
          return 'Please select valid population';
        }
      }
      return ''; 
    }

    /**
     * Select Population Details
     *
     * @returns object
     */
    getPopulationDetails(event: any) {
      if(event !== undefined){
        this.loading = true;
        this.populationService.getRow(event.id).subscribe((result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            if(result.data){
              const populationResult = result.data;
              this.populationForm.patchValue({
                religionId: populationResult.religion_id,
                sectDenomination: (populationResult.sect_denomination_name && populationResult.sect_denomination_name != 'null') ? populationResult.sect_denomination_name : '',
                populationEthnicGroup: (populationResult.population_ethnic_group_name && populationResult.population_ethnic_group_name != 'null') ? populationResult.population_ethnic_group_name : '',
                populationLinguisticGroup: (populationResult.population_linguistic_group_name && populationResult.population_linguistic_group_name != 'null') ? populationResult.population_linguistic_group_name : '',
                populationSocialHierarchy: (populationResult.population_social_hierarchy_name && populationResult.population_social_hierarchy_name != 'null') ? populationResult.population_social_hierarchy_name : '',
              });
              if(!populationResult.religion_id){
                this.populationForm.controls['religionId'].enable();
              }else{
                this.populationForm.controls['religionId'].disable();
              }
            }
            this.loading = false;
          } else {
            this.loading = false
          }
        });
      }  
    }
  
    /**
     * Check population field is existed or not
     * @returns null
     */
     checkUniquePopulation(event: any) {
      const populationFieldValue = (event.target as HTMLInputElement).value;
      if (populationFieldValue.length > this.populationMaxLength) {
        return false;
      }
  
        if (populationFieldValue.length < this.populationMinLength) {
          return false;
        }
    
      if (populationFieldValue !== "") {
          this.populationService
          .checkUniquePopulation(
            populationFieldValue,
            this.populationId
          )
          .subscribe({
            next:(result: any) => {
              if (result.status === 1 || result.status === 200) {
                this.populationForm.get("name")!.setErrors(null);
              } else {
                this.populationForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
              }
            },
            error: (error: any) => {
              if (error.status === 400) {
                this.populationForm
                  .get("name")!
                  .setErrors({ unique: "Name already exists" });
              } else {
                console.log(
                  "Something went wrong. Please contact to administrator."
                );
              }
            }
          });
      }
      return null;
    }
  
    /**
       * Get all Population for map with Population
       * @returns object
       */
     // Get Population Dictionary
     getPopulationList(event: any){
      if( 
          event.term !== undefined && event.term.length > 1 
        ){
        this.loading = true;
        this.populationService.getActiveList(event.term)
        .subscribe({
          next: (result: any) => {
            if(result.status === AppConstants.serverSuccessStatus){
              this.populationList = result.data;
            }else{
              this.populationList = [];
            }
            this.loading = false;
          },
          error: (e: any) => {
            console.log(
              e.error
              );
              this.loading = false;
            },
        });
      }
      // this.populationList = [];
      return this.populationList;
    }
  }
  
  
