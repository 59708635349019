import { Component, OnInit } from '@angular/core';
import { MatrixService } from 'src/app/service/matrix.service';
import { SiteService } from 'src/app/service/site.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss']
})
export class MatrixComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Site Code',
      dataKey: 'site_code',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['site_code']}`,
    },
    {
      name: 'Site Name',
      dataKey: 'site_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['site_name']}`,
    },
    {
      name: 'Start Date',
      dataKey: 'start_date',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['start_date']}`,
    },
    {
      name: 'End Date',
      dataKey: 'end_date',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['end_date']}`,
    },
    {
      name: 'Camp-City',
      dataKey: 'city_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['city_name']}`,
    },
    {
      name: 'Camp-State',
      dataKey: 'state_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['state_name']}`,
    },
    {
      name: 'Site Coordinator',
      dataKey: 'site_coordinator_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['site_coordinator_name']}`,
    },
    {
      name: 'Total Participants',
      dataKey: 'participants_count',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['participants_count']}`,
    },
  ];

  public isApproved: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public loading: boolean = false;
  public viewLink = 'site/matrix/dashboard/';
  public title = 'Matrix';
  public matrixCount:any = null;

  public totalSites: number = 0;
  public totalPatricipants: number = 0;
  public totalAudios: number = 0;
  public totalImages: number = 0;
  public totalDiseases: number = 0;
  public matrixList: any;
  public exportLink: any = 'matrix/participant-export-orange-dm?site_id=';
  public currentMenuPermission: any;

  
  constructor(
    private matrixService: MatrixService,
    private siteService: SiteService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
    this.getMatrixCount();
  }
  
    /**
    * it will get all module
    * @author Farhan Shaikh
    */
    getAll() {
      this.loading = true;
      this.siteService.getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      ).subscribe({
        next: (result: any) => {
          this.loading = false;
          this.matrixList = result.data.list.data;
          this.totalRows = result.data.list.total;
        },
        error: (err: any) => {
          this.loading = false;
          // console.log('Site Listing err :: ', err);
        }
      });
    }

    viewAction(event: any) {
      this.router.navigate([this.viewLink + event.id]);
    }
    searchFilter(filterValue: any) {
      this.filterValue = filterValue;
      this.currentPage = 0;
      this.getAll();
    }
    sortFilter(sortState: any) {
      if (sortState.active !== '' && sortState.direction !== '') {
        this.sortField = sortState.active;
        this.sortOrder = sortState.direction;
        this.getAll();
      }
    }
    pageChanged(event: any) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.getAll();
    }

    /**
     * This function is used to get matrix count
     */
    getMatrixCount() {
      this.matrixService.getMatrixCount(0)
      .subscribe({
        next: (result: any) => {
          this.matrixCount = result.data;
          this.totalSites = this.matrixCount ? this.matrixCount.site_ids:"-";
          this.totalPatricipants = this.matrixCount ? this.matrixCount.number_of_pids:"-";
          this.totalAudios = this.matrixCount ? this.matrixCount.number_of_audio_files:"-";
          this.totalImages = this.matrixCount ? this.matrixCount.no_of_images:"-";
          this.totalDiseases = this.matrixCount ? this.matrixCount.total_disease_count:"-";
        },
        error: (err: any) => {
          console.log('Matrix Count err :: ', err);
        }
      });
    }

  }