<div class="card-wrape m-b-0">
  <div class="card-head with-dropdown">
    <h4 class="left-text">Gender Wise Participant Count</h4>
    <div class="right-control">
      <div class="input-wrape">
        <div class="control-pad">
          <ng-select [items]="percentageOrAbsoluteCounts" [clearable]="false" [dropdownPosition]="'bottom'"
            [searchable]="false" placeholder="Please Select" bindLabel="value" bindValue="id"
            [(ngModel)]="genderWisePercentOrCount"
            (change)="showRecordsInPercentOrAbsoluteCount('gender_wise', $event)">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <mat-spinner *ngIf="genderChartLoader"></mat-spinner>
    <div class="progress-overlay"></div>
    <div *ngIf="totalGenderChartCounts == 0 && !genderChartLoader; else foundGenderChartData">
      <div class="no-data-found">
        <div class="no-data-container">
          <div class="no-data-icon"></div>
          <div class="no-data-text">NO DATA FOUND</div>
        </div>
      </div>
    </div>
    <ng-template #foundGenderChartData>
      <div echarts [options]="genderChartOptions" class="echart"></div>
      <div class="total-count-piechart" *ngIf="!genderChartLoader;">
        {{ totalGenderChartCounts }}<span>Total</span>
      </div>
    </ng-template>
  </div>
</div>