import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-token',
  templateUrl: './invalid-token.component.html',
  styleUrls: ['./invalid-token.component.scss']
})
export class InvalidTokenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
  }

}
