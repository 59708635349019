import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { CrudService } from './crud.service';


@Injectable({
  providedIn: 'root',
})
export class ModuleService extends CrudService {
  constructor(public override http: HttpClient) {
    super('module', http);
  }

  /**
   * @author Dhaval Bera
   */
  getModuleList() {
    return this.http.get(this.baseUrl + '/get-module-list');
  }
}
