<div class="page-title-box">
	<h1>Dashboard</h1>
	<ul class="bradcrumb">
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>
<section class="container-fluid">
	<div class="row">
		<div class="col-xl-12 col-md-12">
			<div class="stat-wrape three m-b-0">
				<div class="stat-pad">
					<p>PARTICIPANTS ENROLLED <a title="Refresh" (click)="clearCacheRefreshPage()" class="indi-icon sync-icon ng-star-inserted">
					</a></p>
					<h2><i class="theme-icon participant-enroll"></i> {{totalParticipantEnrolled}}</h2>
				</div>
				<div class="stat-pad">
					<p>CAMPS HELD</p>
					<h2><i class="theme-icon camp-held"></i> {{totalCampsHeld}}</h2>
				</div>
				<div class="stat-pad">
					<p>COUNSELORS</p>
					<h2><i class="theme-icon couselor"></i> {{totalCounselors}}</h2>
				</div>
			</div>
		</div>
	</div>


	<div class="row short-filter-wrape"
		*ngIf=" selectedSiteCodes.length > 0 || selectedGenders.length > 0 || selectedStates.length > 0 || selectedSubpopulations.length > 0 || selectedDiseases.length > 0 || fromDate || toDate ">
		<div class="card-wrape">
			<div class="card-head">
				<h4 class="left-text"> Selected filters</h4>
			</div>
			<div class="col-lg-12">
				<div class="card-body">
					<span class="filter" *ngIf="selectedSiteCodes.length > 0">
						<label> Site Code </label>

						<div class="ctrls">
							<ul class="vic-list">
								<li *ngFor="let selectedSiteCode of selectedSiteCodes">
									<a style="cursor: pointer" title="Remove Item"
										(click)="removeItem('site_code', selectedSiteCode)" href="javascript:;">×</a>
									<span>{{ selectedSiteCode.site_code }}</span>
								</li>
							</ul>
						</div>
					</span>

					<span class="filter" *ngIf="selectedGenders.length > 0">
						<label> Gender </label>
						<div class="ctrls">
							<ul class="vic-list">
								<li *ngFor="let selectedGender of selectedGenders">
									<a style="cursor: pointer" title="Remove Item"
										(click)="removeItem('gender', selectedGender)" href="javascript:;">×</a>
									<span>{{ selectedGender | titlecase }}</span>
								</li>
							</ul>
						</div>
					</span>

					<span class="filter" *ngIf="selectedStates.length > 0">
						<label> Native State </label>
						<div class="ctrls">
							<ul class="vic-list">
								<li *ngFor="let selectedState of selectedStates">
									<a style="cursor: pointer" title="Remove Item"
										(click)="removeItem('state', selectedState)" href="javascript:;">×</a>
									<span>{{ selectedState.name }}</span>
								</li>
							</ul>
						</div>
					</span>

					<span class="filter" *ngIf="selectedSubpopulations.length > 0">
						<label> Population </label>
						<div class="ctrls">
							<ul class="vic-list">
								<li *ngFor="let selectedSubpopulation of selectedSubpopulations">
									<a style="cursor: pointer" title="Remove Item"
										(click)="removeItem('population', selectedSubpopulation)"
										href="javascript:;">×</a>
									<span>{{ selectedSubpopulation.name }}</span>
								</li>
							</ul>
						</div>
					</span>

					<span class="filter" *ngIf="selectedDiseases.length > 0">
						<label> Disease </label>
						<div class="ctrls">
							<ul class="vic-list">
								<li *ngFor="let selectedIDisease of selectedDiseases">
									<a style="cursor: pointer" title="Remove Item"
										(click)="removeItem('disease', selectedIDisease)" href="javascript:;">×</a>
									<span>{{ selectedIDisease.name }}</span>
								</li>
							</ul>
						</div>
					</span>

					<span class="filter" *ngIf="fromDate">
						<label> Start Date </label>
						<div class="ctrls">
							<ul class="vic-list">
								<li>
									<a style="cursor: pointer" title="Remove Item" (click)="clearFromDateFilter()"
										href="javascript:;">×</a>
									<span>{{ fromDate | date:'dd MMM yyyy'}}</span>
								</li>
							</ul>
						</div>
					</span>

					<span class="filter" *ngIf="toDate">
						<label> End Date </label>
						<div class="ctrls">
							<ul class="vic-list">
								<li>
									<a style="cursor: pointer" title="Remove Item" (click)="clearToDateFilter()"
										href="javascript:;">×</a>
									<span>{{ toDate | date:'dd MMM yyyy' }}</span>
								</li>
							</ul>
						</div>
					</span>

				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<mat-spinner *ngIf="loading"></mat-spinner>
		<div class="col-xl-6 col-md-6">
			<app-disease-chart #loadDiseaseChart></app-disease-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<app-participant-count-chart #loadParticipantCountChart></app-participant-count-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<app-site-wise-participant-counts #loadSiteChart></app-site-wise-participant-counts>
		</div>
		<div class="col-xl-6 col-md-6">
			<app-population-chart #loadPopulationChart></app-population-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<app-state-chart #loadStateChart></app-state-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<app-gender-chart #loadGenderChart></app-gender-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<ngx-state-map-chart #loadStateMapChart></ngx-state-map-chart>
		</div>
		<div class="col-xl-6 col-md-6">
			<ngx-latest-sites #loadLatestSites></ngx-latest-sites>
		</div>
	</div>
</section>

<section class="filter-wrape toggle-close" [ngClass]="status ? 'toggle-open' : 'toggle-close'">
	<button class="btn filter-btn" (click)="clickEvent()"><i></i></button>
	<div class="filter-section">
		<div class="title">
			<h4 class="title-main"> Chart Filter </h4>
			<button class="close-btn" (click)="clickEvent()">×</button>
		</div>
		<form [formGroup]="dashboardFilterForm" #form="ngForm" (ngSubmit)="filterDashboardData()">
			<ngx-simplebar class="sidebar-slider">
				<div class="sidebar-content">
					<div class="scroll-area sidebar-slider">
						<div class="input-wrape">
							<mat-label>Site Code</mat-label>
							<div class="control-pad">
								<ng-select [items]="sites" [dropdownPosition]="'bottom'" [searchable]="true"
									[multiple]="true" formControlName="siteCodes" bindLabel="site_code" bindValue="id"
									placeholder="Select site code" (add)="addSelectedItem('site_code', $event)"
									(clear)="clearSelectedItem('site_code')"
									(remove)="removeSelectedItem('site_code', $event)" #selectSiteCode>
								</ng-select>
							</div>
						</div>
						<div class="input-wrape">
							<mat-label>Gender</mat-label>
							<div class="control-pad">
								<ng-select formControlName="genders" [dropdownPosition]="'bottom'" [searchable]="false"
									[multiple]="true" bindValue="id" placeholder="Select gender"
									(add)="addSelectedItem('gender', $event)" (clear)="clearSelectedItem('gender')"
									(remove)="removeSelectedItem('gender', $event)" #selectGender>
									<ng-option *ngFor="let gender of genders" [value]="gender">{{ gender | titlecase
										}}</ng-option>
								</ng-select>
							</div>
						</div>
						<div class="input-wrape">
							<mat-label>Native State</mat-label>
							<div class="control-pad">
								<ng-select formControlName="states" [searchable]="true" [multiple]="true"
									[dropdownPosition]="'bottom'" [items]="states" bindLabel="name" bindValue="id"
									placeholder="Select native state" (add)="addSelectedItem('state', $event)"
									(clear)="clearSelectedItem('state')" (remove)="removeSelectedItem('state', $event)"
									#selectState>
								</ng-select>
							</div>
						</div>
						<div class="input-wrape">
							<mat-label>Population</mat-label>
							<div class="control-pad">
								<ng-select formControlName="populations" [items]="populations"
									[dropdownPosition]="'bottom'" [searchable]="true" [multiple]="true"
									[virtualScroll]="true" bindLabel="name" bindValue="id"
									placeholder="Select population" (add)="addSelectedItem('population', $event)"
									(clear)="clearSelectedItem('population')"
									(remove)="removeSelectedItem('population', $event)" #selectPopulation>
								</ng-select>
							</div>
						</div>
						<div class="input-wrape">
							<mat-label>Disease</mat-label>
							<div class="control-pad">
								<ng-select formControlName="diseases" [items]="diseases" [dropdownPosition]="'bottom'"
									[searchable]="true" [multiple]="true" [virtualScroll]="true" bindLabel="name"
									bindValue="id" placeholder="Select disease"
									(add)="addSelectedItem('disease', $event)" (clear)="clearSelectedItem('disease')"
									(remove)="removeSelectedItem('disease', $event)" #selectDisease>
								</ng-select>
							</div>
						</div>
						<div class="input-wrape calendar-wrape">
							<mat-label>Collection Start Date</mat-label>
							<mat-form-field class="example-full-width" appearance="outline">
								<input matInput [matDatepicker]="picker" (click)="picker.open()" [max]="minDate"
									formControlName="fromDate" placeholder="Select date"
									(dateChange)="compareTwoDates('fromDate', $event)">
								<mat-datepicker-toggle matSuffix [for]="picker">
									<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
								</mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>
						</div>
						<div class="input-wrape calendar-wrape">
							<mat-label>Collection End Date</mat-label>
							<mat-form-field class="example-full-width" appearance="outline">
								<input matInput [matDatepicker]="pickers" (click)="pickers.open()" [max]="minDate"
									placeholder="Select date" formControlName="toDate"
									(dateChange)="compareTwoDates('toDate', $event)">
								<mat-datepicker-toggle matSuffix [for]="pickers">
									<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
								</mat-datepicker-toggle>
								<mat-datepicker #pickers></mat-datepicker>
							</mat-form-field>
						</div>
					</div>
				</div>
			</ngx-simplebar>
			<div class="footer-btn">
				<button mat-raised-button class="btn" color="warn" style="margin: 0 5px 5px 0;"
					(click)="clearForm()">Clear All</button>
				<button type="submit" mat-raised-button [disabled]="disableFilterSubmitBtn" class="btn"
					color="secoundary" style="margin: 0 5px 5px 0;">Apply</button>
			</div>
		</form>
	</div>
</section>
<div class="filter-overlay"></div>