<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1>{{title}}</h1>
        <ul class="bradcrumb">
            <li>{{title}}</li>
            <li><a routerLink="/htr" title="Ctate" class="only-link">Manage HTR</a></li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>
</section>


<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="stat-detail-wrape">
                <div class="column">
                    <p class="detail">
                        <b>First Name</b>
                        <span>{{participantHeaderDetails?.first_name ? participantHeaderDetails?.first_name :
                            '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>Middle Name</b>
                        <span>{{participantHeaderDetails?.middle_name ? participantHeaderDetails?.middle_name :
                            '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>Last Name</b>
                        <span>{{participantHeaderDetails?.last_name ? participantHeaderDetails?.last_name : '-'}}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>Email ID</b>
                        <span>{{participantHeaderDetails?.email ? participantHeaderDetails?.email : '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>Gender</b>
                        <span>{{participantHeaderDetails?.gender ? participantHeaderDetails?.gender : '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>Age</b>
                        <span>{{participantHeaderDetails?.age ? participantHeaderDetails?.age : '-'}}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>New PID</b>
                        <span>{{participantHeaderDetails?.new_pid ? participantHeaderDetails?.new_pid : '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>New Recollection ID</b>
                        <span>{{participantHeaderDetails?.new_recollection_id ?
                            participantHeaderDetails?.new_recollection_id : '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>DOB</b>
                        <span>{{participantHeaderDetails?.date_of_birth ? participantHeaderDetails?.date_of_birth :
                            '-'}}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>Collection Date</b>
                        <span>{{participantHeaderDetails?.collection_date ? participantHeaderDetails?.collection_date :
                            '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>City</b>
                        <span>{{participantHeaderDetails?.residential_city ? participantHeaderDetails?.residential_city
                            : '-'}}</span>
                    </p>
                    <p class="detail">
                        <b>Address</b>
                        <span>{{participantHeaderDetails?.residential_address ?
                            participantHeaderDetails?.residential_address : '-'}}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>Site</b>
                        <span>{{participantHeaderDetails?.site_name ? participantHeaderDetails?.site_name : '-'}}</span>
                    </p>
                    <p *ngIf="htrID" class="detail">
                        <b>{{qcedAt}}</b>
                        <a *ngIf="!isQced && !isViewMode" href="javascript:void(0);" (click)="onMarkedQC()" class="btn"
                            color="secoundary">Mark as QC'ed</a>
                    </p>
                    <p *ngIf="htrID" class="detail">
                        <b>{{reviewedAt}}</b>
                        <a *ngIf="!isReviewed && !isViewMode" href="javascript:void(0);" (click)="onMarkedReviewed()"
                            class="btn" color="secoundary">Mark as Reviewed</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<form [formGroup]="htrForm" #form="ngForm" [class]="isViewMode ? 'view-mode' : 'edit-mode'" (ngSubmit)="onSubmit()">
    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <mat-accordion class="example-headers-align">
                    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                HTR Basic Information
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape"
                                            [ngClass]="{ 'has-error': htrForm.controls['htr_informations'].get('participant_id')?.invalid && (htrForm.controls['htr_informations'].get('participant_id')?.dirty || htrForm.controls['htr_informations'].get('participant_id')?.touched)}">
                                            <mat-label><i>*</i>Participant ID:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input type="text" placeholder="Enter Participant ID"
                                                    aria-label="Number" matInput formControlName="participant_id"
                                                    [matAutocomplete]="participantSearch">
                                                <mat-autocomplete autoActiveFirstOption
                                                    #participantSearch="matAutocomplete"
                                                    [displayWith]="getParticipantsData.bind(this)">
                                                    <mat-option *ngFor="let option of participantsFiltered | async"
                                                        [value]="option.id">
                                                        {{option.new_pid}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <span
                                                *ngIf="htrForm.controls['htr_informations'].get('participant_id')?.invalid && (htrForm.controls['htr_informations'].get('participant_id')?.dirty || htrForm.controls['htr_informations'].get('participant_id')?.touched)">
                                                <mat-error
                                                    *ngIf="htrForm.controls['htr_informations'].get('participant_id')?.errors?.['required']">
                                                    Participant ID is required
                                                </mat-error>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Patient Name:</mat-label>
                                            <mat-form-field appearance="outline" class="disabled">
                                                <input matInput #input type="text" formControlName="patient_name"
                                                    placeholder="Enter patient name">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape"
                                            [ngClass]="{ 'has-error': htrForm.controls['htr_informations'].get('health_care_center')?.invalid && (htrForm.controls['htr_informations'].get('health_care_center')?.dirty || htrForm.controls['htr_informations'].get('health_care_center')?.touched)}">
                                            <mat-label><i>*</i>Diagnostic Lab:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input type="text" formControlName="health_care_center"
                                                    placeholder="Enter diagnostic lab" value="">
                                            </mat-form-field>
                                            <span
                                                *ngIf="htrForm.controls['htr_informations'].get('health_care_center')?.invalid && (htrForm.controls['htr_informations'].get('health_care_center')?.dirty || htrForm.controls['htr_informations'].get('health_care_center')?.touched)">
                                                <mat-error
                                                    *ngIf="htrForm.controls['htr_informations'].get('health_care_center')?.errors?.['required']">
                                                    Diagnostic Lab is required
                                                </mat-error>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>LR No.:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="lr_no" type="text"
                                                    placeholder="Enter LR no">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label>HTR Yes/No:</mat-label>
                                            <mat-radio-group formControlName="htr_available">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Glycosylated/Glycated Hemoglobin
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Glycosylated Test Data:</mat-label>
                                            <mat-radio-group formControlName="glycated_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="glycated_hemoglobins"
                                    *ngIf="htrForm.controls['htr_informations'].value.glycated_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HbA1c (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="hba1c"
                                                    placeholder="Enter HbA1c (%)" type="number">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Estimated Average Glucose (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="estimated_average_glucose"
                                                    placeholder="Enter estimated average glucose (mg/dL):"
                                                    type="number">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Mean Plasma Glucose (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="mean_plasma_glucose"
                                                    placeholder="Enter mean plasma glucose (mg/dL)" type="number">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <!-- remove/modify htr-columns -->
                    <!--<mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Glucose Test/Blood Sugar Random
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                 <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Glucose Test Data: </mat-label>
                                            <mat-radio-group formControlName="glucose_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="glucose_tests"
                                    *ngIf="htrForm.controls['htr_informations'].value.glucose_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                     <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Fasting Mean Blood (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter fasting mean blood (mg/dL)"
                                                    type="number" formControlName="fasting_mean_blood">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blast:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blast" type="number"
                                                    formControlName="blast">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Random Average (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter random average (mg/dL)"
                                                    type="number" formControlName="random_average">
                                            </mat-form-field>
                                        </div>
                                    </div> 
                                </div> 
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Bloodsugar Fastings
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Bloodsugar Fastings Test Data:</mat-label>
                                            <mat-radio-group formControlName="bloodsugar_fastings_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="blood_sugar_fasting"
                                    *ngIf="htrForm.controls['htr_informations'].value.bloodsugar_fastings_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Fasting Blood Sugar (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter fasting blood sugar (mg/dL)"
                                                    type="number" formControlName="fasting_blood_sugar">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Post Lunch (PP) Blood Sugar (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter post lunch (PP) blood sugar (mg/dL)"
                                                    type="number" formControlName="post_lunch_blood_sugar">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                     <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Random Blood Sugar (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter random blood sugar (mg/dL)"
                                                    type="number" formControlName="random_blood_sugar">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Post Prandial(PP) (2 hrs. after Lunch) (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter post prandial(PP) (2 hrs. after lunch) (mg/dL)"
                                                    type="number" formControlName="post_prandial">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Complete Blood Count
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Complete Blood Count Test Data:</mat-label>
                                            <mat-radio-group formControlName="complete_blood_count_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="complete_blood_counts"
                                    *ngIf="htrForm.controls['htr_informations'].value.complete_blood_count_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Haemoglobin (gm/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter haemoglobin (gm/dL)"
                                                    type="number" formControlName="haemoglobin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Basophils (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter basophils (%)" type="number"
                                                    formControlName="basophils">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RDW (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RDW (%)" type="number"
                                                    formControlName="rdw">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Epithelial Cells (Microscopic Examination):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter epithelial cells (microscopic examination)"
                                                    type="text" formControlName="epithelial_cells_microscopic">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total RBC Count (10^12/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total RBC count (10^12/L)"
                                                    type="number" formControlName="total_rbc_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Granulocytes(%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter granulocytes(%):"
                                                    type="number" formControlName="granulocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RDW-CV (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RDW-CV (%)" type="number"
                                                    formControlName="rdw_cv">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Peripheral Smear Platelets:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter peripheral smear platelets"
                                                    type="text" formControlName="peripheral_smear_platelets">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total WBC Count (/cmm):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total WBC count (/cmm)"
                                                    type="number" formControlName="total_wbc_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Monocytes (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter monocytes (%):" type="number"
                                                    formControlName="monocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RDW-SD (fL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RDW-SD (fL)" type="number"
                                                    formControlName="rdw_sd">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Peripheral Smear WBC:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter peripheral smear WBC"
                                                    type="text" formControlName="peripheral_smear_wbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hematocrit (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter hematocrit (%)" type="number"
                                                    formControlName="hematocrit">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Basophil Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute basophil count (10^3/uL)" type="number"
                                                    formControlName="absolute_basophil_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>PDW (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter PDW (%)" type="number"
                                                    formControlName="pdw">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Thalassemia check:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter thalassemia check" type="text"
                                                    formControlName="thalassemia_check">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MCV (fl):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MCV (fl)" type="number"
                                                    formControlName="mcv">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Eosinophil Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute eosinophil count (10^3/uL)"
                                                    type="number" formControlName="absolute_eosinophil_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>P-LCC (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter P-LCC (10^3/uL)" type="number"
                                                    formControlName="plcc_10_3">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Platelet morphology:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter platelet morphology"
                                                    type="text" formControlName="platelet_morphology">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MCH (pg):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MCH (pg)" type="number"
                                                    formControlName="mch">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Lymphocyte Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute lymphocyte count (10^3/uL)"
                                                    type="number" formControlName="absolute_lymphocyte_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>P-LCR (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter P-LCR (%)" type="number"
                                                    formControlName="plcr">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Leucocyte (Microscopic Examination):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter leucocyte (microscopic examination)" type="text"
                                                    formControlName="leucocyte_microscopic_examination">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MCHC (g/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MCHC (g/dL)" type="number"
                                                    formControlName="mchc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Monocyte Count (10^3/uL2):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute monocyte count (10^3/uL2)" type="number"
                                                    formControlName="absolute_monocyte_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>PCT (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter PCT (%)" type="number"
                                                    formControlName="pct">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RBC (Microscopic Examination):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RBC (microscopic examination)"
                                                    type="text" formControlName="rbc_microscopic">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Neutrophils (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter neutrophils (%)" type="number"
                                                    formControlName="neutrophils">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Neutrophil Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute neutrophil count (10^3/uL)"
                                                    type="number" formControlName="absolute_neutrophil_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Morphology of Red Blood Cell/ Peripheral smear RBC :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter morphology of red blood cell/ peripheral smear RBC"
                                                    type="text" formControlName="morphology_red_blood_cell">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Granulocyte Count:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter absolute granulocyte count"
                                                    type="number" formControlName="granulocytes_10_3">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Lymphocytes (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter lymphocytes (%)" type="number"
                                                    formControlName="lymphocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Platelet Counts (/cmm):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter platelet counts (/cmm)"
                                                    type="number" formControlName="platelet_counts">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Morphology of WBC:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter morphology of WBC" type="text"
                                                    formControlName="morphology_wbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Immature Granulocyte (IG):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter immature granulocyte (IG)"
                                                    type="number" formControlName="immature_granulocyte">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Eosinophils (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter eosinophils (%)" type="number"
                                                    formControlName="eosinophils">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MPV (fl):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MPV (fl)" type="number"
                                                    formControlName="mpv">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Platelets on smear:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter platelets on smear"
                                                    type="text" formControlName="platelets_on_smear">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Nucleated RBC (NRBC):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter nucleated RBC (NRBC)"
                                                    type="number" formControlName="nucleated_rbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Neutrophil Lymphocyte Ratio (NLR):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter neutrophil lymphocyte ratio (NLR)" type="number"
                                                    formControlName="neutrophil_lymphocyte_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Erythrocyte Sedimentation Rate, Blood Sedimentation Rate
                                                (ESRmm/HR):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter erythrocyte sedimentation rate, Blood sedimentation rate (ESRmm/HR)"
                                                    type="number" formControlName="erythrocyte_sedimentation_rate">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Mentzer Index:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter Mentzer Index" type="text"
                                                    formControlName="mentzer_index">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>PDW (fL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter PDW (fL)" type="number"
                                                    formControlName="pdw_fl">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Lipid Profile
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Lipid Profile Test Data: </mat-label>
                                            <mat-radio-group formControlName="lipid_profile_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="lipid_profiles"
                                    *ngIf="htrForm.controls['htr_informations'].value.lipid_profile_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Appearance Other:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter appearance other" type="text"
                                                    formControlName="appearance_other">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Appearance of Serum on Refrigeration:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter appearance of serum on refrigeration" type="text"
                                                    formControlName="apperance_serum_refrigeration">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HDL (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter HDL (mg/dL)" type="number"
                                                    formControlName="hdl">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Triglyceride (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum triglyceride (mg/dL)"
                                                    type="number" formControlName="serum_triglyceride">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Naked Eye Appearance of Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter naked eye appearance of serum"
                                                    type="text" formControlName="naked_eye_appearance_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>LDL CHOL/HDL ratio (Range 0 - 3.5):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter LDL CHOL/HDL ratio (range 0 - 3.5)" type="number"
                                                    formControlName="ldl_chol_hdl_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Lipid (mg/100ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total lipid (mg/100ml)"
                                                    type="number" formControlName="total_lipid">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>LDL Cholesterol (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter LDL cholesterol (mg/dL)"
                                                    type="number" formControlName="ldl_cholesterol">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Cholesterol(mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum cholesterol(mg/dL)"
                                                    type="number" formControlName="serum_cholesterol">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>LDL/ HDL RATIO:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter LDL/ HDL RATIO" type="number"
                                                    formControlName="ldl_hdl_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HDL Cholesterol - Direct (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter HDL cholesterol - direct (mg/dL)" type="number"
                                                    formControlName="hdl_cholesterol_direct">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Chol:HDL Cholesterol Ratio:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter chol:HDL cholesterol ratio"
                                                    type="number" formControlName="hdl_cholesterol_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HDL cholesterol plus 3rd gen.(mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter HDL cholesterol plus 3rd gen.(mg/dL)"
                                                    type="number" formControlName="hdl_cholesterol_plus">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Triglyceride Enzymatic (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter triglyceride enzymatic (mg/dL)" type="number"
                                                    formControlName="triglyceride_enzymatic">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>NON-HDL CHOL (mg/mL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter NON-HDL CHOL (mg/mL)"
                                                    type="number" formControlName="non_hdl_chol">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>TC/ HDL CHOL RATIO:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter TC/ HDL CHOL RATIO"
                                                    type="number" formControlName="tc_hdl_chol_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Cholesterol LDL - Direct (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter cholesterol LDL - direct (mg/dL)" type="number"
                                                    formControlName="cholesterol_ldl_direct">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>V L D L Cholesterol (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter V L D L cholesterol (mg/dL)"
                                                    type="number" formControlName="vldl_cholesterol">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Triglyceride Liquid (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter triglyceride liquid (mg/dL)"
                                                    type="number" formControlName="triglyceride_liquid">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Cholesterol Gen.2 (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter cholesterol gen.2 (mg/dL)"
                                                    type="number" formControlName="cholesterol_gen_2">
                                            </mat-form-field>
                                        </div>
                                    </div>  -->
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Thyroid Report/Thyroid Function Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Thyroid Report Test Data: </mat-label>
                                            <mat-radio-group formControlName="thyroid_report_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="thyroid_reports"
                                    *ngIf="htrForm.controls['htr_informations'].value.thyroid_report_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Triidothyronine (T3) (ng/ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter triidothyronine (T3) (ng/ml)"
                                                    type="number" formControlName="triidothyronine">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Thyroid Stimulating Hormone (TSH) (µlU/ml) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter thyroid stimulating hormone (TSH) (µlU/ml)"
                                                    type="number" formControlName="thyroid_stimulating_hormone">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Thyroxine (T4) (µg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total thyroxine (T4) (µg/dL)"
                                                    type="number" formControlName="total_thyroxine">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Creatinine
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Creatinine Test Data: </mat-label>
                                            <mat-radio-group formControlName="creatinine_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="creatinines"
                                    *ngIf="htrForm.controls['htr_informations'].value.creatinine_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Creatinine (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum creatinine (mg/dL)"
                                                    type="number" formControlName="serum_creatinine">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Est. Glomerular Filtration Rate (mL/min/1.73 m2) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter Est. glomerular filtration rate (mL/min/1.73 m2)"
                                                    type="number" formControlName="est_glomerular_filter_rate">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Cystatin C (mg/L) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter Cystatin C (mg/L)"
                                                    type="number" formControlName="cystatin_c">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Phosphorous, Serum
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Phosphorous, Serum Test Data: </mat-label>
                                            <mat-radio-group formControlName="phosphorous_serum_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="phosphorous_serums"
                                    *ngIf="htrForm.controls['htr_informations'].value.phosphorous_serum_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Phosphorous (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter phosphorous (mg/dL)"
                                                    type="number" formControlName="phosphorous">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Electrolytes, Serum
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Electrolytes, Serum Test Data: </mat-label>
                                            <mat-radio-group formControlName="electrolytes_serum_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="electrolytes_serums"
                                    *ngIf="htrForm.controls['htr_informations'].value.electrolytes_serum_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Sodium (mmol/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter sodium (mmol/L)" type="number"
                                                    formControlName="sodium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Potassium (mmol/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter potassium (mmol/L):"
                                                    type="number" formControlName="potassium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Chloride (mmol/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter chloride (mmol/L)"
                                                    type="number" formControlName="chloride">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Statin Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Statin Test Data: </mat-label>
                                            <mat-radio-group formControlName="statin_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="statin_reports"
                                    *ngIf="htrForm.controls['htr_informations'].value.statin_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Allele Tested:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter allele tested" type="text"
                                                    formControlName="allele_tested">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>SLCO1B1:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter SLCO1B1" type="text"
                                                    formControlName="slco1b1">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>SLCO1B1 Genotype Result:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter SLCO1B1 genotype result"
                                                    type="text" formControlName="slco1b1_genotype_result">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Hepatitis B Surface Antibody (Quantitative)
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Hepatitis B Test Data: </mat-label>
                                            <mat-radio-group formControlName="hepatitis_b_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="hepatitis_b_surfaces"
                                    *ngIf="htrForm.controls['htr_informations'].value.hepatitis_b_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis B Surface Antibody (Quantitative) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter hepatitis B surface antibody (quantitative)"
                                                    type="text" formControlName="hepatitis_b_surface">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <!-- remove/modify htr-columns -->
                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Vitamin B12
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Vitamin B12 Test Data: </mat-label>
                                            <mat-radio-group formControlName="vitamin_b12_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div> 

                                <div class="row" formArrayName="vitamin_b_twelves"
                                    *ngIf="htrForm.controls['htr_informations'].value.vitamin_b12_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Vitamin B12:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter vitamin B12" type="number"
                                                    formControlName="vitamin_b_twelve">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> 
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Blood Grouping
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Blood Grouping Test Data: </mat-label>
                                            <mat-radio-group formControlName="blood_grouping_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="blood_groupings"
                                    *ngIf="htrForm.controls['htr_informations'].value.blood_grouping_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>ABO Group:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter ABO group" type="text"
                                                    formControlName="abo_group">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RH Type:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RH type" type="text"
                                                    formControlName="rh_type">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Group:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood group" type="text"
                                                    formControlName="blood_group">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Liver Function Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Liver Function Test Data: </mat-label>
                                            <mat-radio-group formControlName="liver_function_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="liver_function_tests"
                                    *ngIf="htrForm.controls['htr_informations'].value.liver_function_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Indirect:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin indirect"
                                                    type="number" formControlName="bilirubin_indirect">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Albumin:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter albumin" type="number"
                                                    formControlName="albumin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Alkaline Phosphatase:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter alkaline phosphatase"
                                                    type="number" formControlName="alkaline_phosphatase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Direct:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin direct" type="text"
                                                    formControlName="bilirubin_direct">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Total:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin total" type="text"
                                                    formControlName="bilirubin_total">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Aspartate Aminotransferase (Sgot):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter aspartate aminotransferase (Sgot)" type="number"
                                                    formControlName="aspartate_aminotransferase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Alanine Aminotransferase (Sgpt):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter alanine aminotransferase (Sgpt)" type="number"
                                                    formControlName="alanine_aminotransferase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>A:G Ratio:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter A:G ratio" type="number"
                                                    formControlName="ag_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Globulin:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter globulin" type="number"
                                                    formControlName="globulin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Total Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin total method"
                                                    type="text" formControlName="bilirubin_total_method">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Albumin Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter albumin method" type="text"
                                                    formControlName="albumin_method">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Alkaline Phosphatase Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter alkaline phosphatase method"
                                                    type="text" formControlName="alkaline_phosphatase_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Protein:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total protein" type="number"
                                                    formControlName="total_protein">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Protein Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total protein method"
                                                    type="text" formControlName="total_protein_method">
                                            </mat-form-field>
                                        </div>
                                    </div> 
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Direct Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin direct method"
                                                    type="text" formControlName="bilirubin_direct_method">
                                            </mat-form-field>
                                        </div>
                                    </div> 
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Aspartate Aminotransferase (SGOT) Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter aspartate aminotransferase (SGOT) method"
                                                    type="text" formControlName="aspartate_aminotransferase_method">
                                            </mat-form-field>
                                        </div>
                                    </div> 
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Alanine Aminotransferase (SGPT) Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter alanine aminotransferase (SGPT) method"
                                                    type="text" formControlName="alanine_aminotransferase_method">
                                            </mat-form-field>
                                        </div>
                                    </div> 
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>A:G Ratio Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter A:G ratio method" type="text"
                                                    formControlName="ag_ratio_method">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Globulin Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter globulin method" type="text"
                                                    formControlName="globulin_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Uric Acid Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Uric Acid Test Data:</mat-label>
                                            <mat-radio-group formControlName="uric_acid_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="uric_acid_tests"
                                    *ngIf="htrForm.controls['htr_informations'].value.uric_acid_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Urea Nitrogen:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood urea nitrogen"
                                                    type="number" formControlName="blood_urea_nitrogen">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Uric Acid:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter uric acid" type="number"
                                                    formControlName="uric_acid">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Urea mg/dL:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood urea mg/dL"
                                                    type="number" formControlName="blood_urea">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Urea Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood urea method" type="text"
                                                    formControlName="blood_urea_method">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Urea Nitrogen Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood urea nitrogen method"
                                                    type="text" formControlName="blood_urea_nitrogen_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Protein Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Protein Test Data: </mat-label>
                                            <mat-radio-group formControlName="protein_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="protein_tests"
                                    *ngIf="htrForm.controls['htr_informations'].value.protein_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>C-Reactive Protein:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter c-reactive protein"
                                                    type="text" formControlName="creactive_protein">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Gamma Glutamyl Transferase:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter gamma glutamyl transferase"
                                                    type="number" formControlName="gamma_glutamyl_transferase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Protein Total:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter protein total" type="number"
                                                    formControlName="protein_total">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <!-- remove/modify htr-columns -->
                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                25-Hydroxy Vitamin D
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                 <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Hydroxy Vitamin D Test Data: </mat-label>
                                            <mat-radio-group formControlName="hydroxy_vitamin_d_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="hydroxy_vitamin_ds"
                                    *ngIf="htrForm.controls['htr_informations'].value.hydroxy_vitamin_d_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>25-Hydroxy Vitamin D:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter 25-hydroxy vitamin D"
                                                    type="number" formControlName="hydroxy_vitamin_d">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Calcium
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Calcium Test Data: </mat-label>
                                            <mat-radio-group formControlName="calcium_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="calciums"
                                    *ngIf="htrForm.controls['htr_informations'].value.calcium_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Calcium (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter calcium (mg/dL)" type="number"
                                                    formControlName="calcium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <!-- remove/modify htr-columns -->
                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Differential Blood Count
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                 <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Differential Blood Count Test Data: </mat-label>
                                            <mat-radio-group formControlName="differential_blood_count_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="differential_blood_counts"
                                    *ngIf="htrForm.controls['htr_informations'].value.differential_blood_count_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Promyelocyte:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter promyelocyte" type="text"
                                                    formControlName="promyelocyte">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Myelocyte:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter myelocyte" type="text"
                                                    formControlName="myelocyte">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Metamyelocyte:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter metamyelocyte" type="text"
                                                    formControlName="metamyelocyte">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Band Form:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter band form" type="text"
                                                    formControlName="band_form">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Promonocyte:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter promonocyte" type="text"
                                                    formControlName="promonocyte">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Prolymphocytes:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter prolymphocytes" type="text"
                                                    formControlName="prolymphocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div> 
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel> -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Rheumatoid Arthritis Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Rheumatoid Arthritis Test Data: </mat-label>
                                            <mat-radio-group formControlName="rheumatoid_arthritis_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="rheumatoid_arthritis"
                                    *ngIf="htrForm.controls['htr_informations'].value.rheumatoid_arthritis_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RA Factor:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RA factor" type="text"
                                                    formControlName="ra_factor">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Complete Urine Examinations
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Complete Urine Test Data:</mat-label>
                                            <mat-radio-group formControlName="complete_urine_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="complete_urine_examination"
                                    *ngIf="htrForm.controls['htr_informations'].value.complete_urine_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Quantity (ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter quantity (ml)" type="number"
                                                    formControlName="quantity">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Color:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter color" type="text"
                                                    formControlName="color">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Appearance:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter appearance" type="text"
                                                    formControlName="apperance">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- remove/modify htr-columns -->
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Appearance Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter appearance method" type="text"
                                                    formControlName="apperance_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>pH:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter pH" type="text"
                                                    formControlName="ph">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>pH Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter pH method" type="text"
                                                    formControlName="ph_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Specific Gravity:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter specific gravity" type="text"
                                                    formControlName="specific_gravity">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Specific Gravity Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter specific gravity method"
                                                    type="text" formControlName="specific_gravity_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Protein:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter protein" type="text"
                                                    formControlName="protein">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Protein Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter protein method" type="text"
                                                    formControlName="protein_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Glucose:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter glucose" type="text"
                                                    formControlName="glucose">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Glucose Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter glucose method" type="text"
                                                    formControlName="glucose_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Ketones:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter ketones" type="text"
                                                    formControlName="ketones">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Ketones Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter ketones method" type="text"
                                                    formControlName="ketones_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin" type="text"
                                                    formControlName="bilirubin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin method" type="text"
                                                    formControlName="bilirubin_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Urobilinogen:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter urobilinogen" type="text"
                                                    formControlName="urobilinogen">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Urobilinogen Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter urobilinogen method"
                                                    type="text" formControlName="urobilinogen_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood" type="text"
                                                    formControlName="blood">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood method" type="text"
                                                    formControlName="blood_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Nitrite:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter nitrite" type="text"
                                                    formControlName="nitrite">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Nitrite Method:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter nitrite method" type="text"
                                                    formControlName="nitrite_method">
                                            </mat-form-field>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>WBC (/HPF):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter WBC (/HPF)" type="text"
                                                    formControlName="wbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Epithelial Cells (/HPF):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter epithelial cells (/HPF)"
                                                    type="text" formControlName="epithelial_cells">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Red Blood Cells:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter red blood cells" type="text"
                                                    formControlName="red_blood_cells">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Casts:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter casts" type="text"
                                                    formControlName="casts_hpf">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Crystals:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter crystals" type="text"
                                                    formControlName="crystals">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bacteria:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bacteria" type="text"
                                                    formControlName="bacteria">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Yeast:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter Yeast" type="text"
                                                    formControlName="yeast">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>


                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Vitamin D Total
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Vitamin D Total Test Data: </mat-label>
                                            <mat-radio-group formControlName="vitamin_d_total_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="vitamin_d_total"
                                    *ngIf="htrForm.controls['htr_informations'].value.vitamin_d_total_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Vitamin D Total (ng/ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter vitamin D Total (ng/ml)"
                                                    type="number" formControlName="vitamin_d_total">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <!-- remove/modify htr-columns -->
                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Serum Calcium
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Serum Calcium Test Data: </mat-label>
                                            <mat-radio-group formControlName="serum_calcium_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div> 

                                <div class="row" formArrayName="serum_calcium"
                                    *ngIf="htrForm.controls['htr_informations'].value.serum_calcium_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Calcium (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum calcium (mg/dL)"
                                                    type="number" formControlName="serum_calcium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>  -->

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Infectious Panel
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row" formArrayName="htr_informations">
                                    <div class="col-xl-12 col-md-12 m-b-0">
                                        <div class="input-wrape rc-wrape">
                                            <mat-label><i>*</i>Infectious Panel Test Data: </mat-label>
                                            <mat-radio-group formControlName="infectious_panel_test_data"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" formArrayName="infectious_panels"
                                    *ngIf="htrForm.controls['htr_informations'].value.infectious_panel_test_data=='yes'">
                                    <input type="hidden" value="" id="id" formControlName="id">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HIV 4th Gene Assay (P24AG+HIV AB), Serum (Index Value):
                                            </mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter HIV 4th Gene Assay (P24AG+HIV AB), Serum (Index Value)"
                                                    type="number"
                                                    formControlName="hiv_4th_gene_assay_serum_index_value">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HIV 4th Gene Assay (P24AG+HIV AB), Serum (Result):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter HIV 4th gene assay (P24AG+HIV AB), serum (result)"
                                                    type="text" formControlName="hiv_4th_gene_assay_serum_result">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HIV 1 & 2 Antibodies Screening Test, Serum (Result):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="HIV 1 & 2 antibodies screening test, serum (result)"
                                                    type="text"
                                                    formControlName="hiv_1_2_antibodies_screening_test_serum_result">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis B Surface Antigen, Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter hepatitis B surface antigen, serum" type="text"
                                                    formControlName="hepatitis_b_surface_antigen_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis C Antibodies, Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter hepatitis C antibodies, serum"
                                                    type="text" formControlName="hepatitis_c_antibodies_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis C Antibodies, Serum (Patient Value):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter hepatitis C antibodies, serum (patient value)"
                                                    type="number"
                                                    formControlName="hepatitis_c_antibodies_serum_patient_value">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>VDRL/RPR, Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter VDRL/RPR, serum" type="text"
                                                    formControlName="vdrlrpr_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Malaria Parasite/Blood Parasite Smear Identification:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter malaria parasite/blood parasite smear identification"
                                                    type="text"
                                                    formControlName="malaria_parasiteblood_parasite_smear_identification">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                HTR Documents
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="card-body">
                                    <mat-spinner *ngIf="loading"></mat-spinner>
                                    <div class="progress-overlay"></div>
                                    <div class="row" *ngIf="albums.length>0">
                                        <div class="col-xl-3 col-md-3" *ngFor="let report of albums; index as i"
                                            id="staticImage_{{ i }}">
                                            <!-- <h6>{{report | json}}</h6> -->
                                            <div class="input-wrape">
                                                <mat-label>Document {{ i + 1 }}</mat-label>
                                                <div class="img-wrape pmr_pdf icon-wrape-preview thumb-upload">
                                                    <div>
                                                        <span mat-button *ngIf="report.fileType.toLowerCase() != 'pdf'"
                                                            appNoRightClick>
                                                            <img src="{{ report.thumb }}" alt="Participant Document"
                                                                class="grid_img">
                                                        </span>
                                                        <span mat-button *ngIf="report.fileType.toLowerCase() == 'pdf'"
                                                            appNoRightClick>
                                                            <a href="{{report.path}}" target="_blank">
                                                                <div mat-button
                                                                    class="icon-wrape-preview choosen_file_div">
                                                                    <span class="icon pdf"></span>
                                                                    <div class="upload-wrape"></div>
                                                                </div>
                                                            </a>
                                                            <a href="javascript:;"
                                                                class="theme-icon download-icon participant-docs"
                                                                title="Download HTR Document"
                                                                (click)="downloadMedicalRecordDoc(report)"></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="no-data-found" *ngIf="albums.length<=0">
                                        <div class="no-data-container">
                                            <div class="no-data-icon"></div>
                                            <div class="no-data-text">HTR Documents not found</div>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div class="invidiual-btn-wrape">
                    <button type="button" mat-raised-button class="btn" color="warn" routerLink="/htr">Cancel</button>
                    <button type="submit" mat-raised-button class="btn" *ngIf="!isViewMode"
                        color="secoundary">Save</button>
                </div>
            </div>
        </div>
    </section>
</form>