import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticLabsService extends CrudService {
  dictionaryBaseUrl: string = `${environment.baseURL}` + 'dictionary';
  constructor(public override http: HttpClient) {
    super('diagnostic-lab', http);
  }

  /**
   * Check diagnostic lab name field is unique
   *
   * @param string diagnosticLabName
   *
   * @returns object
   */
  checkUniqueDiagnosticLabName(diagnosticLabName: string, id: number) {
    return this.http.get(`${this.baseUrl}/check-unique-diagnostic-lab-name/${diagnosticLabName}/${id}`);
  }

  /**
   * get state based on country
   * @param id
   * @author Dhaval Bera
   */
  searchState(keyword: string, country: number) {
    return this.http.get(
      this.dictionaryBaseUrl +
      "/search?search_param=" +
      keyword +
      "&dictionary=states&country_id=" +
      country
    );
  }
  /**
   * get City based on state
   * @param keyword
   * @param state
   * @author Dhaval Bera
   */
  searchCity(keyword: string, state: number) {
    return this.http.get(
      this.dictionaryBaseUrl +
      "/search?search_param=" +
      keyword +
      "&dictionary=cities&state_id=" +
      state
    );
  }

}
