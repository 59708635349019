<div class="page-title-box">
  <h1>Manage Participant</h1>
  <ul class="bradcrumb">
    <li>Participant</li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<div class="stat-wrape four">
  <div class="stat-pad">
    <p>PARTICIPANTS</p>
    <h2><i class="theme-icon participants"></i>{{statistics?.totalParticipants}}</h2>
  </div>
  <div class="stat-pad">
    <p>Males</p>
    <h2><i class="theme-icon males"></i> {{statistics?.totalMaleParticipants}}</h2>
  </div>
  <div class="stat-pad">
    <p>FEMALES</p>
    <h2><i class="theme-icon females"></i> {{statistics?.totalFemaleParticipants}}</h2>
  </div>
  <div class="stat-pad table-search-wrape side-info">
    <p>Others</p>
    <h2><i class="theme-icon others"></i> {{ totalOtherParticipants }}</h2>
    <div class="info-pad popup-over">
      <button class="info-icon"></button>
      <div class="info-section popupcontent">
        <ul>
          <li>Transgender: {{statistics?.totalTransgenderParticipants}}</li>
          <li>Prefer Not To Say: {{statistics?.totalNotToSayParticipants}}</li>
          <li>Others: {{statistics?.totalOtherParticipants}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
<section class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <div class="card-wrape">
        <mat-spinner *ngIf="loading"></mat-spinner>
        <div class="progress-overlay"></div>
        <div class="card-head">
          <h4 class="left-text">Participant List</h4>
          <div class="right-control">
            <div class="table-search-wrape side-info">
              <span class="icon"></span>
              <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control" type="text">
              <div class="info-pad popup-over">
                <button class="info-icon"></button>
                <div class="info-section popupcontent">
                  ID, New PID, New Recollection ID, Site Code, Collection Date, First Name, Last Name, Age, Gender,
                  Origin State, Is PHF QC'ed, Is MR
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body table-section">
          <div class="table-wrape">
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="SortFilter($event)">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> Core ID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="new_pid">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="user-name"> New PID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="user-name"> {{element.new_pid}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="new_recollection_id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> New Recollection Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> <span>{{element.new_recollection_id}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="site_code">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Site Code </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.site_code}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="collection_date">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Collection Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.collection_date}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.first_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.last_name}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="age">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Age </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.age}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="gender">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.gender | titlecase }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="state_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Origin State </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.state_name}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="is_phf_qced">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Is PHF QC'ed </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.is_phf_qced}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="pmr_available">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Is MR </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.pmr_available}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                  <div class="icons-wrape">
                    <a routerLink='edit/{{element.id}}' *ngIf="currentMenuPermission.view == true"
                      class="theme-icon blue-bg-btn view-icon" title="View Participant" target="_blank"></a>
                    <!-- <a *ngIf="currentMenuPermission.edit == true" color="primary" class="theme-icon sync-icon"
                      (click)="reContactParticipant(element.id)" title="Recontact"></a> -->
                    <a class="theme-icon delete-icon" *ngIf="currentMenuPermission.delete == true"
                      title="Delete Participant" (click)="deleteParticipant(element.id)"></a>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
              <div *matNoDataRow>{{matNoDataRowTitle}}</div>
            </mat-table>
            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
              showFirstLastButtons>
            </mat-paginator>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>