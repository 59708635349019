import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
    providedIn: 'root',
})
export class SiteDayWiseService extends CrudService {

    constructor(public override http: HttpClient) {
        super('site-day-wise', http);
    }

    /**
  * Get All Site
  */
    // getSitesDayWiseBySiteId(siteId: any) {
    //     return this.http.get(`${this.baseUrl}/list/${siteId}`);


    // }
    /**
  * This function is used to get record by filter
  * @param pageNo
  * @param sortField
  * @param sortOrder
  * @param globalField
  * @author Dhaval Bera
  */
    getSitesDayWiseBySiteId(
        siteId: any,
        pageNo: number,
        sortField: string,
        sortOrder: string,
        globalFilter: string,
        rows: number
    ) {
        let sortFieldName = '';
        let page = '';
        let globalSearch = '';
        let perPage = '';

        if (!Number.isNaN(pageNo) && pageNo != null) {
            page = '?page=' + pageNo;
        }

        if (sortField !== undefined) {
            sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
        }

        if (globalFilter != null && globalFilter !== undefined) {
            globalSearch = '&filters[global]=' + globalFilter;
        }

        if (rows !== 0) {
            perPage = '&perPage=' + rows;
        }

        const finalFilter = page + sortFieldName + globalSearch + perPage;
        return this.http.get(`${this.baseUrl}/list/${siteId}` + finalFilter);
    }

    /**
   * @author Jaydeep Patel
   * This function is Used Filer Participant Data
   * @param id
   */
    bulkDaysAssign(formData: any) {
        return this.http.post(`${this.baseUrl}/bulk-assign`, formData);
    }

}
