<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>Edit</li>
        <li>
            <a routerLink="/participant-documents" title="Participant Documents" class="only-link">
                Manage Participant Documents
            </a>
        </li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="stat-detail-wrape">
                <div class="column">
                    <p class="detail">
                        <b>First Name</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.first_name ?
                            participantHeaderDetails.first_name : '-' }}</span>
                    </p>
                    <p class="detail">
                        <b>Middle Name</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.middle_name ?
                            participantHeaderDetails.middle_name : '-' }}</span>
                    </p>
                    <p class="detail">
                        <b>Last Name</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.last_name ?
                            participantHeaderDetails.last_name : '-' }}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>Email ID</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.email ?
                            participantHeaderDetails.email : '-' }}</span>
                    </p>
                    <p class="detail">
                        <b>Gender</b>
                        <span *ngIf="participantHeaderDetails; else elseBlockShow">
                            {{participantHeaderDetails.gender | titlecase}}
                        </span>
                        <ng-template #elseBlockShow>
                            <span>-</span>
                        </ng-template>
                    </p>
                    <p class="detail">
                        <b>Age</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.age ? participantHeaderDetails.age
                            : '-' }}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>New PID</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.new_pid ?
                            participantHeaderDetails.new_pid : '-' }}</span>
                    </p>
                    <p class="detail">
                        <b>New Recollection ID</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.new_recollection_id ?
                            participantHeaderDetails.new_recollection_id : '-' }}</span>
                    </p>
                    <p class="detail">
                        <b>DOB</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.date_of_birth ?
                            participantHeaderDetails.date_of_birth : '-' }}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>Collection Date</b>
                        <span
                            *ngIf="participantHeaderDetails && participantHeaderDetails.collection_date;else dateNotAvailable">
                            {{ participantHeaderDetails.collection_date | date:'dd MMM YYYY' }}
                        </span>
                        <ng-template #dateNotAvailable>
                            <span>-</span>
                        </ng-template>
                    </p>
                    <p class="detail">
                        <b>City</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.residential_city ?
                            participantHeaderDetails.residential_city : '-' }}</span>
                    </p>
                    <p class="detail">
                        <b>Address</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.residential_address ?
                            participantHeaderDetails.residential_address : '-' }}</span>
                    </p>
                </div>
                <div class="column">
                    <p class="detail">
                        <b>Site</b>
                        <span>{{participantHeaderDetails && participantHeaderDetails.site_name ?
                            participantHeaderDetails.site_name : '-' }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-md-center">
        <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">{{title}}</h4>
                </div>
                <form [formGroup]="participantEditDocumentForm" #form="ngForm" class="edit-mode"
                    (ngSubmit)="participantDocumentSubmit()">
                    <div class="card-body">
                        <mat-spinner *ngIf="showLoading"></mat-spinner>
                        <div class="progress-overlay"></div>
                        <div class="row">
                            <div class="col-xl-4 col-md-4">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': participantEditDocumentForm.controls['participant_id'].invalid && (participantEditDocumentForm.controls['participant_id'].dirty || participantEditDocumentForm.controls['participant_id'].touched)}">
                                    <mat-label><i>*</i>Select Participant: </mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="participant" formControlName="participant_id"
                                            bindLabel="new_pid" bindValue="id" labelForId="new_pid"
                                            placeholder="Enter New PID" (search)="search($event)"
                                            (change)="participantDetails($event)">
                                        </ng-select>
                                        <span *ngIf=" participantEditDocumentForm.controls['participant_id'].invalid && (participantEditDocumentForm.controls['participant_id'].dirty ||
										participantEditDocumentForm.controls['participant_id'].touched)">
                                            <mat-error
                                                *ngIf="participantEditDocumentForm.controls['participant_id'].errors?.['required']">
                                                Participant is required
                                            </mat-error>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': participantEditDocumentForm.controls['document_type'].invalid && (participantEditDocumentForm.controls['document_type'].dirty || participantEditDocumentForm.controls['document_type'].touched)}">
                                    <mat-label>Document Type: </mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput formControlName="document_type" #input
                                            placeholder="Document Type" type="text" readonly>
                                    </mat-form-field>
                                    <span *ngIf=" participantEditDocumentForm.controls['document_type'].invalid && (participantEditDocumentForm.controls['document_type'].dirty ||
										participantEditDocumentForm.controls['document_type'].touched)">
                                        <mat-error
                                            *ngIf="participantEditDocumentForm.controls['document_type'].errors?.['required']">
                                            Document Type is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': participantEditDocumentForm.controls['document_name'].invalid && (participantEditDocumentForm.controls['document_name'].dirty || participantEditDocumentForm.controls['document_name'].touched)}">
                                    <mat-label>Document Name: </mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput formControlName="document_name" #input
                                            placeholder="Document name" type="text" readonly>
                                    </mat-form-field>
                                    <span *ngIf=" participantEditDocumentForm.controls['document_name'].invalid && (participantEditDocumentForm.controls['document_name'].dirty ||
										participantEditDocumentForm.controls['document_name'].touched)">
                                        <mat-error
                                            *ngIf="participantEditDocumentForm.controls['document_name'].errors?.['required']">
                                            Document name is required
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="/participant-documents"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" color="secoundary" type="submit"
                            title="Save">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>