<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="{{cancelLink}}" title="Drug Category" class="only-link">Drug
                Category</a>
        </li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-8 col-md-12 col-sm-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">{{title}}</h4>
                </div>
                <form [formGroup]="drugCategoryForm" #form="ngForm" class="edit-mode"
                    (ngSubmit)="onSubmit(drugCategoryForm.value)">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': drugCategoryForm.controls['category_name'].invalid && (drugCategoryForm.controls['category_name'].dirty || drugCategoryForm.controls['category_name'].touched)}">
                                    <mat-label><i>*</i> Drug Category Name</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput #input placeholder="Enter Drug Category name" type="text"
                                            formControlName="category_name"
                                            (focusout)="checkUniqueDrugCategory($event)">
                                    </mat-form-field>
                                    <span
                                        *ngIf="drugCategoryForm.controls['category_name'].invalid && (drugCategoryForm.controls['category_name'].dirty || drugCategoryForm.controls['category_name'].touched)">
                                        <mat-error
                                            *ngIf="drugCategoryForm.controls['category_name'].errors?.['required']">
                                            Drug Category Name is required
                                        </mat-error>
                                        <mat-error
                                            *ngIf="drugCategoryForm.controls['category_name'].errors?.['unique']">
                                            {{ drugCategoryForm.controls['category_name'].errors?.['unique'] }}
                                        </mat-error>
                                        <!-- <mat-error
                                            *ngIf="drugCategoryForm.controls['category_name'].errors?.['minlength']">
                                            Min Length is {{nameMinLength}} characters.
                                        </mat-error> -->
                                        <mat-error
                                            *ngIf="drugCategoryForm.controls['category_name'].errors?.['maxlength']">
                                            Max Length is 255 characters.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="drugCategoryForm.controls['category_name'].errors?.['validField']">
                                            {{drugCategoryForm.controls['category_name'].errors?.['validField']}}
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape">
                                    <mat-label>SNOMED ID</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="snomedIdList" formControlName="snomed_id" [multiple]="true"
                                            bindLabel="snomed_id" labelForId="snomed_id" placeholder="Select SNOMED ID">
                                        </ng-select>
                                        <mat-error
                                            *ngIf="drugCategoryForm.controls['snomed_id'].errors?.['validField']">
                                            {{drugCategoryForm.controls['snomed_id'].errors?.['validField']}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
                            title="{{buttonText}}">{{buttonText}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>