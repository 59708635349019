import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { CrudService } from "./crud.service";

@Injectable({
  providedIn: "root",
})
export class DiseaseReclassificationService extends CrudService {
  constructor(public override http: HttpClient) {
    super('snomed-reclassification', http);
  }

  /**
   * Get last three days SNOMED information
   * @param number participantId
   */
  getDiseaseAndMedicalHistoryByParticipant(participantId: number) {
    return this.http.get(
      `${this.baseUrl}/medical-history-by-participant/${participantId}`
    );
  }

  /**
    * Get last three days SNOMED information
    */
  getLastThreeDaysSnomedInformation() {
    return this.http.get(`${this.baseUrl}/last-three-days`);
  }

  /**
   * Save derived SNOMED data by participant
   * @param derivedSnomedData
   * @param participantId
   * @returns
   */
  saveSnomedDeriveData(derivedSnomedData: any) {
    return this.http.post(`${this.baseUrl}/save-snomed-derive-data`, derivedSnomedData);
  }

  /**
   * Update Reviewed Status
   * @param participantIds
   * @returns
   */
  updateReviewedSnomed(formData: any) {
    return this.http.post(`${this.baseUrl}/update-reviewed-status`, formData);
  }

  /**
  * Get SNOMED information of participant
  */
  getParticipantDataForSnomed(pageNo: any, sortField: string, sortOrder: string, globalFilter: string, rows: any, body: any) {
    if (!Number.isNaN(pageNo) && pageNo != null) {
      body.append("page", pageNo);
    }

    if (sortField !== undefined) {
      body.append("sortField", sortField);
      body.append("sortOrder", sortOrder);
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      body.append("filters[global]", globalFilter);
    }

    if (rows !== 0) {
      body.append("perPage", rows);
    }
    return this.http.post(`${this.baseUrl}`, body);
  }

}
