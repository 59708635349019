<section class="my-profile-wrape">
	<div class="page-title-box">
		<h1>{{ title }}</h1>
		<ul class="bradcrumb">
			<li>{{ title }}</li>
			<li>
				<a routerLink="/site/dashboard/{{ siteId }}" title="Manage Day Wise Data" class="only-link">
					Manage Day Wise Data
				</a>
			</li>
			<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
		</ul>
	</div>
</section>


<section class="container-fluid">
	<div class="row">
		<div class="col-xl-12 col-md-12 col-sm-12">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text" *ngIf="siteDayWiseId != 0">{{siteDetail?siteDetail?.site?.site_name:""}} -
						{{siteDetail?siteDetail?.site?.site_code:""}}</h4>
					<h4 class="left-text" *ngIf="siteDayWiseId == 0">{{tmpSiteDetail?tmpSiteDetail?.site_name:""}} -
						{{tmpSiteDetail?tmpSiteDetail?.site_code:""}}</h4>
				</div>
			</div>
		</div>
		<mat-spinner *ngIf="loading"></mat-spinner>
		<div class="progress-overlay"></div>
		<div class="col-xl-12 col-md-12">
			<form [formGroup]="siteDayWiseForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit()">
				<mat-accordion class="example-headers-align">
					<mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Camp Site Management
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accr-participant-container inner-bg-gray">
							<mat-panel-body>
								<div class="row">
									<div class="col-xl-6 col-md-6">
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Basic Info </h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape">
															<mat-label><i>*</i>Camp Date:</mat-label>
															<div class="input-wrape calendar-wrape"
																[ngClass]="displayFieldCss('siteDate')">
																<mat-form-field class="example-full-width"
																	appearance="outline">
																	<input matInput [matDatepicker]="pickerStartDate"
																		placeholder="Enter camp date" readonly="true"
																		(click)="pickerStartDate.open()" [min]="min"
																		formControlName="siteDate">
																	<mat-datepicker-toggle matSuffix
																		[for]="pickerStartDate">
																		<mat-icon matDatepickerToggleIcon
																			class="calendar-icon"></mat-icon>
																	</mat-datepicker-toggle>
																	<mat-datepicker #pickerStartDate></mat-datepicker>
																</mat-form-field>
																<span
																	*ngIf="siteDayWiseForm.controls['siteDate'].invalid && (siteDayWiseForm.controls['siteDate'].dirty || siteDayWiseForm.controls['siteDate'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['siteDate'].errors?.['required']">
																		Camp Date is required
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape half-wrape"
															[ngClass]="displayFieldCss('campTimingFrom','campTimingTo')">
															<mat-label><i>*</i>Camp Timings: </mat-label>
															<div class="container-fluid">
																<div class="time-wrape row">
																	<div class="col-xl-6 ">
																		<div class="default-time-example">
																			<span class="deafult-text">From</span>
																			<input formControlName="campTimingFrom"
																				aria-label="default time"
																				[ngxTimepicker]="fromValue"
																				[value]="'12:00 AM'" readonly>
																			<ngx-material-timepicker
																				#fromValue></ngx-material-timepicker>
																		</div>
																	</div>
																	<div class="col-xl-6 ">
																		<div class="default-time-example">
																			<span class="deafult-text">To</span>
																			<input formControlName="campTimingTo"
																				[ngxTimepicker]="toValue"
																				aria-label="default time" readonly
																				[value]="'11:00 PM'">
																			<ngx-material-timepicker
																				#toValue></ngx-material-timepicker>
																		</div>
																	</div>
																	<span
																		*ngIf="(siteDayWiseForm.controls['campTimingFrom'].invalid && (siteDayWiseForm.controls['campTimingFrom'].dirty || siteDayWiseForm.controls['campTimingFrom'].touched)) || siteDayWiseForm.controls['campTimingTo'].invalid && (siteDayWiseForm.controls['campTimingTo'].dirty || siteDayWiseForm.controls['campTimingTo'].touched)">
																		<mat-error
																			*ngIf="siteDayWiseForm.controls['campTimingFrom'].errors?.['required'] || siteDayWiseForm.controls['campTimingTo'].errors?.['required']">
																			Camp Timings is required
																		</mat-error>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Team Details </h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape">
															<mat-label>Supervisor (Optional):</mat-label>
															<div class="control-pad">
																<ng-select [items]="supervisors"
																	formControlName="campSupervisor" bindValue="id"
																	bindLabel="name"
																	placeholder="Select camp supervisor">
																</ng-select>
															</div>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('campCoordinator')">
															<mat-label><i>*</i>Coordinator: </mat-label>
															<div class="control-pad">
																<ng-select [items]="coordinators"
																	formControlName="campCoordinator" bindValue="id"
																	bindLabel="name"
																	placeholder="Select camp coordinator"
																	labelForId="campCoordinator">
																</ng-select>
																<span
																	*ngIf="siteDayWiseForm.controls['campCoordinator'].invalid && (siteDayWiseForm.controls['campCoordinator'].dirty || siteDayWiseForm.controls['campCoordinator'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['campCoordinator'].errors?.['required']">
																		Coordinator is required
																	</mat-error>
																</span>
															</div>
														</div>
													</div>

													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('campCounsellor')">
															<mat-label><i>*</i>Counsellor:</mat-label>
															<div class="control-pad">
																<ng-select [items]="counsellors"
																	formControlName="campCounsellor" bindValue="id"
																	bindLabel="name"
																	placeholder="Select camp counsellor"
																	[multiple]="true"></ng-select>
																<span
																	*ngIf="siteDayWiseForm.controls['campCounsellor'].invalid && (siteDayWiseForm.controls['campCounsellor'].dirty || siteDayWiseForm.controls['campCounsellor'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['campCounsellor'].errors?.['required']">
																		Counsellor is required
																	</mat-error>
																</span>
															</div>
														</div>
													</div>

													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('campPhlebotomist')">
															<mat-label><i>*</i>Phlebotomist:</mat-label>
															<div class="control-pad">
																<ng-select [items]="phlebotomists"
																	formControlName="campPhlebotomist" bindValue="id"
																	bindLabel="name"
																	placeholder="Select camp phlebotomist"
																	[multiple]="true">
																</ng-select>
																<span
																	*ngIf="siteDayWiseForm.controls['campPhlebotomist'].invalid && (siteDayWiseForm.controls['campPhlebotomist'].dirty || siteDayWiseForm.controls['campPhlebotomist'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['campPhlebotomist'].errors?.['required']">
																		Phlebotomist is required
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-xl-6 col-md-6">
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Details</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape">
															<mat-label><i>*</i>Date - Materials Packed for Camp:
															</mat-label>
															<div class="input-wrape calendar-wrape"
																[ngClass]="displayFieldCss('dateOfMaterialsPacked')">
																<mat-form-field class="example-full-width"
																	appearance="outline">
																	<input matInput [matDatepicker]="pickerEndDate"
																		placeholder="Enter date materials packed for camp"
																		(click)="pickerEndDate.open()"
																		formControlName="dateOfMaterialsPacked"
																		readonly>
																	<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
																	<mat-datepicker-toggle matSuffix
																		[for]="pickerEndDate">
																		<mat-icon matDatepickerToggleIcon
																			class="calendar-icon"></mat-icon>
																	</mat-datepicker-toggle>
																	<mat-datepicker #pickerEndDate></mat-datepicker>
																</mat-form-field>
																<span
																	*ngIf="siteDayWiseForm.controls['dateOfMaterialsPacked'].invalid && (siteDayWiseForm.controls['dateOfMaterialsPacked'].dirty || siteDayWiseForm.controls['dateOfMaterialsPacked'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['dateOfMaterialsPacked'].errors?.['required']">
																		Date - materials packed for camp is required
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('totalParticipantsExpected')">
															<mat-label>Total Number of Participants Expected:
															</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter total number of participants expected"
																	type="number" (keypress)="onlyNumeric($event)"
																	formControlName="totalParticipantsExpected">
															</mat-form-field>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('materialsPackedTotalParticipants')">
															<mat-label>Materials Packed for Number of Participants:
															</mat-label>
															<mat-form-field appearance="outline">
																<input matInput
																	formControlName="materialsPackedTotalParticipants"
																	(keypress)="onlyNumeric($event)" #input
																	placeholder="Enter materials packed for number of participants"
																	type="number">
															</mat-form-field>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape">
															<mat-label>Data Logger Given to: </mat-label>
															<mat-form-field appearance="outline">
																<input matInput formControlName="dataLoggerGivenTo"
																	#input placeholder="Enter data logger given to"
																	type="text">
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Files</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="upload-thumb-wrape full">
															<div class="input-wrape">
																<mat-label>Attachment for Temp Logger - PDF:
																</mat-label>
																<div class="upload-wrape side-info">
																		<input id="fileInput"
																			formControlName="tempLoggerAttachment"
																			type="file" class="ng-hide"
																			placeholder="upload"
																			(change)="fileSelect($event,'tempLoggerAttachment', 'selectedFile1')">
																		<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
    <div class="info-pad popup-over">
        <button class="info-icon"></button>
        <div class="info-section popupcontent">
            Please choose .pdf file only
        </div>
    </div>
</div>
																<div class="upload-filename"
																	*ngIf="tempLoggerAttachment !== ''">
																	<span *ngIf="tempLoggerAttachmentUrl">
																		<a href="{{ tempLoggerAttachmentUrl }}"
																			target="_blank">
																			{{ tempLoggerAttachment }}
																		</a>
																	</span>
																	<span *ngIf="!tempLoggerAttachmentUrl">
																		<a href="JavaScript:void(0);">
																			{{ tempLoggerAttachment }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteDayWiseForm.controls['tempLoggerAttachment'].invalid && (siteDayWiseForm.controls['tempLoggerAttachment'].dirty || siteDayWiseForm.controls['tempLoggerAttachment'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['tempLoggerAttachment'].errors?.['validField']">
																		{{siteDayWiseForm.controls['tempLoggerAttachment'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
															<div class="input-wrape">
																<mat-label>Any Other Camp Deliverable Status - Invoice,
																	Material Restock etc. - PDF: </mat-label>
																<div class="upload-wrape side-info">
																		<input id="fileInput"
																			formControlName="anyOtherCampDeliverableStatus"
																			type="file" class="ng-hide"
																			placeholder="upload"
																			(change)="fileSelect($event,'anyOtherCampDeliverableStatus', 'selectedFile2')">
																		<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
    <div class="info-pad popup-over">
        <button class="info-icon"></button>
        <div class="info-section popupcontent">
            Please choose .pdf file only
        </div>
    </div>
</div>
																<div class="upload-filename"
																	*ngIf="anyOtherCampDeliverableStatus !== ''">
																	<span *ngIf="anyOtherCampDeliverableStatusUrl">
																		<a href="{{ anyOtherCampDeliverableStatusUrl }}"
																			target="_blank">
																			{{ anyOtherCampDeliverableStatus }}
																		</a>
																	</span>
																	<span *ngIf="!anyOtherCampDeliverableStatusUrl">
																		<a href="JavaScript:void(0);">
																			{{ anyOtherCampDeliverableStatus }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteDayWiseForm.controls['anyOtherCampDeliverableStatus'].invalid && (siteDayWiseForm.controls['anyOtherCampDeliverableStatus'].dirty || siteDayWiseForm.controls['anyOtherCampDeliverableStatus'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['anyOtherCampDeliverableStatus'].errors?.['validField']">
																		{{siteDayWiseForm.controls['anyOtherCampDeliverableStatus'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</mat-panel-body>
						</div>
					</mat-expansion-panel>

					<mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Health Test Record Management
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accr-participant-container inner-bg-gray">
							<mat-panel-body>
								<div class="row">
									<div class="col-xl-12 col-md-12 m-b-0">
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text">Health Test Record Management Details</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('diagnosticLabId')">
															<mat-label><i>*</i>Diagnostic Lab: </mat-label>
															<div class="control-pad">
																<ng-select [items]="diagnosticLabs"
																	formControlName="diagnosticLabId"
																	[hideSelected]="true" bindValue="id"
																	bindLabel="name"
																	placeholder="Select diagnostic lab">
																</ng-select>
																<span
																	*ngIf="siteDayWiseForm.controls['diagnosticLabId'].invalid && (siteDayWiseForm.controls['diagnosticLabId'].dirty || siteDayWiseForm.controls['diagnosticLabId'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['diagnosticLabId'].errors?.['required']">
																		Diagnostic Lab is required
																	</mat-error>
																</span>
															</div>
														</div>
														<div class="upload-thumb-wrape full">
															<div class="input-wrape">
																<mat-label>Acknowledgement of Sample Pickup / Delivery
																	for Diagnostic Lab: </mat-label>
																<div class="upload-wrape side-info">
																	<input id="fileInput"
																		formControlName="acknowledgmentOfSamplePickupDelivery"
																		type="file" class="ng-hide"
																		placeholder="upload"
																		(change)="fileSelect($event,'acknowledgmentOfSamplePickupDelivery', 'selectedFile3')">
																	<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
																	<div class="info-pad popup-over">
																		<button class="info-icon"></button>
																		<div class="info-section popupcontent">
																			Please choose .pdf file only
																		</div>
																	</div>
																</div>
																<div class="upload-filename"
																	*ngIf="acknowledgmentOfSamplePickupDelivery !== ''">
																	<span
																		*ngIf="acknowledgmentOfSamplePickupDeliveryUrl">
																		<a href="{{ acknowledgmentOfSamplePickupDeliveryUrl }}"
																			target="_blank">
																			{{ acknowledgmentOfSamplePickupDelivery }}
																		</a>
																	</span>
																	<span
																		*ngIf="!acknowledgmentOfSamplePickupDeliveryUrl">
																		<a href="JavaScript:void(0);">
																			{{ acknowledgmentOfSamplePickupDelivery }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteDayWiseForm.controls['acknowledgmentOfSamplePickupDelivery'].invalid && (siteDayWiseForm.controls['acknowledgmentOfSamplePickupDelivery'].dirty || siteDayWiseForm.controls['acknowledgmentOfSamplePickupDelivery'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['acknowledgmentOfSamplePickupDelivery'].errors?.['validField']">
																		{{siteDayWiseForm.controls['acknowledgmentOfSamplePickupDelivery'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>

														<div class="input-wrape">
															<mat-label>Test Request Form (TRF) available:</mat-label>
															<mat-radio-group aria-label="Select an option"
																formControlName="isAvailableTestRequestForm"
																(change)="changeIdCardDetail($event,'testRequestFormAttachment')">
																<mat-radio-button value="Y">Yes</mat-radio-button>
																<mat-radio-button value="N">No</mat-radio-button>
															</mat-radio-group>
														</div>

														<div class="upload-thumb-wrape full"
															*ngIf="trfAvailable == 'Y'">
															<div class="input-wrape tree one new-upload">
																<mat-label>Upload Test Request Form (TRF) Attachment -
																	PDF: </mat-label>
																<div class="upload-wrape side-info">
																	<input id="fileInput"
																		formControlName="testRequestFormAttachment"
																		type="file" class="ng-hide"
																		placeholder="upload"
																		(change)="fileSelect($event,'testRequestFormAttachment', 'selectedFile4')">
																	<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
																	<div class="info-pad popup-over">
																		<button class="info-icon"></button>
																		<div class="info-section popupcontent">
																			Please choose .pdf file only
																		</div>
																	</div>
																</div>
																<i class="ruler"></i>
																<div class="upload-filename"
																	*ngIf="testRequestFormAttachment !== ''">
																	<span *ngIf="testRequestFormAttachmentUrl">
																		<a href="{{ testRequestFormAttachmentUrl }}"
																			target="_blank">
																			{{ testRequestFormAttachment }}
																		</a>
																	</span>
																	<span *ngIf="!testRequestFormAttachmentUrl">
																		<a href="JavaScript:void(0);">
																			{{ testRequestFormAttachment }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteDayWiseForm.controls['testRequestFormAttachment'].invalid && (siteDayWiseForm.controls['testRequestFormAttachment'].dirty || siteDayWiseForm.controls['testRequestFormAttachment'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['testRequestFormAttachment'].errors?.['validField']">
																		{{siteDayWiseForm.controls['testRequestFormAttachment'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>

														<div class="input-wrape">
															<mat-label>Date- HTR Received in Office: </mat-label>
															<div class="input-wrape calendar-wrape">
																<mat-form-field class="example-full-width"
																	appearance="outline">
																	<input matInput [matDatepicker]="pickerHTR"
																		formControlName="htrReceivedDate"
																		placeholder="Enter date HTR received in office"
																		(click)="pickerHTR.open()" readonly>
																	<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
																	<mat-datepicker-toggle matSuffix [for]="pickerHTR">
																		<mat-icon matDatepickerToggleIcon
																			class="calendar-icon"></mat-icon>
																	</mat-datepicker-toggle>
																	<mat-datepicker #pickerHTR></mat-datepicker>
																</mat-form-field>
															</div>
														</div>

														<div class="input-wrape">
															<mat-label>Reports Received By: </mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter reports received by" type="text"
																	formControlName="reportsReceivedBy">
															</mat-form-field>
														</div>

														<div class="input-wrape">
															<mat-label>Number of Reports Received: </mat-label>
															<mat-form-field appearance="outline">
																<input matInput (keypress)="onlyNumeric($event)" #input
																	placeholder="Enter number of reports received"
																	type="number"
																	formControlName="totalReportsReceived">
															</mat-form-field>
														</div>

														<div class="input-wrape">
															<mat-label>Reports Checked and Invoice Verified
																By:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter reports checked and invoice verified by"
																	type="text"
																	formControlName="reportsCheckedInvoiceVerifiedBy">
															</mat-form-field>
														</div>
													</div>

													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape">
															<mat-label>Camp Report Delivery Status (Delivered /
																Pending): </mat-label>
															<mat-radio-group aria-label="Select an option"
																formControlName="campReportDeliveryStatus">
																<mat-radio-button
																	value="Delivered">Delivered</mat-radio-button>
																<mat-radio-button
																	value="Pending">Pending</mat-radio-button>
															</mat-radio-group>
														</div>

														<div class="input-wrape">
															<mat-label>Date on Which Reports Dispatched to Site:
															</mat-label>
															<div class="input-wrape calendar-wrape">
																<mat-form-field class="example-full-width"
																	appearance="outline">
																	<input matInput [matDatepicker]="pickerDispatched"
																		formControlName="reportsDispatchedToSiteDate"
																		placeholder="Enter date HTR received in office"
																		(click)="pickerDispatched.open()" readonly>
																	<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
																	<mat-datepicker-toggle matSuffix
																		[for]="pickerDispatched">
																		<mat-icon matDatepickerToggleIcon
																			class="calendar-icon"></mat-icon>
																	</mat-datepicker-toggle>
																	<mat-datepicker #pickerDispatched></mat-datepicker>
																</mat-form-field>
															</div>
														</div>

														<div class="input-wrape">
															<mat-label>Reports Delivered By: </mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter reports Delivered by" type="text"
																	formControlName="reportsDeliverdBy">
															</mat-form-field>
														</div>

														<div class="upload-thumb-wrape full">
															<div class="input-wrape">
																<mat-label>Soft Copies of Reports Received and Uploaded
																	- Excel/PDF: </mat-label>
																<div class="upload-wrape side-info">
																		<input id="fileInput" type="file"
																			class="ng-hide" placeholder="upload"
																			formControlName="softCopiesOfReportsReceivedAndUploaded"
																			(change)="fileSelect($event,'softCopiesOfReportsReceivedAndUploaded', 'selectedFile5', true)">
																		<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
    <div class="info-pad popup-over">
        <button class="info-icon"></button>
        <div class="info-section popupcontent">
            Please choose .pdf file only
        </div>
    </div>
</div>
																<div class="upload-filename"
																	*ngIf="softCopiesOfReportsReceivedAndUploaded !== ''">
																	<span
																		*ngIf="softCopiesOfReportsReceivedAndUploadedUrl">
																		<a href="{{ softCopiesOfReportsReceivedAndUploadedUrl }}"
																			target="_blank">
																			{{ softCopiesOfReportsReceivedAndUploaded }}
																		</a>
																	</span>
																	<span
																		*ngIf="!softCopiesOfReportsReceivedAndUploadedUrl">
																		<a href="JavaScript:void(0);">
																			{{ softCopiesOfReportsReceivedAndUploaded }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteDayWiseForm.controls['softCopiesOfReportsReceivedAndUploaded'].invalid && (siteDayWiseForm.controls['softCopiesOfReportsReceivedAndUploaded'].dirty || siteDayWiseForm.controls['softCopiesOfReportsReceivedAndUploaded'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['softCopiesOfReportsReceivedAndUploaded'].errors?.['validField']">
																		{{siteDayWiseForm.controls['softCopiesOfReportsReceivedAndUploaded'].errors?.['validField']}}
																	</mat-error>
																</span>

																<!-- <div class="img-wrape">
																	<span mat-button (click)="openDialog()"><img
																			src="../../../../../../assets/images/patient-pic-1.jpg" alt=""></span>
																	<a href="javascript:;" class="theme-icon delete-icon" title="Delete Site"
																		(click)="deletePhoto()"></a>
																</div> -->
															</div>

															<div class="input-wrape">
																<mat-label>Soft Copies of Invoice Received and Uploaded
																	- Excel/PDF: </mat-label>
																<div class="upload-wrape side-info">
																		<input id="fileInput"
																			formControlName="softCopiesOfInvoiceReceivedAndUploaded"
																			type="file" class="ng-hide"
																			placeholder="upload"
																			(change)="fileSelect($event,'softCopiesOfInvoiceReceivedAndUploaded', 'selectedFile6', true)">
																		<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
    <div class="info-pad popup-over">
        <button class="info-icon"></button>
        <div class="info-section popupcontent">
            Please choose .pdf file only
        </div>
    </div>
</div>
																<div class="upload-filename"
																	*ngIf="softCopiesOfInvoiceReceivedAndUploaded !== ''">
																	<span
																		*ngIf="softCopiesOfInvoiceReceivedAndUploadedUrl">
																		<a href="{{ softCopiesOfInvoiceReceivedAndUploadedUrl }}"
																			target="_blank">
																			{{ softCopiesOfInvoiceReceivedAndUploaded }}
																		</a>
																	</span>
																	<span
																		*ngIf="!softCopiesOfInvoiceReceivedAndUploadedUrl">
																		<a href="JavaScript:void(0);">
																			{{ softCopiesOfInvoiceReceivedAndUploaded }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteDayWiseForm.controls['softCopiesOfInvoiceReceivedAndUploaded'].invalid && (siteDayWiseForm.controls['softCopiesOfInvoiceReceivedAndUploaded'].dirty || siteDayWiseForm.controls['softCopiesOfInvoiceReceivedAndUploaded'].touched)">
																	<mat-error
																		*ngIf="siteDayWiseForm.controls['softCopiesOfInvoiceReceivedAndUploaded'].errors?.['validField']">
																		{{siteDayWiseForm.controls['softCopiesOfInvoiceReceivedAndUploaded'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>

														<div class="input-wrape">
															<mat-label>Invoice Cleared and Payment Done: </mat-label>
															<mat-radio-group aria-label="Select an option"
																formControlName="isInvoiceClearedAndPaymentDone">
																<mat-radio-button value="Y">Yes</mat-radio-button>
																<mat-radio-button value="N">No</mat-radio-button>
															</mat-radio-group>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</mat-panel-body>
						</div>
					</mat-expansion-panel>
				</mat-accordion>

				<div class="bottom-seprate-btn-wrape">
					<button mat-raised-button class="btn" color="warn" routerLink="/site/dashboard/{{ siteId }}"
						title="Cancel">Cancel</button>
					<button mat-raised-button class="btn" type="submit" color="secoundary"
						title="{{buttonText}}">{{buttonText}}</button>
				</div>
			</form>
		</div>
	</div>
</section>