import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { PopulationService } from 'src/app/service/population.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-population',
  templateUrl: './population.component.html',
  styleUrls: ['./population.component.scss']
})
export class PopulationComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    {
      name: 'Participants Count',
      dataKey: 'participant_count',
      isSortable: true,
      cell: (element: Record<string, any>) => ({
        content: element['participant_count'],
        keyName: 'population/population_id',
      }),
    },
    {
      name: 'Religion',
      dataKey: 'religion_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['religion_name']}`,
    },
  ];

  public currentPage = 0;
  public data: any;
  public editLink: string = 'dictionary-management/sub-population/edit/';
  public filterValue = '';
  public isApproved: number = 1;
  public isCurate: boolean = false;
  public loading: boolean = false;
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public pageSize = 10;
  public sortField = '';
  public sortOrder = '';
  public title = 'Sub Population';
  public totalRows = 0;
  public currentMenuPermission: any;
  public is_data_curation_access : boolean = false;

  constructor(
    private populationService: PopulationService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService,
  ) {}

  ngOnInit(): void {
    this.is_data_curation_access = JSON.parse(localStorage.getItem('userInfo') as string).is_data_curation_access;
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    if(this.router.url.indexOf('curation-pending') >= 0){
      this.isCurate = true;
      this.isApproved = 0;
      this.editLink = 'dictionary-management/sub-population/curation-pending/';
    }
    this.getAll();
  }

  /**
   * This function is used to get all population Listing
   * @author Farhan Shaikh
   */
  getAll() {
    this.loading = true;
    this.populationService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('population Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  downloadParticipantData(url:string){
    if (this.currentMenuPermission.edit == true) {
      this.loading = true;
      this.populationService.downloadDictionaryParticipantList(url).subscribe({
        next: (result: any) => {
          console.log(result);
          const file = new Blob([result.data.body], { type: 'text/csv' });
          var downloadURL = window.URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.data.file_name;
          link.click();
        },
        error: () => { },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }
  /**
   * call Create Organ site api
   * @returns null
   */
  deletePopulation(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Sub Population?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.populationService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                result.message
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                result.message
              );
            }
          },
          error: (e: any) => {
            this.commonService.showErrorToast('Sub Population not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

   /**
   * Make unapproved records
   * @param id
   */
   makeUnapproved(event: any) {
    Swal.fire({
      title: 'Unapproved?',
      html: 'Are you sure want to unapproved the sub population?',
      imageUrl: '../../../../../assets/images/unapproved.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        let url = (
          `${environment.baseURL}` + 'dictionary/unapproved/population/'+event.id
        );
        this.populationService
          .makeUnapprovedDictionary(url)
          .subscribe((results: any) => {
            if (results.status === 1 || results.status === 200) {
              this.commonService.showSuccessToast(
                'Sub population unapproved successfully'
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                "Sub population can't be unapproved. Please try again later."
              );
            }
          });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
