import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ParticipantService } from 'src/app/service/participant.service';
import { HtrService } from 'src/app/service/htr.service';
import { CommonService } from 'src/app/service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-htr-form',
  templateUrl: './htr-form.component.html',
  styleUrls: ['./htr-form.component.scss']
})
export class HtrFormComponent implements OnInit {

  public htrForm!: FormGroup;
  public participants: any = [];
  public participantsFiltered!: Observable<any[]>;
  public participantHeaderDetails: any;
  public htrID: any;
  public htrDetail: any;
  public title: any = 'Add HTR';
  public isViewMode: boolean = false;
  public loading: boolean = false;
  public albums: any[] = [];
  qcedAt: any = 'Yet not QC\'ed';
  reviewedAt: any = 'Yet not Reviewed';
  isReviewed: boolean = false;
  isQced: boolean = false;

  glycosylatedTestData = {};
  glycoseSugarRandom = {};
  bloodsugarFastingsTestData = {};
  completeBloodCountTestData = {};
  lipidProfileTestData = {};
  thyroidReportTestData = {};
  creatinineTestData = {};
  phosphorousSerumTestData = {};
  electrolytesSerumTestData = {};
  statinTestData = {};
  hepatitisBTestData = {};
  bloodGroupingTestData = {};
  liverFunctionTestData = {};
  uricAcidTestData = {};
  proteinTestData = {};
  hydroxyVitaminDTestData = {};
  calciumTestData = {};
  differentialBloodCountTestData = {};
  rheumatoidArthritisTestData = {};
  completeUrineTestData = {};
  vitaminDTotalTestData = {};
  serumCalciumTestData = {};
  infectiousPanelTestData = {};
  vitaminB12TestData = {};

  // accordion code start
  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  // accordion code end
  constructor(
    public dialog: MatDialog,
    public participantService: ParticipantService,
    public htrService: HtrService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.htrID = this.route.snapshot.paramMap.get('id');
    if (this.htrID) {
      this.setEditData();
      if (this.router.url.indexOf('view') >= 0) {
        this.title = 'View HTR';
        this.isViewMode = true;
      } else {
        this.title = 'Edit HTR';
      }
    } else {
      this.htrID = 0;
    }
  }

  setEditData(): void {

    console.log('edit mode');
    const ID = this.route.snapshot.paramMap.get('id');

    this.htrService.getRow(<string>ID)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            // this.router.navigate(["/htr"]);
            // this.commonService.showSuccessToast(
            //   result.message
            // );
            this.htrDetail = result.data;

            this.participants.push(this.htrDetail.participantInfo);
            this.participantHeaderDetails = this.htrDetail.participant_details;

            const htr_informationArray = this.htrForm.get('htr_informations') as FormArray;

            htr_informationArray.controls[<any>'id'].setValue(this.htrDetail.id);
            htr_informationArray.controls[<any>'participant_id'].setValue(this.htrDetail.participantInfo.id);
            htr_informationArray.controls[<any>'patient_name'].setValue(this.htrDetail.patient_name);
            htr_informationArray.controls[<any>'health_care_center'].setValue(this.htrDetail.health_care_center);
            htr_informationArray.controls[<any>'lr_no'].setValue(this.htrDetail.lr_no);
            htr_informationArray.controls[<any>'htr_available'].setValue(this.htrDetail?.htr_available?.toLowerCase());
            htr_informationArray.controls[<any>'glycated_test_data'].setValue(this.htrDetail.glycated_test_data?.toLowerCase());
            // htr_informationArray.controls[<any>'glucose_test_data'].setValue(this.htrDetail.glucose_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'bloodsugar_fastings_test_data'].setValue(this.htrDetail.bloodsugar_fastings_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'complete_blood_count_test_data'].setValue(this.htrDetail.complete_blood_count_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'lipid_profile_test_data'].setValue(this.htrDetail.lipid_profile_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'thyroid_report_test_data'].setValue(this.htrDetail.thyroid_report_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'creatinine_test_data'].setValue(this.htrDetail.creatinine_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'phosphorous_serum_test_data'].setValue(this.htrDetail.phosphorous_serum_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'electrolytes_serum_test_data'].setValue(this.htrDetail.electrolytes_serum_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'statin_test_data'].setValue(this.htrDetail.statin_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'hepatitis_b_test_data'].setValue(this.htrDetail.hepatitis_b_test_data?.toLowerCase());
            // <!-- remove/modify htr-columns -->
            // htr_informationArray.controls[<any>'vitamin_b12_test_data'].setValue(this.htrDetail.vitamin_b12_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'blood_grouping_test_data'].setValue(this.htrDetail.blood_grouping_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'liver_function_test_data'].setValue(this.htrDetail.liver_function_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'uric_acid_test_data'].setValue(this.htrDetail.uric_acid_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'protein_test_data'].setValue(this.htrDetail.protein_test_data?.toLowerCase());
            // htr_informationArray.controls[<any>'hydroxy_vitamin_d_test_data'].setValue(this.htrDetail.hydroxy_vitamin_d_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'calcium_test_data'].setValue(this.htrDetail.calcium_test_data?.toLowerCase());
            // <!-- remove/modify htr-columns -->
            // htr_informationArray.controls[<any>'differential_blood_count_test_data'].setValue(this.htrDetail.differential_blood_count_test_data?.toLowerCase());
            // htr_informationArray.controls[<any>'serum_calcium_test_data'].setValue(this.htrDetail.serum_calcium_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'rheumatoid_arthritis_test_data'].setValue(this.htrDetail.rheumatoid_arthritis_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'complete_urine_test_data'].setValue(this.htrDetail.complete_urine_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'vitamin_d_total_test_data'].setValue(this.htrDetail.vitamin_d_total_test_data?.toLowerCase());
            htr_informationArray.controls[<any>'infectious_panel_test_data'].setValue(this.htrDetail.infectious_panel_test_data?.toLowerCase());

            if (this.htrDetail.qcedBy) {
              this.qcedAt = 'QC\'ed by ' + this.htrDetail.qcedBy + ' @ ' + this.htrDetail.qcedAt;
              this.isQced = true;
            }
            if (this.htrDetail.reviewedBy) {
              this.reviewedAt = 'Reviewed by ' + this.htrDetail.reviewedBy + ' @ ' + this.htrDetail.reviewedAt;
              this.isReviewed = true;
            }

            if (this.htrDetail.glycated_test_data.toLowerCase() === 'yes') {

              const glycated_hemoglobinsArray = this.htrForm.get('glycated_hemoglobins') as FormArray;
              glycated_hemoglobinsArray.controls[<any>'id'].setValue(this.htrDetail.glycated_hemoglobin.id);
              glycated_hemoglobinsArray.controls[<any>'hba1c'].setValue(this.htrDetail.glycated_hemoglobin.hba1c);
              glycated_hemoglobinsArray.controls[<any>'estimated_average_glucose'].setValue(this.htrDetail.glycated_hemoglobin.estimated_average_glucose);
              glycated_hemoglobinsArray.controls[<any>'mean_plasma_glucose'].setValue(this.htrDetail.glycated_hemoglobin.mean_plasma_glucose);
            }

            // if (this.htrDetail.glucose_test_data.toLowerCase() === 'yes') {
            //   const glucose_testsArray = this.htrForm.get('glucose_tests') as FormArray;
            //   glucose_testsArray.controls[<any>'id'].setValue(this.htrDetail.glucose_test.id);
            //    <!-- remove/modify htr-columns -->
            //   glucose_testsArray.controls[<any>'fasting_mean_blood'].setValue(this.htrDetail.glucose_test.fasting_mean_blood);
            //   glucose_testsArray.controls[<any>'blast'].setValue(this.htrDetail.glucose_test.blast);
            //   glucose_testsArray.controls[<any>'random_average'].setValue(this.htrDetail.glucose_test.random_average);
            // }

            if (this.htrDetail.bloodsugar_fastings_test_data.toLowerCase() === 'yes') {
              const blood_sugar_fastingArray = this.htrForm.get('blood_sugar_fasting') as FormArray;
              blood_sugar_fastingArray.controls[<any>'id'].setValue(this.htrDetail.blood_sugar_fasting.id);
              blood_sugar_fastingArray.controls[<any>'fasting_blood_sugar'].setValue(this.htrDetail.blood_sugar_fasting.fasting_blood_sugar);
              // <!-- remove/modify htr-columns -->
              // blood_sugar_fastingArray.controls[<any>'post_lunch_blood_sugar'].setValue(this.htrDetail.blood_sugar_fasting.post_lunch_blood_sugar);
              // blood_sugar_fastingArray.controls[<any>'random_blood_sugar'].setValue(this.htrDetail.blood_sugar_fasting.random_blood_sugar);
              blood_sugar_fastingArray.controls[<any>'post_prandial'].setValue(this.htrDetail.blood_sugar_fasting.post_prandial);
            }

            if (this.htrDetail.complete_blood_count_test_data.toLowerCase() === 'yes') {
              const complete_blood_countsArray = this.htrForm.get('complete_blood_counts') as FormArray;
              complete_blood_countsArray.controls[<any>'id'].setValue(this.htrDetail.complete_blood_count.id);
              complete_blood_countsArray.controls[<any>'haemoglobin'].setValue(this.htrDetail.complete_blood_count.haemoglobin);
              complete_blood_countsArray.controls[<any>'total_rbc_count'].setValue(this.htrDetail.complete_blood_count.total_rbc_count);
              complete_blood_countsArray.controls[<any>'mcv'].setValue(this.htrDetail.complete_blood_count.mcv);
              complete_blood_countsArray.controls[<any>'mch'].setValue(this.htrDetail.complete_blood_count.mch);
              complete_blood_countsArray.controls[<any>'mchc'].setValue(this.htrDetail.complete_blood_count.mchc);
              complete_blood_countsArray.controls[<any>'total_wbc_count'].setValue(this.htrDetail.complete_blood_count.total_wbc_count);
              complete_blood_countsArray.controls[<any>'neutrophils'].setValue(this.htrDetail.complete_blood_count.neutrophils);
              complete_blood_countsArray.controls[<any>'lymphocytes'].setValue(this.htrDetail.complete_blood_count.lymphocytes);
              complete_blood_countsArray.controls[<any>'monocytes'].setValue(this.htrDetail.complete_blood_count.monocytes);
              complete_blood_countsArray.controls[<any>'eosinophils'].setValue(this.htrDetail.complete_blood_count.eosinophils);
              complete_blood_countsArray.controls[<any>'basophils'].setValue(this.htrDetail.complete_blood_count.basophils);
              complete_blood_countsArray.controls[<any>'platelet_counts'].setValue(this.htrDetail.complete_blood_count.platelet_counts);
              complete_blood_countsArray.controls[<any>'platelets_on_smear'].setValue(this.htrDetail.complete_blood_count.platelets_on_smear);
              complete_blood_countsArray.controls[<any>'mpv'].setValue(this.htrDetail.complete_blood_count.mpv);
              complete_blood_countsArray.controls[<any>'morphology_red_blood_cell'].setValue(this.htrDetail.complete_blood_count.morphology_red_blood_cell);
              complete_blood_countsArray.controls[<any>'peripheral_smear_wbc'].setValue(this.htrDetail.complete_blood_count.peripheral_smear_wbc);
              complete_blood_countsArray.controls[<any>'peripheral_smear_platelets'].setValue(this.htrDetail.complete_blood_count.peripheral_smear_platelets);
              // <!-- remove/modify htr-columns -->
              // complete_blood_countsArray.controls[<any>'rdw_cv'].setValue(this.htrDetail.complete_blood_count.rdw_cv);
              complete_blood_countsArray.controls[<any>'pdw'].setValue(this.htrDetail.complete_blood_count.pdw);
              complete_blood_countsArray.controls[<any>'pct'].setValue(this.htrDetail.complete_blood_count.pct);
              complete_blood_countsArray.controls[<any>'thalassemia_check'].setValue(this.htrDetail.complete_blood_count.thalassemia_check);
              complete_blood_countsArray.controls[<any>'morphology_wbc'].setValue(this.htrDetail.complete_blood_count.morphology_wbc);
              complete_blood_countsArray.controls[<any>'platelet_morphology'].setValue(this.htrDetail.complete_blood_count.platelet_morphology);
              complete_blood_countsArray.controls[<any>'granulocytes'].setValue(this.htrDetail.complete_blood_count.granulocytes);
              complete_blood_countsArray.controls[<any>'granulocytes_10_3'].setValue(this.htrDetail.complete_blood_count.granulocytes_10_3);
              complete_blood_countsArray.controls[<any>'rdw'].setValue(this.htrDetail.complete_blood_count.rdw);
              complete_blood_countsArray.controls[<any>'rdw_sd'].setValue(this.htrDetail.complete_blood_count.rdw_sd);
              complete_blood_countsArray.controls[<any>'plcc_10_3'].setValue(this.htrDetail.complete_blood_count.plcc_10_3);
              complete_blood_countsArray.controls[<any>'plcr'].setValue(this.htrDetail.complete_blood_count.plcr);
              complete_blood_countsArray.controls[<any>'absolute_lymphocyte_count'].setValue(this.htrDetail.complete_blood_count.absolute_lymphocyte_count);
              complete_blood_countsArray.controls[<any>'absolute_monocyte_count'].setValue(this.htrDetail.complete_blood_count.absolute_monocyte_count);
              complete_blood_countsArray.controls[<any>'absolute_eosinophil_count'].setValue(this.htrDetail.complete_blood_count.absolute_eosinophil_count);
              complete_blood_countsArray.controls[<any>'absolute_basophil_count'].setValue(this.htrDetail.complete_blood_count.absolute_basophil_count);
              complete_blood_countsArray.controls[<any>'hematocrit'].setValue(this.htrDetail.complete_blood_count.hematocrit);
              complete_blood_countsArray.controls[<any>'absolute_neutrophil_count'].setValue(this.htrDetail.complete_blood_count.absolute_neutrophil_count);
              complete_blood_countsArray.controls[<any>'leucocyte_microscopic_examination'].setValue(this.htrDetail.complete_blood_count.leucocyte_microscopic_examination);
              // <!-- remove/modify htr-columns -->
              // complete_blood_countsArray.controls[<any>'epithelial_cells_microscopic'].setValue(this.htrDetail.complete_blood_count.epithelial_cells_microscopic);
              complete_blood_countsArray.controls[<any>'rbc_microscopic'].setValue(this.htrDetail.complete_blood_count.rbc_microscopic);
              complete_blood_countsArray.controls[<any>'immature_granulocyte'].setValue(this.htrDetail.complete_blood_count.immature_granulocyte);
              complete_blood_countsArray.controls[<any>'nucleated_rbc'].setValue(this.htrDetail.complete_blood_count.nucleated_rbc);
              complete_blood_countsArray.controls[<any>'neutrophil_lymphocyte_ratio'].setValue(this.htrDetail.complete_blood_count.neutrophil_lymphocyte_ratio);
              complete_blood_countsArray.controls[<any>'erythrocyte_sedimentation_rate'].setValue(this.htrDetail.complete_blood_count.erythrocyte_sedimentation_rate);
              complete_blood_countsArray.controls[<any>'mentzer_index'].setValue(this.htrDetail.complete_blood_count.mentzer_index);
              complete_blood_countsArray.controls[<any>'pdw_fl'].setValue(this.htrDetail.complete_blood_count.pdw_fl);
            }

            if (this.htrDetail.lipid_profile_test_data.toLowerCase() === 'yes') {
              const lipid_profilesArray = this.htrForm.get('lipid_profiles') as FormArray;
              lipid_profilesArray.controls[<any>'id'].setValue(this.htrDetail.lipid_profile.id);
              lipid_profilesArray.controls[<any>'serum_cholesterol'].setValue(this.htrDetail.lipid_profile.serum_cholesterol);
              lipid_profilesArray.controls[<any>'serum_triglyceride'].setValue(this.htrDetail.lipid_profile.serum_triglyceride);
              // <!-- remove/modify htr-columns -->
              // lipid_profilesArray.controls[<any>'hdl_cholesterol_plus'].setValue(this.htrDetail.lipid_profile.hdl_cholesterol_plus);
              // lipid_profilesArray.controls[<any>'ldl_chol_hdl_ratio'].setValue(this.htrDetail.lipid_profile.ldl_chol_hdl_ratio);
              // lipid_profilesArray.controls[<any>'triglyceride_enzymatic'].setValue(this.htrDetail.lipid_profile.triglyceride_enzymatic);
              // lipid_profilesArray.controls[<any>'triglyceride_liquid'].setValue(this.htrDetail.lipid_profile.triglyceride_liquid);
              // lipid_profilesArray.controls[<any>'hdl_cholesterol_direct'].setValue(this.htrDetail.lipid_profile.hdl_cholesterol_direct);
              // lipid_profilesArray.controls[<any>'ldl_cholesterol'].setValue(this.htrDetail.lipid_profile.ldl_cholesterol);
              // lipid_profilesArray.controls[<any>'cholesterol_gen_2'].setValue(this.htrDetail.lipid_profile.cholesterol_gen_2);
              // lipid_profilesArray.controls[<any>'tc_hdl_chol_ratio'].setValue(this.htrDetail.lipid_profile.tc_hdl_chol_ratio);
              // lipid_profilesArray.controls[<any>'appearance_other'].setValue(this.htrDetail.lipid_profile.appearance_other);
              lipid_profilesArray.controls[<any>'vldl_cholesterol'].setValue(this.htrDetail.lipid_profile.vldl_cholesterol);
              lipid_profilesArray.controls[<any>'hdl_cholesterol_ratio'].setValue(this.htrDetail.lipid_profile.hdl_cholesterol_ratio);
              lipid_profilesArray.controls[<any>'ldl_hdl_ratio'].setValue(this.htrDetail.lipid_profile.ldl_hdl_ratio);
              lipid_profilesArray.controls[<any>'non_hdl_chol'].setValue(this.htrDetail.lipid_profile.non_hdl_chol);
              lipid_profilesArray.controls[<any>'cholesterol_ldl_direct'].setValue(this.htrDetail.lipid_profile.cholesterol_ldl_direct);
              lipid_profilesArray.controls[<any>'hdl'].setValue(this.htrDetail.lipid_profile.hdl);
              lipid_profilesArray.controls[<any>'apperance_serum_refrigeration'].setValue(this.htrDetail.lipid_profile.apperance_serum_refrigeration);
              lipid_profilesArray.controls[<any>'naked_eye_appearance_serum'].setValue(this.htrDetail.lipid_profile.naked_eye_appearance_serum);
              lipid_profilesArray.controls[<any>'total_lipid'].setValue(this.htrDetail.lipid_profile.total_lipid);
            }

            if (this.htrDetail.thyroid_report_test_data.toLowerCase() === 'yes') {
              const thyroid_reportsArray = this.htrForm.get('thyroid_reports') as FormArray;
              thyroid_reportsArray.controls[<any>'id'].setValue(this.htrDetail.thyroid_report.id);
              thyroid_reportsArray.controls[<any>'triidothyronine'].setValue(this.htrDetail.thyroid_report.triidothyronine);
              thyroid_reportsArray.controls[<any>'total_thyroxine'].setValue(this.htrDetail.thyroid_report.total_thyroxine);
              thyroid_reportsArray.controls[<any>'thyroid_stimulating_hormone'].setValue(this.htrDetail.thyroid_report.thyroid_stimulating_hormone);
            }

            if (this.htrDetail.creatinine_test_data.toLowerCase() === 'yes') {
              const creatininesArray = this.htrForm.get('creatinines') as FormArray;
              creatininesArray.controls[<any>'id'].setValue(this.htrDetail.creatinine.id);
              creatininesArray.controls[<any>'serum_creatinine'].setValue(this.htrDetail.creatinine.serum_creatinine);
              creatininesArray.controls[<any>'est_glomerular_filter_rate'].setValue(this.htrDetail.creatinine.est_glomerular_filter_rate);
              creatininesArray.controls[<any>'cystatin_c'].setValue(this.htrDetail.creatinine.cystatin_c);

            }

            if (this.htrDetail.phosphorous_serum_test_data.toLowerCase() === 'yes') {
              const phosphorous_serumsArray = this.htrForm.get('phosphorous_serums') as FormArray;
              phosphorous_serumsArray.controls[<any>'id'].setValue(this.htrDetail.phosphorous_serum.id);
              phosphorous_serumsArray.controls[<any>'phosphorous'].setValue(this.htrDetail.phosphorous_serum.phosphorous);
            }

            if (this.htrDetail.electrolytes_serum_test_data.toLowerCase() === 'yes') {
              const electrolytes_serumsArray = this.htrForm.get('electrolytes_serums') as FormArray;
              electrolytes_serumsArray.controls[<any>'id'].setValue(this.htrDetail.electrolytes_serum.id);
              electrolytes_serumsArray.controls[<any>'sodium'].setValue(this.htrDetail.electrolytes_serum.sodium);
              electrolytes_serumsArray.controls[<any>'potassium'].setValue(this.htrDetail.electrolytes_serum.potassium);
              electrolytes_serumsArray.controls[<any>'chloride'].setValue(this.htrDetail.electrolytes_serum.chloride);
            }

            if (this.htrDetail.statin_test_data.toLowerCase() === 'yes') {
              const statin_reportsArray = this.htrForm.get('statin_reports') as FormArray;
              statin_reportsArray.controls[<any>'id'].setValue(this.htrDetail.statin_report.id);
              statin_reportsArray.controls[<any>'allele_tested'].setValue(this.htrDetail.statin_report.allele_tested);
              statin_reportsArray.controls[<any>'slco1b1'].setValue(this.htrDetail.statin_report.slco1b1);
              statin_reportsArray.controls[<any>'slco1b1_genotype_result'].setValue(this.htrDetail.statin_report.slco1b1_genotype_result);
            }

            if (this.htrDetail.hepatitis_b_test_data.toLowerCase() === 'yes') {
              const hepatitis_b_surfacesArray = this.htrForm.get('hepatitis_b_surfaces') as FormArray;
              hepatitis_b_surfacesArray.controls[<any>'id'].setValue(this.htrDetail.hepatitis_b_surface.id);
              hepatitis_b_surfacesArray.controls[<any>'hepatitis_b_surface'].setValue(this.htrDetail.hepatitis_b_surface.hepatitis_b_surface);
            }

            // <!-- remove/modify htr-columns -->
            // if (this.htrDetail.vitamin_b12_test_data.toLowerCase() === 'yes') {
            //   const vitamin_b_twelvesArray = this.htrForm.get('vitamin_b_twelves') as FormArray;
            //   vitamin_b_twelvesArray.controls[<any>'id'].setValue(this.htrDetail.vitamin_b_twelve.id);
            //   vitamin_b_twelvesArray.controls[<any>'vitamin_b_twelve'].setValue(this.htrDetail.vitamin_b_twelve.vitamin_b_twelve);
            // }

            if (this.htrDetail.blood_grouping_test_data?.toLowerCase() === 'yes') {
              const blood_groupingsArray = this.htrForm.get('blood_groupings') as FormArray;
              blood_groupingsArray.controls[<any>'id'].setValue(this.htrDetail.blood_grouping.id);
              blood_groupingsArray.controls[<any>'abo_group'].setValue(this.htrDetail.blood_grouping.abo_group);
              blood_groupingsArray.controls[<any>'rh_type'].setValue(this.htrDetail.blood_grouping.rh_type);
              // <!-- remove/modify htr-columns -->
              // blood_groupingsArray.controls[<any>'blood_group'].setValue(this.htrDetail.blood_grouping.blood_group);
            }

            if (this.htrDetail.liver_function_test_data.toLowerCase() === 'yes') {
              const liver_function_testsArray = this.htrForm.get('liver_function_tests') as FormArray;
              liver_function_testsArray.controls[<any>'id'].setValue(this.htrDetail.liver_function_test.id);
              liver_function_testsArray.controls[<any>'bilirubin_indirect'].setValue(this.htrDetail.liver_function_test.bilirubin_indirect);
              liver_function_testsArray.controls[<any>'albumin'].setValue(this.htrDetail.liver_function_test.albumin);
              liver_function_testsArray.controls[<any>'alkaline_phosphatase'].setValue(this.htrDetail.liver_function_test.alkaline_phosphatase);
              liver_function_testsArray.controls[<any>'bilirubin_direct'].setValue(this.htrDetail.liver_function_test.bilirubin_direct);
              liver_function_testsArray.controls[<any>'bilirubin_total'].setValue(this.htrDetail.liver_function_test.bilirubin_total);
              liver_function_testsArray.controls[<any>'aspartate_aminotransferase'].setValue(this.htrDetail.liver_function_test.aspartate_aminotransferase);
              liver_function_testsArray.controls[<any>'alanine_aminotransferase'].setValue(this.htrDetail.liver_function_test.alanine_aminotransferase);
              liver_function_testsArray.controls[<any>'ag_ratio'].setValue(this.htrDetail.liver_function_test.ag_ratio);
              liver_function_testsArray.controls[<any>'globulin'].setValue(this.htrDetail.liver_function_test.globulin);
              // <!-- remove/modify htr-columns -->
              // liver_function_testsArray.controls[<any>'bilirubin_total_method'].setValue(this.htrDetail.liver_function_test.bilirubin_total_method);
              // liver_function_testsArray.controls[<any>'albumin_method'].setValue(this.htrDetail.liver_function_test.albumin_method);
              // liver_function_testsArray.controls[<any>'alkaline_phosphatase_method'].setValue(this.htrDetail.liver_function_test.alkaline_phosphatase_method);
              // liver_function_testsArray.controls[<any>'total_protein_method'].setValue(this.htrDetail.liver_function_test.total_protein_method);
              // liver_function_testsArray.controls[<any>'bilirubin_direct_method'].setValue(this.htrDetail.liver_function_test.bilirubin_direct_method);
              // liver_function_testsArray.controls[<any>'aspartate_aminotransferase_method'].setValue(this.htrDetail.liver_function_test.aspartate_aminotransferase_method);
              // liver_function_testsArray.controls[<any>'alanine_aminotransferase_method'].setValue(this.htrDetail.liver_function_test.alanine_aminotransferase_method);
              // liver_function_testsArray.controls[<any>'ag_ratio_method'].setValue(this.htrDetail.liver_function_test.ag_ratio_method);
              // liver_function_testsArray.controls[<any>'globulin_method'].setValue(this.htrDetail.liver_function_test.globulin_method);
              liver_function_testsArray.controls[<any>'total_protein'].setValue(this.htrDetail.liver_function_test.total_protein);
            }

            if (this.htrDetail.uric_acid_test_data.toLowerCase() === 'yes') {
              const uric_acid_testsArray = this.htrForm.get('uric_acid_tests') as FormArray;
              uric_acid_testsArray.controls[<any>'id'].setValue(this.htrDetail.uric_acidtest.id);
              uric_acid_testsArray.controls[<any>'blood_urea_nitrogen'].setValue(this.htrDetail.uric_acidtest.blood_urea_nitrogen);
              uric_acid_testsArray.controls[<any>'uric_acid'].setValue(this.htrDetail.uric_acidtest.uric_acid);
              uric_acid_testsArray.controls[<any>'blood_urea'].setValue(this.htrDetail.uric_acidtest.blood_urea);
              // <!-- remove/modify htr-columns -->
              // uric_acid_testsArray.controls[<any>'blood_urea_method'].setValue(this.htrDetail.uric_acidtest.blood_urea_method);
              // uric_acid_testsArray.controls[<any>'blood_urea_nitrogen_method'].setValue(this.htrDetail.uric_acidtest.blood_urea_nitrogen_method);
            }

            if (this.htrDetail.protein_test_data.toLowerCase() === 'yes') {
              const protein_testsArray = this.htrForm.get('protein_tests') as FormArray;
              protein_testsArray.controls[<any>'id'].setValue(this.htrDetail.protein_test.id);
              protein_testsArray.controls[<any>'creactive_protein'].setValue(this.htrDetail.protein_test.creactive_protein);
              protein_testsArray.controls[<any>'gamma_glutamyl_transferase'].setValue(this.htrDetail.protein_test.gamma_glutamyl_transferase);
              // <!-- remove/modify htr-columns -->
              // protein_testsArray.controls[<any>'protein_total'].setValue(this.htrDetail.protein_test.protein_total);
            }

            // <!-- remove/modify htr-columns -->
            // if (this.htrDetail.hydroxy_vitamin_d_test_data.toLowerCase() === 'yes') {
            //   const hydroxy_vitamin_dsArray = this.htrForm.get('hydroxy_vitamin_ds') as FormArray;
            //   hydroxy_vitamin_dsArray.controls[<any>'id'].setValue(this.htrDetail.hydroxy_vitamin_d.id);
            //   hydroxy_vitamin_dsArray.controls[<any>'hydroxy_vitamin_d'].setValue(this.htrDetail.hydroxy_vitamin_d.hydroxy_vitamin_d);
            // }

            if (this.htrDetail.calcium_test_data.toLowerCase() === 'yes') {
              const calciumsArray = this.htrForm.get('calciums') as FormArray;
              calciumsArray.controls[<any>'id'].setValue(this.htrDetail.calcium.id);
              calciumsArray.controls[<any>'calcium'].setValue(this.htrDetail.calcium.calcium);
            }

            // <!-- remove/modify htr-columns -->
            // if (this.htrDetail.differential_blood_count_test_data.toLowerCase() === 'yes') {
            //   const differential_blood_countsArray = this.htrForm.get('differential_blood_counts') as FormArray;
            //   differential_blood_countsArray.controls[<any>'id'].setValue(this.htrDetail.differential_blood_count.id);
            //   differential_blood_countsArray.controls[<any>'promyelocyte'].setValue(this.htrDetail.differential_blood_count.promyelocyte);
            //   differential_blood_countsArray.controls[<any>'myelocyte'].setValue(this.htrDetail.differential_blood_count.myelocyte);
            //   differential_blood_countsArray.controls[<any>'metamyelocyte'].setValue(this.htrDetail.differential_blood_count.metamyelocyte);
            //   differential_blood_countsArray.controls[<any>'band_form'].setValue(this.htrDetail.differential_blood_count.band_form);
            //   differential_blood_countsArray.controls[<any>'promonocyte'].setValue(this.htrDetail.differential_blood_count.promonocyte);
            //   differential_blood_countsArray.controls[<any>'prolymphocytes'].setValue(this.htrDetail.differential_blood_count.prolymphocytes);
            // }

            if (this.htrDetail.rheumatoid_arthritis_test_data.toLowerCase() === 'yes') {
              const rheumatoid_arthritisArray = this.htrForm.get('rheumatoid_arthritis') as FormArray;
              rheumatoid_arthritisArray.controls[<any>'id'].setValue(this.htrDetail.rheumatoid_arthritis.id);
              rheumatoid_arthritisArray.controls[<any>'ra_factor'].setValue(this.htrDetail.rheumatoid_arthritis.ra_factor);
            }

            if (this.htrDetail.complete_urine_test_data.toLowerCase() === 'yes') {
              const complete_urine_examinationArray = this.htrForm.get('complete_urine_examination') as FormArray;
              complete_urine_examinationArray.controls[<any>'id'].setValue(this.htrDetail.complete_urine_examination.id);
              // <!-- remove/modify htr-columns -->
              // complete_urine_examinationArray.controls[<any>'quantity'].setValue(this.htrDetail.complete_urine_examination.quantity);
              // complete_urine_examinationArray.controls[<any>'apperance_method'].setValue(this.htrDetail.complete_urine_examination.apperance_method);
              complete_urine_examinationArray.controls[<any>'color'].setValue(this.htrDetail.complete_urine_examination.color);
              complete_urine_examinationArray.controls[<any>'apperance'].setValue(this.htrDetail.complete_urine_examination.apperance);
              complete_urine_examinationArray.controls[<any>'ph'].setValue(this.htrDetail.complete_urine_examination.ph);
              // complete_urine_examinationArray.controls[<any>'ph_method'].setValue(this.htrDetail.complete_urine_examination.ph_method);
              complete_urine_examinationArray.controls[<any>'specific_gravity'].setValue(this.htrDetail.complete_urine_examination.specific_gravity);
              // complete_urine_examinationArray.controls[<any>'specific_gravity_method'].setValue(this.htrDetail.complete_urine_examination.specific_gravity_method);
              complete_urine_examinationArray.controls[<any>'protein'].setValue(this.htrDetail.complete_urine_examination.protein);
              // complete_urine_examinationArray.controls[<any>'protein_method'].setValue(this.htrDetail.complete_urine_examination.protein_method);
              complete_urine_examinationArray.controls[<any>'glucose'].setValue(this.htrDetail.complete_urine_examination.glucose);
              // complete_urine_examinationArray.controls[<any>'glucose_method'].setValue(this.htrDetail.complete_urine_examination.glucose_method);
              complete_urine_examinationArray.controls[<any>'ketones'].setValue(this.htrDetail.complete_urine_examination.ketones);
              // complete_urine_examinationArray.controls[<any>'ketones_method'].setValue(this.htrDetail.complete_urine_examination.ketones_method);
              complete_urine_examinationArray.controls[<any>'bilirubin'].setValue(this.htrDetail.complete_urine_examination.bilirubin);
              // complete_urine_examinationArray.controls[<any>'bilirubin_method'].setValue(this.htrDetail.complete_urine_examination.bilirubin_method);
              complete_urine_examinationArray.controls[<any>'urobilinogen'].setValue(this.htrDetail.complete_urine_examination.urobilinogen);
              // complete_urine_examinationArray.controls[<any>'urobilinogen_method'].setValue(this.htrDetail.complete_urine_examination.urobilinogen_method);
              complete_urine_examinationArray.controls[<any>'blood'].setValue(this.htrDetail.complete_urine_examination.blood);
              // complete_urine_examinationArray.controls[<any>'blood_method'].setValue(this.htrDetail.complete_urine_examination.blood_method);
              complete_urine_examinationArray.controls[<any>'nitrite'].setValue(this.htrDetail.complete_urine_examination.nitrite);
              // complete_urine_examinationArray.controls[<any>'nitrite_method'].setValue(this.htrDetail.complete_urine_examination.nitrite_method);
              complete_urine_examinationArray.controls[<any>'wbc'].setValue(this.htrDetail.complete_urine_examination.wbc);
              complete_urine_examinationArray.controls[<any>'epithelial_cells'].setValue(this.htrDetail.complete_urine_examination.epithelial_cells);
              complete_urine_examinationArray.controls[<any>'red_blood_cells'].setValue(this.htrDetail.complete_urine_examination.red_blood_cells);
              complete_urine_examinationArray.controls[<any>'casts_hpf'].setValue(this.htrDetail.complete_urine_examination.casts_hpf);
              complete_urine_examinationArray.controls[<any>'crystals'].setValue(this.htrDetail.complete_urine_examination.crystals);
              complete_urine_examinationArray.controls[<any>'bacteria'].setValue(this.htrDetail.complete_urine_examination.bacteria);
              complete_urine_examinationArray.controls[<any>'yeast'].setValue(this.htrDetail.complete_urine_examination.yeast);
            }

            if (this.htrDetail.vitamin_d_total_test_data.toLowerCase() === 'yes') {
              const vitamin_d_totalArray = this.htrForm.get('vitamin_d_total') as FormArray;
              vitamin_d_totalArray.controls[<any>'id'].setValue(this.htrDetail.vitamin_d_total.id);
              vitamin_d_totalArray.controls[<any>'vitamin_d_total'].setValue(this.htrDetail.vitamin_d_total.vitamin_d_total);
            }
            // <!-- remove/modify htr-columns -->
            // if (this.htrDetail.serum_calcium_test_data.toLowerCase() === 'yes') {
            //   const serum_calciumArray = this.htrForm.get('serum_calcium') as FormArray;
            //   serum_calciumArray.controls[<any>'id'].setValue(this.htrDetail.serum_calcium.id);
            //   serum_calciumArray.controls[<any>'serum_calcium'].setValue(this.htrDetail.serum_calcium.serum_calcium);
            // }

            if (this.htrDetail.infectious_panel_test_data.toLowerCase() === 'yes') {
              const infectious_panelsArray = this.htrForm.get('infectious_panels') as FormArray;
              infectious_panelsArray.controls[<any>'id'].setValue(this.htrDetail.infectious_panel.id);
              infectious_panelsArray.controls[<any>'hiv_4th_gene_assay_serum_index_value'].setValue(this.htrDetail.infectious_panel.hiv_4th_gene_assay_serum_index_value);
              infectious_panelsArray.controls[<any>'hiv_4th_gene_assay_serum_result'].setValue(this.htrDetail.infectious_panel.hiv_4th_gene_assay_serum_result);
              infectious_panelsArray.controls[<any>'hiv_1_2_antibodies_screening_test_serum_result'].setValue(this.htrDetail.infectious_panel.hiv_1_2_antibodies_screening_test_serum_result);
              infectious_panelsArray.controls[<any>'hepatitis_b_surface_antigen_serum'].setValue(this.htrDetail.infectious_panel.hepatitis_b_surface_antigen_serum);
              infectious_panelsArray.controls[<any>'hepatitis_c_antibodies_serum'].setValue(this.htrDetail.infectious_panel.hepatitis_c_antibodies_serum);
              infectious_panelsArray.controls[<any>'hepatitis_c_antibodies_serum_patient_value'].setValue(this.htrDetail.infectious_panel.hepatitis_c_antibodies_serum_patient_value);
              infectious_panelsArray.controls[<any>'vdrlrpr_serum'].setValue(this.htrDetail.infectious_panel.vdrlrpr_serum);
              infectious_panelsArray.controls[<any>'malaria_parasiteblood_parasite_smear_identification'].setValue(this.htrDetail.infectious_panel.malaria_parasiteblood_parasite_smear_identification);
            }

            this.albums = [];
            if (this.htrDetail.htr_documents.length > 0) {
              this.htrDetail.htr_documents.forEach((element: any, index: number) => {
                const album = {
                  src: element.path,
                  fileType: element.fileType,
                  caption: "Document " + (index + 1),
                  name: element.name,
                  thumb: element.path_thumb,
                  path: element.path,
                };
                this.albums.push(album);
              });
            }

            console.log(this.albums);

          } else {
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })

  }

  ngOnInit(): void {
    this.htrForm = new FormGroup({
      htr_informations: new FormGroup({
        id: new FormControl(''),
        participant_id: new FormControl('', [Validators.required]),
        patient_name: new FormControl(''),
        health_care_center: new FormControl('', [Validators.required]),
        lr_no: new FormControl(''),
        htr_available: new FormControl(''),
        glycated_test_data: new FormControl('no', [Validators.required]),
        // glucose_test_data: new FormControl('no', [Validators.required]),
        bloodsugar_fastings_test_data: new FormControl('no', [Validators.required]),
        complete_blood_count_test_data: new FormControl('no', [Validators.required]),
        lipid_profile_test_data: new FormControl('no', [Validators.required]),
        thyroid_report_test_data: new FormControl('no', [Validators.required]),
        creatinine_test_data: new FormControl('no', [Validators.required]),
        phosphorous_serum_test_data: new FormControl('no', [Validators.required]),
        electrolytes_serum_test_data: new FormControl('no', [Validators.required]),
        statin_test_data: new FormControl('no', [Validators.required]),
        hepatitis_b_test_data: new FormControl('no', [Validators.required]),
        // <!-- remove/modify htr-columns -->
        // vitamin_b12_test_data: new FormControl('no', [Validators.required]),
        // hydroxy_vitamin_d_test_data: new FormControl('no', [Validators.required]),
        blood_grouping_test_data: new FormControl('no', [Validators.required]),
        liver_function_test_data: new FormControl('no', [Validators.required]),
        uric_acid_test_data: new FormControl('no', [Validators.required]),
        protein_test_data: new FormControl('no', [Validators.required]),
        calcium_test_data: new FormControl('no', [Validators.required]),
        // <!-- remove/modify htr-columns -->
        // differential_blood_count_test_data: new FormControl('no', [Validators.required]),
        // serum_calcium_test_data: new FormControl('no', [Validators.required]),
        rheumatoid_arthritis_test_data: new FormControl('no', [Validators.required]),
        complete_urine_test_data: new FormControl('no', [Validators.required]),
        vitamin_d_total_test_data: new FormControl('no', [Validators.required]),
        infectious_panel_test_data: new FormControl('no', [Validators.required]),
      }),
      glycated_hemoglobins: new FormGroup({
        id: new FormControl(''),
        hba1c: new FormControl(''),
        estimated_average_glucose: new FormControl(''),
        mean_plasma_glucose: new FormControl(''),
      }),
      glucose_tests: new FormGroup({
        id: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // fasting_mean_blood: new FormControl(''),
        // blast: new FormControl(''),
        // random_average: new FormControl(''),
      }),
      blood_sugar_fasting: new FormGroup({
        id: new FormControl(''),
        fasting_blood_sugar: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // post_lunch_blood_sugar: new FormControl(''),
        // random_blood_sugar: new FormControl(''),
        post_prandial: new FormControl(''),
      }),
      complete_blood_counts: new FormGroup({
        id: new FormControl(''),
        haemoglobin: new FormControl(''),
        total_rbc_count: new FormControl(''),
        mcv: new FormControl(''),
        mch: new FormControl(''),
        mchc: new FormControl(''),
        total_wbc_count: new FormControl(''),
        neutrophils: new FormControl(''),
        lymphocytes: new FormControl(''),
        monocytes: new FormControl(''),
        eosinophils: new FormControl(''),
        basophils: new FormControl(''),
        platelet_counts: new FormControl(''),
        platelets_on_smear: new FormControl(''),
        mpv: new FormControl(''),
        morphology_red_blood_cell: new FormControl(''),
        peripheral_smear_wbc: new FormControl(''),
        peripheral_smear_platelets: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // rdw_cv: new FormControl(''),
        pdw: new FormControl(''),
        pct: new FormControl(''),
        thalassemia_check: new FormControl(''),
        morphology_wbc: new FormControl(''),
        platelet_morphology: new FormControl(''),
        granulocytes: new FormControl(''),
        granulocytes_10_3: new FormControl(''),
        rdw: new FormControl(''),
        rdw_sd: new FormControl(''),
        plcc_10_3: new FormControl(''),
        plcr: new FormControl(''),
        absolute_lymphocyte_count: new FormControl(''),
        absolute_monocyte_count: new FormControl(''),
        absolute_eosinophil_count: new FormControl(''),
        absolute_basophil_count: new FormControl(''),
        hematocrit: new FormControl(''),
        absolute_neutrophil_count: new FormControl(''),
        leucocyte_microscopic_examination: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // epithelial_cells_microscopic: new FormControl(''),
        rbc_microscopic: new FormControl(''),
        immature_granulocyte: new FormControl(''),
        nucleated_rbc: new FormControl(''),
        neutrophil_lymphocyte_ratio: new FormControl(''),
        erythrocyte_sedimentation_rate: new FormControl(''),
        mentzer_index: new FormControl(''),
        pdw_fl: new FormControl(''),
      }),
      lipid_profiles: new FormGroup({
        id: new FormControl(''),
        serum_cholesterol: new FormControl(''),
        serum_triglyceride: new FormControl(''),
        // hdl_cholesterol_plus: new FormControl(''),
        // ldl_cholesterol: new FormControl(''),
        vldl_cholesterol: new FormControl(''),
        hdl_cholesterol_ratio: new FormControl(''),
        // ldl_chol_hdl_ratio: new FormControl(''),
        // tc_hdl_chol_ratio: new FormControl(''),
        ldl_hdl_ratio: new FormControl(''),
        non_hdl_chol: new FormControl(''),
        // triglyceride_enzymatic: new FormControl(''),
        // triglyceride_liquid: new FormControl(''),
        // cholesterol_gen_2: new FormControl(''),
        cholesterol_ldl_direct: new FormControl(''),
        hdl: new FormControl(''),
        apperance_serum_refrigeration: new FormControl(''),
        naked_eye_appearance_serum: new FormControl(''),
        // appearance_other: new FormControl(''),
        // hdl_cholesterol_direct: new FormControl(''),
        total_lipid: new FormControl(''),
      }),
      thyroid_reports: new FormGroup({
        id: new FormControl(''),
        triidothyronine: new FormControl(''),
        total_thyroxine: new FormControl(''),
        thyroid_stimulating_hormone: new FormControl(''),
      }),
      creatinines: new FormGroup({
        id: new FormControl(''),
        serum_creatinine: new FormControl(''),
        est_glomerular_filter_rate: new FormControl(''),
        cystatin_c: new FormControl(''),
      }),
      phosphorous_serums: new FormGroup({
        id: new FormControl(''),
        phosphorous: new FormControl(''),
      }),
      electrolytes_serums: new FormGroup({
        id: new FormControl(''),
        sodium: new FormControl(''),
        potassium: new FormControl(''),
        chloride: new FormControl(''),
      }),
      statin_reports: new FormGroup({
        id: new FormControl(''),
        allele_tested: new FormControl(''),
        slco1b1: new FormControl(''),
        slco1b1_genotype_result: new FormControl(''),
      }),
      hepatitis_b_surfaces: new FormGroup({
        id: new FormControl(''),
        hepatitis_b_surface: new FormControl(''),
      }),
      // <!-- remove/modify htr-columns -->
      // vitamin_b_twelves: new FormGroup({
      //   id: new FormControl(''),
      //   vitamin_b_twelve: new FormControl(''),
      // }),
      blood_groupings: new FormGroup({
        id: new FormControl(''),
        abo_group: new FormControl(''),
        rh_type: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // blood_group: new FormControl(''),
      }),
      liver_function_tests: new FormGroup({
        id: new FormControl(''),
        bilirubin_indirect: new FormControl(''),
        albumin: new FormControl(''),
        alkaline_phosphatase: new FormControl(''),
        bilirubin_direct: new FormControl(''),
        bilirubin_total: new FormControl(''),
        aspartate_aminotransferase: new FormControl(''),
        alanine_aminotransferase: new FormControl(''),
        ag_ratio: new FormControl(''),
        globulin: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // bilirubin_total_method: new FormControl(''),
        // albumin_method: new FormControl(''),
        // alkaline_phosphatase_method: new FormControl(''),
        // total_protein_method: new FormControl(''),
        // bilirubin_direct_method: new FormControl(''),
        // aspartate_aminotransferase_method: new FormControl(''),
        // alanine_aminotransferase_method: new FormControl(''),
        // ag_ratio_method: new FormControl(''),
        // globulin_method: new FormControl(''),
        total_protein: new FormControl(''),
      }),
      uric_acid_tests: new FormGroup({
        id: new FormControl(''),
        blood_urea_nitrogen: new FormControl(''),
        uric_acid: new FormControl(''),
        blood_urea: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // blood_urea_method: new FormControl(''),
        // blood_urea_nitrogen_method: new FormControl(''),
      }),
      protein_tests: new FormGroup({
        id: new FormControl(''),
        creactive_protein: new FormControl(''),
        gamma_glutamyl_transferase: new FormControl(''),
        // <!-- remove/modify htr-columns -->
        // protein_total: new FormControl(''),
      }),
      // <!-- remove/modify htr-columns -->
      // hydroxy_vitamin_ds: new FormGroup({
      //   id: new FormControl(''),
      //   hydroxy_vitamin_d: new FormControl(''),
      // }),
      calciums: new FormGroup({
        id: new FormControl(''),
        calcium: new FormControl(''),
      }),
      // <!-- remove/modify htr-columns -->
      // differential_blood_counts: new FormGroup({
      // id: new FormControl(''),
      // promyelocyte: new FormControl(''),
      // myelocyte: new FormControl(''),
      // metamyelocyte: new FormControl(''),
      // band_form: new FormControl(''),
      // promonocyte: new FormControl(''),
      // prolymphocytes: new FormControl(''),
      // }),
      rheumatoid_arthritis: new FormGroup({
        id: new FormControl(''),
        ra_factor: new FormControl(''),
      }),
      // <!-- remove/modify htr-columns -->
      complete_urine_examination: new FormGroup({
        id: new FormControl(''),
        // quantity: new FormControl(''),
        // apperance_method: new FormControl(''),
        color: new FormControl(''),
        apperance: new FormControl(''),
        ph: new FormControl(''),
        // ph_method: new FormControl(''),
        specific_gravity: new FormControl(''),
        // specific_gravity_method: new FormControl(''),
        protein: new FormControl(''),
        // protein_method: new FormControl(''),
        glucose: new FormControl(''),
        // glucose_method: new FormControl(''),
        ketones: new FormControl(''),
        // ketones_method: new FormControl(''),
        bilirubin: new FormControl(''),
        // bilirubin_method: new FormControl(''),
        urobilinogen: new FormControl(''),
        // urobilinogen_method: new FormControl(''),
        blood: new FormControl(''),
        // blood_method: new FormControl(''),
        nitrite: new FormControl(''),
        // nitrite_method: new FormControl(''),
        wbc: new FormControl(''),
        epithelial_cells: new FormControl(''),
        red_blood_cells: new FormControl(''),
        casts_hpf: new FormControl(''),
        crystals: new FormControl(''),
        bacteria: new FormControl(''),
        yeast: new FormControl(''),
      }),
      vitamin_d_total: new FormGroup({
        id: new FormControl(''),
        vitamin_d_total: new FormControl(''),
      }),
      // serum_calcium: new FormGroup({
      //   id: new FormControl(''),
      //   serum_calcium: new FormControl(''),
      // }),
      infectious_panels: new FormGroup({
        id: new FormControl(''),
        hiv_4th_gene_assay_serum_index_value: new FormControl(''),
        hiv_4th_gene_assay_serum_result: new FormControl(''),
        hiv_1_2_antibodies_screening_test_serum_result: new FormControl(''),
        hepatitis_b_surface_antigen_serum: new FormControl(''),
        hepatitis_c_antibodies_serum: new FormControl(''),
        hepatitis_c_antibodies_serum_patient_value: new FormControl(''),
        vdrlrpr_serum: new FormControl(''),
        malaria_parasiteblood_parasite_smear_identification: new FormControl(''),
      }),
    });

    //For Search Participant
    const htr_informationArray = this.htrForm.get('htr_informations') as FormArray;
    this.participantsFiltered = htr_informationArray.controls[<any>'participant_id'].valueChanges.pipe(
      startWith(''),
      map(value => {
        if (value && value.length > 1) {
          const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.name;
          this.searchParticipant(filterValue);
          return this.participants.filter((participants: { new_pid: string; }) => participants.new_pid.toLowerCase().includes(filterValue));
        }
        return this.participants.slice();
      }),
    );
  }

  getParticipantsData(participantID: any) {
    if (participantID && this.participants.find((participants: { id: string; }) => participants.id == participantID)) {
      let finalParticipant = this.participants.find((participants: { id: string; }) => participants.id == participantID).new_pid;
      this.htrForm.controls['htr_informations'].value.participant_id = participantID;
      this.participantHeaderDetails = this.participants.find((participants: { id: string; }) => participants.id == participantID);
      return finalParticipant;
    }
    return null;
  }

  searchParticipant(filterValue: string) {
    this.participantService.getParticipantByPID(filterValue).subscribe({
      next: (result: any) => {
        this.participants = result.data;
      },
      error: (e) => { },
      complete: () => { },
    });
  }
  //For Search Participant

  /**
   * Update HTR Marked as QC'ed
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  onMarkedQC() {

    const formData: FormData = new FormData();
    formData.append('htrID', this.htrID);

    this.htrService.updateQCed(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            // this.router.navigate(["/htr"]);

            this.commonService.showSuccessToast(
              "Record marked as QC\'ed"
            );

            this.qcedAt = 'QC\'ed by ' + result.data.qcedBy + ' @ ' + result.data.qcedAt;
            this.isQced = true;

          } else {
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      });

  }

  /**
   * Update HTR Marked as Reviewed
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  onMarkedReviewed() {

    const formData: FormData = new FormData();
    formData.append('htrID', this.htrID);

    this.htrService.updateReviewed(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/htr"]);

            this.commonService.showSuccessToast(
              "Record marked as Reviewed!"
            );

            this.reviewedAt = 'Reviewed by ' + result.data.reviewedBy + ' @ ' + result.data.reviewedAt;
            this.isReviewed = true;

          } else {
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      });

  }

  /**
   * Validate form fields recursivly
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Add and update HTR Data
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
  onSubmit(): void {


    if (this.htrForm.invalid) {
      this.validateAllFormFields(this.htrForm);
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    }

    this.htrService.store(this.htrForm.value)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/htr"]);
            if (this.htrID) {
              this.commonService.showSuccessToast(
                "HTR data updated successfully!"
              );
            } else {
              this.commonService.showSuccessToast(
                "HTR data added successfully!"
              );
            }
          } else {
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }

  openDialog(report: any) {
    console.log('this is report', report);
    this.dialog.open(DialogEmaple, { data: { image: report.src } });
  }

  downloadMedicalRecordDoc(report: any) {
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append("file_name", report.name);
    this.participantService.downloadMedicalRecordDoc(formData, this.htrDetail.participantInfo.id).subscribe({
      next: (result: any) => {
        var link = document.createElement('a');
        link.href = result.data.url;
        link.click();
      },
      error: () => { },
      complete: () => {
        this.loading = false;
      },
    });
  }

}

// dialog code start
@Component({
  selector: 'dialogEmaple',
  templateUrl: 'dialog-emaple.html',
})
export class DialogEmaple {
  image: string = '../../../../../../assets/images/patient-pic-1.jpg';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogEmaple>
  ) {
    if (data.image != undefined && data.image != '') {
      this.image = data.image;
    }
  }
  onClose(): void {
    this.dialogRef.close(true);
  }
}
// dialog code end
