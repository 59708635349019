<section class="my-profile-wrape">
	<div class="page-title-box">
		<h1> Participant Gender Data Validation Statistics </h1>
		<ul class="bradcrumb">
			<li>Participant Gender Data Validation</li>
			<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
		</ul>
	</div>

	<section class="container-fluid">
		<div class="row">
			<div class="col-xl-12 col-md-12">
				<div class="stat-wrape four">
					<div class="stat-pad">
						<p>PENDING GENDER</p>
						<h2>
							<i class="theme-icon participant-enroll"></i>
							{{ lastThreeDaysData?.pendingCounts  ?? 0 }}
						</h2>
					</div>
					<div class="stat-pad">
						<p>REVIEW-PENDING GENDER</p>
						<h2><i class="theme-icon camp-held"></i>
							{{ lastThreeDaysData?.nonReviewedCounts  ?? 0 }}
						</h2>
					</div>
					<div class="stat-pad">
						<p>VALIDATED GENDER</p>
						<h2><i class="theme-icon couselor"></i> {{ lastThreeDaysData?.validatedCounts  ?? 0 }}</h2>
					</div>
				</div>
			</div>

			<div class="col-xl-12 col-md-12">
                <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text"> Gender Validation Filters </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="input-wrape">
                                    <mat-label>Stages of Validation</mat-label>
                                    <div class="control-pad">
                                        <ng-select appendTo="body" (change)="changeGenderFilter($event)"
                                            bindLabel="label" bindValue="value" [dropdownPosition]="'bottom'"
                                            [searchable]="true" [multiple]="false" [closeOnSelect]="true"
                                            [items]="genderValidationFilters" [(ngModel)]="selectedGenderValidationFilter"
                                            placeholder="Please select"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-4 button-controller ">
                                <button (click)="updateReviewed('review_pending')" mat-raised-button class="btn" color="secoundary"
                                    *ngIf="selectedStage == 'pending' && currentMenuPermission?.edit == true">Move to Review Pending</button>
								<button (click)="updateReviewed('validated')" mat-raised-button class="btn" color="secoundary"
                                    *ngIf="selectedStage == 'review_pending' && currentMenuPermission?.edit == true">Mark As Reviewed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

			<div class="col-xl-12 col-md-12">
				<!-- table expand code start -->
				<div class="row">
					<div class="col-xl-12 col-md-12">
						<div class="card-wrape">
							<mat-spinner *ngIf="loading"></mat-spinner>
							<div class="progress-overlay"></div>
							<div class="card-head">
								<h4 class="left-text"> Gender Validation List </h4>
                               	<div class="right-control">
									<div class="table-search-wrape side-info">
										<span class="icon"></span>
										<input (keyup)="searchFilter($event)" placeholder="Search" #input
											class="form-control" type="text">
										<div class="info-pad popup-over">
											<button class="info-icon"></button>
											<div class="info-section popupcontent">
												Sr.No., New PID, New Recollection ID, Site Code, First Name, Middle
												Name, Last Name, Gender, Collection Date
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-body table-section">
								<div class="table-wrape">
									<table mat-table [dataSource]="dataSources" multiTemplateDataRows
										class="mat-elevation-z8" #expandSort="matSort" matSort
										(matSortChange)="SortFilter($event)">
										<ng-container matColumnDef="expand">
											<th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
											<td mat-cell *matCellDef="let element">
												<button mat-icon-button aria-label="expand row"
													(click)="(expandedElement = expandedElement === element ? undefined : element); $event.stopPropagation()">
													<mat-icon
														*ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
													<mat-icon
														*ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
												</button>
											</td>
										</ng-container>
										<!-- Checkbox Column -->
										<ng-container matColumnDef="select">
											<th mat-header-cell *matHeaderCellDef [hidden]="selectedStage == 'validated'">
												<mat-checkbox (change)="$event ? masterToggle() : null"
													[checked]="selectedParticipants.hasValue() && isAllSelected()"
													[indeterminate]="selectedParticipants.hasValue() && !isAllSelected()">
												</mat-checkbox>
											</th>
											<td mat-cell *matCellDef="let element" [hidden]="selectedStage == 'validated'">
												<mat-checkbox (click)="$event.stopPropagation()"
													(change)="$event ? selectedParticipants.toggle(element) : null"
													[checked]="selectedParticipants.isSelected(element)">
												</mat-checkbox>
											</td>
										</ng-container>
										<ng-container matColumnDef="participant_id">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Sr.No.</th>
											<td mat-cell *matCellDef="let element">{{element.participant_id}}</td>
										</ng-container>
										<ng-container matColumnDef="new_pid">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>New PID</th>
											<td mat-cell *matCellDef="let element">{{element.new_pid}}</td>
										</ng-container>
										<ng-container matColumnDef="new_recollection_id">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>New Recollection ID
											</th>
											<td mat-cell *matCellDef="let element">{{element.new_recollection_id}}</td>
										</ng-container>
										<ng-container matColumnDef="site_code">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Site Code</th>
											<td mat-cell *matCellDef="let element">{{element.site_code}}</td>
										</ng-container>
										<ng-container matColumnDef="first_name">
											<th mat-header-cell *matHeaderCellDef>First Name</th>
											<td mat-cell *matCellDef="let element">{{element.first_name}}</td>
										</ng-container>
										<ng-container matColumnDef="middle_name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Middle Name</th>
											<td mat-cell *matCellDef="let element">{{element.middle_name}}</td>
										</ng-container>
										<ng-container matColumnDef="last_name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
											<td mat-cell *matCellDef="let element">{{element.last_name}}</td>
										</ng-container>
										<ng-container matColumnDef="gender">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
											<td mat-cell *matCellDef="let element">{{element.gender | titlecase}}</td>
										</ng-container>
										<ng-container matColumnDef="collection_date">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Collection Date</th>
											<td mat-cell *matCellDef="let element">{{element.collection_date}}</td>
										</ng-container>
										<!-- <ng-container matColumnDef="occupation_type">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Occupation Type</th>
											<td mat-cell *matCellDef="let element">{{element.occupation_type}}</td>
										</ng-container> -->

										<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
										<ng-container matColumnDef="expandedDetail">
											<td mat-cell *matCellDef="let element"
												[attr.colspan]="columnsToDisplayWithExpand.length">
												<div class="example-element-detail"
													[@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
													<div class="example-element-position"> </div>
													<div class="inner-bg-gray">
														<div class="form-area">
                                                            <div class="head-part">
                                                                <h2>Add other data in Gender Validation
                                                                </h2>
                                                                <div class="icons-wrape" *ngIf="currentMenuPermission?.edit == true">
                                                                    <button type="button" (click)="clickEventTableForm(element)"
                                                                        class="edit-icon" title="Edit"
                                                                        *ngIf="!element.editing"></button>
                                                                    <button class="delete-icon close-icon"
                                                                        title="Cancel" (click)="cancelEvent(element)"
                                                                        *ngIf="element.editing"></button>
                                                                    <button class="save-icon" title="Save"
                                                                        (click)="saveEvent(element)"
                                                                        *ngIf="element.editing"></button>
                                                                </div>
                                                            </div>
                                                            <div class="form-pad"
                                                                [ngClass]="element.editing ? 'enable' : 'disable'">
                                                                <div class="row">
                                                                    <div class="col-xl-3 col-md-3">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Participant Declared Gender
                                                                            </mat-label>
																			<div class="control-pad">
                                                                                <ng-select appendTo="body"
                                                                                    bindLabel="label" bindValue="value"
                                                                                    [dropdownPosition]="'bottom'"
                                                                                    [searchable]="true"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [items]="genderTypes"
                                                                                    [(ngModel)]="element.gender"
																					[disabled]="true">
                                                                                </ng-select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-md-3">
                                                                        <div class="input-wrape">
                                                                            <mat-label>YSTS Gender (Lab Data)
                                                                            </mat-label>
																			<mat-form-field appearance="outline" class="disabled">
																				<input matInput #input
																					type="text"
																					[(ngModel)]="element.ysts_gender"
																					[disabled]="true"
																				>
																			</mat-form-field>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-md-3">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Validated Gender
                                                                            </mat-label>
																			<div class="control-pad">
                                                                                <ng-select appendTo="body"
                                                                                    bindLabel="label" bindValue="value"
                                                                                    [dropdownPosition]="'bottom'"
                                                                                    [searchable]="true"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [items]="genderTypes"
                                                                                    [(ngModel)]="element.validated_gender"
																					[disabled]="selectedStage == 'validated'"
                                                                                    placeholder="Please select Validated Gender">
                                                                                </ng-select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-md-3">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Flag</mat-label>
                                                                            <div class="control-pad">
                                                                                <ng-select appendTo="body"
                                                                                    bindLabel="label" bindValue="value"
                                                                                    [dropdownPosition]="'bottom'"
                                                                                    [searchable]="true"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [items]="flag"
                                                                                    [(ngModel)]="element.validated_gender_status"
                                                                                    placeholder="Please select flag"
																				>
                                                                                </ng-select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
													</div>
												</div>
											</td>
										</ng-container>
										<tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
										<tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
											class="example-element-row"
											[class.example-expanded-row]="expandedElement === element"
											(click)="expandedElement = expandedElement === element ? undefined : element">
										</tr>
										<tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
											class="example-element-row example-detail-row"></tr>
										<tr mat-row>
											<td *matNoDataRow colspan="11">{{matNoDataRowTitle}}</td>
										</tr>
									</table>
								</div>
								<mat-paginator #expandpagination [length]="totalRows" [pageIndex]="currentPage"
									[pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
									(page)="pageChanged($event)" aria-label="Select page" showFirstLastButtons>
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
				<!-- table expand code end -->
			</div>
		</div>
	</section>
</section>