import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/service/site.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { CommonService } from 'src/app/service/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Site Code',
      dataKey: 'site_code',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['site_code']}`,
    },
    {
      name: 'Site Name',
      dataKey: 'site_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['site_name']}`,
    },
    {
      name: 'Project Name',
      dataKey: 'project_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['project_name']}`,
    },
    {
      name: 'Start Date',
      dataKey: 'start_date',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['start_date']}`,
    },
    {
      name: 'End Date',
      dataKey: 'end_date',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['end_date']}`,
    },
    {
      name: 'Camp-City',
      dataKey: 'city_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['city_name']}`,
    },
    {
      name: 'Camp-State',
      dataKey: 'state_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['state_name']}`,
    },
    {
      name: 'Site Coordinator Name',
      dataKey: 'site_coordinator_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['site_coordinator_name']}`,
    },
  ];

  public isApproved: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public loading: boolean = false;
  public editLink = 'site/edit/';
  public viewLink = 'site/dashboard/';
  public title = 'Site';
  public totalRecord = 1;
  public totalSites: number = 0;
  public totalCoordinator: number = 0;
  public totalCounsellor: number = 0;
  public totalPhlebotomist: number = 0;
  public siteList: any;
  public currentMenuPermission: any;


  constructor(
    private siteService: SiteService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
  }

  /**
  * it will get all module
  * @author Dhaval Bera
  */
  getAll() {
    this.loading = true;
    this.siteService.getRecordByFilter(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      this.isApproved
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.siteList = result.data.list.data;
        this.totalRecord = result.data.list.data.length;
        this.totalRows = result.data.list.total;
        this.totalSites = this.totalRows;
        this.totalCoordinator = result.data.stat.totalCoordinator;
        this.totalCounsellor = result.data.stat.totalCounselloer;
        this.totalPhlebotomist = result.data.stat.totalPhlebotomist;
      },
      error: (err: any) => {
        this.loading = false;
        // console.log('Site Listing err :: ', err);
      }
    });
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }
  viewAction(event: any) {
    this.router.navigate([this.viewLink + event.id]);
  }
  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  SortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }

}