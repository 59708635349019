<div class="row">
  <div class="col-xl-8 col-md-8">
    <h1 class="date-title"> QC Track Log List </h1>
  </div>
  <div class="col-xl-4 col-md-4">
    <div class="input-wrape">
      <mat-label><i>*</i>Select the user from the list: </mat-label>
      <div class="control-pad">
          <ng-select [items]="userList" formControlName="userId"
              bindLabel="name" bindValue="id" labelForId="name"
              placeholder="Please Select"
              (change)="changeUser($event)">
          </ng-select>
      </div>
    </div>
  </div>
</div>

<h5 *ngIf="logs && !logs.length">No logs found</h5>
<mat-spinner *ngIf="showLoading"></mat-spinner>
<ul *ngFor="let log of logs">
  <h2 class="date-title"> {{log.date| date:'d MMM y'}}</h2>
  <li class="li-sidebar-space" *ngFor="let dataLog of log.data">
    <ng-container *ngIf="dataLog.field == 'Created At' && !dataLog.old_value">
      <b>{{ dataLog.user_name }}</b> created new <b class="color-blue">{{ dataLog.model }}</b> At {{ dataLog.created_at | date:'d MMM y h:mm a' }}, 
    </ng-container>
    <ng-container *ngIf="dataLog.field == 'Deleted At'">
      <b>{{ dataLog.user_name }}</b> deleted record of <b class="color-blue">{{ dataLog.model }}</b> At {{ dataLog.created_at | date:'d MMM y h:mm a' }}, 
    </ng-container>
    <ng-container *ngIf="dataLog.created_at && dataLog.old_value">
      <b>{{ dataLog.user_name }}</b> changed <b class="color-blue">{{ dataLog.model }} {{dataLog.field}}</b> from
      <b class="color-yellow">{{ dataLog.old_value ? dataLog.old_value : "empty" }}</b> to <b class="color-yellow">{{ dataLog.new_value ? dataLog.new_value : "empty" }}</b> At {{ dataLog.created_at | date:'d MMM y h:mm a' }}, 
    </ng-container>
  </li>
</ul>

<a class="load-more li-sidebar-space" *ngIf="(currentPage * itemsPerPage) < totalRecords" (click)="loadMore()">Load More >></a>
<a class="load-more li-sidebar-space" *ngIf="(currentPage * itemsPerPage) >= totalRecords && currentPage != 1" (click)="goToFirst()">Go to First Page</a>
<a class="load-more li-sidebar-space" *ngIf="(totalRecords / itemsPerPage) >= (currentPage - 1) && currentPage != 1" (click)="goToPrevious()"> Go to Previous</a>
