import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialsModule } from './../../../../modules/materials.module';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { SiteDashboardComponent } from './site-dashboard.component';
import { DiseaseChartComponent } from './disease-chart/disease-chart.component';
import { ParticipantCountChartComponent } from './participant-count-chart/participant-count-chart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopulationChartComponent } from './population-chart/population-chart.component';
import { StateChartComponent } from './state-chart/state-chart.component';
import { GenderChartComponent } from './gender-chart/gender-chart.component';
@NgModule({
  imports: [
    CommonModule,
    NgxEchartsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialsModule,
    NgSelectModule
  ],
  declarations: [
    SiteDashboardComponent,
    DiseaseChartComponent,
    ParticipantCountChartComponent,
    PopulationChartComponent,
    StateChartComponent,
    GenderChartComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [

  ],
})

export class SiteDashboardModule {


}
