import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { LabDataService } from 'src/app/service/lab-data.service';

@Component({
  selector: 'app-lab-data-view',
  templateUrl: './lab-data-view.component.html',
  styleUrls: ['./lab-data-view.component.scss']
})
export class LabDataViewComponent implements OnInit {

  public labDataID: any;
  public labDataDetail: any;

  constructor(
    public labDataService: LabDataService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    protected router: Router
  ) { 
    this.labDataID = this.route.snapshot.paramMap.get('id');
    if (this.labDataID) {
      this.setViewData();
    } else {
      this.labDataID = 0;
    }
  }

  setViewData(): void{

    console.log('edit mode');
    const ID = this.route.snapshot.paramMap.get('id');

    this.labDataService.getRow(<string>ID)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.labDataDetail = result.data;
        }
        else{

        }
      },
      error:(err: any) => {
        console.log(err);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })

  }

  ngOnInit(): void {
  }

}
