import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class QCTrackLogService extends CrudService {

  constructor(public override http: HttpClient) {
    super('qc-track-logs', http);
  }

  /**
   * get listing
   * @returns object
   */
  getQcTrackLogs(user_id: number, page: number) {
    return this.http.get(
      `${this.baseUrl}?user_id=${user_id}&page=${page}`
    );
  }

  /**
   * get list of user
   * @author Farhan Shaikh
   * @return object
   */
  getQcTrackLogUsers() {
    return this.http.get(this.baseUrl + '/user-list');
  }
}
