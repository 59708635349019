import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { DiseaseService } from 'src/app/service/disease.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Disease } from 'src/app/interfaces/Disease';
import { DrugBrandService } from 'src/app/service/drug-brand.service';
import { GenericService } from 'src/app/service/generic.service';

@Component({
  selector: 'app-drug-brand-form',
  templateUrl: './drug-brand-form.component.html',
  styleUrls: ['./drug-brand-form.component.scss'],
})
export class DrugBrandFormComponent implements OnInit {
  public title: any;
  public loading: Boolean = false;
  public isAddMode: boolean = true;
  public disableSubmitBtn: boolean = false;
  public userId: any = '';
  public brandId: any = '';
  public brandDetails: any = '';
  public brandForm!: FormGroup;
  // public nameMinLength: number = 3;
  public is_brand_or_symptom_checked: boolean = false;
  public buttonText: string;
  public selectedGenerics: any = [];
  public selectMapOption: boolean = false;
  public isApproved: number = 1;
  public brandList: any[] = [];
  public snomedIdList: any[] = [];
  public genericList: any[] = [];
  public cancelLink: string = '/dictionary-management/drug-brand';

  constructor(
    private diseaseService: DiseaseService,
    private genericService: GenericService,
    private drugBrandService: DrugBrandService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.brandId = this.route.snapshot.paramMap.get('id');

    if (this.brandId !== 0 && this.brandId !== null) {
      this.title = 'Edit Drug Brand';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add Drug Brand';
      this.buttonText = 'Add';
      this.brandId = 0;
    }
  }

  ngOnInit(): void {
    this.getDisease();
    if (this.brandId !== 0 && this.brandId !== null) {
      this.setEditData();
    }
    this.isAddMode = !this.brandId;
    this.userId = localStorage.getItem('userId');
    this.brandForm = this.formBuilder.group({
      brand_name: [
        '',
        [
          Validators.required,
          // Validators.minLength(this.nameMinLength),
          Validators.maxLength(255),
        ],
      ],
      generic_id: [null, ''],
      snomed_id: [null, ''],
    });
  }

  onSubmit(data: any) {
    if (this.brandForm.invalid) {
      this.commonService.validateAllFormFields(this.brandForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('brand_name', this.brandForm.controls['brand_name'].value);

    if (
      this.brandForm.controls['snomed_id'].value != '' &&
      this.brandForm.controls['snomed_id'].value != null &&
      this.brandForm.controls['snomed_id'].value.length > 0
    ) {
      formData.append('snomed_id', JSON.stringify(this.brandForm.controls['snomed_id'].value));
    }
    if (
      this.brandForm.controls['generic_id'].value != '' &&
      this.brandForm.controls['generic_id'].value != null &&
      this.brandForm.controls['generic_id'].value.length > 0
    ) {
      formData.append('generic_id', this.brandForm.controls['generic_id'].value);
    }

    if (this.brandId === 0) {
      formData.append('created_by', this.userId);
    } else {
      formData.append('updated_by', this.userId);
    }
    if (this.isAddMode) {
      this.createBrand(formData);
    } else {
      this.updateBrand(formData);
    }
  }

  /**
   * call Create brand api
   * @returns null
   */
  private createBrand(formData: any): void {
    this.drugBrandService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast(result.message);
          this.router.navigate(['/dictionary-management/drug-brand']);
        } else {
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(result.message);
          if (typeof result.data === 'object') {
            for (const key in result.data) {
              this.brandForm
                .get(key)!
                .setErrors({ validField: result.data[key] });
            }
          }
        }
      },
      error: (err) => {
        console.log('error from create brand', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }

  /**
   * call Update brand api
   * @returns null
   */
  private updateBrand(formData: any): void {
    this.drugBrandService
      .update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(result.message);
            this.router.navigate(['/dictionary-management/drug-brand']);
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err: any) => {
          this.disableSubmitBtn = false;
          console.log('error from update brand', err);
        },
      });
  }

  setEditData() {
    this.drugBrandService.getRow(this.brandId).subscribe((result: any) => {
      if (result.status !== AppConstants.serverSuccessStatus) {
        this.router.navigate(['page-not-found']);
      }
      this.brandDetails = result['data'];

      // Set Selected snomedId
      let selected_diseases: any[] = [];
      this.brandDetails.brands_diseases.forEach((res: any) => {
        if (res.get_disease != null) {
          selected_diseases.push(res.get_disease);
        }
      });

      // Set Selected generic
      this.selectedGenerics = [];
      let selectedGenerics: any[] = [];
      this.brandDetails.brands_generics.forEach((res: any) => {
        this.selectedGenerics.push(res.get_generic);
        selectedGenerics.push(res.get_generic.id);
      });
      this.genericList = this.selectedGenerics;
      this.brandForm.patchValue({
        brand_name: this.brandDetails.brand_name,
        snomed_id: selected_diseases,
        generic_id: selectedGenerics,
      });
    });
  }

  /**
   * Check brand field is existed or not
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  checkUniqueDrugBrand(event: any) {
    let name = (event.target as HTMLInputElement).value;
    if (name !== '' && name != null && name.length >= 0 && name.length < 255) {
      this.drugBrandService
        .checkUniqueDrugBrand(name, this.brandId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.brandForm.controls['brand_name'].setErrors(null);
            } else {
              this.brandForm.controls['brand_name'].setErrors({
                unique: 'Drug Brand name already exists',
              });
            }
          },
          error: (error: any) => {
            // console.log(error);
            if (error.status === 400) {
              this.brandForm.controls['name'].setErrors({
                unique: 'Drug Brand name already exists',
              });
            } else {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            }
          },
        });
    }
  }

  getDisease() {
    this.diseaseService.getActiveList().subscribe({
      next: (result: any) => {
        if (result.status === 1 || result.status === 200) {
          this.snomedIdList = result.data;
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  getGeneric(event: any) {
    if (event.term !== undefined && event.term.length >= 2) {
      this.loading = true;
      this.genericService.getActiveList(event.term).subscribe({
        next: (result: any) => {
          if (result.status === 1 || result.status === 200) {
            this.genericList = result.data;
            this.loading = false;
          }
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;
        },
      });
    }
  }
}
