<!-- Non-Alcoholic Fatty Liver Disease que section start -->
<div class="col-lg-12" [formGroup]="diseaseDetailForm">
    <div class="card-wrape" formArrayName="NON_ALCOHOLIC_FATTY_LIVER_DISEASE">
        <div class="card-head">
            <h4 class="left-text">Non-Alcoholic Fatty Liver Disease (NAFLD) Details</h4>
        </div>
        <div class="card-body" [ngClass]="isViewDisease ? 'view-mode' : 'edit-mode'">
            <div class="nafld-detail-que row">
                <div class="col-lg-6">
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.invalid)}">
                        <mat-label><i>*</i>NAFLD Fibrosis Score (NFS) Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_nfs_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_nfs_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of NAFLD Fibrosis Score (NFS) Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_nfs_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.value,'photo_nafld_nfs_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_nfs_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.invalid)}">
                        <mat-label><i>*</i>Fasting Blood Sugar Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_fasting_sugar_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fasting_sugar_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Fasting Blood Sugar Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_fasting_sugar_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.value,'photo_nafld_fasting_sugar_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fasting_sugar_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.invalid)}">
                        <mat-label><i>*</i>Complete Blood Count Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_complete_blood_count_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_complete_blood_count_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Complete Blood Count Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_complete_blood_count_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.value,'photo_nafld_complete_blood_count_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_complete_blood_count_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.invalid)}">
                        <mat-label><i>*</i>AST/SGOT Report Available? (Refer:Liver Function
                            Test):</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_ast_sgot_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.invalid)}">
                        <mat-label><i>*</i>Photo of AST/SGOT Test Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (click)="onSelectFile($event,'photo_nafld_ast_sgot_report_1')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.value,'photo_nafld_ast_sgot_report_1')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_1')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape tree one extend no-label"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_ast_sgot_report')?.value == 'yes'">
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_ast_sgot_report_2')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_ast_sgot_report_2')?.value,'photo_nafld_ast_sgot_report_2')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.invalid)}">
                        <mat-label><i>*</i>ALT/SGPT Report Available? (Refer:Liver Function
                            Test):</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_alt_sgpt_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.invalid)}">
                        <mat-label><i>*</i>Photo of ALT/SGPT Test Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_alt_sgpt_report_1')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.value,'photo_nafld_alt_sgpt_report_1')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_1')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape tree one extend no-label"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_alt_sgpt_report')?.value == 'yes'">
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_alt_sgpt_report_2')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_alt_sgpt_report_2')?.value,'photo_nafld_alt_sgpt_report_2')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.invalid)}">
                        <mat-label><i>*</i>Albumin Report Available? (Refer:Liver Function
                            Test):</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_albumin_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.invalid)}">
                        <mat-label><i>*</i>Photo of Albumin Test Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_albumin_report_1')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.value,'photo_nafld_albumin_report_1')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_1')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape tree one extend no-label"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_albumin_report')?.value == 'yes'">
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_albumin_report_2')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_albumin_report_2')?.value,'photo_nafld_albumin_report_2')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape">
                        <mat-label>Hepatitis A Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_hepatitis_a_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatitis_a_report')?.value == 'yes'">
                        <mat-label>Photo of Hepatitis A Test Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_hepatitis_a_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_a_report')?.value,'photo_nafld_hepatitis_a_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape">
                        <mat-label>Hepatitis B Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_hepatitis_b_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatitis_b_report')?.value == 'yes'">
                        <mat-label>Photo of Hepatitis B Test Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_hepatitis_b_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_b_report')?.value,'photo_nafld_hepatitis_b_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape">
                        <mat-label>Hepatitis C Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_hepatitis_c_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatitis_c_report')?.value == 'yes'">
                        <mat-label>Photo of Hepatitis C Test Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_hepatitis_c_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatitis_c_report')?.value,'photo_nafld_hepatitis_c_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.invalid)}">
                        <mat-label><i>*</i>Hepatic Ultrasound Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_hepatic_ultrasound_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.invalid)}"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.value == 'yes'">
                        <mat-label><i>*</i>Photo of Hepatic Ultrasound Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_hepatic_ultrasound_report_1')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.value,'photo_nafld_hepatic_ultrasound_report_1')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_1')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                    <div class="input-wrape tree  one extend no-label"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hepatic_ultrasound_report')?.value == 'yes'">
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_hepatic_ultrasound_report_2')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_hepatic_ultrasound_report_2')?.value,'photo_nafld_hepatic_ultrasound_report_2')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="input-wrape">
                        <mat-label>Fibroscan Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_nafld_fibroscan_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="not available">Not Available</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fibroscan_report')?.value == 'yes'">
                        <mat-label>Photo of Fibroscan Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_fibroscan_report_1')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_1')?.value,'photo_nafld_fibroscan_report_1')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>


                    <div class="input-wrape tree one extend no-label"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fibroscan_report')?.value == 'yes'">
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2')?.value == '') || (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_nafld_fibroscan_report_2')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2')?.value != '') && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('photo_nafld_fibroscan_report_2')?.value,'photo_nafld_fibroscan_report_2')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape tree text-tree one extend"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_fibroscan_report')?.value == 'yes'">
                        <mat-label>CAP Score?</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput #input placeholder="Enter cap score" type="number" formControlName="nafld_cap_score">
                        </mat-form-field>                         
                            <mat-error *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('nafld_cap_score')?.errors">
                                Cap Score Should Be Between 100 and 400
                            </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_blood_transfusion')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_blood_transfusion')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_blood_transfusion')?.invalid)}">
                        <mat-label><i>*</i>Any History of Blood Transfusion?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_any_history_blood_transfusion">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_blood_transfusion')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_blood_transfusion')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_blood_transfusion')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hiv_infection')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hiv_infection')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hiv_infection')?.invalid)}">
                        <mat-label><i>*</i>HIV Infection?:</mat-label>
                        <div class="control-pad">
                            <ng-select bindLabel="option" bindValue="option" placeholder="Select HIV infection" formControlName="have_nafld_hiv_infection">
                                <ng-option *ngFor="let option of infectionStatus" [value]="option">{{ option | titlecase
                                }}</ng-option>
                            </ng-select>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hiv_infection')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hiv_infection')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_hiv_infection')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_previouly_received_currently_dialysis')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_previouly_received_currently_dialysis')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_previouly_received_currently_dialysis')?.invalid)}">
                        <mat-label><i>*</i>Previously Received Dialysis or Are Currently on
                            Dialysis?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_previouly_received_currently_dialysis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_previouly_received_currently_dialysis')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_previouly_received_currently_dialysis')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_previouly_received_currently_dialysis')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.invalid)}">
                        <mat-label><i>*</i>Any Infection in the past One Week?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_any_infection_in_past_week">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.invalid)}">
                        <mat-label><i>*</i>What Type?</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput #input placeholder="Enter what type" type="text"
                                formControlName="what_type_of_infection_in_past_week">
                        </mat-form-field>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_of_cirrhosis')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_of_cirrhosis')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_of_cirrhosis')?.invalid)}">
                        <mat-label><i>*</i>Any History of Cirrhosis?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_any_history_of_cirrhosis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_of_cirrhosis')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_of_cirrhosis')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_of_cirrhosis')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape" *ngIf="!isNAFDCancer"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.invalid)}">
                        <mat-label><i>*</i>Have You Ever Had Cancer?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_nafld_any_history_or_currently_suffering_from_cancer">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.errors && (diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.pristine || diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Non-Alcoholic Fatty Liver Disease que section end -->
