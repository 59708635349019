import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// sweet alert code start
import Swal from 'sweetalert2'
// sweet alert code end
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from 'src/app/service/site.service';
import { SiteDayWiseService } from 'src/app/service/site-day-wise.service';
import { UserService } from 'src/app/service/user.service';
import { CommonService } from 'src/app/service/common.service';
import { DiagnosticLabsService } from 'src/app/service/diagnosticLabs.service';
import { AppConstants } from 'src/app/app.constant';

@Component({
  selector: 'app-site-day-wise-form',
  templateUrl: './site-day-wise-form.component.html',
  styleUrls: ['./site-day-wise-form.component.scss']
})
export class SiteDayWiseFormComponent implements OnInit {
  public trfAvailable = '';
  public step = 0;
  public siteId: any;
  public siteDayWiseId: any;
  public title: string = '';
  public buttonText: string;
  public siteDetail: any;
  public tmpSiteDetail: any;
  public supervisors: any[] = [];
  public coordinators: any[] = [];
  public phlebotomists: any[] = [];
  public counsellors: any[] = [];
  public diagnosticLabs: any[] = [];
  public siteDayWiseForm!: FormGroup;
  public loading: Boolean = false;
  public min = new Date();
  public max = new Date();
  public changeIdCardDetail1: boolean = false;

  public selectedFile1!: File;
  public selectedFile2!: File;
  public selectedFile3!: File;
  public selectedFile4!: File;
  public selectedFile5!: File;
  public selectedFile6!: File;

  public tempLoggerAttachment!: string;
  public anyOtherCampDeliverableStatus!: string;
  public acknowledgmentOfSamplePickupDelivery!: string;
  public testRequestFormAttachment!: string;
  public softCopiesOfReportsReceivedAndUploaded!: string;
  public softCopiesOfInvoiceReceivedAndUploaded!: string;

  public tempLoggerAttachmentUrl!: string;
  public anyOtherCampDeliverableStatusUrl!: string;
  public acknowledgmentOfSamplePickupDeliveryUrl!: string;
  public testRequestFormAttachmentUrl!: string;
  public softCopiesOfReportsReceivedAndUploadedUrl!: string;
  public softCopiesOfInvoiceReceivedAndUploadedUrl!: string;

  public selectedCampCounsellor: any = [];
  public selectedCampPhlebotomist: any = [];

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    protected router: Router,
    private siteService: SiteService,
    private siteDayWiseService: SiteDayWiseService,
    public userService: UserService,
    private commonService: CommonService,
    private diagnosticLabsService: DiagnosticLabsService,
  ) {
    this.siteId = this.route.snapshot.paramMap.get("site_id");
    this.siteDayWiseId = this.route.snapshot.paramMap.get("id");
    if (this.siteDayWiseId) {
      this.title = "Edit Day Wise Data";
      this.setEditData();
      this.buttonText = 'Save';
    } else {
      this.title = "Add Day Wise Data";
      this.siteDayWiseId = 0;
      this.buttonText = 'Add';
      this.getSiteById(this.siteId);
    }
  }

  ngOnInit(): void {
    this.getUsersByRoles("supervisor", "supervisors");
    this.getUsersByRoles("coordinator", "coordinators");
    this.getUsersByRoles("counsellors", "counsellors");
    this.getUsersByRoles("phlebotomist", "phlebotomists");
    this.getDiagnosticLabs();

    this.siteDayWiseForm = this.fb.group({
      'siteDate': ["", [Validators.required]],
      'campTimingFrom': ["", [Validators.required]],
      'campTimingTo': ["", [Validators.required]],
      'campSupervisor': [null, ""],
      'campCoordinator': [null, [Validators.required]],
      'campCounsellor': [null, [Validators.required]],
      'campPhlebotomist': [null, [Validators.required]],
      'dateOfMaterialsPacked': ["", [Validators.required]],
      'totalParticipantsExpected': ["", ""],
      'materialsPackedTotalParticipants': ["", ""],
      'dataLoggerGivenTo': ["", ""],
      'tempLoggerAttachment': ["", ""],
      'anyOtherCampDeliverableStatus': ["", ""],
      'diagnosticLabId': [null, [Validators.required]],
      'acknowledgmentOfSamplePickupDelivery': ["", ""],
      'isAvailableTestRequestForm': ["", ""],
      'testRequestFormAttachment': ["", ""],
      'htrReceivedDate': ["", ""],
      'reportsReceivedBy': ["", ""],
      'totalReportsReceived': ["", ""],
      'campReportDeliveryStatus': ["", ""],
      'reportsDispatchedToSiteDate': ["", ""],
      'reportsCheckedInvoiceVerifiedBy': ["", ""],
      'reportsDeliverdBy': ["", ""],
      'softCopiesOfReportsReceivedAndUploaded': ["", ""],
      'softCopiesOfInvoiceReceivedAndUploaded': ["", ""],
      'isInvoiceClearedAndPaymentDone': ["", ""],
    });
  }
  /**
   * Submit Form
   */
  onSubmit() {
    if (this.siteDayWiseForm.invalid) {
      this.commonService.validateAllFormFields(this.siteDayWiseForm);
      return;
    }
    const formData: FormData = new FormData();
    const isAvailableTestRequestForm = this.siteDayWiseForm.controls["isAvailableTestRequestForm"].value;
    const campSupervisor = !this.commonService.isNullOrUndefined(this.siteDayWiseForm.controls["campSupervisor"].value) ? this.siteDayWiseForm.controls["campSupervisor"].value : "";

    formData.append("site_id", this.siteId);
    formData.append(`site_date`, this.commonService.transformDate(this.siteDayWiseForm.controls["siteDate"].value) as string);
    formData.append("date_of_materials_packed", this.commonService.transformDate(this.siteDayWiseForm.controls["dateOfMaterialsPacked"].value) as string);
    formData.append("htr_received_date", this.commonService.transformDate(this.siteDayWiseForm.controls["htrReceivedDate"].value) as string);
    formData.append("reports_dispatched_to_site_date", this.commonService.transformDate(this.siteDayWiseForm.controls["reportsDispatchedToSiteDate"].value) as string);
    formData.append("camp_timing_from", this.siteDayWiseForm.controls["campTimingFrom"].value);
    formData.append("camp_timing_to", this.siteDayWiseForm.controls["campTimingTo"].value);
    formData.append("camp_supervisor", campSupervisor);
    formData.append("camp_coordinator", this.siteDayWiseForm.controls["campCoordinator"].value);
    formData.append("camp_counsellor", this.siteDayWiseForm.controls["campCounsellor"].value);
    formData.append("camp_phlebotomist", this.siteDayWiseForm.controls["campPhlebotomist"].value);
    formData.append("total_participants_expected", this.siteDayWiseForm.controls["totalParticipantsExpected"].value);
    formData.append("materials_packed_total_participants", this.siteDayWiseForm.controls["materialsPackedTotalParticipants"].value);
    formData.append("data_logger_given_to", this.siteDayWiseForm.controls["dataLoggerGivenTo"].value);
    formData.append("diagnostic_lab_id", this.siteDayWiseForm.controls["diagnosticLabId"].value);
    formData.append("is_available_test_request_form", this.siteDayWiseForm.controls["isAvailableTestRequestForm"].value);
    formData.append("reports_received_by", this.siteDayWiseForm.controls["reportsReceivedBy"].value);
    formData.append("total_reports_received", this.siteDayWiseForm.controls["totalReportsReceived"].value);
    formData.append("camp_report_delivery_status", this.siteDayWiseForm.controls["campReportDeliveryStatus"].value);
    formData.append("reports_checked_invoice_verified_by", this.siteDayWiseForm.controls["reportsCheckedInvoiceVerifiedBy"].value);
    formData.append("reports_deliverd_by", this.siteDayWiseForm.controls["reportsDeliverdBy"].value);
    formData.append("is_invoice_cleared_and_payment_done", this.siteDayWiseForm.controls["isInvoiceClearedAndPaymentDone"].value);
    if (!this.commonService.isNullOrUndefined(this.selectedFile1)) {
      formData.append("temp_logger_attachment", this.selectedFile1, this.selectedFile1.name);
    }
    if (!this.commonService.isNullOrUndefined(this.selectedFile2)) {
      formData.append("any_other_camp_deliverable_status", this.selectedFile2, this.selectedFile2.name);
    }
    if (!this.commonService.isNullOrUndefined(this.selectedFile3)) {
      formData.append("acknowledgment_of_sample_pickup_delivery", this.selectedFile3, this.selectedFile3.name);
    }
    if (!this.commonService.isNullOrUndefined(this.selectedFile4) && isAvailableTestRequestForm === "Y") {
      formData.append("test_request_form_attachment", this.selectedFile4, this.selectedFile4.name);
    }
    if (!this.commonService.isNullOrUndefined(this.selectedFile5)) {
      formData.append("soft_copies_of_reports_received_and_uploaded", this.selectedFile5, this.selectedFile5.name);
    }
    if (!this.commonService.isNullOrUndefined(this.selectedFile6)) {
      formData.append("soft_copies_of_invoice_received_and_uploaded", this.selectedFile6, this.selectedFile6.name);
    }
    this.loading = true;
    if (this.siteDayWiseId === 0) {
      this.createSiteDayWise(formData);
    } else {
      this.updateSiteDayWise(formData);
    }
  }

  createSiteDayWise(formData: any) {
    this.siteDayWiseService.store(formData).subscribe({
      next: (result: any) => {
        this.loading = false;
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast("Site day wise data added successfully");
          this.router.navigate([`/site/dashboard/${this.siteId}`]);
        } else {
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (err) => {
        this.loading = false;
        this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
      }
    });
  }

  updateSiteDayWise(formData: any) {
    this.siteDayWiseService
      .update(formData, this.siteDayWiseId)
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast("Site updated successfully");
            this.router.navigate([`/site/dashboard/${this.siteId}`]);
          } else {
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err) => {
          this.loading = false;
          this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
        }
      });
  }

  /**
  * it will set edit data in form
  */
  setEditData() {
    this.loading = true;
    this.siteDayWiseService.getRow(this.siteDayWiseId).subscribe({
      next: (result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.router.navigate(["page-not-found"]);
        }
        this.siteDetail = result.data;
        if (
          !this.commonService.isNullOrUndefined(this.siteDetail.is_available_test_request_form) &&
          this.siteDetail.is_available_test_request_form === "Y"
        ) {
          this.changeIdCardDetail1 = true;
        }

        if (this.siteDetail.camp_counsellor) {
          for (const campCounsellor of this.siteDetail.camp_counsellor) {
            this.selectedCampCounsellor.push(parseInt(campCounsellor, 10));
          }
        }

        if (this.siteDetail.camp_phlebotomist) {
          for (const campPhlebotomist of this.siteDetail.camp_phlebotomist) {
            this.selectedCampPhlebotomist.push(parseInt(campPhlebotomist, 10));
          }
        }

        let campSupervisor: any = null;
        if (this.siteDetail.camp_supervisor) {
          campSupervisor = parseInt(this.siteDetail.camp_supervisor.id, 10);
        }

        let campCoordinator: any = null;
        if (this.siteDetail.camp_coordinator) {
          campCoordinator = parseInt(this.siteDetail.camp_coordinator.id, 10);
        }

        let diagnosticLabId = null;

        if (
          this.siteDetail.diagnostic_lab_id !== "" &&
          this.siteDetail.diagnostic_lab_id !== 0 &&
          this.siteDetail.diagnostic_lab_id !== "0"
        ) {
          diagnosticLabId = this.siteDetail.diagnostic_lab_id;
        }

        this.siteDayWiseForm.patchValue({
          siteId: this.siteDetail.site_id,
          siteDate: this.siteDetail.site_date,
          dateOfMaterialsPacked: this.siteDetail.date_of_materials_packed,
          htrReceivedDate: this.siteDetail.htr_received_date,
          reportsDispatchedToSiteDate: this.siteDetail.reports_dispatched_to_site_date,
          campTimingFrom: this.siteDetail.camp_timing_from,
          campTimingTo: this.siteDetail.camp_timing_to,
          campSupervisor: campSupervisor,
          campCoordinator: campCoordinator,
          campCounsellor: this.selectedCampCounsellor,
          campPhlebotomist: this.selectedCampPhlebotomist,
          totalParticipantsExpected:
            this.siteDetail.total_participants_expected,
          materialsPackedTotalParticipants:
            this.siteDetail.materials_packed_total_participants,
          dataLoggerGivenTo: this.siteDetail.data_logger_given_to,
          diagnosticLabId: diagnosticLabId,
          isAvailableTestRequestForm:
            this.siteDetail.is_available_test_request_form,
          reportsReceivedBy: this.siteDetail.reports_received_by,
          totalReportsReceived: this.siteDetail.total_reports_received,
          campReportDeliveryStatus: this.siteDetail.camp_report_delivery_status,
          reportsCheckedInvoiceVerifiedBy:
            this.siteDetail.reports_checked_invoice_verified_by,
          reportsDeliverdBy: this.siteDetail.reports_deliverd_by,
          isInvoiceClearedAndPaymentDone:
            this.siteDetail.is_invoice_cleared_and_payment_done,
        });

        this.tempLoggerAttachment = this.siteDetail.temp_logger_attachment;
        this.anyOtherCampDeliverableStatus =
          this.siteDetail.any_other_camp_deliverable_status;
        this.acknowledgmentOfSamplePickupDelivery =
          this.siteDetail.acknowledgment_of_sample_pickup_delivery;
        this.testRequestFormAttachment =
          this.siteDetail.test_request_form_attachment;
        this.softCopiesOfReportsReceivedAndUploaded =
          this.siteDetail.soft_copies_of_reports_received_and_uploaded;
        this.softCopiesOfInvoiceReceivedAndUploaded =
          this.siteDetail.soft_copies_of_invoice_received_and_uploaded;

        this.tempLoggerAttachmentUrl =
          this.siteDetail.temp_logger_attachment_url;
        this.anyOtherCampDeliverableStatusUrl =
          this.siteDetail.any_other_camp_deliverable_status_url;
        this.acknowledgmentOfSamplePickupDeliveryUrl =
          this.siteDetail.acknowledgment_of_sample_pickup_delivery_url;
        this.testRequestFormAttachmentUrl =
          this.siteDetail.test_request_form_attachment_url;
        this.softCopiesOfReportsReceivedAndUploadedUrl =
          this.siteDetail.soft_copies_of_reports_received_and_uploaded_url;
        this.softCopiesOfInvoiceReceivedAndUploadedUrl =
          this.siteDetail.soft_copies_of_invoice_received_and_uploaded_url;
        this.loading = false;

      },
      error: (err) => {
        this.loading = false;
        console.log('err :: ', err);
      }
    });
  }
  /**
  *
  * @param siteId
  */
  getSiteById(siteId: any) {
    this.siteService.getRow(siteId).subscribe({
      next: (result: any) => {
        this.tmpSiteDetail = result.data;
      },
      error: (err) => {
        this.commonService.showErrorToast(
          "Something went wrong. Please contact to administrator."
        );
      }
    });
  }
  /**
   * get users by roles
   */
  getUsersByRoles(role: any, userListKey: any) {
    const formData: FormData = new FormData();
    formData.append("roles", role);
    this.userService.getUsersByRoles(formData).subscribe({
      next: (result: any) => {
        (this as any)[userListKey] = result.data;
      },
      error: (err) => {
        // console.log('getSiteAdmin err :: ', err);
      }
    });
  }

  /**
  * Get diagnostic labs List
  *
  * @returns object
  */
  getDiagnosticLabs() {
    this.diagnosticLabsService.getActiveList().subscribe({
      next: (result: any) => {
        this.diagnosticLabs = result.data;
      }
    });
  }


  deletePhoto() {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    });
  }

  /**
     *
     * @param field
     */
  displayFieldCss(field1: string, field2 = '') {
    let field = field1;
    if (field2 != '') {
      field = field2
    }
    return {
      "has-error": this.commonService.isFieldValid(this.siteDayWiseForm, field),
      "has-feedback": this.commonService.isFieldValid(this.siteDayWiseForm, field),
    };
  }

  /**
  * It will select file & validate file
  * @param event
  */
  fileSelect(event: any, selectedFileName: string, fileObject: string, isExcelAccepted = false) {

    (this as any)[fileObject] = <File>event.target.files[0];

    if ((this as any)[fileObject] !== undefined) {
      (this as any)[selectedFileName] = (this as any)[fileObject].name;
    } else {
      (this as any)[selectedFileName] = null;
    }
    if (isExcelAccepted === true) {
      if ((this as any)[fileObject] !== undefined && (this as any)[fileObject].type !== "application/pdf" &&
        (this as any)[fileObject] !== undefined &&
        (this as any)[fileObject].type !== "application/vnd.ms-excel" &&
        (this as any)[fileObject] !== undefined &&
        (this as any)[fileObject].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.siteDayWiseForm.controls[selectedFileName].setErrors({ validField: "Please select valid file type" });
      } else if ((this as any)[fileObject] !== undefined && (this as any)[fileObject].size / 1024 / 1024 > 25) {
        this.siteDayWiseForm.controls[selectedFileName].setErrors({ validField: "Files size limit exceeded. Max file size is 25 MB." });
      } else {
        this.siteDayWiseForm.controls[selectedFileName].setErrors(null);
      }
    } else {
      if ((this as any)[fileObject] !== undefined && (this as any)[fileObject].type !== "application/pdf") {
        this.siteDayWiseForm.controls[selectedFileName].setErrors({ validField: "Please select valid file type" });
      } else if ((this as any)[fileObject] !== undefined && (this as any)[fileObject].size / 1024 / 1024 > 25) {
        this.siteDayWiseForm.controls[selectedFileName].setErrors({ validField: "Files size limit exceeded. Max file size is 25 MB." });
      } else {
        this.siteDayWiseForm.controls[selectedFileName].setErrors(null);
      }
    }
  }

  changeIdCardDetail(event: any, selectedFileName: any) {
    this.trfAvailable = event.value;
    if (event.value == "N") {
      this.siteDayWiseForm.controls[selectedFileName].setValue('');
      (this as any)[selectedFileName] = null;
    }
  }


  /**
  * @param event
  */
  onlyNumeric(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // dialog code start 
  openDialog() {
    const dialogRef = this.dialog.open(DialogEmaple);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

}

@Component({
  selector: 'dialogEmaple',
  templateUrl: 'dialog-emaple.html',
})
export class DialogEmaple { }