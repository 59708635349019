<div class="page-title-box">
    <h1>View/Edit Participant</h1>
    <ul class="bradcrumb">
      <li>View/Edit Participant</li>
      <li><a routerLink="/participant" title="Participant" class="only-link">Participant</a></li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>

  <section class="container-fluid no-space">
    <mat-tab-group [selectedIndex]="selected" (selectedTabChange)="onChange($event)" class="verticle-tabs">

      <!-- participant dasbhardo tab start -->
      <mat-tab md-dynamic-height label="participant-dashboard">
        <div class="parti-dasbh">
          <ng-template mat-tab-label> <mat-icon class="dash-icon"></mat-icon><span>Participant's Dashboard</span></ng-template>
          <app-participant-dashboard [currentMenuPermission]="currentMenuPermission" [participantInformation]="participantInformation" [diseaseHistory]="diseaseHistory" (participantInformationChange) = "receivePIChildData($event)" (diseaseHistoryChange)="receiveDHChildData($event)" (medicationHistoryChange)="receiveMedicationChildData($event)" [sampleTestInformation]="sampleTestInformation" (sampleTestInformationChange)="receiveSTIChildData($event)" *ngIf="dashboard"></app-participant-dashboard>
        </div>
      </mat-tab>
      <!-- participant dasbhardo tab end -->

      <!-- participant Information tab start -->
      <mat-tab md-dynamic-height label="participant-information">
        <ng-template mat-tab-label> <mat-icon class="pi-icon"></mat-icon><span>Participant Information  </span></ng-template>
          <app-participant-information *ngIf="participantInformation" [currentMenuPermission]="currentMenuPermission"></app-participant-information>
      </mat-tab>
      <!-- participant Information tab end -->

      <!-- Participant Medical History (PMH) tab start -->
      <mat-tab label="participant-disease-history">
        <ng-template mat-tab-label> <mat-icon class="pmh-icon"></mat-icon><span>Disease History </span></ng-template>
        <app-participant-disease-history *ngIf="diseaseHistory" [currentMenuPermission]="currentMenuPermission" [declared_through_options]="declared_through_options"></app-participant-disease-history>
      </mat-tab>
      <!-- Participant Medical History (PMH) tab end -->

       <!-- Recreational Habits tab start -->
       <mat-tab label="participant-eye-problems">
        <ng-template mat-tab-label> <mat-icon class="pmh-icon"></mat-icon><span>Eye Problems</span></ng-template>
        <app-eye-problems *ngIf="eyeProblems" [currentMenuPermission]="currentMenuPermission"></app-eye-problems>
      </mat-tab>
      <!-- Recreational Habits tab end -->

      <!-- Medication tab start -->
      <mat-tab label="participant-medication-history">
        <ng-template mat-tab-label > <mat-icon class="medication-icon"></mat-icon><span>Medication History </span></ng-template>
        <app-participant-medication *ngIf="medicationHistory" [currentMenuPermission]="currentMenuPermission"></app-participant-medication>
      </mat-tab>
      <!-- Medication tab end -->

      <!-- Surgeries tab start -->
      <mat-tab label="participant-surgeries">
        <ng-template mat-tab-label> <mat-icon class="surgeries-icon"></mat-icon><span>Surgeries </span></ng-template>
        <app-participant-surgeries *ngIf="surgeries" [currentMenuPermission]="currentMenuPermission"></app-participant-surgeries>
      </mat-tab>
      <!-- Surgeries tab end -->

      <!-- Allergies OR Drug Reactions tab start -->
      <mat-tab label="participant-allergies-drug-reactions">
        <ng-template mat-tab-label> <mat-icon class="allergies-icon"></mat-icon><span>Allergies/Drug Reactions </span></ng-template>
        <app-allergies-drug-reactions *ngIf="allergiesDrugReactions" [currentMenuPermission]="currentMenuPermission"></app-allergies-drug-reactions>
      </mat-tab>
      <!-- Allergies OR Drug Reactions tab end -->

      <!-- Recreational Habits tab start -->
      <mat-tab label="participant-recreational-habits">
        <ng-template mat-tab-label> <mat-icon class="habit-icon"></mat-icon><span>Recreational Habits </span></ng-template>
        <app-recreational-habits *ngIf="recreationalHabits" [currentMenuPermission]="currentMenuPermission"></app-recreational-habits>
      </mat-tab>
      <!-- Recreational Habits tab end -->

      <!-- Clinical Biochemical Data tab start -->
      <mat-tab label="participant-clinical-biochemical-data">
        <ng-template mat-tab-label> <mat-icon class="measurement-icon"></mat-icon><span>Physiological Measurement </span></ng-template>
        <app-clinical-biochemical-data *ngIf="clinicalBiochemicalData" [currentMenuPermission]="currentMenuPermission"></app-clinical-biochemical-data>
      </mat-tab>
      <!-- Clinical Biochemical Data tab end -->

      <!-- Medical Record  tab start -->
      <mat-tab label="participant-medical-record">
        <ng-template mat-tab-label> <mat-icon class="med-report-icon"></mat-icon><span>Medical Record</span></ng-template>
        <app-medical-record *ngIf="medicalRecord" [currentMenuPermission]="currentMenuPermission"></app-medical-record>
      </mat-tab>
      <!-- Medical Record  tab end -->

      <!-- Family Medical History tab start -->
      <mat-tab label="participant-family-medical-history">
        <ng-template mat-tab-label> <mat-icon class="fmh-icon"></mat-icon><span>Family Medical History </span></ng-template>
        <app-family-medical-history *ngIf="familyMedicalHistory" [currentMenuPermission]="currentMenuPermission"></app-family-medical-history>
      </mat-tab>
      <!-- Family Medical History tab end -->

      <!-- Sample & Test Information tab start -->
      <mat-tab label="participant-sample-test-information">
        <ng-template mat-tab-label> <mat-icon class="sample-icon"></mat-icon><span>Sample & Test Information </span></ng-template>
        <app-sample-test-information *ngIf="sampleTestInformation" [currentMenuPermission]="currentMenuPermission"></app-sample-test-information>
      </mat-tab>
      <!-- Sample & Test Information tab end -->

      <!-- Participant Document tab start -->
      <mat-tab label="participant-document">
        <ng-template mat-tab-label> <mat-icon class="document-icon"></mat-icon><span>Participant Documents </span></ng-template>
        <app-participant-document *ngIf="participantDocument" [currentMenuPermission]="currentMenuPermission"></app-participant-document>
      </mat-tab>
      <!-- Participant Document tab end -->

      <!-- Curation Notes tab start -->
      <mat-tab label="participant-curation-notes">
        <ng-template mat-tab-label> <mat-icon class="curation-icon"></mat-icon><span>Curation Notes </span></ng-template>
        <app-curation-notes *ngIf="curationNotes" [currentMenuPermission]="currentMenuPermission"></app-curation-notes>
      </mat-tab>
      <!-- Curation Notes tab end -->


    </mat-tab-group>
  </section>
