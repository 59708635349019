<div class="page-title-box">
	<h1>{{title}}</h1>
	<ul class="bradcrumb">
		<li>{{title}}</li>
		<li><a routerLink="/dictionary-management/language" title="Language" class="only-link">Language</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<form [formGroup]="languageForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit()">
				<div class="card-wrape">
					<div class="card-head">
						<h4 class="left-text">{{title}}</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': languageForm.controls['name'].invalid && (languageForm.controls['name'].dirty || languageForm.controls['name'].touched)}">
									<mat-label><i>*</i> Name</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter name" formControlName="name"
											(focusout)="checkUniqueLanguageName($event)" type="text">
									</mat-form-field>
									<mat-error
                    					*ngIf="this.languageForm.controls['name'].invalid && (this.languageForm.controls['name'].dirty || this.languageForm.controls['name'].touched)">
										{{ getNameError() }}
									</mat-error>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': languageForm.controls['family'].invalid && (languageForm.controls['family'].dirty || languageForm.controls['family'].touched)}">
									<mat-label>Family</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter family" type="text"
											formControlName="family">
									</mat-form-field>
									<mat-error
                    					*ngIf="this.languageForm.controls['family'].invalid && (this.languageForm.controls['family'].dirty || this.languageForm.controls['family'].touched)">
										{{ getFamilyError() }}
									</mat-error>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': languageForm.controls['family_branch'].invalid && (languageForm.controls['family_branch'].dirty || languageForm.controls['family_branch'].touched)}">
									<mat-label>Family Branch</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter family branch"
											type="text" formControlName="family_branch">
									</mat-form-field>
									<mat-error
                    					*ngIf="this.languageForm.controls['family_branch'].invalid && (this.languageForm.controls['family_branch'].dirty || this.languageForm.controls['family_branch'].touched)">
										{{ getFamilyBranchError() }}
									</mat-error>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button mat-raised-button class="btn" color="warn" routerLink="/dictionary-management/language"
							title="Cancel">Cancel</button>
						<button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
							title="{{buttonText}}">{{buttonText}}</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</section>