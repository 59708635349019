import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, map, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string = '';
  errorData = {};
  userRes: any;
  public loggedUser = new BehaviorSubject<any>(<any>{});

  private accountSubject: BehaviorSubject<any>;
  public account: Observable<any>;

  constructor(private http: HttpClient, private router: Router) {
    this.accountSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('userInfo') as string)
    );
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): any {
    return this.accountSubject.value;
  }

  /**
   * Login function
   * @param formData
   * @author Dhaval Bera
   */
  sendOTP(formData: object) {
    return this.http.post<any>(`${environment.baseURL}send-otp`, formData);
  }

  /**
   * Login function
   * @param formData
   * @author Dhaval Bera
   */
  loginStep2(formData: object) {
    return this.http.post<any>(`${environment.baseURL}login`, formData).pipe(
      map((user: any) => {
        this.accountSubject.next(user.data);
        return user;
      })
    );
  }

  /**
   * reset password update
   * @param formData
   * @author Dhaval Bera
   */
  resetpassword(formData: object) {
    return this.http.post<any>(
      `${environment.baseURL}mpin/reset`,
      formData
    );
  }

  /**
   * Logout
   * @author Dhaval Bera
   */
  logout() {
    this.http.get(`${environment.baseURL}logout`).subscribe({
      next: (result: any) => {
        localStorage.removeItem('userId');
        localStorage.removeItem('userInfo');
        localStorage.clear();
        this.router.navigate(['login']);
      },
      error: (e) => {
        localStorage.removeItem('userId');
        localStorage.removeItem('userInfo');
        localStorage.clear();
      },
      complete: () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('userInfo');
        localStorage.clear();
      },
    });
  }

  /**
   * It will handle error
   * @param error
   * @author Dhaval Bera
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops!',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(() => this.errorData);
  }

  /**
   * get Authorization token
   * @author Dhaval Bera
   */
  getAuthorizationToken() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
    if (userInfo) {
      return userInfo.api_key;
    }
    return null;
  }



  /**
   * Reset password token check (Valid/Invalid)
   * @param token
   * @author Dhaval Bera
   */
  checkResetPasswordToken(token: string) {
    return this.http.get<any>(
      `${environment.baseURL}mpin/reset?token=` + token
    );
  }

  /**
   * Refresh Token
   * @param token
   * @author Dhaval Bera
   */
  refreshToken(token: string) {
    return this.http.post<any>(`${environment.baseURL}refresh-token`, { token: token });
  }
}
