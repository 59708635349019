<div class="tabbing-content-wrape">
	<mat-spinner *ngIf="loading"></mat-spinner>
	<div class="progress-overlay"></div>
	<form [ngClass]="statusTableForm && currentMenuPermission?.edit == true ? 'edit-mode' : 'view-mode'" id="diseaseForm" [formGroup]="sampleForm">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12">
					<div class="card-wrape">
						<div class="card-head">
							<h4 class="left-text">Sample & Test Information</h4>
						</div>
						<div class="card-body">
							<div class="container-fluid">
								<div class="row justify-item">
									<div class="col-lg-4 m-b-0">
										<div class="input-wrape">
											<mat-label>Any Sample Collected?</mat-label>
											<mat-radio-group aria-label="Select an option"
												formControlName="sample_information_available">
												<mat-radio-button class="m-b-0" value="yes">Yes</mat-radio-button>
												<mat-radio-button class="m-b-0" value="no">No</mat-radio-button>
											</mat-radio-group>
										</div>
									</div>
									<div class="col-lg-4"
										*ngIf="sampleForm.controls['sample_information_available'].value === 'yes'">
										<div class="input-wrape">
											<mat-label>How Many Hours since you had anything to eat?</mat-label>
											<mat-form-field appearance="outline">
												<input matInput #input formControlName="last_meal_since"
													placeholder="Enter how many hours since you had anything to eat"
													type="number" (keypress)="onlyNumeric($event)">
											</mat-form-field>
										</div>
									</div>
									<div class="col-lg-4 align-right">
										<button mat-raised-button type="button"
											*ngIf="currentMenuPermission.create == true && sampleForm.controls['sample_information_available'].value === 'yes'"
											class="btn add-btn" color="secoundary" (click)="openSample()">Add</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- popup code start -->
				<section class="popup-wrape" [formGroup]="sampleDetailForm"
					[ngClass]="togglePopup ? 'danger' : 'success'">
					<div class="overlays"></div>
					<div class="card-wrape">
						<div class="card-head">
							<h4 class="left-text">Add Sample & Test Information</h4>
							<a href="javascript:;" class="popup-close-btn" (click)="closePopup()">×</a>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12 m-b-0">
									<div class="input-wrape" [ngClass]="displayFieldCss('type')">
										<mat-label><i>*</i>Sample For:</mat-label>
										<div class="control-pad">
											<ng-select placeholder="Sample For" formControlName="type">
												<ng-option value="anuva">Anuva</ng-option>
												<ng-option value="diagnostic labs">Diagnostic Lab</ng-option>
											</ng-select>
											<mat-error
												*ngIf="sampleDetailForm.controls['type'].invalid && (sampleDetailForm.controls['type'].dirty || sampleDetailForm.controls['type'].touched)">
												Please Select sample For
											</mat-error>
										</div>
									</div>
								</div>
								<div class="col-lg-12 m-b-0">
									<div class="input-wrape" [ngClass]="displayFieldCss('sample_type')">
										<mat-label><i>*</i>Sample Type:</mat-label>
										<div class="control-pad">
											<ng-select [searchable]="false" placeholder="Select sample type"
												formControlName="sample_type">
												<ng-option *ngFor="let sampleType of sampleTypeList"
													[value]="sampleType">{{sampleType | titlecase }}</ng-option>
											</ng-select>
											<mat-error
												*ngIf="sampleDetailForm.controls['sample_type'].invalid && (sampleDetailForm.controls['sample_type'].dirty || sampleDetailForm.controls['sample_type'].touched)">
												Please Select sample type
											</mat-error>
										</div>
									</div>
								</div>
								<div class="col-lg-12 m-b-0">
									<div class="input-wrape"
										[ngClass]="displayFieldCss('no_of_samples_collected') || displayFieldCss('sample_unit_type')">
										<mat-label><i>*</i>No. of Samples Collected:</mat-label>
										<div class="half-controls">
											<div class="half">
												<div class="control-pad">
													<ng-select [items]="sampleItems"
														placeholder="No. of samples collected"
														formControlName="no_of_samples_collected" [searchable]="false">
													</ng-select>
													<mat-error
														*ngIf="sampleDetailForm.controls['no_of_samples_collected'].invalid && (sampleDetailForm.controls['no_of_samples_collected'].dirty || sampleDetailForm.controls['no_of_samples_collected'].touched)">
														Please select No. of samples collected
													</mat-error>
												</div>
											</div>
											<div class="half">
												<div class="control-pad">
													<ng-select placeholder="Select unit type"
														formControlName="sample_unit_type" [searchable]="false">
														<ng-option *ngFor="let sampleUnitType of sampleUnitTypeList"
															[value]="sampleUnitType">
															{{sampleUnitType }}
														</ng-option>
													</ng-select>
													<mat-error
														*ngIf="sampleDetailForm.controls['sample_unit_type'].invalid && (sampleDetailForm.controls['sample_unit_type'].dirty || sampleDetailForm.controls['sample_unit_type'].touched)">
														Please Select unit type
													</mat-error>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-12 m-b-0">
									<div class="input-wrape calendar-wrape"
										[ngClass]="displayFieldCss('date_of_collection')">
										<mat-label><i>*</i>Date of Collection:</mat-label>
										<mat-form-field class="example-full-width" appearance="outline">
											<input matInput [matDatepicker]="pickerPMHDob" placeholder="Select date"
												(click)="pickerPMHDob.open()" formControlName="date_of_collection"
												[max]="maxDate">
											<mat-datepicker-toggle matSuffix [for]="pickerPMHDob">
												<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
											</mat-datepicker-toggle>
											<mat-datepicker #pickerPMHDob></mat-datepicker>
										</mat-form-field>
										<mat-error
											*ngIf="sampleDetailForm.controls['date_of_collection'].invalid && (sampleDetailForm.controls['date_of_collection'].dirty || sampleDetailForm.controls['date_of_collection'].touched)">
											Please Select Date of Collection
										</mat-error>
									</div>
								</div>
							</div>
						</div>
						<div class="card-footer">
							<button mat-raised-button class="btn" color="secoundary" *ngIf="!sampleUpdate" [disabled]=""
								(click)="addSampleTest()">Add</button>
							<button mat-raised-button class="btn" color="secoundary" *ngIf="sampleUpdate" [disabled]=""
								(click)="updateSample()">Update</button>
						</div>
					</div>
				</section>
				<!-- popup code end -->
				<div>
					<!-- ANUVA sample code List start here -->
					<div class="row">
						<div class="col-lg-12"
							*ngIf="dataSource.filteredData.length && sampleForm.controls['sample_information_available'].value === 'yes'">
							<div class="card-wrape">
								<div class="card-head">
									<h4 class="left-text">Sample Information List</h4>
								</div>
								<div class="card-body">
									<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
										<ng-container matColumnDef="sample_type">
											<mat-header-cell *matHeaderCellDef> Sample Type </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.sample_type | titlecase}}
											</mat-cell>
										</ng-container>

										<ng-container matColumnDef="no_of_samples_collected">
											<mat-header-cell *matHeaderCellDef> No. of Samples Collected
											</mat-header-cell>
											<mat-cell *matCellDef="let element"> {{element.no_of_samples_collected}}
											</mat-cell>
										</ng-container>

										<ng-container matColumnDef="sample_unit_type">
											<mat-header-cell *matHeaderCellDef> No. of Samples Unit Type
											</mat-header-cell>
											<mat-cell *matCellDef="let element">
												{{ element.sample_unit_type }}
											</mat-cell>
										</ng-container>

										<ng-container matColumnDef="date_of_collection">
											<mat-header-cell *matHeaderCellDef> Samples Collected Date
											</mat-header-cell>
											<mat-cell *matCellDef="let element">
												{{element.date_of_collection | date:'d MMM y'}}
											</mat-cell>
										</ng-container>

										<ng-container matColumnDef="type">
											<mat-header-cell *matHeaderCellDef> Sample For </mat-header-cell>
											<mat-cell *matCellDef="let element"> {{ removeUnder(element.type) }}
											</mat-cell>
										</ng-container>

										<ng-container matColumnDef="action">
											<mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
											<mat-cell *matCellDef="let element; let i=index;">
												<div class="icons-wrape">
													<a href="javascript:;" class="theme-icon edit-icon"
														title="Edit Medication" (click)="editSampleTest(i)" *ngIf="currentMenuPermission.edit == true"></a>
													<a (click)="sampleDelete(i)" class="theme-icon delete-icon edit-sti-mode"
														title="Delete Medication" *ngIf="currentMenuPermission.delete == true"></a>
												</div>
											</mat-cell>
										</ng-container>

										<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
										<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
										<div *matNoDataRow>No records found</div>
									</mat-table>
								</div>
							</div>
						</div>
					</div>
					<!-- ANUVA sample code end here -->
				</div>
			</div>

			<!-- test infromation start -->
			<div class="col-lg-12"
        [ngClass]="currentMenuPermission.edit == true ? 'edit-mode' : 'view-mode'"
				*ngIf="sampleForm.controls['sample_information_available'].value== 'yes' && !hideTestInformationSection">
				<mat-expansion-panel expanded>
					<mat-expansion-panel-header>
						<mat-panel-title>
							Test Information
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-expansion-panel-body>
						<div class="input-wrape">
							<mat-label>Full Body Profile:</mat-label>
							<mat-radio-group aria-label="Select an option" formControlName="full_body_profile">
								<mat-radio-button value="yes">Yes</mat-radio-button>
								<mat-radio-button value="no">No</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="input-wrape">
							<mat-label>Infectious Test:</mat-label>
							<mat-radio-group aria-label="Select an option" formControlName="infectious_test">
								<mat-radio-button value="yes">Yes</mat-radio-button>
								<mat-radio-button value="no">No</mat-radio-button>
							</mat-radio-group>
						</div>

						<div class="row">
							<div class="col-lg-6">
								<h6>Major Tests </h6>
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox *ngFor="let major of majorTestList" [checked]="major?.checked"
											value="{{major.id}}" (change)="testSelection($event, 'major_test')">
											{{ major.name| uppercase}}
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>
							</div>
							<div class="col-lg-6">
								<h6>Minor Tests </h6>
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox *ngFor="let minor of minorTestsList" [checked]="minor?.checked"
											value="{{minor.id}}" (change)="testSelection($event, 'minor_test')">
											{{ minor.name | uppercase}}
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>
							</div>
						</div>
					</mat-expansion-panel-body>
				</mat-expansion-panel>
			</div>
		</div>
		<!-- test infromation end -->

		<div class="stic-form-btn-wrape" *ngIf="currentMenuPermission?.create == true || currentMenuPermission?.edit == true">
			<button type="button" (click)="clickEventTableForm()" *ngIf="!enableTableForm" mat-raised-button class="btn"
				color="secoundary">Edit</button>
			<button type="button" (click)="cancelEventTableForm()" *ngIf="enableTableForm" mat-raised-button class="btn"
				color="warn">Cancel</button>
			<button type="button" (click)="submitSampleInfoForm()" *ngIf="enableTableForm"
				[disabled]="!sampleAdd && !sampleUpdate" mat-raised-button class="btn" color="secoundary">Save</button>
		</div>
	</form>
</div>
