<div class="page-title-box">
    <h1>Manage Loinc Codes</h1>
    <ul class="bradcrumb">
      <li>Manage Loinc Codes</li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>
  
  <section class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text">Manage Loinc Code List</h4>
            <!-- <div class="right-control"> -->
              <!-- </div> -->
              <div class="right-control">
              <div class="table-search-wrape side-info">
                <span class="icon"></span>
                <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control" type="text">
                <div class="info-pad popup-over">
                  <button class="info-icon"></button>
                  <div class="info-section popupcontent">
                    New PID, Site Code, First Name, Last Name, Diagnostic Lab
                  </div>
                </div>
              </div>
              <button mat-raised-button class="btn" color="secoundary" title="Add HTR" style="margin:0 5px 0 15px;"
                routerLink="/site/loinc-codes/add" *ngIf="currentMenuPermission?.create == true" >Add Loinc Codes</button>
            </div>
          </div>
          <div class="card-body table-section">
            <div class="table-wrape">
              <mat-table [dataSource]="dataSource" (matSortChange)="SortFilter($event)" matSort>
  
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> ID </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="diagnostic_lab">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Diagnostic Lab </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.diagnostic_lab}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lab_code">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Lab Code </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.lab_code}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="hba1c">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="user-name"> HBA1C </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px" class="user-name"> {{element.hba1c}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="glucose">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Glucose </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.estimated_average_glucose}} </mat-cell>
                </ng-container>
  
                <!-- <ng-container matColumnDef="last_name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.last_name}} </mat-cell>
                </ng-container> -->
  
  
                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                    <div class="icons-wrape">
                      <a routerLink='/site/loinc-codes/edit/{{element.id}}' *ngIf="currentMenuPermission?.edit == true" class="theme-icon edit-icon" title="Edit Loinc Code"></a>
                      <!-- <a routerLink='/site/loinc-codes/view/{{element.id}}' *ngIf="currentMenuPermission?.view == true && currentMenuPermission?.edit == false" class="theme-icon view-icon" title="View Loinc Code"></a> -->

                      <a class="theme-icon delete-icon" *ngIf="currentMenuPermission?.delete == true"
                      title="Delete Loinc Code" (click)="deleteLoincCode(element)"></a>
                    </div>
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <div *matNoDataRow>No records found</div>
              </mat-table>
  
              <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
                showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>