import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { LanguageService } from 'src/app/service/language.service';

@Component({
  selector: 'app-language-form',
  templateUrl: './language-form.component.html',
  styleUrls: ['./language-form.component.scss'],
})
export class LanguageFormComponent implements OnInit {
  public languageForm!: FormGroup;
  public title: any;
  public languageId: any;
  public isAddMode: boolean = true;
  public disableSubmitBtn: boolean = false;
  public buttonText: string = '';
  public cancelLink: string = '/dictionary-management/language';
  public userId: any = '';
  public isApproved: number = 1;
  public loading: boolean = false;
  public languageDetails: any = [];

  constructor(
    private languageService: LanguageService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.languageId = this.route.snapshot.params['id'];
    if (this.languageId != 0 && this.languageId != null) {
      this.setEditData();
      this.title = 'Edit language';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add language';
      this.buttonText = 'Add';
      this.languageId = 0;
    }
  }

  ngOnInit(): void {
    this.isAddMode = !this.languageId;
    this.userId = localStorage.getItem('userId');
    this.languageForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
        ],
      ],
      family: ['', [Validators.minLength(3), Validators.maxLength(255)]],
      family_branch: ['', [Validators.minLength(3), Validators.maxLength(255)]],
      isApproved: [this.isApproved, []],
    });
  }

  setEditData() {
    this.languageService.getRow(this.languageId).subscribe((result: any) => {
      if (result.status !== AppConstants.serverSuccessStatus) {
        this.router.navigate(['page-not-found']);
      }
      this.languageDetails = result['data'];
      this.languageForm.patchValue({
        name: this.languageDetails.name,
        family: this.languageDetails.family,
        family_branch: this.languageDetails.family_branch,
      });
    });
  }

  getNameError() {
    if (this.languageForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.languageForm.controls['name'].errors!['minlength']) {
      return 'Min length is 3 characters.';
    }
    if (this.languageForm.controls['name'].errors!['maxlength']) {
      return 'Max length is 255 characters.';
    }
    if (this.languageForm.controls['name'].errors!['unique']) {
      return this.languageForm.get("name")!.errors!['unique'] ;
    }
    return ''; 
  } 

  getFamilyError() {
    if (this.languageForm.controls['family'].errors!['minlength']) {
      return 'Min length is 3 characters.';
    }
    if (this.languageForm.controls['family'].errors!['maxlength']) {
      return 'Max length is 255 characters.';
    }
    return ''; 
  }

  getFamilyBranchError() {

    if (this.languageForm.controls['family_branch'].errors!['minlength']) {
      return 'Min length is 3 characters.';
    }
    if (this.languageForm.controls['family_branch'].errors!['maxlength']) {
      return 'Max length is 255 characters.';
    }
    return ''; 
  }

  /**
   * submit form
   * @returns null
   */
  onSubmit(): void {
    if (this.languageForm.invalid) {
      this.commonService.validateAllFormFields(this.languageForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('name', this.languageForm.controls['name'].value);
    formData.append('family', this.languageForm.controls['family'].value);
    formData.append(
      'family_branch',
      this.languageForm.controls['family_branch'].value
    );
    if (this.languageId === 0) {
      formData.append('created_by', this.userId);
    } else {
      formData.append('updated_by', this.userId);
    }
    formData.append(
      'is_approved',
      this.languageForm.controls['isApproved'].value
    );

    if (this.isAddMode) {
      this.createLanguage(formData);
    } else {
      this.updateLanguage(formData);
    }
  }

  /**
   * call Create language api
   * @returns null
   */
  private createLanguage(formData: any): void {
    this.languageService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(['/dictionary-management/language']);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error: (err) => {
        this.disableSubmitBtn = false;
        console.log('error from create language', err);
      },
    });
  }

  /**
   * call Update Language api
   * @returns null
   */
  private updateLanguage(formData: any): void {
    this.languageService
      .update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err) => {
          this.disableSubmitBtn = false;
          console.log('error from update country', err);
        },
      });
  }

  /**
   * Check language field is existed or not
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  checkUniqueLanguageName(event: any) {
    let languageName = (event.target as HTMLInputElement).value;

    if (
      languageName !== '' &&
      languageName.length > 3 &&
      languageName.length < 255
    ) {
      this.languageService
        .checkUniqueLanguageName(languageName, this.languageId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.languageForm.controls['name'].setErrors(null);
            } else {
              this.languageForm.controls['name'].setErrors({
                unique: 'Language name already exists',
              });
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.languageForm.controls['name'].setErrors({
                unique: 'Language name already exists',
              });
            } else {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            }
          },
        });
    }
  }
}
