import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { DuplicateParticipantService } from 'src/app/service/duplicate-participant.service';

@Component({
  selector: 'app-duplicate-participant',
  templateUrl: './duplicate-participant.component.html',
  styleUrls: ['./duplicate-participant.component.scss']
})
export class DuplicateParticipantComponent implements OnInit {
  public displayedColumns: string[] = [
    'id',
    'new_pid',
    'new_recollection_id',
    'site_code',
    'collection_date',
    'first_name',
    'last_name',
    'age',
    'gender'
  ];
  public totalRows = 0;
  public pageSize = 100;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public data: any;
  public title = 'Duplicate Participant';
  public mappedDocumentFilters: any = 'level_1';
  public totalRecord: number = 1;
  public dataSource = new MatTableDataSource<any>();
  public matNoDataRowTitle: string = 'Loading Data...';
  public currentMenuPermission: any;

  step = 1;
  setStep(index: number) {
    this.step = index;
  }

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private commonService: CommonService,
    private duplicateParticipantService: DuplicateParticipantService,
    private menuService: MenuService
  ) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
    this.getDuplicateParticipants();
  }

  /**
  * It will search Participant documents
  * @param event
  * @author Jaydeep Patel
  */
  getDuplicateParticipants() {
    this.loading = true;
    this.duplicateParticipantService
      .getDuplicateParticipants(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.mappedDocumentFilters
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.dataSource = new MatTableDataSource(result.data);
          this.data = result.data.length;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.total;
          });
          if (this.dataSource != undefined && this.dataSource.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  /**
  * It will search Participant documents
  * @param event
  * @author Jaydeep Patel
  */
  downloadDuplicateParticipants() {
    this.loading = true;
    this.duplicateParticipantService
      .downloadDuplicateParticipants(
        this.mappedDocumentFilters
      )
      .subscribe({
        next: (result: any) => {
          console.log(result);
          const file = new Blob([result.data.body], { type: 'text/csv' });
          var downloadURL = window.URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.data.file_name;
          link.click();
          
        },
        error: (e) => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  /**
   * Get filtered values
   * @param event
   * @author Jaydeep Patel
   */
  filterDuplicateParticipants(event: any) {
    if (!this.commonService.isNullOrUndefined(event)) {
      this.mappedDocumentFilters = event;
    } else {
      this.mappedDocumentFilters = '';
    }
    this.getDuplicateParticipants();
  }

  searchFilter(filterValue: any) {
    this.filterValue = (filterValue.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getDuplicateParticipants();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getDuplicateParticipants();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getDuplicateParticipants();
  }

}