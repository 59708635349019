import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { AllergicToWhatService } from 'src/app/service/allergic-to-what.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-allergic-to-what-from',
  templateUrl: './allergic-to-what-from.component.html',
  styleUrls: ['./allergic-to-what-from.component.scss']
})
export class AllergicToWhatFromComponent implements OnInit {

  public allergicToWhatForm!: FormGroup;
  public title: any;
  public allergicToWhatId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/dictionary-management/allergic-to-what";
  public userId: any = "";
  public disableSubmitBtn: boolean = false;
  public isApproved: number = 1;
  public selectMapOption: boolean = false;
  public isCurate: boolean = false;
  public showLoading: boolean = false;
  public allergicToWhatMinLength: number = 3;
  public allergicToWhatDetails: any = [];
  public allergicToWhatList: any[] = [];
  public allergyTypes: any;

  constructor(
    private allergicToWhatService: AllergicToWhatService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.allergicToWhatId = this.route.snapshot.params['id'];
    if (this.allergicToWhatId != 0 && this.allergicToWhatId != null) {
      this.setEditData();
      this.title = "Edit Allergic To What";
      this.buttonText = "Save";
    } else {
      this.title = "Add Allergic To What";
      this.buttonText = "Add";
      this.allergicToWhatId = 0;
    }
  }

  ngOnInit(): void {
    let allergyTypes = JSON.parse(localStorage.getItem('constants') as string);
    this.allergyTypes = allergyTypes['ALLERGY_TYPES'];
    this.isAddMode = !this.allergicToWhatId;
    const routerSubscription = this.route.queryParams.subscribe((queryParams: any) => {
      if (this.router.url.indexOf('curation-pending') >= 0) {
        this.isCurate = true;
        this.title = "Curate Allergic To What";
        this.cancelLink = '/dictionary-management/allergic-to-what/curation-pending';
        // Get allergicToWhat Dictionary
        // this.getAllergicToWhatList(this.allergicToWhatForm.controls['allergy_type'].value);
      }
    });

    this.userId = localStorage.getItem("userId");
    this.allergicToWhatForm = this.formBuilder.group({
      'allergy_type': [null, [Validators.required]],
      'allergic_to_what': ['', [Validators.required, Validators.minLength(this.allergicToWhatMinLength), Validators.maxLength(255)]],
      'isApproved': [this.isApproved, []],
      'mapWithOtherAllergicToWhat': [null, []]
    });
  }

  setEditData() {
    this.allergicToWhatService.getRow(this.allergicToWhatId)
      .subscribe({
        next: (result: any) => {
          if (result.status != AppConstants.serverSuccessStatus) {
            this.commonService.showErrorToast('Allergic To What not found.');
            this.router.navigate([this.cancelLink]);
          } else {
            this.allergicToWhatDetails = result['data'];
            console.log(this.allergicToWhatDetails.allergy_type);
            this.allergicToWhatForm.patchValue({
              allergic_to_what: this.allergicToWhatDetails.allergic_to_what,
              allergy_type: this.allergicToWhatDetails.allergy_type.trim().toLowerCase(),
            });
          }
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
  }

  /**
  * submit form
  * @returns null
  */
  onSubmit(): void {
    if (this.allergicToWhatForm.invalid) {
      this.commonService.validateAllFormFields(this.allergicToWhatForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('allergy_type', this.allergicToWhatForm.controls['allergy_type'].value);
    formData.append('allergic_to_what', this.allergicToWhatForm.controls['allergic_to_what'].value);
    formData.append('is_approved', this.allergicToWhatForm.controls['isApproved'].value);
    formData.append('map_with_other_to_what', (this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].value) ? this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].value : 0);

    if (this.isAddMode) {
      this.createAllergicToWhat(formData);
    } else {
      this.updateAllergicToWhat(formData);
    }
  }

  /**
  * call Create Allergic To What api
  * @returns null
  */
  private createAllergicToWhat(formData: any): void {
    this.allergicToWhatService.store(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/dictionary-management/allergic-to-what"]);
            this.commonService.showSuccessToast(
              result.message
            );
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err) => {
          console.log('error from create allergic to what', err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }

  /**
   * call Update Allergic To What api
   * @returns null
   */
  private updateAllergicToWhat(formData: any): void {
    formData.append('updated_by', this.userId);
    this.allergicToWhatService.update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err) => {
          console.log('error from update allergic to what', err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }

  /**
    * Get all allergic To What for validate Country
    * @returns object
    */
  getAllergicToWhatList() {
    this.allergicToWhatService.getActiveList('',this.allergicToWhatForm.controls['allergy_type'].value)
      .subscribe({
        next: (result: any) => {
          this.allergicToWhatList = result.data;
        },
        error: (e) => {
          console.log('error from get Allergic To What list', e.error);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        },
      });
  }

  getIsWhatError() {
    if (this.allergicToWhatForm.controls['allergic_to_what'].errors!['required']) {
      return 'Allergic to what is required.';
    }
    if (this.allergicToWhatForm.controls['allergic_to_what'].errors!['minlength']) {
      return 'Min Length is ' + this.allergicToWhatMinLength + ' characters.';
    }
    if (this.allergicToWhatForm.controls['allergic_to_what'].errors!['maxlength']) {
      return 'Max Length is 255 characters.';
    }
    if (this.allergicToWhatForm.controls['allergic_to_what'].errors!['unique']) {
      return this.allergicToWhatForm.get("allergic_to_what")!.errors!['unique'];
    }
    return '';
  }

  /**
   * Check allergicToWhat field is existed or not
   * @returns null
   */
  checkUniqueAllergicToWhatFields(
    allergicToWhatField: string,
    event: any
  ) {
    const allergicToWhatFieldValue = (event.target as HTMLInputElement).value;
    if (allergicToWhatFieldValue.length > 255) {
      return false;
    }

    switch (allergicToWhatField) {
      case "allergic_to_what":
        if (allergicToWhatFieldValue.length < this.allergicToWhatMinLength) {
          return false;
        }
        break;
    }

    if (allergicToWhatFieldValue !== "") {
      this.allergicToWhatService
        .checkUniqueAllergicToWhatFields(
          allergicToWhatField,
          allergicToWhatFieldValue,
          this.allergicToWhatId
        )
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.setUniqueError(allergicToWhatField, false);
            } else {
              this.setUniqueError(allergicToWhatField, true);
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.setUniqueError(allergicToWhatField, true);
            } else {
              console.log(
                'error from check unique allergicToWhat', error
              );
            }
          }
        });
    }
    return null;
  }

  mapWithAllergicToWhatChange(event: any) {
    if (event && event.id) {
      this.allergicToWhatService.getRow(event.id)
        .subscribe({
          next: (result: any) => {
            if (result.status == AppConstants.serverSuccessStatus && result.data) {
              setTimeout(() => {
                this.allergicToWhatForm.patchValue({});
              }, 200);
            }
          },
          error: (e: any) => {
            console.log(
              e.error
            );
          },
        });
    } else {
      this.allergicToWhatForm.patchValue({});
    }
  }

  getMapWithAllergicToWhatError() {
    if (this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].invalid) {
      if (this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].errors!['required']) {
        return 'Select Allergic To What';
      }
      if (this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].errors!['pattern']) {
        return 'Please select valid allergic to what';
      }
    }
    return '';
  }

  /**
 * Select Approved Option
 *
 * @returns object
 */
  selectOption(value: boolean) {
    this.selectMapOption = value;
    if (value) {
      this.allergicToWhatForm.get("mapWithOtherAllergicToWhat")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
      this.getAllergicToWhatList();
    } else {
      this.allergicToWhatForm.patchValue({
        mapWithOtherAllergicToWhat: null,
      });
      this.allergicToWhatForm.get('mapWithOtherAllergicToWhat')!.setValidators(null);
    }
    this.allergicToWhatForm.controls['mapWithOtherAllergicToWhat'].updateValueAndValidity()
    this.getMapWithAllergicToWhatError();
  }

  /**
   * set unique field error
   * @returns null
   */
  setUniqueError(allergicToWhatField: string, isSetError: boolean): void {
    if (isSetError) {
      switch (allergicToWhatField) {
        case "allergic_to_what":
          this.allergicToWhatForm
            .get("allergic_to_what")!
            .setErrors({ unique: "Allergic is what already exists" });
          break;
      }
    } else {
      switch (allergicToWhatField) {
        case "allergic_to_what":
          this.allergicToWhatForm.get("allergic_to_what")!.setErrors(null);
          break;
      }
    }
  }

}
