import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../app/components/main-container/auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   *
   * @param authService
   * @param router
   * @author Dhaval Bera
   */
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * It will check authorization
   * @param route
   * @param state
   * @author Dhaval Bera
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  /**
   * It will check whether user is logged in or not
   * @param url
   * @author Dhaval Bera
   */
  checkLogin(url: string) {
    const userId = localStorage.getItem('userId');
    if (userId !== null && userId !== '') {
      return true;
    }

    this.authService.redirectUrl = url;
    this.router.navigate(['/login'], { state: { redirect: url } });
    return false;
  }
}
