import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class PopulationService extends CrudService {

  constructor(public override http: HttpClient) {
    super('population', http);
  }

  /**
   * Check population field is unique
   * @returns object
   */
  checkUniquePopulation(populationName: string, id: number) {
    return this.http.get(
      `${this.baseUrl}/check-unique-population/${populationName}/${id}`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  getParticipantPopulation() {
    return this.http.get(`${this.baseUrl}/participant/population`);
  }
  /**
   *
   * @param id
   * @returns
   */
  searchSubpopulation(search: any) {
    return this.http.get(`${this.baseUrl}/search?search=${search}`);
  }

  /**
   * Check disease field is unique
   * @returns object
   */
   getMappedPopulation(id: number) {
    return this.http.get(
      `${this.baseUrl}/mapped-population/${id}`
    );
  }
}
