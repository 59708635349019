import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { AllergicReactionService } from 'src/app/service/allergic-reaction.service';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-allergic-reaction',
  templateUrl: './allergic-reaction.component.html',
  styleUrls: ['./allergic-reaction.component.scss']
})
export class AllergicReactionComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Allergic Reaction/Symptoms',
      dataKey: 'allergic_reaction_symptoms',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['allergic_reaction_symptoms']}`,
    },
    {
      name: 'Participants Count',
      dataKey: 'participant_count',
      isSortable: true,
      cell: (element: Record<string, any>) => ({
        content: element['participant_count'],
        keyName: 'allergic-reaction/allergic_reaction_map_id',
      }),
    },
  ];
  public isApproved: number = 1;
  public isCurate: boolean = false;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/allergic-reaction/edit/';
  public title = 'Allergic Reaction/Symptoms';
  public data: any;
  public currentMenuPermission: any;
  public is_data_curation_access : boolean = false;

  constructor(
    private commonService: CommonService,
    private allergicReactionService: AllergicReactionService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.is_data_curation_access = JSON.parse(localStorage.getItem('userInfo') as string).is_data_curation_access;
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.loading = true;
    this.route.queryParams.subscribe((queryParams: any) => {
      if (this.router.url.indexOf('curation-pending') >= 0) {

        this.isApproved = 0;
        this.isCurate = true;
        this.editLink = 'dictionary-management/allergic-reaction/curation-pending/';
      } else {
        this.isApproved = 1;
        this.isCurate = false;
      }
      this.getAll();
    });
  }

  /**
  * Get all Allergic-to-what
  *
  * @returns object
  */
  getAll() {
    this.loading = true;
    this.allergicReactionService.getRecordByFilter(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      this.isApproved
    )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log(e.error);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  downloadParticipantData(url:string){
    if (this.currentMenuPermission.edit == true) {
      this.loading = true;
      this.allergicReactionService.downloadDictionaryParticipantList(url).subscribe({
        next: (result: any) => {
          console.log(result);
          const file = new Blob([result.data.body], { type: 'text/csv' });
          var downloadURL = window.URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.data.file_name;
          link.click();
        },
        error: () => { },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  editAction(event: any) {
    this.router.navigate([this.editLink + event.id]);
  }

  // sweet alert code start
  deleteAllergicToWhat(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Allergic Reaction?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.allergicReactionService.delete(event.id)
          .subscribe({
            next: (result: any) => {
              if (result.status === AppConstants.serverSuccessStatus) {
                this.commonService.showSuccessToast(
                  result.message
                );
              } else {
                this.commonService.showErrorToast(
                  result.message
                );
              }
              this.getAll();
            },
            error: (e: any) => {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            },
          });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  /**
   * Make unapproved records
   * @param id
   */
  makeUnapproved(event: any) {
    Swal.fire({
      title: 'Unapproved?',
      html: 'Are you sure want to unapproved the Allergic Reaction?',
      imageUrl: '../../../../../assets/images/unapproved.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        let url = (
          `${environment.baseURL}` + 'dictionary/unapproved/allergic-reaction/'+event.id
        );
        this.allergicReactionService
          .makeUnapprovedDictionary(url)
          .subscribe((results: any) => {
            if (results.status === 1 || results.status === 200) {
              this.commonService.showSuccessToast(
                'Allergic Reaction unapproved successfully'
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                "Allergic Reaction can't be unapproved. Please try again later."
              );
            }
          });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }

  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
    } else {
      this.sortField = 'id';
    }
    this.getAll();
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }

}
