import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class DrugBrandService extends CrudService {
  constructor(public override http: HttpClient) {
    super('medicine-brand', http);
  }

  /**
   * Check drug-brand field is unique
   *
   * @author Dhaval Bera
   *
   * @param string fieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueDrugBrand(fieldValue: string, id: number) {
    return this.http.get(`${this.baseUrl}/check-unique/${fieldValue}/${id}`);
  }
}
