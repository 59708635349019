import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

import Swal from 'sweetalert2';
import { UserService } from '../../../service/user.service';
import { UsersElement } from 'src/app/interfaces/Users';
import { CommonService } from '../../../service/common.service';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';
import { AppConstants } from 'src/app/app.constant';

const ELEMENT_DATA: UsersElement[] = [];

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements AfterViewInit {
  public displayedColumns: string[] = [
    'id',
    'user_full_name',
    'email',
    'gender',
    'created_date_formatted',
    'status_name',
    'created_by_name',
    'action',
  ];
  public dataSource = new MatTableDataSource<UsersElement>(ELEMENT_DATA);
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public data = 1;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public currentMenuPermission: any;
  public loading: boolean = false;
  public matNoDataRowTitle: string = 'Loading Data...';

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService
  ) { }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getAllUser();
  }
  ngOnInit(): void {
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllUser();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      if (sortState.active == 'created_date_formatted') {
        this.sortField = 'created_at';
      }
      this.sortOrder = sortState.direction;
      this.getAllUser();
    }
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
  getAllUser() {
    this.matNoDataRowTitle = 'Loading Data...';
    this.userService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.dataSource = new MatTableDataSource(result.data.data);
          this.data = result.data.data.length;
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
          if (this.dataSource != undefined && this.dataSource.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
        },
        error: (e) => {
          this.loading = false;
          console.log('User Listing err :: ', e);
        }
      });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllUser();
  }

  /**
   * It will resend invite user
   * @param id
   * @author Dhaval Bera
   */
  resendLink(id: number) {
    Swal.fire({
      title: 'Are you sure want to resend invitation link ?',
      imageUrl: '../../../../../assets/images/link-icon.png',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
      buttonsStyling: false,
    }).then((res) => {
      if (res.value) {
        this.userService.resendInviteLink(id).subscribe({
          next: (result: any) => {
            if (result.status === 1) {
              this.commonService.showSuccessToast(result.message);
            } else {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            }
          },
          error: (e) => {
            this.commonService.showErrorToast(
              'Something went wrong. Please contact to administrator.'
            );
          },
          complete: () => { },
        });
      }
      if (res.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}
