<div class="page-title-box">
	<h1>{{title}}</h1>
	<ul class="bradcrumb">
		<li>{{title}}</li>
		<li><a routerLink="/dictionary-management/organ-site" title="Disease" class="only-link">Organ/Site</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{title}}</h4>
				</div>
				<form [formGroup]="organSiteForm" #form="ngForm" (ngSubmit)="onSubmit()">
					<div class="card-body">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': organSiteForm.controls['name'].invalid && (organSiteForm.controls['name'].dirty || organSiteForm.controls['name'].touched)}">
									<mat-label><i>*</i> Organ/Site</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter organ/site" type="text"
											formControlName="name" required (focusout)="
											checkUniqueRecord($event) ">
									</mat-form-field>
									<span
										*ngIf="organSiteForm.controls['name'].invalid && (organSiteForm.controls['name'].dirty || organSiteForm.controls['name'].touched)">
										<mat-error *ngIf="organSiteForm.controls['name'].errors?.['required']">
											Organ/Site name is required
										</mat-error>
										<mat-error *ngIf="organSiteForm.controls['name'].errors?.['unique']">
											{{ organSiteForm.controls['name'].errors?.['unique'] }}
										</mat-error>
										<mat-error *ngIf="organSiteForm.controls['name'].errors?.['minlength']">
											Min Length is 3 characters.
										</mat-error>
										<mat-error *ngIf="organSiteForm.controls['name'].errors?.['maxlength']">
											Max Length is 255 characters.
										</mat-error>
									</span>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': organSiteForm.controls['diseaseId'].invalid && (organSiteForm.controls['diseaseId'].dirty || organSiteForm.controls['diseaseId'].touched)}">
									<mat-label><i>*</i> Diseases</mat-label>
									<div class="control-pad">
										<ng-select [items]="diseases" formControlName="diseaseId"
											bindLabel="snomed_name" bindValue="id" placeholder="Select disease"
											[multiple]="true" labelForId="disease">
										</ng-select>
										<span
											*ngIf="organSiteForm.controls['diseaseId'].invalid && (organSiteForm.controls['diseaseId'].dirty || organSiteForm.controls['diseaseId'].touched)">
											<mat-error *ngIf="organSiteForm.controls['diseaseId'].errors?.['required']">
												Please select diseases
											</mat-error>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape">
									<mat-label>Description</mat-label>
									<mat-form-field class="example-full-width" appearance="outline">
										<textarea matInput formControlName="description"
											placeholder="Description"></textarea>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button mat-raised-button class="btn" color="warn"
							routerLink="/dictionary-management/organ-site" title="Cancel">Cancel</button>
						<button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
							title="{{buttonText}}">{{buttonText}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>