<div class="page-title-box">
	<h1>Add User</h1>
	<ul class="bradcrumb">
		<li>Add User</li>
		<li><a routerLink="/manage-user" title="Manage User" class="only-link">Manage User</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<mat-spinner *ngIf="loading"></mat-spinner>
		<div class="col-xl-9 col-md-9">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">Add User</h4>
				</div>
				<form [formGroup]="userForm" #form="ngForm" (ngSubmit)="onSubmit(userForm.value)">
					<div class="card-body">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)}">
									<mat-label><i>*</i> Email ID</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter email ID" type="email"
											formControlName="email" required (focusout)="checkEmailExist($event)">
									</mat-form-field>
									<span
										*ngIf="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)">
										<mat-error *ngIf="userForm.controls['email'].errors?.['required']">Please enter
											email address
										</mat-error>
										<mat-error *ngIf="userForm.controls['email'].errors?.['email']">
											Please enter valid email address
										</mat-error>
										<mat-error *ngIf="userForm.controls['email'].errors?.['pattern']">
											Please enter valid email address
										</mat-error>
										<mat-error *ngIf="userForm.controls['email'].errors?.['unique']">
											{{ userForm.controls['email'].errors?.['unique'] }}
										</mat-error>
									</span>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': userForm.controls['roleName'].invalid && (userForm.controls['roleName'].dirty || userForm.controls['roleName'].touched)}">
									<mat-label><i>*</i> Role Name</mat-label>
									<div class="control-pad">
										<ng-select [multiple]="true" placeholder="Select role name"
											formControlName="roleName" [items]="roleList" bindLabel="role_name"
											bindValue="id" labelForId="roleName">
										</ng-select>
									</div>
									<span
										*ngIf="userForm.controls['roleName'].invalid && (userForm.controls['roleName'].dirty || userForm.controls['roleName'].touched)">
										<mat-error *ngIf="userForm.controls['roleName'].errors?.['required']">
											Please select user role
										</mat-error>
									</span>
								</div>
							</div>

							<div class="col-xl-6 col-md-6">
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox class="example-margin" formControlName="is_hide_personal_info">
											Hide Personal Identifications
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>

							</div>
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape">
									<mat-checkbox-groups class="checkbox-section">
										<mat-checkbox class="example-margin" formControlName="is_data_curation_access">
											Data Curation Access
										</mat-checkbox>
									</mat-checkbox-groups>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button mat-raised-button class="btn" color="warn" routerLink=".."
							title="Cancel">Cancel</button>
						<button type="submit" mat-raised-button class="btn" color="secoundary" title="Add">Add</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>