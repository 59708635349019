import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class OrganSiteService extends CrudService {
  constructor(public override http: HttpClient) {
    super('organ-site', http);
  }

  /**
   * Check organ-site field is unique
   *
   * @param string fieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueName(fieldValue: string, id: number) {
    return this.http.get(
      `${this.baseUrl}/check-unique-organ-site/${fieldValue}/${id}`
    );
  }
}
