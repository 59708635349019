import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-disease-ckd-questions',
  templateUrl: './disease-ckd-questions.component.html',
  styleUrls: ['./disease-ckd-questions.component.scss']
})
export class DiseaseCkdQuestionsComponent implements OnChanges {

  @Input() diseaseForm!: FormGroup;
  @Input() diseaseDetailForm!: FormGroup;
  @Input() years: any = [];
  @Input() months: any = [];
  @Input() days: any = [];
  @Input() diseaseUpdateIndex: any;
  @Input() diseaseDetails: any;
  @Input() isViewDisease: any;

  public ckdForm!: FormGroup;
  public CHRONIC_KIDNEY_DISEASE: any = [];
  public participantID: any;
  public currentMenuPermission: any;

  constructor(
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private menuService: MenuService
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  ngOnChanges(): void {

    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });

    // CHRONIC_KIDNEY_DISEASE Questions

    if (![null, undefined].includes(this.diseaseUpdateIndex)) {
      this.CHRONIC_KIDNEY_DISEASE = this.diseaseDetails['details'][this.diseaseUpdateIndex]['CHRONIC_KIDNEY_DISEASE'];
    }

    let howLongDialysis = null;
    if (![undefined, null, ''].includes(this.CHRONIC_KIDNEY_DISEASE)) {
      howLongDialysis = (![null, undefined, ''].includes(this.CHRONIC_KIDNEY_DISEASE?.how_long_do_you_need_dialysis)) ? this.CHRONIC_KIDNEY_DISEASE?.how_long_do_you_need_dialysis?.split('-') : null;
    }

    this.ckdForm = this.formBuilder.group({
      have_egfr_report: new FormControl([null, undefined, ''].includes(this.CHRONIC_KIDNEY_DISEASE) ? null : this.CHRONIC_KIDNEY_DISEASE?.have_egfr_report, []),
      photo_egfr_report: new FormControl([null, undefined, ''].includes(this.CHRONIC_KIDNEY_DISEASE) ? null : this.CHRONIC_KIDNEY_DISEASE?.photo_egfr_report, []),
      photo_egfr_report_url: new FormControl([null, undefined, ''].includes(this.CHRONIC_KIDNEY_DISEASE) ? null : this.CHRONIC_KIDNEY_DISEASE?.photo_egfr_report_url, []),
      do_you_need_dialysis: new FormControl([null, undefined, ''].includes(this.CHRONIC_KIDNEY_DISEASE) ? null : this.CHRONIC_KIDNEY_DISEASE.do_you_need_dialysis, []),
      how_long_do_you_need_dialysis: new FormControl( !howLongDialysis ? null : this.CHRONIC_KIDNEY_DISEASE?.how_long_do_you_need_dialysis, []),
      how_long_do_you_need_dialysis_year: new FormControl((howLongDialysis != null) ? howLongDialysis[0] : '', []),
      how_long_do_you_need_dialysis_month: new FormControl((howLongDialysis != null) ? howLongDialysis[1] : '', []),
      how_long_do_you_need_dialysis_day: new FormControl((howLongDialysis != null) ? howLongDialysis[2] : '', []),
    });

    this.diseaseDetailForm.setControl('CHRONIC_KIDNEY_DISEASE', this.ckdForm);

    // CKD Dynamic Validation
    const ckdForm = this.diseaseDetailForm.get('CHRONIC_KIDNEY_DISEASE') as FormArray;
    ckdForm.get('have_egfr_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        // this.ckdForm.controls['photo_egfr_report'].setValidators([Validators.required]);
      } else {
        // this.ckdForm.controls['photo_egfr_report'].clearValidators();
      }
      // this.ckdForm.controls['photo_egfr_report'].updateValueAndValidity();
    });

    ckdForm.get('how_long_do_you_need_dialysis_year')?.valueChanges.subscribe(val => {
      if(val){
        this.ckdForm.controls['how_long_do_you_need_dialysis'].setValue(val + '-' + ckdForm.get('how_long_do_you_need_dialysis_month')?.value + '-' + ckdForm.get('how_long_do_you_need_dialysis_day')?.value);
      }
    });
    ckdForm.get('how_long_do_you_need_dialysis_month')?.valueChanges.subscribe(val => {
      if(val){
        this.ckdForm.controls['how_long_do_you_need_dialysis'].setValue(ckdForm.get('how_long_do_you_need_dialysis_year')?.value + '-' + val + '-' + ckdForm.get('how_long_do_you_need_dialysis_day')?.value);
      }
    });
    ckdForm.get('how_long_do_you_need_dialysis_day')?.valueChanges.subscribe(val => {
      if(val){
        this.ckdForm.controls['how_long_do_you_need_dialysis'].setValue(ckdForm.get('how_long_do_you_need_dialysis_year')?.value + '-' + ckdForm.get('how_long_do_you_need_dialysis_month')?.value + '-' + val);
      }
    });
  }

  /**
* This funtion is used to upload report
* @param event
* @param key
*/
  onSelectFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0]
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('disease_type', 'CHRONIC_KIDNEY_DISEASE');
      formData.append('previous_file', [null, undefined, ''].includes(this.CHRONIC_KIDNEY_DISEASE.have_egfr_report) ? '' : this.CHRONIC_KIDNEY_DISEASE.have_egfr_report);
      this.participantService.uploadDiseaseQuestionReport(formData, this.participantID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            console.log(key);
            this.ckdForm.controls[key]?.setValue(result.data.file);
            this.ckdForm.controls[key + '_url']?.setValue(result.data.file_with_path);
            console.log(this.ckdForm.value);
          }
        },
        error: (e: any) => {
          console.log('err :: ', e);
        },
        complete: () => {

        },
      });
    }
  }

  /**
   * Delete Disease Question Report File
   * @param fileName
   * @param index
   */
  deleteDiseaseQuestionReport(fileName: string, key: string) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the file?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {

        this.participantService.deleteDiseaseQuestionReport(fileName).subscribe({
          next: (result: any) => {
            if (result.status === 1) {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Report deleted successfully.', icon: 'success', });
              this.ckdForm.controls[key]?.setValue(null);
              this.diseaseDetails['details'][this.diseaseUpdateIndex]['CHRONIC_KIDNEY_DISEASE'][key + '_url'] = null;
              this.ckdForm.controls['photo_egfr_report'].setValidators([Validators.required]);
              this.ckdForm.controls['photo_egfr_report'].updateValueAndValidity();
            } else {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: result.message, icon: 'warning', });
            }
          },
          error: (e: any) => {
            console.log('err :: ', e);
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: e, icon: 'warning', });
          },
          complete: () => {

          },
        });
      }
    });
  }

  showImage(fileUrl: string) {
    Swal.fire({
      imageUrl: fileUrl,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

}
