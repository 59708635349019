import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService extends CrudService {

  constructor(public override http: HttpClient) {
    super('country', http);
  }

  /**
   * Check Country field is unique
   *
   * @param string countryFieldName
   * @param string countryFieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueCountryFields(countryFieldName: string, countryFieldValue: string, id: number) {
    return this.http.get(`${ this.baseUrl }/check-unique-country-fields/${ countryFieldName }/${ countryFieldValue }/${ id }`);
  }
}
