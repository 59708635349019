import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AllergicToWhatService extends CrudService {

  constructor(public override http: HttpClient) {
    super('allergic-to-what', http);
  }

  /**
   * Check Allergic To What field is unique
   *
   * @param string allergicToWhatFieldName
   * @param string allergicToWhatFieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueAllergicToWhatFields(allergicToWhatFieldName: string, allergicToWhatFieldValue: string, id: number) {
    return this.http.get(`${this.baseUrl}/check-unique-allergic-to-what-fields/${allergicToWhatFieldName}/${allergicToWhatFieldValue}/${id}`);
  }
}
