<div class="page-title-box">
    <h1>Manage Role</h1>
    <ul class="bradcrumb">
        <li>Manage Role</li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
                <mat-spinner *ngIf="loading"></mat-spinner>
                <div class="progress-overlay"></div>
                <div class="card-head">
                    <h4 class="left-text">Manage Role</h4>
                    <div class="right-control">
                        <div class="table-search-wrape side-info">
                            <span class="icon"></span>
                            <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control"
                                type="text">
                            <div class="info-pad popup-over">
                                <button class="info-icon"></button>
                                <div class="info-section popupcontent">
                                    Role ID, Role Name, Created By, Status
                                </div>
                            </div>
                        </div>
                        <button *ngIf="currentMenuPermission.create == true" color="secoundary" class="primary btn" mat-raised-button
                         routerLink="add" title="Add Role">Add Role</button>
                    </div>
                </div>
                <div class="card-body table-section">
                    <div class="table-wrape">
                        <mat-table [dataSource]="dataSource" matSort (matSortChange)="SortFilter($event)">
                            <ng-container matColumnDef="id">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id">Role ID
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="role_name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="user-name">Role Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="user-name"> {{element.role_name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="created_by_name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Created By </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.created_by_name}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="status_type">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="{{element.status_type}}">
                                    {{element.status_type}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="created_date_formatted">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.created_date_formatted}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                                    <div class="icons-wrape">
                                        <a href="javascript:void(0)" *ngIf="currentMenuPermission.edit == true" routerLink="edit/{{element.id}}" class="theme-icon edit-icon"
                                            title="Edit Role"></a>
                                        <a href="javascript:void(0)" *ngIf="currentMenuPermission.delete == true"
                                            (click)="deleteRole(element.id)" class="theme-icon delete-icon"
                                            title="Delete Role"></a>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            <div *matNoDataRow>{{matNoDataRowTitle}}</div>
                        </mat-table>
                        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
                            showFirstLastButtons>
                        </mat-paginator>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>