<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>
<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text"> Biobank and Sequencing Statistics Lists</h4>
                </div>
                <div class="card-body">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div class="progress-overlay"></div>
                    <div class="ui-table-wrapper">
                        <table class="table-wrapes ui-table">
                            <thead class="ui-table-thead">
                                <tr>
                                    <th>Biobank Sample Breakdown</th>
                                    <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                                </tr>
                            </thead>
                            <tbody class="ui-table-tbody">
                                <tr>
                                    <td class="info-panel"><label>PIDs Shipped to Helix</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_shipped_to_helix">
                                            <a (click)="downloadBioBank(year,'pids_shipped_to_helix')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].pids_shipped_to_helix}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_shipped_to_helix == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label> PIDs sequenced (15055) by Orange and not shipped to
                                            Helix</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_seq_by_orange_and_not_shipped_to_helix">
                                            <a (click)="downloadBioBank(year,'pids_seq_by_orange_and_not_shipped_to_helix')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].pids_seq_by_orange_and_not_shipped_to_helix}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_seq_by_orange_and_not_shipped_to_helix == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label>New Collections </label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].new_collections">
                                            <a (click)="downloadBioBank(year,'new_collections')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].new_collections}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].new_collections == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label><b>Total NGS Grade Samples - Sequenced /
                                                Queued</b></label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label><b>{{ bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].total_ngs_grade_samples?bioBankSequencingCounts[year].total_ngs_grade_samples:0
                                                }}</b></label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ui-table-wrapper">
                        <table class="table-wrapes ui-table">
                            <thead class="ui-table-thead">
                                <tr>
                                    <th>WES Ready </th>
                                    <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                                </tr>
                            </thead>
                            <tbody class="ui-table-tbody">
                                <tr>
                                    <td class="info-panel"><label>Remaining Samples passing 260/280, Yield, Conc, Gel -
                                            Mild Smear </label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].rsp_mild_smear">
                                            <a (click)="downloadBioBank(year,'rsp_mild_smear')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].rsp_mild_smear}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].rsp_mild_smear == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label> Remaining Samples passing 260/280, Yield, Conc, Gel -
                                            Pass / Blank</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].rsp_pass">
                                            <a (click)="downloadBioBank(year,'rsp_pass')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].rsp_pass}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].rsp_pass == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label><b>Samples Potentially WES ready</b></label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].samples_potentially_wes_ready">
                                            <b> {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].samples_potentially_wes_ready}}
                                            </b>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].samples_potentially_wes_ready == '0'">0</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ui-table-wrapper">
                        <table class="table-wrapes ui-table">
                            <thead class="ui-table-thead">
                                <tr>
                                    <th>Total Samples - Absent / Failing QC </th>
                                    <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                                </tr>
                            </thead>
                            <tbody class="ui-table-tbody">
                                <tr>
                                    <td class="info-panel"><label> No Sample Present</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].no_sample_present">
                                            <a (click)="downloadBioBank(year,'no_sample_present')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].no_sample_present}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].no_sample_present == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label> Sample lab data Discrepancy</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].sample_lab_data_discrepancy">
                                            <a (click)="downloadBioBank(year,'sample_lab_data_discrepancy')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].sample_lab_data_discrepancy}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].sample_lab_data_discrepancy == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label>Samples Failing 260/280 (1.65 &lt; 260/280&gt; 2.05)
                                        </label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].samples_failing">
                                            <a (click)="downloadBioBank(year,'samples_failing')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].samples_failing}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].samples_failing == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label><b>Total Samples - Absent / Failing QC</b></label>
                                    </td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label><b>{{ bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].total_samples_absent_failing_qc?bioBankSequencingCounts[year].total_samples_absent_failing_qc:0
                                                }}</b></label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ui-table-wrapper">
                        <table class="table-wrapes ui-table">
                            <thead class="ui-table-thead">
                                <tr>
                                    <th>WES Fail Funnel</th>
                                    <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                                </tr>
                            </thead>
                            <tbody class="ui-table-tbody">
                                <tr>
                                    <td class="info-panel"><label> Samples passing 260/280 but Failing Conc (20ng/ul
                                            &lt; Conc) </label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].samples_passing">
                                            <a (click)="downloadBioBank(year,'samples_passing')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].samples_passing}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].samples_passing == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label> Passing 260/280, Conc but Failing Yield (Yield &lt;
                                            6ug)</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].passing_conc_but_failing_yield">
                                            <a (click)="downloadBioBank(year,'passing_conc_but_failing_yield')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].passing_conc_but_failing_yield}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].passing_conc_but_failing_yield == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label> Passing 260/280, Conc, Yield, Failing Gel</label>
                                    </td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].passing_conc_yield_failing_gel">
                                            <a (click)="downloadBioBank(year,'passing_conc_yield_failing_gel')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].passing_conc_yield_failing_gel}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].passing_conc_yield_failing_gel == '0'">0</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label><b>Total WES Fail Funnel</b></label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label><b>{{ bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].total_wes_fail_funnel?bioBankSequencingCounts[year].total_wes_fail_funnel:0
                                                }}</b></label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label><b>Total PIDs in Biobank</b></label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label><b>{{ bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].total_pids_in_biobank?bioBankSequencingCounts[year].total_pids_in_biobank:0
                                                }}</b></label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="info-panel"><label>PIDs with Y-STS Discrepancy</label></td>
                                    <td class="info-panel" *ngFor="let year of yearArray">
                                        <label
                                            *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_with_ysts">
                                            <a (click)="downloadBioBank(year,'pids_with_ysts')">
                                                {{bioBankSequencingCounts[year] &&
                                                bioBankSequencingCounts[year].pids_with_ysts}}
                                            </a>
                                        </label>
                                        <label
                                            *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_with_ysts == '0'">0</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
