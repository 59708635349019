import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends CrudService {

  constructor(public override http: HttpClient) {
    super('role', http);
  }

  /**
   * get Active Role
   * @param id
   * @author Dhaval Bera
   */
  getActiveRole() {
    return this.http.get(this.baseUrl + '/list');
  }

  /**
   * Get all the roles
   * @param pageNo
   * @param sortField
   * @param sortOrder
   * @param globalField
   *
   * @author Dhaval Bera
   */
  getRoles(
    pageNo: number,
    sortField: string,
    sortOrder: string,
    globalFilter: string,
    rows: number
  ) {
    let sortFieldName = '';
    let page = '';
    let globalSearch = '';
    let perPage = '';

    if (!Number.isNaN(pageNo) && pageNo != null) {
      page = '?page=' + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    }

    if (globalFilter != null && globalFilter !== undefined) {
      globalSearch = '&filters[global]=' + globalFilter;
    }

    if (rows !== 0) {
      perPage = '&per_page=' + rows;
    }

    const finalFilter = page + sortFieldName + globalSearch + perPage;

    return this.http.get(this.baseUrl + finalFilter);
  }

  /**
   * Delete Role
   * @param id
   * @author Dhaval Bera
   */
  deleteRole(id: number) {
    return this.http.delete(this.baseUrl + '/' + id);
  }

  /**
 * get Unique Role
 * @param id
 */
  checkRoleUnique(name: string, id: number) {
    const body = new HttpParams()
      .set(`role_name`, name);
    if (id === null) {
      id = 0;
    }
    return this.http.post(this.baseUrl + '/unique/' + id, body);
  }
}
