import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HtrService extends CrudService {

  constructor(public override http: HttpClient) {
    super('htr', http);
  }

  /**
   * @author Jaydeep Patel
   * This function is used to Make QC
   * @param id
   */
  updateQCed(htrID: any) {
    return this.http.post(this.baseUrl + '/updateqc', htrID);
  }

  /**
   * @author Jaydeep Patel
   * This function is used to Make QC
   * @param id
   */
  updateReviewed(htrIDs: any) {
    return this.http.post(this.baseUrl + '/updatereview', htrIDs);
  }
  
  /**
   * @author Jaydeep Patel
   * This function is used to Make QC
   * @param id
   */
  updateReviewedBulk(formData: any) {
    return this.http.post(this.baseUrl + '/updatereviewbulk', formData);
  }

  /**
   * @author Jaydeep Patel
   * This function is used to Make QC
   * @param id
   */
  removeQCed(htrIDs: any) {
    return this.http.post(this.baseUrl + '/removeqc', htrIDs);
  }

  /**
   * @author Jaydeep Patel
   * This function is used to Make QC
   * @param id
   */
  importHtrData(htrData: any) {
    return this.http.post(this.baseUrl + '/import', htrData);
  }

  /**
   * Get all records
   *
   * @author Jaydeep Patel <jaydeep.patel@anuva.bio>
   *
   * @returns object
   */
  // getAll(filterData: any) {
  //   return this.http.post(this.baseUrl + '/list', filterData);
  // }

  getAllHtr(pageNo: number, sortField: string, sortOrder: string, globalFilter: string, rows: number, siteCodes: string, is_htr_qced: string, is_htr_reviewed: string) {

    let sortFieldName = '';
    let page = '';
    let globalSearch = '';
    let perPage = '';
    let siteCodesValue = '';
    let is_htr_qcedValue = '';
    let is_htr_reviewedValue = '';

    if (!Number.isNaN(pageNo) && pageNo != null) {
      page = '&page=' + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      globalSearch = '&filters[global]=' + globalFilter;
    }
    
    if (siteCodes !== null && siteCodes !== undefined) {
      siteCodesValue = '&siteCodes=' + siteCodes;
    }
    
    if (is_htr_qced !== null && is_htr_qced !== undefined) {
      is_htr_qcedValue = '&is_htr_qced=' + is_htr_qced;
    }
    
    if (is_htr_reviewed !== null && is_htr_reviewed !== undefined) {
      is_htr_reviewedValue = '&is_htr_reviewed=' + is_htr_reviewed;
    }

    if (rows !== 0) {
      perPage = '&perPage=' + rows;
    }
    const finalFilter = page + sortFieldName + globalSearch + siteCodesValue + is_htr_qcedValue + is_htr_reviewedValue + perPage;

    return this.http.get(`${this.baseUrl}?` + finalFilter)
      .pipe(catchError(this.handleError));
  }
}
