import { AbstractControl } from '@angular/forms';

export class CompareValidatorsDirective {
  constructor() {}

  /**
   * IT will match password. This is generally used while creating form.
   * @param control
   * @constructor
   * @author Dhaval Bera
   */
  static MatchMpin(control: AbstractControl) {
    const password = control.get('mpin')!.value;
    const confirmPassword = control.get('retypeMpin')!.value;

    if (confirmPassword === '' || password === '') {
      return null;
    }

    if (password !== confirmPassword) {
      control.get('retypeMpin')!.setErrors({ ConfirmPassword: true });
    } else {
      /*control.get('retypePassword').setErrors( {ConfirmPassword: false} );
      control.get('retypePassword').setErrors( {erros: false} );*/
      return null;
    }
    return null;
  }

  /**
   * It will check matching passwords
   * @param control
   * @constructor
   * @author Dhaval Bera
   */
  static MatchPasswordEdit(control: AbstractControl) {
    const password = control.get('password')!.value;
    const confirmPassword = control.get('retypePassword')!.value;

    if (confirmPassword === '' && password === '') {
      return null;
    }

    if (confirmPassword === '' && password !== '') {
      return null;
    }

    if (
      (confirmPassword === '' && password !== '') ||
      password !== confirmPassword
    ) {
      control.get('retypePassword')!.setErrors({ ConfirmPassword: true });
    } else {
      /*control.get('retypePassword').setErrors( {ConfirmPassword: false} );
      control.get('retypePassword').setErrors( {erros: false} );*/
      return null;
    }
    return null;
  }
}
