import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { UniqueIdentityService } from 'src/app/service/unique-identity.service';

@Component({
  selector: 'app-unique-identity-form',
  templateUrl: './unique-identity-form.component.html',
  styleUrls: ['./unique-identity-form.component.scss']
})
export class UniqueIdentityFormComponent implements OnInit {

    public uniqueIdentityForm!: FormGroup;
    public title: any;
    public uniqueIdentityId: any;
    public isAddMode: boolean = true;
    public buttonText: string = "";
    public cancelLink: string = "/dictionary-management/unique-identity";
    public userId: any = "";
    public isApproved: number = 1;
    public showLoading: boolean = false;
    public disableSubmitBtn: boolean = false;
    public uniqueIdentityNameMinLength: number = 3;
    public uniqueIdentityNameMaxLength: number = 255;
    public uniqueIdentityDetails: any = [];
  
    constructor(
      private uniqueIdentityService: UniqueIdentityService,
      public commonService: CommonService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      protected router: Router
    ) {
      this.uniqueIdentityId = this.route.snapshot.params['id'];
      if (this.uniqueIdentityId != 0 && this.uniqueIdentityId != null) {
        this.setEditData();
        this.title = "Edit Unique Identity";
        this.buttonText = "Save";
      } else {
        this.title = "Add Unique Identity";
        this.buttonText = "Add";
        this.uniqueIdentityId = 0;
      }
    }
  
    ngOnInit(): void {
      this.isAddMode = !this.uniqueIdentityId;
  
      this.userId = localStorage.getItem("userId");
      this.uniqueIdentityForm = this.formBuilder.group({
        'name': ['', [Validators.required, Validators.minLength(this.uniqueIdentityNameMinLength), Validators.maxLength(this.uniqueIdentityNameMaxLength)]],
        'isApproved':[this.isApproved,  []],
      });   
    }
    
    setEditData() {
      this.uniqueIdentityService.getRow(this.uniqueIdentityId)
      .subscribe({
        next: (result: any) => {
        if (result.status != AppConstants.serverSuccessStatus) {
          this.commonService.showErrorToast('Unique Identity not found.');
            this.router.navigate([this.cancelLink]);
        }else{
          this.uniqueIdentityDetails = result['data'];
          this.uniqueIdentityForm.patchValue({
            name: this.uniqueIdentityDetails.name,
          });
        }
      },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }
  
    getUniqueIdentityNameError() {
      if (this.uniqueIdentityForm.controls['name'].errors!['required']) {
        return 'Name is required.';
      }
      if (this.uniqueIdentityForm.controls['name'].errors!['minlength']) {
        return 'Min Length is '+ this.uniqueIdentityNameMinLength +' characters.';
      }
      if (this.uniqueIdentityForm.controls['name'].errors!['maxlength']) {
        return 'Max Length is '+ this.uniqueIdentityNameMaxLength +' characters.';
      }
      if (this.uniqueIdentityForm.controls['name'].errors!['unique']) {
        return this.uniqueIdentityForm.get("name")!.errors!['unique'] ;
      }
      return ''; 
    } 
  
    /**
     * submit form
     * @returns null
     */
    onSubmit(): void {  
      if (this.uniqueIdentityForm.invalid) {
        this.commonService.validateAllFormFields(this.uniqueIdentityForm);
        this.commonService.showErrorToast(
          'OOPS! Please enter correct values'
        );
        return;
      }
      this.disableSubmitBtn = true;
      const formData: FormData = new FormData();
      formData.append('name', this.uniqueIdentityForm.controls['name'].value);
      formData.append('is_approved', this.uniqueIdentityForm.controls['isApproved'].value);
      
        if (this.isAddMode) {
            this.createUniqueIdentity(formData);
        } else {
            this.updateUniqueIdentity(formData);
        }
    }
    
    /**
     * call Create Unique Identity api
     * @returns null
     */
    private createUniqueIdentity(formData: any): void {
      this.uniqueIdentityService.store(formData)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/dictionary-management/unique-identity"]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error:(err) => {
          console.log('error from create Unique Identity', err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }
    
    /**
     * call Update UniqueIdentity api
     * @returns null
     */
    private updateUniqueIdentity(formData: any): void {
      formData.append('updated_by', this.userId);
      this.uniqueIdentityService.update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error:(err: any) => {
          console.log('error from update Unique Identity', err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }
   
    /**
       * Check Unique Identity field is existed or not
       * @returns null
       */
     checkUniqueIdentity(event: any) {
      const uniqueIdentityFieldValue = (event.target as HTMLInputElement).value;
      if (uniqueIdentityFieldValue.length > this.uniqueIdentityNameMaxLength) {
        return false;
      }
  
        if (uniqueIdentityFieldValue.length < this.uniqueIdentityNameMinLength) {
          return false;
        }
    
      if (uniqueIdentityFieldValue !== "") {
          this.uniqueIdentityService
          .checkUniqueIdentity(
            uniqueIdentityFieldValue,
            this.uniqueIdentityId
          )
          .subscribe({
            next:(result: any) => {
              if (result.status === 1 || result.status === 200) {
                this.uniqueIdentityForm.get("name")!.setErrors(null);
              } else {
                this.uniqueIdentityForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
              }
            },
            error: (error: any) => {
              if (error.status === 400) {
                this.uniqueIdentityForm
                  .get("name")!
                  .setErrors({ unique: "Name already exists" });
              } else {
                console.log(
                  "Something went wrong. Please contact to administrator."
                );
              }
            }
          });
      }
      return null;
    }
  }
  