import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class DuplicateParticipantService extends CrudService {

  constructor(public override http: HttpClient) {
    super('participant', http);
  }

  /**
   * Get all records
   * @author Jaydeep Patel
   * @returns object
   */
   getDuplicateParticipants(pageNo: any, sortField: string, sortOrder: string, globalFilter: string, rows: any, filterData: any) {
    let params: FormData = new FormData();
    if (!Number.isNaN(pageNo) && pageNo != null) {
      params.append("page", pageNo);
    }

    if (sortField !== undefined) {
      params.append("sortField", sortField);
      params.append("sortOrder", sortOrder);
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      params.append("filters[global]", globalFilter);
    }
    if (filterData !== null && filterData !== undefined) {
      params.append("mapped_filter", filterData);
    }

    if (rows !== 0) {
      params.append("perPage", rows);
    }
    return this.http.post(this.baseUrl + '/duplicate-participant', params);
  }

  downloadDuplicateParticipants(filterData: any){

    let params: FormData = new FormData();
    if (filterData !== null && filterData !== undefined) {
      params.append("mapped_filter", filterData);
    }
    
    return this.http.post(this.cronUrl + '/download-duplicate-participant', params);
  }
}
