import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, share } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  baseUrl: string = `${environment.baseURL}` + 'dictionary';
  unique_identities: Observable<any> | undefined;
  countries: Observable<any> | undefined;
  cities: Observable<any> | undefined;
  states: Observable<any> | undefined;
  religions: Observable<any> | undefined;
  populations: Observable<any> | undefined;
  languages: Observable<any> | undefined;
  drugs: Observable<any> | undefined;
  diseases: Observable<any> | undefined;
  surgeries: Observable<any> | undefined;
  disease_categories: Observable<any> | undefined;
  how_longs: Observable<any> | undefined;
  occupations: Observable<any> | undefined;
  participant_diseases: Observable<any> | undefined;

  constructor(private http: HttpClient) { }

  /**
   * @author Dhaval Bera
   * This function is used to get all country listing with flag
   */
  getUniqueIdentities(): Observable<any> {
    if (this.unique_identities) {
      return this.unique_identities;
    }
    this.unique_identities = this.http
      .get(`${this.baseUrl}/get?dictionary_type=unique_identities`)
      .pipe(share());

    return this.unique_identities;
  }

  getCountryList(): Observable<any> {
    if (this.countries) {
      return this.countries;
    }
    this.countries = this.http
      .get(`${this.baseUrl}/get?dictionary_type=countries`)
      .pipe(share());

    return this.countries;
  }

  getCityList(): Observable<any> {
    if (this.cities) {
      return this.cities;
    }
    this.cities = this.http
      .get(`${this.baseUrl}/get?dictionary_type=cities`)
      .pipe(share());

    return this.cities;
  }

  getStateList(): Observable<any> {
    if (this.states) {
      return this.states;
    }
    this.states = this.http
      .get(`${this.baseUrl}/get?dictionary_type=states`)
      .pipe(share());

    return this.states;
  }

  getReligionList(): Observable<any> {
    if (this.religions) {
      return this.religions;
    }
    this.religions = this.http
      .get(`${this.baseUrl}/get?dictionary_type=religions`)
      .pipe(share());

    return this.religions;
  }

  getPopulationList(): Observable<any> {
    if (this.populations) {
      return this.populations;
    }
    this.populations = this.http
      .get(`${this.baseUrl}/get?dictionary_type=populations`)
      .pipe(share());

    return this.populations;
  }

  getLanguageList(): Observable<any> {
    if (this.languages) {
      return this.languages;
    }
    this.languages = this.http
      .get(`${this.baseUrl}/get?dictionary_type=languages`)
      .pipe(share());

    return this.languages;
  }

  /**
   * @author Dhaval Bera
   * This function is used to get current user location
   */
  getCurrentLocationDetails() {
    return this.http.get(this.baseUrl + '/get-country-name-from-ip');
  }

  /**
   * Search Drugs
   * @param text
   * @returns
   */
  searchDrugs(text: string) {
    this.drugs = this.http
      .get(`${this.baseUrl}/search?dictionary=drugs&search_param=` + text)
      .pipe(share());

    return this.drugs;
  }

  getDiseaseList(): Observable<any> {
    if (this.diseases) {
      return this.diseases;
    }
    this.diseases = this.http
      .get(`${this.baseUrl}/get?dictionary_type=disease_name`)
      .pipe(share());
    return this.diseases;
  }

  getSurgeriesList(): Observable<any> {
    if (this.surgeries) {
      return this.surgeries;
    }
    this.surgeries = this.http
      .get(`${this.baseUrl}/get?dictionary_type=surgeries`)
      .pipe(share());
    return this.surgeries;
  }

  getDiseaseCategorList(): Observable<any> {
    if (this.disease_categories) {
      return this.disease_categories;
    }
    this.disease_categories = this.http
      .get(`${this.baseUrl}/get?dictionary_type=disease_categories`)
      .pipe(share());
    return this.disease_categories;
  }

  getHowLongList(): Observable<any> {
    if (this.how_longs) {
      return this.how_longs;
    }
    this.how_longs = this.http
      .get(`${this.baseUrl}/get?dictionary_type=how_longs`)
      .pipe(share());
    return this.how_longs;
  }

  /**
     * @author Dhaval Bera
     * This function is used to get all ethics-committee list
     */
  getEthicsCommittee(projectId: any) {
    return this.http.get(this.baseUrl + "/get-ethics-committee/" + projectId);
  }

  getOccupationList(): Observable<any> {
    if (this.occupations) {
      return this.occupations;
    }
    this.occupations = this.http
      .get(`${this.baseUrl}/get?dictionary_type=occupation`)
      .pipe(share());
    return this.occupations;
  }

  /**
 * @author Dhaval Bera
 * This function is used to get major_tests List Dictionary
 */
  getMajorTestListDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=major_tests");
  }

  /**
   * @author Dhaval Bera
   * This function is used to get minorTests List Dictionary
   */
  getMinorTestListDictionary() {
    return this.http.get(this.baseUrl + "/get?dictionary_type=minor_tests");
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to get Unit Dictionary
   */
  getUnitDictionary() {
    return this.http.get(`${environment.baseURL}` + "units");
  }
}
