import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LabDataElement } from 'src/app/interfaces/lab-data';
import { LabDataService } from 'src/app/service/lab-data.service';
import { CommonService } from 'src/app/service/common.service';
import { MenuService } from 'src/app/service/menu.service';

const ELEMENT_DATA: LabDataElement[] = [];

@Component({
  selector: 'app-lab-data',
  templateUrl: './lab-data.component.html',
  styleUrls: ['./lab-data.component.scss']
})

export class LabDataComponent implements OnInit {

  public displayedColumns: string[] = [
    'id',
    'new_pid',
    'first_name',
    'last_name',
    'site_code',
    'action'
  ];

  public dataSource = new MatTableDataSource<LabDataElement>(ELEMENT_DATA);
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public currentMenuPermission: any;

  constructor(
    public labDataService: LabDataService,
    private menuService: MenuService,
    public commonService: CommonService,
  ) { }

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllLabData();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      if (sortState.active == 'created_date_formatted') {
        this.sortField = 'created_at';
      }
      this.sortOrder = sortState.direction;
      this.getAllLabData();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllLabData();
  }

  ngOnInit(): void {
    
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });

    this.getAllLabData();
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
   getAllLabData() {

    this.labDataService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result.data.data);
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
        },
        error: (e) => {
          console.log('Participant Listing err :: ', e);
        },
        complete: () => {},
      });
  }
}