import { NgModule, Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  @Input() children: any;


  public sidebarShow: boolean = true;

  showFiller = false;

  constructor(public router: Router,
  ) {
  }


  selectedData = null;
  showHome = true;

  ngOnInit(): void {
  }

}
