import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from 'src/app/service/country.service';
import { AppConstants } from 'src/app/app.constant';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { CommonService } from '../../../../../service/common.service';
@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.scss']
})

export class CountryFormComponent implements OnInit {
  
  public countryForm!: FormGroup;
  public title: any;
  public countryId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/dictionary-management/country";
  public userId: any = "";
  public disableSubmitBtn: boolean = false;
  public isApproved: number = 1;
  public selectMapOption: boolean = false;
  public isCurate: boolean = false;
  public showLoading: boolean = false;
  public countryNameMinLength: number = 3;
  public countryCodeMaxLength: number = 5;
  public countryPhoneCodeMaxLength: number = 5;
  public countryDetails: any = [];
  public countryList: any[] = [];

  constructor(
    private countryService: CountryService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.countryId = this.route.snapshot.params['id'];
    if (this.countryId != 0 && this.countryId != null) {
      this.setEditData();
      this.title = "Edit Country";
      this.buttonText = "Save";
    } else {
      this.title = "Add Country";
      this.buttonText = "Add";
      this.countryId = 0;
    }
  }

  ngOnInit(): void {
    this.isAddMode = !this.countryId;
    const routerSubscription = this.route.queryParams.subscribe((queryParams: any) => {
      if(this.router.url.indexOf('curation-pending') >= 0){
        this.isCurate = true;
        this.title = "Curate Country";
        this.cancelLink = '/dictionary-management/country/curation-pending';
        // Get Country Dictionary
        this.getCountryList();
      }
    });

    this.userId = localStorage.getItem("userId");
    this.countryForm = this.formBuilder.group({
      'name': ['', [Validators.required, Validators.minLength(this.countryNameMinLength), Validators.maxLength(255)]],
      'code': ['', [Validators.maxLength(this.countryCodeMaxLength)]],
      'phoneCode':['',  [Validators.required, Validators.maxLength(this.countryPhoneCodeMaxLength)]],
      'hasState':[0,  []],
      'isApproved':[this.isApproved,  []],
      'mapWithOtherCountry':[null, []]
    });   
  }
  
  /**
     * Get all countrys for validate Country
     * @returns object
     */
   // Get Country Dictionary
   getCountryList(){
    this.countryService.getActiveList()
    .subscribe({
      next: (result: any) => {
        this.countryList = result.data;
      },
      error: (e) => {
        console.log( 'error from get country list', e.error);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }
  
  setEditData() {
    this.countryService.getRow(this.countryId)
    .subscribe({
      next: (result: any) => {
      if (result.status != AppConstants.serverSuccessStatus) {
        this.commonService.showErrorToast('Country not found.');
          this.router.navigate([this.cancelLink]);
      }else{
        this.countryDetails = result['data'];
        this.countryForm.patchValue({
          name: this.countryDetails.name,
          code: (this.countryDetails.code) ? this.countryDetails.code : '',
          phoneCode: this.countryDetails.phone_code,
          hasState: (this.countryDetails.has_state) ? this.countryDetails.has_state : 0,
        });
      }
    },
      error: (e: any) => {
        console.log(
          e.error
        );
      },
    });
  }

  getCountryNameError() {
    if (this.countryForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.countryForm.controls['name'].errors!['minlength']) {
      return 'Min Length is '+ this.countryNameMinLength +' characters.';
    }
    if (this.countryForm.controls['name'].errors!['maxlength']) {
      return 'Max Length is 255 characters.';
    }
    if (this.countryForm.controls['name'].errors!['unique']) {
      return this.countryForm.get("name")!.errors!['unique'] ;
    }
    return ''; 
  } 

  getCountryCodeError() {
    if (this.countryForm.controls['code'].errors!['maxlength']) {
      return 'Max Length is '+ this.countryCodeMaxLength +' characters.';
    }
    if (this.countryForm.controls['code'].errors!['unique']) {
      return this.countryForm.get("code")!.errors!['unique'] ;
    }
    return ''; 
  } 

  getCountryPhoneCodeError() {
    if (this.countryForm.controls['phoneCode'].errors!['required']) {
      return 'Phone code is required.';
    }
    if (this.countryForm.controls['phoneCode'].errors!['maxlength']) {
      return 'Max Length is '+ this.countryPhoneCodeMaxLength +' characters.';
    }
    if (this.countryForm.controls['phoneCode'].errors!['unique']) {
      return this.countryForm.get("phoneCode")!.errors!['unique'] ;
    }
    return ''; 
  } 

  /**
   * submit form
   * @returns null
   */
  onSubmit(): void {  
    if (this.countryForm.invalid) {
      this.commonService.validateAllFormFields(this.countryForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('name', this.countryForm.controls['name'].value);
    formData.append('code', this.countryForm.controls['code'].value);
    formData.append('phone_code', this.countryForm.controls['phoneCode'].value);
    formData.append('has_state', this.countryForm.controls['hasState'].value);
    formData.append('is_approved', this.countryForm.controls['isApproved'].value);
    formData.append('map_with_other_country', (this.countryForm.controls['mapWithOtherCountry'].value) ? this.countryForm.controls['mapWithOtherCountry'].value : 0);
    
      if (this.isAddMode) {
          this.createCountry(formData);
      } else {
          this.updateCountry(formData);
      }
  }
  
  /**
   * call Create Country api
   * @returns null
   */
  private createCountry(formData: any): void {
    this.countryService.store(formData)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(["/dictionary-management/country"]);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error:(err) => {
        console.log('error from create country', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  }
  
  /**
   * call Update Country api
   * @returns null
   */
  private updateCountry(formData: any): void {
    formData.append('updated_by', this.userId);
    this.countryService.update(formData, this.route.snapshot.params['id'])
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate([this.cancelLink]);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          let errorMessage = '';
          if(result.data.name){
            errorMessage = result.data.name;
          }else if(result.data.code){
            errorMessage = result.data.code;
          }else if (result.data.phone_code){
            errorMessage = result.data.phone_code;
          }else {
            errorMessage = result.message;
          }
          this.commonService.showErrorToast(errorMessage);
        }
      },
      error:(err) => {
        console.log('error from update country', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  }

  /**
   * Check country field is existed or not
   * @returns null
   */
   checkUniqueCountryFields(
    countryFieldName: string,
    event: any
  ) {
    const countryFieldValue = (event.target as HTMLInputElement).value;
    if (countryFieldValue.length > 255) {
      return false;
    }

    switch (countryFieldName) {
      case "name":
        if (countryFieldValue.length < this.countryNameMinLength) {
          return false;
        }
        break;

      case "code":
        if (countryFieldValue.length > this.countryCodeMaxLength) {
          return false;
        }
        break;

      case "phoneCode":
        if (countryFieldValue.length > this.countryPhoneCodeMaxLength) {
          return false;
        }
        break;
    }

    if (countryFieldValue !== "") {
        this.countryService
        .checkUniqueCountryFields(
          countryFieldName,
          countryFieldValue,
          this.countryId
        )
        .subscribe({
          next:(result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.setUniqueError(countryFieldName, false);
            } else {
              this.setUniqueError(countryFieldName, true);
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.setUniqueError(countryFieldName, true);
            } else {
              console.log(
                'error from check unique country',error 
              );
            }
          }
        });
    }
    return null;
  }
 
  /**
   * set unique field error 
   * @returns null
   */
  setUniqueError(countryFieldName: string, isSetError: boolean): void {
      if(isSetError){
        switch (countryFieldName) {
          case "name":
            this.countryForm
            .get("name")!
            .setErrors({ unique: "Name already exists" });
            break;
            
          case "code":
            this.countryForm
            .get("code")!
            .setErrors({ unique: "Code already exists" });
            break;
              
          case "phone_code":
            this.countryForm
            .get("phoneCode")!
            .setErrors({ unique: "Phone code already exists" });
            break;
        }
    }else{
      switch (countryFieldName) {
        case "name":
        this.countryForm.get("name")!.setErrors(null);
        break;

        case "code":
        this.countryForm.get("code")!.setErrors(null);
        break;
        
        case "phoneCode":
          this.countryForm.get("phoneCode")!.setErrors(null);
          break;
        }
    }
  }

  /**
   * Select Approved Option
   *
   * @returns object
   */
   selectOption(value: boolean) {
    this.selectMapOption = value;
    if(value){
      this.countryForm.get("mapWithOtherCountry")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
      this.countryForm.controls['hasState'].disable();
    }else{
      this.countryForm.controls['hasState'].enable();
      this.countryForm.patchValue({
        code: (this.countryDetails.code) ? this.countryDetails.code : '',
        phoneCode: this.countryDetails.phone_code,
        hasState: (this.countryDetails.has_state) ? this.countryDetails.has_state : 0,
        mapWithOtherCountry: null,
      }); 
      this.countryForm.get('mapWithOtherCountry')!.setValidators(null);
    }
    this.countryForm.controls['mapWithOtherCountry'].updateValueAndValidity()
    this.getMapWithCountryError();
  }
  
  getMapWithCountryError() {
    if(this.countryForm.controls['mapWithOtherCountry'].invalid){
      if (this.countryForm.controls['mapWithOtherCountry'].errors!['required']) {
        return 'Select country';
      }
      if(this.countryForm.controls['mapWithOtherCountry'].errors!['pattern']) {
        return 'Please select valid country';
      }
    }
    return ''; 
  }
  
  mapWithOtherCountryChange(event: any){
    if(event && event.id){
      this.countryService.getRow(event.id)
      .subscribe({
      next: (result: any) => {
        if (result.status == AppConstants.serverSuccessStatus && result.data) {
          setTimeout(() => {
            this.countryForm.patchValue({
              code: (result.data.code) ? result.data.code : '',
              phoneCode: result.data.phone_code,
              hasState: (result.data.has_state) ? result.data.has_state : 0,
            });
          }, 200); 
        }
      },
      error: (e: any) => {
        console.log(
          e.error
          );
        },
      });
    } else {
      this.countryForm.patchValue({
        code: '',
        phoneCode: '',
        hasState: 0
      });        
    }
  }
}
