import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { environment } from 'src/environments/environment';
const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  public tableDataSource = new MatTableDataSource([ELEMENT_DATA]);
  public displayedColumns!: string[];
  public filterColumns!: string;
  public matNoDataRowTitle: string = 'Loading Data...';
 

  @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort!: MatSort;

  @Input() isPageable = false;
  @Input() isMatrix = false;
  @Input() isMatrixParticipant = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() tableColumns: TableColumn[] = [];
  @Input() rowActionIcon!: string;
  @Input() pageTitle!: string;
  @Input() paginationSizes: number[] = AppConstants.pageSizeOptions;
  @Input() defaultPageSize = this.paginationSizes[0];
  @Input() totalRows!: number;
  @Input() currentPage!: number;
  @Input() totalRecord: number = 1;
  @Input() isCurate!: boolean;
  @Input() loading!: boolean;
  @Input() isApproved!: number;
  @Input() exportOrangeDmCohortLink!: string;
  @Input() currentMenuPermission: any;
  @Input() disableSyncBtn: boolean = false;
  @Input() is_data_curation_access: boolean = false;

  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  @Output() deleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() editAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onlyViewAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() makeUnapproved: EventEmitter<any> = new EventEmitter<any>();
  @Output() syncCohortDMData: EventEmitter<any> = new EventEmitter();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadParticipantData: EventEmitter<any> = new EventEmitter<any>();

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any) {
    if (data != undefined && data.length == 0) {
      this.matNoDataRowTitle = 'No Record Found';
    }
    this.setTableDataSource(data);
  }

  constructor() { }

  ngOnInit(): void {
    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    this.displayedColumns = columnNames;
    this.filterColumns = this.displayedColumns.join(", ");
    if (this.rowActionIcon) {
      this.displayedColumns.push(this.rowActionIcon);
    }
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {    
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
    setTimeout(() => {
      this.matPaginator.pageIndex = this.currentPage;
      this.matPaginator.length = this.totalRows;
    });
  }

  emitSearchFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchFilter.emit(filterValue);
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    this.currentPage = 0;
  }

  sortTable(sortParameters: Sort) {
    this.tableColumns.filter((data) => {
      if (data.name === sortParameters.active) {
        sortParameters.active = data.dataKey;
      }
    });
    this.sort.emit(sortParameters);
  }

  emitExportOrangeDmCohort() {
    return (
      `${environment.baseURL}` + this.exportOrangeDmCohortLink
    );
  }

  emitSyncCohortDMData() {
    console.log('from sync Cohort DM data common table');
    this.syncCohortDMData.emit();
  }

  emitPageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageChanged.emit(event);
  }

  emitDeleteAction(row: any) {
    this.deleteAction.emit(row);
  }
  emitEditAction(row: any) {
    this.editAction.emit(row);
  }
  emitViewAction(row: any) {
    this.viewAction.emit(row);
  }
  emitOnlyViewAction(row: any) {
    this.onlyViewAction.emit(row);
  }
  emitMakeUnapproved(row: any) {
    this.makeUnapproved.emit(row);
  }

  getRelationValue(element: any, tableColumn: any) {
    if (element[tableColumn.dataKey] != '' && element[tableColumn.dataKey] != null && element[tableColumn.dataKey] != undefined) {
      return element[tableColumn.dataKey][tableColumn.relationFieldName];
    }
    return '';
  }

  emitDownloadParticipantData(element: any, tableColumn: any){
    var url = (
      `${environment.baseURL}` + 'dictionary/participants/'+tableColumn+'/'+element
    );
    this.downloadParticipantData.emit(url);
  }
}
