import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { DictionaryService } from 'src/app/service/dictionary.service';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/service/common.service';


@Component({
  selector: 'app-recreational-habits',
  templateUrl: './recreational-habits.component.html',
  styleUrls: ['./recreational-habits.component.scss']
})
export class RecreationalHabitsComponent implements OnInit {

  @Input() currentMenuPermission: any;
  public PID: any;
  public recreationalHabitsDetails: any;
  public recreationalHabitsForm!: FormGroup;
  public dietTypeList: any;
  public whatisyourdiettypeOther: boolean = false;
  public highestFormalEducationItems: any = [];
  public occupationTypeItems: any = [];
  public loading: boolean = true;

  recreationalHabitsFormStatus: boolean = false;
  public isRequiredDiseases: any = [];

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
    private commonService: CommonService,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    let constants = JSON.parse(localStorage.getItem('constants') as string);
    this.dietTypeList = constants['DIET_TYPE'];
    this.highestFormalEducationItems = constants['EDUCATION'];

    this.recreationalHabitsForm = this.formBuilder.group({
      alcohol: [null, []],
      chew_tobacco: [null, []],
      caffeine: [null, []],
      smoking: [null, []],
      exercise: [null, []],
      is_taking_drugs: [null, []],
      diet_type: [null, [Validators.required]],
      highest_education: [null, []],
      additional_diet_types: [null, []],
      describe_additional_diet_type: [null, []],
      occupation_id: [null, [Validators.required]],
      occupation_name: [null, []],
    });

    this.getOccupation();
    this.getRecreationalHabits();
    this.getParticipantDiseasesList();

  }

  addTagFn(name: any) {
    return { name: name, id: name };
  }

  changeWhatisyourdiettype(event: any) {
    if (event.length === 0) {
      this.whatisyourdiettypeOther = false;
      return false;
    }

    if(event.includes('other')){
      this.whatisyourdiettypeOther = true;
    }

    // event.forEach((diettype: any) => {
    //   this.whatisyourdiettypeOther = false;
    //   if (diettype === 'other') {

    //     return;
    //   }
    // });

    if (this.whatisyourdiettypeOther === false) {
      this.recreationalHabitsForm.controls["describe_additional_diet_type"].setValue(null);
    }
    return false;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to get recreational habits
  */
  getRecreationalHabits() {
    this.loading = true;
    this.participantService.getRecreationalHabits(this.PID).subscribe({
      next: (result: any) => {
        this.recreationalHabitsDetails = result.data;

        let occupation_id = null;
        if(this.recreationalHabitsDetails.occupation_map_id != null){
          occupation_id = this.recreationalHabitsDetails.occupation_map_id
        } else if(this.recreationalHabitsDetails.occupation_id > 0){
          occupation_id = this.recreationalHabitsDetails.occupation_id
        }

        this.recreationalHabitsForm.patchValue({
          chew_tobacco: this.recreationalHabitsDetails.chew_tobacco,
          smoking: this.recreationalHabitsDetails.smoking,
          alcohol: this.recreationalHabitsDetails.alcohol,
          exercise: this.recreationalHabitsDetails.exercise,
          highest_education: this.recreationalHabitsDetails.highest_education,
          can_read_write: this.recreationalHabitsDetails.can_read_write,
          occupation_id: occupation_id,
          diet_type: this.recreationalHabitsDetails.diet_type,
          additional_diet_types: this.recreationalHabitsDetails.additional_diet_types,
          describe_additional_diet_type: this.recreationalHabitsDetails.describe_additional_diet_type,
          is_taking_drugs: this.recreationalHabitsDetails.is_taking_drugs,
          caffeine: this.recreationalHabitsDetails.caffeine,
        });

        if (this.recreationalHabitsDetails.additional_diet_types.length > 0) {

          if(this.recreationalHabitsDetails.additional_diet_types.includes('other')){
            this.whatisyourdiettypeOther = true;
          }

          // this.recreationalHabitsDetails.additional_diet_types.forEach((diettype: any) => {
          //   console.log(diettype);
          //   if (diettype === 'other') {
          //     this.whatisyourdiettypeOther = true;
          //     return false;
          //   } else {
          //     this.whatisyourdiettypeOther = false;
          //   }
          //   return false;
          // });
        }

        if (this.recreationalHabitsDetails.is_approved_occupation == false) {
          this.occupationTypeItems.push({ id: this.recreationalHabitsDetails.occupation_id, name: this.recreationalHabitsDetails.occupation_name });
          this.occupationTypeItems = this.occupationTypeItems.slice(0);
        }
        this.loading = false;

      }, error: (e) => {
        this.loading = false;
        console.log('Surgery err :: ', e);
      }
    })
  }

  getOccupation() {
    this.dictionaryService.getOccupationList().subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.occupationTypeItems = result.data;
        }
      },
      error: (e) => { },
      complete: () => {
        this.setOccupation();
      },
    });
  }

  setOccupation() {
    setTimeout(() => {
      if (this.recreationalHabitsDetails?.is_approved_occupation == false) {
        this.occupationTypeItems.push({ id: this.recreationalHabitsDetails.occupation_id, name: this.recreationalHabitsDetails.occupation_name });
        this.occupationTypeItems = this.occupationTypeItems.slice(0);
      }
    }, 3000);

  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form edit
  */
  clickOnEdit() {
    this.recreationalHabitsFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close
  */
  clickOnCancel() {
    this.recreationalHabitsFormStatus = false;
  }

  recreationalHabitsSubmit() {
    if (this.recreationalHabitsForm.invalid) {
      console.log(this.recreationalHabitsForm);
      this.commonService.validateAllFormFields(this.recreationalHabitsForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      return;
    } else {
      this.loading = true;
      this.participantService.updateRecreationalHabit(this.recreationalHabitsForm.value, this.PID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
            this.getRecreationalHabits();
          } else {
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
          }
          this.loading = false;
          this.recreationalHabitsFormStatus = false;
        },
        error: (e) => {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  /**
  * This function is used to get disease list
  */
  getParticipantDiseasesList() {
    this.participantService.getParticipantDiseasesList(this.PID).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          let requiredDiseases = ['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'];
          if (result.data.length > 0) {
            result.data.forEach((disease: any) => {
              if (disease.category != null) {
                let requiredD = requiredDiseases.find(temp => temp == disease.category.slug);
                if (requiredD !== undefined) {
                  this.isRequiredDiseases.push(requiredD);
                }
              }
            });
          }
          if (this.isRequiredDiseases.length > 0) {
            this.commonService.validateFieldBasedOnDisease("is_taking_drugs", this.recreationalHabitsForm, [Validators.required]);
            this.commonService.validateFieldBasedOnDisease("alcohol", this.recreationalHabitsForm, [Validators.required]);

          }


        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }




  /**
* @author Pramod Pramod Kushwaha
* @param field
*/
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }

  /**
  * @author Pramod Kushwaha
  * This funtion is used to submit Form Details
  * @param field
  */
  isFieldValid(field: string) {
    return (
      (this.recreationalHabitsForm.controls[field].invalid && this.recreationalHabitsForm.controls[field].dirty) ||
      (this.recreationalHabitsForm.controls[field].touched && this.recreationalHabitsForm.controls[field].invalid)
    );
  }


}
