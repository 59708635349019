<div class="login-wrape">
	<mat-spinner *ngIf="loading"></mat-spinner>
	<div class="inner" *ngIf="login">
		<form [formGroup]="loginForm" #form="ngForm" class="form-element" (ngSubmit)="onSubmit(loginForm.value)">
			<a [routerLink]="['/dashboard']" class="logo">
				<img src="../../../../../assets/images/logo-large.png" alt="ggcPHENO">
			</a>
			<h2>Login</h2>
			<h4>Continue to PHENO Admin</h4>
			<div *ngIf="errors && errors.length > 0">
				<mat-error *ngFor="let error of errors">{{ error }}</mat-error>
			</div>
			<div class="input-wrape"
				[ngClass]="{ 'has-error': loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)}">
				<mat-label>Email ID</mat-label>
				<mat-form-field appearance="outline" class="email-icon">
					<input matInput #input placeholder="Enter email ID" type="text" formControlName="email" required>
				</mat-form-field>
				<span
					*ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
					<mat-error *ngIf="loginForm.controls['email'].errors?.['required']">Please enter email address
					</mat-error>
					<mat-error *ngIf="loginForm.controls['email'].errors?.['email']">Please enter valid email address
					</mat-error>
				</span>
			</div>
			<button mat-raised-button [disabled]="disabled" type="submit" class="login-button btn" color="secoundary"
				title="Login">Login</button>
		</form>
	</div>

	<div class="inner" *ngIf="otpWrap">
		<form [formGroup]="checkOTPForm" #form="ngForm" class="form-element" (ngSubmit)="checkOTP(checkOTPForm.value)">
			<a [routerLink]="['/dashboard']" class="logo">
				<img src="../../../../../assets/images/logo-large.png" alt="ggcPHENO">
			</a>
			<h2>LOGIN</h2>
			<h4>
				Continue to PHENO Admin
				<span class="user-id">
					<a href="javascript:void(0);" (click)="backToEmail()" title="{{ userEmail }}">{{ userEmail }}</a>
				</span>
			</h4>
			<div *ngIf="errors && errors.length > 0">
				<mat-error *ngFor="let error of errors">{{ error }}</mat-error>
			</div>
			<div class="input-wrape"
				[ngClass]="{ 'has-error': checkOTPForm.controls['otp'].invalid && (checkOTPForm.controls['otp'].dirty || checkOTPForm.controls['otp'].touched)}">
				<mat-label>OTP</mat-label>
				<mat-form-field appearance="outline" class="otp-icon">
					<input matInput #input placeholder="Enter OTP" type="text" (keypress)="keyPressNumbers($event)"
						[maxLength]="6" formControlName="otp" required>
				</mat-form-field>
				<span
					*ngIf="checkOTPForm.controls['otp'].invalid && (checkOTPForm.controls['otp'].dirty || checkOTPForm.controls['otp'].touched)">
					<mat-error *ngIf="checkOTPForm.controls['otp'].errors?.['required']">
						Please enter your OTP
					</mat-error>
					<mat-error *ngIf="checkOTPForm.controls['otp'].errors?.['pattern']">
						Please Enter number only.
					</mat-error>
				</span>
			</div>
			<button mat-raised-button class="login-button btn" color="secoundary" title="Submit"
				type="submit">Submit</button>
			<a href="javascript:void(0)" class="other-link" (click)="resendOTP()" title="Resend OTP">Resend OTP</a>
		</form>
	</div>
	<div class="login-bg"><img src="../../../../../assets/images/login-bg.svg"></div>
</div>