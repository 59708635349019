<div class="page-title-box">
	<h1>{{title}}</h1>
	<ul class="bradcrumb">
		<li>{{title}}</li>
		<li><a routerLink="{{cancelLink}}" title="Drug Generic" class="only-link">Drug Generic</a>
		</li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{title}}</h4>
				</div>
				<form [formGroup]="genericForm" #form="ngForm" class="edit-mode"
					(ngSubmit)="onSubmit(genericForm.value)">
					<div class="card-body">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': genericForm.controls['generic_name'].invalid && (genericForm.controls['generic_name'].dirty || genericForm.controls['generic_name'].touched)}">
									<mat-label><i>*</i> Drug Generic Name</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter drug generic name" type="text"
											formControlName="generic_name" (focusout)="checkUniqueGeneric($event)">
									</mat-form-field>
									<span
										*ngIf="genericForm.controls['generic_name'].invalid && (genericForm.controls['generic_name'].dirty || genericForm.controls['generic_name'].touched)">
										<mat-error *ngIf="genericForm.controls['generic_name'].errors?.['required']">
											Drug generic name is required
										</mat-error>
										<mat-error *ngIf="genericForm.controls['generic_name'].errors?.['unique']">
											{{ genericForm.controls['generic_name'].errors?.['unique'] }}
										</mat-error>
										<!-- <mat-error *ngIf="genericForm.controls['generic_name'].errors?.['minlength']">
											Min Length is {{nameMinLength}} characters.
										</mat-error> -->
										<mat-error *ngIf="genericForm.controls['generic_name'].errors?.['maxlength']">
											Max Length is 255 characters.
										</mat-error>
										<mat-error *ngIf="genericForm.controls['generic_name'].errors?.['validField']">
											{{genericForm.controls['generic_name'].errors?.['validField']}}
										</mat-error>
									</span>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': genericForm.controls['category'].invalid && (genericForm.controls['category'].dirty || genericForm.controls['category'].touched)}">
									<mat-label>Category</mat-label>
									<div class="control-pad">
										<ng-select [items]="categoryList" formControlName="category" [multiple]="true"
											bindLabel="category_name" bindValue="id" labelForId="category"
											placeholder="Select category">
										</ng-select>
										<span
											*ngIf="genericForm.controls['category'].invalid && (genericForm.controls['category'].dirty || genericForm.controls['category'].touched)">
											<mat-error *ngIf="genericForm.controls['category'].errors?.['required']">
												Please Category is required
											</mat-error>
											<mat-error *ngIf="genericForm.controls['category'].errors?.['validField']">
												{{genericForm.controls['category'].errors?.['validField']}}
											</mat-error>
										</span>
									</div>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': genericForm.controls['rxcui_id'].invalid && (genericForm.controls['rxcui_id'].dirty || genericForm.controls['rxcui_id'].touched)}">
									<mat-label>RXCUI ID</mat-label>
									<mat-form-field appearance="outline">
										<input matInput formControlName="rxcui_id" [value]="" (keypress)="this.commonService.keyPressNumbers($event)" #input
											placeholder="Enter RXCUI ID" type="text">
									</mat-form-field>

									<mat-error *ngIf="genericForm.controls['rxcui_id'].errors?.['maxlength']">
										Max Length is 255 characters.
									</mat-error>
									<mat-error *ngIf="genericForm.controls['rxcui_id'].errors?.['validField']">
										{{genericForm.controls['rxcui_id'].errors?.['validField']}}
									</mat-error>
								</div>
								<div class="input-wrape">
									<mat-label>SNOMED ID</mat-label>
									<div class="control-pad">
										<ng-select [items]="snomedIdList" formControlName="snomed_id" [multiple]="true"
											bindLabel="snomed_id" labelForId="snomed_id" placeholder="Select SNOMED ID">
										</ng-select>
										<mat-error *ngIf="genericForm.controls['snomed_id'].errors?.['validField']">
											{{genericForm.controls['snomed_id'].errors?.['validField']}}
										</mat-error>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
							title="Cancel">Cancel</button>
						<button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
							title="{{buttonText}}">{{buttonText}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>