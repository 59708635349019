<div class="card-wrape m-b-0">
  <div class="card-head with-dropdown">
    <h4 class="left-text">Native State Chart</h4>
    <div class="right-control">
      <div class="control-pad">
        <div class="input-wrape">
          <div class="control-pad">
            <ng-select [items]="percentageOrAbsoluteCounts" [clearable]="false" [dropdownPosition]="'bottom'"
              [searchable]="false" placeholder="Please Select" bindLabel="value" bindValue="id"
              [(ngModel)]="stateMapWisePercentOrCount"
              (change)="showRecordsInPercentOrAbsoluteCount('state_map', $event)">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <mat-spinner *ngIf="stateMapChartLoader"></mat-spinner>
    <div class="progress-overlay"></div>
    <!-- <div *ngIf="totalNativeMapStateChartCounts == 0; else foundStateMapChartData"> -->
      <div class="no-data-found" *ngIf="totalNativeMapStateChartCounts == 0 && !stateMapChartLoader">
        <div class="no-data-container">
          <div class="no-data-icon"></div>
          <div class="no-data-text">NO DATA FOUND</div>
        </div>
      <!-- </div> -->
    </div>
    <google-chart #googleChart [data]="geoChart" *ngIf="totalNativeMapStateChartCounts > 0"></google-chart>
    <!-- <ng-template #foundStateMapChartData>
    </ng-template> -->
  </div>
</div>