<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="{{cancelLink}}" title="Ctate" class="only-link">Occupation</a></li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-8 col-md-12 col-sm-12">
            <div class="card-wrape">
                <mat-spinner *ngIf="showLoading"></mat-spinner>
                <div class="progress-overlay"></div>
                <form [formGroup]="occupationForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
                    <div class="card-head">
                        <h4 class="left-text">{{title}}</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape"
                                    [class]="this.commonService.displayFieldCss(this.occupationForm, 'name')">
                                    <mat-label><i>*</i> Name</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput #input placeholder="Enter name" formControlName="name"
                                            (focusout)=" checkUniqueOccupationFields( 'name', $event )
									" type="text">
                                    </mat-form-field>
                                    <mat-error
                                        *ngIf="this.occupationForm.controls['name'].invalid && (this.occupationForm.controls['name'].dirty || this.occupationForm.controls['name'].touched)">
                                        {{getOccupationNameError()}}
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape" *ngIf="isCurate">
                                    <mat-label><i>*</i> Is Approved?:</mat-label>
                                    <mat-radio-group aria-label="Select an option" class="example-radio-group">
                                        <mat-radio-button ngDefaultControl class="example-radio-button"
                                            formControlName="isApproved" id="radio_yes" (click)="selectOption(false)"
                                            [value]="true" checked>
                                            Approved</mat-radio-button>
                                        <mat-radio-button ngDefaultControl class="example-radio-button"
                                            formControlName="isApproved" id="radio_no" (click)="selectOption(true)"
                                            [value]="false">
                                            Map with others</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape" *ngIf="selectMapOption"
                                    [class]="this.commonService.displayFieldCss(this.occupationForm, 'mapWithOtherOccupation')">
                                    <mat-label><i>*</i> Map With:</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="occupationList" formControlName="mapWithOtherOccupation"
                                            bindLabel="name" bindValue="id" labelForId="mapWithOtherOccupation"
                                            (change)="mapWithOtherOccupationChange($event)"
                                            placeholder="Select Occupation">
                                        </ng-select>
                                        <mat-error
                                            *ngIf="this.occupationForm.controls['mapWithOtherOccupation'].invalid && (this.occupationForm.controls['mapWithOtherOccupation'].dirty || this.occupationForm.controls['mapWithOtherOccupation'].touched)">
                                            {{getMapWithOccupationError()}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" type="submit" color="secoundary"
                            [disabled]="disableSubmitBtn" title="{{buttonText}}">{{buttonText}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>