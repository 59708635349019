import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { AppConstants } from '../../../../app.constant';
import { Router, RoutesRecognized } from '@angular/router';
import { CommonService } from '../../../../service/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isVisible: any;
  isSelected: boolean = true;

  login: boolean = true;
  otpWrap: boolean = false;
  forgot: boolean = false;

  public loginForm!: FormGroup;
  public checkOTPForm!: FormGroup;
  public forgotPasswordForm!: FormGroup;
  public disabled: any;
  public errors: string[] = [];
  public loading: boolean = false;
  public userEmail!: string;
  public expiredOTP = false;
  public otpSend: boolean = false;
  public disabledOTP: any;
  public messages: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    protected router: Router,
    private commonService: CommonService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.checkOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

  }

  get username() {
    return this.loginForm.get('email');
  }

  get otp() {
    return this.checkOTPForm.get('otp');
  }

  ngOnInit(): void {
    console.log('ssss');
    
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
  }
  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

  // Only Integer Numbers
  keyPressNumbers(event:any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Send OTP
   * @author Dhaval Bera
   */
  onSubmit(data: any) {
    if (this.loginForm.invalid) {
      this.commonService.validateAllFormFields(this.loginForm);
      return;
    }
    this.disabled = true;
    this.loading = true;
    this.errors = [];

    const formData: FormData = new FormData();
    formData.append('email', data.email);

    this.authService.sendOTP(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.loading = false;
          this.login = false;
          this.otpWrap = true;
          this.forgot = false;
          this.userEmail = this.username!.value;
          setTimeout(() => {
            this.expiredOTP = true;
          }, 180000);
        } else {
          this.errors.push(result.message);
        }
      },
      error: (e) => {
        this.loading = false;
        this.disabled = false;
        this.errors.push(
          'Oops! Something went wrong. Please contact to administrator.'
        );
      },
      complete: () => {
        this.loading = false;
        this.disabled = false;
      },
    });
  }

  /**
   * It will check OTP
   * @author Dhaval Bera
   */
  checkOTP(data: any) {
    this.login = false;
    this.otpWrap = true;
    this.forgot = false;
    if (this.checkOTPForm.invalid) {
      this.commonService.validateAllFormFields(this.checkOTPForm);
      return;
    }
    this.errors = [];
    this.otpSend = false;

    this.loading = true;
    this.disabledOTP = true;

    const formData: FormData = new FormData();
    formData.append('email', this.username!.value);
    formData.append('otp', data.otp);

    this.authService.loginStep2(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          localStorage.setItem('userId', result.data.user_id);
          localStorage.setItem('userInfo', JSON.stringify(result.data));
          this.authService.loggedUser.next(result.data);
          this.commonService.showSuccessToast('Successfully Logged In');
          this.router.navigate(['/dashboard']);
        } else if (result.status === 2 || result.status === 0) {
          this.errors = [];
          this.loading = false;
          this.errors.push(result.message);
        } else {
          this.loading = false;
          this.errors = [];
          this.errors.push(result.validation_data);
        }
      },
      error: (e) => {
        this.loading = false;
        this.errors.push(
          'Oops! Something went wrong. Please contact to administrator.'
        );
      },
      complete: () => {
        this.disabledOTP = false;
      },
    });
  }

  /**
   * Back to email form after reseting value
   * @author Dhaval Bera
   */
  backToEmail() {
    this.login = true;
    this.otpWrap = false;
    this.forgot = false;
    this.errors = [];
    this.loginForm.controls['email'].setValue(null);
    this.checkOTPForm.controls['otp'].setValue(null);
  }

  /**
   * resend otp
   * @author Dhaval Bera
   */
  resendOTP() {
    this.loading = true;
    this.errors = [];
    this.otpSend = false;
    this.expiredOTP = false;
    this.checkOTPForm.controls['otp'].setValue(null);
    const formData: FormData = new FormData();
    formData.append('email', this.username!.value);
    this.authService.sendOTP(formData).subscribe({
      next: (result: any) => {
        this.loading = false;
        if (result.status === 1) {
          this.errors = [];
          this.userEmail = this.username!.value;
          setTimeout(() => {
            this.expiredOTP = true;
          }, 180000);
          this.commonService.showSuccessToast('Otp Send Successfully');
        } else {
          this.errors.push('Invalid Username/Password');
          this.backToLogin();
        }
      },
      error: (e) => {
        this.loading = false;
        this.errors.push(
          'Oops! Something went wrong. Please contact to administrator.'
        );
      },
      complete: () => {
        this.loading = false;
        setTimeout(function (this: any) {
          this.otpSend = false;
        }, 1000);
      },
    });
  }

  /**
   *
   */
  backToLogin() {
    this.errors = [];
    this.login = true;
    this.otpWrap = false;
    this.loginForm.controls['email'].setValue(null);
    this.checkOTPForm.controls['otp'].setValue(null);
  }

}
