<div class="page-title-box">
	<h1>{{title}}</h1>
    <ul class="bradcrumb">
      <li>{{title}}</li>
      <li><a routerLink="{{cancelLink}}" title="Unique Identity" class="only-link">Unique Identity</a></li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>
  
  <section class="container-fluid">
        <div class="row justify-content-md-center">
          <div class="col-xl-8 col-md-12 col-sm-12">
              <form [formGroup]="uniqueIdentityForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
              <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text">{{title}}</h4>
                    </div>
                    <div class="card-body">
                      <div class="row">
                          <div class="col-xl-6 col-md-6">
                              <div class="input-wrape" 
                              [class]="this.commonService.displayFieldCss(this.uniqueIdentityForm, 'name')">
                                  <mat-label><i>*</i> Name</mat-label>
                                    <mat-form-field appearance="outline">
                                      <input matInput #input 
                                          placeholder="Enter name" 
                                          formControlName="name" 
                                          (focusout)="
                                              checkUniqueIdentity(
                                              $event
                                              )
                                          "
                                          type="text">
                                  </mat-form-field>
                                  <mat-error *ngIf="this.uniqueIdentityForm.controls['name'].invalid && (this.uniqueIdentityForm.controls['name'].dirty || this.uniqueIdentityForm.controls['name'].touched)">
                                      {{getUniqueIdentityNameError()}}
                                  </mat-error>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}" title="Cancel">Cancel</button>
                      <button mat-raised-button class="btn" [disabled]="disableSubmitBtn" type="submit" color="secoundary" title="{{buttonText}}">{{buttonText}}</button> 
                    </div>
                </div>
              </form>
          </div>
      </div>
  </section>