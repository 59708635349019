import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl: string = `${environment.baseURL}` + 'user';
  dictionaryBaseUrl: string = `${environment.baseURL}` + 'dictionary';

  constructor(private http: HttpClient) { }

  /**
   * It will store user
   * @param formData
   * @author Dhaval Bera
   */
  registerUser(formData: any) {
    return this.http.post(this.baseUrl + '/invite', formData);
  }

  /**
   *
   * This function is used to check unique validation
   * @author Dhaval Bera
   * @param email
   */
  checkEmailUnique(email: string) {
    return this.http.get(this.baseUrl + '/check-unique-email/' + email);
  }

  /**
   *  get EmailId from token
   * @author Dhaval Bera
   * @param token
   */
  getEmail(token: string) {
    return this.http.get(this.baseUrl + '/validate/' + token);
  }

  /**
   * It will activate user
   * @param formData
   * @author Dhaval Bera
   */
  activateUser(formData: any) {
    return this.http.post(`${environment.baseURL}` + 'register', formData);
  }

  /**
   * This function is used to get record by filter
   * @param pageNo
   * @param sortField
   * @param sortOrder
   * @param globalField
   * @author Dhaval Bera
   */
  getRecordByFilter(
    pageNo: number,
    sortField: string,
    sortOrder: string,
    globalFilter: string,
    rows: number
  ) {
    let sortFieldName = '';
    let page = '';
    let globalSearch = '';
    let perPage = '';

    if (!Number.isNaN(pageNo) && pageNo != null) {
      page = '?page=' + pageNo;
    }

    if (sortField !== undefined) {
      sortFieldName = '&sortField=' + sortField + '&sortOrder=' + sortOrder;
    }

    if (globalFilter != null && globalFilter !== undefined) {
      globalSearch = '&filters[global]=' + globalFilter;
    }

    if (rows !== 0) {
      perPage = '&per_page=' + rows;
    }

    const finalFilter = page + sortFieldName + globalSearch + perPage;

    return this.http.get(this.baseUrl + '/list' + finalFilter);
  }

  /**
   * Resend Invite Link
   * @param id
   * @author Dhaval Bera
   */
  resendInviteLink(id: number) {
    return this.http.post(this.baseUrl + '/resend-invite/' + id, id);
  }

  /**
   * Get User By ID
   * @param id
   * @author Dhaval Bera
   */
  getUserById(id: any) {
    return this.http.get(this.baseUrl + '/view/' + id);
  }

  /**
   * Update user Details
   * @param id
   * @param body
   * @author Dhaval Bera
   */
  update(body: any, id: number) {
    return this.http.post(this.baseUrl + '/update/' + id, body);
  }
  /**
   * Get Constants
   * @author Dhaval Bera
   */
  getConstants() {
    return this.http.get(this.baseUrl + '/get-constants');
  }

  /**
   * Get users by roles
   * @param body
   */
  getUsersByRoles(body: any) {
    return this.http.post(`${this.baseUrl}/users-by-roles`, body);
  }
}
