import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CompareValidatorsDirective } from '../../../../shared/directives/compare-validators.directive';
import { AuthService } from '../service/auth.service';
import { AppConstants } from '../../../../app.constant';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public resetPasswordForm!: FormGroup;
  public token: any = 'test';
  public errors: string[] = [];
  public messages: string[] = [];
  public submitted = false;
  public inValidToken: boolean = false;
  public errorMsg: any;
  public successMsg: any;
  public loading: boolean = false;
  public thankYou: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private commonService: CommonService,
  ) {
    this.token = this.route.snapshot.paramMap.get('token');
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        retypePassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      },
      {
        validator: CompareValidatorsDirective.MatchPasswordEdit,
      }
    );

    // It will check reset password token
    // this.redirectApps();
    this.checkResetPasswordToken();
  }

  ngOnInit(): void {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    this.resetPasswordForm.controls['password'].valueChanges.subscribe((x) =>
      this.resetPasswordForm.controls['retypePassword'].updateValueAndValidity()
    );
  }
  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }
  /**
   * It will check token is valid or not
   * @author Dhaval Bera
   */
  checkResetPasswordToken() {
    this.authService.checkResetPasswordToken(this.token).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverFailStatus) {
          this.inValidToken = true;
          this.errorMsg = result.message;
        }
      },
      error: (e) => {
        this.errors.push(
          'Oops! Something went wrong. Please contact to administrator.'
        );
      },
      complete: () => { },
    });
  }

  /**
   * Reset Password
   * @param event
   * @author Dhaval Bera
   */
  onSubmit(data: any) {
    if (this.resetPasswordForm.invalid) {
      this.validateAllFormFields(this.resetPasswordForm);
      return;
    }
    this.loading = true;
    const formData: FormData = new FormData();
    formData.append('token', this.token);
    formData.append('mpin', data.password);

    this.authService.resetpassword(formData).subscribe({
      next: (result: any) => {
        this.loading = false;
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast(result.message);
          this.resetPasswordForm.reset();
          this.thankYou = true;
        } else {
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (e) => {
        this.loading = false;
        this.errors = [];
        this.commonService.showErrorToast('Oops! Something went wrong. Please contact to administrator.');
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
   *
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
