<div class="login-wrape">
  <div class="card-wrape">
    <div class="card-head">
      <h4 class="left-text">User Registered</h4>
    </div>
    <div class="error-wrap">
      <h4>Your account has been created successfully</h4>
    </div>
  </div>
</div>
