import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {
  url = '';

  constructor(private router: Router) {
       router.events.subscribe((route) => {
       if(route instanceof NavigationEnd){
          this.url = route.url;
          if(this.url && this.url.length > 0){
            this.url = this.url.slice(1);
          }
       }
    });
  }

  ngOnInit(): void {
  }

}
