import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { map, startWith } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { DiagnosticLabsService } from 'src/app/service/diagnosticLabs.service';
import { LoincCodeService } from 'src/app/service/loinc-code.service';
import { CommonService } from 'src/app/service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-loinc-code-form',
  templateUrl: './loinc-code-form.component.html',
  styleUrls: ['./loinc-code-form.component.scss']
})
export class LoincCodeFormComponent implements OnInit {
  
  public loincCodeForm!: FormGroup;
  public loincCodeID: any;
  public loincCodeDetail: any;
  public title: any = 'Add Loinc Code';
  public isViewMode: boolean = false;
  public diagnosticLabs: any[] = [];
  public currentMenuPermission: any;

    // accordion code start
    step = 0;
    setStep(index: number) {
      this.step = index;
    }
    nextStep() {
      this.step++;
    }
    prevStep() {
      this.step--;
    }
    // accordion code end
  constructor(
    public diagnosticLabService: DiagnosticLabsService,
    public loincCodeService: LoincCodeService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    protected router: Router,
    private formBuilder: FormBuilder,
    private menuService: MenuService
  ) {
    this.loincCodeID = this.route.snapshot.paramMap.get('id');
    if (this.loincCodeID) {
      this.setEditData();
      if (this.router.url.indexOf('view') >= 0) {
        this.title = 'View Loinc Code';
        this.isViewMode = true;
      }else{
        this.title = 'Edit Loinc Code';
      }
    } else {
      this.loincCodeID = 0;
    }
   }

  setEditData(): void{

    console.log('edit mode');

    this.loincCodeService.getRow(<string>this.loincCodeID)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
         
          this.loincCodeDetail = result.data;
          
          this.loincCodeForm.patchValue({
            id: this.loincCodeDetail.id,
            diagnostic_lab_id: this.loincCodeDetail.diagnostic_lab_id,
            hba1c: this.loincCodeDetail.hba1c,
            estimated_average_glucose: this.loincCodeDetail.estimated_average_glucose,
            mean_plasma_glucose: this.loincCodeDetail.mean_plasma_glucose,
            fasting_blood_sugar: this.loincCodeDetail.fasting_blood_sugar,
            post_prandial: this.loincCodeDetail.post_prandial,
            haemoglobin: this.loincCodeDetail.haemoglobin,
            total_rbc_count: this.loincCodeDetail.total_rbc_count,
            mcv: this.loincCodeDetail.mcv,
            mch: this.loincCodeDetail.mch,
            mchc: this.loincCodeDetail.mchc,
            total_wbc_count: this.loincCodeDetail.total_wbc_count,
            neutrophils: this.loincCodeDetail.neutrophils,
            lymphocytes: this.loincCodeDetail.lymphocytes,
            monocytes: this.loincCodeDetail.monocytes,
            eosinophils: this.loincCodeDetail.eosinophils,
            basophils: this.loincCodeDetail.basophils,
            platelet_counts: this.loincCodeDetail.platelet_counts,
            platelets_on_smear: this.loincCodeDetail.platelets_on_smear,
            mpv: this.loincCodeDetail.mpv,
            morphology_red_blood_cell: this.loincCodeDetail.morphology_red_blood_cell,
            peripheral_smear_wbc: this.loincCodeDetail.peripheral_smear_wbc,
            peripheral_smear_platelets: this.loincCodeDetail.peripheral_smear_platelets,
            pdw: this.loincCodeDetail.pdw,
            pct: this.loincCodeDetail.pct,
            thalassemia_check: this.loincCodeDetail.thalassemia_check,
            morphology_wbc: this.loincCodeDetail.morphology_wbc,
            platelet_morphology: this.loincCodeDetail.platelet_morphology,
            granulocytes: this.loincCodeDetail.granulocytes,
            granulocytes_10_3: this.loincCodeDetail.granulocytes_10_3,
            rdw: this.loincCodeDetail.rdw,
            rdw_sd: this.loincCodeDetail.rdw_sd,
            plcc_10_3: this.loincCodeDetail.plcc_10_3,
            plcr: this.loincCodeDetail.plcr,
            absolute_lymphocyte_count: this.loincCodeDetail.absolute_lymphocyte_count,
            absolute_monocyte_count: this.loincCodeDetail.absolute_monocyte_count,
            absolute_eosinophil_count: this.loincCodeDetail.absolute_eosinophil_count,
            absolute_basophil_count: this.loincCodeDetail.absolute_basophil_count,
            hematocrit: this.loincCodeDetail.hematocrit,
            absolute_neutrophil_count: this.loincCodeDetail.absolute_neutrophil_count,
            leucocyte_microscopic_examination: this.loincCodeDetail.leucocyte_microscopic_examination,
            rbc_microscopic: this.loincCodeDetail.rbc_microscopic,
            immature_granulocyte: this.loincCodeDetail.immature_granulocyte,
            nucleated_rbc: this.loincCodeDetail.nucleated_rbc,
            neutrophil_lymphocyte_ratio: this.loincCodeDetail.neutrophil_lymphocyte_ratio,
            erythrocyte_sedimentation_rate: this.loincCodeDetail.erythrocyte_sedimentation_rate,
            mentzer_index: this.loincCodeDetail.mentzer_index,
            pdw_fl: this.loincCodeDetail.pdw_fl,
            serum_cholesterol: this.loincCodeDetail.serum_cholesterol,
            serum_triglyceride: this.loincCodeDetail.serum_triglyceride,
            vldl_cholesterol: this.loincCodeDetail.vldl_cholesterol,
            hdl_cholesterol_ratio: this.loincCodeDetail.hdl_cholesterol_ratio,
            ldl_hdl_ratio: this.loincCodeDetail.ldl_hdl_ratio,
            non_hdl_chol: this.loincCodeDetail.non_hdl_chol,
            cholesterol_ldl_direct: this.loincCodeDetail.cholesterol_ldl_direct,
            hdl: this.loincCodeDetail.hdl,
            apperance_serum_refrigeration: this.loincCodeDetail.apperance_serum_refrigeration,
            naked_eye_appearance_serum: this.loincCodeDetail.naked_eye_appearance_serum,
            total_lipid: this.loincCodeDetail.total_lipid,
            triidothyronine: this.loincCodeDetail.triidothyronine,
            total_thyroxine: this.loincCodeDetail.total_thyroxine,
            thyroid_stimulating_hormone: this.loincCodeDetail.thyroid_stimulating_hormone,
            serum_creatinine: this.loincCodeDetail.serum_creatinine,
            est_glomerular_filter_rate: this.loincCodeDetail.est_glomerular_filter_rate,
            cystatin_c: this.loincCodeDetail.cystatin_c,
            phosphorous: this.loincCodeDetail.phosphorous,
            sodium: this.loincCodeDetail.sodium,
            potassium: this.loincCodeDetail.potassium,
            chloride: this.loincCodeDetail.chloride,
            allele_tested: this.loincCodeDetail.allele_tested,
            slco1b1: this.loincCodeDetail.slco1b1,
            slco1b1_genotype_result: this.loincCodeDetail.slco1b1_genotype_result,
            hepatitis_b_surface: this.loincCodeDetail.hepatitis_b_surface,
            abo_group: this.loincCodeDetail.abo_group,
            rh_type: this.loincCodeDetail.rh_type,
            bilirubin_indirect: this.loincCodeDetail.bilirubin_indirect,
            albumin: this.loincCodeDetail.albumin,
            alkaline_phosphatase: this.loincCodeDetail.alkaline_phosphatase,
            bilirubin_direct: this.loincCodeDetail.bilirubin_direct,
            bilirubin_total: this.loincCodeDetail.bilirubin_total,
            aspartate_aminotransferase: this.loincCodeDetail.aspartate_aminotransferase,
            alanine_aminotransferase: this.loincCodeDetail.alanine_aminotransferase,
            ag_ratio: this.loincCodeDetail.ag_ratio,
            globulin: this.loincCodeDetail.globulin,
            total_protein: this.loincCodeDetail.total_protein,
            blood_urea_nitrogen: this.loincCodeDetail.blood_urea_nitrogen,
            uric_acid: this.loincCodeDetail.uric_acid,
            blood_urea: this.loincCodeDetail.blood_urea,
            creactive_protein: this.loincCodeDetail.creactive_protein,
            gamma_glutamyl_transferase: this.loincCodeDetail.gamma_glutamyl_transferase,
            calcium: this.loincCodeDetail.calcium,
            ra_factor: this.loincCodeDetail.ra_factor,
            color: this.loincCodeDetail.color,
            apperance: this.loincCodeDetail.apperance,
            ph: this.loincCodeDetail.ph,
            specific_gravity: this.loincCodeDetail.specific_gravity,
            protein: this.loincCodeDetail.protein,
            glucose: this.loincCodeDetail.glucose,
            ketones: this.loincCodeDetail.ketones,
            bilirubin: this.loincCodeDetail.bilirubin,
            urobilinogen: this.loincCodeDetail.urobilinogen,
            blood: this.loincCodeDetail.blood,
            nitrite: this.loincCodeDetail.nitrite,
            wbc: this.loincCodeDetail.wbc,
            epithelial_cells: this.loincCodeDetail.epithelial_cells,
            red_blood_cells: this.loincCodeDetail.red_blood_cells,
            casts_hpf: this.loincCodeDetail.casts_hpf,
            crystals: this.loincCodeDetail.crystals,
            bacteria: this.loincCodeDetail.bacteria,
            yeast: this.loincCodeDetail.yeast,
            vitamin_d_total: this.loincCodeDetail.vitamin_d_total,
            hiv_4th_gene_assay_serum_index_value: this.loincCodeDetail.hiv_4th_gene_assay_serum_index_value,
            hiv_4th_gene_assay_serum_result: this.loincCodeDetail.hiv_4th_gene_assay_serum_result,
            hiv_1_2_antibodies_screening_test_serum_result: this.loincCodeDetail.hiv_1_2_antibodies_screening_test_serum_result,
            hepatitis_b_surface_antigen_serum: this.loincCodeDetail.hepatitis_b_surface_antigen_serum,
            hepatitis_c_antibodies_serum: this.loincCodeDetail.hepatitis_c_antibodies_serum,
            hepatitis_c_antibodies_serum_patient_value: this.loincCodeDetail.hepatitis_c_antibodies_serum_patient_value,
            vdrlrpr_serum: this.loincCodeDetail.vdrlrpr_serum,
            malaria_parasiteblood_parasite_smear_identification: this.loincCodeDetail.malaria_parasiteblood_parasite_smear_identification,
          });
        }else{
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error:(err: any) => {
        console.log(err);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })

  }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
    this.getDiagnosticLabs();

    this.loincCodeForm = this.formBuilder.group({
        id: new FormControl(''),
        diagnostic_lab_id: [null, [Validators.required]],
        hba1c: null,
        estimated_average_glucose: null,
        mean_plasma_glucose: null,
        fasting_blood_sugar: null,
        post_prandial: null,
        haemoglobin: null,
        total_rbc_count: null,
        mcv: null,
        mch: null,
        mchc: null,
        total_wbc_count: null,
        neutrophils: null,
        lymphocytes: null,
        monocytes: null,
        eosinophils: null,
        basophils: null,
        platelet_counts: null,
        platelets_on_smear: null,
        mpv: null,
        morphology_red_blood_cell: null,
        peripheral_smear_wbc: null,
        peripheral_smear_platelets: null,
        pdw: null,
        pct: null,
        thalassemia_check: null,
        morphology_wbc: null,
        platelet_morphology: null,
        granulocytes: null,
        granulocytes_10_3: null,
        rdw: null,
        rdw_sd: null,
        plcc_10_3: null,
        plcr: null,
        absolute_lymphocyte_count: null,
        absolute_monocyte_count: null,
        absolute_eosinophil_count: null,
        absolute_basophil_count: null,
        hematocrit: null,
        absolute_neutrophil_count: null,
        leucocyte_microscopic_examination: null,
        rbc_microscopic: null,
        immature_granulocyte: null,
        nucleated_rbc: null,
        neutrophil_lymphocyte_ratio: null,
        erythrocyte_sedimentation_rate: null,
        mentzer_index: null,
        pdw_fl: null,
        serum_cholesterol: null,
        serum_triglyceride: null,
        vldl_cholesterol: null,
        hdl_cholesterol_ratio: null,
        ldl_hdl_ratio: null,
        non_hdl_chol: null,
        cholesterol_ldl_direct: null,
        hdl: null,
        apperance_serum_refrigeration: null,
        naked_eye_appearance_serum: null,
        total_lipid: null,
        triidothyronine: null,
        total_thyroxine: null,
        thyroid_stimulating_hormone: null,
        serum_creatinine: null,
        est_glomerular_filter_rate: null,
        cystatin_c: null,
        phosphorous: null,
        sodium: null,
        potassium: null,
        chloride: null,
        allele_tested: null,
        slco1b1: null,
        slco1b1_genotype_result: null,
        hepatitis_b_surface: null,
        abo_group: null,
        rh_type: null,
        bilirubin_indirect: null,
        albumin: null,
        alkaline_phosphatase: null,
        bilirubin_direct: null,
        bilirubin_total: null,
        aspartate_aminotransferase: null,
        alanine_aminotransferase: null,
        ag_ratio: null,
        globulin: null,
        total_protein: null,
        blood_urea_nitrogen: null,
        uric_acid: null,
        blood_urea: null,
        creactive_protein: null,
        gamma_glutamyl_transferase: null,
        calcium: null,
        ra_factor: null,
        color: null,
        apperance: null,
        ph: null,
        specific_gravity: null,
        protein: null,
        glucose: null,
        ketones: null,
        bilirubin: null,
        urobilinogen: null,
        blood: null,
        nitrite: null,
        wbc: null,
        epithelial_cells: null,
        red_blood_cells: null,
        casts_hpf: null,
        crystals: null,
        bacteria: null,
        yeast: null,
        vitamin_d_total: null,
        hiv_4th_gene_assay_serum_index_value: null,
        hiv_4th_gene_assay_serum_result: null,
        hiv_1_2_antibodies_screening_test_serum_result: null,
        hepatitis_b_surface_antigen_serum: null,
        hepatitis_c_antibodies_serum: null,
        hepatitis_c_antibodies_serum_patient_value: null,
        vdrlrpr_serum: null,
        malaria_parasiteblood_parasite_smear_identification: null
    });
  }

  /**
  * Get diagnostic labs List
  *
  * @returns object
  */
   getDiagnosticLabs() {
    this.loincCodeService.getDiagnosticLab().subscribe({
      next: (result: any) => {
        this.diagnosticLabs = result.data;
        if (this.loincCodeForm.value.diagnostic_lab_id != 0 || this.loincCodeForm.value.diagnostic_lab_id != null || this.loincCodeForm.value.diagnostic_lab_id != '') {
          const keyExists = this.diagnosticLabs.some((item: any) => item.name.includes(this.loincCodeDetail.diagnostic_lab.name));
          if (!this.diagnosticLabs || keyExists == false) {
            this.diagnosticLabs.push({ 'id': this.loincCodeForm.value.diagnostic_lab_id, 'name': this.loincCodeDetail.diagnostic_lab.name });
            this.diagnosticLabs = this.diagnosticLabs.slice(0);
          }
        }
      }
    });
  }

  /**
   * Validate form fields recursivly
   *
   * @author Jaydeep Patel <jaydeep.patel@globalgenecorp.com>
   *
   * @returns object
   */
   validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Add and update Loinc Code Data
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @returns object
   */
  onSubmit(): void {


    if (this.loincCodeForm.invalid) {
      this.validateAllFormFields(this.loincCodeForm);
      this.commonService.showErrorToast(
        'Please fill all the fields'
      );
      return;
    }
    if(this.loincCodeID){
      this.updateLoincCode(this.loincCodeForm.value, this.loincCodeID);
    }else{
      this.loincCodeForm.controls['id'].disable();
      this.storeLoincCode(this.loincCodeForm.value);
    }
    
    
  }

  storeLoincCode(formValue: any){
    this.loincCodeService.store(formValue)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(["/site/loinc-codes"]);
            this.commonService.showSuccessToast(
              "Loinc Code data added successfully!"
            );
        }else{
          if(result.data.diagnostic_lab_id){
            this.commonService.showErrorToast(
              result.data.diagnostic_lab_id
            ); 
          }else{
            this.commonService.showErrorToast(
              result.message
            );
          }
        }
      },
      error:(err: any) => {
        console.log(err);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    });
  }

  getDiagnosticLabError() {
    if(this.loincCodeForm.controls['diagnostic_lab_id'].invalid){
      if (this.loincCodeForm.controls['diagnostic_lab_id'].errors!['required']) {
        return 'Please Select Diagnostic Lab';
      }
      if(this.loincCodeForm.controls['diagnostic_lab_id'].errors!['pattern']) {
        return 'Please select valid diagnostic lab';
      }
    }
    return ''; 
  }

  updateLoincCode(formValue: any, ID: any){
    this.loincCodeService.update(formValue, ID)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(["/site/loinc-codes"]);
            this.commonService.showSuccessToast(
              "Loinc Code data updated successfully!"
            );
        }else{
          if(result.data.diagnostic_lab_id){
            this.commonService.showErrorToast(
              result.data.diagnostic_lab_id
            ); 
          }else{
            this.commonService.showErrorToast(
              result.message
            );
          }
        }
      },
      error:(err: any) => {
        console.log(err);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    });
  }

   /**
    *
    * @param field
    */
   displayFieldCss(field1: string, field2 = '') {
    let field = field1;
    if (field2 != '') {
      field = field2
    }
    return {
      "has-error": this.commonService.isFieldValid(this.loincCodeForm, field),
      "has-feedback": this.commonService.isFieldValid(this.loincCodeForm, field),
    };
  }

}
