import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-participant-count-chart',
  templateUrl: './participant-count-chart.component.html',
  styleUrls: ['./participant-count-chart.component.scss']
})
export class ParticipantCountChartComponent implements OnInit {

  public siteId: any;
  percentageOrAbsoluteCounts: any[] = [
    {
      id: 2,
      value: "Absolute Counts",
    },
    {
      id: 1,
      value: "Percentage",
    },
  ];
  public collectionDateWisePercentOrCount: any = 2;
  public options: any = {};
  public totalCollectionDateWiseParticipantChartCounts: any = 0;
  public monthOrYear: any = "year";
  public monthWiseChartLoader: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {
    this.siteId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit(): void {
    const formData: FormData = new FormData();
    formData.append("site_codes", this.siteId);
    this.getDashboardChartData("month_wise", formData);
  }

  /**
 * Get Participants counts
 */
  getDashboardChartData(
    type: string,
    formData: any,
    isPercentSelected: any = 2,
    monthOrYearFilter = "year"
  ) {
    this.monthWiseChartLoader = true;
    this.dashboardService
      .getDashboardChartData(
        type,
        formData,
        monthOrYearFilter,
        isPercentSelected
      )
      .subscribe((result: any) => {
        this.totalCollectionDateWiseParticipantChartCounts =
          result.data.name.length;
        this.showCollectionDateWiseChart(
          result.data.name,
          result.data.value,
          isPercentSelected
        );
        this.monthWiseChartLoader = false;
      });
  }

  showCollectionDateWiseChart(monthOrWeekData: any, monthOrWeekCountData: any, isPercentSelected: any = 2) {
    var base = +new Date(1968, 9, 3);
    var oneDay = 24 * 3600 * 1000;
    var date = [];

    var data = [Math.random() * 300];

    for (var i = 1; i < 20000; i++) {
      var now = new Date(base += oneDay);
      date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
      data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
    }

    this.options = {
      color: ['#598bff', '#2ce69b', '#42aaff', '#ffc94d', '#F46D43', '#ABDDA4', '#F4D787', '#ff708d'],
      grid: {
        left: 38,
        top: 40,
        right: 22,
        bottom: 40,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        extraCssText: 'z-index: 100',
        position: 'inside',
        axisPointer: {
          type: 'shadow'
        },

        formatter: function (params: any) {
          const colorSpan = (color: any) =>
            '<span style="display:inline-block; z-index: 100; margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';

          let showCountOrPercentValue: string;

          if (isPercentSelected === 1) {
            showCountOrPercentValue = params[0].value.toFixed(2) + "%";
          } else {
            showCountOrPercentValue = params[0].value;
          }
          const xx = ` ${colorSpan(params[0].color)} ${params[0].name
            } \n <span class="badge badge-dark">${showCountOrPercentValue} </span> `;
          const rez = '<div style="padding:5px">' + xx + "</div>";

          return '<div class="echart-toolitp-container">' + rez + "</div>";
        },


      },
      toolbox: {
        itemSize: '20',
        showTitle: false,
        feature: {
          magicType: {
            type: ['bar', 'line'],
            title: {
              bar: 'bar',
              line: 'line'
            },
          },
          restore: {
            show: true,
            title: 'Restore',
          },

        }
      },

      xAxis: [
        {
          type: 'category',
          splitLine: { show: false },
          data: monthOrWeekData,
          boundaryGap: false,
          name: 'Collection Date',
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: {
            fontSize: 13,
            fontStyle: 'normal',
            color: '#222b45',
            fontWeight: '500',
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {

            },
          },
          axisLabel: {
            textStyle: {

              color: '#222b45',
            },
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Collection Sample Counts',
          nameLocation: 'middle',
          nameGap: 40,
          boundaryGap: false,
          minInterval: 1,
          nameTextStyle: {
            fontSize: 13,
            fontStyle: 'normal',
            color: '#222b45',
            fontWeight: '500',
          },
          data: monthOrWeekCountData,
          axisLine: {
            lineStyle: {

            },
          },
          splitLine: {
            lineStyle: {

            },
          },
          axisLabel: {
            textStyle: {
              color: '#222b45',
            },
          },
        },
      ],

      series: [
        {
          name: 'Collection Date',
          type: 'line',
          smooth: true,
          symbol: 'none',
          sampling: 'average',
          itemStyle: {
            color: 'rgba(66, 170, 255, 0.5)',
          },
          areaStyle: {

          },
          data: monthOrWeekCountData
        }
      ]
    };
  }

  /**
   *
   * @param chartType
   * @param isPercentSelected
   */
  showRecordsInPercentOrAbsoluteCount(
    chartType: string,
    isPercentSelected: any = 2
  ) {
    const formData: FormData = new FormData();
    formData.append("site_codes", this.siteId);
    this.getDashboardChartData(
      chartType,
      formData,
      isPercentSelected.id,
      this.monthOrYear
    );
  }
}
