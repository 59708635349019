<section class="my-profile-wrape">
  <div class="page-title-box">
    <h1>Manage Recontact</h1>
    <ul class="bradcrumb">
      <li>Manage Recontact</li>
      <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
  </div>

  <section class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text">Recontact List Filter</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-4 col-md-4">
                <div class="input-wrape">
                  <mat-label>Recontact Filter</mat-label>
                  <div class="control-pad">
                    <ng-select bindLabel="label" bindValue="value" (change)="changeRecontactFilter($event)"
                      (clear)="clearRecontactFilter();" [(ngModel)]="selectedFilter" [dropdownPosition]="'bottom'"
                      [searchable]="true" [multiple]="false" [closeOnSelect]="true" [items]="recontactFlags"
                      placeholder="Select recontact filter"></ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-md-12">
        <!-- table expand code start -->
        <div class="row">
          <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <div class="progress-overlay"></div>
              <div class="card-head">
                <h4 class="left-text"> Recontact List </h4>
                <div class="right-control">
                  <div class="table-search-wrape side-info">
                    <span class="icon"></span>
                    <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control" type="text">
                    <div class="info-pad popup-over">
                      <button class="info-icon"></button>
                      <div class="info-section popupcontent">
                        Sr. No., New PID, New Recollection ID, Site Code, Sample Collection
                        Date, Age, Gender
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-section">
                <div class="table-wrape">
                  <table mat-table [dataSource]="dataSources" multiTemplateDataRows class="mat-elevation-z8"
                    #expandSort="matSort" matSort (matSortChange)="SortFilter($event)">
                    <ng-container matColumnDef="expand">
                      <th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
                      <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="expand row"
                          (click)="(expandedElement = expandedElement === element ? undefined : element); $event.stopPropagation()">
                          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="participant_id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sr. No.</th>
                      <td mat-cell *matCellDef="let element">{{element.participant_id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="new_pid">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>New PID</th>
                      <td mat-cell *matCellDef="let element">{{element.new_pid}}</td>
                    </ng-container>
                    <ng-container matColumnDef="new_recollection_id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>New Recollection ID
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.new_recollection_id}}</td>
                    </ng-container>
                    <ng-container matColumnDef="site_code">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Site Code</th>
                      <td mat-cell *matCellDef="let element">{{element.site_code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="sample_collection_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sample Collection Date
                      </th>
                      <td mat-cell *matCellDef="let element">{{element.sample_collection_date}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="age">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Age</th>
                      <td mat-cell *matCellDef="let element">{{element.age}}</td>
                    </ng-container>
                    <ng-container matColumnDef="gender">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
                      <td mat-cell *matCellDef="let element">{{element.gender | titlecase }}</td>
                    </ng-container>

                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail">
                      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div class="example-element-detail"
                          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                          <div class="example-element-position"> </div>
                          <div class="inner-bg-gray">
                            <div class="col-xl-6">
                              <div class="row-view">
                                <p>
                                  <b>First Name</b>
                                  <span>{{element.first_name}}</span>
                                </p>
                                <p>
                                  <b>Last Name</b>
                                  <span>{{element.last_name}}</span>
                                </p>
                                <p>
                                  <b>Postal Address</b>
                                  <span>{{element.postal_address}}</span>
                                </p>
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="row-view">
                                <p>
                                  <b>Phone Number</b>
                                  <span>{{element.phone_no}}</span>
                                </p>
                                <p>
                                  <b>Alternate Phone Number</b>
                                  <span>{{element.alternate_phone_no}}</span>
                                </p>
                              </div>
                            </div>

                            <div class="form-area">
                              <div class="head-part">
                                <h2>Recontact Fields</h2>
                                <div class="icons-wrape" *ngIf="currentMenuPermission.edit">
                                  <button type="button" (click)="clickEvent(element)" *ngIf="!element.editing"
                                    class="edit-icon" title="Edit"></button>
                                  <button (click)="saveEvent(element)" *ngIf="element.editing" class="save-icon"
                                    title="Save"></button>
                                  <button (click)="cancelEvent(element)" *ngIf="element.editing"
                                    class="delete-icon close-icon" title="Cancel"></button>
                                </div>
                              </div>
                              <div class="form-pad" [ngClass]="element.editing ? 'enable' : 'disable'">
                                <div class="row">
                                  <div class="col-xl-4 col-md-4">
                                    <div class="input-wrape">
                                      <mat-label>Recontact Status </mat-label>
                                      <div class="control-pad">
                                        <ng-select appendTo="body" bindLabel="label" bindValue="value"
                                          [dropdownPosition]="'bottom'" [(ngModel)]="element.recontact_status"
                                          [searchable]="false" [multiple]="false" [closeOnSelect]="true"
                                          [items]="recontact_status_flag" placeholder="Select recontact filter"></ng-select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-xl-4 col-md-4">
                                    <div class="input-wrape">
                                      <mat-label>Recontact Remark </mat-label>
                                      <mat-form-field appearance="outline">
                                        <input matInput #input placeholder="Enter recontact remark" type="text"
                                          value="{{element.recontact_remark}}" [(ngModel)]="element.recontact_remark">
                                      </mat-form-field>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                      class="example-element-row" [class.example-expanded-row]="expandedElement === element"
                      (click)="expandedElement = expandedElement === element ? undefined : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                      class="example-element-row example-detail-row"></tr>
                    <tr mat-row>
                      <td *matNoDataRow colspan="7">{{matNoDataRowTitle}}</td>
                    </tr>
                  </table>
                  <mat-paginator #expandpagination [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
                    showFirstLastButtons>
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- table expand code end -->
      </div>
    </div>
  </section>
</section>
