<div [class.sidebar-slide-in]="sidebarShow" id="sidebar-wrape" class=" sidebar-slide-in">
	<!-- <div class="sidebar-opener hamburger" (click)="sidebarShow = !sidebarShow">
	    <span>h</span>
	</div> -->
	<div class="sidebar-head-wrape">
		<a href="javascript:;" title="Pheno" class="logo"><img src="../../../assets/images/pheno-logo.png" alt="Pheno">
			<span>PHENO</span></a>
		<div class="sidebar-opener hamburger" (click)="sidebarShow = !sidebarShow" (click)="sidebarShowHide()">
			<span class="hamburg-icon"></span>
			<!-- <span class="sidebar-close" (click)="sidebarShow = !sidebarShow"></span> -->
		</div>
	</div>
	<ngx-simplebar [options]="options" class="sidebar-slider">
		<mat-tree [dataSource]="dataSourceFlat" [treeControl]="treeControlFlat" class="example-tree">
			<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="setActiveChange(node.path)">
				<div class="mat-tree-node node-level-{{node.level}}" [class.navigation-active]="node.active === true">
					<i class="{{node.icons}} icons"></i>
					<ng-container *ngIf="node.path !='#'">
						<a routerLink="{{node.path}}" [class.navigation-active]="node.active === true">
							{{node.name}}
						</a>
					</ng-container>
					<ng-container *ngIf="node.path == '#'">
						<a [class.navigation-active]="node.active === true">
							<i class="{{node.icons}} icons"></i>{{node.name}}
						</a>
					</ng-container>
				</div>
			</mat-tree-node>
			<mat-tree-node *matTreeNodeDef="let node; when: hasChildFlat" matTreeNodePadding
				(click)="setActiveChange(node.path)">
				<div (click)="openSidebarOnIconClick()" class="mat-tree-node  node-level-{{node.level}}" [class.navigation-active]="node.active === true"
					matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
					<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
						<mat-icon class="mat-icon-rtl-mirror">
							{{treeControlFlat.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
						</mat-icon>
					</button>
					<i class="{{node.icons}} icons"></i>
					<ng-container *ngIf="node.path !='#'">
						<a routerLink="{{node.path}}" [class.navigation-active]="node.active === true">
							{{node.name}}
						</a>
					</ng-container>
					<ng-container *ngIf="node.path == '#'">
						<a [class.navigation-active]="node.active === true">
							{{node.name}}
						</a>
					</ng-container>
				</div>
				<div [class.example-tree-invisible]="!treeControlFlat.isExpanded(node)" role="group">
					<ng-container matTreeNodeOutlet></ng-container>
				</div>
			</mat-tree-node>
		</mat-tree>
	</ngx-simplebar>

</div>
<section class="right-layout">
	<app-header></app-header>
	<app-main-container>

	</app-main-container>
	<app-footer></app-footer>

	<div class="filter-overlay" (click)="overLayClose()"></div>
</section>