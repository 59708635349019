<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="{{cancelLink}}" title="Drug Brand" class="only-link">Drug Brand</a>
        </li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-8 col-md-12 col-sm-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">{{title}}</h4>
                </div>
                <form [formGroup]="brandForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit(brandForm.value)">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': brandForm.controls['brand_name'].invalid && (brandForm.controls['brand_name'].dirty || brandForm.controls['brand_name'].touched)}">
                                    <mat-label><i>*</i> Drug Brand Name</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput #input placeholder="Enter Drug Brand name" type="text"
                                            formControlName="brand_name" (focusout)="checkUniqueDrugBrand($event)">
                                    </mat-form-field>
                                    <span
                                        *ngIf="brandForm.controls['brand_name'].invalid && (brandForm.controls['brand_name'].dirty || brandForm.controls['brand_name'].touched)">
                                        <mat-error *ngIf="brandForm.controls['brand_name'].errors?.['required']">
                                            Drug Brand Name is required
                                        </mat-error>
                                        <mat-error *ngIf="brandForm.controls['brand_name'].errors?.['unique']">
                                            {{ brandForm.controls['brand_name'].errors?.['unique'] }}
                                        </mat-error>
                                        <!-- <mat-error *ngIf="brandForm.controls['brand_name'].errors?.['minlength']">
                                            Min Length is {{nameMinLength}} characters.
                                        </mat-error> -->
                                        <mat-error *ngIf="brandForm.controls['brand_name'].errors?.['maxlength']">
                                            Max Length is 255 characters.
                                        </mat-error>
                                        <mat-error *ngIf="brandForm.controls['brand_name'].errors?.['validField']">
                                            {{brandForm.controls['brand_name'].errors?.['validField']}}
                                        </mat-error>
                                    </span>
                                </div>
                                <div class="input-wrape">
                                    <mat-label>SNOMED ID</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="snomedIdList" formControlName="snomed_id" [multiple]="true"
                                            bindLabel="snomed_id" labelForId="snomed_id" placeholder="Select SNOMED ID">
                                        </ng-select>
                                        <mat-error *ngIf="brandForm.controls['snomed_id'].errors?.['validField']">
                                            {{brandForm.controls['snomed_id'].errors?.['validField']}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape">
                                    <mat-label>Generic</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="genericList" formControlName="generic_id" [multiple]="true"
                                            bindLabel="generic_name" bindValue="id" labelForId="generic"
                                            placeholder="Search generic" (search)="getGeneric($event)">
                                        </ng-select>
                                        <mat-error *ngIf="brandForm.controls['generic_id'].errors?.['validField']">
                                            {{brandForm.controls['generic_id'].errors?.['validField']}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
                            title="{{buttonText}}">{{buttonText}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>