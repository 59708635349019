import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class StateService extends CrudService {

  constructor(public override http: HttpClient) {
    super('state', http);
  }

  /**
   * Get state by city
   *
   * @returns object
   */
  getStateByCity(cityId: any) {
    return this.http.get(`${this.baseUrl}/get-states-by-city/${cityId}`);
  }

  /**
   * Get state code
   *
   * @returns object
   */
  getStateCodeList() {
    return this.http.get(`${this.baseUrl}/get-code-list`);
  }

  /**
   * Check State field is unique
   *
   * @param string stateFieldName
   * @param string stateFieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueStateFields(stateFieldName: string, stateFieldValue: string, id: number,countryId: number) {
    return this.http.get(`${ this.baseUrl }/check-unique-state/${ stateFieldName }/${ stateFieldValue }/${ id }/${countryId}`);
  }
}
