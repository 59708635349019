<div class="page-title-box">
  <h1>View Lab Data</h1>
  <ul class="bradcrumb">
    <li>View Lab Data</li>
    <li><a routerLink="/lab-data" title="Ctate" class="only-link">Lab Data</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row justify-content-md-center">

    <div class="col-xl-12 col-md-12 col-sm-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">Lab Information - {{labDataDetail.participant?.new_pid ?
            labDataDetail.participant?.new_pid :
            '-'}}</h4>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-md-12 col-sm-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">Quality Quantity Record</h4>
        </div>
        <div class="card-body">
          <table class="table-wrapes">
            <tr>
              <td class="info-panel">
                <label>Plate Number:</label>
                <span>{{labDataDetail?.plate_number}}</span>
              </td>
              <td class="info-panel">
                <label>Spectrophotometer Report: </label>
                <span> {{labDataDetail?.spectrophotometer_report}}</span>
              </td>
              <td class="info-panel">
                <label>Date of Run: </label>
                <span> {{labDataDetail?.quantification_date}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>260/280: </label>
                <span> {{labDataDetail?.v_260_280}}</span>
              </td>
              <td class="info-panel">
                <label>260/230: </label>
                <span> {{labDataDetail?.v_260_230}}</span>
              </td>
              <td class="info-panel">
                <label>Conc (ng/ul): </label>
                <span> {{labDataDetail?.conc_ng_ul}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Elution Vol: </label>
                <span> {{labDataDetail?.elution_vol_ul}}</span>
              </td>
              <td class="info-panel">
                <label>Yield (ug): </label>
                <span> {{labDataDetail?.yield_ug}}</span>
              </td>
              <td class="info-panel">
                <label>Elution Buffer: </label>
                <span> {{labDataDetail?.elution_buffer}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Quantification Done By: </label>
                <span> {{labDataDetail?.quantitation_done_by}}</span>
              </td>

              <td class="info-panel">
                <label> </label>
                <span> </span>
              </td>
              <td class="info-panel">
                <label> </label>
                <span> </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-md-12 col-sm-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">Gel Analysis</h4>
        </div>
        <div class="card-body">
          <table class="table-wrapes">
            <tr>
              <td class="info-panel">
                <label>Gel File: </label>
                <span> {{labDataDetail?.gel_file}}</span>
              </td>
              <td class="info-panel">
                <label>Date of Gel Run: </label>
                <span> {{labDataDetail?.date_of_gel_run}}</span>
              </td>
              <td class="info-panel">
                <label>Sample Loading Done By: </label>
                <span> {{labDataDetail?.sample_loading_done_by}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Date of Gel Review: </label>
                <span> {{labDataDetail?.date_of_gel_review}}</span>
              </td>
              <td class="info-panel">
                <label>Gel Review Done By: </label>
                <span> {{labDataDetail?.gel_review_done_by}}</span>
              </td>
              <td class="info-panel">
                <label>Gel Lane: </label>
                <span> {{labDataDetail?.gel_lane}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Well Number: </label>
                <span> {{labDataDetail?.well_number}}</span>
              </td>
              <td class="info-panel">
                <label>DNA Band Nomenclature: </label>
                <span> {{labDataDetail?.dna_band_nomenclature}}</span>
              </td>
              <td class="info-panel">
                <label>Gel Report: </label>
                <span> {{labDataDetail?.gel_report}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-md-12 col-sm-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">Y-STS</h4>
        </div>
        <div class="card-body">
          <table class="table-wrapes">
            <tr>
              <td class="info-panel">
                <label>Gel Based Gender Interpretation: </label>
                <span> {{labDataDetail?.y_test_male_female}}</span>
              </td>
              <td class="info-panel">
                <label>Experiment Number: </label>
                <span> {{labDataDetail?.experiment_number}}</span>
              </td>
              <td class="info-panel">
                <label>Gender Scoring (As per PHF): </label>
                <span> {{labDataDetail?.gender_scoring_as_per_phf}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Well Number: </label>
                <span> {{labDataDetail?.well_number_ysts}}</span>
              </td>
              <td class="info-panel">
                <label>No of Bands in Gel: </label>
                <span> {{labDataDetail?.no_of_bands_in_gel}}</span>
              </td>
              <td class="info-panel">
                <label>Testing: </label>
                <span> {{labDataDetail?.testing}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Status: </label>
                <span> {{labDataDetail?.status}}</span>
              </td>
              <td class="info-panel">
                <label>Gender (As per PHF): </label>
                <span> {{labDataDetail?.gender_as_per_phf}}</span>
              </td>
              <td class="info-panel">
                <label>Date of Y-STS PCR: </label>
                <span> {{labDataDetail?.pcr_date}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-md-12 col-sm-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">Storage Record</h4>
        </div>
        <div class="card-body">
          <table class="table-wrapes">
            <tr>
              <td class="info-panel">
                <label>DNA Barcode: </label>
                <span> {{labDataDetail?.dna_barcode}}</span>
              </td>
              <td class="info-panel">
                <label>DNA Storage Box ID: </label>
                <span> {{labDataDetail?.dna_storage_box_id}}</span>
              </td>
              <td class="info-panel">
                <label>DNA Vial Position: </label>
                <span> {{labDataDetail?.dna_vial_position}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>DNA Rack ID: </label>
                <span> {{labDataDetail?.dna_rack_id}}</span>
              </td>
              <td class="info-panel">
                <label>DNA Eluted Volume: </label>
                <span> {{labDataDetail?.dna_eluted_volume}}</span>
              </td>
              <td class="info-panel">
                <label>DNA Current Volume: </label>
                <span> {{labDataDetail?.dna_current_volume}}</span>
              </td>

            </tr>
            <tr>
              <td class="info-panel">
                <label>Blood Barcode: </label>
                <span> {{labDataDetail?.blood_barcode}}</span>
              </td>
              <td class="info-panel">
                <label>Blood Storage Box ID: </label>
                <span> {{labDataDetail?.blood_storage_box_id}}</span>
              </td>
              <td class="info-panel">
                <label>Blood Vial Position: </label>
                <span> {{labDataDetail?.blood_vial_position}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Blood Rack ID: </label>
                <span> {{labDataDetail?.blood_rack_id}}</span>
              </td>
              <td class="info-panel">
                <label>Blood Original Volume: </label>
                <span> {{labDataDetail?.blood_original_volume}}</span>
              </td>
              <td class="info-panel">
                <label>Blood Current Volume: </label>
                <span> {{labDataDetail?.blood_current_volume}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Plasma Working Vial Barcode: </label>
                <span> {{labDataDetail?.plasma_working_vial_barcode}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Working Vial Storage Box ID: </label>
                <span> {{labDataDetail?.plasma_working_vial_storage_box_id}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Working Vial Position: </label>
                <span> {{labDataDetail?.plasma_working_vial_position}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Plasma Working Vial Rack ID: </label>
                <span> {{labDataDetail?.plasma_working_vial_rack_id}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Working Vial Original Volume: </label>
                <span> {{labDataDetail?.plasma_working_vial_original_volume}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Working Vial Current Volume: </label>
                <span> {{labDataDetail?.plasma_working_vial_current_volume}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Plasma Stock Vial Barcode: </label>
                <span> {{labDataDetail?.plasma_stock_vial_barcode}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Stock Vial Storage Box ID: </label>
                <span> {{labDataDetail?.plasma_stock_vial_storage_box_id}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Stock Vial Position: </label>
                <span> {{labDataDetail?.plasma_stock_vial_position}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Plasma Stock Vial Rack ID: </label>
                <span> {{labDataDetail?.plasma_stock_vial_rack_id}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Stock Vial Original Volume: </label>
                <span> {{labDataDetail?.plasma_stock_vial_original_volume}}</span>
              </td>
              <td class="info-panel">
                <label>Plasma Stock Vial Current Volume: </label>
                <span> {{labDataDetail?.plasma_stock_vial_current_volume}}</span>
              </td>
            </tr>
            <tr>
              <td class="info-panel">
                <label>Remarks: </label>
                <span> {{labDataDetail?.storage_remarks}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>