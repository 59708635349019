<div class="page-title-box">
	<h1>{{title}}</h1>
	<ul class="bradcrumb">
		<li>{{title}}</li>
		<li><a routerLink="{{cancelLink}}" title="Diagnostic Lab" class="only-link">Diagnostic Lab</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<mat-spinner *ngIf="loading"></mat-spinner>
	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{title}}</h4>
				</div>
				<form [formGroup]="diagnosticLabForm" autocomplete="off" novalidate  [class]="isViewMode ? 'view-mode' : 'edit-mode'"
					(ngSubmit)="onSubmit(diagnosticLabForm.value)">
					<div class="card-body">
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['diagnosticLabName'].invalid && (diagnosticLabForm.controls['diagnosticLabName'].dirty || diagnosticLabForm.controls['diagnosticLabName'].touched)}">
									<mat-label><i>*</i> Diagnostic Lab Name</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="diagnosticLabName" matInput #input
											placeholder="Enter diagnostic lab name" type="text" (focusout)="
											checkUniqueDiagnosticLabName($event)
										  ">
									</mat-form-field>
									<mat-error
										*ngIf="diagnosticLabForm.controls['diagnosticLabName'].invalid && (diagnosticLabForm.controls['diagnosticLabName'].dirty || diagnosticLabForm.controls['diagnosticLabName'].touched)">
										{{getDiagnosticLabNameError()}}
									</mat-error>
								</div>
								<div class="input-wrape">
									<mat-label>Lab Code</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="lab_code" matInput #input
											placeholder="Enter lab code" type="text">
									</mat-form-field>
								</div>
								<div class="input-wrape">
									<mat-label>Accreditation</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="accreditation" matInput #input
											placeholder="Enter accreditation" type="text">
									</mat-form-field>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['projectName'].invalid && (diagnosticLabForm.controls['projectName'].dirty || diagnosticLabForm.controls['projectName'].touched)}">
									<mat-label><i>*</i> Project Name:</mat-label>
									<div class="control-pad">
										<ng-select [items]="projectNameOptions" formControlName="projectName"
											bindLabel="name" bindValue="id" labelForId="project"
											placeholder="Enter project name">
										</ng-select>
									</div>
									<mat-error
										*ngIf="diagnosticLabForm.controls['projectName'].invalid && (diagnosticLabForm.controls['projectName'].dirty || diagnosticLabForm.controls['projectName'].touched)">
										{{getProjectNameError()}}
									</mat-error>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['contactPersonName'].invalid && (diagnosticLabForm.controls['contactPersonName'].dirty || diagnosticLabForm.controls['contactPersonName'].touched)}">
									<mat-label><i>*</i> Contact Person Name</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="contactPersonName" matInput #input
											placeholder="Enter contact person name" type="text">
									</mat-form-field>
									<mat-error
										*ngIf="diagnosticLabForm.controls['contactPersonName'].invalid && (diagnosticLabForm.controls['contactPersonName'].dirty || diagnosticLabForm.controls['contactPersonName'].touched)">
										{{getContactPersonNameError()}}
									</mat-error>
								</div>
								<div class="input-wrape code"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['phoneCode'].invalid && (diagnosticLabForm.controls['phoneCode'].dirty || diagnosticLabForm.controls['phoneCode'].touched)}">
									<div class="half-controls">
										<div class="half">
											<mat-label><i>*</i> Country Code </mat-label>
											<div class="control-pad">
												<ng-select [items]="countryCodeOptions" bindLabel="name" bindValue="id"
													placeholder="Enter country code" (change)="changePhoneCode($event)"
													formControlName="phoneCode">
													<ng-template ng-label-tmp let-item="item">
														{{item.phone_code}}
													</ng-template>
													<ng-template ng-option-tmp let-item="item">
														<span class="c-code">{{ item.phone_code }}</span>
														{{item.name}}
													</ng-template>
												</ng-select>
											</div>
											<mat-error
												*ngIf="diagnosticLabForm.controls['phoneCode'].invalid && (diagnosticLabForm.controls['phoneCode'].dirty || diagnosticLabForm.controls['phoneCode'].touched)"
												style="font-size: 10px;">
												{{getPhoneCodeError()}}
											</mat-error>
										</div>
										<div class="input-wrape number"
											[ngClass]="{ 'has-error': diagnosticLabForm.controls['phoneNumber'].invalid && (diagnosticLabForm.controls['phoneNumber'].dirty || diagnosticLabForm.controls['phoneNumber'].touched)}">
											<mat-label><i>*</i> Contact Person Number</mat-label>
											<mat-form-field appearance="outline" class="number-pad" class="no-padding">
												<input formControlName="phoneNumber" matInput #input
													placeholder="Enter phone number" type="number"
													(change)="addValidation()"
													(keypress)="onlyNumeric($event)">
											</mat-form-field>
											<mat-error
												*ngIf="diagnosticLabForm.controls['phoneNumber'].invalid && (diagnosticLabForm.controls['phoneNumber'].dirty || diagnosticLabForm.controls['phoneNumber'].touched)">
												{{getPhoneNumberError()}}
											</mat-error>
										</div>
									</div>
								</div>
								<div class="input-wrape">
									<mat-label>Branch Code</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="branch_code" matInput #input
											placeholder="Enter branch code" type="text">
									</mat-form-field>
								</div>
							</div>

							<div class="col-xl-6 col-md-6">
								<div class="input-wrape">
									<mat-label>PIN/ZIP code:</mat-label>
									<div class="control-pad">
										<ng-select [items]="pincodeList" formControlName="postal_code"
											bindLabel="pincode" bindValue="pincode" labelForId="pincode"
											placeholder="Enter PIN/ZIP code" (search)="searchPincode($event)"
											(change)="selectPincode($event)">
										</ng-select>
									</div>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['country_id'].invalid && (diagnosticLabForm.controls['country_id'].dirty || diagnosticLabForm.controls['country_id'].touched)}">
									<mat-label><i>*</i> Country:</mat-label>
									<div class="control-pad">
										<ng-select [items]="countryList" formControlName="country_id" bindLabel="name"
											bindValue="id" labelForId="countryId" placeholder="Select country"
											(clear)="clearCountry()">
										</ng-select>
									</div>
									<mat-error
										*ngIf="diagnosticLabForm.controls['country_id'].invalid && (diagnosticLabForm.controls['country_id'].dirty || diagnosticLabForm.controls['country_id'].touched)">
										{{getSelectCountryError()}}
									</mat-error>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['state_id'].invalid && (diagnosticLabForm.controls['state_id'].dirty || diagnosticLabForm.controls['state_id'].touched)}">
									<mat-label><i>*</i> State</mat-label>
									<div class="control-pad">
										<ng-select [items]="stateList" formControlName="state_id" bindLabel="name"
											bindValue="id" labelForId="state" placeholder="Enter state"
											(search)="searchState($event, diagnosticLabForm.controls['country_id'].value)"
											(clear)="clearState()">
										</ng-select>
									</div>
									<mat-error
										*ngIf="diagnosticLabForm.controls['state_id'].invalid && ( diagnosticLabForm.controls['state_id'].touched || diagnosticLabForm.controls['state_id'].dirty) ? 'has-error' : '' ">
										{{getSelectStateError()}}
									</mat-error>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diagnosticLabForm.controls['city_id'].invalid && (diagnosticLabForm.controls['city_id'].dirty || diagnosticLabForm.controls['state_id'].touched)}">
									<mat-label><i>*</i> City:</mat-label>
									<div class="control-pad">
										<ng-select [items]="cityList" formControlName="city_id" bindLabel="name"
											bindValue="id" labelForId="city" placeholder="Enter city"
											(search)="searchCity($event,diagnosticLabForm.controls['state_id'].value)"
											(clear)="clearCity()">
										</ng-select>
									</div>
									<mat-error
										*ngIf="diagnosticLabForm.controls['city_id'].invalid && ( diagnosticLabForm.controls['city_id'].touched || diagnosticLabForm.controls['city_id'].dirty) ? 'has-error' : '' ">
										{{getSelectCityError()}}
									</mat-error>
								</div>
								<div class="input-wrape">
									<mat-label>Locality</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="locality" matInput #input placeholder="Enter locality"
											type="text">
									</mat-form-field>
								</div>
								<div class="input-wrape">
									<mat-label>Street</mat-label>
									<mat-form-field appearance="outline">
										<input formControlName="street" matInput #input placeholder="Enter street"
											type="text">
									</mat-form-field>
								</div>
							</div>

							<div class="col-xl-12 col-md-12">
								<h4>Tests Offered </h4>
								<mat-list dense>
									<mat-list-item>
										<mat-checkbox (change)="onSelectAll($event.checked)" [checked]="select_all"
											[indeterminate]="someComplete()" [disabled]="isViewMode">
											Select all
										</mat-checkbox>
									</mat-list-item>
								</mat-list>

								<mat-list dense>
									<ng-container *ngFor="let test of tests_offered;">
										<mat-list-item>
											<mat-checkbox [ngModelOptions]="{standalone: true}"
												[(ngModel)]="test.completed" (ngModelChange)="updateAllComplete()" [disabled]="isViewMode">
												{{ test.name | uppercase }}
											</mat-checkbox>
										</mat-list-item>
									</ng-container>
								</mat-list>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
							title="Cancel">Cancel</button>
						<button mat-raised-button class="btn" type="submit" color="secoundary"
							title="{{buttonText}}" *ngIf="!isViewMode" >{{buttonText}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>