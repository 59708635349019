import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends CrudService {
  constructor(public override http: HttpClient) {
    super('sites', http);
  }

  /**
   * Check organ-site field is unique
   *
   * @param string fieldValue
   * @param number id
   *
   * @returns object
   */
  getAllList() {
    return this.http.get(
      `${this.baseUrl}/all/list`
    );
  }

  /**
   * Check unique site name
   */
  checkSiteName(siteName: string, siteID: any) {
    return this.http.get(`${this.baseUrl}/check-unique-site-name/${siteName}/${siteID}`);
  }

  /**
   * check unique site code
   */
  checkSiteCode(siteCode: any, siteID: any) {
    return this.http.get(`${this.baseUrl}/check-unique-site-code/${siteCode}/${siteID}`);
  }
}
