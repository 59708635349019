import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AllergicReactionService extends CrudService {

  constructor(public override http: HttpClient) {
    super('allergic-reaction', http);
  }

  /**
   * Check Allergic Reaction field is unique
   *
   * @param string allergicReactionFieldName
   * @param string allergicReactionFieldValue
   * @param number id
   *
   * @returns object
   */
  checkUniqueAllergicReactionFields(allergicReactionFieldName: string, allergicReactionFieldValue: string, id: number) {
    return this.http.get(`${this.baseUrl}/check-unique-allergic-reaction-fields/${allergicReactionFieldName}/${allergicReactionFieldValue}/${id}`);
  }
}
