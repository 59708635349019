import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ReligionService } from 'src/app/service/religion.service';
import { AppConstants } from 'src/app/app.constant';
import { map, Observable, startWith } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
@Component({
  selector: 'app-religion-form',
  templateUrl: './religion-form.component.html',
  styleUrls: ['./religion-form.component.scss']
})

export class ReligionFormComponent implements OnInit {
  
  public religionForm!: FormGroup;
  public title: any;
  public religionId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/dictionary-management/religion";
  public userId: any = "";
  public isApproved: number = 1;
  public showLoading: boolean = false;
  public disableSubmitBtn: boolean = false;
  public religionNameMinLength: number = 3;
  public religionNameMaxLength: number = 255;
  public religionDetails: any = [];

  constructor(
    private religionService: ReligionService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.religionId = this.route.snapshot.params['id'];
    if (this.religionId != 0 && this.religionId != null) {
      this.setEditData();
      this.title = "Edit Religion";
      this.buttonText = "Save";
    } else {
      this.title = "Add Religion";
      this.buttonText = "Add";
      this.religionId = 0;
    }
  }

  ngOnInit(): void {
    this.isAddMode = !this.religionId;

    this.userId = localStorage.getItem("userId");
    this.religionForm = this.formBuilder.group({
      'name': ['', [Validators.required, Validators.minLength(this.religionNameMinLength), Validators.maxLength(this.religionNameMaxLength)]],
      'isApproved':[this.isApproved,  []],
    });   
  }
  
  setEditData() {
    this.religionService.getRow(this.religionId)
    .subscribe({
      next: (result: any) => {
      if (result.status != AppConstants.serverSuccessStatus) {
        this.commonService.showErrorToast('Religion not found.');
          this.router.navigate([this.cancelLink]);
      }else{
        this.religionDetails = result['data'];
        this.religionForm.patchValue({
          name: this.religionDetails.name,
        });
      }
    },
      error: (e: any) => {
        console.log(
          e.error
        );
      },
    });
  }

  getReligionNameError() {
    if (this.religionForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.religionForm.controls['name'].errors!['minlength']) {
      return 'Min Length is '+ this.religionNameMinLength +' characters.';
    }
    if (this.religionForm.controls['name'].errors!['maxlength']) {
      return 'Max Length is '+ this.religionNameMaxLength +' characters.';
    }
    if (this.religionForm.controls['name'].errors!['unique']) {
      return this.religionForm.get("name")!.errors!['unique'] ;
    }
    return ''; 
  } 

  /**
   * submit form
   * @returns null
   */
  onSubmit(): void {  
    if (this.religionForm.invalid) {
      this.commonService.validateAllFormFields(this.religionForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('name', this.religionForm.controls['name'].value);
    formData.append('is_approved', this.religionForm.controls['isApproved'].value);
    
      if (this.isAddMode) {
          this.createReligion(formData);
      } else {
          this.updateReligion(formData);
      }
  }
  
  /**
   * call Create Religion api
   * @returns null
   */
  private createReligion(formData: any): void {
    this.religionService.store(formData)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(["/dictionary-management/religion"]);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error:(err) => {
        console.log('error from create religion', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  }
  
  /**
   * call Update Religion api
   * @returns null
   */
  private updateReligion(formData: any): void {
    formData.append('updated_by', this.userId);
    this.religionService.update(formData, this.route.snapshot.params['id'])
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate([this.cancelLink]);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error:(err) => {
        console.log('error from update religion', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  }
 
  /**
     * Check religion field is existed or not
     * @returns null
     */
   checkUniqueReligion(event: any) {
    const religionFieldValue = (event.target as HTMLInputElement).value;
    if (religionFieldValue.length > this.religionNameMaxLength) {
      return false;
    }

      if (religionFieldValue.length < this.religionNameMinLength) {
        return false;
      }
  
    if (religionFieldValue !== "") {
        this.religionService
        .checkUniqueReligion(
          religionFieldValue,
          this.religionId
        )
        .subscribe({
          next:(result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.religionForm.get("name")!.setErrors(null);
            } else {
              this.religionForm
              .get("name")!
              .setErrors({ unique: "Name already exists" });
            }
          },
          error: (error: any) => {
            if (error.status === 400) {
              this.religionForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
            } else {
              console.log(
                "Something went wrong. Please contact to administrator."
              );
            }
          }
        });
    }
    return null;
  }
}
