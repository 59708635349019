import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-disease-chart',
  templateUrl: './disease-chart.component.html',
  styleUrls: ['./disease-chart.component.scss']
})
export class DiseaseChartComponent implements OnInit {

  public percentageOrAbsoluteCounts: any[] = [
    {
      id: 2,
      value: "Absolute Counts",
    },
    {
      id: 1,
      value: "Percentage",
    },
  ];
  public diseaseWisePercentOrCount: any = 2;
  public options: any = {};
  public hemeSubscription: any;
  public chartVariable: string = 'line';
  public siteId: any;
  public diseaseChartLoader: boolean = true;
  public diseaseChartData: any = [];
  public totalDiseaseChartCounts: any = 0;
  public monthOrYear: any = "year";

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) {
    this.siteId = this.route.snapshot.paramMap.get("id");

  }


  ngOnInit(): void {
    const formData: FormData = new FormData();
    formData.append("site_codes", this.siteId);
    // Show all charts with no filter
    this.getDashboardChartData("disease_wise", formData);
  }


  /**
   *
   * @param type
   * @param formData
   * @param isPercentSelected
   * @param monthOrYearFilter
   * @param isUpdate
   */
  getDashboardChartData(
    type: string,
    formData: any,
    isPercentSelected: any = 2,
    monthOrYearFilter = "year",
    isUpdate = 0
  ) {
    this.diseaseChartLoader = true;
    this.dashboardService
      .getDashboardChartData(
        type,
        formData,
        monthOrYearFilter,
        isPercentSelected
      )
      .subscribe((result: any) => {
        this.diseaseChartData = result.data.chart_data;
        this.totalDiseaseChartCounts = result.data.total_chart_counts;
        this.showDiseaseChart(
          this.diseaseChartData,
          result.data.other,
          isPercentSelected
        );
        this.diseaseChartLoader = false;
      });
  }

  showDiseaseChart(diseaseChartData: any,
    otherArray: any,
    isPercentSelected: any = 2) {

    //const colors = config.variables;
    //const echarts: any = config.variables.echarts;

    this.options = {
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 100,
        containLabel: true,
      },
      color: ['#598bff', '#2ce69b', '#42aaff', '#ffc94d', '#F46D43', '#66C2A5', '#ABDDA4', '#F4D787', '#ff708d'],
      tooltip: {
        trigger: 'item',
        enterable: true,
        position: ['40%', '30%'],
        extraCssText: 'z-index: 100',
        padding: 0,
        formatter: function (params: any) {
          const colorSpan = (color: any) =>
            '<span style="z-index:100;display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';
          let rez: string;

          let showCountOrPercentValue: string;

          if (isPercentSelected === 1) {
            showCountOrPercentValue = params.data.percentage + "%";
          } else {
            showCountOrPercentValue = params.data.value;
          }

          if (params.name === "Other") {
            const xx = `${params.name} <span class="badge badge-dark"> ${showCountOrPercentValue} </span>`;
            rez = '<div style="padding:3px 8px">' + xx + "</div>";

            Object.keys(otherArray).forEach((key) => {
              if (isPercentSelected === 1) {
                showCountOrPercentValue = otherArray[key].percentage + "%";
              } else {
                showCountOrPercentValue = otherArray[key].value;
              }

              rez += `<div style="padding:3px 8px"> ${colorSpan(
                params.color
              )} ${otherArray[key].name
                } <span class="badge badge-dark"> ${showCountOrPercentValue} </span> <br> </div>`;
            });
          } else {
            const xx = `${colorSpan(params.color)} ${params.name
              } <span class="badge badge-dark"> ${showCountOrPercentValue} </span>`;
            rez = `<div style="padding:5px"> ${xx} </div>`;
          }

          return '<div class="echart-toolitp-container">' + rez + "</div>";
        },
        axisPointer: {
          animation: true
        },
      },


      legend: {
        bottom: 0,
        type: 'scroll',
        itemWidth: 13,
        itemHeight: 13,
        itemGap: 10,
        textStyle: {
          color: '#222b45',
          fontSize: 13,

        },
      },
      series: [
        {
          name: 'Health',
          type: 'pie',
          radius: ['40%', '85%'],
          center: ['50%', '45%'],
          data: diseaseChartData,
          itemStyle: {
            normal: {
              label: {
                position: 'outside',
                formatter: "{b}\n{c}"
              },
              labelLine: {
                show: true,
                length: 2,
              }
            },
            emphasis: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              label: {
                show: true,
                formatter: function (params: any) {
                  let showCountOrPercentValue: string;

                  if (isPercentSelected === 1) {
                    showCountOrPercentValue = params.data.percentage + "%";
                  } else {
                    showCountOrPercentValue = params.data.value;
                  }
                  return `${params.name} \n ${showCountOrPercentValue} `;
                },
              }
            }

          },

          label: {
            normal: {
              show: false,
              position: 'center',
              textStyle: {
                color: '#222b45',
                fontSize: 15,
                fontWeight: 'bolder'
              },
            },
            emphasis: {
              show: true,
              textStyle: {
                color: '#222b45',
                fontSize: 15,
                fontWeight: 'bolder'
              }
            }
          },
          labelLine: {
            normal: {
            },
          },
        },
      ],
    };
  }


  /**
  *
  * @param chartType
  * @param isPercentSelected
  */
  showRecordsInPercentOrAbsoluteCount(
    chartType: string,
    isPercentSelected: any = 2
  ) {
    const formData: FormData = new FormData();
    formData.append("site_codes", this.siteId);

    this.getDashboardChartData(
      chartType,
      formData,
      isPercentSelected.id,
      this.monthOrYear,
      1
    );
  }
}
