import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CityService } from '../../../../service/city.service';
import Swal from 'sweetalert2'
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    {
      name: 'Origin Participants Count',
      dataKey: 'participant_origin_count',
      isSortable: true,
      cell: (element: Record<string, any>) => ({
        content: element['participant_origin_count'],
        keyName: 'city/origin_city_id',
      }),
    },
    {
      name: 'Residential Participants Count',
      dataKey: 'participant_residential_count',
      isSortable: true,
      cell: (element: Record<string, any>) => ({
        content: element['participant_residential_count'],
        keyName: 'city/residential_city_id',
      }),
    },
    {
      name: 'State',
      dataKey: 'state',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['state_name']}`,
    },
    {
      name: 'Locality',
      dataKey: 'locality',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['locality']}`,
    },
  ];

  public isApproved: number = 1;
  public isCurate: boolean = false;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public editLink = 'dictionary-management/city/edit/';
  public title = 'City';
  public data: any;
  public currentMenuPermission: any;
  public is_data_curation_access : boolean = false;

  constructor(
    private cityService: CityService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private menuService: MenuService,
    ) { }

    ngOnInit() {
      this.is_data_curation_access = JSON.parse(localStorage.getItem('userInfo') as string).is_data_curation_access;
      this.menuService.currentMenu.subscribe(currentMenu => {
        this.currentMenuPermission = currentMenu;
      });
      this.loading = true;
      this.route.queryParams.subscribe((queryParams: any) => {
        if(this.router.url.indexOf('curation-pending') >= 0){

          this.isApproved = 0;
          this.isCurate = true;
          this.editLink = 'dictionary-management/city/curation-pending/';
        } else {
          this.isApproved = 1;
          this.isCurate = false;
        }
        this.getAll();
      });
    }

    /**
     * Get all City
     *
     * @returns object
     */
    getAll() {
      this.loading = true;
      this.cityService.getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log(e.error);
        },
        complete: () => {
          this.loading = false;
        },
      });
    }

    downloadParticipantData(url:string){
      if (this.currentMenuPermission.edit == true) {
        this.loading = true;
        this.cityService.downloadDictionaryParticipantList(url).subscribe({
          next: (result: any) => {
            console.log(result);
            const file = new Blob([result.data.body], { type: 'text/csv' });
            var downloadURL = window.URL.createObjectURL(file);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = result.data.file_name;
            link.click();
          },
          error: () => { },
          complete: () => {
            this.loading = false;
          },
        });
      }
    }

    editAction(event: any) {
      this.router.navigate([this.editLink + event.id]);
    }

    // sweet alert code start
    deleteCity(event: any){
      Swal.fire({
        title: 'Delete?',
        html: 'Are you sure want to delete the City?',
        imageUrl: "../../../../../assets/images/delete.svg",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn mat-secoundary',
          cancelButton: 'btn mat-warn ml-1',
        },
      }).then((result) => {
        if (result.value) {
          this.cityService.delete(event.id)
          .subscribe({
            next: (result: any) => {
              if (result.status === AppConstants.serverSuccessStatus) {
                this.commonService.showSuccessToast(
                  result.message
                );
              }else{
                this.commonService.showErrorToast(
                  result.message
                );
              }
              this.getAll();
            },
            error: (e: any) => {
              this.commonService.showErrorToast(
                'Something went wrong. Please contact to administrator.'
              );
            },
          });
        }
        if (result.dismiss === Swal.DismissReason.cancel) { }
      });
    }



  /**
   * Make unapproved records
   * @param id
   */
  makeUnapproved(event: any) {
    Swal.fire({
      title: 'Unapproved?',
      html: 'Are you sure want to unapproved the City?',
      imageUrl: '../../../../../assets/images/unapproved.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        let url = (
          `${environment.baseURL}` + 'dictionary/unapproved/city/'+event.id
        );
        this.cityService
          .makeUnapprovedDictionary(url)
          .subscribe((results: any) => {
            if (results.status === 1 || results.status === 200) {
              this.commonService.showSuccessToast(
                'City unapproved successfully'
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                "City can't be unapproved. Please try again later."
              );
            }
          });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }

  sortFilter(sortCity: any) {
    if (sortCity.active !== '' && sortCity.direction !== '') {
      this.sortField = sortCity.active;
      this.sortOrder = sortCity.direction;
    }else{
      this.sortField = 'id';
    }
    this.getAll();
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
