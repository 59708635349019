<div class="card-wrape user-activity-container m-b-0">
  <div class="card-head">
    <h4 class="left-text">Live Site</h4>
  </div>
  <div class="table-wrape">
  <div class="card-body">
    <mat-spinner *ngIf="latestSitesChartLoader"></mat-spinner>
    <div class="progress-overlay"></div>
      <div *ngIf="latestSites == '' && !latestSitesChartLoader; else foundLatestSiteChartData">
        <div class="no-data-found site_not_found">
          <div class="no-data-container">
            <div class="no-data-icon"></div>
            <div class="no-data-text">NO DATA FOUND</div>
          </div>
        </div>
      </div>
    <ng-template #foundLatestSiteChartData>
      <ul class="user-activity-list">
          <li *ngIf="!latestSitesChartLoader">
            <div class="latest-site-code">
              <div class="title">Site Code</div>
            </div>
            <div class="latest-site-name">
              <div class="title">Site Name</div>
            </div>
            <div class="latest-site-admin">
              <div class="title">Site Admin</div>
            </div>
            <div class="latest-site-count">
              <div class="title">Participant Count</div>
            </div>
          </li>
          <li *ngFor="let latestSite of latestSites">
            <div class="latest-site-code">
              <div class="value">
                <a (click)="gotSite(latestSite.id)" title="{{ latestSite.site_code }}" class="value">
                  <div *ngIf="latestSite.is_active_site == true" class="active-site-circle"
                    title="{{ latestSite.site_code }}"></div>
                  {{ latestSite.site_code }}
                </a>
              </div>
            </div>
            <div class="latest-site-name">
              <div class="value"> {{ latestSite.site_name }}</div>
            </div>
            <div class="latest-site-admin">
              <div class="value">
                {{ latestSite.site_admin_name }}
              </div>
            </div>
            <div class="latest-site-count">
              <div class="value">{{ latestSite.participant_counts }}</div>
            </div>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>