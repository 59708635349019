import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { OccupationsService } from 'src/app/service/occupations.service';
import { AppConstants } from 'src/app/app.constant';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { CommonService } from '../../../../../../service/common.service';

@Component({
  selector: 'app-occupations-form',
  templateUrl: './occupations-form.component.html',
  styleUrls: ['./occupations-form.component.scss']
})
export class OccupationsFormComponent implements OnInit {

  public occupationForm!: FormGroup;
  public title: any;
  public occupationId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/dictionary-management/occupations";
  public userId: any = "";
  public disableSubmitBtn: boolean = false;
  public isApproved: number = 1;
  public selectMapOption: boolean = false;
  public isCurate: boolean = false;
  public showLoading: boolean = false;
  public occupationNameMinLength: number = 3;
  public occupationDetails: any = [];
  public occupationList: any[] = [];

  constructor(
    private occupationService: OccupationsService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.occupationId = this.route.snapshot.params['id'];
    if (this.occupationId != 0 && this.occupationId != null) {
      this.setEditData();
      this.title = "Edit occupation";
      this.buttonText = "Save";
    } else {
      this.title = "Add occupation";
      this.buttonText = "Add";
      this.occupationId = 0;
    }
  }

  ngOnInit(): void {
    this.isAddMode = !this.occupationId;
    const routerSubscription = this.route.queryParams.subscribe((queryParams: any) => {
      if (this.router.url.indexOf('curation-pending') >= 0) {
        this.isCurate = true;
        this.title = "Curate occupation";
        this.cancelLink = '/dictionary-management/occupations/curation-pending';
        // Get occupation Dictionary
        this.getOccupationList();
      }
    });

    this.userId = localStorage.getItem("userId");
    this.occupationForm = this.formBuilder.group({
      'name': ['', [Validators.required, Validators.minLength(this.occupationNameMinLength), Validators.maxLength(255)]],
      'isApproved': [this.isApproved, []],
      'mapWithOtherOccupation': [null, []]
    });
  }


  /**
  * Get all occupations for validate occupation
  * @returns object
  */
  getOccupationList() {
    this.showLoading = true;
    this.occupationService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.occupationList = result.data;
          this.showLoading = false;
        },
        error: (e) => {
          this.showLoading = false;
          console.log('error from get occupation list', e.error);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        },
      });
  }

  setEditData() {
    this.showLoading = true;
    this.occupationService.getRow(this.occupationId)
      .subscribe({
        next: (result: any) => {
          if (result.status != AppConstants.serverSuccessStatus) {
            this.commonService.showErrorToast('Occupation not found.');
            this.router.navigate([this.cancelLink]);
          } else {
            this.occupationDetails = result['data'];
            this.occupationForm.patchValue({
              name: this.occupationDetails.name,
            });
          }
          this.showLoading = false;
        },
        error: (e: any) => {
          this.showLoading = false;
          console.log(e.error);
        },
      });
  }

  /**
   * Check occupation field is existed or not
   * @returns null
   */
  checkUniqueOccupationFields(occupationFieldName: string, event: any) {
    const occupationFieldValue = (event.target as HTMLInputElement).value;
    if (occupationFieldValue.length > 255) {
      return false;
    }

    switch (occupationFieldName) {
      case "name":
        if (occupationFieldValue.length < this.occupationNameMinLength) {
          return false;
        }
        break;
    }

    if (occupationFieldValue !== "") {
      this.occupationService.checkUniqueOccupationFields(occupationFieldName, occupationFieldValue, this.occupationId)
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.setUniqueError(occupationFieldName, false);
            } else {
              this.setUniqueError(occupationFieldName, true);
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.setUniqueError(occupationFieldName, true);
            } else {
              console.log(
                'error from check unique occupation', error
              );
            }
          }
        });
    }
    return null;
  }

  /**
  * set unique field error 
  * @returns null
  */
  setUniqueError(occupationFieldName: string, isSetError: boolean): void {
    if (isSetError) {
      switch (occupationFieldName) {
        case "name":
          this.occupationForm
            .get("name")!
            .setErrors({ unique: "Name already exists" });
          break;
      }
    } else {
      switch (occupationFieldName) {
        case "name":
          this.occupationForm.get("name")!.setErrors(null);
          break;
      }
    }
  }


  getOccupationNameError() {
    if (this.occupationForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.occupationForm.controls['name'].errors!['minlength']) {
      return 'Min Length is ' + this.occupationNameMinLength + ' characters.';
    }
    if (this.occupationForm.controls['name'].errors!['maxlength']) {
      return 'Max Length is 255 characters.';
    }
    if (this.occupationForm.controls['name'].errors!['unique']) {
      return this.occupationForm.get("name")!.errors!['unique'];
    }
    return '';
  }

  /**
   * Select Approved Option
   *
   * @returns object
   */
  selectOption(value: boolean) {
    this.selectMapOption = value;
    if (value) {
      this.occupationForm.get("mapWithOtherOccupation")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
    } else {
      this.occupationForm.patchValue({
        mapWithOtherOccupation: null,
      });
      this.occupationForm.get('mapWithOtherOccupation')!.setValidators(null);
    }
    this.occupationForm.controls['mapWithOtherOccupation'].updateValueAndValidity()
    this.getMapWithOccupationError();
  }

  getMapWithOccupationError() {
    if (this.occupationForm.controls['mapWithOtherOccupation'].invalid) {
      if (this.occupationForm.controls['mapWithOtherOccupation'].errors!['required']) {
        return 'Select occupation';
      }
      if (this.occupationForm.controls['mapWithOtherOccupation'].errors!['pattern']) {
        return 'Please select valid occupation';
      }
    }
    return '';
  }

  mapWithOtherOccupationChange(event: any) {
    if (event && event.id) {
      this.occupationService.getRow(event.id)
        .subscribe({
          next: (result: any) => {
            if (result.status == AppConstants.serverSuccessStatus && result.data) {
              setTimeout(() => {
                this.occupationForm.patchValue({
                });
              }, 200);
            }
          },
          error: (e: any) => {
            console.log(
              e.error
            );
          },
        });
    } else {
      this.occupationForm.patchValue({});
    }
  }

  /**
  * submit form
  * @returns null
  */
  onSubmit(): void {
    if (this.occupationForm.invalid) {
      this.commonService.validateAllFormFields(this.occupationForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      return;
    }
    // this.disableSubmitBtn = true;
    console.log(this.occupationForm);
    const formData: FormData = new FormData();
    formData.append('name', this.occupationForm.controls['name'].value);
    formData.append('is_approved', this.occupationForm.controls['isApproved'].value);
    formData.append('map_with_other_occupation', (this.occupationForm.controls['mapWithOtherOccupation'].value) ? this.occupationForm.controls['mapWithOtherOccupation'].value : 0);

    if (this.isAddMode) {
      this.createOccupation(formData);
    } else {
      this.updateOccupation(formData);
    }
  }

  /**
   * call Create occupation api
   * @returns null
   */
  private createOccupation(formData: any): void {
    this.showLoading = true;
    this.occupationService.store(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/dictionary-management/occupations"]);
            this.commonService.showSuccessToast(result.message);
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
          this.showLoading = false;
        },
        error: (err) => {
          this.showLoading = false;
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        }
      })
  }

  /**
   * call Update occupation api
   * @returns null
   */
  private updateOccupation(formData: any): void {
    this.showLoading = true;
    formData.append('updated_by', this.userId);
    this.occupationService.update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(result.message);
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(result.message);
          }
          this.showLoading = false;
        },
        error: (err) => {
          this.showLoading = false;
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        }
      })
  }

}
