<section class="my-profile-wrape">
	<div class="page-title-box">
		<h1> Participant Disease Data Validation Statistics of Last Three Days </h1>
		<ul class="bradcrumb">
			<li>Participant Disease Data Validation</li>
			<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
		</ul>
	</div>

	<section class="container-fluid">
		<div class="row">
			<div class="col-xl-12 col-md-12">
				<div class="stat-wrape four">
					<div class="stat-pad">
						<p>VALIDATED DISEASE</p>
						<h2>
							<i class="theme-icon participant-enroll"></i>
							{{ lastThreeDaysData.reclassified_snomed_counts }}
						</h2>
					</div>
					<div class="stat-pad">
						<p>NON RECLASSIFIABLE DISEASE</p>
						<h2><i class="theme-icon camp-held"></i>
							{{ lastThreeDaysData.non_reclassifiable_snomed_counts }}
						</h2>
					</div>
					<div class="stat-pad">
						<p>NON-VALIDATED DISEASE</p>
						<h2><i class="theme-icon couselor"></i> {{ lastThreeDaysData.recontact_snomed_counts }}</h2>
					</div>
					<div class="stat-pad">
						<p>TOTAL PIDs CURATED</p>
						<h2><i class="theme-icon couselor"></i> {{ lastThreeDaysData.total_pid_curated_counts }}</h2>
					</div>
				</div>


				<!-- table expand code start -->
				<div class="row">
					<div class="col-xl-12 col-md-12">
						<div class="card-wrape">
							<mat-spinner *ngIf="loading"></mat-spinner>
							<div class="progress-overlay"></div>
							<div class="card-head">
								<h4 class="left-text">Disease Validation: <span
										class="filter-text">{{selectedStage}}</span></h4>
								<button mat-raised-button class="btn" color="secoundary"
									*ngIf="isQcedOrReviewed === 'PENDING_REVIEW' && currentMenuPermission?.edit == true" style="margin: 0 5px 5px 10px;"
									(click)="updateQcReviewedSnomed('reviewed')">
									Mark as Reviewed
								</button>

								<div class="right-control">
									<div class="table-search-wrape side-info">
										<span class="icon"></span>
										<input (keyup)="searchFilter($event)" placeholder="Search" #input
											class="form-control" type="text">
										<div class="info-pad popup-over">
											<button class="info-icon"></button>
											<div class="info-section popupcontent">
												Sr.No., New PID, New Recollection ID, Site Code, First Name, Middle
												Name, Last Name, Gender, Collection Date, Occupation Type
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="card-body table-section">
								<div class="table-wrape">
									<table mat-table [dataSource]="dataSources" multiTemplateDataRows
										class="mat-elevation-z8" #expandSort="matSort" matSort
										(matSortChange)="SortFilter($event)">
										<ng-container matColumnDef="expand">
											<th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
											<td mat-cell *matCellDef="let element">
												<button mat-icon-button aria-label="expand row"
													(click)="getSnomedDataById(element)"
													(click)="(expandedElement = expandedElement === element ? undefined : element); $event.stopPropagation()">
													<mat-icon
														*ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
													<mat-icon
														*ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
												</button>
											</td>
										</ng-container>
										<!-- Checkbox Column -->
										<ng-container matColumnDef="select">
											<th mat-header-cell *matHeaderCellDef
												[ngClass]="isQcedOrReviewed === 'PENDING_REVIEW' ? 'enableTableform' : 'disableTableform'">
												<mat-checkbox (change)="$event ? masterToggle() : null"
													[checked]="selectedCheckboxParticipants.hasValue() && isAllSelected()"
													[indeterminate]="selectedCheckboxParticipants.hasValue() && !isAllSelected()">
												</mat-checkbox>
											</th>
											<td mat-cell *matCellDef="let element"
												[ngClass]="isQcedOrReviewed === 'PENDING_REVIEW' ? 'enableTableform' : 'disableTableform'">
												<mat-checkbox (click)="$event.stopPropagation()"
													(change)="$event ? selectedCheckboxParticipants.toggle(element) : null"
													[checked]="selectedCheckboxParticipants.isSelected(element)">
												</mat-checkbox>
											</td>
										</ng-container>
										<ng-container matColumnDef="id">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Sr.No.</th>
											<td mat-cell *matCellDef="let element">{{element.id}}</td>
										</ng-container>
										<ng-container matColumnDef="new_pid">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>New PID</th>
											<td mat-cell *matCellDef="let element">{{element.new_pid}}</td>
										</ng-container>
										<ng-container matColumnDef="new_recollection_id">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>New Recollection ID
											</th>
											<td mat-cell *matCellDef="let element">{{element.new_recollection_id}}</td>
										</ng-container>
										<ng-container matColumnDef="site_code">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Site Code</th>
											<td mat-cell *matCellDef="let element">{{element.site_code}}</td>
										</ng-container>
										<ng-container matColumnDef="first_name">
											<th mat-header-cell *matHeaderCellDef>First Name</th>
											<td mat-cell *matCellDef="let element">{{element.first_name}}</td>
										</ng-container>
										<ng-container matColumnDef="middle_name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Middle Name</th>
											<td mat-cell *matCellDef="let element">{{element.middle_name}}</td>
										</ng-container>
										<ng-container matColumnDef="last_name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
											<td mat-cell *matCellDef="let element">{{element.last_name}}</td>
										</ng-container>
										<ng-container matColumnDef="gender">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
											<td mat-cell *matCellDef="let element">{{element.gender | titlecase}}</td>
										</ng-container>
										<ng-container matColumnDef="collection_date">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Collection Date</th>
											<td mat-cell *matCellDef="let element">{{element.collection_date}}</td>
										</ng-container>
										<ng-container matColumnDef="occupation_type">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Occupation Type</th>
											<td mat-cell *matCellDef="let element">{{element.occupation_type}}</td>
										</ng-container>

										<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
										<ng-container matColumnDef="expandedDetail">
											<td mat-cell *matCellDef="let element"
												[attr.colspan]="columnsToDisplayWithExpand.length">
												<div class="example-element-detail"
													[@detailExpand]="isExpanded(element)">
													<div class="example-element-position"> </div>
													<div class="inner-bg-gray">
														<div class="col-xl-6">
															<div class="row-view">
																<p>
																	<b>Participant Disease - Organ/Site</b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let medicalHistory of snomedDetail[element.id].participant_medical_history; let lastIndex = last ">
																			<ng-container
																				*ngIf=" medicalHistory.diseases">
																				{{ medicalHistory.diseases.name }} -
																				<ng-container
																					*ngIf="medicalHistory.diseases && medicalHistory.diseases.organ_sites && medicalHistory.diseases.organ_sites.length > 0">
																					{{
																					medicalHistory.diseases.organ_sites[0].name
																					}}
																				</ng-container>
																			</ng-container>
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
																<p>
																	<b>Participant Disease - Disease Name</b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let medicalHistory of snomedDetail[element.id].participant_medical_history; let lastIndex = last ">
																			<ng-container
																				*ngIf="medicalHistory.diseases">
																				{{ medicalHistory.diseases.name
																				}}
																			</ng-container>
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
																<p>
																	<b>SNOMED ID</b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let medicalHistory of snomedDetail[element.id].participant_medical_history; let lastIndex = last ">
																			<ng-container
																				*ngIf="medicalHistory.diseases">
																				{{ medicalHistory.diseases.name }} - {{
																				medicalHistory.diseases.snomed_id }}
																			</ng-container>
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
																<p>
																	<b>Participant Surgeries/Biopsies/Procedures Type -
																		Year</b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container *ngFor=" let medicalHistory of snomedDetail[element.id].past_surgical_history; let lastIndex = last
                                                                          ">
																			{{ medicalHistory.surgery.type_of_surgery }}
																			-
																			{{ medicalHistory.year_of_surgery }}
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
															</div>
														</div>
														<div class="col-xl-6">
															<div class="row-view">
																<p>
																	<b>Family Member </b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let familyInformation of snomedDetail[element.id].family_diseases?.details; let lastIndex = last">
																			<ng-container
																				*ngIf="familyInformation.family_member">
																				{{
																				familyInformation.family_member |
																				titlecase
																				}}
																				<ng-container *ngIf="!lastIndex">,
																				</ng-container>
																			</ng-container>
																		</ng-container>
																	</span>
																</p>
																<p>
																	<b>Family Disease - Name of Disease </b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let familyInformation of snomedDetail[element.id].family_diseases?.details; let lastIndex = last">
																			<ng-container
																				*ngIf="familyInformation.family_member">
																				{{
																				familyInformation.family_member|
																				titlecase
																				}}
																			</ng-container>
																			<ng-container
																				*ngIf="familyInformation.disease_names">
																				-
																				<ng-container
																					*ngFor="let familyInformationDiseasesName of familyInformation.disease_names">
																					{{
																					familyInformationDiseasesName
																					}}
																				</ng-container>
																			</ng-container>
																			<ng-container
																				*ngIf="familyInformation.is_other_diseases_names">
																				- Other Diseases -
																				<ng-container
																					*ngFor="let familyInformationOtherDiseasesName of familyInformation.is_other_diseases_names">
																					{{
																					familyInformationOtherDiseasesName
																					}}
																				</ng-container>
																			</ng-container>
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
																<p>
																	<b>Participant Disease - Year Diagnosed </b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let medicalHistory of snomedDetail[element.id].participant_medical_history; let lastIndex = last ">
																			<ng-container
																				*ngIf="medicalHistory.diseases">
																				{{ medicalHistory.diseases.name
																				}}
																			</ng-container>
																			-
																			{{ medicalHistory.year_diagnosed }}
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
																<p>
																	<b>Participant - Medication Name </b>
																	<span *ngIf="snomedDetail[element.id]">
																		<ng-container
																			*ngFor=" let medicalHistory of snomedDetail[element.id].participant_medications; let lastIndex = last ">
																			{{ medicalHistory.medication_name }}
																			<ng-container
																				*ngIf="!lastIndex">,</ng-container>
																		</ng-container>
																	</span>
																</p>
															</div>
														</div>

														<div class="form-area">
															<form [formGroup]="deriveParticipantSnomedForm"
																#form="ngForm">
																<mat-spinner *ngIf="snomedFormLoading"></mat-spinner>
																<div class="head-part"
																	*ngIf="isQcedOrReviewed !== 'VALIDATED_DISEASE'">
																	<h2>Disease Derivation Edit Section </h2>
																	<div class="icons-wrape" *ngIf="currentMenuPermission?.edit == true">
																		<button type="button"
																			(click)="clickEventTableForm(element)"
																			*ngIf="!element.editing" class="edit-icon"
																			title="Edit"></button>
																		<button (click)="cancelEventTableForm(element)"
																			*ngIf="element.editing" class="delete-icon"
																			title="Cancel"></button>
																		<button (click)="saveSnomedDeriveData(element)"
																			*ngIf="element.editing" class="save-icon"
																			title="Save"></button>
																	</div>
																</div>
																<div class="form-pad"
																	[ngClass]="element.editing ? 'enableTableform' : 'disableTableform'"
																	formArrayName="derive_participant_snomed">
																	<div class="row"
																		*ngFor=" let deriveParticipantSnomedControl of deriveParticipantSnomedFormArray.controls; let totalDerivedDisease = count; let i = index; let last = last "
																		[formGroupName]="i" id="snomedArray_{{ i }}">
																		<div class="col-xl-6 col-md-6">
																			<div class="input-wrape"
																				[ngClass]="{ 'has-error': deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_name')?.invalid && (deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_name')?.dirty || deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_name')?.touched)}">
																				<mat-label>
																					{{ deriveParticipantSnomedControl.get("disease_name")?.value }}
																				</mat-label>
																				<div class="control-pad">
																					<ng-select appendTo="body"
																						[items]="mapWithSnomed"
																						bindValue="snomed_name"
																						class="hidearrow"
																						bindLabel="snomed_name"
																						placeholder="Select SNOMED name"
																						[loading]="recordLoadingDisease"
																						formControlName="derived_snomed_name"
																						(change)="diseaseDetails($event,i)"></ng-select>
																					<span
																						*ngIf="deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_name')?.invalid &&
                                            deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_name')?.errors &&
																						(deriveParticipantSnomedFormArray .controls[i].get('derived_snomed_name')?.touched ||
                                              deriveParticipantSnomedFormArray .controls[i].get('derived_snomed_name')?.dirty || derivedSnomedFormSubmitted)">
																						<mat-error
																							*ngIf="deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_name')?.errors">
																							Enter SNOMED name
																						</mat-error>
																					</span>
																				</div>
																			</div>
																		</div>
																		<div class="col-xl-6 col-md-6">
																			<div class="input-wrape"
																				[ngClass]="{ 'has-error': deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_id')?.invalid && (deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_id')?.dirty || deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_id')?.touched)}">
																				<mat-label>{{
																					deriveParticipantSnomedControl.get("derived_snomed_name")?.value
																					}} - {{
																					deriveParticipantSnomedControl.get("derived_snomed_id")?.value
																					}}
																				</mat-label>
																				<mat-form-field appearance="outline"
																					class="disabled">
																					<input matInput type="text"
																						placeholder="* Derived SNOMED ID for derived disease"
																						formControlName="derived_snomed_id"
																						readonly />
																				</mat-form-field>
																				<span
																					*ngIf="deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_id')?.invalid &&
                                            deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_id')?.errors &&
																						(deriveParticipantSnomedFormArray .controls[i].get('derived_snomed_id')?.touched ||
                                              deriveParticipantSnomedFormArray .controls[i].get('derived_snomed_id')?.dirty || derivedSnomedFormSubmitted)">
																					<mat-error
																						*ngIf="deriveParticipantSnomedFormArray.controls[i].get('derived_snomed_id')?.errors">
																						Enter SNOMED ID
																					</mat-error>
																				</span>
																			</div>
																		</div>
																		<div class="col-xl-6 col-md-6">
																			<div class="input-wrape"
																				[ngClass]="{ 'has-error': deriveParticipantSnomedFormArray.controls[i].get('derived_flag')?.invalid && (deriveParticipantSnomedFormArray.controls[i].get('derived_flag')?.dirty || deriveParticipantSnomedFormArray.controls[i].get('derived_flag')?.touched)}">
																				<mat-label>{{
																					deriveParticipantSnomedControl.get(
																					"derived_snomed_name" )?.value }} -
																					{{
																					deriveParticipantSnomedControl.get(
																					"derived_snomed_id" )?.value
																					}}</mat-label>
																				<div class="control-pad">
																					<ng-select
																						[items]="reclasifiedItems"
																						formControlName="derived_flag"
																						appendTo="body"
																						[searchable]="false"
																						[multiple]="false"
																						[closeOnSelect]="true"
																						placeholder="Select participant data validation Status"
																						bindLabel="value" bindValue="id"
																						[clearable]="isClearable">
																					</ng-select>
																					<span
																						*ngIf="deriveParticipantSnomedFormArray.controls[i].get('derived_flag')?.invalid &&
                                            deriveParticipantSnomedFormArray.controls[i].get('derived_flag')?.errors &&
																						(deriveParticipantSnomedFormArray .controls[i].get('derived_flag')?.touched ||
                                              deriveParticipantSnomedFormArray .controls[i].get('derived_flag')?.dirty || derivedSnomedFormSubmitted)">
																						<mat-error
																							*ngIf="deriveParticipantSnomedFormArray.controls[i].get('derived_flag')?.errors">
																							Select participant data
																							validation Status
																						</mat-error>
																					</span>
																				</div>
																			</div>
																		</div>
																		<div class="col-xl-6 col-md-6"
																			*ngIf="snomedFilterForm.controls['curation_stage'].value === 'PENDING_REVIEW'">
																			<div class="input-wrape">
																				<mat-label>Reviewed</mat-label>
																				<mat-checkbox-groups
																					class="checkbox-section">
																					<mat-checkbox class="example-margin"
																						formControlName="is_reviewed">
																						Reviewed</mat-checkbox>
																				</mat-checkbox-groups>
																			</div>
																		</div>
																	</div>
																</div>
															</form>
														</div>
													</div>
												</div>
											</td>
										</ng-container>
										<tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
										<tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
											class="example-element-row" (click)="getSnomedDataById(element)"
											[class.example-expanded-row]="expandedElement === element"
											(click)="expandedElement = expandedElement === element ? undefined : element">
										</tr>
										<tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
											class="example-element-row example-detail-row"></tr>
										<tr mat-row>
											<td *matNoDataRow colspan="11">{{matNoDataRowTitle}}</td>
										</tr>
									</table>
								</div>
								<mat-paginator #expandpagination [length]="totalRows" [pageIndex]="currentPage"
									[pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
									(page)="pageChanged($event)" aria-label="Select page" showFirstLastButtons>
								</mat-paginator>
							</div>
						</div>
					</div>
				</div>
				<!-- table expand code end -->
			</div>
		</div>
	</section>
</section>
<section class="filter-wrape toggle-close" [ngClass]="filterStatus ? 'toggle-open' : 'toggle-close'">
	<button class="btn filter-btn" (click)="clickEvent()"><i></i></button>
	<div class="filter-section">
		<div class="title">
			<h4 class="title-main"> SNOMED Filter </h4>
			<button class="close-btn" (click)="overLayClose()">×</button>
		</div>
		<form [formGroup]="snomedFilterForm" #form="ngForm" (ngSubmit)="filterSnomedData()">
			<ngx-simplebar class="sidebar-slider">
				<div class="sidebar-content">
					<div class="scroll-area sidebar-slider">
						<div class="input-wrape">
							<mat-label>Stage of Curation</mat-label>
							<div class="control-pad">
								<ng-select formControlName="curation_stage" [items]="curationStages"
									[dropdownPosition]="'bottom'" [searchable]="false" [multiple]="false"
									[closeOnSelect]="true" [clearable]="false" placeholder="Select stage of curation"
									class="form-group" bindLabel="value" bindValue="id">
								</ng-select>
							</div>
						</div>
						<div class="input-wrape">
							<mat-label>Disease Name Filter</mat-label>
							<div class="control-pad">
								<ng-select formControlName="diseaseId" [items]="diseaseList"
									[dropdownPosition]="'bottom'" [searchable]="true" [multiple]="false"
									[closeOnSelect]="true" placeholder="Select disease" class="form-group"
									bindLabel="name" bindValue="id">
								</ng-select>
							</div>
						</div>
						<div class="input-wrape">
							<mat-checkbox-groups class="checkbox-section">
								<mat-checkbox class="example-margin" formControlName="isSingleDisease">Single Disease
									Search?</mat-checkbox>
							</mat-checkbox-groups>
						</div>
					</div>
				</div>
			</ngx-simplebar>
			<div class="footer-btn">
				<button mat-raised-button class="btn" color="warn" style="margin: 0 5px 5px 0;"
					(click)="clearForm(form)">Clear All</button>
				<button type="submit" mat-raised-button class="btn" color="secoundary"
					style="margin: 0 5px 5px 0;">Submit</button>
			</div>
		</form>
	</div>
</section>
<div class="filter-overlay"
	style="position: fixed; top: 0; left: 0; background: rgba(0, 0, 0, 0.2); width: 100%; height: 100%; z-index: 12">
</div> <!-- (click)="overLayClose()" -->
