import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { QCTrackLogService } from 'src/app/service/qc-track-logs.service';

@Component({
  selector: 'app-qc-track-logs',
  templateUrl: './qc-track-logs.component.html',
  styleUrls: ['./qc-track-logs.component.scss']
})
export class QcTrackLogsComponent implements OnInit {

 
  public userId: any = 0;
  public showLoading: boolean = false;
  public itemsPerPage: number = 20;
  public currentPage: number = 1; 
  public totalRecords: number = 0; 
  public logs: any = [];
  public userList: any = [];
  

  constructor(
    private qCTrackLogService: QCTrackLogService,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getList();
    this.getUserList();
  }

  getList() {
    if(!this.showLoading){
        this.showLoading = true;
        this.qCTrackLogService.getQcTrackLogs(this.userId, this.currentPage)
        .subscribe({
        next: (result: any) => {
        if (result.status != AppConstants.serverSuccessStatus) {
          this.commonService.showErrorToast('Logs not found.');
        }else if(result.data && result.data.data.list){
          this.logs = result.data.data.list;
          this.currentPage = result.data.current_page;
          this.totalRecords = result.data.total;
          this.showLoading = false;
        }
      },
        error: (e: any) => {
          console.log(
            e.error
          );
          this.commonService.showErrorToast('Something went wrong, please try again later.');
          this.showLoading = false;
        },
      });
    }
  }
  
  changeUser(event: any){
    console.log(event, 'this is event id');
    this.userId = event ? event.id : 0;
    this.currentPage = 1;
    this.getList();
  }

  getUserList(){
    this.qCTrackLogService.getQcTrackLogUsers()
    .subscribe({
      next: (result: any) => {
      if (result.status != AppConstants.serverSuccessStatus) {
        this.commonService.showErrorToast('Users not found.');
      }else if(result.data){
        this.userList = result.data;
      }
    },
      error: (e: any) => {
        console.log(
          e.error
        );
      },
    });
  }

  loadMore() {
    this.currentPage++;
    this.getList();
  }

  goToPrevious(){
    this.currentPage--;
    this.getList();
  }

  goToFirst(){
    this.currentPage = 1;
    this.getList();
  }

  displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.logs.slice(startIndex, endIndex);
  }

}
