<div class="card-head">
    <h4 class="left-text"> {{pageTitle}} List</h4>
    <div class="right-control">
        <div class="table-search-wrape side-info" *ngIf="isFilterable">
            <span class="icon"></span>
            <input (keyup)="emitSearchFilter($event)" placeholder="Search" #input class="form-control" type="text">
            <div class="info-pad popup-over">
                <button class="info-icon"></button>
                <div class="info-section popupcontent">
                    {{filterColumns}}
                </div>
            </div>
        </div>
        <button class="primary btn" *ngIf="!isCurate && currentMenuPermission?.create == true && !isMatrix" mat-raised-button color="secoundary" routerLink="add">Add {{pageTitle}}</button>
        <button (click)="emitSyncCohortDMData()" [disabled]="disableSyncBtn" class="primary btn" *ngIf="isMatrixParticipant" mat-raised-button color="secoundary"> Sync DM Cohort Data</button>
        <a [href]="emitExportOrangeDmCohort()" *ngIf="isMatrix" mat-raised-button class="custom-btn"> Export Orange DM Cohort</a>
    </div>
</div>
<div class="card-body table-section">
    <div class="table-wrape">
        <!-- Table -->
        <mat-table [dataSource]="tableDataSource" matSort (matSortChange)="sortTable($event)">
            <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.name">

                <!-- if sortable column header -->
                <ng-container *ngIf="tableColumn.isSortable; else notSortable">
                    <mat-header-cell *matHeaderCellDef [mat-sort-header]="tableColumn.name">
                        {{tableColumn.name}}
                    </mat-header-cell>
                </ng-container>
                <!-- else not sortable -->
                <ng-template #notSortable>
                    <mat-header-cell *matHeaderCellDef>
                        {{tableColumn.name}}
                    </mat-header-cell>
                </ng-template>

                <!-- column data -->
                <ng-container *ngIf="tableColumn.is_relation; else notRelation">
                    <mat-cell *matCellDef="let element">
                        {{ getRelationValue(element,tableColumn) }}
                    </mat-cell>
                </ng-container>
                <ng-template #notRelation>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngIf="['participant_count','family_count','participant_origin_count','participant_residential_count'].indexOf(tableColumn.dataKey) !== -1; else simpleText">
                            <a href="javascript:void(0);" *ngIf="tableColumn.cell(element).content > 0" (click)="emitDownloadParticipantData((element.medication_id ? element.medication_id : element.id), tableColumn.cell(element).keyName)"> {{(tableColumn.cell(element)=='null') ? "": tableColumn.cell(element).content }} </a>
                            <ng-container *ngIf="tableColumn.cell(element).content <= 0"> 0 </ng-container>
                        </ng-container>
                        <ng-template #simpleText>
                            {{(tableColumn.cell(element)=='null') ? "": tableColumn.cell(element) }}
                        </ng-template>
                    </mat-cell>
                </ng-template>
            </ng-container>
            <!-- action column -->
            <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
                <mat-header-cell *matHeaderCellDef class="action"> {{rowActionIcon | titlecase}} </mat-header-cell>
                <mat-cell *matCellDef="let element" class="action" [id]="rowActionIcon">
                    <div class="icons-wrape">
                        <a class="theme-icon unapproved-icon"
                            *ngIf="is_data_curation_access==true &&  isApproved == 1 && makeUnapproved.observers.length && currentMenuPermission?.edit == true"
                            title="Click to Unapproved {{pageTitle}}" (click)="emitMakeUnapproved(element)"></a>
                        <a class="theme-icon view-icon" *ngIf="is_data_curation_access==true &&  viewAction.observers.length && !isCurate && currentMenuPermission?.view == true"
                            title="View {{pageTitle}}" (click)="emitViewAction(element)"></a>
                        <a class="theme-icon view-icon" *ngIf="is_data_curation_access==true && onlyViewAction.observers.length && !isCurate && currentMenuPermission?.view == true && currentMenuPermission?.edit == false"
                            title="View {{pageTitle}}" (click)="emitOnlyViewAction(element)"></a>
                        <a class="theme-icon edit-icon" *ngIf="is_data_curation_access==true && editAction.observers.length && currentMenuPermission?.edit == true" title="Edit {{pageTitle}}"
                            (click)="emitEditAction(element)"></a>
                        <a class="theme-icon delete-icon" *ngIf="is_data_curation_access==true && deleteAction.observers.length && !isCurate && currentMenuPermission?.delete == true"
                            title="Delete {{pageTitle}}" (click)="emitDeleteAction(element)"></a>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <div *matNoDataRow>{{matNoDataRowTitle}}</div>
        </mat-table>
        <!-- Pagination -->
        <mat-paginator #paginator *ngIf="isPageable" [length]="totalRows" [pageIndex]="currentPage"
            [pageSize]="defaultPageSize" [pageSizeOptions]="paginationSizes" aria-label="Select page"
            (page)="emitPageChanged($event)" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>