import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-site-wise-participant-counts',
  templateUrl: './site-wise-participant-counts.component.html',
  styleUrls: ['./site-wise-participant-counts.component.scss']
})
export class SiteWiseParticipantCountsComponent implements OnInit {
  public ownFormData!: FormData;
  public percentageOrAbsoluteCounts: any[] = [
    {
      id: 2,
      value: "Absolute Counts",
    },
    {
      id: 1,
      value: "Percentage",
    },
  ];
  public totalSiteChartCounts: any = 0;
  public siteWisePercentOrCount: any = 2;
  public monthOrYear: any = "year";
  public sitesChartLoader: boolean = true;
  public options: any = {};

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getDashboardChartData("site_wise", "");
  }

  /**
    *
    * @param type
    * @param formData
    * @param isPercentSelected
    * @param monthOrYearFilter
    * @param isUpdate
    */
  getDashboardChartData(
    type: string,
    formData: any,
    isPercentSelected: any = 2,
    monthOrYearFilter = "year"
  ) {

    this.sitesChartLoader = true;
    this.dashboardService
      .getDashboardChartData(
        type,
        formData,
        monthOrYearFilter,
        isPercentSelected
      )
      .subscribe((result: any) => {
        this.totalSiteChartCounts = result.data.name.length;
        this.showSiteChart(
          result.data.name,
          result.data.value,
          isPercentSelected
        );
        this.sitesChartLoader = false;
      });
  }

  /**
   *
   * @param siteNameData
   * @param siteCountData
   * @param isPercentSelected
   */
  showSiteChart(siteNameData: any, siteCountData: any, isPercentSelected: any = 2) {
    this.options = {

      color: ['#598bff'],
      tooltip: {
        trigger: 'axis',
        extraCssText: 'z-index: 100',
        position: 'inside',
        axisPointer: {
          type: 'shadow'
        },

        formatter: function (params: any) {
          const colorSpan = (color: any) =>
            '<span style="display:inline-block; z-index: 100; margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';

          let showCountOrPercentValue: string;

          if (isPercentSelected === 1) {
            // showCountOrPercentValue =  (params[0].value / totalSites).toFixed(2)  + '%';
            showCountOrPercentValue = params[0].value.toFixed(2) + "%";
          } else {
            showCountOrPercentValue = params[0].value;
          }

          const xx = ` ${colorSpan(params[0].color)} ${params[0].name
            } \n <span class="badge badge-dark"> ${showCountOrPercentValue} </span> `;
          const rez = '<div style="padding:5px">' + xx + "</div>";

          return '<div class="echart-toolitp-container">' + rez + "</div>";
        },


      },
      toolbox: {
        itemSize: '20',
        showTitle: false,
        feature: {
          magicType: {
            type: ['bar', 'line'],
            title: {
              stack: 'Bar',
              tiled: 'Line'
            },
          },
          restore: {
            show: true,
            title: 'Restore',
          },

        }
      },
      grid: {
        left: 45,
        top: 40,
        right: 22,
        bottom: 35,
        containLabel: true,
      },
      legend: {
        bottom: 0,
        show: false,
        data: ['Age Group'],
        itemWidth: 13,
        itemHeight: 13,
        itemGap: 10,
        textStyle: {

          color: '#222b45',
          fontSize: 13,
        },
      },

      xAxis: [
        {
          type: 'category',
          data: siteNameData,
          name: 'Site Code',
          nameLocation: 'middle',
          nameGap: 35,
          nameTextStyle: {
            fontSize: 13,
            fontStyle: 'normal',
            color: '#222b45',
            fontWeight: '500',
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {

            },
          },
          axisLabel: {
            textStyle: {

              color: '#222b45',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Count of Participant',
          nameLocation: 'middle',
          nameGap: 40,
          nameTextStyle: {
            fontSize: 13,
            fontStyle: 'normal',
            color: '#222b45',
            fontWeight: '500',
          },
          axisLine: {
            lineStyle: {

            },
          },
          splitLine: {
            lineStyle: {

            },
          },
          axisLabel: {
            textStyle: {
              color: '#222b45',
            },
          },
        },
      ],
      series: [
        {
          name: 'Age Group',
          type: 'bar',
          barWidth: '60%',
          data: siteCountData,
        },
      ],
    };

    if (window.matchMedia("(max-width: 991px)").matches) {
      this.options.tooltip.position = ['35%', '30%'];
    }
  }

  /**
   *
   * @param chartType
   * @param isPercentSelected
   */
  showRecordsInPercentOrAbsoluteCount(
    chartType: string,
    isPercentSelected: any = 2
  ) {
    this.getDashboardChartData(
      chartType,
      this.ownFormData,
      isPercentSelected.id,
      this.monthOrYear
    );
  }

  /**
  *
  * @param isRemoveItemMethod
  */
  filterDashboardData(parentFormData: FormData) {
    this.ownFormData = parentFormData;
    this.getDashboardChartData("site_wise", parentFormData);
  }
}
