import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import Swal from 'sweetalert2';

export interface DiseaseTable {
  id: Number,
  disease_id: Number,
  disease_name: string,
  diagnose_age: string,
  diagnose_year: string,
  declared_through: string,
  action: any
}

let ELEMENT_DATA: DiseaseTable[] = [];

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-participant-disease-history',
  templateUrl: './disease-history.component.html',
  styleUrls: ['./disease-history.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    },
  ]
})
export class DiseaseHistoryComponent implements OnInit {

  @ViewChild('diagnose_year', { static: false })



  public participantID: any;
  public diseases: any = [];
  public disease_categories: any = [];
  public diseaseDetails: any = [];
  public diseaseForm!: FormGroup;
  public diseaseDetailForm!: FormGroup;


  public ckdForm!: FormGroup;
  public diabeteForm!: FormGroup;
  public hfForm!: FormGroup;
  public miForm!: FormGroup;
  public nafldForm!: FormGroup;

  public diseaseArray!: FormArray;
  public diseaseFormStatus: boolean = false;
  public diseaseUpdate: boolean = false;
  public diseaseAdd: boolean = false;
  public diseaseUpdateIndex: any;
  public newObject: any;
  public isdiseaseSubmit: boolean = false;

  public years: any = [];
  public months: any = [];
  public days: any = [];
  public infectionStatus: any = [];

  public participantDOB: any;
  public participantAge: any;
  public loading: boolean = false;
  public isNAFDCancer: boolean = false;
  public minDate: any;
  public maxDate: any;
  public minDiagnoseYear: any;
  public itBelongAnyFollowingDiseases: any;

  togglepopup: boolean = false;
  isViewDisease: boolean = false;

  @Input() currentMenuPermission: any;
  @Input() declared_through_options: any;


  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dictionaryService: DictionaryService,
    private formBuilder: FormBuilder
  ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  displayedColumns: string[] = ['disease_name', 'diagnose_age', 'diagnose_year', 'action'];
  public dataSource = new MatTableDataSource<DiseaseTable>(ELEMENT_DATA);

  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 150);

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 0);

    this.getDiseaseList();
    this.getDiseaseCategory();
    this.getHowlogs();
    this.getDiseases();

    this.diseaseForm = new FormGroup({});
    this.diseaseForm.setControl('option', new FormControl(null, [Validators.required]));
    this.diseaseForm.setControl('details', new FormArray([]));
    this.diseaseArray = <FormArray>this.diseaseForm.controls["details"];

    this.diseaseDetailForm = this.formBuilder.group({
      disease_id: [null, [Validators.required]],
      medical_history_id: [null, []],
      display_term: [null, []],
      disease_name: [null, []],
      declared_through: [null, []],
      diagnose_age: [null, []],
      diagnose_year: [null, []],
      disease_type_flag: [null, []],
      it_belong_any_following_diseases: [null, []],
    });

    this.diseaseDetailForm.controls['disease_id']?.valueChanges.subscribe(val => {
      // console.log(val);
      if (val === 0) {
        this.diseaseDetailForm.controls['it_belong_any_following_diseases'].setValidators([Validators.required]);
      } else {
        this.diseaseDetailForm.controls['it_belong_any_following_diseases'].clearValidators();
      }
      this.diseaseDetailForm.controls['it_belong_any_following_diseases'].updateValueAndValidity();
    });

    // console.log('diseaseDetailForm Length=', this.diseaseDetailForm.value)
    this.infectionStatus = JSON.parse(localStorage.getItem('constants') as string).INFECTION_STATUS;

  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to get Disease and set Value
   */
  getDiseases() {
    this.loading = true;
    this.participantService.getDiseases(this.participantID).subscribe({
      next: (result: any) => {
        this.diseaseDetails = result.data;
        this.diseaseForm.controls['option'].setValue(this.diseaseDetails?.option);
        this.dataSource = new MatTableDataSource(result.data.details);
        this.participantDOB = this.diseaseDetails?.date_of_birth;
        this.participantAge = this.diseaseDetails?.age;
        if ((this.participantDOB != '') && (this.participantDOB != null)) {
          const birthDate = new Date(this.participantDOB);
          const currentDate = new Date();
          const lastYearDate = new Date(currentDate.getFullYear() - 1);

          if (birthDate >= lastYearDate && birthDate <= currentDate) {
            let getFinalYear;
            if (birthDate.getFullYear() < currentDate.getFullYear()) {
              getFinalYear = birthDate.getFullYear();
            } else {
              getFinalYear = birthDate.getFullYear() - 1;
            }
            this.minDiagnoseYear = new Date(
              getFinalYear,
              birthDate.getMonth(),
              birthDate.getDate()
            );
          } else {
            this.minDiagnoseYear = new Date(
              birthDate.getFullYear(),
              birthDate.getMonth(),
              birthDate.getDate()
            );
          }
        } else {
          const currentDate = new Date();
          const participantAge = this.participantAge;
          if (participantAge === 0) {
            this.minDiagnoseYear = new Date(
              currentDate.setFullYear(currentDate.getFullYear() - 1)
            );
            // this.diagnosedAgeRanges = this.getAgeRanges(0);
            // console.log('0 vala', this.diagnosedAgeRanges);
          } else {
            this.minDiagnoseYear = new Date(
              currentDate.setFullYear(
                currentDate.getFullYear() - participantAge
              )
            );
            // this.diagnosedAgeRanges = this.getAgeRanges(participantAge);
            // console.log('more vala', this.diagnosedAgeRanges);
          }
        }
        this.loading = false;
        // console.log('minDiagnoseYear=', this.minDiagnoseYear);
      }, error: (e) => {
        this.loading = false;
        // console.log('Disease err :: ', e);
      }
    })
  }


  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to Open Medication and hide Update button
  */
  openDisease() {
    this.diseaseAdd = true;
    this.diseaseUpdate = false;
    this.togglepopup = !this.togglepopup;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function used to close and update value
   */
  closePopup() {
    this.togglepopup = !this.togglepopup;
    this.diseaseAdd = false;
    this.diseaseUpdate = false;
    this.diseaseUpdateIndex = null;
    this.diseaseDetailForm.reset();
  }

  /**
 * @author Pramod Pramod Kushwaha
 * This function is used to add Medication and set value
 */
  addDisease() {

    this.diseaseDetailForm.controls['disease_type_flag'].setValue(this.itBelongAnyFollowingDiseases);

    if (this.diseaseDetailForm.invalid) {
      // console.log(this.diseaseDetailForm);
      this.commonService.validateAllFormFields(this.diseaseDetailForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      // this.toastrService.danger("Please enter correct values", "OOPS!");
      return;
    }
    // console.log(this.diseaseDetailForm);

    let appendingTable = {
      'disease_id': this.diseaseDetailForm.controls['disease_id']?.value !== "" ? this.diseaseDetailForm.controls['disease_id']?.value : null,
      'display_term': this.diseaseDetailForm.controls['display_term']?.value !== "" ? this.diseaseDetailForm.controls['display_term']?.value : null,
      'disease_name': this.diseaseDetailForm.controls['disease_name']?.value !== "" ? this.diseaseDetailForm.controls['disease_name']?.value : null,
      'declared_through': this.diseaseDetailForm.controls['declared_through']?.value !== "" ? this.diseaseDetailForm.controls['declared_through']?.value : null,
      'diagnose_age': this.diseaseDetailForm.controls['diagnose_age']?.value !== "" ? this.diseaseDetailForm.controls['diagnose_age']?.value : null,
      'diagnose_year': this.diseaseDetailForm.controls['diagnose_year']?.value !== "" ? this.diseaseDetailForm.controls['diagnose_year']?.value : null,
      'disease_type_flag': this.diseaseDetailForm.controls['disease_type_flag']?.value !== "" ? this.diseaseDetailForm.controls['disease_type_flag']?.value : null,
      'it_belong_any_following_diseases': this.diseaseDetailForm.controls['it_belong_any_following_diseases']?.value !== "" ? this.diseaseDetailForm.controls['it_belong_any_following_diseases']?.value : null,
    };
    // console.log(this.diseaseDetails.details);

    this.diseaseDetails.details.push(appendingTable);

    // console.log("208",this.diseaseForm.controls['details']?.value,newFormGroup?.value,this.diseaseDetails.details);
    this.dataSource = new MatTableDataSource(this.diseaseDetails.details);
    this.togglepopup = !this.togglepopup;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to update Medication update form value
   */
  updateDisease() {
    this.diseaseDetails.details[this.diseaseUpdateIndex]['disease_id'] = this.diseaseDetailForm.controls['disease_id']?.value !== "" ? this.diseaseDetailForm.controls['disease_id']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['display_term'] = this.diseaseDetailForm.controls['display_term']?.value !== "" ? this.diseaseDetailForm.controls['display_term']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['disease_name'] = this.diseaseDetailForm.controls['disease_name']?.value !== "" ? this.diseaseDetailForm.controls['disease_name']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['declared_through'] = this.diseaseDetailForm.controls['declared_through']?.value !== "" ? this.diseaseDetailForm.controls['declared_through']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['diagnose_age'] = this.diseaseDetailForm.controls['diagnose_age']?.value !== "" ? this.diseaseDetailForm.controls['diagnose_age']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['diagnose_year'] = this.diseaseDetailForm.controls['diagnose_year']?.value !== "" ? this.diseaseDetailForm.controls['diagnose_year']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['disease_type_flag'] = this.diseaseDetailForm.controls['disease_type_flag']?.value !== "" ? this.diseaseDetailForm.controls['disease_type_flag']?.value : null;
    this.diseaseDetails.details[this.diseaseUpdateIndex]['it_belong_any_following_diseases'] = this.diseaseDetailForm.controls['it_belong_any_following_diseases']?.value !== "" ? this.diseaseDetailForm.controls['it_belong_any_following_diseases']?.value : null;

    this.diseaseDetailForm.controls['disease_type_flag'].setValue([null, undefined, ''].includes(this.itBelongAnyFollowingDiseases) ? this.diseaseDetails.details[this.diseaseUpdateIndex]['disease_type_flag'] : this.itBelongAnyFollowingDiseases);

    this.dataSource = new MatTableDataSource(this.diseaseDetails.details);
    this.togglepopup = !this.togglepopup;
  }

  /**
   * @author Pramod Pramod Kushwaha
   * This function is used to get updated value based on index
   * @param id
   */
  editDisease(id: any, medicalHistoryId: Number) {
    this.isViewDisease = false;
    // console.log(medicalHistoryId);

    this.editDiseaseDetails(id, medicalHistoryId)
  }

  viewDisease(id: any, medicalHistoryId: Number) {
    // console.log('from viewDisease :',id +' & '+ medicalHistoryId);
    this.isViewDisease = true;
    this.editDiseaseDetails(id, medicalHistoryId)
  }
  editDiseaseDetails(id: any, medicalHistoryId: Number) {
    this.diseaseUpdateIndex = id;
    this.diseaseUpdate = true;
    this.diseaseAdd = false;
    if (this.isViewDisease == false) {
      this.togglepopup = !this.togglepopup;
    }

    const disease_id = this.diseaseDetails.details[id]['disease_id'];
    const display_term = this.diseaseDetails.details[id]['display_term'];
    const disease_name = this.diseaseDetails.details[id]['disease_name'];
    const declared_through = this.diseaseDetails.details[id]['declared_through'];
    const diagnose_year = this.diseaseDetails.details[id]['diagnose_year'];
    const diagnose_age = this.diseaseDetails.details[id]['diagnose_age'];
    const disease_type_flag = this.diseaseDetails.details[id]['disease_type_flag'];
    const it_belong_any_following_diseases = this.diseaseDetails.details[id]['it_belong_any_following_diseases'];

    let isQueAlready = this.diseaseDetails['details'].some((disease: any) => disease.disease_type_flag === disease_type_flag);
    this.itBelongAnyFollowingDiseases = isQueAlready ? null : disease_type_flag;

    if (disease_id != 0 || disease_id != null || disease_id != '') {
      const keyExists =  this.diseases.some((item: any) => item.display_term.includes(disease_name));
        if(keyExists == false){
          this.diseases.push({ 'id': disease_id, 'display_term': disease_name });
          this.diseases = this.diseases.slice(0);
        }
    }
    // this.diseases.push();

    this.diseaseDetailForm.controls['disease_id'].setValue(disease_id);
    this.diseaseDetailForm.controls['display_term'].setValue(display_term);
    this.diseaseDetailForm.controls['disease_name'].setValue(disease_name);
    this.diseaseDetailForm.controls['declared_through'].setValue(declared_through);
    this.diseaseDetailForm.controls['diagnose_year'].setValue(diagnose_year);
    this.diseaseDetailForm.controls['diagnose_age'].setValue(diagnose_age);
    this.diseaseDetailForm.controls['disease_type_flag'].setValue((['', null, undefined].includes(disease_type_flag)) ? this.itBelongAnyFollowingDiseases : disease_type_flag);
    this.diseaseDetailForm.controls['it_belong_any_following_diseases'].setValue(it_belong_any_following_diseases);
    this.diseaseDetailForm.controls['medical_history_id'].setValue(medicalHistoryId);

    if ((this.participantDOB != '') && (this.participantDOB != null)) {
      const diagnosed = new Date(diagnose_year);
      const birthDate = new Date(this.participantDOB);
      const age = diagnosed.getFullYear() - birthDate.getFullYear();
      if (age > 0) {
        this.diseaseDetailForm.controls['diagnose_age'].setValue(age);
      }
    } else {
      const pmhCurrentDate = new Date();
      const birthYear = pmhCurrentDate.getFullYear() - this.participantAge;
      const age = diagnose_year - birthYear;
      if (age > 0) {
        this.diseaseDetailForm.controls['diagnose_age'].setValue(age);
      }
    }
  }

  diseaseSubmit() {
    this.isNAFDCancer = false;
    this.diseaseDetails['details'].forEach((disease: any) => {
      if (disease.disease_type_flag === 'CANCER') {
        this.isNAFDCancer = true;
      }
    });

    const diseaseForm = this.diseaseForm.get('details') as FormArray;

    if (this.diseaseDetailForm.controls['disease_type_flag'].value != 'CANCER') {
      this.diseaseDetailForm.setControl('CANCER', new FormArray([]));
    }
    if (this.diseaseDetailForm.controls['disease_type_flag'].value != 'CHRONIC_KIDNEY_DISEASE') {
      this.diseaseDetailForm.setControl('CHRONIC_KIDNEY_DISEASE', new FormArray([]));
    }
    if (this.diseaseDetailForm.controls['disease_type_flag'].value != 'DIABETES') {
      this.diseaseDetailForm.setControl('DIABETES', new FormArray([]));
    }
    if (this.diseaseDetailForm.controls['disease_type_flag'].value != 'HEART_FAILURE') {
      this.diseaseDetailForm.setControl('HEART_FAILURE', new FormArray([]));
    }
    if (this.diseaseDetailForm.controls['disease_type_flag'].value != 'MYOCARDIAL_INFARCTION') {
      this.diseaseDetailForm.setControl('MYOCARDIAL_INFARCTION', new FormArray([]));
    }
    if (this.diseaseDetailForm.controls['disease_type_flag'].value != 'NON_ALCOHOLIC_FATTY_LIVER_DISEASE') {
      this.diseaseDetailForm.setControl('NON_ALCOHOLIC_FATTY_LIVER_DISEASE', new FormArray([]));
    }
    if (this.diseaseDetailForm.controls['disease_type_flag'].value === 'NON_ALCOHOLIC_FATTY_LIVER_DISEASE' && this.isNAFDCancer) {
      this.diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.clearValidators();
    }
    this.diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_history_or_currently_suffering_from_cancer')?.updateValueAndValidity();
    if (this.diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('have_nafld_any_infection_in_past_week')?.value == 'no') {
      this.diseaseDetailForm.controls['NON_ALCOHOLIC_FATTY_LIVER_DISEASE'].get('what_type_of_infection_in_past_week')?.setValue(null);
    }
    if (this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.value == 'no') {
      this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_present_before_hf_diagnosis')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.value == 'no') {
      this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_shortness_of_breath_before_hf_diagnosis')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.value == 'no') {
      this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_before_hf_diagnosis')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.value == 'no') {
      this.diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_before_hf_diagnosis')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['DIABETES'].get('have_chronic_foot_ulcers')?.value == 'no') {
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_foot_ulcers')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_foot_ulcers_year')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_foot_ulcers_month')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_foot_ulcers_day')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['DIABETES'].get('have_chronic_non_healing_skin_wound')?.value == 'no') {
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_non_healing_skin_wound')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_non_healing_skin_wound_year')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_non_healing_skin_wound_month')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_have_chronic_non_healing_skin_wound_day')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['DIABETES'].get('have_numbness_tingling_burning_sensation')?.value == 'no') {
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_numbness_tingling_burning_sensation')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_numbness_tingling_burning_sensation_year')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_numbness_tingling_burning_sensation_month')?.setValue('');
      this.diseaseDetailForm.controls['DIABETES'].get('how_long_numbness_tingling_burning_sensation_day')?.setValue('');
    }
    if (this.diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('do_you_need_dialysis')?.value == 'no') {
      this.diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('how_long_do_you_need_dialysis')?.setValue('');
      this.diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('how_long_do_you_need_dialysis_year')?.setValue('');
      this.diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('how_long_do_you_need_dialysis_month')?.setValue('');
      this.diseaseDetailForm.controls['CHRONIC_KIDNEY_DISEASE'].get('how_long_do_you_need_dialysis_day')?.setValue('');
    }
    // console.log(this.diseaseForm,(this.diseaseForm.controls['option']?.value === 'yes'),this.diseaseForm.invalid,this.diseaseDetailForm.invalid);
    // return false;
    this.isdiseaseSubmit = true;
    if (this.diseaseDetailForm.invalid && (this.diseaseForm.controls['option']?.value === 'yes')) {
      this.commonService.validateAllFormFields(this.diseaseForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      return;
    }
    diseaseForm.push(this.diseaseDetailForm);
    this.loading = true;
    this.participantService.updateDiseases(this.diseaseForm.value, this.route.snapshot.paramMap.get('id')).subscribe({
      next: (result: any) => {
        // console.log(result);
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.diseaseDetails = result.data;
          this.dataSource = new MatTableDataSource(result.data.details);
          this.diseaseUpdateIndex = null;
          this.diseaseUpdate = false;
          this.diseaseAdd = false;
          this.isViewDisease = false;
          this.diseaseDetailForm.reset();
          this.diseaseForm.setControl('details', new FormArray([]));
          this.diseaseDetailForm.setControl('CANCER', new FormArray([]));
          this.diseaseDetailForm.setControl('CHRONIC_KIDNEY_DISEASE', new FormArray([]));
          this.diseaseDetailForm.setControl('DIABETES', new FormArray([]));
          this.diseaseDetailForm.setControl('HEART_FAILURE', new FormArray([]));
          this.diseaseDetailForm.setControl('MYOCARDIAL_INFARCTION', new FormArray([]));
          this.diseaseDetailForm.setControl('NON_ALCOHOLIC_FATTY_LIVER_DISEASE', new FormArray([]));
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => { },
    });

  }

  /**
   * @author Pramod Pramod Kushwaha
   * Enable form edit
   */
  clickOnEdit() {
    this.diseaseFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close
  */
  clickOnCancel() {
    this.diseaseFormStatus = false;
    this.diseaseAdd = false;
    this.diseaseUpdate = false;
    this.diseaseUpdateIndex = null;
    this.isViewDisease = false;
    this.diseaseDetailForm.reset();
    this.diseaseForm.setControl('details', new FormArray([]));
    this.diseaseDetailForm.setControl('CANCER', new FormArray([]));
    this.diseaseDetailForm.setControl('CHRONIC_KIDNEY_DISEASE', new FormArray([]));
    this.diseaseDetailForm.setControl('DIABETES', new FormArray([]));
    this.diseaseDetailForm.setControl('HEART_FAILURE', new FormArray([]));
    this.diseaseDetailForm.setControl('MYOCARDIAL_INFARCTION', new FormArray([]));
    this.diseaseDetailForm.setControl('NON_ALCOHOLIC_FATTY_LIVER_DISEASE', new FormArray([]));
  }

  /**
 * @author Pramod Pramod Kushwaha
 * This function is used to reset Form
 * @param option
 */
  resetForm(option: string) {
    // console.log(option);
    this.diseaseForm.setControl('details', new FormArray([]));
    this.dataSource = new MatTableDataSource(this.diseaseForm.controls['details'].value);

    if (option == 'yes') {
      this.diseaseAdd = true;
      this.togglepopup = !this.togglepopup;
    } else {
      this.diseaseUpdate = true;
    }
    this.diseaseUpdateIndex = null;
    this.diseaseDetailForm.reset();
  }

  /**
   * Date Picker year heandler
   * @param ev
   * @param dp
   */
  chosenYearHandler(ev: any, dp: any) {
    this.diseaseDetailForm.controls['diagnose_year'].setValue(ev);
    const calAge = this.ageFromDiagnosedYear(ev._d, this.participantDOB, this.participantAge);
    // console.log(ev, ev._d, this.participantDOB, this.participantAge, calAge);
    this.diseaseDetailForm.controls['diagnose_age'].setValue(Number(calAge));
    this.diseaseDetailForm.controls['diagnose_year'].setValidators(null);
    this.diseaseDetailForm.controls['diagnose_age'].setValidators(null);
    this.diseaseDetailForm.controls['diagnose_year'].updateValueAndValidity();
    this.diseaseDetailForm.controls['diagnose_age'].updateValueAndValidity();
    dp.close()
  }

  /**
   * This function is used to get disease list
   */
  getDiseaseList() {
    this.dictionaryService.getDiseaseList().subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.diseases = result.data;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
   * This function is used to get disease list
   */
  getDiseaseCategory() {
    this.dictionaryService.getDiseaseCategorList().subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.disease_categories = result.data;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
   * This function is used to get disease list
   */
  getHowlogs() {
    this.dictionaryService.getHowLongList().subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.years = result.data.years;
          this.months = result.data.months;
          this.days = result.data.days;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  addTagFn(name: any) {
    return { display_term: name, id: 0 };
  }

  diseaseSelect(event: any) {
    // console.log(event);
    if (event === undefined) {
      this.itBelongAnyFollowingDiseases = null;
      // this.diseaseDetailForm.controls["disease_type_flag"].setValue(null);
    }
    if ((event != '') && (event != null)) {
      let displayTerm = ((event.display_term != '') && (event.display_term != null)) ? event.display_term : event.name;
      this.diseaseDetailForm.controls['display_term'].setValue(displayTerm);
      this.diseaseDetailForm.controls['disease_name'].setValue(displayTerm);
      let isQueAlready = this.diseaseDetails['details'].some((disease: any) => disease.disease_type_flag === event.disease_category);
      this.itBelongAnyFollowingDiseases = isQueAlready ? null : event.disease_category;
      // this.diseaseDetailForm.controls['disease_type_flag'].setValue(event.disease_category);
    }
  }

  diseaseCustomSelect(event: any) {
    if (event === undefined) {
      this.diseaseDetailForm.controls["it_belong_any_following_diseases"].setValue(null);
      // this.diseaseDetailForm.controls['disease_type_flag'].setValue(null);
      this.itBelongAnyFollowingDiseases = null;
    }
    if ((event != '') && (event != null)) {
      this.diseaseDetailForm.controls['it_belong_any_following_diseases'].setValue(event.disease_category);
      let isQueAlready = this.diseaseDetails['details'].some((disease: any) => disease.disease_type_flag === event.disease_category);
      this.itBelongAnyFollowingDiseases = isQueAlready ? null : event.disease_category;
      // this.diseaseDetailForm.controls['disease_type_flag'].setValue(event.disease_category);
    }
  }

  /**
 * @author Pramod Pramod Kushwaha
 * @param field
 */
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }

  /**
   * @author Pramod Kushwaha
   * This funtion is used to submit Form Details
   * @param field
   */
  isFieldValid(field: string) {
    return (
      (this.diseaseDetailForm.controls[field].invalid && this.diseaseDetailForm.controls[field].dirty) ||
      (this.diseaseDetailForm.controls[field].touched && this.diseaseDetailForm.controls[field].invalid)
    );
  }

  getAgeRanges(count = 150) {
    const ages = [];
    for (let i = 0; i <= count; i++) {
      ages.push(i);
    }
    return ages;
  }

  /**
   * @author Pramod Kushwaha
   * This function is used to Custom Validate Values
   * @param index
   */
  ageOrDiagnosedValidator(event: any) {
    if (event === undefined) {
      this.diseaseDetailForm.controls["diagnose_year"].setValue(null);
    }
    const diagnoseAge =
      this.diseaseDetailForm.controls["diagnose_age"];
    const diagnoseYear =
      this.diseaseDetailForm.controls["diagnose_year"];
    if (diagnoseAge || diagnoseYear) {
      if ((diagnoseYear.value != null && diagnoseYear.value != '') || (diagnoseAge.value != null && diagnoseAge.value != '')) {
        // console.log(diagnoseAge.value, diagnoseYear.value, event);
        diagnoseYear.setValidators(null);
        diagnoseAge.setValidators(null);
      } else {
        diagnoseYear.setValidators(null);
        diagnoseAge.setValidators(null);
      }
      diagnoseAge.updateValueAndValidity();
      diagnoseYear.updateValueAndValidity();
    }
  }

  /**
   * @author Pramod Kushwaha
   * Calculate Year based on Selection
   * @param index
   */
  yearCalculationFromAge() {
    const diagnoseAge =
      this.diseaseDetailForm.controls["diagnose_age"];
    const diagnoseYear =
      this.diseaseDetailForm.controls["diagnose_year"];

    // console.log(diagnoseAge,diagnoseYear);

    if ((diagnoseAge.value != '') && (diagnoseAge.value != null)) {
      if (this.participantDOB != '') {
        const dateOfBirth = new Date(this.participantDOB);
        const finalYear = dateOfBirth.getFullYear() + Number(diagnoseAge.value);
        diagnoseYear.setValue(new Date(finalYear, 1, 0));
      } else {
        const CalAgeDate = new Date();
        const dateOfBirth =
          CalAgeDate.getFullYear() -
          Number(this.participantAge);
        const finalYear = dateOfBirth + Number(diagnoseAge.value);
        diagnoseYear.setValue(new Date(finalYear, 1, 0));
      }
    } else {
      diagnoseAge.setValue(null);
      diagnoseYear.setValue(null);
    }
  }

  ageFromDiagnosedYear(diagnosedYear: any, dateOfBirth: any, participantAge: any): number {
    const diagnosed = new Date(diagnosedYear);
    if ((dateOfBirth != '') && (dateOfBirth != null)) {
      const birthDate = new Date(dateOfBirth);

      const currentDate = new Date();
      const lastYearDate = new Date(currentDate.getFullYear() - 1);

      if (birthDate >= lastYearDate && birthDate <= currentDate) {
        let getFinalYear;
        if (birthDate.getFullYear() < currentDate.getFullYear()) {
          getFinalYear = birthDate.getFullYear();
        } else {
          getFinalYear = birthDate.getFullYear() - 1;
        }
        this.minDiagnoseYear = new Date(
          getFinalYear,
          birthDate.getMonth(),
          birthDate.getDate()
        );
      } else {
        this.minDiagnoseYear = new Date(
          birthDate.getFullYear(),
          birthDate.getMonth(),
          birthDate.getDate()
        );
      }

      const age = diagnosed.getFullYear() - birthDate.getFullYear();
      if (age < 0) {
        return 0;
      }
      return age;
    } else {
      const pmhCurrentDate = new Date();
      const birthYear = pmhCurrentDate.getFullYear() - participantAge;
      const age = diagnosed.getFullYear() - birthYear;
      if (age < 0) {
        return 0;
      }
      return age;
    }
  }

  deleteDiseaseHistory(disease_id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.participantService.deleteDiseaseHistory(disease_id).subscribe({
          next: (result: any) => {
            this.loading = false;
            if (result.status === 1) {
              this.commonService.showSuccessToast("Disease Record deleted successfully");
              this.diseaseDetails = result.data;
              this.dataSource = new MatTableDataSource(result.data.details);
              this.diseaseUpdateIndex = null;
              this.diseaseUpdate = false;
              this.diseaseAdd = false;
              this.isViewDisease = false;
              this.diseaseDetailForm.reset();
              this.diseaseDetailForm.setControl('CANCER', new FormArray([]));
              this.diseaseDetailForm.setControl('CHRONIC_KIDNEY_DISEASE', new FormArray([]));
              this.diseaseDetailForm.setControl('DIABETES', new FormArray([]));
              this.diseaseDetailForm.setControl('HEART_FAILURE', new FormArray([]));
              this.diseaseDetailForm.setControl('MYOCARDIAL_INFARCTION', new FormArray([]));
              this.diseaseDetailForm.setControl('NON_ALCOHOLIC_FATTY_LIVER_DISEASE', new FormArray([]));
            } else {
            }
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.showSuccessToast("Something went wrong. Please contact to administrator.");
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

}
