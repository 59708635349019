<div class="page-title-box">
  <h1>{{title}}</h1>
  <ul class="bradcrumb">
    <li>{{title}}</li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>
<section class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text"> Biobank and Sequencing Statistics Lists</h4>
        </div>
        <div class="card-body">
          <mat-spinner *ngIf="loading"></mat-spinner>
          <div class="progress-overlay"></div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Management Dashboard Metrics</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>Total QC Passing Bio/ Data Bank (includes exhausted samples)</label>
                  </td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].total_qc_passing_bio_bank">
                      <a (click)="downloadBioBank(year,'total_qc_passing_bio_bank')">
                      {{bioBankSequencingCounts[year] &&
                      bioBankSequencingCounts[year].total_qc_passing_bio_bank}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].total_qc_passing_bio_bank == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label> PIDs shipped for WES / WGS Sequencing</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_shipped_for_WES_WGS_sequencing">
                      <a (click)="downloadBioBank(year,'pids_shipped_for_WES_WGS_sequencing')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_shipped_for_WES_WGS_sequencing}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_shipped_for_WES_WGS_sequencing == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs sequenced (WES / WGS)</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].samples_sequenced">
                      <a (click)="downloadBioBank(year,'samples_sequenced')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].samples_sequenced}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].samples_sequenced == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Remnant DNA Available for Samples Already Shipped for Sequencing</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>DNA Samples Passing QC with current yield > = 6ug</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].remnant_dna_samples_passing_qc_with_current_yield_6ug">
                      <a (click)="downloadBioBank(year,'remnant_dna_samples_passing_qc_with_current_yield_6ug')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].remnant_dna_samples_passing_qc_with_current_yield_6ug}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].remnant_dna_samples_passing_qc_with_current_yield_6ug == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>DNA Samples Passing QC with 6> current yield > = 1.2 ug</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].remnant_dna_samples_passing_qc_with_6_current_yield_12ug">
                      <a (click)="downloadBioBank(year,'remnant_dna_samples_passing_qc_with_6_current_yield_12ug')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].remnant_dna_samples_passing_qc_with_6_current_yield_12ug}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].remnant_dna_samples_passing_qc_with_6_current_yield_12ug == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>DNA Available from Unsequenced Biobank Samples (Unshipped)</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>New Collections under processing in lab (last 1 month)</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].new_collections_under_processing_in_lab">
                      <a (click)="downloadBioBank(year,'new_collections_under_processing_in_lab')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].new_collections_under_processing_in_lab}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].new_collections_under_processing_in_lab == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>DNA Samples Passing QC with current yield > = 6ug</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].dna_samples_passing_qc_with_current_yield_6ug">
                      <a (click)="downloadBioBank(year,'dna_samples_passing_qc_with_current_yield_6ug')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].dna_samples_passing_qc_with_current_yield_6ug}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].dna_samples_passing_qc_with_current_yield_6ug == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>DNA Samples Passing QC with 6> current yield > = 1.2 ug</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].dna_samples_passing_qc_with_6_current_yield_12ug">
                      <a (click)="downloadBioBank(year,'dna_samples_passing_qc_with_6_current_yield_12ug')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].dna_samples_passing_qc_with_6_current_yield_12ug}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].dna_samples_passing_qc_with_6_current_yield_12ug == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Operational Metrics</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>Total Biobank on {{ currentDate | date:'dd MMM yyyy' }}</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].total_biobank_on_08_oct_2023">
                      <a (click)="downloadBioBank(year,'total_biobank_on_08_oct_2023')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].total_biobank_on_08_oct_2023}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].total_biobank_on_08_oct_2023 == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>Biobank Available for at least 1 Sequencing</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].biobank_available_for_at_least_1_sequencing">
                      <a (click)="downloadBioBank(year,'biobank_available_for_at_least_1_sequencing')">
                      {{bioBankSequencingCounts[year] &&
                      bioBankSequencingCounts[year].biobank_available_for_at_least_1_sequencing}}
                    </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].biobank_available_for_at_least_1_sequencing == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Advance Project QC Metrics (On entire biobank)</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>DNA Samples Passing QC with current yield > = 10ug</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].advance_project_dna_samples_passing_qc_with_current_yield_10ug">
                      <a
                        (click)="downloadBioBank(year,'advance_project_dna_samples_passing_qc_with_current_yield_10ug')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].advance_project_dna_samples_passing_qc_with_current_yield_10ug}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].advance_project_dna_samples_passing_qc_with_current_yield_10ug == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>DNA Samples Passing QC with 10> current yield > = 2 ug</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].advance_project_dna_samples_passing_qc_with_10_current_yield_2ug">
                      <a
                        (click)="downloadBioBank(year,'advance_project_dna_samples_passing_qc_with_10_current_yield_2ug')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].advance_project_dna_samples_passing_qc_with_10_current_yield_2ug}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].advance_project_dna_samples_passing_qc_with_10_current_yield_2ug == '0'">0</label>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>WES / WGS Metrics</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>PIDs shipped for WES / WGS Sequencing</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_shipped_for_sequencing">
                      <a (click)="downloadBioBank(year,'pids_shipped_for_sequencing')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_shipped_for_sequencing}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_shipped_for_sequencing == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs sequenced (WES / WGS)</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_sequenced">
                      <a (click)="downloadBioBank(year,'pids_sequenced')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_sequenced}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_sequenced == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs passed WES / WGS sequencing QC</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_passed_sequencing_qc">
                      <a (click)="downloadBioBank(year,'pids_passed_sequencing_qc')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_passed_sequencing_qc}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_passed_sequencing_qc == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs shipped and pending sequencing</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_shipped_and_pending_sequencing">
                      <a (click)="downloadBioBank(year,'pids_shipped_and_pending_sequencing')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_shipped_and_pending_sequencing}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_shipped_and_pending_sequencing == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs failed WES / WGS sequencing QC</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_failed_sequencing_qc">
                      <a (click)="downloadBioBank(year,'pids_failed_sequencing_qc')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_failed_sequencing_qc}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_failed_sequencing_qc == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs Failed Pre Sequencing</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_failed_pre_sequencing">
                      <a (click)="downloadBioBank(year,'pids_failed_pre_sequencing')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_failed_pre_sequencing}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_failed_pre_sequencing == '0'">0</label>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Sample Failure Metrics</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>DNA Samples with Lab Discrepancy (No data uploaded for more than 1
                      month)</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].dna_samples_with_lab_discrepancy">
                      <a (click)="downloadBioBank(year,'dna_samples_with_lab_discrepancy')">{{
                      bioBankSequencingCounts[year].dna_samples_with_lab_discrepancy }}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].dna_samples_with_lab_discrepancy == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>PIDs with no DNA collected or received in lab</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].pids_with_no_dna_collected_or_recieved_in_lab">
                      <a (click)="downloadBioBank(year,'pids_with_no_dna_collected_or_recieved_in_lab')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].pids_with_no_dna_collected_or_recieved_in_lab}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].pids_with_no_dna_collected_or_recieved_in_lab == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>DNA Samples Failing QC or exhausted in lab</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].dna_samples_failing_qc_or_exhausted_in_lab">
                      <a (click)="downloadBioBank(year,'dna_samples_failing_qc_or_exhausted_in_lab')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].dna_samples_failing_qc_or_exhausted_in_lab}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].dna_samples_failing_qc_or_exhausted_in_lab == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Y-STS Discrepancy</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label></label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].y_sts_discrepancy">
                      <a (click)="downloadBioBank(year,'y_sts_discrepancy')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].y_sts_discrepancy}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].y_sts_discrepancy == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ui-table-wrapper">
            <table class="table-wrapes ui-table">
              <thead class="ui-table-thead">
                <tr>
                  <th>Recollections</th>
                  <th *ngFor="let year of yearArray">{{year?year:'Grand Total'}}</th>
                </tr>
              </thead>
              <tbody class="ui-table-tbody">
                <tr>
                  <td class="info-panel"><label>Cases with recollections</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].cases_with_recollections">
                      <a (click)="downloadBioBank(year,'cases_with_recollections')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].cases_with_recollections}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].cases_with_recollections == '0'">0</label>
                  </td>
                </tr>
                <tr>
                  <td class="info-panel"><label>Instances of recollection</label></td>
                  <td class="info-panel" *ngFor="let year of yearArray">
                    <label
                      *ngIf="bioBankSequencingCounts[year] && bioBankSequencingCounts[year].instances_of_recollection">
                      <a (click)="downloadBioBank(year,'instances_of_recollection')">
                        {{bioBankSequencingCounts[year] &&
                        bioBankSequencingCounts[year].instances_of_recollection}}
                      </a>
                    </label>
                    <label
                      *ngIf="!bioBankSequencingCounts[year] || bioBankSequencingCounts[year].instances_of_recollection == '0'">0</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
