import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { PhysiologicalMeasurementValidationService } from 'src/app/service/physiological-measurement.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MenuService } from 'src/app/service/menu.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-physiological-measurements-validation',
  templateUrl: './physiological-measurements-validation.component.html',
  styleUrls: ['./physiological-measurements-validation.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PhysiologicalMeasurementsValidationComponent implements OnInit {

  public title: string = 'Physiological Measurements Validation';
  public lastThreeDaysData: any = [];
  public measurementsValidationFilters: any = [
    { label: 'Pending', value: 'pending' },
    { label: 'No Change', value: 'no_change' },
    { label: 'Value Updated', value: 'value_updated' },
  ];

  public flag: any = [
    { label: 'No Change', value: 'NO_CHANGE' },
    { label: 'Value Updated', value: 'VALUE_UPDATED' },
  ];
  public reclassifiedPMFilters: any;
  public selectedMeasurementsValidationFilter: string = 'pending';
  public selectedStage = 'pending';
  public dataSources = new MatTableDataSource<MeasurementTable>(ELEMENT_DATA);
  public expandedElement: MeasurementTable | undefined;
  public columnsToDisplay =
    ['participant_id',
      'new_pid',
      'new_recollection_id',
      'site_code',
      'first_name',
      'height',
      'weight',
      'high_blood_pressure_systolic',
      'low_blood_pressure_diastolic',
    ];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public tableData: any = {}
  public loading: boolean = true;
  public totalRecord: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  selectedParticipants = new SelectionModel<MeasurementTable>(true, []);
  public matNoDataRowTitle: string = 'Loading Data...';
  public currentMenuPermission: any;

  constructor(
    private PhysiologicalMeasurementValidationService: PhysiologicalMeasurementValidationService,
    private commonService: CommonService,
    private menuService: MenuService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });

    this.getValidationStats();
    this.getAllMeasurementValidations();
  }


  /**
   * It will search Participant
   * @param event
   */
  getAllMeasurementValidations() {
    this.matNoDataRowTitle = 'Loading Data...';
    this.selectedParticipants = new SelectionModel<MeasurementTable>(true, []);
    let pm_flags = '';
    let siteCodes = '';

    if (this.reclassifiedPMFilters !== undefined) {
      pm_flags = this.reclassifiedPMFilters.value;
    }

    this.loading = true;
    this.PhysiologicalMeasurementValidationService.getAllPMValidations(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      pm_flags,
      siteCodes
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        this.dataSources = new MatTableDataSource(result.data.data);
        this.totalRecord = result.data.data.length;
        this.dataSources.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = result.data.total;
          
        });
        if (this.dataSources != undefined && this.dataSources.data.length == 0) {
          this.matNoDataRowTitle = 'No Record Found';
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }


  isAllSelected() {
    const numSelected = this.selectedParticipants.selected.length;
    const numRows = this.dataSources.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selectedParticipants.clear() :
      this.dataSources.data.forEach(row => this.selectedParticipants.select(row));
  }


  /**
   * Get Reclassification Stats
   *
   * @returns object
   */
  getValidationStats() {
    this.PhysiologicalMeasurementValidationService.getPMValidationStatistics()
      .subscribe({
        next: (result: any) => {
          this.lastThreeDaysData = result.data;
        }
      });
  }

  /**
  * Get filtered values
  * @param event
  */
  changePMFilter(event: any) {
    if (event != undefined) {
      this.selectedStage = event.value;
      this.reclassifiedPMFilters = event;
    } else {
      this.selectedStage = '';
      this.reclassifiedPMFilters = '';
    }
    this.getAllMeasurementValidations();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAllMeasurementValidations();
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllMeasurementValidations();
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    this.getAllMeasurementValidations();
  }

  clickEventTableForm(event: any) {
    this.dataSources.data.find(res => {
      if (res.participant_id == event.participant_id) {
        res.editing = true;
      }
    })
  }

  cancelEvent(event: any) {
    this.dataSources.data.find(res => {
      if (res.participant_id == event.participant_id) {
        res.editing = false;
      }
    })
  }

  saveEvent(measurementValidations: any) {
    if (measurementValidations.participant_id !== '') {
      if (!measurementValidations.derived_status) {
        this.commonService.showErrorToast('Please select Validated Physiological Measurements');
        return;
      }
      if (!measurementValidations.derived_status) {
        this.commonService.showErrorToast('Please select Flag');
        return;
      }

      this.loading = true;
      this.PhysiologicalMeasurementValidationService.updatePMValidationStatus(measurementValidations).subscribe({
        next: (result: any) => {
          this.loading = false;
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast('Data has been updated successfully');
            this.getValidationStats();
            this.getAllMeasurementValidations();
          } else {
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: () => {
          this.loading = false;
          this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
        }
      });
    } else {
      this.commonService.showErrorToast('Something went wrong. Please contact to administrator.');
    }
  }

  changeFlag(ele: any) {
    // console.log(ele.derived_status);
    if ((ele.derived_status == "VALUE_UPDATED") || (ele.derived_status == "NO_CHANGE")) {
      setTimeout(() => {
        ele.derived_height = ele.height;
        ele.derived_weight = ele.weight;
        ele.derived_high_blood_pressure_systolic = ele.high_blood_pressure_systolic;
        ele.derived_low_blood_pressure_diastolic = ele.low_blood_pressure_diastolic;
      }, 0);
    } else {
      setTimeout(() => {
        ele.derived_height = null;
        ele.derived_weight = null;
        ele.derived_high_blood_pressure_systolic = null;
        ele.derived_low_blood_pressure_diastolic = null;
      }, 0);
    }
  }

  changeNullHeight(ele: any) {
    if (ele.mark_as_null_height == true) {
      ele.derived_height = null;
    } else {
      ele.derived_height = ele.height;
    }    
  }

  changeNullWeight(ele: any) {
    if (ele.mark_as_null_weight == true) {
      ele.derived_weight = null;
    } else {
      ele.derived_weight = ele.weight;
    }
  }

  changeNullHighBP(ele:any){
    if (ele.mark_as_null_high_blood_pressure_systolic == true) {
      ele.derived_high_blood_pressure_systolic = null;
    } else {
      ele.derived_high_blood_pressure_systolic = ele.high_blood_pressure_systolic;
    }
  }

  changeNullLowBP(ele:any){
    if (ele.mark_as_null_low_blood_pressure_diastolic == true) {
      ele.derived_low_blood_pressure_diastolic = null;
    } else {
      ele.derived_low_blood_pressure_diastolic = ele.low_blood_pressure_diastolic;
    }
  }

  openDialog(report: any) {
    this.dialog.open(DialogEmaple, { data: { image: report.path } });
  }
}
export interface MeasurementTable {
  // select: any;
  participant_id: any;
  editing: boolean;
  new_pid: string;
  new_recollection_id: string;
  site_code: string;
  first_name: string;
  height: string;
  weight: string;
  high_blood_pressure_systolic: string;
  low_blood_pressure_diastolic: string;
  derived_height: number;
}
// dialog code start
@Component({
  selector: 'dialogEmaple',
  templateUrl: 'dialog-emaple.html',
})
export class DialogEmaple {
  image: string = '../../../../../../assets/images/patient-pic-1.jpg';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogEmaple>
  ) {
    if (data.image != undefined && data.image != '') {
      this.image = data.image;
    }
  }
  onClose(): void {
    this.dialogRef.close(true);
  }
}
const ELEMENT_DATA: MeasurementTable[] = [];
