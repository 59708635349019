import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private datePipe: DatePipe) { }


  showErrorToast(message: string) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: 'Warning!',
      text: message,
      icon: 'warning',
    });
  }
  showSuccessToast(message: string) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: 'success!',
      text: message,
      icon: 'success',
    });
  }

  /**
   * Validate all form
   * @param formGroup
   * @author Dhaval Bera
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
      this.showErrorToast("Required fields must be filled up.!!");
    });
  }

  /**
   * Highlights error field
   *
   * @returns object
   */
  displayFieldCss(form: FormGroup, field: string) {
    return {
      'has-error': this.isFieldValid(form, field),
      'has-feedback': this.isFieldValid(form, field),
    };
  }

  /**
   * Highlights error field
   *
   * @returns object
   */
  isFieldValid(form: FormGroup, field: string) {
    return (
      form.controls[field].invalid &&
      (form.controls[field].dirty || form.controls[field].touched)
    );
  }

  /*
   * @param date
   */
  transformDate(date: string) {
    if (date != null) {
      return this.datePipe.transform(date.toString(), 'yyyy-MM-dd');
    }
    return;
  }

  // Only Integer Numbers
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * It will remove white space
   * @param control
   * @author Dhaval Bera
   */
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }

  /**
   * This function is used get Age of Participant
   * @param id
   */
  getAge() {
    const age = [{ id: 0, value: "<1" }];
    let ageIncrement;

    for (let i = 1; i <= 150; i++) {
      ageIncrement = i;
      age.push({ id: i, value: ageIncrement.toString() });
    }
    // age.push({id: 101, value: '100+'});

    return age;
  }

  ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  isNullOrUndefined(value: any) {
    if (value === undefined || value === null) {
      return true
    }
    return false;
  }


  validateFieldBasedOnDisease(fieldName: any, form: any, validations: any, reset = false) {
    if (!reset) {
      form.controls[fieldName].setValidators(validations);
      form.controls[fieldName].updateValueAndValidity();
    } else {
      form.controls[fieldName].clearValidators();
      form.controls[fieldName].updateValueAndValidity();
    }
  }
}
