<div class="tabbing-content-wrape">
  <form [class]="statusTableform ? 'edit-mode' : 'view-mode'">
    <div class="container-fluid">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text"> Participant Documents</h4>
        </div>
        <div class="card-body">
          <mat-spinner *ngIf="loading"></mat-spinner>
          <div class="progress-overlay"></div>
          <div class="row" *ngIf="albums.length>0">
            <div class="col-xl-3 col-md-3" *ngFor="let report of albums; index as i" id="staticImage_{{ i }}">
              <!-- <h6>{{report | json}}</h6> -->
              <div class="input-wrape">
                <mat-label>Document {{ i + 1 }}</mat-label>
                <div class="img-wrape pmr_pdf icon-wrape-preview thumb-upload">
                  <div *ngIf="currentMenuPermission.edit != true;else other_content">
                    <span mat-button *ngIf="report.fileType.toLowerCase() != 'pdf'" appNoRightClick>
                      <img src="{{ report.thumb }}"  alt="Participant Document" class="grid_img">
                    </span>
                    <span mat-button *ngIf="report.fileType.toLowerCase() == 'pdf'" appNoRightClick>
                      <!-- <a href=""> -->
                        <div mat-button class="icon-wrape-preview choosen_file_div">
                          <span class="icon pdf"></span>
                          <div class="upload-wrape"></div>
                        </div>
                      <!-- </a> -->
                    </span>
                  </div>
                  <ng-template #other_content>
                    <span mat-button (click)="openDialog(report)" *ngIf="report.fileType.toLowerCase() != 'pdf'">
                      <img src="{{ report.thumb }}" alt="Participant Document" class="grid_img">
                    </span>
                    <span mat-button *ngIf="report.fileType.toLowerCase() == 'pdf'">
                      <a href="{{report.path}}" target="_blank">
                        <div mat-button class="icon-wrape-preview choosen_file_div">
                          <span class="icon pdf"></span>
                          <div class="upload-wrape"></div>
                        </div>
                      </a>
                      <a href="javascript:;" class="theme-icon download-icon participant-docs"
                        title="Download Participant Document" (click)="downloadMedicalRecordDoc(report)"></a>
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="no-data-found" *ngIf="albums.length<=0">
            <div class="no-data-container">
              <div class="no-data-icon"></div>
              <div class="no-data-text">Participant Documents not found</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
