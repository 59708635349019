<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="{{cancelLink}}" title="Medicine Brand" class="only-link">Medicine
                Brand</a>
        </li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <mat-spinner *ngIf="loading"></mat-spinner>
        <div class="progress-overlay"></div>
        <div class="col-xl-8 col-md-12 col-sm-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">{{title}}</h4>
                </div>
                <form [formGroup]="tempBrandGenericForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit()">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['medicineName'].invalid && (tempBrandGenericForm.controls['medicineName'].dirty || tempBrandGenericForm.controls['medicineName'].touched)}">
                                    <mat-label><i>*</i> Medicine Brand Name</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput #input placeholder="Enter medicine brand name" type="text"
                                            formControlName="medicineName">
                                    </mat-form-field>
                                    <span
                                        *ngIf="tempBrandGenericForm.controls['medicineName'].invalid && (tempBrandGenericForm.controls['medicineName'].dirty || tempBrandGenericForm.controls['medicineName'].touched)">
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['medicineName'].errors?.['required']">
                                            Medicine name is required
                                        </mat-error>
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['medicineName'].errors?.['unique']">
                                            {{ tempBrandGenericForm.controls['medicineName'].errors?.['unique'] }}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['medicineName'].errors?.['minlength']">
                                            Min Length is {{nameMinLength}} characters.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['medicineName'].errors?.['maxlength']">
                                            Max Length is 255 characters.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['medicineName'].errors?.['validField']">
                                            {{tempBrandGenericForm.controls['medicineName'].errors?.['validField']}}
                                        </mat-error>
                                    </span>
                                </div>

                                <div class="input-wrape">
                                    <mat-label><i>*</i> Is Approved?:</mat-label>
                                    <mat-radio-group aria-label="Select an option"
                                        class="example-radio-group no-padding" formControlName="isApproved">
                                        <mat-radio-button class="example-radio-button" id="radio_yes"
                                            (click)="selectOption(1)" value="1" checked>
                                            Approved</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" id="radio_no"
                                            (click)="selectOption(0)" value="0">
                                            Map with others</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="input-wrape" *ngIf="selectMapOption == 1">
                                    <mat-label><i>*</i> Is Drug Brand/Drug Generic?:</mat-label>
                                    <mat-radio-group aria-label="Select an option"
                                        class="example-radio-group no-padding" formControlName="isBrandOrGeneric">
                                        <mat-radio-button class="example-radio-button" id="isBrandOrGeneric_Brand"
                                            (click)="changeMedicationType('brand')" value="brand" checked>
                                            Drug Brand</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" id="isBrandOrGeneric_Generic"
                                            (click)="changeMedicationType('generic')" value="generic">
                                            Drug Generic</mat-radio-button>
                                        <mat-radio-button class="example-radio-button" id="isBrandOrGeneric_Category"
                                            (click)="changeMedicationType('category')" value="category">
                                            Drug Category</mat-radio-button>
                                    </mat-radio-group>
                                    <span
                                        *ngIf="tempBrandGenericForm.controls['isBrandOrGeneric'].errors && (tempBrandGenericForm.controls['isBrandOrGeneric'].touched)">
                                        <mat-error *ngIf="tempBrandGenericForm.controls['isBrandOrGeneric'].invalid">
                                            Select Brand/Generic/Category Drug
                                        </mat-error>
                                    </span>
                                </div>
                                <div class="input-wrape" *ngIf="selectMapOption == 0">
                                    <mat-label><i>*</i> Map with Brand/Drug Generic?:</mat-label>
                                    <mat-radio-group aria-label="Select an option"
                                        class="example-radio-group no-padding" formControlName="mapWithBrandOrGeneric">
                                        <mat-radio-button class="example-radio-button" id="mapWithBrandOrGeneric_Brand"
                                            (click)="changeMapWith('brand')" value="brand" checked>
                                            Drug Brand</mat-radio-button>
                                        <mat-radio-button class="example-radio-button"
                                            id="mapWithBrandOrGeneric_Generic" (click)="changeMapWith('generic')"
                                            value="generic">
                                            Drug Generic</mat-radio-button>
                                        <mat-radio-button class="example-radio-button"
                                            id="mapWithBrandOrGeneric_Category" (click)="changeMapWith('category')"
                                            value="category">
                                            Drug Category</mat-radio-button>
                                    </mat-radio-group>
                                    <span
                                        *ngIf="tempBrandGenericForm.controls['mapWithBrandOrGeneric'].errors && (tempBrandGenericForm.controls['mapWithBrandOrGeneric'].touched)">
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['mapWithBrandOrGeneric'].invalid">
                                            Select Brand / Generic / Category Drug
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape" *ngIf="selectMapOption == 0 && mapWithBrand == 'brand'"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['mapWithBrands'].invalid && (tempBrandGenericForm.controls['mapWithBrands'].dirty || tempBrandGenericForm.controls['mapWithBrands'].touched)}"
                                >
                                    <mat-label><i>*</i> Map with Brand</mat-label>                                    
                                    <div class="control-pad">
                                        <ng-select [items]="brandList" formControlName="mapWithBrands"
                                            bindLabel="brand_name" bindValue="id" labelForId="mapWithBrands"
                                            placeholder="Select brand" (search)="getBrand($event)">
                                        </ng-select>
                                        <span *ngIf="tempBrandGenericForm.controls['mapWithBrands'].errors">
                                            <mat-error
                                                *ngIf="tempBrandGenericForm.controls['mapWithBrands'].errors?.['required']">
                                                Please Select Drug Brand
                                            </mat-error>
                                        </span>
                                    </div>
                                </div>
                                <div class="input-wrape" *ngIf="selectMapOption == 0 && mapWithBrand == 'generic'"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['mapWithGeneric'].invalid && (tempBrandGenericForm.controls['mapWithGeneric'].dirty || tempBrandGenericForm.controls['mapWithGeneric'].touched)}"
                                >
                                    <mat-label><i>*</i> Map with Generic</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="genericList" formControlName="mapWithGeneric"
                                            bindLabel="generic_name" bindValue="id" labelForId="mapWithGeneric"
                                            placeholder="Select generic" (search)="getGeneric($event)">
                                        </ng-select>
                                        <span *ngIf="tempBrandGenericForm.controls['mapWithGeneric'].errors">
                                            <mat-error
                                                *ngIf="tempBrandGenericForm.controls['mapWithGeneric'].errors?.['required']">
                                                Please Select Drug Generic
                                            </mat-error>
                                        </span>
                                    </div>
                                </div>
                                <div class="input-wrape" *ngIf="selectMapOption == 0 && mapWithBrand == 'category'"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['mapWithCategories'].invalid && (tempBrandGenericForm.controls['mapWithCategories'].dirty || tempBrandGenericForm.controls['mapWithCategories'].touched)}"
                                >
                                    <mat-label><i>*</i> Map with category</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="categoryList" formControlName="mapWithCategories"
                                            bindLabel="category_name" bindValue="id" labelForId="mapWithCategories"
                                            placeholder="Select category" (search)="getMedicineCategory($event)">
                                        </ng-select>
                                        <span *ngIf="tempBrandGenericForm.controls['mapWithCategories'].errors">
                                            <mat-error
                                                *ngIf="tempBrandGenericForm.controls['mapWithCategories'].errors?.['required']">
                                                Please Select Drug Category
                                            </mat-error>
                                        </span>
                                    </div>
                                </div>

                                <div class="input-wrape" *ngIf="isBrand == 'generic' && approvedDictionary"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['category'].invalid && (tempBrandGenericForm.controls['category'].dirty || tempBrandGenericForm.controls['category'].touched)}">
                                    <mat-label>Category</mat-label>
                                    <div class="control-pad">
                                        <ng-select [items]="categoryList" formControlName="category" [multiple]="true"
                                            bindLabel="category_name" bindValue="id" labelForId="category"
                                            placeholder="Select category" (search)="getMedicineCategory($event)">
                                        </ng-select>
                                        <span *ngIf="tempBrandGenericForm.controls['category'].errors">
                                            <mat-error
                                                *ngIf="tempBrandGenericForm.controls['category'].errors?.['required']">
                                                Please Select Drug Category
                                            </mat-error>
                                        </span>
                                    </div>
                                    <span
                                        *ngIf="tempBrandGenericForm.controls['category'].invalid && (tempBrandGenericForm.controls['category'].dirty || tempBrandGenericForm.controls['category'].touched)">
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['category'].errors?.['required']">
                                            Please Category is required
                                        </mat-error>
                                        <mat-error
                                            *ngIf="tempBrandGenericForm.controls['category'].errors?.['validField']">
                                            {{tempBrandGenericForm.controls['category'].errors?.['validField']}}
                                        </mat-error>
                                    </span>
                                </div>
                                <div class="input-wrape" *ngIf="approvedDictionary"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['disease'].invalid && (tempBrandGenericForm.controls['disease'].dirty || tempBrandGenericForm.controls['disease'].touched)}"
                                >
                                    <mat-label>SNOMED ID</mat-label>

                                    <div class="control-pad">
                                        <ng-select [items]="snomedIdList" formControlName="disease" [multiple]="true"
                                            bindLabel="snomed_id" labelForId="disease"
                                            placeholder="Select SNOMED ID" (search)="getDisease($event)">
                                        </ng-select>
                                        <mat-error *ngIf="tempBrandGenericForm.controls['disease'].errors?.['validField']">
                                            {{tempBrandGenericForm.controls['disease'].errors?.['validField']}}
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="input-wrape" *ngIf="isBrand == 'generic' && approvedDictionary"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['rxcuiId'].invalid && (tempBrandGenericForm.controls['rxcuiId'].dirty || tempBrandGenericForm.controls['rxcuiId'].touched)}"
                                >
                                    <mat-label>RXCUI ID</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput formControlName="rxcuiId" [value]="" #input
                                            placeholder="Enter RXCUI ID" type="text" (keypress)="this.commonService.keyPressNumbers($event)">
                                    </mat-form-field>
                                    <mat-error *ngIf="tempBrandGenericForm.controls['rxcuiId'].errors?.['maxlength']">
                                        Max Length is 255 characters.
                                    </mat-error>
                                    <mat-error *ngIf="tempBrandGenericForm.controls['rxcuiId'].errors?.['validField']">
                                        {{tempBrandGenericForm.controls['rxcuiId'].errors?.['validField']}}
                                    </mat-error>
                                </div>
                                <div class="input-wrape" *ngIf="isBrand == 'brand' && approvedDictionary"
                                    [ngClass]="{ 'has-error': tempBrandGenericForm.controls['generic'].invalid && (tempBrandGenericForm.controls['generic'].dirty || tempBrandGenericForm.controls['generic'].touched)}"
                                >
                                    <mat-label>Generic</mat-label>
                                    
                                    <div class="control-pad">
                                        <ng-select [items]="genericList" formControlName="generic" [multiple]="true"
                                            bindLabel="generic_name" bindValue="id" labelForId="generic"
                                            placeholder="Select generic" (search)="getGeneric($event)">
                                        </ng-select>
                                        <mat-error *ngIf="tempBrandGenericForm.controls['generic'].errors?.['validField']">
                                            {{tempBrandGenericForm.controls['generic'].errors?.['validField']}}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
                            title="{{buttonText}}">{{buttonText}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>