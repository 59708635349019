<div class="page-title-box">
	<h1>{{title}}</h1>
	<ul class="bradcrumb">
		<li>{{title}}</li>
		<li><a routerLink="{{cancelLink}}" title="Ctate" class="only-link">Country</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">
	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<div class="card-wrape">
			<form [formGroup]="countryForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
				<div class="card-head">
					<h4 class="left-text">{{title}}</h4>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-xl-6 col-md-6">
							<div class="input-wrape"
								[class]="this.commonService.displayFieldCss(this.countryForm, 'name')">
								<mat-label><i>*</i> Name</mat-label>
								<mat-form-field appearance="outline">
									<input matInput #input placeholder="Enter name" formControlName="name"
										(focusout)="
										checkUniqueCountryFields(
										'name',
										$event
										)
									" type="text">
								</mat-form-field>
								<mat-error
									*ngIf="this.countryForm.controls['name'].invalid && (this.countryForm.controls['name'].dirty || this.countryForm.controls['name'].touched)">
									{{getCountryNameError()}}
								</mat-error>
							</div>
							<div class="input-wrape"
								[class]="this.commonService.displayFieldCss(this.countryForm, 'phoneCode')">
								<mat-label><i>*</i> Phone Code</mat-label>
								<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
									<input matInput #input placeholder="Enter phone code"
										formControlName="phoneCode" (focusout)="
									checkUniqueCountryFields(
									'phone_code',
									$event
									)
								" type="text">
								</mat-form-field>
								<mat-error
									*ngIf="this.countryForm.controls['phoneCode'].invalid && (this.countryForm.controls['phoneCode'].dirty || this.countryForm.controls['phoneCode'].touched)">
									{{getCountryPhoneCodeError()}}
								</mat-error>
							</div>
						</div>
						<div class="col-xl-6 col-md-6">
							<div class="input-wrape"
								[class]="this.commonService.displayFieldCss(this.countryForm, 'code')">
								<mat-label>Code</mat-label>
								<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
									<input matInput #input placeholder="Enter code" formControlName="code" (focusout)="
										checkUniqueCountryFields(
										'code',
										$event
										)
									" type="text">
								</mat-form-field>
								<mat-error
									*ngIf="this.countryForm.controls['code'].invalid && (this.countryForm.controls['code'].dirty || this.countryForm.controls['code'].touched)">
									{{getCountryCodeError()}}
								</mat-error>
							</div>
							<div class="input-wrape">
								<mat-label>&nbsp;</mat-label>
								<mat-checkbox-groups class="checkbox-section">
									<mat-checkbox formControlName="hasState" class="example-margin">Has State?</mat-checkbox>
								</mat-checkbox-groups>
							</div>
						</div>

						<div class="col-xl-6 col-md-6">
							<div class="input-wrape" *ngIf="isCurate">
								<mat-label><i>*</i> Is Approved?:</mat-label>
								<mat-radio-group aria-label="Select an option" class="example-radio-group">
									<mat-radio-button ngDefaultControl
										class="example-radio-button"
										formControlName="isApproved" 
										id="radio_yes"
										(click)="selectOption(false)"
										[value]="true" checked
									>
										Approved</mat-radio-button>
									<mat-radio-button ngDefaultControl
										class="example-radio-button"
										formControlName="isApproved" 
										id="radio_no"
										(click)="selectOption(true)"
										[value]="false"
									>
										Map with others</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
						<div class="col-xl-6 col-md-6">
							<div class="input-wrape" *ngIf="selectMapOption"
								[class]="this.commonService.displayFieldCss(this.countryForm, 'mapWithOtherCountry')">
								<mat-label><i>*</i> Map With:</mat-label>
								<div class="control-pad">
									<ng-select [items]="countryList" formControlName="mapWithOtherCountry"
										bindLabel="name" bindValue="id" labelForId="mapWithOtherCountry" (change)="mapWithOtherCountryChange($event)"
										placeholder="Select country">
									</ng-select>
									<mat-error
										*ngIf="this.countryForm.controls['mapWithOtherCountry'].invalid && (this.countryForm.controls['mapWithOtherCountry'].dirty || this.countryForm.controls['mapWithOtherCountry'].touched)">
										{{getMapWithCountryError()}}
									</mat-error>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
						title="Cancel">Cancel</button>
					<button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
						title="{{buttonText}}">{{buttonText}}</button>
				</div>
				</form>
			</div>
		</div>
	</div>
</section>