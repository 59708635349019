import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RecontactService extends CrudService {

    constructor(public override http: HttpClient) {
        super('recontact', http);
    }

    /**
     * @author Dhaval Bera
     * @param formData
     */
    listRecontacts(pageNo: any, sortField: string, sortOrder: string, globalFilter: string, rows: any, recontactFilters: string) {
        let params: FormData = new FormData();
        if (!Number.isNaN(pageNo) && pageNo != null) {
            params.append("page", pageNo);
        }

        if (sortField !== undefined) {
            params.append("sortField", sortField);
            params.append("sortOrder", sortOrder);
        }

        if (globalFilter !== null && globalFilter !== undefined) {
            params.append("filters[global]", globalFilter);
        }
        if (recontactFilters !== null && recontactFilters !== undefined) {
            params.append("recontact_filter", recontactFilters);
        }

        if (rows !== 0) {
            params.append("perPage", rows);
        }
        return this.http.post(`${this.baseUrl}/list`, params)
            .pipe(catchError(this.handleError));
    }

    /**
     * This function is used to Make QC
     * @param formData
     */
    updateRecontactStatus(formData: any) {
        return this.http.post(this.baseUrl + '/updatestatus', formData);
    }

    /**
     * @author Dhaval Bera
     * This function is used to Recontact Participant
     * @param formData
     */
    recontact(formData: any) {
        return this.http.post(this.baseUrl, formData);
    }

}