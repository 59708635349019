<!-- Myocardial Infarction Disease que section start -->
<div class="col-lg-12" [formGroup]="diseaseDetailForm">
    <div class="card-wrape" formArrayName="MYOCARDIAL_INFARCTION">
        <div class="card-head">
            <h4 class="left-text">Myocardial Infarction (MI) Details</h4>
        </div>
        <div class="card-body" [ngClass]="isViewDisease ? 'view-mode' : 'edit-mode'">
            <div class="mi-detail-que row">
                <div class="col-lg-6">
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.invalid)}">
                        <mat-label><i>*</i>Were You Diagnosed with Myocardial Infarction (MI) by a
                            Clinician?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="is_mi_diagnosed_by_clinician">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.value =='yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.invalid)}">
                        <mat-label><i>*</i>Photo of Doctor's Prescription/Note Confirming the
                            Diagnosis:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.value == '') || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_mi_prescription_note')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.value != '') && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.value,'photo_mi_prescription_note')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_prescription_note')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.invalid)}">
                        <mat-label><i>*</i>Electrocardiography (ECG) Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_mi_echocardiography_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_mi_echocardiography_report')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_mi_echocardiography_report')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_mi_echocardiography_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_mi_echocardiography_report')?.value =='yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Electrocardiography (ECG) Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.value == '') || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_mi_echocardiography_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.value != '') && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.value,'photo_mi_echocardiography_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_mi_echocardiography_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.invalid)}">
                        <mat-label><i>*</i>Troponin-I Measurement Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_troponin_i_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_i_report')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_i_report')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_i_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_i_report')?.value =='yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Troponin-I Measurement Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.value == '') || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_troponin_i_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.value != '') && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.value,'photo_troponin_i_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_i_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>


                </div>

                <div class="col-lg-6">
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('is_mi_diagnosed_by_clinician')?.invalid)}">
                        <mat-label><i>*</i>Troponin-T Measurement Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_troponin_t_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not Available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_t_report')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_t_report')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_t_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one"
                        *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('have_troponin_t_report')?.value =='yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.errors && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.pristine || diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Troponin-T measurement Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.value == '') || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_troponin_t_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.value != '') && (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img src="{{diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report_url')?.value}}"
                                    alt=""
                                    (click)="showImage(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report_url')?.value)">
                            </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.value,'photo_troponin_t_report')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.errors || (diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.pristine && diseaseDetailForm.controls['MYOCARDIAL_INFARCTION'].get('photo_troponin_t_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Myocardial Infarction Disease que section end -->
