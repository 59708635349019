import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { ReligionService } from 'src/app/service/religion.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { MenuService } from 'src/app/service/menu.service';


@Component({
  selector: 'app-religion',
  templateUrl: './religion.component.html',
  styleUrls: ['./religion.component.scss']
})
export class ReligionComponent implements OnInit {

  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Name',
      dataKey: 'name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['name']}`,
    },
    {
      name: 'Participants Count',
      dataKey: 'participant_count',
      isSortable: true,
      cell: (element: Record<string, any>) => ({
        content: element['participant_count'],
        keyName: 'religion/religion_id',
      }),
    },
  ];

  public title = 'Religion';
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public data: any;
  public currentMenuPermission: any;
  public is_data_curation_access : boolean = false;

  constructor(
    private religionService: ReligionService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.is_data_curation_access = JSON.parse(localStorage.getItem('userInfo') as string).is_data_curation_access;
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
  }

  /**
   * This function is user to get all User Listing
   * @author Farhan Shaikh
   */
  getAll() {
    this.loading = true;
    this.religionService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('religion Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  downloadParticipantData(url:string){
    if (this.currentMenuPermission.edit == true) {
      this.loading = true;
      this.religionService.downloadDictionaryParticipantList(url).subscribe({
        next: (result: any) => {
          console.log(result);
          const file = new Blob([result.data.body], { type: 'text/csv' });
          var downloadURL = window.URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.data.file_name;
          link.click();
        },
        error: () => { },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  editAction(event: any) {
    this.router.navigate(['dictionary-management/religion/edit/' + event.id]);
  }
  /**
   * call Delete Religion api
   * @returns null
   */
  deleteReligion(event: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Religion?',
      imageUrl: '../../../../../assets/images/delete.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.religionService.delete(event.id).subscribe({
          next: (result: any) => {
            if (result.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast(
                result.message
              );
              this.getAll();
            } else {
              this.commonService.showErrorToast(
                result.message
              );
            }
          },
          error: (e: any) => {
            this.commonService.showErrorToast('Religion not found');
          },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
