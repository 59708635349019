<section class="my-profile-wrape">
	<div class="page-title-box">
		<h1>{{title}}</h1>
		<ul class="bradcrumb">
			<li>{{title}}</li>
			<li><a routerLink="{{cancelLink}}" title="Ctate" class="only-link">Manage Site</a></li>
			<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
		</ul>
	</div>
</section>

<mat-spinner *ngIf="loading"></mat-spinner>
<section class="container-fluid">
	<div class="row">
		<div class="col-xl-12 col-md-12 col-sm-12" *ngIf="siteDetail && siteID != 0">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{siteDetail?siteDetail?.site_name:""}} -
						{{siteDetail?siteDetail.site_code:""}}</h4>
				</div>
			</div>
		</div>
		<form [formGroup]="siteForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()"
			enctype="multipart/form-data">
			<div class="col-xl-12 col-md-12">
				<mat-accordion class="example-headers-align">
					<mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Camp Site Management
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accr-participant-container inner-bg-gray">
							<mat-panel-body>
								<div class="row">
									<div class="col-xl-6 col-md-6">
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Basic Info</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('siteName')">
															<mat-label><i>*</i>Site Name:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput formControlName="siteName" #input
																	placeholder="Enter site name" type="text"
																	(change)="checkSiteName($event)" maxlength="50"
																	(keypress)="omitSpecialChar($event)">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['siteName'].invalid && (siteForm.controls['siteName'].dirty || siteForm.controls['siteName'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['siteName'].errors?.['required']">
																	Site name is required
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteName'].errors?.['unique']">
																	{{
																	siteForm.controls['siteName'].errors?.['unique']
																	}}
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteName'].errors?.['validField']">
																	{{siteForm.controls['siteName'].errors?.['validField']}}
																</mat-error>
															</span>
														</div>
													</div>
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape">
															<mat-label>Site Type: </mat-label>
															<mat-radio-group aria-label="Select an option"
																formControlName="siteType">
																<mat-radio-button value="INTERNAL" >Internal</mat-radio-button>
																<mat-radio-button value="EXTERNAL" >External</mat-radio-button>
															</mat-radio-group>
														</div>
													</div>
													<div class="col-xl-4 col-md-4 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('siteCode')">
															<mat-label><i>*</i>Site Code:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput formControlName="siteCode" #input
																	placeholder="Enter site code" type="text"
																	(change)="checkSiteCode($event)" maxlength="5"
																	[pattern]="siteForm.controls['siteType'].value == 'EXTERNAL' ? '^[A-Za-z]{2}\\d{2}$' : ''">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['siteCode'].invalid && (siteForm.controls['siteCode'].dirty || siteForm.controls['siteCode'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['siteCode'].errors?.['required']">
																	Site code is required
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCode'].errors?.['unique']">
																	{{
																	siteForm.controls['siteCode'].errors?.['unique']
																	}}
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCode'].errors?.['validField']">
																	{{siteForm.controls['siteCode'].errors?.['validField']}}
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCode'].errors?.['pattern']">
																	Enter Site Code #: emaple - AA12
																</mat-error>
															</span>
														</div>
													</div>
													<div class="col-xl-4 col-md-4 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('startDate')">
															<mat-label><i>*</i>Start Date:</mat-label>
															<div class="input-wrape calendar-wrape">
																<mat-form-field class="example-full-width"
																	appearance="outline">
																	<input matInput [matDatepicker]="pickerStartDate"
																		[min]="startMinDate"
																		placeholder="Enter start date"
																		(click)="pickerStartDate.open()"
																		formControlName="startDate"
																		(dateChange)="compareTwoDates()" readonly>
																	<mat-datepicker-toggle matSuffix
																		[for]="pickerStartDate">
																		<mat-icon matDatepickerToggleIcon
																			class="calendar-icon"></mat-icon>
																	</mat-datepicker-toggle>
																	<mat-datepicker #pickerStartDate></mat-datepicker>
																</mat-form-field>
																<span
																	*ngIf="siteForm.controls['startDate'].invalid && (siteForm.controls['startDate'].dirty || siteForm.controls['startDate'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['startDate'].errors?.['required']">
																		Start date is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['startDate'].errors?.['validField']">
																		{{siteForm.controls['startDate'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-4 col-md-4 m-b-0">
														<div class="input-wrape" [ngClass]="displayFieldCss('endDate')">
															<mat-label><i>*</i>End Date:</mat-label>
															<div class="input-wrape calendar-wrape">
																<mat-form-field class="example-full-width"
																	appearance="outline">
																	<input matInput [matDatepicker]="pickerEndDate"
																		[min]="endMinDate" placeholder="Enter end date"
																		(click)="pickerEndDate.open()"
																		formControlName="endDate"
																		(dateChange)="compareTwoDates()" readonly>
																	<mat-datepicker-toggle matSuffix
																		[for]="pickerEndDate">
																		<mat-icon matDatepickerToggleIcon
																			class="calendar-icon"></mat-icon>
																	</mat-datepicker-toggle>
																	<mat-datepicker #pickerEndDate></mat-datepicker>
																</mat-form-field>
																<span
																	*ngIf="siteForm.controls['endDate'].invalid && (siteForm.controls['endDate'].dirty || siteForm.controls['endDate'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['endDate'].errors?.['required']">
																		End date is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['endDate'].errors?.['validField']">
																		{{siteForm.controls['endDate'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('numberOfCampDays')">
															<mat-label><i>*</i>Number of Days Camp Was Conducted/Number
																of
																Camp Days:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput formControlName="numberOfCampDays"
																	#input
																	placeholder="Enter number of days camp Was conducted/number of camp days"
																	type="text" maxlength="50" pattern="\d*">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['numberOfCampDays'].invalid && (siteForm.controls['numberOfCampDays'].dirty || siteForm.controls['numberOfCampDays'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['numberOfCampDays'].errors?.['required']">
																	Number of camp days is required
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['numberOfCampDays'].errors?.['pattern']">
																	Please enter numeric value
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['numberOfCampDays'].errors?.['validField']">
																	{{siteForm.controls['numberOfCampDays'].errors?.['validField']}}
																</mat-error>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Details</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('siteCoordinatorName')">
															<mat-label><i>*</i>Site Coordinator Name:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter site coordinator name"
																	type="text" formControlName="siteCoordinatorName"
																	(keypress)="omitSpecialChar($event)" maxlength="60">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['siteCoordinatorName'].invalid && (siteForm.controls['siteCoordinatorName'].dirty || siteForm.controls['siteCoordinatorName'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorName'].errors?.['required']">
																	Site coordinator name is required
																</mat-error>
															</span>
														</div>
													</div>
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('siteCoordinatorPhone')">
															<mat-label><i>*</i>Site Coordinator Phone 1:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter site coordinator phone 1"
																	type="text" (keypress)="onlyNumeric($event)"
																	formControlName="siteCoordinatorPhone"
																	maxlength="10" minlength="10">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['siteCoordinatorPhone'].invalid && (siteForm.controls['siteCoordinatorPhone'].dirty || siteForm.controls['siteCoordinatorPhone'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone'].errors?.['required']">
																	Site coordinator phone 1 is required
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone'].errors?.['minlength']">
																	Phone 1 must be 10 digits
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone'].errors?.['maxlength']">
																	Phone 1 must be 10 digits
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone'].errors?.['invalidPhone']">
																	Please enter valid Phone 1
																</mat-error>
															</span>
														</div>
													</div>
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('siteCoordinatorPhone2')">
															<mat-label>Site Coordinator Phone 2:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input
																	placeholder="Enter site coordinator phone 2"
																	type="text" (keypress)="onlyNumeric($event)"
																	formControlName="siteCoordinatorPhone2"
																	maxlength="10" minlength="10">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['siteCoordinatorPhone2'].invalid && (siteForm.controls['siteCoordinatorPhone2'].dirty || siteForm.controls['siteCoordinatorPhone2'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone2'].errors?.['minlength']">
																	Phone 2 must be 10 digits
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone2'].errors?.['maxlength']">
																	Phone 2 must be 10 digits
																</mat-error>
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorPhone2'].errors?.['invalidPhone']">
																	Please enter valid Phone 2
																</mat-error>
															</span>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('siteCoordinatorEmail')">
															<mat-label>Site Coordinator Email:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input maxlength="100"
																	placeholder="Enter site coordinator email"
																	formControlName="siteCoordinatorEmail" type="text">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['siteCoordinatorEmail'].invalid && (siteForm.controls['siteCoordinatorEmail'].dirty || siteForm.controls['siteCoordinatorEmail'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['siteCoordinatorEmail'].errors?.['pattern']">
																	Please enter valid email address
																</mat-error>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Other Details</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape">
															<mat-label>Stay/ Hospitality (if Any) Arrangements Done
																By:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input maxlength="100"
																	formControlName="stayHospitalityArragementsDoneBy"
																	placeholder="Enter stay/hospitality (if any) arrangements done by"
																	type="text">
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-xl-6 col-md-6">
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Address</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('pincodeId')">
															<mat-label><i>*</i>Pincode / Zipcode:</mat-label>
															<div class="control-pad">
																<ng-select [items]="pincodeList"
																	formControlName="pincodeId" bindLabel="pincode"
																	bindValue="id" labelForId="pincode"
																	placeholder="Enter camp pincode / zipcode"
																	(search)="searchPincode($event)"
																	(change)="selectPincode($event)">
																</ng-select>
																<span
																	*ngIf="siteForm.controls['pincodeId'].invalid && (siteForm.controls['pincodeId'].dirty || siteForm.controls['pincodeId'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['pincodeId'].errors?.['required']">
																		Pincode is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['pincodeId'].errors?.['validField']">
																		{{siteForm.controls['pincodeId'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape" [ngClass]="displayFieldCss('stateId')">
															<mat-label><i>*</i>State:</mat-label>
															<div class="control-pad">
																<ng-select [items]="stateList" formControlName="stateId"
																	bindLabel="name" bindValue="id" labelForId="state"
																	placeholder="Enter camp state">
																</ng-select>
																<span
																	*ngIf="siteForm.controls['stateId'].invalid && (siteForm.controls['stateId'].dirty || siteForm.controls['stateId'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['stateId'].errors?.['required']">
																		State is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['stateId'].errors?.['validField']">
																		{{siteForm.controls['stateId'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape" [ngClass]="displayFieldCss('cityId')">
															<mat-label><i>*</i>City/Village/Town:</mat-label>
															<div class="control-pad">
																<ng-select [items]="cityList" formControlName="cityId"
																	bindLabel="name" bindValue="id" labelForId="city"
																	placeholder="Enter camp city"
																	(search)="searchCities($event)">
																</ng-select>
																<span
																	*ngIf="siteForm.controls['cityId'].invalid && (siteForm.controls['cityId'].dirty || siteForm.controls['cityId'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['cityId'].errors?.['required']">
																		City/Village/Town is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['cityId'].errors?.['validField']">
																		{{siteForm.controls['cityId'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape"
															[ngClass]="displayFieldCss('locality')">
															<mat-label>Locality or Neighbourhood:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input formControlName="locality"
																	placeholder="Enter locality or neighbourhood"
																	maxlength="255" type="text">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['locality'].invalid && (siteForm.controls['locality'].dirty || siteForm.controls['locality'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['locality'].errors?.['required']">
																	Locality is required
																</mat-error>
															</span>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape" [ngClass]="displayFieldCss('street')">
															<mat-label><i>*</i>Street Number, Street Name:</mat-label>
															<mat-form-field appearance="outline">
																<input matInput #input formControlName="street"
																	placeholder="Enter camp street number, street name"
																	maxlength="255" type="text">
															</mat-form-field>
															<span
																*ngIf="siteForm.controls['street'].invalid && (siteForm.controls['street'].dirty || siteForm.controls['street'].touched)">
																<mat-error
																	*ngIf="siteForm.controls['street'].errors?.['required']">
																	Street is required
																</mat-error>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text"> Camp Files</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="upload-thumb-wrape full">
															<div class="input-wrape">
																<mat-label>Attachment for Organizer Feedback
																	Form:</mat-label>
																	<div class="upload-wrape side-info">
																		<input id="fileInput" type="file"
																			class="ng-hide" placeholder="upload"
																			formControlName="organizerFeedbackForm"
																			(change)="uploadFileEvt($event,'organizerFeedbackForm','selectedFile4')"
																			accept="application/pdf">
																		<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
																		<div class="info-pad popup-over">
																			<button class="info-icon"></button>
																			<div class="info-section popupcontent">
																				Please choose .pdf file only
																			</div>
																		</div>
																	</div>
																<div class="upload-filename"
																	*ngIf="organizerFeedbackForm !== ''">
																	<span *ngIf="organizerFeedbackFormUrl">
																		<a href="{{ organizerFeedbackFormUrl }}"
																			target="_blank">
																			{{ organizerFeedbackForm }}
																		</a>
																	</span>

																	<span *ngIf="!organizerFeedbackFormUrl">
																		<a href="JavaScript:void(0);">
																			{{ organizerFeedbackForm }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteForm.controls['organizerFeedbackForm'].invalid && (siteForm.controls['organizerFeedbackForm'].dirty || siteForm.controls['organizerFeedbackForm'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['organizerFeedbackForm'].errors?.['required']">
																		Organizer Feedback Form is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['organizerFeedbackForm'].errors?.['validField']">
																		{{siteForm.controls['organizerFeedbackForm'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
															<div class="input-wrape">
																<mat-label>Attachment for Organizer Appreciation
																	Letter/Certificate of Participation:</mat-label>
																	<div class="upload-wrape side-info">
																		<input id="fileInput" type="file"
																			class="ng-hide" placeholder="upload"
																			formControlName="organizerAppreciationLetter"
																			(change)="uploadFileEvt($event,'organizerAppreciationLetter','selectedFile5')"
																			accept="application/pdf">
																		<button type="button" id="uploadButton" class="btn" aria-label="attach_file" mat-raised-button class="btn" color="primary"> Upload</button>
																		<div class="info-pad popup-over">
																			<button class="info-icon"></button>
																			<div class="info-section popupcontent">
																				Please choose .pdf file only
																			</div>
																		</div>
																	</div>
																<div class="upload-filename"
																	*ngIf="organizerAppreciationLetter !== ''">
																	<span *ngIf="organizerAppreciationLetterUrl">
																		<a href="{{ organizerAppreciationLetterUrl }}"
																			target="_blank">
																			{{ organizerAppreciationLetter }}
																		</a>
																	</span>

																	<span *ngIf="!organizerAppreciationLetterUrl">
																		<a href="JavaScript:void(0);">
																			{{ organizerAppreciationLetter }}
																		</a>
																	</span>
																</div>
																<span
																	*ngIf="siteForm.controls['organizerAppreciationLetter'].invalid && (siteForm.controls['organizerAppreciationLetter'].dirty || siteForm.controls['organizerAppreciationLetter'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['organizerAppreciationLetter'].errors?.['required']">
																		Organizer Appreciation Letter is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['organizerAppreciationLetter'].errors?.['validField']">
																		{{siteForm.controls['organizerAppreciationLetter'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>
													</div>
													<div class="col-xl-12 col-md-12 m-b-0">
														<div class="input-wrape">
															<mat-label>Is It Live Site: </mat-label>
															<mat-radio-group aria-label="Select an option"
																formControlName="isLiveSite">
																<mat-radio-button value="liveSiteyes"
																	[value]="1">Yes</mat-radio-button>
																<mat-radio-button value="liveSiteno"
																	[value]="0">No</mat-radio-button>
															</mat-radio-group>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</mat-panel-body>
						</div>
					</mat-expansion-panel>

					<mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
						<mat-expansion-panel-header>
							<mat-panel-title>
								Ethics Committee
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="accr-participant-container inner-bg-gray">
							<mat-panel-body>
								<div class="row">
									<div class="col-xl-12 col-md-12 m-b-0">
										<div class="card-wrape">
											<div class="card-head">
												<h4 class="left-text">Ethics Committee Details</h4>
											</div>
											<div class="card-body">
												<div class="row">
													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape">
															<mat-label>Data Shareable with Third Party: </mat-label>
															<mat-radio-group aria-label="Select an option"
																formControlName="dataSharable">
																<mat-radio-button
																	value="Y">Yes</mat-radio-button>
																<mat-radio-button
																	value="N">No</mat-radio-button>
															</mat-radio-group>
														</div>

														<div class="input-wrape" [ngClass]="displayFieldCss('projectId')">
															<mat-label><i>*</i>Project Name: </mat-label>
															<div class="control-pad">
																<ng-select [items]="projectList"
																	formControlName="projectId" bindLabel="name"
																	bindValue="id" labelForId="projectName"
																	placeholder="Enter project name"
																	(change)="getEthicsCommittee($event)">
																</ng-select>
																<span
																	*ngIf="siteForm.controls['projectId'].invalid && (siteForm.controls['projectId'].dirty || siteForm.controls['projectId'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['projectId'].errors?.['required']">
																		Project name is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['projectId'].errors?.['validField']">
																		{{siteForm.controls['projectId'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>

														<div class="input-wrape" [ngClass]="displayFieldCss('ethicsCommitteeId')">
															<mat-label><i>*</i>Ethics Committee Name: </mat-label>
															<div class="control-pad">
																<ng-select [items]="ethicsCommitteeList"
																	formControlName="ethicsCommitteeId" bindLabel="name"
																	bindValue="id" labelForId="consentVersion"
																	placeholder="Select ethics committee"
																	(change)="changeEthicsCommittee($event)">
																</ng-select>
																<span
																	*ngIf="siteForm.controls['ethicsCommitteeId'].invalid && (siteForm.controls['ethicsCommitteeId'].dirty || siteForm.controls['ethicsCommitteeId'].touched)">
																	<mat-error
																		*ngIf="siteForm.controls['ethicsCommitteeId'].errors?.['required']">
																		Ethics committee is required
																	</mat-error>
																	<mat-error
																		*ngIf="siteForm.controls['ethicsCommitteeId'].errors?.['validField']">
																		{{siteForm.controls['ethicsCommitteeId'].errors?.['validField']}}
																	</mat-error>
																</span>
															</div>
														</div>

														<div class="input-wrape">
															<mat-label>Consent Type:</mat-label>
															<mat-form-field appearance="outline" class="disabled">
																<input matInput #input
																	value="{{informedConsentFormVersion}}"
																	placeholder="Enter consent type" type="text" disabled>
															</mat-form-field>
														</div>
													</div>

													<div class="col-xl-6 col-md-6 m-b-0">
														<div class="input-wrape">
															<mat-label>Research Protocol Version:</mat-label>
															<mat-form-field appearance="outline" class="disabled">
																<input matInput #input
																	value="{{researchProtocolVersion}}"
																	placeholder="Enter research protocol version"
																	type="text" disabled>
															</mat-form-field>
														</div>
														<div class="input-wrape">
															<mat-label>EC Annual Renewal Date/Expiry Date:</mat-label>
															<mat-form-field appearance="outline" class="disabled">
																<input matInput #input
																	value="{{ethicsCommitteeRenewalOrExpiryDate}}"
																	placeholder="Enter EC annual renewal date/expiry date"
																	type="text" disabled>
															</mat-form-field>
														</div>
														<div class="input-wrape">
															<mat-label>EC Patient History Form (PHF)
																Version:</mat-label>
															<mat-form-field appearance="outline" class="disabled">
																<input matInput #input value="{{phfVersion}}"
																	placeholder="Enter EC patient history form (PHF) version"
																	type="text" disabled>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</mat-panel-body>
						</div>
					</mat-expansion-panel>
				</mat-accordion>

				<div class="bottom-seprate-btn-wrape">
					<button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
						title="Cancel">Cancel</button>
					<button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
						title="{{buttonText}}">{{buttonText}}</button>
				</div>
			</div>
		</form>
	</div>
</section>