<!-- Heart Failure Disease que section start -->
<div class="col-lg-12" [formGroup]="diseaseDetailForm">
    <div class="card-wrape" formArrayName="HEART_FAILURE">
        <div class="card-head">
            <h4 class="left-text">Heart Failure (HF) Details</h4>
        </div>
        <div class="card-body" [ngClass]="isViewDisease ? 'view-mode' : 'edit-mode'">
            <div class="heart-failure-detail-que row">
                <div class="col-lg-6">
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.invalid)}">
                        <mat-label><i>*</i>Were You Diagnosed with Heart Failure by a
                            Clinician?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="is_hf_diagnosed_by_clinician">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('is_hf_diagnosed_by_clinician')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.invalid)}">
                        <mat-label><i>*</i>Photo of Doctor's Prescription/Note Confirming the
                            Diagnosis</mat-label>
                        <div class="icon-wrape-preview thumb-upload" *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.value == '') || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                   (change)="onSelectFile($event,'photo_hf_prescription_note')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.value != '') && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img
                                    src="{{diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note_url')?.value}}"
                                    alt="" (click)="showImage(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note_url')?.value)">
                                </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.value,'photo_hf_prescription_note')"
                                class="theme-icon delete-icon" title="Delete" *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_prescription_note')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('is_heart_failure_due_to_surgery')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('is_heart_failure_due_to_surgery')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('is_heart_failure_due_to_surgery')?.invalid)}">
                        <mat-label><i>*</i>Did You Have Heart Failure Due to Any Surgery?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="is_heart_failure_due_to_surgery">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('is_heart_failure_due_to_surgery')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('is_heart_failure_due_to_surgery')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('is_heart_failure_due_to_surgery')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <h6>BEFORE THE ONSET of Heart Failure, Did You Have?</h6>
                    <div class="input-wrape">
                        <mat-label>Myocardial Infarction:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_myocardial_infraction_before_onset">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="input-wrape">
                        <mat-label>Pulmonary Embolus:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_myocardial_infraction_pulmonary_embolus">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="col-lg-6">
                    <h6>Do You Have Any of These Symptoms?</h6>
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.invalid)}">
                        <mat-label><i>*</i>Dry Cough:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_dry_cough_at_onset">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="don't know">Don't know</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_dry_cough_at_onset')?.value == 'yes'">
                        <mat-label>Was It Present Before the Heart Failure Diagnosis?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_dry_cough_present_before_hf_diagnosis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.invalid)}">
                        <mat-label><i>*</i>Shortness of Breath:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_hf_shortness_of_breath_at_onset">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="don't know">Don't know</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_shortness_of_breath_at_onset')?.value == 'yes'">
                        <mat-label>Was It Present Before the Heart Failure Diagnosis?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_shortness_of_breath_before_hf_diagnosis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.invalid)}">
                        <mat-label><i>*</i>Rapid Weight Gain / Whole Body Swelling:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_rapid_weight_gain_at_onset">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="don't know">Don't know</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_rapid_weight_gain_at_onset')?.value == 'yes'">
                        <mat-label>Was It Present Before the Heart Failure Diagnosis?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_rapid_weight_gain_before_hf_diagnosis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.invalid)}">
                        <mat-label><i>*</i>Swelling or Edema in the Lower Body:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_swelling_edema_lower_body_at_onset">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="don't know">Don't know</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>

                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_swelling_edema_lower_body_at_onset')?.value == 'yes'">
                        <mat-label>Was It Present Before the Heart Failure Diagnosis?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_swelling_edema_lower_before_hf_diagnosis">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <mat-radio-button value="don't know">Don't know</mat-radio-button>
                        </mat-radio-group>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.invalid)}">
                        <mat-label><i>*</i>Echocardiography Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_hf_echocardiography_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_echocardiography_report')?.value == 'yes'"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Echocardiography Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload"  *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.value == '') || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_hf_echocardiography_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.value != '') && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img
                                    src="{{diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report_url')?.value}}"
                                    alt="" (click)="showImage(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report_url')?.value)">
                                </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.value,'photo_hf_echocardiography_report')"
                                class="theme-icon delete-icon" title="Delete"  *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_echocardiography_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.invalid)}">
                        <mat-label><i>*</i>Electrocardiogram (ECG) Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option"
                            formControlName="have_hf_electrocardiogram_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.value == 'yes'" [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.invalid)}">
                        <mat-label><i>*</i>Photo of Electrocardiogram Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload" *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.value == '') || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_hf_electrocardiogram_report_1')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.value != '') && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img
                                    src="{{diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1_url')?.value}}"
                                    alt="" (click)="showImage(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1_url')?.value)">
                                </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.value,'photo_hf_electrocardiogram_report_1')"
                                class="theme-icon delete-icon" title="Delete"  *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_1')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape tree one extend no-label" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_hf_electrocardiogram_report')?.value == 'yes'" [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.invalid)}">
                        <div class="icon-wrape-preview thumb-upload" *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.value == '') || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_hf_electrocardiogram_report_2')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                            *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.value != '') && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.value != null)">
                            <span mat-button appNoRightClick>
                                <img
                                    src="{{diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2_url')?.value}}"
                                    alt="" (click)="showImage(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2_url')?.value)">
                                </span>
                            <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.value,'photo_hf_electrocardiogram_report_2')"
                                class="theme-icon delete-icon" title="Delete"  *ngIf="!isViewDisease"></a>
                        </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('photo_hf_electrocardiogram_report_2')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>

                    <div class="input-wrape"
                        [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.invalid)}">
                        <mat-label><i>*</i>Brain Natriuretic Peptide (BNP) Report Available?:</mat-label>
                        <mat-radio-group aria-label="Select an option" formControlName="have_bnp_report">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                            <!-- <mat-radio-button value="not available">Not available</mat-radio-button> -->
                        </mat-radio-group>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.invalid)">
                            This field is required
                        </mat-error>
                    </div>
                    <div class="input-wrape tree one" *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('have_bnp_report')?.value == 'yes'" [ngClass]="{ 'has-error': diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.errors && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.pristine || diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.invalid)}">
                        <mat-label><i>*</i>Photo of Brain Natriuretic Peptide (BNP) Report:</mat-label>
                        <div class="icon-wrape-preview thumb-upload" *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.value == '') || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.value == null)">
                            <span class="icon img"></span>
                            <div class="upload-wrape">
                                <input id="fileInput" type="file" class="ng-hide" placeholder="upload"
                                    (change)="onSelectFile($event,'photo_bnp_report')">
                                <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                    mat-raised-button class="btn" color="primary"> Upload</button>
                            </div>
                            <span class="type">Upload</span>
                        </div>
                        <div class="img-wrape"
                        *ngIf="(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.value != '') && (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.value != null)">
                        <span mat-button appNoRightClick>
                            <img
                                src="{{diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report_url')?.value}}"
                                alt="" (click)="showImage(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report_url')?.value)">
                            </span>
                        <a (click)="deleteDiseaseQuestionReport(diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.value,'photo_bnp_report')"
                            class="theme-icon delete-icon" title="Delete"  *ngIf="!isViewDisease"></a>
                    </div>
                        <mat-error
                            *ngIf="diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.errors || (diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.pristine && diseaseDetailForm.controls['HEART_FAILURE'].get('photo_bnp_report')?.invalid)">
                            This field is required
                        </mat-error>
                        <i class="ruler"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Heart Failure Disease que section end -->
