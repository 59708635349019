<div class="page-title-box">
	<h1>{{title}}</h1>
  <ul class="bradcrumb">
    <li>{{title}}</li>
    <li><a routerLink="{{cancelLink}}" title="Sub Population" class="only-link">Sub Population</a></li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xl-8 col-md-12 col-sm-12">
      <form [formGroup]="populationForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div class="card-wrape">
          <div class="card-head">
            <h4 class="left-text">{{title}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.populationForm, 'name')">
                  <mat-label><i>*</i> Name</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput #input formControlName="name" placeholder="Enter name" type="text" (focusout)="
                                        checkUniquePopulation($event)">
                  </mat-form-field>
                  <mat-error
                    *ngIf="this.populationForm.controls['name'].invalid && (this.populationForm.controls['name'].dirty || this.populationForm.controls['name'].touched)">
                    {{getPopulationError()}}
                  </mat-error>
                </div>
                <div class="input-wrape"
                  [class]="this.commonService.displayFieldCss(this.populationForm, 'religionId')">
                  <mat-label><i>*</i> Religion</mat-label>
                    <div class="control-pad">
                      <ng-select [items]="religionList" bindValue="id"
                        bindLabel="name" placeholder="Select religion" labelForId="religionId"
                        formControlName="religionId">
                      </ng-select>
                      <mat-error
                      *ngIf="this.populationForm.controls['religionId'].invalid && (this.populationForm.controls['religionId'].dirty || this.populationForm.controls['religionId'].touched)">
                      {{getSelectReligionError()}}
                    </mat-error>
                  </div>
                </div>
                <div class="input-wrape">
                  <mat-label>Sect Denomiation Name</mat-label>
                  <mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
                    <input matInput #input formControlName="sectDenomination" placeholder="Enter sect denomination"
                      type="text">
                  </mat-form-field>
                </div>

                <div *ngIf="this.populationId" class="input-wrape">
									<mat-label> Mapped Populations </mat-label>
									<div class="control-pad">
										<ng-select [items]="mappedPopulationLists"
											formControlName="mapped_population_id" bindLabel="name" bindValue="id"
											placeholder="Select Mapped Population" [multiple]="true">
										</ng-select>
									</div>
								</div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape">
                  <mat-label>Sub Population Ethnic Group</mat-label>
                  <mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
                    <input matInput #input formControlName="populationEthnicGroup"
                      placeholder="Enter sub population ethnic group" type="text">
                  </mat-form-field>
                </div>
                <div class="input-wrape">
                  <mat-label>Sub Population Linguistic Group Name</mat-label>
                  <mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
                    <input matInput #input formControlName="populationLinguisticGroup"
                      placeholder="Enter sub population linguistic group" type="text">
                  </mat-form-field>
                </div>
                <div class="input-wrape">
                  <mat-label>Sub Population Social Hierarchy Name</mat-label>
                  <mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
                    <input matInput #input formControlName="populationSocialHierarchy"
                      placeholder="Enter sub population social hierarchy" type="text">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" *ngIf="isCurate">
                  <mat-label><i>*</i> Is Approved?:</mat-label>
                  <mat-radio-group formControlName="isApproved" aria-label="Select an option"
                    class="example-radio-group">
                    <mat-radio-button class="example-radio-button" id="radio_yes" (click)="selectOption(false)" [value]="true"
                      checked>
                      Approved</mat-radio-button>
                    <mat-radio-button class="example-radio-button" id="radio_no" (click)="selectOption(true)" [value]="false">
                      Map with others</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="col-xl-6 col-md-6">
                <div class="input-wrape" *ngIf="selectMapOption"
                  [class]="this.commonService.displayFieldCss(this.populationForm, 'mapWithOtherPopulation')">
                  <mat-label><i>*</i> Map With:</mat-label>
                  <div class="control-pad">
										<ng-select [items]="populationList" bindValue="id" class="hidearrow"
											bindLabel="name" placeholder="Select sub population"
											formControlName="mapWithOtherPopulation" (search)="getPopulationList($event)" (change)="getPopulationDetails($event)">
										</ng-select>
										<mat-error
                        *ngIf="this.populationForm.controls['mapWithOtherPopulation'].invalid && (this.populationForm.controls['mapWithOtherPopulation'].dirty || this.populationForm.controls['mapWithOtherPopulation'].touched)">
                        {{getMapWithPopulationError()}}
                    </mat-error>
									</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button mat-raised-button class="btn" color="warn" routerLink="{{this.cancelLink}}"
              title="Cancel">Cancel</button>
            <button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
              title="{{buttonText}}">{{buttonText}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>