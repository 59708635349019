<div class="card-wrape m-b-0">
  <div class="card-head with-dropdown">
    <h4 class="left-text">Native State Wise Participant Count</h4>
    <div class="right-control">
      <div class="input-wrape">
        <div class="control-pad">
          <ng-select [items]="percentageOrAbsoluteCounts" [clearable]="false" [dropdownPosition]="'bottom'"
            [searchable]="false" placeholder="Please Select" bindLabel="value" bindValue="id"
            [(ngModel)]="stateWisePercentOrCount" (change)="showRecordsInPercentOrAbsoluteCount('state_wise', $event)">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <mat-spinner *ngIf="stateChartLoader"></mat-spinner>
    <div class="progress-overlay"></div>
    <div *ngIf="totalStateChartCounts == 0 && !stateChartLoader; else foundStateChartData">
      <div class="no-data-found">
        <div class="no-data-container">
          <div class="no-data-icon"></div>
          <div class="no-data-text">NO DATA FOUND</div>
        </div>
      </div>
    </div>
    <ng-template #foundStateChartData>
      <div echarts [options]="stateChartOptions" class="echart"></div>
      <div class="total-count-piechart" *ngIf="!stateChartLoader;">
        {{ totalStateChartCounts }}<span>Total</span>
      </div>
    </ng-template>
  </div>
</div>