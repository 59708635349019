<div class="page-title-box">
	<h1>{{title}}</h1>
	<ul class="bradcrumb">
		<li>{{title}}</li>
		<li><a routerLink="{{cancelLink}}" title="Disease" class="only-link">Disease</a></li>
		<li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
	</ul>
</div>

<section class="container-fluid">

	<div class="row justify-content-md-center">
		<div class="col-xl-8 col-md-12 col-sm-12">
			<div class="card-wrape">
				<div class="card-head">
					<h4 class="left-text">{{title}}</h4>
				</div>
				<form [formGroup]="diseaseForm" #form="ngForm"  [class]="isViewMode ? 'view-mode' : 'edit-mode'" (ngSubmit)="onSubmit()">
					<div class="card-body">
						<mat-spinner *ngIf="loading"></mat-spinner>
        <div class="progress-overlay"></div>
						<div class="progress-overlay"></div>
						<div class="row">
							<div class="col-xl-6 col-md-6">
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diseaseForm.controls['name'].invalid && (diseaseForm.controls['name'].dirty || diseaseForm.controls['name'].touched)}">
									<mat-label><i>*</i> Declared Disease Name</mat-label>
									<mat-form-field appearance="outline">
										<input matInput #input placeholder="Enter declared disease name" type="text"
											formControlName="name" required (focusout)="
										checkUniqueRecord($event)">
									</mat-form-field>
									<span *ngIf=" diseaseForm.controls['name'].invalid && (diseaseForm.controls['name'].dirty ||
											diseaseForm.controls['name'].touched)">
										<mat-error *ngIf="diseaseForm.controls['name'].errors?.['required']">
											Disease name is required
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['name'].errors?.['unique']">
											{{ diseaseForm.controls['name'].errors?.['unique'] }}
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['name'].errors?.['minlength']">
											Min Length is {{nameMinLength}} characters.
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['name'].errors?.['maxlength']">
											Max Length is 255 characters.
										</mat-error>
									</span>
								</div>

								<div class="input-wrape" *ngIf="isCurate">
									<mat-label><i>*</i> Is Approved?:</mat-label>
									<mat-radio-group aria-label="Select an option" formControlName="isApproved"
										class="example-radio-group">
										<mat-radio-button class="example-radio-button" id="radio_yes"
											(click)="selectApprovedOption(1)" [value]="1" checked>
											Approved</mat-radio-button>
										<mat-radio-button class="example-radio-button" id="radio_no"
											(click)="selectApprovedOption(0)" [value]="0">
											Map with SNOMED</mat-radio-button>
									</mat-radio-group>
								</div>

								<div class="input-wrape"  *ngIf="selectMapOption"
									[ngClass]="{ 'has-error': diseaseForm.controls['mapWithOtherDisease'].invalid && (diseaseForm.controls['mapWithOtherDisease'].dirty || diseaseForm.controls['mapWithOtherDisease'].touched)}">
									<mat-label><i>*</i> SNOMED Name:</mat-label>
									<div class="control-pad">
										<ng-select [items]="diseaseList" bindValue="id" class="hidearrow"
											bindLabel="snomed_name" placeholder="Select SNOMED name"
											formControlName="mapWithOtherDisease" (change)="getDiseaseDetails($event)">
										</ng-select>
										<mat-error
											*ngIf="diseaseForm.controls['mapWithOtherDisease'].invalid && ( diseaseForm.controls['mapWithOtherDisease'].touched || diseaseForm.controls['mapWithOtherDisease'].dirty)">
											{{getMapWithDiseaseError()}}
										</mat-error>
									</div>
								</div>
								<div class="input-wrape" *ngIf="!selectMapOption"
									[ngClass]="{ 'has-error': diseaseForm.controls['snomed_name'].invalid && (diseaseForm.controls['snomed_name'].dirty || diseaseForm.controls['snomed_name'].touched)}">
									<mat-label><i>*</i> SNOMED Name </mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter SNOMED name" type="text"
											formControlName="snomed_name">
									</mat-form-field>
									<span *ngIf=" diseaseForm.controls['snomed_name'].invalid && (diseaseForm.controls['snomed_name'].dirty ||
											diseaseForm.controls['snomed_name'].touched)">
										<mat-error *ngIf="diseaseForm.controls['snomed_name'].errors?.['required']">
											SNOMED name is required
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['snomed_name'].errors?.['minlength']">
											Min Length is 3 characters.
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['snomed_name'].errors?.['maxlength']">
											Max Length is 255 characters.
										</mat-error>
									</span>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diseaseForm.controls['snomed_id'].invalid && (diseaseForm.controls['snomed_id'].dirty || diseaseForm.controls['snomed_id'].touched)}">
									<mat-label><i *ngIf="!selectMapOption">*</i> SNOMED ID </mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter SNOMED ID" type="text"
											formControlName="snomed_id">
									</mat-form-field>
									<span *ngIf=" diseaseForm.controls['snomed_id'].invalid && (diseaseForm.controls['snomed_id'].dirty ||
											diseaseForm.controls['snomed_id'].touched)">
										<mat-error *ngIf="diseaseForm.controls['snomed_id'].errors?.['required']">
											SNOMED ID is required
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['snomed_id'].errors?.['minlength']">
											Min Length is 3 characters.
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['snomed_id'].errors?.['maxlength']">
											Max Length is 255 characters.
										</mat-error>
									</span>
								</div>

								<div class="input-wrape"
									[ngClass]="{ 'has-error': diseaseForm.controls['display_term'].invalid && (diseaseForm.controls['display_term'].dirty || diseaseForm.controls['display_term'].touched)}">
									<mat-label><i>*</i> Display Term</mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter display term" type="text"
											formControlName="display_term">
									</mat-form-field>
									<span *ngIf=" diseaseForm.controls['display_term'].invalid && (diseaseForm.controls['display_term'].dirty ||
										diseaseForm.controls['display_term'].touched)">
										<mat-error *ngIf="diseaseForm.controls['display_term'].errors?.['required']">
											Display Term is required
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['display_term'].errors?.['minlength']">
											Min Length is 3 characters.
										</mat-error>
										<mat-error *ngIf="diseaseForm.controls['display_term'].errors?.['maxlength']">
											Max Length is 255 characters.
										</mat-error>
									</span>
								</div>
								<div class="input-wrape"
									[ngClass]="{ 'has-error': diseaseForm.controls['organ_site_id'].invalid && (diseaseForm.controls['organ_site_id'].dirty || diseaseForm.controls['organ_site_id'].touched)}">
									<mat-label><i>*</i> Organ/Site </mat-label>
									<div class="control-pad">
										<ng-select [items]="organSiteList" [disabled]="true"
											formControlName="organ_site_id" bindLabel="name" bindValue="id"
											labelForId="organSite" placeholder="Select organ/site" [multiple]="true">
										</ng-select>
										<span *ngIf=" diseaseForm.controls['organ_site_id'].invalid && (diseaseForm.controls['organ_site_id'].dirty ||
										diseaseForm.controls['organ_site_id'].touched)">
											<mat-error
												*ngIf="diseaseForm.controls['organ_site_id'].errors?.['required']">
												Organ/Site is required
											</mat-error>
											<mat-error
												*ngIf="diseaseForm.controls['organ_site_id'].errors?.['minlength']">
												Min Length is 3 characters.
											</mat-error>
											<mat-error
												*ngIf="diseaseForm.controls['organ_site_id'].errors?.['maxlength']">
												Max Length is 255 characters.
											</mat-error>
										</span>
									</div>
								</div>

								<div *ngIf="this.diseaseId" class="input-wrape">
									<mat-label> Mapped Diseases </mat-label>
									<div class="control-pad">
										<ng-select [items]="mappedDiseaseLists"
											formControlName="mapped_disease_id" bindLabel="name" bindValue="id"
											placeholder="Select Mapped Disease" [multiple]="true">
										</ng-select>
									</div>
								</div>
							</div>
							<div class="col-xl-6 col-md-6">

								<div class="input-wrape"
								[ngClass]="{ 'has-error': diseaseForm.controls['is_disease_or_symptom'].invalid && (diseaseForm.controls['is_disease_or_symptom'].dirty || diseaseForm.controls['is_disease_or_symptom'].touched)}">
									<mat-label><i>*</i> Is Disease OR Symptom?:</mat-label>
									<mat-radio-group aria-label="Select an option"
										formControlName="is_disease_or_symptom" [disabled]="selectMapOption">
										<mat-radio-button value="Disease" (change)="diseaseOrSymptomChange($event)"
											[checked]="diseaseForm.controls['is_disease_or_symptom'].value === 'Disease' ? true : false"
											disabled="{{selectMapOption}}">
											Disease</mat-radio-button>
										<mat-radio-button value="Symptom" (change)="diseaseOrSymptomChange($event)"
											[checked]="diseaseForm.controls['is_disease_or_symptom'].value === 'Symptom' ? true : false"
											disabled="{{selectMapOption}}">
											Symptom
										</mat-radio-button>
									</mat-radio-group>
									<span *ngIf=" diseaseForm.controls['is_disease_or_symptom'].invalid && (diseaseForm.controls['is_disease_or_symptom'].dirty ||
										diseaseForm.controls['is_disease_or_symptom'].touched)">
										<mat-error
											*ngIf="diseaseForm.controls['is_disease_or_symptom'].errors?.['required']">
											Is Disease OR Symptom is required
										</mat-error>
									</span>
								</div>

								<div class="input-wrape">
									<mat-label>ICD-10 Terminology</mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter ICD-10 terminology" type="text"
											formControlName="icd10_terminology">
									</mat-form-field>
								</div>
								<div class="input-wrape">
									<mat-label>ICD-10 ID</mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter ICD-10 ID" type="text"
											formControlName="icd10_id">
									</mat-form-field>
								</div>
								<div class="input-wrape">
									<mat-label>ICD-9 Terminology</mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter ICD-9 terminology" type="text"
											formControlName="icd9_terminology">
									</mat-form-field>
								</div>
								<div class="input-wrape">
									<mat-label>ICD-9 ID</mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter ICD-9 ID" type="text"
											formControlName="icd9_id">
									</mat-form-field>
								</div>
								<div class="input-wrape">
									<mat-label>Remarks</mat-label>
									<mat-form-field appearance="outline" class="{{selectMapOption ? 'disabled' : ''}}">
										<input matInput #input placeholder="Enter remarks" type="text"
											formControlName="remarks">
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<button mat-raised-button class="btn" color="warn" routerLink="{{cancelLink}}"
							title="Cancel">Cancel</button>
						<button mat-raised-button class="btn" type="submit" color="secoundary" [disabled]="disableSubmitBtn"
							title="{{buttonText}}" *ngIf="!isViewMode" >{{buttonText}}</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>
