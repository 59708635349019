<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1> Participant Physiological Measurements Validation Statistics </h1>
        <ul class="bradcrumb">
            <li>Participant Physiological Measurements Data Validation</li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>

    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <div class="stat-wrape four">
                    <div class="stat-pad">
                        <p>PENDING</p>
                        <h2>
                            <i class="theme-icon participant-enroll"></i>
                            {{ lastThreeDaysData?.pendingCounts ?? 0 }}
                        </h2>
                    </div>
                    <div class="stat-pad">
                        <p>No Change</p>
                        <h2><i class="theme-icon camp-held"></i>
                            {{ lastThreeDaysData?.noChangeCounts ?? 0 }}
                        </h2>
                    </div>
                    <div class="stat-pad">
                        <p>Value Updated</p>
                        <h2><i class="theme-icon couselor"></i> {{ lastThreeDaysData?.valueUpdatedCounts ?? 0 }}</h2>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-md-12">
                <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text"> Physiological Measurements Validation Filters </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 m-b-0">
                                <div class="input-wrape">
                                    <mat-label>Stages of Validation</mat-label>
                                    <div class="control-pad">
                                        <ng-select appendTo="body" (change)="changePMFilter($event)" bindLabel="label"
                                            bindValue="value" [dropdownPosition]="'bottom'" [searchable]="true"
                                            [multiple]="false" [closeOnSelect]="true"
                                            [items]="measurementsValidationFilters"
                                            [(ngModel)]="selectedMeasurementsValidationFilter"
                                            placeholder="Please select"></ng-select>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-4 col-md-4 button-controller ">
                                <button (click)="updateReviewed('review_pending')" mat-raised-button class="btn"
                                    color="secoundary"
                                    *ngIf="selectedStage == 'pending' && currentMenuPermission?.edit == true">Move to
                                    Review Pending</button>
                                <button (click)="updateReviewed('validated')" mat-raised-button class="btn"
                                    color="secoundary"
                                    *ngIf="selectedStage == 'review_pending' && currentMenuPermission?.edit == true">Mark
                                    As Reviewed</button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-md-12">
                <!-- table expand code start -->
                <div class="row">
                    <div class="col-xl-12 col-md-12">
                        <div class="card-wrape">
                            <mat-spinner *ngIf="loading"></mat-spinner>
                            <div class="progress-overlay"></div>
                            <div class="card-head">
                                <h4 class="left-text"> Physiological Measurements Validation List </h4>
                                <div class="right-control">
                                    <div class="table-search-wrape side-info">
                                        <span class="icon"></span>
                                        <input (keyup)="searchFilter($event)" placeholder="Search" #input
                                            class="form-control" type="text">
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Sr.No., New PID, New Recollection ID, Site Code, First Name, Height,
                                                Weight, High BP, Low BP
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body table-section">
                                <div class="table-wrape">
                                    <table mat-table [dataSource]="dataSources" multiTemplateDataRows
                                        class="mat-elevation-z8" #expandSort="matSort" matSort
                                        (matSortChange)="SortFilter($event)">
                                        <ng-container matColumnDef="expand">
                                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">Action</th>
                                            <td mat-cell *matCellDef="let element">
                                                <button mat-icon-button aria-label="expand row"
                                                    (click)="(expandedElement = expandedElement === element ? undefined : element); $event.stopPropagation()">
                                                    <mat-icon
                                                        *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                                                    <mat-icon
                                                        *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                        <!-- Checkbox Column -->
                                        <!-- <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef
                                                [hidden]="selectedStage == 'validated'">
                                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                                    [checked]="selectedParticipants.hasValue() && isAllSelected()"
                                                    [indeterminate]="selectedParticipants.hasValue() && !isAllSelected()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let element"
                                                [hidden]="selectedStage == 'validated'">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selectedParticipants.toggle(element) : null"
                                                    [checked]="selectedParticipants.isSelected(element)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container> -->
                                        <ng-container matColumnDef="participant_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sr.No.</th>
                                            <td mat-cell *matCellDef="let element">{{element.participant_id}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="new_pid">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>New PID</th>
                                            <td mat-cell *matCellDef="let element">{{element.new_pid}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="new_recollection_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>New Recollection ID
                                            </th>
                                            <td mat-cell *matCellDef="let element">{{element.new_recollection_id}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="site_code">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Site Code</th>
                                            <td mat-cell *matCellDef="let element">{{element.site_code}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="first_name">
                                            <th mat-header-cell *matHeaderCellDef>First Name</th>
                                            <td mat-cell *matCellDef="let element">{{element.first_name}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="height">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Height</th>
                                            <td mat-cell *matCellDef="let element">{{element.height}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="weight">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Weight</th>
                                            <td mat-cell *matCellDef="let element">{{element.weight}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="high_blood_pressure_systolic">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>High BP</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.high_blood_pressure_systolic}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="low_blood_pressure_diastolic">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Low BP</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.low_blood_pressure_diastolic}}</td>
                                        </ng-container>
                                        <!-- <ng-container matColumnDef="occupation_type">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Occupation Type</th>
											<td mat-cell *matCellDef="let element">{{element.occupation_type}}</td>
										</ng-container> -->

                                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                        <ng-container matColumnDef="expandedDetail">
                                            <td mat-cell *matCellDef="let element"
                                                [attr.colspan]="columnsToDisplayWithExpand.length">
                                                <div class="example-element-detail"
                                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                                    <div class="example-element-position"> </div>
                                                    <div class="inner-bg-gray">
                                                        <div class="form-area">
                                                            <div class="head-part">
                                                                <h2>Add other data in Physiological Measurements
                                                                    Validation</h2>
                                                                <div class="icons-wrape"
                                                                    *ngIf="currentMenuPermission?.edit == true">
                                                                    <button type="button"
                                                                        (click)="clickEventTableForm(element)"
                                                                        class="edit-icon" title="Edit"></button>
                                                                    <button class="delete-icon close-icon"
                                                                        title="Cancel" (click)="cancelEvent(element)"
                                                                        *ngIf="element.editing"></button>
                                                                    <button class="save-icon" title="Save"
                                                                        (click)="saveEvent(element)"
                                                                        *ngIf="element.editing"></button>
                                                                </div>
                                                            </div>
                                                            <div class="form-pad"
                                                                [ngClass]="element.editing ? 'enable' : 'disable'">
                                                                <div class="row">
                                                                    <div class="col-xl-2 col-md-2">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Height (CM) - {{element.height}}
                                                                            </mat-label>
                                                                            <mat-form-field appearance="outline"
                                                                                [ngClass]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_height == true)) ? 'disabled' : ''">
                                                                                <input matInput #input type="number"
                                                                                    [disabled]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_height == true)) ? true : false"
                                                                                    placeholder="Derived Height"
                                                                                    [(ngModel)]="element.derived_height">
                                                                            </mat-form-field>
                                                                            <mat-checkbox class="example-margin" *ngIf="element.derived_status == 'VALUE_UPDATED'"
                                                                                [(ngModel)]="element.mark_as_null_height"
                                                                                (change)="changeNullHeight(element)">
                                                                                Mark As NULL
                                                                            </mat-checkbox>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-2 col-md-2">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Weight (KG) - {{element.weight}}
                                                                            </mat-label>
                                                                            <mat-form-field appearance="outline"
                                                                                [ngClass]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_weight == true)) ? 'disabled' : ''">
                                                                                <input matInput #input type="number"
                                                                                    placeholder="Derived Weight"
                                                                                    [(ngModel)]="element.derived_weight"
                                                                                    [disabled]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_weight == true)) ? true : false">
                                                                            </mat-form-field>
                                                                            <mat-checkbox class="example-margin" *ngIf="element.derived_status == 'VALUE_UPDATED'"
                                                                                [(ngModel)]="element.mark_as_null_weight"
                                                                                (change)="changeNullWeight(element)">
                                                                                Mark As NULL
                                                                            </mat-checkbox>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-md-3">
                                                                        <div class="input-wrape">
                                                                            <mat-label>High BP (mmHg) -
                                                                                {{element.high_blood_pressure_systolic}}
                                                                            </mat-label>
                                                                            <mat-form-field appearance="outline"
                                                                                [ngClass]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_high_blood_pressure_systolic == true)) ? 'disabled' : ''">
                                                                                <input matInput #input type="number"
                                                                                    placeholder="Derived High BP"
                                                                                    [(ngModel)]="element.derived_high_blood_pressure_systolic"
                                                                                    [disabled]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_high_blood_pressure_systolic == true)) ? true : false">
                                                                            </mat-form-field>
                                                                            <mat-checkbox class="example-margin" *ngIf="element.derived_status == 'VALUE_UPDATED'"
                                                                                [(ngModel)]="element.mark_as_null_high_blood_pressure_systolic"
                                                                                (change)="changeNullHighBP(element)">
                                                                                Mark As NULL
                                                                            </mat-checkbox>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-md-3">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Low BP (mmHg) -
                                                                                {{element.low_blood_pressure_diastolic}}
                                                                            </mat-label>
                                                                            <mat-form-field appearance="outline"
                                                                                [ngClass]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_low_blood_pressure_diastolic == true)) ? 'disabled' : ''">
                                                                                <input matInput #input type="number"
                                                                                    placeholder="Derived Low BP"
                                                                                    [(ngModel)]="element.derived_low_blood_pressure_diastolic"
                                                                                    [disabled]="((element.derived_status == 'NO_CHANGE') || (element.mark_as_null_low_blood_pressure_diastolic == true)) ? true : false">
                                                                            </mat-form-field>
                                                                            <mat-checkbox class="example-margin" *ngIf="element.derived_status == 'VALUE_UPDATED'"
                                                                                [(ngModel)]="element.mark_as_null_low_blood_pressure_diastolic"
                                                                                (change)="changeNullLowBP(element)">
                                                                                Mark As NULL
                                                                            </mat-checkbox>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-2 col-md-2">
                                                                        <div class="input-wrape">
                                                                            <mat-label>Flag</mat-label>
                                                                            <div class="control-pad">
                                                                                <ng-select appendTo="body"
                                                                                    bindLabel="label" bindValue="value"
                                                                                    [dropdownPosition]="'bottom'"
                                                                                    [searchable]="true"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    (change)="changeFlag(element)"
                                                                                    [items]="flag"
                                                                                    [(ngModel)]="element.derived_status"
                                                                                    placeholder="Please select flag">
                                                                                </ng-select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h2>Participant Documents</h2>
                                                            <div class="row" *ngIf="element.participant_documents.length>0" style="margin-top: 10px;">
                                                                <div class="col-xl-3 col-md-3" *ngFor="let report of element.participant_documents; index as i"
                                                                    id="staticImage_{{ i }}">
                                                                    <div class="input-wrape ">
                                                                        <mat-label>Document {{ i + 1 }}</mat-label>
                                                                        <div class="img-wrape pmr_pdf icon-wrape-preview thumb-upload">
                                                                            <span mat-button (click)="openDialog(report)" *ngIf="report.fileType != 'pdf'">
                                                                                <img src="{{ report.path_thumb }}" alt="Medical Report" class="grid_img">
                                                                            </span>
                                                                            <span mat-button *ngIf="report.fileType == 'pdf'">
                                                                                <a href="{{report.path}}" target="_blank">
                                                                                    <div mat-button class="icon-wrape-preview choosen_file_div">
                                                                                        <span class="icon pdf"></span>
                                                                                    </div>
                                                                                </a>
                                                                                <div mat-button class="icon-wrape-preview choosen_file_div">
                                                                                    <span class="icon pdf"></span>
                                                                                </div>                                                                                
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="no-data-found" *ngIf="element.participant_documents.length<=0">
                                                                <div class="no-data-container">
                                                                    <div class="no-data-icon"></div>
                                                                    <div class="no-data-text">Record not found</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                                        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                                            class="example-element-row"
                                            [class.example-expanded-row]="expandedElement === element"
                                            (click)="expandedElement = expandedElement === element ? undefined : element">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                                            class="example-element-row example-detail-row"></tr>
                                        <tr mat-row>
                                            <td *matNoDataRow colspan="11">{{matNoDataRowTitle}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <mat-paginator #expandpagination [length]="totalRows" [pageIndex]="currentPage"
                                    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                                    (page)="pageChanged($event)" aria-label="Select page" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- table expand code end -->
            </div>
        </div>
    </section>
</section>