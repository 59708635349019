import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BioBankSequencingOldService } from 'src/app/service/bio-bank-sequencing-old.service';
import { MenuService } from 'src/app/service/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bio-bank-sequencing-old',
  templateUrl: './bio-bank-sequencing-old.component.html',
  styleUrls: ['./bio-bank-sequencing-old.component.scss']
})
export class BioBankSequencingOldComponent implements OnInit {

  public title = 'Biobank and Sequencing Statistics Old';
  public bioBankSequencingCounts: any = [];
  public yearArray: any = [0];
  public totalYears: any = 11;
  public loading: boolean = false;
  public baseUrl = `${environment.baseURL}` + 'bio-bank-sequencing/';
  public currentMenuPermission: any;

  constructor(
    private bioBankSequencingOldService: BioBankSequencingOldService,
    protected router: Router,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
    this.getBioBankSequencingCounts(0);
    let countTotalYears = 0;
    for (let i = new Date().getFullYear(); i >= 2014; i--) {
      countTotalYears++;
      this.yearArray.push(i);
      this.getBioBankSequencingCounts(i);
    }
    this.totalYears = countTotalYears + 2;
  }

  /**
 * This function is used to get BioBank Sequencing Counts
 */
  getBioBankSequencingCounts(year: number) {
    this.bioBankSequencingOldService.getAllBioBank(year).subscribe({
      next: (result: any) => {
        this.bioBankSequencingCounts[year] = result.data;
      },
      error: () => { },
      complete: () => {
        this.loading = false;
      },
    });
  }
  downloadBioBank(year: number, questionKey: string) {
    if (this.currentMenuPermission.edit == true) {
      this.loading = true;
      this.bioBankSequencingOldService.downloadBioBank(year, questionKey).subscribe({
        next: (result: any) => {
          console.log(result);
          const file = new Blob([result.data.body], { type: 'text/csv' });
          var downloadURL = window.URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.data.file_name;
          link.click();
        },
        error: () => { },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }
}
