import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatrixService } from 'src/app/service/matrix.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { ParticipantService } from 'src/app/service/participant.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import Swal from 'sweetalert2';
import { MenuService } from 'src/app/service/menu.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-matrix-form',
  templateUrl: './matrix-form.component.html',
  styleUrls: ['./matrix-form.component.scss']
})
export class MatrixFormComponent implements OnInit {
  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['id']}`,
    },
    {
      name: 'Participant ID',
      dataKey: 'new_pid',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['new_pid']}`,
    },
    {
      name: 'New Recollection ID',
      dataKey: 'new_recollection_id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['new_recollection_id']}`,
    },
    {
      name: 'Recontact',
      dataKey: 'recontact_status',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['recontact_status']}`,
    },
    {
      name: 'First Name',
      dataKey: 'first_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['first_name']}`,
    },
    {
      name: 'Last Name',
      dataKey: 'last_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['last_name']}`,
    },
    {
      name: 'Age',
      dataKey: 'age',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['age']}`,
    },
    {
      name: 'Gender',
      dataKey: 'gender',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['gender']}`,
    },
    {
      name: 'Origin State',
      dataKey: 'state',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['state']}`,
    }
  ];

  public isApproved: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = [10, 25, 50, 100];
  public loading: boolean = false;
  public disableReportBtn: boolean = false;
  public disableSyncBtn: boolean = false;
  public viewLink = 'site/matrix/dashboard/';
  public exportLink = 'matrix/participant-export-orange-dm?site_id=';
  public title = 'Participants';
  public siteMatrixLoader: boolean = true;
  public siteMatrixData:any = [];
  public participantList:any = [];
  public participantMatrixData:any = [];
  public participantTimeTaken:any = null;
  public totalTimeTaken: number = 0;

  public startDate:any;
  public endDate:any;
  public minDate = new Date();
  public maxDate = new Date();
  public emails: string[] = [];
  public emailId: any = null;
  public reportBtnText = '';
  public tableShow: boolean = true;
  public tableDetail: boolean = false;
  public displayDateFilter: boolean = true;
  public inputTogglePad: boolean = false;

  public matrixCount:any = null;
  public siteId:any;
  public highestDiseaseTooltip:string = "";
  public totalPatricipants: number = 0;
  public totalAudios: number = 0;
  public totalImages: number = 0;
  public totalDiseases: number = 0;
  public currentMenuPermission: any;
  public datePipeFormat: any;
  public is_data_curation_access : boolean = false; 

  constructor(
    private route: ActivatedRoute,
    private matrixService: MatrixService,
    public commonService: CommonService,
    private participantService: ParticipantService,
    private router: Router,
    private menuService: MenuService,
    private datePipe: DatePipe
    ) {
      this.siteId = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : 0;
      this.exportLink += this.siteId;
      this.datePipeFormat = this.datePipe;
    }

  ngOnInit(): void {
    this.is_data_curation_access = JSON.parse(localStorage.getItem('userInfo') as string).is_data_curation_access;
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getParticipantBySiteId(this.siteId);
    this.getMatrixCount();
    this.getSiteMatrix(this.startDate, this.endDate, true);
  }

    /**
  * it will get all module
  * @author Farhan Shaikh
  */
    getParticipantBySiteId(siteId: any) {
    this.loading = true;
    this.participantService.getParticipantBySiteId(
      this.siteId,
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        if (result.status === AppConstants.serverSuccessStatus && result.data){
          this.participantList = result.data.data;
          this.totalRows = result.data.total;
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log('Participant Listing err :: ', err);
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getParticipantBySiteId(this.siteId);
  }
  sortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getParticipantBySiteId(this.siteId);
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getParticipantBySiteId(this.siteId);
  }


  showInfo (event: any) {
    this.tableShow = false;
    this.tableDetail = true;
    this.getParticipantMatrix(event.id);
    // this.router.navigate([this.viewLink + event.id]);
  }
  showTable () {
    this.tableShow = true;
    this.tableDetail = false;
    this.getParticipantBySiteId(this.siteId);
  }

  tabClick(event: any) {
    //site matrix
    if(event.index == '0'){
      this.getSiteMatrix(this.startDate, this.endDate, true);
      this.displayDateFilter = true;
    }else{
      // table show
      this.tableShow = true;
      this.tableDetail = false;
      this.displayDateFilter = false;
      this.getParticipantBySiteId(this.siteId);
    }
  }


  inputToggle () {
    this.inputTogglePad = !this.inputTogglePad;
  }

  addEmail(email: string) {

    let regularExp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{1,3}|[0-9]{1,3})(\]?)$/;

    if (email !== undefined && !regularExp.test(email)) {
      return false;
    }

    this.emails.push(email);
    this.emails = this.emails.filter((el, i, a) => i === a.indexOf(el))
    this.emailId = "";
    return true;
  }

  removeEmail(index: number) {
    this.emails.splice(index, 1);
  }

  /**
   * This function is used to get matrix count
   */
  getMatrixCount() {
    this.matrixService.getMatrixCount(this.siteId)
    .subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus && result.data.site_matrix_data) {
            this.matrixCount = result.data;
            this.highestDiseaseTooltip = result.data.site_matrix_data.highest_disease_tooltip;
            this.totalPatricipants = this.matrixCount ? this.matrixCount.number_of_pids:"-";
            this.totalAudios = this.matrixCount ? this.matrixCount.number_of_audio_files:"-";
            this.totalImages = this.matrixCount ? this.matrixCount.no_of_images:"-";
            this.totalDiseases = this.matrixCount ? this.matrixCount.total_disease_count:"-";
          }
        },
        error: (err: any) => {
          console.log('Matrix Count err :: ', err);
        }
    });
  }

  /**
   * This function is used to get site matrix
   */
  getSiteMatrix(startDate: any, endDate: any, isMinMaxDateUpdate: boolean) {
    this.siteMatrixLoader = true;
    this.matrixService.getSiteMatrix(this.siteId, startDate, endDate)
      .subscribe({
        next: (result: any) => {
          this.siteMatrixLoader = false;
          if (result.status === AppConstants.serverSuccessStatus && result.data) {
            this.siteMatrixData = result.data;
            this.startDate = this.commonService.transformDate(result.data.start_date_for_calendar) as string;
            this.endDate = this.commonService.transformDate(result.data.end_date_for_calendar) as string;
            if(isMinMaxDateUpdate){
              if(result.data.start_date_for_calendar){
                this.minDate = new Date(result.data.start_date_for_calendar);
              }
              if(result.data.end_date_for_calendar){
                this.maxDate = new Date(result.data.end_date_for_calendar);
              }
            }
          }
        },
        error: (err: any) => {
          console.log('Site Matrix err :: ', err);
        }
    });
  }

  /**
   * This function is used to get participant matrix
   */
  getParticipantMatrix(participantId: any) {
    this.matrixService.getParticipantMatrix(participantId)
    .subscribe({
      next: (result: any) => {
        if (result.status) {
          this.participantMatrixData = (result.data.data) ? result.data.data : [];
          this.participantTimeTaken = result.data.timeTakenData;
          this.totalTimeTaken = result.data.totalTimeTaken;
        } else {
          this.participantMatrixData = [];
          this.participantTimeTaken = [];
          this.commonService.showErrorToast("No Record Found");
        }
      },
      error: (err: any) => {
        console.log('Participant Matrix err :: ', err);
      }
    });
  }

  compareTwoDates(dateType: any, event: any) {
    let eventDate = event.target.value._d;
    if (!dateType && !eventDate) {
      return false;
    }

    if (dateType === "startDate") {
      this.startDate = eventDate;
    }

    if (dateType === "endDate") {
      this.endDate = eventDate;
    }

    if (this.startDate == null || this.endDate == null) {
      return false;
    }

    let startDate = this.commonService.transformDate(this.startDate) as string;
    let endDate = this.commonService.transformDate(this.endDate) as string;

    if (startDate !== undefined && endDate !== undefined && endDate < startDate)
    {
      this.commonService.showErrorToast("'From Date' should be less than 'To Date'");
      return false;
    }

    this.getSiteMatrix(startDate, endDate, false);
    return true;
  }

  clearDate(type: string) {
    if(type == 'startDate'){
      this.startDate = '';
    }else{
      this.endDate = '';
    }
  }

  sendReport() {
    const formData: FormData = new FormData();
    formData.append("site_id", this.siteId)
    formData.append("start_date", this.commonService.transformDate(this.startDate) as string);
    formData.append("end_date", this.commonService.transformDate(this.endDate) as string);
    formData.append("emails", JSON.stringify(this.emails))
    if (this.emails.length === 0) {
      this.commonService.showErrorToast("Please add atleast one Email ID");
      return false;
    }
    this.loading = true;
    this.disableReportBtn = true;
    this.reportBtnText = "Sending...";
    this.matrixService.sendReport(formData)
      .subscribe({
        next: (result: any) => {
          if(result.status === AppConstants.serverSuccessStatus && result.data) {
            this.commonService.showSuccessToast(result.message);
            this.reportBtnText = "Send Report";
            this.emails = [];
            this.inputTogglePad = !this.inputTogglePad;
            this.disableReportBtn = false;
            this.loading = false;
          }
        },
        error: (err: any) => {
          console.log('Matrix Site Report err :: ', err);
          this.loading = false;
        }
      });
    return true;
  }

  /**
  * This function will be used to sync and export the csv file.
  * */
  syncCohortDMData() {
    this.loading = true;
    this.disableSyncBtn = true;
    this.matrixService.syncCohortDMData(this.siteId)
    .subscribe({
      next: (result: any) => {
        if(result.status === AppConstants.serverSuccessStatus && result.data) {
          this.loading = false;
          this.disableSyncBtn = false;
          this.commonService.showSuccessToast("Data Synced Successfully");
          return true;
        } else {
          this.loading = false;
          this.disableSyncBtn = false;
          this.commonService.showErrorToast("Something Went Wrong!");
          return false;
        }
      },
      error: (err: any) => {
        console.log('sync Cohort DM Data err :: ', err);
        this.loading = false;
        this.disableSyncBtn = false;
        this.commonService.showErrorToast("Something Went Wrong!");
        return false;
      }
    });
  }

  /**
  * This function will be used to sync site matrix.
  * */
 syncSiteMatrix(id: any){
  Swal.fire({
    title: 'Synced?',
    html: 'Are you sure want to sync site matrix data?',
    imageUrl: '../../../../../assets/images/sync.svg',
    showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      this.siteMatrixLoader = true;
      if (result.value) {
        this.matrixService.syncSiteMatrixData(id)
          .subscribe({
            next: (results: any) => {
              if (results.status === 1 || results.status === 200) {
                this.commonService.showSuccessToast("Site matrix data successfully synced",);
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate([currentUrl]);
                });
                this.commonService.showSuccessToast(
                  "Site matrix data can't be synced. Please try again later.");
                // this.router.navigate(['/site/matrix-dashboard/' + id]);
              } else {
                this.commonService.showErrorToast(
                  "Site matrix data can't be synced. Please try again later.");
              }
              this.siteMatrixLoader = false;
            }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
        this.siteMatrixLoader = false;
      }
    });
  }

}
