import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopulationReclassificationService extends CrudService {

  constructor(public override http: HttpClient) {
    super('reclassification/population', http);
  }

  /**
     * @author Dhaval Bera
     * @param formData
     */
  getAllPopulation(pageNo: any, sortField: string, sortOrder: string, globalFilter: string, rows: any, phf_flags: any, siteCodes: any) {

    let params: FormData = new FormData();
    if (!Number.isNaN(pageNo) && pageNo != null) {
      params.append("page", pageNo);
    }

    if (sortField !== undefined) {
      params.append("sortField", sortField);
      params.append("sortOrder", sortOrder);
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      params.append("filters[global]", globalFilter);
    }
    if (phf_flags !== null && phf_flags !== undefined && phf_flags != '') {
      params.append("phf_flags", phf_flags);
    }
    if (siteCodes !== null && siteCodes !== undefined && siteCodes != '') {
      params.append("siteCodes", siteCodes);
    }

    if (rows !== 0) {
      params.append("perPage", rows);
    }
    return this.http.post(`${this.baseUrl}/list`, params)
      .pipe(catchError(this.handleError));
  }

  /**
   * This function is used to Make QC
   * @param id
   */
  getReclassificationStatistics() {
    return this.http.get(this.baseUrl + '/get-statistics');
  }
  /**
  * This function is used to Make QC
  * @param formData
  */
  updateReclassificationStatus(formData: any) {
    return this.http.post(this.baseUrl + '/update-status', formData);
  }

  /**
   * This function is used to Make QC
   * @param id
   */
  updateQCed(participantIDs: any) {
    let params: FormData = new FormData();
    params.append("participant_id", participantIDs);

    return this.http.post(this.baseUrl + '/update-qc', params);
  }

  /**
  * This function is used to Make QC
  * @param id
  */
  updateReviewed(participantIDs: any) {
    let params: FormData = new FormData();
    params.append("participant_id", participantIDs);

    return this.http.post(this.baseUrl + '/update-review', params);
  }
}
