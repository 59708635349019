import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-disease-hf-questions',
  templateUrl: './disease-hf-questions.component.html',
  styleUrls: ['./disease-hf-questions.component.scss']
})
export class DiseaseHfQuestionsComponent implements OnChanges {

  @Input() diseaseForm!: FormGroup;
  @Input() diseaseDetailForm!: FormGroup;
  @Input() diseaseUpdateIndex: any;
  @Input() diseaseDetails: any = [];
  @Input() isViewDisease: any;

  public hfForm!: FormGroup;
  public HEART_FAILURE: any = [];
  public participantID: any;
  public currentMenuPermission: any;

  constructor(
    private formBuilder: FormBuilder,
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private menuService: MenuService

    ) {
    this.participantID = this.route.snapshot.paramMap.get('id');
  }

  ngOnChanges(): void {

    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });

    if (![null, undefined].includes(this.diseaseUpdateIndex)) {
      this.HEART_FAILURE = this.diseaseDetails['details'][this.diseaseUpdateIndex]['HEART_FAILURE'];
    }

    console.log(this.HEART_FAILURE,this.diseaseUpdateIndex);

    // HEART_FAILURE Questions
    this.hfForm = this.formBuilder.group({
      have_bnp_report: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_bnp_report, [Validators.required]),
      have_dry_cough_at_onset: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_dry_cough_at_onset, [Validators.required]),
      have_hf_echocardiography_report: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_hf_echocardiography_report, [Validators.required]),
      have_hf_shortness_of_breath_at_onset: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_hf_shortness_of_breath_at_onset, [Validators.required]),
      have_myocardial_infraction_before_onset: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_myocardial_infraction_before_onset, []),
      have_rapid_weight_gain_at_onset: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_rapid_weight_gain_at_onset, [Validators.required]),
      have_swelling_edema_lower_body_at_onset: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_swelling_edema_lower_body_at_onset, [Validators.required]),
      is_heart_failure_due_to_surgery: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.is_heart_failure_due_to_surgery, [Validators.required]),
      is_hf_diagnosed_by_clinician: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.is_hf_diagnosed_by_clinician, [Validators.required]),
      photo_hf_echocardiography_report: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_echocardiography_report, []),
      photo_hf_echocardiography_report_url: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_echocardiography_report_url, []),
      photo_hf_prescription_note: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_prescription_note, []),
      photo_hf_prescription_note_url: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_prescription_note_url, []),
      photo_hf_electrocardiogram_report_1: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_electrocardiogram_report_1, []),
      photo_hf_electrocardiogram_report_2: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_electrocardiogram_report_2, []),
      photo_hf_electrocardiogram_report_1_url: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_electrocardiogram_report_1_url, []),
      photo_hf_electrocardiogram_report_2_url: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_hf_electrocardiogram_report_2_url, []),
      photo_bnp_report: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_bnp_report, []),
      photo_bnp_report_url: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.photo_bnp_report_url, []),
      have_swelling_edema_lower_before_hf_diagnosis: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_swelling_edema_lower_before_hf_diagnosis, []),
      have_shortness_of_breath_before_hf_diagnosis: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_shortness_of_breath_before_hf_diagnosis, []),
      have_rapid_weight_gain_before_hf_diagnosis: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_rapid_weight_gain_before_hf_diagnosis, []),
      have_myocardial_infraction_pulmonary_embolus: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_myocardial_infraction_pulmonary_embolus, []),
      have_hf_electrocardiogram_report: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_hf_electrocardiogram_report, [Validators.required]),
      have_dry_cough_present_before_hf_diagnosis: new FormControl([null, undefined, ''].includes(this.HEART_FAILURE) ? null : this.HEART_FAILURE?.have_dry_cough_present_before_hf_diagnosis, []),
    });

    this.diseaseDetailForm.setControl('HEART_FAILURE', this.hfForm);

    // HEART_FAILURE Dynamic Validation
    const hfForm = this.diseaseDetailForm.get('HEART_FAILURE') as FormArray;
    hfForm.get('is_hf_diagnosed_by_clinician')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.hfForm.controls['photo_hf_prescription_note'].setValidators([Validators.required]);
      } else {
        this.hfForm.controls['photo_hf_prescription_note'].clearValidators();
      }
      this.hfForm.controls['photo_hf_prescription_note'].updateValueAndValidity();
    });

    hfForm.get('have_hf_echocardiography_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.hfForm.controls['photo_hf_echocardiography_report'].setValidators([Validators.required]);
      } else {
        this.hfForm.controls['photo_hf_echocardiography_report'].clearValidators();
      }
      this.hfForm.controls['photo_hf_echocardiography_report'].updateValueAndValidity();
    });

    hfForm.get('have_hf_electrocardiogram_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.hfForm.controls['photo_hf_electrocardiogram_report_1'].setValidators([Validators.required]);
      } else {
        this.hfForm.controls['photo_hf_electrocardiogram_report_1'].clearValidators();
      }
      this.hfForm.controls['photo_hf_electrocardiogram_report_1'].updateValueAndValidity();
    });

    hfForm.get('have_bnp_report')?.valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.hfForm.controls['photo_bnp_report'].setValidators([Validators.required]);
      } else {
        this.hfForm.controls['photo_bnp_report'].clearValidators();
      }
      this.hfForm.controls['photo_bnp_report'].updateValueAndValidity();
    });
  }

  /**
* This funtion is used to upload report
* @param event
* @param key
*/
  onSelectFile(event: any, key: string) {
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0]
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('disease_type', 'HEART_FAILURE');
      formData.append('previous_file', [null, undefined, ''].includes(this.HEART_FAILURE?.key) ? '' : this.HEART_FAILURE.key);
      this.participantService.uploadDiseaseQuestionReport(formData, this.participantID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            console.log(key);
            this.hfForm.controls[key]?.setValue(result.data.file);
            this.hfForm.controls[key + '_url']?.setValue(result.data.file_with_path);
            console.log(this.hfForm.value);
          }
        },
        error: (e: any) => {
          console.log('err :: ', e);
        },
        complete: () => {

        },
      });
    }
  }

  /**
 * Delete Disease Question Report File
 * @param fileName
 * @param index
 */
  deleteDiseaseQuestionReport(fileName: string, key: string) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the file?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {

        this.participantService.deleteDiseaseQuestionReport(fileName).subscribe({
          next: (result: any) => {
            if (result.status === 1) {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Report deleted successfully.', icon: 'success', });
              this.hfForm.controls[key]?.setValue(null);
              this.diseaseDetails['details'][this.diseaseUpdateIndex]['HEART_FAILURE'][key + '_url'] = null;
              this.hfForm.controls[key].setValidators([Validators.required]);
              this.hfForm.controls[key].updateValueAndValidity();
            } else {
              Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: result.message, icon: 'warning', });
            }
          },
          error: (e: any) => {
            console.log('err :: ', e);
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: e, icon: 'warning', });
          },
          complete: () => {

          },
        });
      }
    });
  }

  showImage(fileUrl: string) {
    Swal.fire({
      imageUrl: fileUrl,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

}
