import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { TableColumn } from 'src/app/interfaces/TableColumn';
import { ApprovedDrugsService } from 'src/app/service/approved-drugs.service';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';


@Component({
  selector: 'app-approved-drugs',
  templateUrl: './approved-drugs.component.html',
  styleUrls: ['./approved-drugs.component.scss'],
})
export class ApprovedDrugsComponent implements OnInit {
  public displayedColumns: TableColumn[] = [
    {
      name: 'ID',
      dataKey: 'medication_id',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['medication_id']}`,
    },
    {
      name: 'Medication Name',
      dataKey: 'medication_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['medication_name']}`,
    },
    {
      name: 'Participants Count',
      dataKey: 'participant_count',
      isSortable: true,
      cell: (element: Record<string, any>) => ({
        content: element['participant_count'],
        keyName: 'approved-drugs/medication_name',
      }),
    },
    {
      name: 'Drug Brand Name',
      dataKey: 'brand_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['brand_name']}`,
    },
    {
      name: 'Generic Name',
      dataKey: 'generic_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['generic_name']}`,
    },
    {
      name: 'Category Name',
      dataKey: 'category_name',
      isSortable: true,
      cell: (element: Record<string, any>) => `${element['category_name']}`,
    },
  ];

  public title = 'Approved Drugs';
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public loading: boolean = false;
  public isCurate: boolean = true;
  public data: any;
  public isApproved: number = 1;
  public editLink = 'dictionary-management/approved-drugs/edit/';
  public currentMenuPermission: any;
  public is_data_curation_access : boolean = false;


  constructor(
    private approvedDrugsService: ApprovedDrugsService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.is_data_curation_access = JSON.parse(localStorage.getItem('userInfo') as string).is_data_curation_access;
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getAll();
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
  getAll() {
    this.loading = true;
    this.approvedDrugsService
      .getRecordByFilter(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize,
        this.isApproved
      )
      .subscribe({
        next: (result: any) => {
          this.loading = false;
          this.data = result.data.data;
          this.totalRows = result.data.total;
        },
        error: (e) => {
          this.loading = false;
          console.log('approved-drugs Listing err :: ', e);
        },
        complete: () => {
          this.loading = false;
        },
      });
  }

  downloadParticipantData(url:string){
    if (this.currentMenuPermission.edit == true) {
      this.loading = true;
      this.approvedDrugsService.downloadDictionaryParticipantList(url).subscribe({
        next: (result: any) => {
          console.log(result);
          const file = new Blob([result.data.body], { type: 'text/csv' });
          var downloadURL = window.URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.data.file_name;
          link.click();
        },
        error: () => { },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }

  /**
   * Make unapproved records
   * @param id
   */
  makeUnapproved(event: any) {
    Swal.fire({
      title: 'Unapproved?',
      html: 'Are you sure want to unapproved the Drug?',
      imageUrl: '../../../../../assets/images/unapproved.svg',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.approvedDrugsService
          .makeUnapproved(event.medication_id)
          .subscribe((results: any) => {
            if (results.status === 1 || results.status === 200) {
              this.commonService.showSuccessToast(
                'Drug unapproved successfully'
              );
              this.getAll();
              this.loading = false;
            } else {
              this.commonService.showErrorToast(
                "Drug can't be unapproved. Please try again later."
              );
              this.loading = false;
            }
          });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(filterValue: any) {
    this.filterValue = filterValue;
    this.currentPage = 0;
    this.getAll();
  }
  SortFilter(sortState: any) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      this.getAll();
    }
  }
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAll();
  }
}
