<div class="page-title-box">
    <h1> Lab Data Import </h1>
    <ul class="bradcrumb">
        <li> Lab Data Import </li>
        <li><a routerLink="/lab-data" title="Ctate" class="only-link">Lab Data</a></li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-7 col-md-12 col-sm-12">
            <div class="card-wrape">
                <form [formGroup]="labDataImportForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit()">
                    <div class="card-head">
                        <h4 class="left-text"> Lab Data Import </h4>
                        <div class="right-control">
                            <a href="https://docs.google.com/spreadsheets/d/1r9hsw9sjyovmbluUW2rc6VsiRZxiHy9W-AsS53Qvc9o/view#gid=0"
                                class="download-btn" target="_blank" color="secoundary">Download Template</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': labDataImportForm.controls['labFileType'].invalid && (labDataImportForm.controls['labFileType'].dirty || labDataImportForm.controls['labFileType'].touched)}">
                                    <mat-label>File Type </mat-label>
                                    <div class="control-pad">
                                        <ng-select formControlName="labFileType" bindLabel="label" bindValue="value"
                                            [dropdownPosition]="'bottom'" [searchable]="true" [multiple]="false"
                                            [closeOnSelect]="true" [items]="labFileType" placeholder="Select file type">
                                        </ng-select>

                                    </div>
                                    <span
                                        *ngIf="labDataImportForm.controls['labFileType'].invalid && (labDataImportForm.controls['labFileType'].dirty || labDataImportForm.controls['labFileType'].touched)">
                                        <mat-error
                                            *ngIf="labDataImportForm.controls['labFileType'].errors?.['required']">
                                            File type is required
                                        </mat-error>
                                    </span>
                                    <span
                                        *ngIf="labDataImportForm.controls['labFileType'].invalid && (labDataImportForm.controls['labFileType'].dirty || labDataImportForm.controls['labFileType'].touched)">
                                        <mat-error
                                            *ngIf="labDataImportForm.controls['labFileType'].errors?.['serverError']">
                                            {{labDataImportForm.controls['labFileType'].errors?.['serverError']}}
                                        </mat-error>
                                    </span>
                                </div>

                            </div>
                            <div class="col-xl-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': labDataImportForm.controls['lab_data_file'].invalid && (labDataImportForm.controls['lab_data_file'].dirty || labDataImportForm.controls['lab_data_file'].touched)}">
                                    <mat-label>Upload an Excel File</mat-label>
                                    <div class="upload-wrape side-info">
                                        <input (change)="onSelectLabFile($event)" id="fileInput"
                                            formControlName="lab_data_file" type="file" class="ng-hide">
                                        <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                            mat-raised-button class="btn" color="primary"> Upload</button>
                                        <div class="info-pad popup-over">
                                            <button class="info-icon"></button>
                                            <div class="info-section popupcontent">
                                                Please choose .xls file only
                                            </div>
                                        </div>
                                    </div>

                                    <span
                                        *ngIf="labDataImportForm.controls['lab_data_file'].invalid && (labDataImportForm.controls['lab_data_file'].dirty || labDataImportForm.controls['lab_data_file'].touched)">
                                        <mat-error class="text-danger errorlbl"
                                            *ngIf="labDataImportForm.controls['lab_data_file'].errors?.['fileInvalidSize']">
                                            {{labDataImportForm.controls['lab_data_file'].errors?.['fileInvalidSize']}}
                                        </mat-error>
                                        <mat-error class="text-danger errorlbl"
                                            *ngIf="labDataImportForm.controls['lab_data_file'].errors?.['fileInvalidType']">
                                            {{ labDataImportForm.controls['lab_data_file'].errors?.['fileInvalidType']
                                            }}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="labDataImportForm.controls['lab_data_file'].errors?.['required']">
                                            Lab File is required
                                        </mat-error>
                                    </span>
                                    <mat-error class="text-danger errorlbl"
                                        *ngIf="labDataImportForm.controls['lab_data_file'].errors && labDataImportForm.controls['lab_data_file'].errors?.['invalidRecord']">
                                        {{ labDataImportForm.controls['lab_data_file'].errors?.['invalidRecord'] }}
                                    </mat-error>
                                </div>
                                <div class="upload-filename" *ngIf="selectedFileName !== '' ">
                                    <a href="JavaScript:void(0);">{{ selectedFileName }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <span
                                    *ngIf="labDataImportForm.controls['lab_data_file'].invalid && (labDataImportForm.controls['lab_data_file'].dirty || labDataImportForm.controls['lab_data_file'].touched)">
                                    <mat-error
                                        *ngIf="labDataImportForm.controls['lab_data_file'].errors?.['missingPIDs']">
                                        {{labDataImportForm.controls['lab_data_file'].errors?.['missingPIDs']}}
                                    </mat-error>
                                </span>
                                <div class="upload-filename"
                                    *ngIf="labDataImportForm.controls['lab_data_file'].errors?.['missingPIDsNote']">
                                    {{labDataImportForm.controls['lab_data_file'].errors?.['missingPIDsNote']}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="/lab-data"
                            title="Cancel">Cancel</button>
                        <button type="submit" mat-raised-button class="btn" color="secoundary"
                            title="Submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>