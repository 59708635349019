import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { filter, map, catchError, take, finalize, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../app.constant';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    rowData: any;
    errors: string[] = [];

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        false
    );

    constructor(
        private authService: AuthService,
        protected router: Router
    ) { }

    refreshToken(): Observable<any> {
        const authToken = this.authService.getAuthorizationToken();
        return this.authService.refreshToken(authToken).pipe(
            map((res) => {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                return true;
            })
        );
    }

    /**
     * Intercept where setting header token
     * @param req
     * @param next
     * @author Dhaval Bera
     */

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.addTokenToRequest(req);
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error.status === AppConstants.TokenExpiredStatus ) {
                    if (this.refreshTokenInProgress) {
                        return this.refreshTokenSubject.pipe(
                            filter((result) => result !== null),
                            take(1),
                            switchMap(() => next.handle(this.addTokenToRequest(req)))
                        );
                    } else {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);
                        return this.refreshToken().pipe(
                            switchMap((success: boolean) => {
                                this.refreshTokenSubject.next(success);
                                req = this.addTokenToRequest(req);

                                return next.handle(req);
                            }),
                            finalize(() => (this.refreshTokenInProgress = false))
                        );
                    }
                } else if (error.error.status === AppConstants.logoutStatus) {
                    localStorage.clear();
                    this.router.navigate(['login']);
                } else {
                    return throwError(() => error);
                }
                return throwError(() => error);
            })
        );
    }

    /**
     *
     * @param req
     * @author Dhaval
     */
    addTokenToRequest(req: HttpRequest<any>) {
        const userId = localStorage.getItem('userId');
        const splitted = req.url.split('/', 3);
        if (splitted[2] !== 'globalgenecorp.atlassian.net') {
            const authToken = this.authService.getAuthorizationToken();
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`,
                    'x-login-source': AppConstants.loginSource
                },
            });
            if (userId) {
                const authToken = this.authService.getAuthorizationToken();
                req = req.clone({
                    setHeaders: {
                        token: `${authToken}`,
                        Authorization: `Bearer ${authToken}`,
                        'x-login-source': AppConstants.loginSource,
                    },
                });
            }
        }
        return req;
    }
}
