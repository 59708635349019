import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, } from '@angular/forms';
import Swal from 'sweetalert2'

import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { CommonService } from 'src/app/service/common.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { map, Observable, startWith } from 'rxjs';
import { PincodeService } from 'src/app/service/pincode.service';
import { AppConstants } from 'src/app/app.constant';
import { PopulationService } from 'src/app/service/population.service';

@Component({
  selector: 'app-participant-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})

export class InformationComponent implements OnInit {

  @Input() currentMenuPermission: any;


  public participantInformation: any;
  public PID: any;
  public participantForm!: FormGroup;
  public genderOptions: any = [];
  public uniqueIdOptions: any = [];
  public ageOptions: any = [];
  public bloodRelatives = [];
  public countries: any;
  public cities: any = [];
  public states: any = [];
  public religions: any = [];
  public populations: any = [];
  public languages: any = [];
  public pincodes: any = [];
  public participantFormStatus: boolean = false;
  public participantInfoSave: boolean = false;
  public loading: boolean = false;
  public minDate: any;
  public maxDate: any;
  public userInfo: any;
  public participantPhoneNumber: any;

  constructor(
    private participantService: ParticipantService,
    private populationService: PopulationService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private pincodeService: PincodeService,
    private dictionaryService: DictionaryService) {
    this.PID = this.route.snapshot.paramMap.get('id');
    this.getConstants();
  }

  ngOnInit() {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 150);

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 0);

    this.getDictionaries();
    this.getParticipantInformation();

    this.participantForm = this.formBuilder.group({
      selected_unique_id: [null, [Validators.required]],
      unique_id_detail: [null, ""],
      new_recollection_id: [null, ""],
      site_code: [null, ""],
      first_name: [null, Validators.required],
      middle_name: [null, ""],
      last_name: [null, Validators.required],
      date_of_birth: [null, ""],
      age: [null],
      gender: [""],
      participant_identification: [null, Validators.required],
      origin_country_id: [null, Validators.required],
      origin_state_id: [null, Validators.required],
      origin_city_id: [null, ""],
      origin_postal_code_name: [null, ""],
      origin_postal_code_id: [null],
      origin_country_name: [null, ""],
      origin_city_name: [null, ""],
      population_id: [null, Validators.required],
      religion_id: [null, Validators.required],
      mother_tongue_id: [null, Validators.required],
      is_parents_blood_relation: [null, ""],
      parents_blood_relation: [null, ""],
      parents_blood_relation_description: [null, ""],
      phone_mobile: [null, [Validators.required]],
      email: [null, ""],
      alternate_phone_number: [null],
      residential_postal_code_id: [null],
      residential_postal_code_name: [null, ""],
      is_residential_address_same_as: [null, ""],
      residential_country_id: [null, Validators.required],
      residential_state_id: [null, Validators.required],
      residential_city_id: [null, Validators.required],
      residential_country_name: [null, ""],
      residential_state_name: [null, ""],
      residential_city_name: [null, ""],
      residential_postal_address: [null, ""],
      phone_mobile_country_id: [null, Validators.required],
      phone_mobile_phone_code: [null],
      phone_mobile_country_name: [null],
      alternate_phone_no_country_id: [null, ""],
      population_name: [null, ""],
      mother_tongue_name: [null],
      is_phone_number_verified: [null, '']
    });
  }

  searchPincode(filterValue: any) {
    if (filterValue && filterValue.length > 2) {
      this.pincodeService.searchPincode(filterValue).subscribe({
        next: (result: any) => {
          if (result.status == AppConstants.serverSuccessStatus && result.data) {
            this.pincodes = result.data;
          }
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
        complete: () => { },
      });
    }
  }

  searchSubpopulation(filterValue: any) {
    if (filterValue && filterValue.length > 2) {
      this.populationService.searchSubpopulation(filterValue).subscribe({
        next: (result: any) => {
          if (result.status == AppConstants.serverSuccessStatus && result.data) {
            this.populations = result.data;
            // if (result.data.length > 0) {
            //   this.participantForm.controls['population_id'].setValue(null);
            // }
          }
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
        complete: () => { },
      });
    }
  }

  clickOnEdit() {
    this.participantFormStatus = true;
    this.participantInfoSave = true;
  }
  clickOnCancel() {
    this.participantFormStatus = false;
    this.participantInfoSave = false;
  }

  showProfile() {
    Swal.fire({
      imageUrl: (this.participantInformation.photo != '') ? this.participantInformation.photo : '../../../../../../../assets/images/patient-pic-1.jpg',
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  showVideo() {
    Swal.fire({
      html: "<video width='100%' height='265' controls><source src=" + this.participantInformation.video_file_name + " type='video/mp4'></video>",
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  getParticipantInformation() {
    this.loading = true;
    this.participantService.getParticipantInformation(this.PID).subscribe({
      next: (result: any) => {
        this.participantInformation = result.data;
        this.participantForm.controls['selected_unique_id'].setValue(this.participantInformation.selected_unique_id);
        this.participantForm.controls['unique_id_detail'].setValue(this.participantInformation.unique_id_detail);
        this.participantForm.controls['participant_identification'].setValue(this.participantInformation.participant_identification);
        this.participantForm.controls['new_recollection_id'].setValue(this.participantInformation.new_recollection_id);
        this.participantForm.controls['site_code'].setValue(this.participantInformation.site_code);
        this.participantForm.controls['first_name'].setValue(this.participantInformation.first_name);
        this.participantForm.controls['middle_name'].setValue(this.participantInformation.middle_name);
        this.participantForm.controls['last_name'].setValue(this.participantInformation.last_name);
        this.participantForm.controls['date_of_birth'].setValue(this.participantInformation.date_of_birth);
        this.participantForm.controls['age'].setValue(parseInt(this.participantInformation.age));
        this.participantForm.controls['gender'].setValue(this.participantInformation.gender);

        this.participantForm.controls['phone_mobile_country_id'].setValue(this.participantInformation.phone_mobile_country_id);
        this.participantForm.controls['phone_mobile'].setValue(this.participantInformation.phone_mobile);
        this.participantPhoneNumber = this.participantInformation.phone_mobile;
        this.participantForm.controls['is_phone_number_verified'].setValue(this.participantInformation.is_phone_number_verified);
        this.participantForm.controls['alternate_phone_no_country_id'].setValue(this.participantInformation.alternate_phone_no_country_id);
        this.participantForm.controls['alternate_phone_number'].setValue(this.participantInformation.alternate_phone_number);
        this.participantForm.controls['email'].setValue(this.participantInformation.email);

        this.participantForm.controls['origin_country_id'].setValue(this.participantInformation.origin_country_id);
        this.participantForm.controls['origin_city_id'].setValue(this.participantInformation.origin_city_id);
        this.participantForm.controls['origin_state_id'].setValue(this.participantInformation.origin_state_id);
        this.participantForm.controls['origin_postal_code_id'].setValue(this.participantInformation.origin_postal_code_id);
        this.participantForm.controls['origin_postal_code_name'].setValue(this.participantInformation.origin_postal_code_name);

        this.participantForm.controls['is_residential_address_same_as'].setValue(this.participantInformation.is_residential_address_same_as);
        this.participantForm.controls['residential_country_id'].setValue(this.participantInformation.residential_country_id);
        this.participantForm.controls['residential_city_id'].setValue(this.participantInformation.residential_city_id);
        this.participantForm.controls['residential_state_id'].setValue(this.participantInformation.residential_state_id);
        this.participantForm.controls['residential_postal_code_id'].setValue(this.participantInformation.residential_postal_code_id);
        this.participantForm.controls['residential_postal_code_name'].setValue(this.participantInformation.residential_postal_code_name);
        this.participantForm.controls['residential_postal_address'].setValue(this.participantInformation.residential_postal_address);

        this.participantForm.controls['religion_id'].setValue(this.participantInformation.religion_id);
        this.participantForm.controls['population_id'].setValue(this.participantInformation.population_map_id != null ? this.participantInformation.population_map_id : this.participantInformation.population_id);
        this.participantForm.controls['mother_tongue_id'].setValue(this.participantInformation.mother_tongue_id);

        this.participantForm.controls['is_parents_blood_relation'].setValue(this.participantInformation.is_parents_blood_relation);
        this.participantForm.controls['parents_blood_relation'].setValue(this.participantInformation.parents_blood_relation);
        this.participantForm.controls['parents_blood_relation_description'].setValue(this.participantInformation.parents_blood_relation_description);
        this.loading = false;
      }, error: (e) => {
        console.log('Participant Information err :: ', e);
        this.loading = false;
      }, complete: () => {
        this.loading = false;
        this.setCustomDictionary();
      },
    });
  }

  participantInfoSubmit() {
    // Remove validation start
    // this.ageDateofBirthValidation();
    // if (this.participantForm.invalid) {
    //   this.commonService.validateAllFormFields(this.participantForm);
    //   Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
    //   return;
    // }
    // Remove validation end

    // this.participantInfoSave = false;
    this.loading = true;
    // this.participantForm.controls['is_phone_number_verified'].setValue(true);
    if (this.participantForm.controls['phone_mobile'].value != this.participantPhoneNumber) {
      this.participantForm.controls['is_phone_number_verified'].setValue(false);
    }

    if (this.participantForm.value.is_parents_blood_relation == "no") {
      this.participantForm.get('parents_blood_relation')?.setValue(null);
      this.participantForm.get('parents_blood_relation_description')?.setValue(null);
    }else if(this.participantForm.value.is_parents_blood_relation == "yes" && this.participantForm.value.parents_blood_relation != "other"){
      this.participantForm.get('parents_blood_relation_description')?.setValue(null);
    }
    console.log(this.participantForm.value)
    this.participantService.updateParticipantInformation(this.participantForm.value, this.PID).subscribe({
      next: (result: any) => {
        console.log(result);
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.participantForm.markAsPristine();
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
        this.participantFormStatus = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getConstants() {
    this.userService.getConstants().subscribe({
      next: (result: any) => {
        this.genderOptions = result.GENDERS;
        this.bloodRelatives = result.PARENTS_BLOOD_RELATION;
      },
      error: (e) => { },
      complete: () => { },
    });

    this.ageOptions = this.commonService.getAge();
  }

  getDictionaries() {
    this.dictionaryService.getUniqueIdentities().subscribe({
      next: (result: any) => {
        this.uniqueIdOptions = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });

    this.dictionaryService.getCountryList().subscribe({
      next: (result: any) => {
        this.countries = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });

    this.dictionaryService.getCityList().subscribe({
      next: (result: any) => {
        this.cities = result.data;
        this.setResidentCity();
      },
      error: (e) => {
        console.log(e);
      },
    });

    this.dictionaryService.getStateList().subscribe({
      next: (result: any) => {
        this.states = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });

    this.dictionaryService.getPopulationList().subscribe({
      next: (result: any) => {
        this.populations = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });
    this.dictionaryService.getReligionList().subscribe({
      next: (result: any) => {
        this.religions = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });

    this.dictionaryService.getLanguageList().subscribe({
      next: (result: any) => {
        this.languages = result.data;
      },
      error: (e) => {
        console.log(e);
      },
    });

  }

  ageCalculation(event: any) {
    let birthDate = event.target.value._d;
    if (birthDate === '') {
      this.ageDateofBirthValidation();
      return false;
    }
    const calAge = this.commonService.ageFromDateOfBirthday(birthDate);
    this.participantForm.controls["age"].setValue(Number(calAge));
    this.ageDateofBirthValidation();
    return true;
    // this.diagnosedAgeRanges = this.getAgeRanges(Number(calAge));
  }

  ageSelection(event: any) {
    this.participantForm.controls["date_of_birth"].setValue(null);
    if (typeof event === "undefined") {
      this.ageDateofBirthValidation();
      return false;
    }
    this.participantForm.controls["age"].setValue(Number(event.id));
    this.ageDateofBirthValidation();
    return true;
  }

  ageDateofBirthValidation() {
    let age = this.participantForm.controls['age'].value;
    let dateOfBirth = this.participantForm.controls['date_of_birth'].value;
    if ((age === undefined || age === null) && (dateOfBirth === undefined || dateOfBirth === null)) {
      this.participantForm.controls['age'].setValidators(Validators.required);
      this.participantForm.controls['date_of_birth'].setValidators(Validators.required);
    } else {
      this.participantForm.controls['age'].setValidators(null);
      this.participantForm.controls['date_of_birth'].setValidators(null);
    }

    let transformedDate = null;
    if (this.participantForm.controls['date_of_birth'].value) {
      transformedDate = this.commonService.transformDate(
        this.participantForm.controls['date_of_birth'].value
      ) as unknown as Blob;
    }
    this.participantForm.controls['date_of_birth'].patchValue(transformedDate);

    this.participantForm.controls['age'].updateValueAndValidity();
    this.participantForm.controls['date_of_birth'].updateValueAndValidity();
  }

  /**
  * @author Pramod Pramod Kushwaha
  * @param field
  */
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }

  /**
   * @author Pramod Kushwaha
   * This funtion is used to submit Form Details
   * @param field
   */
  isFieldValid(field: string) {
    return (
      (this.participantForm.controls[field].invalid && this.participantForm.controls[field].dirty) ||
      (this.participantForm.controls[field].touched && this.participantForm.controls[field].invalid)
    );
  }

  /**
   *
   * @param event
   */
  onlyNumeric(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  addTagFn(name: any) {
    console.log(name);
    return { name: name, id: name };
  }

  setResidentCity() {
    setTimeout(() => {
      if (this.participantInformation.residential_city_name != '') {
        const keyExists = this.cities.some((item: any) => item.name.includes(this.participantInformation.residential_city_name));
        if (keyExists == false) {
          this.cities.push({ id: this.participantInformation.residential_city_id, name: this.participantInformation.residential_city_name });
          this.cities = this.cities.slice(0);
        }
        this.participantForm.controls['residential_city_id'].setValue(this.participantInformation.residential_city_id > 0 ? this.participantInformation.residential_city_id : null);

      }
    }, 3000);

  }

  setCustomDictionary() {
    setTimeout(() => {
      if (this.participantInformation.origin_postal_code_id != 0 || this.participantInformation.origin_postal_code_id != null || this.participantInformation.origin_postal_code_id != '') {
        const keyExists =  this.pincodes.some((item: any) => item.pincode.includes(this.participantInformation.origin_postal_code_name));
        if(keyExists == false){
          this.pincodes.push({ 'id': this.participantInformation.origin_postal_code_id, 'pincode': this.participantInformation.origin_postal_code_name });
          this.pincodes = this.pincodes.slice(0);
        }
      }

      if (this.participantInformation.origin_country_id != 0 || this.participantInformation.origin_country_id != null || this.participantInformation.origin_country_id != '') {
        const keyExists = this.countries.some((item: any) => item.name.includes(this.participantInformation.origin_country_name));
        if (keyExists == false) {
          this.countries.push({ 'id': this.participantInformation.origin_country_id, 'name': this.participantInformation.origin_country_name });
          this.countries = this.countries.slice(0);
        }
      }

      if (this.participantInformation.origin_city_id != 0 || this.participantInformation.origin_city_id != null || this.participantInformation.origin_city_id != '') {
        const keyExists = this.cities.some((item: any) => item.name.includes(this.participantInformation.origin_city_name));
        if (keyExists == false) {
          this.cities.push({ 'id': this.participantInformation.origin_city_id, 'name': this.participantInformation.origin_city_name });
          this.cities = this.cities.slice(0);
        }
      }

      if (this.participantInformation.origin_state_id != 0 || this.participantInformation.origin_state_id != null || this.participantInformation.origin_state_id != '') {
        const keyExists = this.states.some((item: any) => item.name.includes(this.participantInformation.origin_state_name));
        if (keyExists == false) {
          this.states.push({ 'id': this.participantInformation.origin_state_id, 'name': this.participantInformation.origin_state_name });
          this.states = this.states.slice(0);
        }
      }

      if (this.participantInformation.residential_postal_code_id != 0 || this.participantInformation.residential_postal_code_id != null || this.participantInformation.residential_postal_code_id != '') {
        const keyExists = this.pincodes.some((item: any) => item.pincode.includes(this.participantInformation.residential_postal_code_name));
        if (keyExists == false) {
          this.pincodes.push({ 'id': this.participantInformation.residential_postal_code_id, 'pincode': this.participantInformation.residential_postal_code_name });
          this.pincodes = this.pincodes.slice(0);
        }
        this.participantForm.controls['residential_postal_code_id'].setValue(this.participantInformation.residential_postal_code_id > 0 ? this.participantInformation.residential_postal_code_id : null);

      }

      if (this.participantInformation.residential_country_id != 0 || this.participantInformation.residential_country_id != null || this.participantInformation.residential_country_id != '') {
        const keyExists = this.countries.some((item: any) => item.name.includes(this.participantInformation.residential_country_name));
        if (keyExists == false) {
          this.countries.push({ 'id': this.participantInformation.residential_country_id, 'name': this.participantInformation.residential_country_name });
          this.countries = this.countries.slice(0);
        }
        this.participantForm.controls['residential_country_id'].setValue(this.participantInformation.residential_country_id > 0 ? this.participantInformation.residential_country_id : null);
      }

      if (this.participantInformation.residential_city_id != 0 || this.participantInformation.residential_city_id != null || this.participantInformation.residential_city_id != '') {
        const keyExists = this.cities.some((item: any) => item.name.includes(this.participantInformation.residential_city_name));
        if (keyExists == false) {
          this.cities.push({ 'id': this.participantInformation.residential_city_id, 'name': this.participantInformation.residential_city_name });
          this.cities = this.cities.slice(0);
        }
        this.participantForm.controls['residential_city_id'].setValue(this.participantInformation.residential_city_id > 0 ? this.participantInformation.residential_city_id : null);

      }

      if (this.participantInformation.residential_state_id != 0 || this.participantInformation.residential_state_id != null || this.participantInformation.residential_state_id != '') {
        const keyExists = this.states.some((item: any) => item.name.includes(this.participantInformation.residential_state_name));
        if (keyExists == false) {
          this.states.push({ 'id': this.participantInformation.residential_state_id, 'name': this.participantInformation.residential_state_name });
          this.states = this.states.slice(0);
        }
        this.participantForm.controls['residential_state_id'].setValue(this.participantInformation.residential_state_id > 0 ? this.participantInformation.residential_state_id : null);

      }

      if (this.participantInformation.population_id != 0 || this.participantInformation.population_id != null || this.participantInformation.population_id != '') {
        const keyExists = this.populations.some((item: any) => item.name.includes(this.participantInformation.population_name));
        if (keyExists == false) {
          this.populations.push({ 'id': this.participantInformation.population_id, 'name': this.participantInformation.population_name });
          this.populations = this.populations.slice(0);
        }
      }

      if (this.participantInformation.mother_tongue_id != 0 || this.participantInformation.mother_tongue_id != null || this.participantInformation.mother_tongue_id != '') {
        const keyExists = this.languages.some((item: any) => item.name.includes(this.participantInformation.mother_tongue_name));
        if (keyExists == false) {
          this.languages.push({ 'id': this.participantInformation.mother_tongue_id, 'name': this.participantInformation.mother_tongue_name });
          this.languages = this.languages.slice(0);
        }
      }
    }, 4000);
  }

  /**
   * Set residential same as a Birth/Origin/None of these
   * @param value
   */
  sameAsAddress(value: any) {
    if (value === "PLACE_OF_ORIGIN") {
      let originPicode =
        this.participantForm.controls["origin_postal_code_id"].value;
      let originCountryId =
        this.participantForm.controls["origin_country_id"].value;
      let originStateId = this.participantForm.controls["origin_state_id"].value;
      let originCityId = this.participantForm.controls["origin_city_id"].value;

      this.participantForm.controls["residential_postal_code_id"].setValue(originPicode);
      this.participantForm.controls["residential_country_id"].setValue(
        originCountryId
      );
      this.participantForm.controls["residential_state_id"].setValue(
        originStateId
      );
      this.participantForm.controls["residential_city_id"].setValue(
        originCityId
      );
      this.participantForm.controls["residential_postal_address"].setValue(
        null
      );
    } else {
      this.participantForm.controls["residential_postal_code_id"].setValue(null);
      this.participantForm.controls["residential_country_id"].setValue(null);
      this.participantForm.controls["residential_state_id"].setValue(null);
      this.participantForm.controls["residential_city_id"].setValue(null);
      this.participantForm.controls["residential_postal_address"].setValue(
        null
      );
    }
  }

}
