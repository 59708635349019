//import { Component, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-edit-participant',
  templateUrl: './edit-participant.component.html',
  styleUrls: ['./edit-participant.component.scss']
})
export class EditParticipantComponent implements OnInit {

  public dashboard: boolean = true;
  public participantInformation: boolean = false;
  public diseaseHistory: boolean = false;
  public medicationHistory: boolean = false;
  public surgeries: boolean = false;
  public allergiesDrugReactions: boolean = false;
  public recreationalHabits: boolean = false;
  public medicalRecord: boolean = false;
  public familyMedicalHistory: boolean = false;
  public sampleTestInformation: boolean = false;
  public clinicalBiochemicalData: boolean = false;
  public curationNotes: boolean = false;
  public participantDocument: boolean = false;
  public currentMenuPermission: any;
  public eyeProblems: boolean = false;
  public declared_through_options: any = [
    { label: 'Application', value: 'Application' },
    { label: 'Dashboard', value: 'Dashboard' },
    { label: 'PMR', value: 'PMR' },
    { label: 'HTR', value: 'HTR' },
  ];

  selected = 0;

  public PID: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private menuService: MenuService,
  ) {
    this.PID = this.activatedRoute.snapshot.paramMap.get('id');
  }



  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'edit-participant');
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
  }
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'edit-participant');
  }

  onChange(event: MatTabChangeEvent) {
    this.resetComponentPages();
    const tab = event.tab.textLabel;

    console.log(tab, this.selected);

    if (tab == 'participant-information') {
      this.participantInformation = true;
      this.selected = 1;
    } else if (tab == 'participant-dashboard') {
      this.dashboard = true;
      this.selected = 0;
    } else if (tab == 'participant-disease-history') {
      this.diseaseHistory = true;
      this.selected = 2;
    } else if (tab == 'participant-medication-history') {
      this.medicationHistory = true;
      this.selected = 3;
    } else if (tab == 'participant-surgeries') {
      this.surgeries = true;
    } else if (tab == 'participant-allergies-drug-reactions') {
      this.allergiesDrugReactions = true;
    } else if (tab == 'participant-recreational-habits') {
      this.recreationalHabits = true;
    } else if (tab == 'participant-medical-record') {
      this.medicalRecord = true;
    } else if (tab == 'participant-family-medical-history') {
      this.familyMedicalHistory = true;
    } else if (tab == 'participant-sample-test-information') {
      this.sampleTestInformation = true;
      this.selected = 10;
    } else if (tab == 'participant-clinical-biochemical-data') {
      this.clinicalBiochemicalData = true;
    } else if (tab == 'participant-curation-notes') {
      this.curationNotes = true;
    } else if (tab == 'participant-document') {
      this.participantDocument = true;
    }else if (tab == 'participant-eye-problems') {
      this.eyeProblems = true;
    }
     else {
      this.dashboard = true;
      this.selected = 0;
    }
  }

  resetComponentPages() {
    this.dashboard = false;
    this.participantInformation = false;
    this.diseaseHistory = false;
    this.medicationHistory = false;
    this.surgeries = false;
    this.allergiesDrugReactions = false;
    this.recreationalHabits = false;
    this.eyeProblems=false;
    this.medicalRecord = false;
    this.familyMedicalHistory = false;
    this.sampleTestInformation = false;
    this.clinicalBiochemicalData = false;
    this.curationNotes = false;
    this.participantDocument = false;
  }

  receivePIChildData(data: any) {
    this.resetComponentPages();
    this.participantInformation = true;
    this.selected = 1;
  }

  receiveDHChildData(data: any) {
    this.resetComponentPages();
    this.diseaseHistory = true;
    this.selected = 2;
  }

  receiveMedicationChildData(data: any) {
    this.resetComponentPages();
    this.diseaseHistory = true;
    this.selected = 3;
  }

  receiveSTIChildData(data: any) {
    this.resetComponentPages();
    this.sampleTestInformation = true;
    this.selected = 10;
  }
}
