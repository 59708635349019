import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class SurgeryService extends CrudService {

  constructor(public override http: HttpClient) {
    super('surgery', http);
  }

  /**
   * Check Surgery field is unique
   * @returns object
   */
  checkUniqueSurgery(surgeryName: string, id: number) {
    return this.http.get(
      `${this.baseUrl}/check-unique-surgery/${surgeryName}/${id}`
    );
  }
}
