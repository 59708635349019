<div class="tabbing-content-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <form [ngClass]="eyeProblemFormStatus && currentMenuPermission?.edit == true ? 'edit-mode' : 'view-mode untouched'" id="recreationalHabitsForm"
        [formGroup]="eyeProblemForm">
        <div class="container-fluid">
            <!-- PMH medical history 1 start -->
            <div class="col-lg-12">
                <mat-expansion-panel expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Eye Problems
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel-body>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-wrape">
                                    <mat-label>do you have eye prescription glasses?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="wear_eye_glasses">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="input-wrape" *ngIf="eyeProblemForm.controls['wear_eye_glasses']?.value == 'yes'">
                                    <mat-label>Glasses Type</mat-label>
                                    <div class="control-pad">
                                        <ng-select formControlName="wear_eye_glasses_type"
                                            placeholder="What is your glasses type?" [multiple]="true">
                                            <ng-option *ngFor="let option of prescriptionGlasses" [value]="option">{{ option | titlecase
                                            }}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="input-wrape" *ngIf="eyeProblemForm.controls['wear_eye_glasses']?.value == 'yes'">
                                    <mat-label>Did you get it in childhood or adulthood ?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="glasses_since">
                                        <mat-radio-button value="childhood">Childhood</mat-radio-button>
                                        <mat-radio-button value="adulthood">Adulthood</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="input-wrape">
                                    <mat-label>do you have any eye / vision problems?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="have_eye_vision_problem">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape" *ngIf="eyeProblemForm.controls['have_eye_vision_problem']?.value == 'yes'">
                                    <mat-label>do you have any of the following eye/ vision problems?</mat-label>
                                    <div class="control-pad">
                                        <ng-select formControlName="following_eye_vision_problems"
                                            placeholder="What is your eye/ vision problems?" [multiple]="true">
                                            <ng-option *ngFor="let option of eyeProblemList" [value]="option">{{ option | titlecase
                                            }}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="input-wrape" *ngIf="eyeProblemForm.controls['have_eye_vision_problem']?.value == 'yes'">
                                    <mat-label>do you have any other eye/ vision problem?</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <textarea matInput placeholder="do you have any other eye/ vision problem?"
                                            formControlName="other_eye_vision_problem"></textarea>
                                    </mat-form-field>
                                    <i class="ruler"></i>
                                </div>
                                

                                <div class="input-wrape">
                                    <mat-label>do you see or have you seen any doctors for any other problems?</mat-label>
                                    <mat-radio-group aria-label="Select an option" formControlName="visit_any_doctor">
                                        <mat-radio-button value="yes">Yes</mat-radio-button>
                                        <mat-radio-button value="no">No</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="input-wrape" *ngIf="eyeProblemForm.controls['visit_any_doctor']?.value == 'yes'">
                                    <mat-label>what for?</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <textarea matInput placeholder="what for?"
                                            formControlName="reason_for_visit_doctor"></textarea>
                                    </mat-form-field>
                                    <i class="ruler"></i>
                                </div>

                                <div class="input-wrape" *ngIf="eyeProblemForm.controls['visit_any_doctor']?.value == 'yes'">
                                    <mat-label>what did the doctor say was the problem?</mat-label>
                                    <mat-form-field class="example-full-width" appearance="outline">
                                        <textarea matInput placeholder="what did the doctor say was the problem?"
                                            formControlName="doctor_prescription"></textarea>
                                    </mat-form-field>
                                    <i class="ruler"></i>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel-body>
                </mat-expansion-panel>
            </div>
        </div>
        <div class="stic-form-btn-wrape" *ngIf="currentMenuPermission?.create == true || currentMenuPermission.edit == true">
            <button type="button" (click)="clickOnEdit()" *ngIf="!eyeProblemFormStatus" mat-raised-button
                class="btn" color="secoundary">Edit</button>
            <button type="button" (click)="clickOnCancel()" *ngIf="eyeProblemFormStatus" mat-raised-button
                class="btn" color="warn">Cancel</button>
            <button type="button" (click)="eyeProblemsSubmit()" *ngIf="eyeProblemFormStatus"
                mat-raised-button class="btn" color="secoundary">Save</button>
        </div>
    </form>
</div>
