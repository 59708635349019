import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Country } from 'src/app/interfaces/Country';
import { DictionaryService } from '../../../../service/dictionary.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../service/user.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from '../../../../service/common.service';
import { Gender } from 'src/app/interfaces/Gender';
import { CompareValidatorsDirective } from 'src/app/shared/directives/compare-validators.directive';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent implements OnInit {
  public options: boolean = true;
  public selectedValue = {};
  public countryCodes: Country[] = [];
  public userForm!: FormGroup;
  public hideForm: boolean = false;
  public userDetail: any;
  public roleList: any;
  public today = new Date();
  public genderOptions: Gender[] = [];

  constructor(
    private dictionaryService: DictionaryService,
    private fb: FormBuilder,
    protected router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private commonService: CommonService
  ) {
    this.getConstants();
    const userId = localStorage.getItem('userId');
    if (userId !== null && userId !== '') {
      this.router.navigate(['/dashboard']);
    }
    const token = this.route.snapshot.paramMap.get('token') as string;
    this.getUserDetails(token);
  }

  ngOnInit() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    this.getCountryWisePhoneCode();
    this.getCurrentLocationDetails();

    this.userForm = this.fb.group(
      {
        firstName: [
          '',
          [Validators.required, this.commonService.noWhitespaceValidator],
        ],
        middleName: ['', ''],
        lastName: [
          '',
          [Validators.required, this.commonService.noWhitespaceValidator],
        ],
        gender: ['', Validators.required],
        gender_other: ['', ''],
        dob: ['', []],
        phoneNumberCode: [null, ''],
        phoneNumber: ['', ''],
        email: ['', ''],
        mpin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        retypeMpin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
      },
      {
        validator: CompareValidatorsDirective.MatchMpin,
      }
    );
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  /**
   * @author Dhaval Bera
   *
   * This function is used to get gender dictionary
   */
  getCountryWisePhoneCode() {
    this.dictionaryService.getCountryList().subscribe({
      next: (result: any) => {
        this.countryCodes = result.data;
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
   * @author Dhaval Bera
   * This function is used to get Location details
   */
  getCurrentLocationDetails() {
    this.dictionaryService.getCurrentLocationDetails().subscribe({
      next: (result: any) => { },
      error: (e) => {
        console.log('Something went wrong. Please contact to administrator.');
      },
      complete: () => { },
    });
  }

  genderChange(event: any) {
    this.setGenderChange(event.value);
  }
  setGenderChange(gender: any) {
    this.genderOptions.filter((element) => {
      if (element.value == gender) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  /**
   * @author Dhaval Bera
   * This function is used to get email address
   */
  getUserDetails(token: string) {
    this.userService.getEmail(token).subscribe({
      next: (result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          localStorage.setItem('errorMsg', result.message);
          this.router.navigate(['/invalid-token']);
        }
        this.hideForm = true;
        this.userDetail = result.data;

        const roleArr: any[] = [];
        this.userDetail.roleList.forEach((res: { role_name: any }) => {
          roleArr.push(res.role_name);
        });
        this.roleList = roleArr.join(',');
        this.userForm.patchValue({
          email: this.userDetail.email,
        });
        this.userForm.controls['email'].disable();
      },
      error: (e) => {
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
      complete: () => { },
    });
  }
  /**


  /**
   * It will create/update user
   * @author Dhaval Bera
   */
  onSubmit(data: any) {
    if (this.userForm.invalid) {
      this.commonService.validateAllFormFields(this.userForm);
      return;
    }
    console.log(this.userForm.controls['dob'].value);

    const formData: FormData = new FormData();
    formData.append(`first_name`, this.userForm.controls['firstName'].value);
    formData.append(`middle_name`, this.userForm.controls['middleName'].value);
    formData.append(`last_name`, this.userForm.controls['lastName'].value);
    if (this.userForm.controls['dob'].value != '' && !this.commonService.isNullOrUndefined(this.userForm.controls['dob'].value)) {
      formData.append(
        'date_of_birth',
        this.commonService.transformDate(
          this.userForm.controls['dob'].value
        ) as unknown as Blob
      );
    }
    formData.append('email', this.userForm.controls['email'].value);
    formData.append('gender', this.userForm.controls['gender'].value);
    formData.append('mpin', this.userForm.controls['mpin'].value);
    formData.append(`mobile`, this.userForm.controls['phoneNumber'].value);
    if (
      this.userForm.controls['phoneNumberCode'].value !== '' &&
      this.userForm.controls['phoneNumberCode'].value !== null
    ) {
      formData.append(
        `mobile_country_id`,
        this.userForm.controls['phoneNumberCode'].value
      );
    }
    this.userService.activateUser(formData).subscribe({
      next: (result: any) => {
        if (result.status === 1) {
          localStorage.setItem('successMsg', result.message);
          this.router.navigate(['/success']);
        } else {
          this.commonService.showErrorToast('Please check all the fields');
          if (typeof result.message === 'object') {
            for (const key in result.message) {
              if (key === 'last_name') {
                this.userForm.controls['lastName'].setErrors({
                  validField: result.message[key],
                });
              } else if (key === 'first_name') {
                this.userForm.controls['firstName'].setErrors({
                  validField: result.message[key],
                });
              } else if (key === 'gender') {
                this.userForm.controls['gender'].setErrors({
                  validField: result.message[key],
                });
              } else if (key === 'mobile') {
                this.userForm.controls['phoneNumber'].setErrors({
                  validField: result.message[key],
                });
              } else {
                this.userForm
                  .get(key)!
                  .setErrors({ validField: result.message[key] });
              }
            }
          }
        }
      },
      error: () => {
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
      complete: () => { },
    });
  }

  /**
   * Get Constants
   * @author Dhaval Bera
   */
  getConstants() {
    this.userService.getConstants().subscribe({
      next: (result: any) => {
        localStorage.setItem('constants', JSON.stringify(result));
        let genderList = JSON.parse(
          localStorage.getItem('constants') as string
        );

        genderList['GENDERS'].forEach((element: string, key: number) => {
          this.genderOptions.push({
            name: element,
            value: element,
            checked: false,
          });
        });
      },
      error: (e) => { },
      complete: () => { },
    });
  }
}
