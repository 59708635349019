import { Inject, Component, Renderer2, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { DiseaseService } from 'src/app/service/disease.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { DiseaseReclassificationService } from 'src/app/service/snomed-reclassification.service';
import { CommonService } from 'src/app/service/common.service';
import { AppConstants } from 'src/app/app.constant';
import { SelectionModel } from '@angular/cdk/collections';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-disease-validation',
  templateUrl: './disease-validation.component.html',
  styleUrls: ['./disease-validation.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DiseaseValidationComponent implements OnInit {

  public dataSources = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  public expandedElement: PeriodicElement | undefined;
  public columnsToDisplay = ['select', 'id', 'new_pid', 'new_recollection_id', 'site_code', 'first_name', 'middle_name', 'last_name', 'gender', 'collection_date', 'occupation_type',];
  public columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  public totalRecord: number = 1;
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  public lastThreeDaysData: any = [];
  public loading: boolean = false;
  public curationStages: any[] = [
    { id: "PENDING", value: "Stage 1: Disease Validation Pending" },
    { id: "PENDING_REVIEW", value: "Stage 2: Review Pending" },
    { id: "VALIDATED_DISEASE", value: "Curated Disease Data" },
  ];
  public reclasifiedItems: any[] = [];
  public reclasifiedItemsStatus: any[] = [
    { id: "VALIDATED_DISEASE", value: "Validated Disease" },
    { id: "NON_RECLASSIFIABLE_DISEASE", value: "Non Reclassifiable Disease" },
    { id: "NON_VALIDATED_DISEASE", value: "Non-Validated Disease" },
  ];
  public selectedStage!: string;
  public diseaseList: any = [];
  public snomedFilterForm!: FormGroup;
  public snomedList: any;
  public selectedParticipants: any;
  public snomedDetail: any = [];
  public deriveParticipantSnomed: any[] = [];
  public deriveParticipantSnomedForm!: FormGroup;
  public deriveParticipantSnomedFormArray!: FormArray;
  public derivedSnomedFormSubmitted: boolean = false;
  public mapWithSnomed: any;
  public recordLoadingDisease: boolean = false;
  public snomedFormLoading: boolean = false;
  public isClearable: boolean = true;
  public filterStatus: boolean = false;
  public matNoDataRowTitle: string = 'Loading Data...';
  public isQcedOrReviewed: string = 'PENDING';
  public selectedCheckboxParticipants = new SelectionModel<PeriodicElement>(true, []);
  public currentMenuPermission: any;
  /**
   * Constructor
   * @param diseaseReclassificationService
   * @param diseaseService
   * @param formBuilder
   * @param commonService
   * @param router
   */
  constructor(
    private diseaseReclassificationService: DiseaseReclassificationService,
    private diseaseService: DiseaseService,
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
    private commonService: CommonService,
    protected router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private menuService: MenuService
  ) {
    this.getDiseaseList();
  }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getLastThreeDaysSnomedInformation();
    this.getSelectedStageValue();
    const formData: FormData = new FormData();
    formData.append("is_single_disease", "0");
    formData.append("disease_id", "");
    formData.append("derivation_flag", "PENDING");
    this.getParticipantDataForSnomed(formData);
    this.getDiseases();
    this.reclasifiedItems = this.reclasifiedItemsStatus;
    this.snomedFilterForm = this.formBuilder.group({
      diseaseId: [null, ""],
      curation_stage: ["PENDING", ""],
      isSingleDisease: [false, ""],
    });
    // SNOMED derivation Form initialization
    this.deriveParticipantSnomedForm = new FormGroup({});

    this.deriveParticipantSnomedForm.setControl(
      "is_healthy_derived",
      new FormControl(false, [])
    );

    this.deriveParticipantSnomedForm.setControl(
      "curation_stage",
      new FormControl("PENDING", [])
    );

    this.deriveParticipantSnomedForm.setControl(
      "participant_id",
      new FormControl(null, [])
    );

    this.deriveParticipantSnomedForm.setControl(
      "derive_participant_snomed",
      new FormArray([])
    );

    this.deriveParticipantSnomedFormArray = <FormArray>(
      this.deriveParticipantSnomedForm.controls["derive_participant_snomed"]
    );
  }

  /**
  * Get last three days data of SNOMED
  */
  getLastThreeDaysSnomedInformation() {
    this.loading = true;
    this.diseaseReclassificationService
      .getLastThreeDaysSnomedInformation()
      .subscribe(
        (result: any) => {
          this.loading = false
          this.lastThreeDaysData = result.data;
        });
  }

  /**
   *
   * @param stageId
   */
  getSelectedStageValue(stageId: string = "PENDING") {
    this.curationStages.filter((item) => {
      if (item.id == stageId) {
        this.selectedStage = item.value;
      }
    });
  }

  /**
  * Get Disease List
  * @returns object
  */
  getDiseases() {
    this.diseaseService
      .getActiveList()
      .subscribe((result: any) => {
        this.diseaseList = result.data;
      });
  }


  /**
 * Get participant SNOMED data
 * @param body
 */
  getParticipantDataForSnomed(body: any) {
    this.loading = true;
    this.diseaseReclassificationService.getParticipantDataForSnomed(
      this.currentPage + 1,
      this.sortField,
      this.sortOrder,
      this.filterValue,
      this.pageSize,
      body
    ).subscribe({
      next: (result: any) => {
        this.loading = false;
        if (result.data.data != undefined) {
          this.dataSources = new MatTableDataSource(result.data.data);
          this.selectedParticipants = null;
          this.totalRecord = result.data.data.length;
          this.dataSources.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
          if (result.data.data.length != undefined && result.data.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
        } else {
          this.dataSources = new MatTableDataSource();
          this.matNoDataRowTitle = 'No Record Found';
        }
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }

  isExpanded(row: any): string {
    if (this.snomedDetail[row.id]) {
      return 'expanded';
    }
    return 'collapsed';
  }

  /**
   * Get SNOMED Data by id
   * @param row
   * @returns object
   */
  getSnomedDataById(row: any) {
    if (this.snomedDetail[row.id] == undefined) {
      this.snomedDetail = [];
      if (row.id == 0) {
        return;
      }
      this.loading = true;
      this.diseaseReclassificationService
        .getDiseaseAndMedicalHistoryByParticipant(row.id)
        .subscribe((result: any) => {
          this.loading = false;
          this.snomedDetail[row.id] = result.data;
          this.deriveParticipantSnomed = [];
          this.deriveParticipantSnomed = result.data.participant_medical_history;
          this.deriveParticipantSnomedForm.controls["participant_id"].setValue(row.id);
          const curationStage = this.snomedFilterForm.controls["curation_stage"].value;
          this.deriveParticipantSnomedForm.controls["curation_stage"].setValue(curationStage);
          this.initializeDeriveParticipantSnomedArray();
        });
    } else {
      this.snomedDetail = [];
    }
  }
  /**
   * Initialize Derived SNOMED data
   */
  initializeDeriveParticipantSnomedArray() {
    this.deriveParticipantSnomedFormArray.clear();

    if (this.deriveParticipantSnomed.length === 0) {
      this.deriveParticipantSnomedForm.setControl(
        "derive_participant_snomed",
        new FormArray([])
      );
      this.deriveParticipantSnomedFormArray = <FormArray>(
        this.deriveParticipantSnomedForm.controls["derive_participant_snomed"]
      );
      this.deriveParticipantSnomedFormArray.push(this.addDeriveSnomedRow());
    }

    this.deriveParticipantSnomed.forEach((snomedData) => {
      let derivedSnomedName = "";
      let derivedSnomedId = "";
      let derivedDiseaseFlag = null;
      let isReviewed = false;
      let diseaseName = "";

      if (snomedData.diseases) {
        diseaseName = snomedData.diseases.name;

        if (!this.commonService.isNullOrUndefined(snomedData.derived_disease)) {
          derivedSnomedName = snomedData.derived_disease.snomed_name;
        } else {
          derivedSnomedName = snomedData.diseases.snomed_name;
        }

        if (snomedData.derived_disease) {
          derivedSnomedId = snomedData.derived_disease.snomed_id;
        } else {
          derivedSnomedId = snomedData.diseases.snomed_id;
        }
        if (snomedData.derived_disease_flag) {
          derivedDiseaseFlag = snomedData.derived_disease_flag;
          this.reclasifiedItems = this.reclasifiedItemsStatus;
        }
        if (snomedData.is_reviewed_disease) {
          isReviewed = snomedData.is_reviewed_disease;
        }

        this.deriveParticipantSnomedFormArray.push(
          new FormGroup({
            medical_history_id: new FormControl(snomedData.id, []),
            disease_name: new FormControl(diseaseName, []),
            derived_snomed_name: new FormControl(derivedSnomedName, [
              Validators.required,
            ]),
            derived_snomed_id: new FormControl(derivedSnomedId, []),
            derived_flag: new FormControl(derivedDiseaseFlag, []),
            is_reviewed: new FormControl(isReviewed, [Validators.required]),
          })
        );
      }
    });
  }
  /**
   * Add derived snomed empty data row
   * @returns object
   */
  addDeriveSnomedRow() {
    return this.formBuilder.group({
      derived_snomed_name: [null, [Validators.required]],
      derived_snomed_id: [null, []],
      derived_flag: [null, []],
      is_reviewed: [false, []],
    });
  }

  diseaseDetails(disease: any, index: any) {
    if (disease == undefined) {
      return;
    }
    this.snomedFormLoading = true;
    this.diseaseService.getRow(disease.id).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.deriveParticipantSnomedFormArray.controls[index].get('derived_snomed_id')?.setValue(result.data.snomed_id);
        }
        this.snomedFormLoading = false;
      }
    });
  }

  /**
   * Get Disease List
   * @returns object
   */
  getDiseaseList() {
    this.recordLoadingDisease = true
    this.dictionaryService.getDiseaseList().subscribe({
      next: (result: any) => {
        this.recordLoadingDisease = false
        if (result.status === AppConstants.serverSuccessStatus) {
          this.mapWithSnomed = result.data;
        } else {
          this.mapWithSnomed = [];
        }
      },
      error: () => {
        this.recordLoadingDisease = false
      }
    });
  }


  clickEvent() {
    this.filterStatus = !this.filterStatus;
    this.renderer.addClass(this.document.body, 'embedded-body');
    this.snomedDetail = [];
  }
  overLayClose() {
    this.filterStatus = !this.filterStatus;
    this.renderer.removeClass(this.document.body, 'embedded-body');
  }

  clearForm(snomedFilterForm: any) {
    this.filterStatus = !this.filterStatus;
    this.renderer.removeClass(this.document.body, 'embedded-body');
    snomedFilterForm.reset();
    this.snomedFilterForm.controls["curation_stage"].setValue("PENDING");
    this.filterSnomedData();
  }
  /**
   *
   * @param isFilterOpen
   * @returns
   */
  filterSnomedData(isFilterOpen: boolean = false) {
    if (isFilterOpen != true) {
      this.filterStatus = !this.filterStatus;
    }
    this.renderer.removeClass(this.document.body, 'embedded-body');
    this.isQcedOrReviewed = this.snomedFilterForm.controls["curation_stage"].value;
    let curationStage = this.snomedFilterForm.controls["curation_stage"].value;
    this.getSelectedStageValue(curationStage);
    this.reclasifiedItems = this.reclasifiedItemsStatus;
    this.isClearable = true;

    if (curationStage == "VALIDATED_DISEASE") {
      this.isClearable = false;
      this.reclasifiedItems = this.reclasifiedItemsStatus;
    }

    if (this.snomedFilterForm.invalid) {
      this.commonService.validateAllFormFields(this.snomedFilterForm);
      return;
    }

    const formData: FormData = new FormData();
    formData.append("is_single_disease", this.snomedFilterForm.controls["isSingleDisease"].value);
    let diseaseId = this.snomedFilterForm.controls["diseaseId"].value;
    diseaseId = diseaseId === "" || diseaseId === null ? "" : diseaseId;
    formData.append("disease_id", diseaseId);

    curationStage = curationStage === "" || curationStage === null ? "" : curationStage;
    formData.append("derivation_flag", curationStage);

    this.getParticipantDataForSnomed(formData);
  }

  /**
   * This function is used to update qced and reviewed
   */
  updateQcReviewedSnomed(type: string) {
    if (this.selectedCheckboxParticipants.selected.length <= 0) {
      this.commonService.showErrorToast('Please select at least one Participant');
      return;
    }
    this.loading = true;

    const formData: FormData = new FormData();

    let curationStage = this.snomedFilterForm.controls["curation_stage"].value;
    curationStage =
      curationStage === "" || curationStage === null ? "" : curationStage;
    let participantIds: any[] = [];
    this.selectedCheckboxParticipants.selected.forEach((res: any) => {
      participantIds.push(res.id);
    })

    formData.append("participantIds", participantIds.toString());
    formData.append("is_qced_reviewed", curationStage);

    this.diseaseReclassificationService
      .updateReviewedSnomed(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(`SNOMED ${type} successfully`);
            this.getLastThreeDaysSnomedInformation();
          } else {
            this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
          }
          this.loading = false;
          this.filterSnomedData(true);
        },
        error: (err: any) => {
          this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
          this.loading = false;
        }
      });
  }


  clickEventTableForm(event: any) {
    this.dataSources.data.find(res => {
      if (res.id == event.id) {
        res.editing = true;
      }
    });
    this.derivedSnomedFormSubmitted = false;
    this.deriveParticipantSnomedForm.markAsUntouched();
  }
  cancelEventTableForm(event: any) {
    this.dataSources.data.find(res => {
      if (res.id == event.id) {
        res.editing = false;
      }
    })
    this.derivedSnomedFormSubmitted = false;
    this.deriveParticipantSnomedForm.markAsUntouched();
  }
  saveSnomedDeriveData(event: any) {
    this.derivedSnomedFormSubmitted = true;
    this.deriveParticipantSnomedFormArray.controls.forEach(
      (derivedSnomedObject, index) => {
        derivedSnomedObject.get("derived_snomed_name")?.setValidators(Validators.required);
        derivedSnomedObject.get("derived_snomed_id")?.setValidators(null);
        derivedSnomedObject.get("derived_flag")?.setValidators(null);
        derivedSnomedObject.get("is_reviewed")?.setValidators(null);

        derivedSnomedObject.get("derived_snomed_name")?.updateValueAndValidity();
        derivedSnomedObject.get("derived_snomed_id")?.updateValueAndValidity();
        derivedSnomedObject.get("derived_flag")?.updateValueAndValidity();
        derivedSnomedObject.get("is_reviewed")?.updateValueAndValidity();
      }
    );

    if (this.deriveParticipantSnomedForm.invalid) {
      this.commonService.validateAllFormFields(this.deriveParticipantSnomedForm);
      this.commonService.showErrorToast("Please enter correct values");
      return;
    }

    this.loading = true;
    this.diseaseReclassificationService
      .saveSnomedDeriveData(this.deriveParticipantSnomedForm.value)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast("Disease derived with SNOMED successfully");
            this.dataSources.data.find(res => {
              if (res.id == event.id) {
                res.editing = false;
              }
            })
            this.filterSnomedData(true);
          } else {
            this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
          }
          this.loading = false;
          this.getLastThreeDaysSnomedInformation();
        },
        error: (err: any) => {
          this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
          this.loading = false;
        }
      });
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      this.sortOrder = sortState.direction;
      const formData: FormData = new FormData();
      formData.append("is_single_disease", "0");
      formData.append("disease_id", "");
      formData.append("derivation_flag", "PENDING");
      this.getParticipantDataForSnomed(formData);
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const formData: FormData = new FormData();
    formData.append("is_single_disease", "0");
    formData.append("disease_id", "");
    formData.append("derivation_flag", "PENDING");
    this.getParticipantDataForSnomed(formData);
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.currentPage = 0;
    const formData: FormData = new FormData();
    formData.append("is_single_disease", "0");
    formData.append("disease_id", "");
    formData.append("derivation_flag", this.isQcedOrReviewed);
    this.getParticipantDataForSnomed(formData);
  }


  isAllSelected() {
    const numSelected = this.selectedCheckboxParticipants.selected.length;
    const numRows = this.dataSources.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selectedCheckboxParticipants.clear() :
      this.dataSources.data.forEach(row => this.selectedCheckboxParticipants.select(row));
  }
}
export interface PeriodicElement {
  select: any;
  editing: boolean;
  id: number;
  new_pid: string;
  new_recollection_id: string;
  site_code: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  collection_date: string;
  occupation_type: string;
  p_organ_site: string;
  p_diease_name: string;
  snomed_id: string;
  year_diagnosed: string;
  family_member: string;
  f_organ_site: string;
  f_diease_name: string;
  f_diesease_name: string;
  f_year_diagnosed: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];