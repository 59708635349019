import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialsModule } from './../../../modules/materials.module';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';

import { DashboardComponent } from './dashboard.component';
import { DiseaseChartComponent } from './disease-chart/disease-chart.component';
import { ParticipantCountChartComponent } from './participant-count-chart/participant-count-chart.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopulationChartComponent } from './population-chart/population-chart.component';
import { StateChartComponent } from './state-chart/state-chart.component';
import { GenderChartComponent } from './gender-chart/gender-chart.component';
import { SiteWiseParticipantCountsComponent } from './site-wise-participant-counts/site-wise-participant-counts.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { StateMapChartComponent } from './state-map-chart/state-map-chart.component';
import { LatestSitesComponent } from './latest-sites/latest-sites.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    MaterialsModule,
    NgxEchartsModule,
    NgSelectModule,
    Ng2GoogleChartsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    DashboardComponent,
    DiseaseChartComponent,
    ParticipantCountChartComponent,
    PopulationChartComponent,
    StateChartComponent,
    GenderChartComponent,
    SiteWiseParticipantCountsComponent,
    StateMapChartComponent,
    LatestSitesComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [

  ],
})

export class DashboardModule {


}
