<div class="card-wrape m-b-0">
    <div class="card-head with-dropdown">
        <h4 class="left-text">Sample Collection Timeline</h4>
        <div class="right-control">
            <div class="input-wrape">
                <div class="control-pad">
                    <ng-select [items]="percentageOrAbsoluteCounts" [clearable]="false" [dropdownPosition]="'bottom'"
                        [searchable]="false" placeholder="Please Select" bindLabel="value" bindValue="id"
                        [(ngModel)]="collectionDateWisePercentOrCount" (change)="
                    showRecordsInPercentOrAbsoluteCount('collection_date_wise', $event)
                  ">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <mat-spinner *ngIf="monthWiseChartLoader"></mat-spinner>
        <div class="progress-overlay"></div>
        <div *ngIf="totalCollectionDateWiseParticipantChartCounts == 0 && !monthWiseChartLoader;
        else foundCollectoinDateWiseParticipantChartData">
            <div class="no-data-found">
                <div class="no-data-container">
                    <div class="no-data-icon"></div>
                    <div class="no-data-text">NO DATA FOUND</div>
                </div>
            </div>
        </div>
        <ng-template #foundCollectoinDateWiseParticipantChartData>
            <div echarts [options]="options" class="echart"></div>
        </ng-template>
    </div>
</div>