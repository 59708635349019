<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1>{{title}}</h1>
        <ul class="bradcrumb">
            <li>{{title}}</li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>

    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <div class="card-wrape">
                    <div class="card-head">
                        <h4 class="left-text">Document List Filter</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-4 col-md-4">
                                <div class="input-wrape">
                                    <mat-label>Document Filter</mat-label>
                                    <div class="control-pad">
                                        <ng-select [dropdownPosition]="'bottom'"
                                            (change)="changeParticipantDocumentFilter($event)" [multiple]="false"
                                            [closeOnSelect]="true" [searchable]="false" [multiple]="false"
                                            placeholder="Document filter">
                                            <ng-option value="pending">Pending For Mapping</ng-option>
                                            <ng-option value="mapped">Mapped Documents</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <div class="card-wrape">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div class="progress-overlay"></div>
                    <div class="card-head">
                        <h4 class="left-text">{{title}}</h4>
                        <div class="right-control">
                            <div class="table-search-wrape side-info">
                                <span class="icon"></span>
                                <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control"
                                    type="text">
                                <div class="info-pad popup-over">
                                    <button class="info-icon"></button>
                                    <div class="info-section popupcontent">
                                        ID, New PID, New Recollection ID, Site Code, Document Name, Document Type,
                                        Uploaded Date
                                    </div>
                                </div>
                            </div>
                            <button *ngIf="currentMenuPermission.edit == true" href="javascript:void(0);" routerLink="/participant-documents/import"
                                class="primary btn" mat-raised-button color="secoundary"
                                title="Upload Participant Documents">Upload Participant Documents</button>
                        </div>
                    </div>
                    <div class="card-body table-section">
                        <div class="table-wrape">
                            <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortFilter($event)">
                                <ng-container matColumnDef="id">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> ID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="new_pid">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="new_pid">
                                        New PID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="new_pid">
                                        <a href="#" routerLink="/participant/edit/{{element.participant_id}}"
                                            target="_blank" title="View Participant">
                                            {{ element.new_pid }}
                                        </a>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="new_recollection_id">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> New Recollection ID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" title="{{element.new_recollection_id}}">
                                        <span>{{element.new_recollection_id}}</span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="site_code">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Site Code </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.site_code}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Document Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="type">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Document Type </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="{{element.type}}">
                                        {{element.type}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="uploaded_date">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Uploaded Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.uploaded_date | date: "dd MMM y"}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                                        <div class="icons-wrape">
                                            <a *ngIf="!element.participant_id && currentMenuPermission.edit == true" routerLink="edit/{{element.id}}"
                                                type="button" class="theme-icon blue-bg-btn edit-icon" title="Edit">
                                                <i class="fa fa-edit"></i>
                                            </a>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                <div *matNoDataRow>{{matNoDataRowTitle}}</div>
                            </mat-table>
                            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage"
                                [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)"
                                aria-label="Select page" showFirstLastButtons>
                            </mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
