import { Inject, Component, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface SideNav {
  id: any;
  name: string;
  icons: string;
  path: string;
  children?: SideNav[];
}
interface ExampleFlatNode {
  id: any;
  expandable: boolean;
  name: string;
  level: number;
  path: string;
  icons: string;
  parentNodes: SideNav[];
  active: boolean;
}

const MENU_LIST: SideNav[] = [
  {
    id: 1,
    name: 'Dashboard',
    icons: 'dashboard',
    path: 'dashboard',
  },
  {
    id: 2,
    name: 'Data Management',
    icons: 'd-management',
    path: '#',
    children: [
      {
        id: 3,
        name: 'Participant Management',
        icons: 'participant-management',
        path: 'participant'
      },
      {
        id: 5,
        name: 'Participant Data Filtration',
        icons: 'participant-data-filtration',
        path: 'participant-data-filtration'
      },
      {
        id: 6,
        name: 'Biobank and Sequencing Statistics',
        icons: 'bio-bank-sequencing',
        path: 'bio-bank-sequencing',
      },
      // {
      //   id: 'Biobank-6',
      //   name: 'Biobank and Sequencing Statistics-old',
      //   icons: 'bio-bank-sequencing',
      //   path: 'bio-bank-sequencing-old',
      // },
      {
        id: 7,
        name: 'Participant Documents',
        icons: 'participant-documents',
        path: 'participant-documents',
      },
      {
        id: 8,
        name: 'Site Management',
        icons: 'site-management',
        path: '#',
        children: [
          {
            id: 9,
            name: 'Sites',
            icons: 'site',
            path: 'site',
          },
          {
            id: 10,
            name: 'Matrix',
            icons: 'matrix',
            path: 'site/matrix',
          },
          {
            id: 11,
            name: 'Projects',
            icons: 'projects',
            path: 'site/project',
          },
          {
            id: 12,
            name: 'Diagnostic Labs',
            icons: 'diagnostic-labs',
            path: 'site/diagnostic-lab',
          },
          {
            id: 77,
            name: 'Loinc Codes',
            icons: 'loinc-codes',
            path: 'site/loinc-codes',
          },
            {
            id: 13,
            name: 'Ethics Committee',
            icons: 'ethics-committee',
            path: 'site/ethics-committee',
          },
          {
            id: 58,
            name: 'Camp Data Verification',
            icons: 'camp-data-verification',
            path: 'site/camp-data-verification',
          },
        ],
      },
      {
        id: 14,
        name: 'Recontact Management',
        icons: 'recontact-management',
        path: '#',
        children: [
          { id: 15, name: 'Recontact', icons: 're-contact', path: 'recontact' },
        ],
      },
      {
        id: 16,
        name: 'Participant Database',
        icons: 'participant-database',
        path: '#',
        children: [
          { id: 17, name: 'HTR Management', icons: 'participant-database', path: 'htr' },
          { id: 19, name: 'Lab Data', icons: 'lab-data', path: 'lab-data' },
        ],
      },
      {
        id: 4,
        name: 'Duplicate Participants',
        icons: 'participant-duplicate',
        path: 'participant/duplicate'
      },
      {
        id: 5,
        name: 'Internal Participant',
        icons: 'participant-internal',
        path: 'internal/participant'
      },
    ],
  },
  {
    id: 20,
    name: 'Super Administration',
    icons: 'super-administration',
    path: '#',
    children: [
      { id: 21, name: 'Manage User', icons: 'manage-user', path: 'manage-user' },
      { id: 22, name: 'Manage Role', icons: 'manage-role', path: 'manage-role' },
      // { id: 78, name: 'Activity Tracklog', icons: 'activity-tracklog', path: 'qc-track-logs' },
    ],
  },
  {
    id: 23,
    name: 'Dictionary Management',
    icons: 'manage-dictionary',
    path: '#',
    children: [
      {
        id: 24,
        name: 'Location Dictionary',
        icons: 'location-dictionary',
        path: '#',
        children: [
          {
            id: 25,
            name: 'Country',
            icons: 'country',
            path: 'dictionary-management/country',
          },
          {
            id: 26,
            name: 'State',
            icons: 'state',
            path: 'dictionary-management/state',
          },
          {
            id: 27,
            name: 'City',
            icons: 'city',
            path: 'dictionary-management/city'
          },
          {
            id: 28,
            name: 'Pincode',
            icons: 'pincode',
            path: 'dictionary-management/pincode',
          },
        ],
      },
      {
        id: 29,
        name: 'Language Dictionary',
        icons: 'language-dictionary',
        path: '#',
        children: [
          {
            id: 30,
            name: 'Language',
            icons: 'language',
            path: 'dictionary-management/language',
          },
        ],
      },
      {
        id: 31,
        name: 'Population Dictionary',
        icons: 'Population-dictionary',
        path: '#',
        children: [
          {
            id: 32,
            name: 'Religion',
            icons: 'religion',
            path: 'dictionary-management/religion',
          },
          {
            id: 33,
            name: 'Sub Population',
            icons: 'population',
            path: 'dictionary-management/sub-population',
          },
        ],
      },
      {
        id: 34,
        name: 'Medicine Dictionary',
        icons: 'medicine-dictionary',
        path: '#',
        children: [
          {
            id: 35,
            name: 'Drug Category',
            icons: 'drug-category',
            path: 'dictionary-management/drug-category',
          },
          {
            id: 36,
            name: 'Drug Generic',
            icons: 'generic-drug',
            path: 'dictionary-management/drug-generic',
          },
          {
            id: 37,
            name: 'Drug Brand',
            icons: 'drug-brand',
            path: 'dictionary-management/drug-brand',
          },
          {
            id: 38,
            name: 'Approved Drugs',
            icons: 'approved-drug',
            path: 'dictionary-management/approved-drugs',
          },
        ],
      },
      {
        id: 39,
        name: 'Disease Dictionary',
        icons: 'disease-dictionary',
        path: '#',
        children: [
          {
            id: 40,
            name: 'Disease',
            icons: 'disease',
            path: 'dictionary-management/disease',
          },
          {
            id: 41,
            name: 'Organ/Site',
            icons: 'organ-site',
            path: 'dictionary-management/organ-site',
          },
        ],
      },
      {
        id: 70,
        name: 'Other Dictionary',
        icons: 'other-dictionary',
        path: '#',
        children: [
          {
            id: 'other-dictionary-2',
            name: 'Occupations',
            icons: 'occupations',
            path: 'dictionary-management/occupations',
          },
          {
            id: 71,
            name: 'Surgery',
            icons: 'surgery',
            path: 'dictionary-management/surgery',
          },
          {
            id: 75,
            name: 'Unique Identity',
            icons: 'unique-identity',
            path: 'dictionary-management/unique-identity',
          },
          {
            id: 'allergic-to-what',
            name: 'Allergic to What',
            icons: 'allergic-to-what',
            path: 'dictionary-management/allergic-to-what',
          },
          {
            id: 'allergic-reaction',
            name: 'Allergic Reaction',
            icons: 'allergic-reaction',
            path: 'dictionary-management/allergic-reaction',
          },
        ],
      },
    ],
  },
  {
    id: 42,
    name: 'Data Curation',
    icons: 'data-curation',
    path: '#',
    children: [
      {
        id: 43,
        name: 'Dictionary Curation',
        icons: 'dictionary-curation',
        path: '#',
        children: [
          {
            id: 44,
            name: 'Location',
            icons: 'location',
            path: '#',
            children: [
              {
                id: 45,
                name: 'Country',
                icons: 'country',
                path: 'dictionary-management/country/curation-pending',
              },
              {
                id: 46,
                name: 'State',
                icons: 'state',
                path: 'dictionary-management/state/curation-pending',
              },
              {
                id: 47,
                name: 'City',
                icons: 'city',
                path: 'dictionary-management/city/curation-pending',
              },
              {
                id: 48,
                name: 'Pincode',
                icons: 'pincode',
                path: 'dictionary-management/pincode/curation-pending',
              },
            ],
          },
          {
            id: 49,
            name: 'Disease',
            icons: 'disease',
            path: '#',
            children: [
              {
                id: 50,
                name: 'Disease',
                icons: 'disease',
                path: 'dictionary-management/disease/curation-pending',
              },
            ],
          },
          {
            id: 51,
            name: 'Medicine',
            icons: 'Medicine',
            path: '#',
            children: [
              {
                id: 52,
                name: ' Drug Brand / Generic / Category',
                icons: 'Medicine',
                path: 'dictionary-management/temp-brand-generic/curation-pending',
              },
            ],
          },
          {
            id: 53,
            name: 'Population',
            icons: 'population',
            path: '#',
            children: [
              {
                id: 54,
                name: 'Sub Population',
                icons: 'population',
                path: 'dictionary-management/sub-population/curation-pending',
              },
            ],
          },
          {
            id: 72,
            name: 'Other',
            icons: 'other',
            path: '#',
            children: [
              {
                id: 'other-occupations-curation-1',
                name: 'Occupations',
                icons: 'occupations',
                path: 'dictionary-management/occupations/curation-pending',
              },
              {
                id: 73,
                name: 'Surgery',
                icons: 'surgery',
                path: 'dictionary-management/surgery/curation-pending',
              },
              {
                id: 'allergic-to-what-curation',
                name: 'Allergic To What',
                icons: 'allergic-to-what',
                path: 'dictionary-management/allergic-to-what/curation-pending',
              },
              {
                id: 'allergic-reaction-curation',
                name: 'Allergic Reaction',
                icons: 'allergic-reaction',
                path: 'dictionary-management/allergic-reaction/curation-pending',
              },
            ],
          },
        ],
      },
      {
        id: 55,
        name: 'Participant Data Validation',
        icons: 'participant-data-validation',
        path: '#',
        children: [
          {
            id: 56,
            name: 'Population Re-Classification',
            icons: 'population-validation',
            path: 'participant-data-validation/population-validation',
          },
          {
            id: 57,
            name: 'Disease Validation',
            icons: 'disease-validation',
            path: 'participant-data-validation/disease-validation',
          },
          {
            id: 76,
            name: 'Gender Validation',
            icons: 'gender-validation',
            path: 'participant-data-validation/gender-validation',
          },
          {
            id: 77,
            name: 'Physiological Measurements Validation',
            icons: 'physiological-measurements-validation',
            path: 'participant-data-validation/physiological-measurements-validation',
          },
        ]
      },
    ],
  },
];


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public status: boolean = false;
  public disable: boolean = true;
  public enable: boolean = false;
  public options = { autoHide: false, scrollbarMinSize: 100 };
  public sidebarShow: boolean = false;
  public showFiller = false;
  public urlInfo!: any;
  private transformer = (node: SideNav, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      path: node.path,
      icons: node.icons,
      parentNodes: this.getParentNodes(node),
      active: false,
    };
  };
  public treeControlFlat = new FlatTreeControl<ExampleFlatNode>((node) => node.level, (node) => node.expandable);
  public treeFlattener = new MatTreeFlattener(this.transformer, (node) => node.level, (node) => node.expandable, (node) => node.children);
  public dataSourceFlat = new MatTreeFlatDataSource(this.treeControlFlat, this.treeFlattener);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.dataSourceFlat.data = MENU_LIST;
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        if (res.url.indexOf('participant/edit') == 1 || res.url.indexOf('internal/participant/edit/') == 1) {
          this.sidebarShow = true;
          setTimeout(() => {
            this.sidebarShowHide();
          }, 100);
        }
        this.expand(res.url);
      }
    });
  }

  overLayClose() {
    this.status = !this.status;
    this.disable = false;
    this.enable = true;
    this.renderer.removeClass(this.document.body, 'embedded-body');
  }
  hasChildFlat = (_: number, node: ExampleFlatNode) => node.expandable;

  expand(url: string): any {
    let activeElement: any = [];
    this.treeControlFlat.dataNodes.forEach((element: any, index: number) => {
      if (url.indexOf(element.path) == 1) {
        activeElement.push(element);
      } else {
        element.active = false;
        this.treeControlFlat.collapse(this.treeControlFlat.dataNodes[index]);
      }
    });
    let urlPathArray = url.substring(1).split('/');
    let activeUrl = '';
    activeElement.forEach((actElement: any) => {
      let isCuration = actElement.path.indexOf('curation-pending');
      if (actElement.path.indexOf('curation-pending') > 0 || url.indexOf('curation-pending') > 0) {
        activeUrl = urlPathArray[0] + '/' + urlPathArray[1] + '/' + urlPathArray[2]
        if (isCuration > 1 && actElement.path === activeUrl.replace('/undefined', '')) {
          this.expandWithParent(actElement);
        } else {
          actElement.active = false;
          this.treeControlFlat.collapse(actElement);
        }
      } else if (activeElement.length == 1) {
        this.expandWithParent(actElement);
      } else {
        if (url.indexOf('edit') == 6 || url.indexOf('add') == 6 || url.indexOf('edit') == 23 || url.indexOf('import') == 23) {
          if (url.indexOf('ethics-committee') == 6) {
            activeUrl = urlPathArray[0] + '/' + urlPathArray[1]
          } else {
            activeUrl = urlPathArray[0]
          }
        } else {
          activeUrl = urlPathArray[0] + '/' + urlPathArray[1]
        }
        if (actElement.path == activeUrl.replace('/undefined', '')) {
          this.expandWithParent(actElement);
        } else {
          actElement.active = false;
          this.treeControlFlat.collapse(actElement);
        }
      }
    });

  }

  expandWithParent(element: any) {
    if (element.parentNodes.length > 0) {
      element.parentNodes.forEach((parentsElement: any, parentsIndex: number) => {
        this.treeControlFlat.dataNodes.forEach((innerEle) => {
          if (parentsElement.id == innerEle.id) {
            innerEle.active = true;
            element.active = true;
            this.treeControlFlat.expand(innerEle);
          }
        });
      });
    }
  }

  getParentNodes(node: SideNav): SideNav[] {
    const parentNodes = [node];
    let parent = this.getParent(node);
    while (parent !== null) {
      parentNodes.unshift(parent);
      parent = this.getParent(parent);
    }
    return parentNodes;
  }

  getParent(node: SideNav): SideNav | null {
    const data = this.dataSourceFlat.data;
    const stack: SideNav[] = [];
    stack.push(...data);

    while (stack.length > 0) {
      const current = stack.pop();
      if (current === node) {
        return null;
      }
      if (current?.children?.some((child) => child === node)) {
        return current;
      }
      if (current?.children) {
        stack.push(...current.children);
      }
    }
    return null;
  }

  setActiveChange(path: string) {
    this.treeControlFlat.collapseAll();
    this.sidebarShow = false;
    this.router.navigate([path]);
    this.expand(this.router.url);
  }
  openSidebarOnIconClick() {
    this.sidebarShow = false;
  }

  sidebarShowHide() {
    if (this.sidebarShow) {
      this.treeControlFlat.collapseAll();
    } else {
      this.expand(this.router.url);
    }
  }
}
