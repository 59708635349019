import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { ParticipantDocumentsService } from 'src/app/service/participant-documents.service';

@Component({
  selector: 'app-document-import',
  templateUrl: './document-import.component.html',
  styleUrls: ['./document-import.component.scss']
})
export class DocumentImportComponent implements OnInit {

  public participantDocumentImportForm!: FormGroup;
  public documentFile!: File;
  public documentFileType: any = [
    { label: 'Google Drive', value: 'Google' },
    { label: 'File Upload', value: 'File' },
  ];
  public selectedDocumentFilesName: any = "";
  public showLoading: Boolean = false;
  public imageFiles: Blob[] = [];
  public title: string = 'Participant Document Upload';

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    protected router: Router,
    protected participantDocumentsService: ParticipantDocumentsService,
  ) { }

  ngOnInit(): void {
    this.participantDocumentImportForm = this.formBuilder.group({
      upload_from: [null, [Validators.required]],
      google_drive_folder_id: ['', [Validators.required]],
      document_name: [null, [Validators.required]],
    });
    this.participantDocumentImportForm.patchValue({
      upload_from: 'Google'
    });
  }

  /**
   * Add Participant Documents
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  participantDocumentSubmit() {
    this.onSubmitValidateSetError();
    if (this.participantDocumentImportForm.invalid) {
      this.commonService.validateAllFormFields(this.participantDocumentImportForm);
      return;
    }
    const formData: FormData = new FormData();
    if (!this.commonService.isNullOrUndefined(this.imageFiles)) {
      for (let i = 0; i < this.imageFiles.length; i++) {
        formData.append("document_name[]", this.imageFiles[i]);
      }
    }
    if (!this.commonService.isNullOrUndefined(this.participantDocumentImportForm.controls['upload_from'].value)) {
      formData.append("upload_from", this.participantDocumentImportForm.controls['upload_from'].value);
    }
    if (!this.commonService.isNullOrUndefined(this.participantDocumentImportForm.controls['google_drive_folder_id'].value)) {
      formData.append("google_drive_folder_id", this.participantDocumentImportForm.controls['google_drive_folder_id'].value);
    }
    this.showLoading = true;
    this.participantDocumentsService
      .importParticipantDocuments(formData)
      .subscribe({
        next: (result: any) => {
          this.showLoading = false;
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast(result.message);
            this.imageFiles = [];
            this.router.navigate(['/participant-documents']);
          } else if (result.status === AppConstants.serverFailStatus) {
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err: any) => {
          this.showLoading = false;
          this.commonService.showErrorToast("Something went wrong. Please Enter Proper Data.");
        }
      });
  }

  /**
  * @author Dhaval Bera
  * This function is used to Validate upload From Type
  * @param event
  */
  uploadFromChange(event: any) {
    if (event) {
      if (event.value != "Google") {
        this.participantDocumentImportForm.controls["google_drive_folder_id"].setValue("");
        this.imageFiles = [];
      } else {
        this.participantDocumentImportForm.setControl("document_name", new FormControl(null, [])
        );
      }
    } else {
      this.participantDocumentImportForm.setControl("document_name", new FormControl(null, [])
      );
    }
  }

  onSelectDocumentFile(event: any) {
    this.imageFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.documentFile = <File>event.target.files[i];
      if ((this.documentFile !== undefined) && (this.documentFile.type !== 'application/pdf')) {
        this.imageFiles = [];
        this.participantDocumentImportForm.controls['document_name'].setErrors({ 'fileInvalidType': 'File should be .pdf type' });
      } else if ((this.documentFile !== undefined) && ((this.documentFile.size / 1024 / 1024) > 20)) {
        this.imageFiles = [];
        this.participantDocumentImportForm.controls['document_name'].setErrors({ 'fileInvalidSize': 'Files size limit exceeded. Max file size is 20 MB.' });
      } else {
        this.imageFiles.push(event.target.files[i]);
      }
    }
  }


  onSubmitValidateSetError() {
    if (this.participantDocumentImportForm.controls['upload_from'].value == null) {
      this.participantDocumentImportForm.controls['upload_from'].setErrors({ 'requiredfield': 'Please choose upload document type' });
      return true;
    } else {
      if (this.participantDocumentImportForm.controls['upload_from'].value != "Google") {
        this.participantDocumentImportForm.controls['google_drive_folder_id'].setErrors(null);
        if (this.participantDocumentImportForm.controls['document_name'].value == null) {
          this.participantDocumentImportForm.controls['document_name'].setErrors({ 'requiredfield': 'Please select pdf file(s)' });
          return true;
        }
      } else {
        this.participantDocumentImportForm.controls['document_name'].setErrors(null);
        if (this.participantDocumentImportForm.controls['google_drive_folder_id'].value == '') {
          this.participantDocumentImportForm.controls['google_drive_folder_id'].setErrors({ 'requiredfield': 'Please Enter Google Drive folder ID' });
          return true;
        }
      }
    }
    return false;
  }

}
