import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { ParticipantDocumentsService } from 'src/app/service/participant-documents.service';
import { ParticipantService } from 'src/app/service/participant.service';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit {

  public title: string = 'Participant Document';
  public participantEditDocumentForm!: FormGroup;
  public showLoading: Boolean = false;
  public participant: any;
  public selectedParticipantID: any = "";
  public participantHeaderDetails: any;
  public documentDetail: any;
  public id: any;

  constructor(
    public participantService: ParticipantService,
    private participantDocumentsService: ParticipantDocumentsService,
    private route: ActivatedRoute,
    protected router: Router,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.participantEditDocumentForm = this.formBuilder.group({
      participant_id: [null, [Validators.required]],
      document_id: [null, []],
      document_type: [null, []],
      document_name: [null, []],
    });
    this.setEditData();
  }

  participantDocumentSubmit() {
    const formData: FormData = new FormData();
    if (this.participantEditDocumentForm.invalid) {
      this.commonService.validateAllFormFields(this.participantEditDocumentForm);
      return;
    }
    formData.append("participant_id", String(this.selectedParticipantID));
    formData.append("document_id", String(this.participantEditDocumentForm.controls['document_id'].value));

    this.showLoading = true;
    this.participantDocumentsService
      .mapDocumentWithParticipant(formData, this.participantEditDocumentForm.controls['document_id'].value)
      .subscribe({
        next: (result: any) => {
          this.showLoading = false;
          if (result.status === AppConstants.serverSuccessStatus) {
            this.commonService.showSuccessToast("Document mapped with participant successfully");
            this.router.navigate(['/participant-documents']);
          } else if (result.status === AppConstants.serverFailStatus) {
            this.commonService.showErrorToast(result.message);
          }
        },
        error: (err) => {
          this.showLoading = false;
          this.commonService.showErrorToast("Something went wrong. Please Enter Proper Data.");
        },
      });
  }


  /**
   * it will set edit data in form
   * @author Dhaval Bera
   */
  setEditData() {
    // Document Details 
    this.participantDocumentsService.getRow(this.id).subscribe({
      next: (result: any) => {
        if (result.data) {
          this.documentDetail = result.data;
          this.participantEditDocumentForm.controls['participant_id'].setValue(this.documentDetail.participant_id);
          this.participantEditDocumentForm.controls['document_id'].setValue(this.documentDetail.id);
          this.participantEditDocumentForm.controls['document_type'].setValue(this.documentDetail.type);
          this.participantEditDocumentForm.controls['document_name'].setValue(this.documentDetail.name);
        }
      },
      error: (err) => {
      }
    });
  }

  /**
  * It will search Participant
  * @param event
  * @author Dhaval Bera
  */
  search(event: any) {

    this.participantService.getParticipantByPID(event.term,'participant-documents').subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.participant = result.data;
        }
      },
      error: () => {
      },
    });
  }

  /**
   * It will update Participants information
   * @param event
   * @author Dhaval Bera   
   */
  participantDetails(event: any) {
    if (!this.commonService.isNullOrUndefined(event)) {
      this.selectedParticipantID = event.id;
    }
    this.participantHeaderDetails = event;
  }

}
