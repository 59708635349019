import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ParticipantService } from 'src/app/service/participant.service';
import { ActivatedRoute } from '@angular/router';
import { DictionaryService } from 'src/app/service/dictionary.service';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/service/common.service';


@Component({
  selector: 'app-eye-problems',
  templateUrl: './eye-problems.component.html',
  styleUrls: ['./eye-problems.component.scss']
})
export class EyeProblemsComponent implements OnInit {

  @Input() currentMenuPermission: any;
  public PID: any;
  public eyeProblemDetails: any;
  public eyeProblemForm!: FormGroup;
  public eyeProblemList: any;
  public prescriptionGlasses: any = [];
  public loading: boolean = true;

  eyeProblemFormStatus: boolean = false;
  public isRequiredDiseases: any = [];

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
    private commonService: CommonService,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    let constants = JSON.parse(localStorage.getItem('constants') as string);
    this.eyeProblemList = constants['EYE_PROBLEM'];
    this.prescriptionGlasses = constants['PRESCRIPTION_GLASSES'];

    this.eyeProblemForm = this.formBuilder.group({
      wear_eye_glasses: [null, []],
      wear_eye_glasses_type: [null, []],
      glasses_since: [null, []],
      have_eye_vision_problem: [null, []],
      following_eye_vision_problems: [null, []],
      other_eye_vision_problem: [null, []],
      visit_any_doctor: [null, []],
      reason_for_visit_doctor: [null, []],
      doctor_prescription: [null, []]
    });

    this.getEyeProblems();
  }

  addTagFn(name: any) {
    return { name: name, id: name };
  }

  
  /**
  * @author Pramod Pramod Kushwaha
  * This function is used to get recreational habits
  */
  getEyeProblems() {
    this.loading = true;
    this.participantService.getEyeProblems(this.PID).subscribe({
      next: (result: any) => {
        this.eyeProblemDetails = result.data;
        this.eyeProblemForm.patchValue({
          wear_eye_glasses: this.eyeProblemDetails.wear_eye_glasses,
          wear_eye_glasses_type: this.eyeProblemDetails.wear_eye_glasses_type,
          glasses_since: this.eyeProblemDetails.glasses_since,
          have_eye_vision_problem: this.eyeProblemDetails.have_eye_vision_problem,
          following_eye_vision_problems: this.eyeProblemDetails.following_eye_vision_problems,
          other_eye_vision_problem: this.eyeProblemDetails.other_eye_vision_problem,
          visit_any_doctor: this.eyeProblemDetails.visit_any_doctor,
          reason_for_visit_doctor: this.eyeProblemDetails.reason_for_visit_doctor,
          doctor_prescription: this.eyeProblemDetails.doctor_prescription,
        });
        this.loading = false;

      }, error: (e) => {
        this.loading = false;
        console.log('Surgery err :: ', e);
      }
    })
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form edit
  */
  clickOnEdit() {
    this.eyeProblemFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close
  */
  clickOnCancel() {
    this.eyeProblemFormStatus = false;
  }

  eyeProblemsSubmit() {
    if (this.eyeProblemForm.invalid) {
      console.log(this.eyeProblemForm);
      this.commonService.validateAllFormFields(this.eyeProblemForm);
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Please enter correct values', icon: 'warning', });
      return;
    } else {
      this.loading = true;
      this.participantService.updatEeyeProblems(this.eyeProblemForm.value, this.PID).subscribe({
        next: (result: any) => {
          if (result.status === 1) {
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
            this.getEyeProblems();
          } else {
            Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
          }
          this.loading = false;
          this.eyeProblemFormStatus = false;
        },
        error: (e) => {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        },
      });
    }
  }


  /**
* @author Pramod Pramod Kushwaha
* @param field
*/
  displayFieldCss(field: string) {
    return {
      "has-error": this.isFieldValid(field),
      "has-feedback": this.isFieldValid(field),
    };
  }

  /**
  * @author Pramod Kushwaha
  * This funtion is used to submit Form Details
  * @param field
  */
  isFieldValid(field: string) {
    return (
      (this.eyeProblemForm.controls[field].invalid && this.eyeProblemForm.controls[field].dirty) ||
      (this.eyeProblemForm.controls[field].touched && this.eyeProblemForm.controls[field].invalid)
    );
  }


}
