import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2'

export interface FamilyMemberTable {
  id: Number
  family_member: string
  disease_names: any
  is_other_diseases_names: any
  action: any
}

const ELEMENT_DATA: FamilyMemberTable[] = [];

@Component({
  selector: 'app-family-medical-history',
  templateUrl: './family-medical-history.component.html',
  styleUrls: ['./family-medical-history.component.scss']
})
export class FamilyMedicalHistoryComponent implements OnInit {

  @Input() currentMenuPermission: any;
  public PID: any;
  public diseaseFamilyHistory: any;
  public diseaseFamilyHistoryForm!: FormGroup;
  public familyList: any;
  public diseasesList: any;
  public participantDiseasesList: any;
  public fdUpdateIndex: any;
  public fdUpdate: boolean = false;
  public fdAdd: boolean = false;
  public loading: boolean = false;
  public fmhForm!: FormGroup;
  public fmhArray!: FormArray;

  diseaseFamilyHistoryFormStatus: boolean = false;
  familyOtherdiseases: boolean = false;


  displayedColumns: string[] = ['family_member', 'disease_names', 'action'];
  public dataSource = new MatTableDataSource<FamilyMemberTable>(ELEMENT_DATA);

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dictionaryService: DictionaryService,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    let familyList = JSON.parse(localStorage.getItem('constants') as string);
    this.familyList = familyList['FAMILY_MEMBERS'];
    this.getDiseaseList();
    this.getParticipantDiseasesList();

    this.fmhForm = new FormGroup({});
    this.fmhForm.setControl('option', new FormControl(null, [Validators.required]));
    this.fmhForm.setControl('details', new FormArray([]));
    this.fmhArray = <FormArray>this.fmhForm.controls["details"];

    this.diseaseFamilyHistoryForm = this.formBuilder.group({
      family_member: [null, []],
      disease_ids: [[], []],
      disease_names: [[], []],
      is_other_diseases_names: [[], []],
      is_other_diseases: [null, []],
      tmp_disease_ids: [null, []],
    });

    this.getFamilyDiseases();
  }

  // sweet alert code end

  /**
   * @author Pramod Pramod Kushwaha
   * Enable form edit
   */
  clickOnEdit() {
    this.diseaseFamilyHistoryFormStatus = true;
  }

  /**
  * @author Pramod Pramod Kushwaha
  * Enable form Close
  */
  clickOnCancel() {
    this.diseaseFamilyHistoryFormStatus = false;
    this.fdUpdate = false;
    this.fdAdd = false;
    this.fdUpdateIndex = null;
  }

  familyDiseaseSubmit(action = '') {
    if (action !== 'delete' && this.fmhForm.value.option === 'yes' && this.fmhForm.value.details.length <= 0) {
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 5000, title: 'OOPS!', text: 'Please add at least one family medical history', icon: 'warning', });
      return;
    }
    this.loading = true;
    this.participantService.updateFD(this.fmhForm.value, this.PID).subscribe({
      next: (result: any) => {
        if (result.status === 1) {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success', text: 'Participant updated successfully.', icon: 'success', });
          this.fmhForm.setControl('details', new FormArray([]));
          this.fmhArray = <FormArray>this.fmhForm.controls["details"];
          this.getFamilyDiseases();
          this.diseaseFamilyHistoryForm.reset();
          this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
          this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
          this.familyOtherdiseases = false;
          this.fdAdd = false;
          this.fdUpdate = false;
          this.fdUpdateIndex = null;
        } else {
          Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        }
        this.loading = false;
      },
      error: (e) => {
        Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'OOPS!', text: 'Something went wrong. Please contact to administrator.', icon: 'warning', });
        this.loading = false;
      },
      complete: () => { },
    });
  }

  /**
 * This function is used to get disease list
 */
  getDiseaseList() {
    this.dictionaryService.getDiseaseList().subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.diseasesList = result.data;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
* This function is used to get disease list
*/
  getParticipantDiseasesList() {
    this.participantService.getParticipantDiseasesList(this.PID).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.participantDiseasesList = result.data;
        }
      },
      error: (e) => { },
      complete: () => { },
    });
  }

  /**
* This function is used to get disease list
*/
  getFamilyDiseases() {
    this.loading = true;
    this.participantService.getFamilyDiseases(this.PID).subscribe({
      next: (result: any) => {
        if (result.status == 1) {
          this.diseaseFamilyHistory = result.data;
          this.fmhForm.controls['option'].setValue(result.data.option);
          this.dataSource = new MatTableDataSource(result.data.details);

          this.diseaseFamilyHistory.details.forEach((element: any) => {
            this.fmhArray.push(
              new FormGroup({
                family_member: new FormControl(element.family_member),
                disease_ids: new FormControl(element.disease_ids),
                disease_names: new FormControl(element.disease_names),
                is_other_diseases_names: new FormControl(element.is_other_diseases_names),
                is_other_diseases: new FormControl(element.is_other_diseases),
                tmp_disease_ids: new FormControl(element.tmp_disease_ids),
              })
            );
          });
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  /**
   * @author Dhaval Bera
   * This function is used to reset Form
   * @param option
   */
  resetForm(option: string) {
    this.fmhForm.setControl('details', new FormArray([]));
    this.dataSource = new MatTableDataSource(this.fmhForm.controls['details'].value);

    if (option == 'yes') {
      this.fdAdd = true;
    } else {
      this.fdUpdate = true;
    }
    this.fdUpdateIndex = null;
    this.diseaseFamilyHistoryForm.reset();
    this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
    this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
  }

  selectOthers(event: any) {
    this.familyOtherdiseases = false;
    if (event) {
      this.familyOtherdiseases = true;
    }
  }

  selectParticpantDiseases(event: any) {
    if (event.checked) {
      this.diseaseFamilyHistoryForm.controls['disease_ids'].value.push(Number(event.source.value));
      this.diseaseFamilyHistoryForm.controls['disease_names'].value.push(event.source.name);
    } else {
      this.deleteTestSelect(
        this.diseaseFamilyHistoryForm.controls['disease_ids'].value,
        Number(event.source.value)
      );
      this.deleteTestSelect(
        this.diseaseFamilyHistoryForm.controls['disease_names'].value,
        event.source.name
      );
    }
  }

  diseasesSelect(event: any) {
    let is_other_diseases_names: any = [];
    this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].setValue([]);
    if (event.length > 0) {
      event.forEach((value: any) => {
        is_other_diseases_names.push(value.name);
      });
    }
    this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].value.push(is_other_diseases_names);
  }

  deleteTestSelect(data: any, value: Number) {
    const index: number = data.indexOf(value);
    if (index !== -1) {
      data.splice(index, 1);
    }
  }

  addFD() {
    this.fdAdd = true;

    const addForm = this.fmhForm.get('details') as FormArray;
    let newFormGroup = new FormGroup({
      family_member: new FormControl(this.diseaseFamilyHistoryForm.controls['family_member']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['family_member']?.value : null, []),
      disease_ids: new FormControl(this.diseaseFamilyHistoryForm.controls['disease_ids']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['disease_ids']?.value : null, []),
      disease_names: new FormControl(this.diseaseFamilyHistoryForm.controls['disease_names']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['disease_names']?.value : null, []),
      is_other_diseases_names: new FormControl(this.diseaseFamilyHistoryForm.controls['is_other_diseases_names']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['is_other_diseases_names']?.value : null, []),
      is_other_diseases: new FormControl(this.diseaseFamilyHistoryForm.controls['is_other_diseases']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['is_other_diseases']?.value : null, []),
      tmp_disease_ids: new FormControl(this.diseaseFamilyHistoryForm.controls['tmp_disease_ids']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['tmp_disease_ids']?.value : null, []),
    });
    addForm.push(newFormGroup);
    this.dataSource = new MatTableDataSource(this.fmhForm.controls['details']?.value);
    this.diseaseFamilyHistoryForm.reset();
    this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
    this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
    this.familyOtherdiseases = false;
  }

  updateFD() {
    this.deleteObject(this.fdUpdateIndex);
    if (!this.familyOtherdiseases) {
      this.diseaseFamilyHistoryForm.value.is_other_diseases = [];
      this.diseaseFamilyHistoryForm.value.is_other_diseases_names = [];
      this.diseaseFamilyHistoryForm.controls['is_other_diseases'].setValue([]);
      this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].setValue([]);
    }

    const updateForm = this.fmhForm.get('details') as FormArray;
    let newFormGroup = new FormGroup({
      family_member: new FormControl(this.diseaseFamilyHistoryForm.controls['family_member']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['family_member']?.value : null, []),
      disease_ids: new FormControl(this.diseaseFamilyHistoryForm.controls['disease_ids']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['disease_ids']?.value : null, []),
      disease_names: new FormControl(this.diseaseFamilyHistoryForm.controls['disease_names']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['disease_names']?.value : null, []),
      is_other_diseases_names: new FormControl(this.diseaseFamilyHistoryForm.controls['is_other_diseases_names']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['is_other_diseases_names']?.value : null, []),
      is_other_diseases: new FormControl(this.diseaseFamilyHistoryForm.controls['is_other_diseases']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['is_other_diseases']?.value : null, []),
      tmp_disease_ids: new FormControl(this.diseaseFamilyHistoryForm.controls['tmp_disease_ids']?.value !== "" ? this.diseaseFamilyHistoryForm.controls['tmp_disease_ids']?.value : null, []),
    });
    updateForm.setControl(this.fdUpdateIndex, newFormGroup);

    this.dataSource = new MatTableDataSource(this.fmhForm.controls['details']?.value);
    this.diseaseFamilyHistoryForm.reset();
    this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
    this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
    this.familyOtherdiseases = false;
  }

  editFD(index: any) {
    this.fdUpdateIndex = index;
    this.fdUpdate = true;

    const family_member = (<FormArray>this.fmhForm.get("details")).at(index).get('family_member')?.value;
    const disease_ids = (<FormArray>this.fmhForm.get("details")).at(index).get('disease_ids')?.value;
    const disease_names = (<FormArray>this.fmhForm.get("details")).at(index).get('disease_names')?.value;
    const is_other_diseases = (<FormArray>this.fmhForm.get("details")).at(index).get('is_other_diseases')?.value;
    const is_other_diseases_names = (<FormArray>this.fmhForm.get("details")).at(index).get('is_other_diseases_names')?.value;

    if (is_other_diseases?.length > 0) {
      this.familyOtherdiseases = true;
    } else {
      this.familyOtherdiseases = false;
    }

    this.diseaseFamilyHistoryForm.controls['family_member'].setValue(family_member);
    if (disease_ids) {
      this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue(disease_ids);
    } else {
      this.diseaseFamilyHistoryForm.controls['disease_ids'].setValue([]);
    }
    if (disease_names) {
      this.diseaseFamilyHistoryForm.controls['disease_names'].setValue(disease_names);
    } else {
      this.diseaseFamilyHistoryForm.controls['disease_names'].setValue([]);
    }
    if (is_other_diseases) {
      this.diseaseFamilyHistoryForm.controls['is_other_diseases'].setValue(is_other_diseases);
    } else {
      this.diseaseFamilyHistoryForm.controls['is_other_diseases'].setValue([]);
    }
    if (is_other_diseases_names) {
      this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].setValue(is_other_diseases_names);
    } else {
      this.diseaseFamilyHistoryForm.controls['is_other_diseases_names'].setValue([]);
    }
  }

  deleteFD(id: any) {
    Swal.fire({
      title: 'Delete?',
      html: 'Are you sure want to delete the Family Member Medical History?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // this.fdUpdate = true;
        this.deleteObject(id);
        (<FormArray>this.fmhForm.get("details"))?.removeAt(id);
        this.dataSource = new MatTableDataSource(this.fmhForm.controls['details'].value);
        this.familyDiseaseSubmit('delete');
      }
    });
  }

  deleteObject(index: Number) {
    if (index !== -1) {
      this.diseaseFamilyHistory.details.splice(index, 1);
    }
  }

}
