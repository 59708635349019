import { OnInit, Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

import Swal from 'sweetalert2';
import { UserService } from '../../../service/user.service';
import { UsersElement } from 'src/app/interfaces/Users';
import { CommonService } from '../../../service/common.service';
import { Router } from '@angular/router';
import { RoleService } from '../../../service/role.service';
import { AppConstants } from 'src/app/app.constant';
import { MenuService } from 'src/app/service/menu.service';
const ELEMENT_DATA: UsersElement[] = [];

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements AfterViewInit, OnInit {
  public displayedColumns: string[] = [
    'id',
    'role_name',
    'created_by_name',
    'status_type',
    'created_date_formatted',
    'action',
  ];
  public dataSource = new MatTableDataSource<UsersElement>(ELEMENT_DATA);
  public totalRows = 0;
  public pageSize = 10;
  public currentPage = 0;
  public filterValue = '';
  public sortField = '';
  public sortOrder = '';
  public pageSizeOptions: number[] = AppConstants.pageSizeOptions;
  public currentMenuPermission: any;
  public matNoDataRowTitle: string = 'Loading Data...';
  public loading: boolean = false;


  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private roleService: RoleService,
    private commonService: CommonService,
    protected router: Router,
    private menuService: MenuService
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void {
    this.menuService.currentMenu.subscribe(currentMenu => {
      this.currentMenuPermission = currentMenu;
    });
    this.getRoles();
  }

  /**
   * This function is user to get all User Listing
   * @author Dhaval Bera
   */
  getRoles() {
    this.loading = true;
    this.roleService
      .getRoles(
        this.currentPage + 1,
        this.sortField,
        this.sortOrder,
        this.filterValue,
        this.pageSize
      )
      .subscribe({
        next: (result: any) => {
          this.dataSource = new MatTableDataSource(result.data.data);
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = result.data.total;
          });
          if (this.dataSource != undefined && this.dataSource.data.length == 0) {
            this.matNoDataRowTitle = 'No Record Found';
          }
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
          console.log('User Listing err :: ', e);
        },
        complete: () => { },
      });
  }

  /**
   * It will delete role
   * @param id
   * @author Dhaval Bera
   */
  deleteRole(id: number) {
    Swal.fire({
      title: 'Delete?',
      text: 'Are you sure want to delete role?',
      imageUrl: "../../../../../assets/images/delete.svg",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.roleService.deleteRole(id).subscribe({
          next: (results: any) => {
            if (results.status === AppConstants.serverSuccessStatus) {
              this.commonService.showSuccessToast('Role deleted successfully');
            } else {
              this.commonService.showErrorToast(
                "Role can't be deleted. Please try again later."
              );
            }
            this.getRoles();
          },
          error: (err) => {
            this.commonService.showErrorToast(
              'Something went wrong. Please contact to administrator.'
            );
          },
          complete: () => { },
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  searchFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.getRoles();
  }

  SortFilter(sortState: Sort) {
    if (sortState.active !== '' && sortState.direction !== '') {
      this.sortField = sortState.active;
      if (sortState.active == 'created_date_formatted') {
        this.sortField = 'created_at';
      }
      this.sortOrder = sortState.direction;
      this.getRoles();
    }
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getRoles();
  }
}
