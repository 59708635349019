import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { SurgeryService } from 'src/app/service/surgery.service';

@Component({
  selector: 'app-surgery-form',
  templateUrl: './surgery-form.component.html',
  styleUrls: ['./surgery-form.component.scss']
})
export class SurgeryFormComponent implements OnInit {

  public surgeryForm!: FormGroup;
  public title: any;
  public surgeryId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/dictionary-management/surgery";
  public userId: any = "";
  public isApproved: number = 1;
  public showLoading: boolean = false;
  public selectMapOption: boolean = false;
  public isCurate: boolean = false;
  public disableSubmitBtn: boolean = false;
  public surgeryNameMinLength: number = 3;
  public surgeryNameMaxLength: number = 255;
  public surgeryDetails: any = [];
  public surgeryNameList: any[] = [];

  constructor(
    private surgeryService: SurgeryService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.surgeryId = this.route.snapshot.params['id'];
    if (this.surgeryId != 0 && this.surgeryId != null) {
      this.setEditData();
      this.title = "Edit Surgery";
      this.buttonText = "Save";
    } else {
      this.title = "Add Surgery";
      this.buttonText = "Add";
      this.surgeryId = 0;
    }
  }

  ngOnInit(): void {
    this.isAddMode = !this.surgeryId;
    this.route.queryParams.subscribe((queryParams: any) => {
      if(this.router.url.indexOf('curation-pending') >= 0){
        this.isCurate = true;
        this.title = "Curate Surgery";
        this.cancelLink = '/dictionary-management/surgery/curation-pending';
        // Get Surgery Dictionary
        this.getSurgeryList();
      }
    });
    
    this.userId = localStorage.getItem("userId");
    this.surgeryForm = this.formBuilder.group({
      'name': ['', [Validators.required, Validators.minLength(this.surgeryNameMinLength), Validators.maxLength(this.surgeryNameMaxLength)]],
      'isApproved':[this.isApproved,  []],
      'mapWithOtherSurgery':[null, []]
    });   
  }


  /**
     * Get all surgeries for validate Surgery
     * @returns object
     */
   // Get Surgery Dictionary
   getSurgeryList(){
    this.surgeryService.getActiveList()
    .subscribe({
      next: (result: any) => {
        this.surgeryNameList = result.data;
      },
      error: (e) => {
        console.log( 'error from get surgery list', e.error);
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      },
    });
  }
  
  setEditData() {
    this.surgeryService.getRow(this.surgeryId)
    .subscribe({
      next: (result: any) => {
      if (result.status != AppConstants.serverSuccessStatus) {
        this.commonService.showErrorToast('Surgery not found.');
          this.router.navigate([this.cancelLink]);
      }else{
        this.surgeryDetails = result['data'];
        this.surgeryForm.patchValue({
          name: this.surgeryDetails.name,
        });
      }
    },
      error: (e: any) => {
        console.log(
          e.error
        );
      },
    });
  }

  getSurgeryNameError() {
    if (this.surgeryForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.surgeryForm.controls['name'].errors!['minlength']) {
      return 'Min Length is '+ this.surgeryNameMinLength +' characters.';
    }
    if (this.surgeryForm.controls['name'].errors!['maxlength']) {
      return 'Max Length is '+ this.surgeryNameMaxLength +' characters.';
    }
    if (this.surgeryForm.controls['name'].errors!['unique']) {
      return this.surgeryForm.get("name")!.errors!['unique'] ;
    }
    return ''; 
  } 

  /**
   * submit form
   * @returns null
   */
  onSubmit(): void {  
    if (this.surgeryForm.invalid) {
      this.commonService.validateAllFormFields(this.surgeryForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('name', this.surgeryForm.controls['name'].value);
    formData.append('is_approved', this.surgeryForm.controls['isApproved'].value);
    formData.append('map_with_other_surgery', (this.surgeryForm.controls['mapWithOtherSurgery'].value) ? this.surgeryForm.controls['mapWithOtherSurgery'].value : 0);
    
      if (this.isAddMode) {
          this.createSurgery(formData);
      } else {
          this.updateSurgery(formData);
      }
  }
  
  /**
   * call Create Surgery api
   * @returns null
   */
  private createSurgery(formData: any): void {
    this.surgeryService.store(formData)
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate(["/dictionary-management/surgery"]);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error:(err) => {
        console.log('error from create surgery', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  }
  
  /**
   * call Update Surgery api
   * @returns null
   */
  private updateSurgery(formData: any): void {
    formData.append('updated_by', this.userId);
    this.surgeryService.update(formData, this.route.snapshot.params['id'])
    .subscribe({
      next:(result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.router.navigate([this.cancelLink]);
          this.commonService.showSuccessToast(
            result.message
          );
        }else{
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            result.message
          );
        }
      },
      error:(err) => {
        console.log('error from update surgery', err);
        this.disableSubmitBtn = false;
        this.commonService.showErrorToast(
          'Something went wrong. Please contact to administrator.'
        );
      }
    })
  }

  /**
   * Select Approved Option
   *
   * @returns object
   */
  selectOption(value: boolean) {
    this.selectMapOption = value;
    if(value){
      this.surgeryForm.get("mapWithOtherSurgery")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
    }else{
      this.surgeryForm.get('mapWithOtherSurgery')!.setValidators(null);
    }
    this.surgeryForm.controls['mapWithOtherSurgery'].updateValueAndValidity()
    this.getMapWithSurgeryError();
  }
  
  getMapWithSurgeryError() {
    if(this.surgeryForm.controls['mapWithOtherSurgery'].invalid){
      if (this.surgeryForm.controls['mapWithOtherSurgery'].errors!['required']) {
        return 'Select surgery';
      }
      if(this.surgeryForm.controls['mapWithOtherSurgery'].errors!['pattern']) {
        return 'Please select valid Surgery';
      }
    }
    return ''; 
  }
  
  /**
     * Check surgery field is existed or not
     * @returns null
     */
    checkUniqueSurgery(event: any) {
    const surgeryFieldValue = (event.target as HTMLInputElement).value;
    if (surgeryFieldValue.length > this.surgeryNameMaxLength) {
      return false;
    }

      if (surgeryFieldValue.length < this.surgeryNameMinLength) {
        return false;
      }
  
    if (surgeryFieldValue !== "") {
        this.surgeryService
        .checkUniqueSurgery(
          surgeryFieldValue,
          this.surgeryId
        )
        .subscribe({
          next:(result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.surgeryForm.get("name")!.setErrors(null);
            } else {
              this.surgeryForm
              .get("name")!
              .setErrors({ unique: "Name already exists" });
            }
          },
          error: (error: any) => {
            if (error.status === 400) {
              this.surgeryForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
            } else {
              console.log(
                "Something went wrong. Please contact to administrator."
              );
            }
          }
        });
    }
    return null;
  }
}
  