<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1>{{title}}</h1>
        <ul class="bradcrumb">
            <li>{{title}}</li>
            <li><a routerLink="/site/loinc-codes" title="Loinc Codes" class="only-link">Manage Loinc Codes</a></li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>
</section>

<form [formGroup]="loincCodeForm" #form="ngForm"  [class]="isViewMode ? 'view-mode' : 'edit-mode'" (ngSubmit)="onSubmit()">
    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <mat-accordion class="example-headers-align">
                    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Diagnostic Lab Basic Information
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-12 mt-40">
                                        <div class="input-wrape" [class]="this.commonService.displayFieldCss(this.loincCodeForm, 'diagnostic_lab_id')">
                                            <mat-label><i>*</i>Diagnostic Lab:</mat-label>
                                            <div class="control-pad">
                                                <ng-select appendTo="body" [items]="diagnosticLabs"
                                                    formControlName="diagnostic_lab_id"
                                                     bindValue="id"
                                                    bindLabel="name"
                                                    placeholder="Select diagnostic lab"
                                                    required="">
                                                </ng-select>

                                                <mat-error
                                                    *ngIf="this.loincCodeForm.controls['diagnostic_lab_id'].invalid && (this.loincCodeForm.controls['diagnostic_lab_id'].dirty || this.loincCodeForm.controls['diagnostic_lab_id'].touched)">
                                                    {{getDiagnosticLabError()}}
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Glycosylated/Glycated Hemoglobin
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HbA1c (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="hba1c"
                                                    placeholder="Enter HbA1c (%)" type="text">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Estimated Average Glucose (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="estimated_average_glucose"
                                                    placeholder="Enter estimated average glucose (mg/dL):"
                                                    type="text">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Mean Plasma Glucose (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input formControlName="mean_plasma_glucose"
                                                    placeholder="Enter mean plasma glucose (mg/dL)" type="text">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Bloodsugar Fastings
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Fasting Blood Sugar (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter fasting blood sugar (mg/dL)"
                                                    type="text" formControlName="fasting_blood_sugar">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Post Prandial(PP) (2 hrs. after Lunch) (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter post prandial(PP) (2 hrs. after lunch) (mg/dL)"
                                                    type="text" formControlName="post_prandial">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Complete Blood Count
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Haemoglobin (gm/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter haemoglobin (gm/dL)"
                                                    type="text" formControlName="haemoglobin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Basophils (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter basophils (%)" type="text"
                                                    formControlName="basophils">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RDW (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RDW (%)" type="text"
                                                    formControlName="rdw">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total RBC Count (10^12/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total RBC count (10^12/L)"
                                                    type="text" formControlName="total_rbc_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Granulocytes(%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter granulocytes(%):"
                                                    type="text" formControlName="granulocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Peripheral Smear Platelets:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter peripheral smear platelets"
                                                    type="text" formControlName="peripheral_smear_platelets">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total WBC Count (/cmm):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total WBC count (/cmm)"
                                                    type="text" formControlName="total_wbc_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Monocytes (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter monocytes (%):" type="text"
                                                    formControlName="monocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RDW-SD (fL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RDW-SD (fL)" type="text"
                                                    formControlName="rdw_sd">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Peripheral Smear WBC:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter peripheral smear WBC"
                                                    type="text" formControlName="peripheral_smear_wbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hematocrit (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter hematocrit (%)" type="text"
                                                    formControlName="hematocrit">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Basophil Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute basophil count (10^3/uL)" type="text"
                                                    formControlName="absolute_basophil_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>PDW (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter PDW (%)" type="text"
                                                    formControlName="pdw">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Thalassemia check:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter thalassemia check" type="text"
                                                    formControlName="thalassemia_check">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MCV (fl):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MCV (fl)" type="text"
                                                    formControlName="mcv">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Eosinophil Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute eosinophil count (10^3/uL)"
                                                    type="text" formControlName="absolute_eosinophil_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>P-LCC (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter P-LCC (10^3/uL)" type="text"
                                                    formControlName="plcc_10_3">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Platelet morphology:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter platelet morphology"
                                                    type="text" formControlName="platelet_morphology">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MCH (pg):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MCH (pg)" type="text"
                                                    formControlName="mch">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Lymphocyte Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute lymphocyte count (10^3/uL)"
                                                    type="text" formControlName="absolute_lymphocyte_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>P-LCR (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter P-LCR (%)" type="text"
                                                    formControlName="plcr">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Leucocyte (Microscopic Examination):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter leucocyte (microscopic examination)" type="text"
                                                    formControlName="leucocyte_microscopic_examination">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MCHC (g/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MCHC (g/dL)" type="text"
                                                    formControlName="mchc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Monocyte Count (10^3/uL2):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute monocyte count (10^3/uL2)" type="text"
                                                    formControlName="absolute_monocyte_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>PCT (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter PCT (%)" type="text"
                                                    formControlName="pct">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RBC (Microscopic Examination):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RBC (microscopic examination)"
                                                    type="text" formControlName="rbc_microscopic">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Neutrophils (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter neutrophils (%)" type="text"
                                                    formControlName="neutrophils">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Neutrophil Count (10^3/uL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter absolute neutrophil count (10^3/uL)"
                                                    type="text" formControlName="absolute_neutrophil_count">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Morphology of Red Blood Cell/ Peripheral smear RBC :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter morphology of red blood cell/ peripheral smear RBC"
                                                    type="text" formControlName="morphology_red_blood_cell">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Absolute Granulocyte Count:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter absolute granulocyte count"
                                                    type="text" formControlName="granulocytes_10_3">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Lymphocytes (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter lymphocytes (%)" type="text"
                                                    formControlName="lymphocytes">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Platelet Counts (/cmm):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter platelet counts (/cmm)"
                                                    type="text" formControlName="platelet_counts">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Morphology of WBC:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter morphology of WBC" type="text"
                                                    formControlName="morphology_wbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Immature Granulocyte (IG):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter immature granulocyte (IG)"
                                                    type="text" formControlName="immature_granulocyte">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Eosinophils (%):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter eosinophils (%)" type="text"
                                                    formControlName="eosinophils">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>MPV (fl):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter MPV (fl)" type="text"
                                                    formControlName="mpv">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Platelets on smear:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter platelets on smear"
                                                    type="text" formControlName="platelets_on_smear">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Nucleated RBC (NRBC):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter nucleated RBC (NRBC)"
                                                    type="text" formControlName="nucleated_rbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Neutrophil Lymphocyte Ratio (NLR):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter neutrophil lymphocyte ratio (NLR)" type="text"
                                                    formControlName="neutrophil_lymphocyte_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Erythrocyte Sedimentation Rate, Blood Sedimentation Rate (ESRmm/HR):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter erythrocyte sedimentation rate, Blood sedimentation rate (ESRmm/HR)"
                                                    type="text" formControlName="erythrocyte_sedimentation_rate">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Mentzer Index:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter Mentzer Index" type="text"
                                                    formControlName="mentzer_index">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>PDW (fL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter PDW (fL)" type="text"
                                                    formControlName="pdw_fl">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Lipid Profile
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Appearance of Serum on Refrigeration:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter appearance of serum on refrigeration" type="text"
                                                    formControlName="apperance_serum_refrigeration">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HDL (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter HDL (mg/dL)" type="text"
                                                    formControlName="hdl">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Triglyceride (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum triglyceride (mg/dL)"
                                                    type="text" formControlName="serum_triglyceride">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Naked Eye Appearance of Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter naked eye appearance of serum"
                                                    type="text" formControlName="naked_eye_appearance_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Lipid (mg/100ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total lipid (mg/100ml)"
                                                    type="text" formControlName="total_lipid">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Cholesterol(mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum cholesterol(mg/dL)"
                                                    type="text" formControlName="serum_cholesterol">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>LDL/ HDL RATIO:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter LDL/ HDL RATIO" type="text"
                                                    formControlName="ldl_hdl_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Chol:HDL Cholesterol Ratio:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter chol:HDL cholesterol ratio"
                                                    type="text" formControlName="hdl_cholesterol_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>NON-HDL CHOL (mg/mL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter NON-HDL CHOL (mg/mL)"
                                                    type="text" formControlName="non_hdl_chol">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Cholesterol LDL - Direct (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter cholesterol LDL - direct (mg/dL)" type="text"
                                                    formControlName="cholesterol_ldl_direct">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                     <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>V L D L Cholesterol (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter V L D L cholesterol (mg/dL)"
                                                    type="text" formControlName="vldl_cholesterol">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Thyroid Report/Thyroid Function Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Triidothyronine (T3) (ng/ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter triidothyronine (T3) (ng/ml)"
                                                    type="text" formControlName="triidothyronine">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Thyroid Stimulating Hormone (TSH) (µlU/ml) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter thyroid stimulating hormone (TSH) (µlU/ml)"
                                                    type="text" formControlName="thyroid_stimulating_hormone">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Thyroxine (T4) (µg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total thyroxine (T4) (µg/dL)"
                                                    type="text" formControlName="total_thyroxine">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Creatinine
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Serum Creatinine (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter serum creatinine (mg/dL)"
                                                    type="text" formControlName="serum_creatinine">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Est. Glomerular Filtration Rate (mL/min/1.73 m2) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter Est. glomerular filtration rate (mL/min/1.73 m2)"
                                                    type="text" formControlName="est_glomerular_filter_rate">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Cystatin C (mg/L) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter Cystatin C (mg/L)"
                                                    type="text" formControlName="cystatin_c">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Phosphorous, Serum
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Phosphorous (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter phosphorous (mg/dL)"
                                                    type="text" formControlName="phosphorous">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Electrolytes, Serum
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Sodium (mmol/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter sodium (mmol/L)" type="text"
                                                    formControlName="sodium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Potassium (mmol/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter potassium (mmol/L):"
                                                    type="text" formControlName="potassium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Chloride (mmol/L):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter chloride (mmol/L)"
                                                    type="text" formControlName="chloride">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Statin Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Allele Tested:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter allele tested" type="text"
                                                    formControlName="allele_tested">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>SLCO1B1:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter SLCO1B1" type="text"
                                                    formControlName="slco1b1">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>SLCO1B1 Genotype Result:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter SLCO1B1 genotype result"
                                                    type="text" formControlName="slco1b1_genotype_result">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Hepatitis B Surface Antibody (Quantitative)
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis B Surface Antibody (Quantitative) :</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter hepatitis B surface antibody (quantitative)"
                                                    type="text" formControlName="hepatitis_b_surface">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Blood Grouping
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>ABO Group:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter ABO group" type="text"
                                                    formControlName="abo_group">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RH Type:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RH type" type="text"
                                                    formControlName="rh_type">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Liver Function Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Indirect:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin indirect"
                                                    type="text" formControlName="bilirubin_indirect">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Albumin:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter albumin" type="text"
                                                    formControlName="albumin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Alkaline Phosphatase:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter alkaline phosphatase"
                                                    type="text" formControlName="alkaline_phosphatase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Direct:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin direct"
                                                    type="text" formControlName="bilirubin_direct">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin Total:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin total" type="text"
                                                    formControlName="bilirubin_total">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Aspartate Aminotransferase (Sgot):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter aspartate aminotransferase (Sgot)" type="text"
                                                    formControlName="aspartate_aminotransferase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Alanine Aminotransferase (Sgpt):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter alanine aminotransferase (Sgpt)" type="text"
                                                    formControlName="alanine_aminotransferase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>A:G Ratio:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter A:G ratio" type="text"
                                                    formControlName="ag_ratio">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Globulin:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter globulin" type="text"
                                                    formControlName="globulin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Total Protein:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter total protein" type="text"
                                                    formControlName="total_protein">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Uric Acid Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Urea Nitrogen:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood urea nitrogen"
                                                    type="text" formControlName="blood_urea_nitrogen">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Uric Acid:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter uric acid" type="text"
                                                    formControlName="uric_acid">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood Urea mg/dL:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood urea mg/dL"
                                                    type="text" formControlName="blood_urea">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Protein Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>C-Reactive Protein:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter c-reactive protein"
                                                    type="text" formControlName="creactive_protein">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Gamma Glutamyl Transferase:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter gamma glutamyl transferase"
                                                    type="text" formControlName="gamma_glutamyl_transferase">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Calcium
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Calcium (mg/dL):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter calcium (mg/dL)" type="text"
                                                    formControlName="calcium">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Rheumatoid Arthritis Test
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>RA Factor:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter RA factor" type="text"
                                                    formControlName="ra_factor">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Complete Urine Examinations
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                               
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Color:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter color" type="text"
                                                    formControlName="color">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Appearance:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter appearance" type="text"
                                                    formControlName="apperance">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>pH:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter pH" type="text"
                                                    formControlName="ph">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Specific Gravity:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter specific gravity" type="text"
                                                    formControlName="specific_gravity">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Protein:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter protein" type="text"
                                                    formControlName="protein">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Glucose:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter glucose" type="text"
                                                    formControlName="glucose">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Ketones:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter ketones" type="text"
                                                    formControlName="ketones">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bilirubin:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bilirubin" type="text"
                                                    formControlName="bilirubin">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Urobilinogen:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter urobilinogen" type="text"
                                                    formControlName="urobilinogen">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Blood:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter blood" type="text"
                                                    formControlName="blood">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Nitrite:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter nitrite" type="text"
                                                    formControlName="nitrite">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>WBC (/HPF):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter WBC (/HPF)" type="text"
                                                    formControlName="wbc">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Epithelial Cells (/HPF):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter epithelial cells (/HPF)"
                                                    type="text" formControlName="epithelial_cells">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Red Blood Cells:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter red blood cells" type="text"
                                                    formControlName="red_blood_cells">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Casts:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter casts" type="text"
                                                    formControlName="casts_hpf">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Crystals:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter crystals" type="text"
                                                    formControlName="crystals">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Bacteria:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter bacteria" type="text"
                                                    formControlName="bacteria">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Yeast:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter Yeast" type="text"
                                                    formControlName="yeast">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>


                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Vitamin D Total
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Vitamin D Total (ng/ml):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter vitamin D Total (ng/ml)"
                                                    type="text" formControlName="vitamin_d_total">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Infectious Panel
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <mat-panel-body>
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HIV 4th Gene Assay (P24AG+HIV AB), Serum (Index Value):
                                            </mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter HIV 4th Gene Assay (P24AG+HIV AB), Serum (Index Value)"
                                                    type="text"
                                                    formControlName="hiv_4th_gene_assay_serum_index_value">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HIV 4th Gene Assay (P24AG+HIV AB), Serum (Result):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter HIV 4th gene assay (P24AG+HIV AB), serum (result)"
                                                    type="text" formControlName="hiv_4th_gene_assay_serum_result">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>HIV 1 & 2 Antibodies Screening Test, Serum (Result):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="HIV 1 & 2 antibodies screening test, serum (result)"
                                                    type="text"
                                                    formControlName="hiv_1_2_antibodies_screening_test_serum_result">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis B Surface Antigen, Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter hepatitis B surface antigen, serum" type="text"
                                                    formControlName="hepatitis_b_surface_antigen_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis C Antibodies, Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter hepatitis C antibodies, serum"
                                                    type="text" formControlName="hepatitis_c_antibodies_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Hepatitis C Antibodies, Serum (Patient Value):</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter hepatitis C antibodies, serum (patient value)"
                                                    type="text"
                                                    formControlName="hepatitis_c_antibodies_serum_patient_value">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>VDRL/RPR, Serum:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input placeholder="Enter VDRL/RPR, serum" type="text"
                                                    formControlName="vdrlrpr_serum">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 m-b-0">
                                        <div class="input-wrape">
                                            <mat-label>Malaria Parasite/Blood Parasite Smear Identification:</mat-label>
                                            <mat-form-field appearance="outline">
                                                <input matInput #input
                                                    placeholder="Enter malaria parasite/blood parasite smear identification"
                                                    type="text"
                                                    formControlName="malaria_parasiteblood_parasite_smear_identification">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-body>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div class="invidiual-btn-wrape">
                    <button type="button" mat-raised-button class="btn" color="warn" routerLink="/site/loinc-codes">Cancel</button>
                    <button type="submit" mat-raised-button class="btn" *ngIf="!isViewMode" color="secoundary">Save</button>
                </div>
            </div>
        </div>
    </section>
</form>