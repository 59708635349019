import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from "src/app/service/dashboard.service";


@Component({
  selector: "app-gender-chart",
  templateUrl: "./gender-chart.component.html",
  styleUrls: ["./gender-chart.component.scss"],
})
export class GenderChartComponent implements OnInit {
  public ownFormData!: FormData;
  public percentageOrAbsoluteCounts: any[] = [
    {
      id: 2,
      value: "Absolute Counts",
    },
    {
      id: 1,
      value: "Percentage",
    },
  ];

  private alive = true;

  genderChartData: any = [];
  themeSubscription: any;
  genderChartOptions: any;
  totalGenderChartCounts: any = 0;
  genderWisePercentOrCount: any = 2;
  monthOrYear: any = "year";
  genderChartLoader: boolean = true;

  constructor(private dashboardService: DashboardService, private route: ActivatedRoute) { }

  /**
   *
   */
  ngOnInit() {
    this.getDashboardChartData("gender_wise", "");
  }

  /**
   *
   * @param type
   * @param formData
   * @param isPercentSelected
   * @param monthOrYearFilter
   * @param isUpdate
   */
  getDashboardChartData(type: string, formData: any, isPercentSelected: any = 2, monthOrYearFilter = "year") {
    this.genderChartLoader = true;
    this.dashboardService.getDashboardChartData(type, formData, monthOrYearFilter, isPercentSelected)
      .subscribe((result: any) => {
        this.genderChartData = result.data.chart_data;
        this.totalGenderChartCounts = result.data.total_chart_counts;
        this.showGenderChart(this.genderChartData, result.data.other, isPercentSelected);
        this.genderChartLoader = false;
      });
  }

  /**
   *
   * @param genderChartData
   * @param otherArray
   * @param isPercentSelected
   */
  showGenderChart(genderChartData: any, otherArray: any, isPercentSelected: any = 2) {
    this.genderChartLoader = false;
    this.genderChartOptions = {
      color: ['#598bff', '#2ce69b', '#42aaff', '#ffc94d', '#F46D43', '#66C2A5', '#ABDDA4', '#F4D787', '#ff708d'],
      tooltip: {
        trigger: 'item',
        hideDelay: 200,
        formatter: function (params: any) {
          const colorSpan = (color: any) =>
            '<span style="z-index:100;display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';
          let rez: string;
          let showCountOrPercentValue: string;
          if (isPercentSelected === 1) {
            showCountOrPercentValue = params.data.percentage + "%";
          } else {
            showCountOrPercentValue = params.data.value;
          }

          if (params.name === "") {
            const xx = `Other <span class="badge badge-dark"> ${showCountOrPercentValue} </span>`;
            rez = '<div style="padding:3px 8px">' + xx + "</div>";

            Object.keys(otherArray).forEach((key) => {
              if (isPercentSelected === 1) {
                showCountOrPercentValue = otherArray[key].percentage + "%";
              } else {
                showCountOrPercentValue = otherArray[key].value;
              }

              rez += `<div style="padding:3px 8px"> ${colorSpan(
                params.color
              )} Other <span class="badge badge-dark"> ${showCountOrPercentValue} </span> <br> </div>`;
            });
          } else {
            const xx = `${colorSpan(params.color)} ${params.name
              } <span class="badge badge-dark">${showCountOrPercentValue}</span>`;
            rez = `<div style="padding:5px"> ${xx} </div>`;
          }

          return '<div class="echart-toolitp-container">' + rez + "</div>";
        },
        extraCssText: 'z-index: 100'
      },
      legend: {
        bottom: 0,
        type: "scroll",
        itemWidth: 13,
        itemHeight: 13,
        itemGap: 10,
        textStyle: {
          color: "#222b45",
          fontSize: 13,
        },
        formatter: function (params: any) {
          if (params === "") {
            return `Other`;
          }
          return params;
        },
      },
      series: [
        {
          name: 'Gender',
          type: 'pie',
          avoidLabelOverlap: false,
          radius: ['40%', '85%'],
          center: ['50%', '45%'],
          data: genderChartData,
          itemStyle: {
            normal: {
              label: {
                position: 'outside',
                formatter: "{b}\n{c}"
              },
              labelLine: {
                show: true,
                length: 2,
              }
            },
            emphasis: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              label: {
                show: true,
                formatter: function (params: any) {
                  let showCountOrPercentValue = '';
                  if (isPercentSelected === 1) {
                    showCountOrPercentValue = params.data.percentage + "%";
                  } else {
                    showCountOrPercentValue = params.data.value;
                  }
                  if (params.name === "") {
                    return `Other \n ${showCountOrPercentValue} `;
                  }
                  return `${params.name} \n ${showCountOrPercentValue} `;
                },
              },
            }

          },

          label: {
            normal: {
              show: false,
              position: 'center',
              textStyle: {
                color: '#222b45',
                fontSize: 15,
                fontWeight: 'bolder'
              },
            },
            emphasis: {
              show: true,
              textStyle: {
                color: '#222b45',
                fontSize: 15,
                fontWeight: 'bolder'
              }
            }
          },

          labelLine: {
            normal: {
              show: false,
              lineStyle: {},
            },
          },
        },
      ],
    };
  }

  /**
   *
   * @param chartType
   * @param isPercentSelected
   */
  showRecordsInPercentOrAbsoluteCount(chartType: string, isPercentSelected: any = 2) {
    this.getDashboardChartData(chartType, this.ownFormData, isPercentSelected.id, this.monthOrYear);
  }

  /**
   *
   * @param isRemoveItemMethod
   */
  filterDashboardData(parentFormData: FormData) {
    this.ownFormData = parentFormData;
    this.getDashboardChartData("gender_wise", parentFormData);
  }

}
