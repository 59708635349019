<div class="page-title-box">
    <h1>{{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="/participant-documents" title="Participant Documents" class="only-link">Manage Participant
                Documents </a>
        </li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xl-8 col-md-12 col-sm-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">{{title}}</h4>
                </div>
                <form [formGroup]="participantDocumentImportForm" #form="ngForm" class="edit-mode"
                    (ngSubmit)="participantDocumentSubmit()">
                    <mat-spinner *ngIf="showLoading"></mat-spinner>
                    <div class="progress-overlay"></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': participantDocumentImportForm.controls['upload_from'].errors && participantDocumentImportForm.controls['upload_from'].errors?.['requiredfield'] }">
                                    <mat-label><i>*</i> Upload Documents Through: </mat-label>
                                    <div class="control-pad">
                                        <ng-select formControlName="upload_from" bindLabel="label" bindValue="value"
                                            [dropdownPosition]="'bottom'" [searchable]="false" [closeOnSelect]="true"
                                            [items]="documentFileType" (change)="uploadFromChange($event)"
                                            placeholder="Upload Documents Through">
                                        </ng-select>
                                        <mat-error
                                            *ngIf="participantDocumentImportForm.controls['upload_from'].errors && participantDocumentImportForm.controls['upload_from'].errors?.['requiredfield']">
                                            {{
                                            participantDocumentImportForm.controls['upload_from'].errors?.['requiredfield']
                                            }}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6">
                                <div class="input-wrape"
                                    [ngClass]="{ 'has-error': participantDocumentImportForm.controls['google_drive_folder_id'].errors && participantDocumentImportForm.controls['google_drive_folder_id'].errors?.['requiredfield'] }"
                                    *ngIf="participantDocumentImportForm.controls['upload_from'].value == 'Google'">
                                    <mat-label><i>*</i>Google Drive Folder ID: </mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput formControlName="google_drive_folder_id" #input
                                            placeholder="Google Drive Folder ID" type="text">
                                    </mat-form-field>
                                    <mat-error
                                        *ngIf="participantDocumentImportForm.controls['google_drive_folder_id'].errors && participantDocumentImportForm.controls['google_drive_folder_id'].errors?.['requiredfield']">
                                        {{
                                        participantDocumentImportForm.controls['google_drive_folder_id'].errors?.['requiredfield']
                                        }}
                                    </mat-error>
                                </div>
                                <div class="upload-thumb-wrape full">
                                    <div class="input-wrape"
                                        *ngIf="participantDocumentImportForm.controls['upload_from'].value == 'File'"
                                        [ngClass]="{ 'has-error': participantDocumentImportForm.controls['document_name'].errors && participantDocumentImportForm.controls['document_name'].errors?.['requiredfield'] }">
                                        <mat-label><i>*</i>Select Document(s):</mat-label>
                                        <div class="upload-wrape side-info">
                                            <input id="fileInput" multiple type="file" formControlName="document_name"
                                                class="ng-hide" placeholder="upload"
                                                (change)="onSelectDocumentFile($event)" accept=".pdf">
                                            <button type="button" id="uploadButton" class="btn" aria-label="attach_file"
                                                mat-raised-button class="btn" color="primary"> Upload</button>
                                            <div class="info-pad popup-over">
                                                <button class="info-icon"></button>
                                                <div class="info-section popupcontent">
                                                    Please choose .pdf file only
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="imageFiles.length > 0" class="upload-filename">
                                            <a *ngFor="let file of imageFiles; let i=index;" href="JavaScript:void(0);">
                                                File{{i + 1}}
                                            </a>
                                        </div>
                                        <span
                                            *ngIf="participantDocumentImportForm.controls['document_name'].errors && (participantDocumentImportForm.controls['document_name'].touched || participantDocumentImportForm.controls['document_name'].dirty)">
                                            <mat-error
                                                *ngIf="participantDocumentImportForm.controls['document_name'].errors?.['fileInvalidSize']">
                                                {{
                                                participantDocumentImportForm.controls['document_name'].errors?.['fileInvalidSize']
                                                }}</mat-error>
                                            <mat-error
                                                *ngIf="participantDocumentImportForm.controls['document_name'].errors?.['fileInvalidType']">
                                                {{
                                                participantDocumentImportForm.controls['document_name'].errors?.['fileInvalidType']
                                                }}</mat-error>
                                        </span>
                                        <mat-error
                                            *ngIf="participantDocumentImportForm.controls['document_name'].errors && participantDocumentImportForm.controls['document_name'].errors?.['requiredfield']">
                                            {{
                                            participantDocumentImportForm.controls['document_name'].errors?.['requiredfield']
                                            }}
                                        </mat-error>
                                        <mat-error
                                            *ngIf="participantDocumentImportForm.controls['document_name'].errors && participantDocumentImportForm.controls['document_name'].errors?.['invalidRecord']">
                                            {{
                                            participantDocumentImportForm.controls['document_name'].errors?.['invalidRecord']
                                            }}
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button mat-raised-button class="btn" color="warn" routerLink="/participant-documents"
                            title="Cancel">Cancel</button>
                        <button mat-raised-button class="btn" color="secoundary" type="submit"
                            title="Save">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>