import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constant';
import { CityService } from 'src/app/service/city.service';
import { CommonService } from 'src/app/service/common.service';
import { DictionaryService } from 'src/app/service/dictionary.service';
import { PincodeService } from 'src/app/service/pincode.service';
import { ProjectService } from 'src/app/service/project.service';
import { SiteService } from 'src/app/service/site.service';
import { StateService } from 'src/app/service/state.service';
export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};
@Component({
  selector: 'app-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class SiteFormComponent implements OnInit {

  public step = 0;
  public title: any;
  public loading: Boolean = false;
  public isAddMode: boolean = true;
  public disableSubmitBtn: boolean = false;
  public siteID: any = '';
  public siteId: any = '';
  public siteDetails: any = '';
  public siteForm!: FormGroup;
  public startMinDate = new Date();
  public endMinDate = new Date();
  public buttonText: string;
  public cancelLink: string = '/site';
  public organizerFeedbackForm: string = '';
  public organizerFeedbackFormUrl: string = '';
  public organizerAppreciationLetter: string = '';
  public organizerAppreciationLetterUrl: string = '';
  public siteDetail: any;
  public pincodeList!: any[];
  public stateList!: any[];
  public cityList!: any[];
  public projectList!: any[];
  public ethicsCommitteeList!: any[];
  public countryID: any = 0;
  public researchProtocolVersion: string = '';
  public informedConsentFormVersion: string = '';
  public ethicsCommitteeRenewalOrExpiryDate: string = '';
  public phfVersion: string = '';
  public selectedFile4!: File;
  public selectedFile5!: File;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router,
    private pincodeService: PincodeService,
    private siteService: SiteService,
    public stateService: StateService,
    public cityService: CityService,
    public commonService: CommonService,
    private projectService: ProjectService,
    private dictionaryService: DictionaryService,
  ) {
    this.siteID = this.route.snapshot.paramMap.get("id");
    if (this.siteID !== 0 && this.siteID !== null) {
      this.title = 'Edit Site';
      this.buttonText = 'Save';
    } else {
      this.title = 'Add Site';
      this.buttonText = 'Add';
      this.siteID = 0;
    }
  }

  ngOnInit(): void {
    this.isAddMode = !this.siteID;
    if (this.siteID !== 0 && this.siteID !== null) {
      this.setEditData();
    }
    this.getStates();
    this.getCities();
    this.getProject();
    this.siteForm = this.fb.group({
      siteName: ["", [Validators.required]],
      siteCode: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      numberOfCampDays: ["", [Validators.required]],
      pincodeId: [null, [Validators.required]],
      stateId: [null, [Validators.required]],
      districtId: [null, ""],
      cityId: [null, [Validators.required]],
      street: [null, [Validators.required]],
      locality: ["", ""],
      siteCoordinatorName: ["", Validators.required],
      siteCoordinatorPhone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      siteCoordinatorPhone2: [
        "",
        [Validators.minLength(10), Validators.maxLength(10)],
      ],
      siteCoordinatorEmail: [
        "",
        Validators.compose([
          // Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
          Validators.pattern("[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
      ],
      stayHospitalityArragementsDoneBy: [null, ""],
      organizerFeedbackForm: ["", ""],
      organizerAppreciationLetter: ["", ""],
      dataSharable: [0, ""],
      projectId: [null, Validators.required],
      ethicsCommitteeId: [null, Validators.required],
      consentVersion: [null, ""],
      consentVersionOther: ["", ""],
      isLiveSite: [1, ""],
      siteType: ["INTERNAL", ""],
    });

  }

  onSubmit() {
    this.compareTwoDates();
    if (this.siteForm.invalid) {
      this.commonService.validateAllFormFields(this.siteForm);
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();

    const dataSharable = this.siteForm.controls["dataSharable"].value;

    const constantVersion =
      this.siteForm.controls["consentVersion"].value === null
        ? ""
        : this.siteForm.controls["consentVersion"].value;

    formData.append("site_code", this.siteForm.controls["siteCode"].value);
    formData.append(`site_name`, this.siteForm.controls["siteName"].value);
    formData.append(
      `start_date`,
      this.commonService.transformDate(this.siteForm.controls["startDate"].value) as string
    );
    formData.append(
      `end_date`,
      this.commonService.transformDate(this.siteForm.controls["endDate"].value) as string
    );

    formData.append(
      `number_of_camp_days`,
      this.siteForm.controls["numberOfCampDays"].value
    );

    formData.append(`pincode_id`, this.siteForm.controls["pincodeId"].value);
    formData.append(`state_id`, this.siteForm.controls["stateId"].value);
    formData.append(`city_id`, this.siteForm.controls["cityId"].value);
    formData.append("locality", this.siteForm.controls["locality"].value);
    formData.append(`street`, this.siteForm.controls["street"].value);
    formData.append(`country_id`, this.countryID);

    formData.append(
      `site_coordinator_name`,
      this.siteForm.controls["siteCoordinatorName"].value
    );
    formData.append(`site_coordinator_phone_code`, this.countryID);
    formData.append(
      `site_coordinator_phone`,
      this.siteForm.controls["siteCoordinatorPhone"].value
    );
    formData.append(`site_coordinator_phone_code2`, this.countryID);
    formData.append(
      `site_coordinator_phone2`,
      this.siteForm.controls["siteCoordinatorPhone2"].value
    );
    formData.append(
      `site_coordinator_email`,
      this.siteForm.controls["siteCoordinatorEmail"].value
    );

    formData.append(
      `stay_hospitality_arragements_done_by`,
      this.siteForm.controls["stayHospitalityArragementsDoneBy"].value
    );

    if (this.siteForm.controls['organizerFeedbackForm'].value != '') {
      formData.append(
        "organizer_feedback_form", this.selectedFile4,
        this.selectedFile4.name
      );
    }
    if (this.siteForm.controls['organizerAppreciationLetter'].value != '') {
      formData.append(
        "organizer_appreciation_letter", this.selectedFile5,
        this.selectedFile5.name
      );
    }

    formData.append(`data_sharable`, dataSharable);
    formData.append("project_id", this.siteForm.controls["projectId"].value
    );
    formData.append(
      "ethics_committee_id", this.siteForm.controls["ethicsCommitteeId"].value
    );
    formData.append(`consent_type`, constantVersion);
    formData.append(
      `consent_type_other`,
      this.siteForm.controls["consentVersionOther"].value
    );

    formData.append(
      `is_live_site`,
      this.siteForm.controls["isLiveSite"].value
    );
    
    formData.append(
      `site_type`,
      this.siteForm.controls["siteType"].value
    );
    if (this.isAddMode) {
      this.createSite(formData);
    } else {
      this.updateSite(formData);
    }
  }

  /**
   * call Create Site api
   * @returns null
   */
  private createSite(formData: any): void {
    this.loading = true;
    this.siteService.store(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast("Site added successfully");
          this.router.navigate(["/site"]);
        } else {
          if (typeof result.message === "object") {
            for (const key in result.message) {
              if (key === "end_date") {
                this.siteForm.controls["endDate"].setErrors({ validField: result.message[key] });
              } else if (key === "site_code") {
                this.siteForm.controls["siteCode"].setErrors({ validField: result.message[key] });
              }
            }
          }
          this.loading = false;
          this.disableSubmitBtn = false;
        }
      },
      error: (err) => {
        this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
        this.loading = false;
        this.disableSubmitBtn = false;
      }
    });
  }
  private updateSite(formData: any) {
    this.loading = true;
    this.siteService.update(formData, parseInt(this.siteID)).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast("Site updated successfully");
          this.router.navigate(["/site"]);
        } else {
          if (typeof result.message === "object") {
            for (const key in result.message) {
              if (key === "end_date") {
                this.siteForm.controls["endDate"].setErrors({ validField: result.message[key] });
              } else if (key === "site_code") {
                this.siteForm.controls["siteCode"].setErrors({ validField: result.message[key] });
              }
            }
          }
          this.loading = false;
          this.disableSubmitBtn = false;
        }
      },
      error: (err) => {
        this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
        this.loading = false;
        this.disableSubmitBtn = false;
      }
    });
  }

  private setEditData() {
    this.loading = true;
    this.siteService.getRow(this.siteID).subscribe({
      next: (result: any) => {
        if (result.status !== AppConstants.serverSuccessStatus) {
          this.router.navigate(["page-not-found"]);
        }
        this.siteDetail = result.data;

        if (this.siteDetail.project_id !== undefined && this.siteDetail.project_id != '') {
          this.getEthicsCommittee({ id: this.siteDetail.project_id });
          this.changeEthicsCommittee(this.siteDetail);
        }
        if (this.siteDetail.pincode !== undefined && this.siteDetail.pincode != '') {
          this.searchPincode({ term: this.siteDetail.pincode });
        }
        if (this.siteDetail.city_name !== undefined && this.siteDetail.city_name != '') {
          this.searchCities({ term: this.siteDetail.city_name });
        }
        this.countryID = this.siteDetail.country_id;

        let stateId = null;
        let cityId = null;
        let pincodeId = null;
        let projectId = null;
        let ethicsCommitteeId = null;

        if (
          this.siteDetail.pincode_id !== "" &&
          this.siteDetail.pincode_id !== 0 &&
          this.siteDetail.pincode_id !== "0"
        ) {
          pincodeId = this.siteDetail.pincode_id;
        }

        if (
          this.siteDetail.state_id !== "" &&
          this.siteDetail.state_id !== 0 &&
          this.siteDetail.state_id !== "0"
        ) {
          stateId = this.siteDetail.state_id;
        }

        if (
          this.siteDetail.city_id !== "" &&
          this.siteDetail.city_id !== 0 &&
          this.siteDetail.city_id !== "0"
        ) {
          cityId = this.siteDetail.city_id;
        }

        if (
          this.siteDetail.project_id !== "" &&
          this.siteDetail.project_id !== 0 &&
          this.siteDetail.project_id !== "0"
        ) {
          projectId = this.siteDetail.project_id;
        }

        if (
          this.siteDetail.ethics_committee_id !== "" &&
          this.siteDetail.ethics_committee_id !== 0 &&
          this.siteDetail.ethics_committee_id !== "0"
        ) {
          ethicsCommitteeId = this.siteDetail.ethics_committee_id;
        }

        if (this.siteDetail.ethics_committee_id > 0) {
          this.ethicsCommitteeList.push({
            id: this.siteDetail.ethics_committee_id,
            name: this.siteDetail.ethics_committee_name,
          });
          this.ethicsCommitteeList = this.ethicsCommitteeList.slice(0);
        }

        let stayHospitalityArragementsDoneBy =
          this.siteDetail.stay_hospitality_arragements_done_by;
        stayHospitalityArragementsDoneBy =
          stayHospitalityArragementsDoneBy === "null"
            ? ""
            : stayHospitalityArragementsDoneBy;

        this.siteForm.patchValue({
          siteCode: this.siteDetail.site_code,
          siteName: this.siteDetail.site_name,
          startDate: this.commonService.transformDate(this.siteDetail.start_date),
          endDate: this.commonService.transformDate(this.siteDetail.end_date),
          numberOfCampDays: this.siteDetail.number_of_camp_days,
          street: this.siteDetail.street,
          locality: this.siteDetail.locality,
          pincodeId: pincodeId,
          cityId: cityId,
          stateId: stateId,
          isLiveSite: this.siteDetail.is_live_site == true ? 1 : 0,
          siteType: this.siteDetail.site_type,
          siteCoordinatorName: this.siteDetail.site_coordinator_name,
          siteCoordinatorPhone: this.siteDetail.site_coordinator_phone,
          siteCoordinatorPhone2: this.siteDetail.site_coordinator_phone2,
          siteCoordinatorEmail: this.siteDetail.site_coordinator_email,
          stayHospitalityArragementsDoneBy: stayHospitalityArragementsDoneBy,

          dataSharable: this.siteDetail.data_sharable,
          projectId: projectId,
          ethicsCommitteeId: ethicsCommitteeId,
          consentVersion:
            this.siteDetail.consent_type !== ""
              ? this.siteDetail.consent_type
              : null,
          consentVersionOther: this.siteDetail.consent_type_other,
        });

        this.organizerFeedbackForm = this.siteDetail.organizer_feedback_form;
        this.organizerFeedbackFormUrl =
          this.siteDetail.organizer_feedback_form_url;

        this.organizerAppreciationLetter =
          this.siteDetail.organizer_appreciation_letter;
        this.organizerAppreciationLetterUrl =
          this.siteDetail.organizer_appreciation_letter_url;

        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        // console.log('err :: ', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }




  /**
   * It will check siteCode
   * @param event
   */
  checkSiteName(event: any) {
    const siteName = event.target.value;
    this.siteService.checkSiteName(siteName, this.siteID).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverFailStatus) {
          this.siteForm.controls["siteName"].setErrors({ unique: 'Site name already exists' });
        } else {
          this.siteForm.controls["siteName"].setErrors(null);
        }
      },
      error: (err) => {
        // console.log('err :: ', err);
      },
      complete: () => {
        // console.log('complete :: ');
      }
    });
  }

  /**
  * It will check siteCode
  * @param event
  */
  checkSiteCode(event: any) {
    const sCode = event.target.value;
    this.siteService.checkSiteCode(sCode, this.siteID).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverFailStatus) {
          this.siteForm.controls["siteCode"].setErrors({ unique: result.message });
        } else if(!this.siteForm.controls['siteCode'].errors?.['pattern']) {
          this.siteForm.controls["siteCode"].setErrors(null);
        }
      },
      error: (err: any) => {
        // console.log('err :: ', err);
      },
    });
  }

  compareTwoDates() {
    let startDate = this.commonService.transformDate(this.siteForm.controls['startDate'].value) as string;
    let endDate = this.commonService.transformDate(this.siteForm.controls['endDate'].value) as string;
    if (endDate !== null) {
      if (endDate < startDate) {
        this.siteForm.controls["startDate"].setErrors({ validField: "Start date should be less than end date" });
        this.commonService.showErrorToast("Start date should be less than end date");
      } else {
        this.siteForm.controls["startDate"].setErrors(null);
      }
    }
  }

  /**
   * It will search pincode
   * @param event
   */
  searchPincode(event: any) {
    if (event.term !== undefined && event.term.length > 2) {
      this.loading = true;
      this.pincodeService.searchPincode(event.term).subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.pincodeList = result.data;
          } else {
            this.siteForm.patchValue({
              cityId: "",
              stateId: "",
            });
          }
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.log('Pincode Search err :: ', err);
        }
      });
    }
  }
  selectPincode(event: any) {
    this.getStates();
    this.getCities();
    this.countryID = event != undefined ? event.country_id : 0;
    this.siteForm.patchValue({
      cityId: event.city_id !== 0 ? event.city_id : null,
      stateId: event.state_id !== 0 ? event.state_id : null,
    });
  }

  /**
   * Get State List
   *
   * @returns object
   */
  getStates() {
    this.stateService.getActiveList().subscribe((result: any) => {
      this.stateList = result.data;
    });
  }

  /**
   * Get State List
   *
   * @returns object
   */
  getCities() {
    this.cityService.getActiveList().subscribe((result: any) => {
      this.cityList = result.data;
    });
  }


  /**
  * Search City List
  *
  * @returns object
  */
  searchCities(event: any) {
    if (event.term !== undefined && event.term.length > 2) {
      this.cityService.searchCityByString(event.term).subscribe((result: any) => {
        this.cityList = result.data;
      });
    }
  }

  /**
  * This function is used to get project List
  */
  getProject() {
    this.projectService.getActiveList().subscribe({
      next: (result: any) => {
        this.projectList = result.data;
      },
      error: (err) => { },
    });
  }

  /**
  * This function is used to get ethics committee List
  */
  getEthicsCommittee(event: any) {
    this.loading = true;
    this.ethicsCommitteeList = [];
    this.researchProtocolVersion = "";
    this.ethicsCommitteeRenewalOrExpiryDate = "";
    this.phfVersion = "";
    this.informedConsentFormVersion = "";
    this.siteForm.controls['ethicsCommitteeId'].setValue(null);
    if (event == undefined || event.id == undefined) {
      this.ethicsCommitteeList = [];
      this.loading = false;
      return;
    }
    const projectId = event.id !== undefined ? event.id : event;
    this.dictionaryService.getEthicsCommittee(projectId).subscribe({
      next: (result: any) => {
        this.ethicsCommitteeList = result.data.length > 0 ? result.data : [];
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      }
    });
  }
  changeEthicsCommittee(event: any) {
    this.researchProtocolVersion = event != undefined ? event.research_protocol_version : '';
    this.ethicsCommitteeRenewalOrExpiryDate = event != undefined ? event.annual_expiry_at : ''
    this.phfVersion = event != undefined ? event.patient_history_form_version : ''
    this.informedConsentFormVersion = event != undefined ? event.informed_consent_form_version : ''
  }


  uploadFileEvt(event: any, fieldName: string, fileObject: string) {
    if (event.target.files && event.target.files[0]) {

      let file = event.target.files[0];
      (this as any)[fieldName] = file.name;
      if (file !== undefined) {
        (this as any)[fieldName] = file.name;
      } else {
        (this as any)[fieldName] = '';
      }
      if (
        file !== undefined &&
        file.type !== "application/pdf"
      ) {
        this.siteForm.controls[fieldName].setErrors({ validField: "Please select pdf file only" });
      } else if (
        file !== undefined &&
        file.size / 1024 / 1024 > 25
      ) {
        this.siteForm.controls[fieldName].setErrors({ validField: 'Files size limit exceeded. Max file size is 25 MB.' });
      } else {
        (this as any)[fileObject] = <File>event.target.files[0];
      }
    }
  }

  /**
  * Omit Special Characters
  * @param event
  */
  omitSpecialChar(event: any) {
    const k = event.charCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57)
    );
  }
  /**
   *
   * @param event
   */
  onlyNumeric(event: any) {
    const pattern = /^([0-9]+)$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /**
     *
     * @param field
     */
  displayFieldCss(field: string) {
    return {
      "has-error": this.commonService.isFieldValid(this.siteForm, field),
      "has-feedback": this.commonService.isFieldValid(this.siteForm, field),
    };
  }

  // accordion code start
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

}
