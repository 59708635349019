<div class="page-title-box">
  <h1>Manage HTR</h1>
  <ul class="bradcrumb">
    <li>Manage HTR</li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">HTR Data Filter</h4>
          <div class="right-control">
            <button *ngIf="show_qc_options && hide_review_options && currentMenuPermission?.edit == true" mat-raised-button class="btn" color="secoundary" title="Mark As Reviewed" (click)="markAsReviewed()">Mark As Reviewed</button>
            <button style="margin: 0 0 0 5px;" *ngIf="show_qc_options && hide_review_options && currentMenuPermission?.edit == true" mat-raised-button class="btn" color="secoundary" title="Mark As Q'C Rejected" (click)="markAsQCRemove()">Mark As Q'C Rejected</button>
            <button style="margin: 0 0 0 5px;" mat-raised-button class="btn" color="secoundary" title="Upload HTR" routerLink="/htr/upload"
              *ngIf="currentMenuPermission?.edit == true" >Upload HTR</button>
            <button mat-raised-button class="btn" color="secoundary" title="Add HTR" style="margin: 0 0 0 5px;"
              routerLink="/htr/add" *ngIf="currentMenuPermission?.create == true" >Add HTR</button>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="htrFilterForm" #form="ngForm" class="edit-mode" (ngSubmit)="getAllHtr()">
            <div class="row">
              <div class="col-xl-4 col-md-4 m-b-0">
                <div class="input-wrape">
                  <mat-label>Site Code(s) </mat-label>
                  <div class="control-pad">
                    <ng-select formControlName="siteCodes" bindLabel="site_code" bindValue="id"
                      [dropdownPosition]="'bottom'" [searchable]="true" [multiple]="true" [closeOnSelect]="true"
                      [items]="siteList" placeholder="Select Site Code">
                    </ng-select>

                  </div>
                </div>

              </div>
              <div class="col-xl-7 col-md-4 m-b-0">
                <div class="input-wrape">
                  <mat-label>&nbsp;</mat-label>
                  <mat-checkbox-group class="checkbox-section horizontal">
                    <mat-checkbox formControlName="is_htr_qced" class="example-margin">Is QC'ed?</mat-checkbox>
                    <mat-checkbox formControlName="is_htr_reviewed" class="example-margin">Is Reviewed?</mat-checkbox>
                  </mat-checkbox-group>
                  <button mat-raised-button class="btn" color="secoundary" title="Reload">Reload</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-xl-12 col-md-12">
      <div class="card-wrape">
        <div class="card-head">
          <h4 class="left-text">Manage HTR List</h4>
          <div class="right-control">
            <div class="table-search-wrape side-info">
              <span class="icon"></span>
              <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control" type="text">
              <div class="info-pad popup-over">
                <button class="info-icon"></button>
                <div class="info-section popupcontent">
                  New PID, Site Code, First Name, Last Name, Diagnostic Lab
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body table-section">
          <div class="table-wrape">
            <mat-table [dataSource]="dataSource" (matSortChange)="SortFilter($event)" matSort>

              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row.id) : null" [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> HTR ID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="new_pid">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="user-name"> New PID </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="100px" class="user-name"> {{element.new_pid}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="site_code">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Site Code </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.site_code}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.first_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.last_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="diagnostic_lab">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Diagnostic Lab </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.diagnostic_lab}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                  <div class="icons-wrape">
                    <a routerLink='/htr/edit/{{element.id}}' *ngIf="currentMenuPermission?.edit == true" class="theme-icon edit-icon" title="Edit HTR"></a>
                    <a routerLink='/htr/view/{{element.id}}' *ngIf="currentMenuPermission?.view == true && currentMenuPermission?.edit == false" class="theme-icon view-icon" title="View HTR"></a>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              <div *matNoDataRow>No records found</div>
            </mat-table>

            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
              showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>