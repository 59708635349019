import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AuthService } from '../../main-container/auth/service/auth.service';
import { Router, RoutesRecognized } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public user: any;
  public userPic: any =
    '../../../assets/images/' + AppConstants.defaultProfileImage;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
    this.user = userInfo;
    this.authService.loggedUser.subscribe({
      next: (result: any) => {
        this.user = result;
      }
    })
    this.authService.account.subscribe((user) => {
      this.user = user;
    });
  }

  /**
   * Logout
   */
  logout() {
    Swal.fire({
      title: 'Logout?',
      text: 'Are you sure want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
        setTimeout( () => {
          this.router.navigate(['login']);
        }, 2000);
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
}
