import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class MatrixService extends CrudService {
  constructor(public override http: HttpClient) {
    super('matrix', http);
  }
  
  /**
    * get Participant Matrix By Participant Id
    * @param id
    */
    getParticipantMatrix(participantId: any) {
      return this.http.get(`${ this.baseUrl }/participant/${participantId}`);
    }
   
  /**
   * get Matrix Count
   * @param id
   */
  getMatrixCount(siteId: any) {
    return this.http.get(`${ this.baseUrl }/count/${siteId}`);
  }

  /**
   * get Site Matrix By Site Id
   * @param id
   */
  getSiteMatrix(siteId: any,startDate:any=null,endDate:any=null) {
    return this.http.get(`${ this.baseUrl }/site/${siteId}?start_date=${startDate}&end_date=${endDate}`);
  }

  /**
     * get send report
     */
  sendReport(data: any) {
    return this.http.post(`${ this.baseUrl }/send-report`,data);
  }

  /**
   * Sync Cohort DM data 
   * @param siteId 
   * @returns 
   */
  syncCohortDMData(siteId: any) {
    return this.http.get(`${this.baseUrl}/syncDMCohort/${siteId}`);
  }

  /**
   * Sync Site Matrix data 
   * @param siteId 
   * @returns 
   */
  syncSiteMatrixData(siteId: any) {
    return this.http.get(`${this.baseUrl}/syncSiteMatrix/${siteId}`);
  }
  
}
