import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticipantService } from 'src/app/service/participant.service';
import Swal from 'sweetalert2';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-participant-dashboard',
  templateUrl: './participant-dashboard.component.html',
  styleUrls: ['./participant-dashboard.component.scss']
})
export class ParticipantDashboardComponent implements OnInit {

  @Input() participantInformation!: boolean;
  @Output() participantInformationChange = new EventEmitter<boolean>();
  @Input() dashboard!:boolean;
  @Output() dashboardChange = new EventEmitter<boolean>();
  @Input() diseaseHistory!: boolean;
  @Output() diseaseHistoryChange = new EventEmitter<boolean>();
  @Input() medicationHistory!: boolean;
  @Output() medicationHistoryChange = new EventEmitter<boolean>();
  @Input() receiveSTI!: boolean;
  @Output() sampleTestInformationChange = new EventEmitter<boolean>();
  @Input() currentMenuPermission: any;

  public PID: any;
  public dashboardInformation: any;
  public loading: boolean = false;

  constructor(
    private participantService: ParticipantService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
  ) {
    this.PID = this.route.snapshot.paramMap.get('id');
    this.getDashbaord();
  }


  getDashbaord() {
    this.loading = true;
    this.participantService.dashboard(this.PID).subscribe({
      next: (result: any) => {
        console.log(result);
        if (result.status === 1) {
          this.dashboardInformation = result.data;
          console.log(this.dashboardInformation);
        } else {
          this.router.navigate(['/page-not-found']);
        }
        this.loading = false;
      },
      error: (e) => {

        this.loading = false;
      },
      complete: () => { },
    });
  }

  ngOnInit(): void {
    console.log('participantInformation=', this.participantInformation);
    console.log('dashboard=', this.dashboard);
  }

  showPI() {
    this.dashboardChange.emit(false);
    this.participantInformationChange.emit(true);
  }

  showDH(){
    this.dashboardChange.emit(false);
    this.diseaseHistoryChange.emit(true);
  }

  showMedication(){
    this.dashboardChange.emit(false);
    this.medicationHistoryChange.emit(true);
  }

  showSTI(){
    this.dashboardChange.emit(false);
    this.sampleTestInformationChange.emit(true);
  }

  // sweet alert code start
  showProfile() {
    Swal.fire({
      imageUrl: this.dashboardInformation.photo,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  showBarcode() {
    Swal.fire({
      imageUrl: this.dashboardInformation.photo_pin,
      showConfirmButton: false,
      showCloseButton: true
    });
  }

  markQCRejected(participantId: any) {
    Swal.fire({
      title: 'Reject?',
      html: 'Are you sure you want to reject PHF QC?',
      imageUrl: "../../../../../assets/images/fail.png",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.participantService.updateQCedRejected(participantId).subscribe({
          next: (prmResult: any) => {
            this.loading = false;
            if (prmResult.status === AppConstants.serverSuccessStatus) {
              this.getDashbaord();
            } else {
            }
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.showSuccessToast("Something went wrong. Please contact to administrator.");
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  markQCApproved(participantId: any) {
    Swal.fire({
      title: 'Approve?',
      html: 'Are you sure you want to approve PHF QC?',
      imageUrl: "../../../../../assets/images/success.png",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        console.log('This is test = ' + participantId);
        this.loading = true;
        this.participantService.updateQCed(participantId).subscribe({
          next: (prmResult: any) => {
            this.loading = false;
            if (prmResult.status === AppConstants.serverSuccessStatus) {
              this.getDashbaord();
            } else {
            }
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.showSuccessToast("Something went wrong. Please contact to administrator.");
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  markPHFReviewed(participantId: any) {
    Swal.fire({
      title: 'Review?',
      html: 'Are you sure you want to review PHF?',
      imageUrl: "../../../../../assets/images/success.png",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn mat-secoundary',
        cancelButton: 'btn mat-warn ml-1',
      },
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.participantService.markPHFReviewed(participantId).subscribe({
          next: (prmResult: any) => {
            this.loading = false;
            if (prmResult.status === AppConstants.serverSuccessStatus) {
              this.getDashbaord();
            } else {
            }
          },
          error: (err: any) => {
            this.loading = false;
            this.commonService.showSuccessToast("Something went wrong. Please contact to administrator.");
          }
        });
      }
      if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

}
