import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { AllergicReactionService } from 'src/app/service/allergic-reaction.service';
import { CommonService } from 'src/app/service/common.service';
@Component({
  selector: 'app-allergic-reaction-form',
  templateUrl: './allergic-reaction-form.component.html',
  styleUrls: ['./allergic-reaction-form.component.scss']
})
export class AllergicReactionFormComponent implements OnInit {

  public allergicReactionForm!: FormGroup;
  public title: any;
  public allergicReactionId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/dictionary-management/allergic-reaction";
  public userId: any = "";
  public disableSubmitBtn: boolean = false;
  public isApproved: number = 1;
  public selectMapOption: boolean = false;
  public isCurate: boolean = false;
  public showLoading: boolean = false;
  public allergicReactionMinLength: number = 3;
  public allergicReactionDetails: any = [];
  public allergicReactionList: any[] = [];
  public allergyTypes: any;

  constructor(
    private allergicReactionService: AllergicReactionService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.allergicReactionId = this.route.snapshot.params['id'];
    if (this.allergicReactionId != 0 && this.allergicReactionId != null) {
      this.setEditData();
      this.title = "Edit allergic reaction/symptom";
      this.buttonText = "Save";
    } else {
      this.title = "Add allergic reaction/symptom";
      this.buttonText = "Add";
      this.allergicReactionId = 0;
    }
  }

  ngOnInit(): void {
    let allergyTypes = JSON.parse(localStorage.getItem('constants') as string);
    this.allergyTypes = allergyTypes['ALLERGY_TYPES'];
    this.isAddMode = !this.allergicReactionId;
    const routerSubscription = this.route.queryParams.subscribe((queryParams: any) => {
      if (this.router.url.indexOf('curation-pending') >= 0) {
        this.isCurate = true;
        this.title = "Curate allergic reaction/symptom";
        this.cancelLink = '/dictionary-management/allergic-reaction/curation-pending';
        // Get allergicReaction Dictionary
        this.getAllergicReactionList();
      }
    });

    this.userId = localStorage.getItem("userId");
    this.allergicReactionForm = this.formBuilder.group({
      'allergic_reaction_symptoms': ['', [Validators.required, Validators.minLength(this.allergicReactionMinLength), Validators.maxLength(255)]],
      'isApproved': [this.isApproved, []],
      'mapWithOtherAllergicReaction': [null, []]
    });
  }

  setEditData() {
    this.allergicReactionService.getRow(this.allergicReactionId)
      .subscribe({
        next: (result: any) => {
          if (result.status != AppConstants.serverSuccessStatus) {
            this.commonService.showErrorToast('Allergic Reaction not found.');
            this.router.navigate([this.cancelLink]);
          } else {
            this.allergicReactionDetails = result['data'];
            this.allergicReactionForm.patchValue({
              allergic_reaction_symptoms: this.allergicReactionDetails.allergic_reaction_symptoms
            });
          }
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
  }

  /**
  * submit form
  * @returns null
  */
  onSubmit(): void {
    if (this.allergicReactionForm.invalid) {
      this.commonService.validateAllFormFields(this.allergicReactionForm);
      this.commonService.showErrorToast(
        'OOPS! Please enter correct values'
      );
      return;
    }
    this.disableSubmitBtn = true;
    const formData: FormData = new FormData();
    formData.append('allergic_reaction_symptoms', this.allergicReactionForm.controls['allergic_reaction_symptoms'].value);
    formData.append('is_approved', this.allergicReactionForm.controls['isApproved'].value);
    formData.append('map_with_other_reaction', (this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].value) ? this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].value : 0);

    if (this.isAddMode) {
      this.createAllergicReaction(formData);
    } else {
      this.updateAllergicReaction(formData);
    }
  }

  /**
  * call Create Allergic Reaction api
  * @returns null
  */
  private createAllergicReaction(formData: any): void {
    this.allergicReactionService.store(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/dictionary-management/allergic-reaction"]);
            this.commonService.showSuccessToast(
              result.message
            );
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err) => {
          console.log('error from create allergic Reaction', err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }

  /**
   * call Update Allergic Reaction api
   * @returns null
   */
  private updateAllergicReaction(formData: any): void {
    formData.append('updated_by', this.userId);
    this.allergicReactionService.update(formData, this.route.snapshot.params['id'])
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          } else {
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error: (err) => {
          console.log('error from update allergic Reaction', err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
  }

  /**
    * Get all allergic Reaction for validate Country
    * @returns object
    */
  getAllergicReactionList() {
    this.allergicReactionService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.allergicReactionList = result.data;
        },
        error: (e) => {
          console.log('error from get Allergic Reaction list', e.error);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        },
      });
  }

  getReactionError() {
    if (this.allergicReactionForm.controls['allergic_reaction_symptoms'].errors!['required']) {
      return 'Allergic reaction/symptom is required.';
    }
    if (this.allergicReactionForm.controls['allergic_reaction_symptoms'].errors!['minlength']) {
      return 'Min Length is ' + this.allergicReactionMinLength + ' characters.';
    }
    if (this.allergicReactionForm.controls['allergic_reaction_symptoms'].errors!['maxlength']) {
      return 'Max Length is 255 characters.';
    }
    if (this.allergicReactionForm.controls['allergic_reaction_symptoms'].errors!['unique']) {
      return this.allergicReactionForm.get("allergic_reaction_symptoms")!.errors!['unique'];
    }
    return '';
  }

  /**
   * Check allergicReaction field is existed or not
   * @returns null
   */
  checkUniqueAllergicReactionFields(
    allergicReactionField: string,
    event: any
  ) {
    const allergicReactionFieldValue = (event.target as HTMLInputElement).value;
    if (allergicReactionFieldValue.length > 255) {
      return false;
    }

    switch (allergicReactionField) {
      case "allergic_reaction_symptoms":
        if (allergicReactionFieldValue.length < this.allergicReactionMinLength) {
          return false;
        }
        break;
    }

    if (allergicReactionFieldValue !== "") {
      this.allergicReactionService
        .checkUniqueAllergicReactionFields(
          allergicReactionField,
          allergicReactionFieldValue,
          this.allergicReactionId
        )
        .subscribe({
          next: (result: any) => {
            if (result.status === 1 || result.status === 200) {
              this.setUniqueError(allergicReactionField, false);
            } else {
              this.setUniqueError(allergicReactionField, true);
            }
          },
          error: (error) => {
            if (error.status === 400) {
              this.setUniqueError(allergicReactionField, true);
            } else {
              console.log(
                'error from check unique allergicReaction', error
              );
            }
          }
        });
    }
    return null;
  }

  mapWithAllergicReactionChange(event: any) {
    if (event && event.id) {
      this.allergicReactionService.getRow(event.id)
        .subscribe({
          next: (result: any) => {
            if (result.status == AppConstants.serverSuccessStatus && result.data) {
              setTimeout(() => {
                this.allergicReactionForm.patchValue({});
              }, 200);
            }
          },
          error: (e: any) => {
            console.log(
              e.error
            );
          },
        });
    } else {
      this.allergicReactionForm.patchValue({});
    }
  }

  getMapWithAllergicReactionError() {
    if (this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].invalid) {
      if (this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].errors!['required']) {
        return 'Select Allergic Reaction';
      }
      if (this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].errors!['pattern']) {
        return 'Please select valid allergic Reaction';
      }
    }
    return '';
  }

  /**
 * Select Approved Option
 *
 * @returns object
 */
  selectOption(value: boolean) {
    this.selectMapOption = value;
    if (value) {
      this.allergicReactionForm.get("mapWithOtherAllergicReaction")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
    } else {
      this.allergicReactionForm.patchValue({
        mapWithOtherAllergicReaction: null,
      });
      this.allergicReactionForm.get('mapWithOtherAllergicReaction')!.setValidators(null);
    }
    this.allergicReactionForm.controls['mapWithOtherAllergicReaction'].updateValueAndValidity()
    this.getMapWithAllergicReactionError();
  }

  /**
   * set unique field error
   * @returns null
   */
  setUniqueError(allergicReactionField: string, isSetError: boolean): void {
    if (isSetError) {
      switch (allergicReactionField) {
        case "allergic_reaction_symptoms":
          this.allergicReactionForm
            .get("allergic_reaction_symptoms")!
            .setErrors({ unique: "Allergic reaction already exists" });
          break;
      }
    } else {
      switch (allergicReactionField) {
        case "allergic_reaction_symptoms":
          this.allergicReactionForm.get("allergic_reaction_symptoms")!.setErrors(null);
          break;
      }
    }
  }

}
