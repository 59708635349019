<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1>{{title}}</h1>
        <ul class="bradcrumb">
            <li>{{title}}</li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>
    

    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <mat-accordion class="example-headers-align">
                    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Duplicate Participant Logic
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accr-participant-container">
                            <p><strong>Level 1: </strong> UID is matching</p>
                            <p><strong>Level 1.1: </strong> Display only if PID UID is matching but PID not matching (this will exclude already linked recollections)</p>
                            <p><strong>Level 2: </strong> Full Name (First, Middle, Last) + Phone Number is matching</p>
                            <p><strong>Level 2.1: </strong> Display only the ones where PID is not matching - keep one instance of multiple recollections matching to newly identified recollection</p>
                            <p><strong>Level 3: </strong> First Name - Gender - GPS rounded to 1 decimal - Phone Number</p>
                            <p><strong>Level 3.1: </strong> Display only one instance of the shortlisted recollections if matching to new one First Name - Gender - GPS rounded to 1 decimal - Phone Number</p>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </section>

    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <div class="card-wrape">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                    <div class="progress-overlay"></div>
                    <div class="card-head">
                        <h4 class="left-text">{{title}}</h4>
                        <div class="right-control">
                            <div class="m-right">
                                <a (click)="downloadDuplicateParticipants()" title="Export Data" class="download-icon"></a>
                            </div>
                            <div class="control-pad filter-participant-wrap">
                                <ng-select [dropdownPosition]="'bottom'"
                                    (change)="filterDuplicateParticipants($event)" [multiple]="false"
                                    [closeOnSelect]="true" [searchable]="false" [multiple]="false"
                                    placeholder="Filter Duplicate Participants">
                                    <ng-option value="level_1">Level 1</ng-option>
                                    <ng-option value="level_1_1">Level 1.1</ng-option>
                                    <ng-option value="level_2">Level 2</ng-option>
                                    <ng-option value="level_2_1">Level 2.1</ng-option>
                                    <ng-option value="level_3">Level 3</ng-option>
                                    <ng-option value="level_3_1">Level 3.1</ng-option>
                                </ng-select>
                            </div>

                            <div class="table-search-wrape side-info">
                                <span class="icon"></span>
                                <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control"
                                    type="text">
                                <div class="info-pad popup-over">
                                    <button class="info-icon"></button>
                                    <div class="info-section popupcontent">
                                        ID, New PID, New Recollection ID, Site Code, Document Name, Document Type,
                                        Uploaded Date
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body table-section">
                        <div class="table-wrape">
                            <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortFilter($event)">
                                <ng-container matColumnDef="id">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> core ID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="new_pid">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="new_pid">
                                        New PID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="new_pid">
                                        <a href="#" routerLink="/participant/edit/{{element.id}}"
                                            target="_blank" title="View Participant">
                                            {{ element.new_pid }}
                                        </a>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="new_recollection_id">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> New Recollection ID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" title="{{element.new_recollection_id}}">
                                        <span>{{element.new_recollection_id}}</span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="site_code">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Site Code </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.site_code}} </mat-cell>
                                </ng-container>
                                
                                <ng-container matColumnDef="collection_date">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Collection Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.collection_date}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="first_name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.first_name}} </mat-cell>
                                </ng-container>
                                
                                <ng-container matColumnDef="last_name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.last_name}} </mat-cell>
                                </ng-container>
                                
                                <ng-container matColumnDef="age">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Age </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.age}} </mat-cell>
                                </ng-container>
                                
                                <ng-container matColumnDef="gender">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.gender}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                <div *matNoDataRow>{{matNoDataRowTitle}}</div>
                            </mat-table>
                            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage"
                                [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)"
                                aria-label="Select page" showFirstLastButtons>
                            </mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
