<section class="my-profile-wrape">
    <div class="page-title-box">
        <h1>Access Denied</h1>
        <ul class="bradcrumb">
            <li>Access Denied</li>
            <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
        </ul>
    </div>
    <section class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-md-12">
                <article class="page-construction">
                    <img src="../../../../assets/images/access-denied.jpg" alt="">
                    <p>You do not have permission to access this page. Please contact to your administrator</p>
                    <a class="btn btn-block btn-hero-primary" routerLink='/dashboard'>
                        Take me home
                    </a>
                </article>
            </div>
        </div>
    </section>
</section>