<div class="page-title-box">
    <h1>Manage {{title}}</h1>
    <ul class="bradcrumb">
        <li>{{title}}</li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
                <mat-spinner *ngIf="loading"></mat-spinner>
                <div class="progress-overlay"></div>
                <app-table [tableData]="data" [tableColumns]="displayedColumns" [isFilterable]="true"
                    [isPageable]="true" [paginationSizes]="pageSizeOptions" [defaultPageSize]="10"
                    [totalRows]="totalRows" [pageIndex]="currentPage" [rowActionIcon]="'action'"
                    (sort)="SortFilter($event)" (editAction)="editAction($event)" (downloadParticipantData)="downloadParticipantData($event)" (pageChanged)="pageChanged($event)"
                    (searchFilter)="searchFilter($event)" [is_data_curation_access]="is_data_curation_access" [pageTitle]="title" [loading]="loading" [isApproved]="isApproved" [currentMenuPermission]="currentMenuPermission" (makeUnapproved)="makeUnapproved($event)">
                </app-table>
            </div>
        </div>
    </div>
</section>
