import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteDayWiseService } from 'src/app/service/site-day-wise.service';
import { UserService } from 'src/app/service/user.service';
import { CommonService } from 'src/app/service/common.service';
import { DiagnosticLabsService } from 'src/app/service/diagnosticLabs.service';
import { SiteService } from 'src/app/service/site.service';
import { AppConstants } from 'src/app/app.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from 'src/app/service/menu.service';

@Component({
  selector: 'app-bulk-site-assign',
  templateUrl: './bulk-site-assign.component.html',
  styleUrls: ['./bulk-site-assign.component.scss']
})
export class BulkSiteAssignComponent implements OnInit {

  public bulkAssignSiteForm!: FormGroup;
  public supervisors: any[] = [];
  public coordinators: any[] = [];
  public phlebotomists: any[] = [];
  public counsellors: any[] = [];
  public diagnosticLabs: any[] = [];
  public siteList: any[] = [];
  public currentMenuPermission: any;

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    private siteDayWiseService: SiteDayWiseService,
    public userService: UserService,
    public commonService: CommonService,
    private diagnosticLabsService: DiagnosticLabsService,
    private siteService: SiteService,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    this.menuService.currentMenu.subscribe((currentMenu) => {
      this.currentMenuPermission = currentMenu;
    });
    this.getUsersByRoles("supervisor", "supervisors");
    this.getUsersByRoles("coordinator", "coordinators");
    this.getUsersByRoles("counsellors", "counsellors");
    this.getUsersByRoles("phlebotomist", "phlebotomists");
    this.getDiagnosticLabs();
    this.getSite();

    this.bulkAssignSiteForm = this.fb.group({
      'siteCode': [null, [Validators.required]],
      'startDate': [null, [Validators.required]],
      'endDate': [null, [Validators.required]],
      'campTimingFrom': [null, [Validators.required]],
      'campTimingTo': [null, [Validators.required]],
      'campSupervisor': [null, ""],
      'campCoordinator': [null, [Validators.required]],
      'campCounsellor': [null, [Validators.required]],
      'campPhlebotomist': [null, [Validators.required]],
      'diagnosticLabId': [null, [Validators.required]],
      'materialsPackedDate': [null, [Validators.required]]
    });
  }

  /**
   * get users by roles
   */
  getUsersByRoles(role: any, userListKey: any) {
    const formData: FormData = new FormData();
    formData.append("roles", role);
    this.userService.getUsersByRoles(formData).subscribe({
      next: (result: any) => {
        (this as any)[userListKey] = result.data;
      },
      error: (err) => {
        // console.log('getSiteAdmin err :: ', err);
      }
    });
  }

  /**
  * Get diagnostic labs List
  *
  * @returns object
  */
  getDiagnosticLabs() {
    this.diagnosticLabsService.getActiveList().subscribe({
      next: (result: any) => {
        this.diagnosticLabs = result.data;
      }
    });
  }

  /**
   * Get Site
   *
   * @author Dhaval Bera
   *
   * @returns object
   */
  getSite() {
    this.siteService.getAllList().subscribe((result: any) => {
      this.siteList = result.data;
    });
  }

  /**
     * submit form
     * @returns null
     */
  onSubmit(): void {

    if (this.bulkAssignSiteForm.invalid) {
      this.commonService.validateAllFormFields(this.bulkAssignSiteForm);
      return;
    }

    const formData: FormData = new FormData();

    const campSupervisor = !this.commonService.isNullOrUndefined(this.bulkAssignSiteForm.controls["campSupervisor"].value) ? this.bulkAssignSiteForm.controls["campSupervisor"].value : "";
    formData.append("site_id", this.bulkAssignSiteForm.controls["siteCode"].value);
    formData.append("camp_timing_from", this.bulkAssignSiteForm.controls["campTimingFrom"].value);
    formData.append("camp_timing_to", this.bulkAssignSiteForm.controls["campTimingTo"].value);
    formData.append("camp_supervisor", campSupervisor);
    formData.append("camp_coordinator", this.bulkAssignSiteForm.controls["campCoordinator"].value);
    formData.append("camp_counsellor", this.bulkAssignSiteForm.controls["campCounsellor"].value);
    formData.append("camp_phlebotomist", this.bulkAssignSiteForm.controls["campPhlebotomist"].value);
    formData.append("diagnostic_lab_id", this.bulkAssignSiteForm.controls["diagnosticLabId"].value);
    formData.append("date_of_materials_packed", this.bulkAssignSiteForm.controls["materialsPackedDate"].value);
    formData.append("startDate", this.bulkAssignSiteForm.controls["startDate"].value);
    formData.append("endDate", this.bulkAssignSiteForm.controls["endDate"].value);

    this.siteDayWiseService.bulkDaysAssign(formData).subscribe({
      next: (result: any) => {
        if (result.status === AppConstants.serverSuccessStatus) {
          this.commonService.showSuccessToast("Site day wise data added successfully");
          this.router.navigate([`/site/dashboard/${this.bulkAssignSiteForm.controls["siteCode"].value}`]);
        } else {
          this.commonService.showErrorToast(result.message);
        }
      },
      error: (err) => {
        this.commonService.showErrorToast("Something went wrong. Please contact to administrator.");
      }
    });
  }

  /**
    *
    * @param field
    */
  displayFieldCss(field1: string, field2 = '') {
    let field = field1;
    if (field2 != '') {
      field = field2
    }
    return {
      "has-error": this.commonService.isFieldValid(this.bulkAssignSiteForm, field),
      "has-feedback": this.commonService.isFieldValid(this.bulkAssignSiteForm, field),
    };
  }

}
