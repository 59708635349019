import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectService } from 'src/app/service/project.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';
import { isNullOrUndefined } from 'is-what';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})

export class ProjectFormComponent implements OnInit {

  public projectForm!: FormGroup;
  public title: any;
  public projectId: any;
  public isAddMode: boolean = true;
  public buttonText: string = "";
  public cancelLink: string = "/site/project";
  public userId: any = "";
  public loading: boolean = false;
  public projectMinLength: number = 3;
  public projectMaxLength: number = 255;
  public projectDetails: any = [];
  public projectSopsFiles: Blob[] = [];
  public projectSopsFile!: File;
  public noteFile!: File;
  public incExcNoteFile!: File;

  constructor(
    private projectService: ProjectService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    protected router: Router
  ) {
    this.projectId = this.route.snapshot.paramMap.get("id");
    if (this.projectId !== 0 && this.projectId !== null) {
      this.title = "Edit Project";
      this.buttonText = "Save";
    } else {
      this.title = "Add Project";
      this.buttonText = "Add";
      this.projectId = 0;
    }
  }

  ngOnInit(): void {

    if (this.projectId !== 0 && this.projectId !== null) {
      this.setEditData();
    }

    this.isAddMode = !this.projectId;

    this.userId = localStorage.getItem("userId");
    this.projectForm = this.formBuilder.group({
      'name': ['', [Validators.required, Validators.minLength(this.projectMinLength), Validators.maxLength(this.projectMaxLength)]],
      'projectSops': [null, []],
      'note': [null, []],
      'inclusionExclusionNote': [null, []],
    });
  }

  setEditData() {
    this.projectService.getRow(this.projectId)
      .subscribe({
        next: (result: any) => {
          if (result.status !== AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
          }
          this.projectDetails = result.data;
          setTimeout(() => {
            this.projectForm.patchValue({
              name: this.projectDetails.name,
            });
          }, 300);
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
  }

  /**
 * Project Sops File Validation
 *
 * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
 *
 * @returns object
 */
  selectProjectSopsFile(event: any) {
    this.projectSopsFiles = [];
    this.projectDetails.project_sops = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.projectSopsFile = <File>event.target.files[i];
      if ((this.projectSopsFile !== undefined) && (this.projectSopsFile.type !== 'application/pdf')) {
        this.projectSopsFiles = [];
        this.projectForm.get('projectSops')?.setErrors({ 'fileValidType': 'File should be .pdf type' });
      } else if ((this.projectSopsFile !== undefined) && ((this.projectSopsFile.size / 1024 / 1024) > 20)) {
        this.projectSopsFiles = [];
        this.projectForm.get('projectSops')?.setErrors({ 'fileValidSize': 'Files size limit exceeded. Max file size is 20 MB.' });
      } else {
        this.projectSopsFiles.push(event.target.files[i]);
      }
    }
  }

  /**
    * Project Note File Validatoin
    *
    * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
    *
    * @param event
    *
    * @returns Object
    */
  selectNoteFile(event: any) {
    this.noteFile = <File>event.target.files[0];
    if ((this.noteFile !== undefined) && (this.noteFile.type !== 'application/pdf')) {
      this.projectForm.get('note')?.setErrors({ 'fileValidType': 'File should be .pdf type' });
    } else if ((this.noteFile !== undefined) && ((this.noteFile.size / 1024 / 1024) > 20)) {
      this.projectForm.get('note')?.setErrors({ 'fileValidSize': 'Files size limit exceeded. Max file size is 20 MB.' });
    } else {
      this.projectForm.get('note')?.setErrors(null);
    }
  }

  /**
   * Inclusion Exclusion Note File Validatoin
   *
   * @author Farhan Shaikh <farhan.shaikh@anuva.bio>
   *
   * @param event
   *
   * @returns Object
   */
  selectInclusionExclusionNoteFile(event: any) {
    this.incExcNoteFile = <File>event.target.files[0];
    if ((this.incExcNoteFile !== undefined) && (this.incExcNoteFile.type !== 'application/pdf')) {
      this.projectForm.get('inclusionExclusionNote')?.setErrors({ 'fileValidType': 'File should be .pdf type' });
    } else if ((this.incExcNoteFile !== undefined) && ((this.incExcNoteFile.size / 1024 / 1024) > 20)) {
      this.projectForm.get('inclusionExclusionNote')?.setErrors({ 'fileValidSize': 'Files size limit exceeded. Max file size is 20 MB.' });
    } else {
      this.projectForm.get('inclusionExclusionNote')?.setErrors(null);
    }

  }

  getProjectError() {
    if (this.projectForm.controls['name'].errors!['required']) {
      return 'Name is required.';
    }
    if (this.projectForm.controls['name'].errors!['minlength']) {
      return 'Min length is ' + this.projectMinLength + ' characters.';
    }
    if (this.projectForm.controls['name'].errors!['maxlength']) {
      return 'Max length is ' + this.projectMaxLength + ' characters.';
    }
    if (this.projectForm.controls['name'].errors!['unique']) {
      return this.projectForm.get("name")!.errors!['unique'];
    }
    return '';
  }

  getPDFFileError(formControlName: any){
    const formControl = this.projectForm.controls[formControlName];
    if (formControl.errors!['fileValidType']) {
      return formControl.errors!['fileValidType'];
    }
    else if (formControl.errors!['fileValidSize']) {
      return formControl.errors!['fileValidSize'];
    }
    return '';
  }
  
    /**
     * submit form
     * @returns null
     */
    onSubmit(): void {  
      if (this.projectForm.invalid) {
        this.commonService.validateAllFormFields(this.projectForm);
        this.commonService.showErrorToast(
          'OOPS! Please enter correct values'
        );
        return;
      }
      const formData: FormData = new FormData();
      formData.append('name', this.projectForm.controls['name'].value);
      
      if (!isNullOrUndefined(this.projectSopsFile)) {
        for (let j = 0; j < this.projectSopsFiles.length; j++) {
          formData.append('project_sops[]', this.projectSopsFiles[j]);
        }
      }
  
      if (!isNullOrUndefined(this.noteFile)) {
        formData.append('note', this.noteFile, this.noteFile.name);
      }
  
      if (!isNullOrUndefined(this.incExcNoteFile)) {
        formData.append('inclusion_exclusion_note', this.incExcNoteFile, this.incExcNoteFile.name);
      }

    this.loading = true;
    if (this.isAddMode) {
      this.createProject(formData);
    } else {
      this.updateProject(formData);
    }
  }

  /**
   * call Create Project api
   * @returns null
   */
  private createProject(formData: any): void {
    this.projectService.store(formData)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          } else {
            this.commonService.showErrorToast(
              result.message
            );
            if(result.data){
              this.validatePdfFilesFromApiResponse(result.data);
            }
          }
          this.loading = false;
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
          this.loading = false;
        }
      })
  }

  /**
   * call Update Project api
   * @returns null
   */
  private updateProject(formData: any): void {
    formData.append('updated_by', this.userId);
    this.projectService.update(formData, this.projectId)
      .subscribe({
        next: (result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          } else {
            this.commonService.showErrorToast(
              result.message
            );
            if(result.data){
              this.validatePdfFilesFromApiResponse(result.data);
            }

          }
          this.loading = false;
        },
        error: (err: any) => {
          console.log(err);
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
          this.loading = false;
        }
      })
    }

    // validate files from response
    validatePdfFilesFromApiResponse(data: any){
      let fileArray: any = [];
          fileArray = {
            'project_sops':'projectSops',
            'note':'note',
            'inclusion_exclusion_note':'inclusionExclusionNote'
          };
          
          // convert result data object to array
          const mappedResultData = Object.keys(data).map(key => ({file_name: key, error_msg: data[key]}));
          
          // check file validation exists
          mappedResultData.forEach((value: any) => {
              this.setInvalidFileError(fileArray[value['file_name']]);
          });
    }

    // set invalid file error
    setInvalidFileError(fileElement: string){
      this.projectForm.get(fileElement)!
        .setErrors({ fileValidType: "File should be .pdf type" });
    }
  
    /**
     * Check project field is existed or not
     * @returns null
     */
     checkUniqueProject(event: any) {
      const projectFieldValue = (event.target as HTMLInputElement).value;
      if (projectFieldValue.length > this.projectMaxLength) {
        return false;
      }
  
        if (projectFieldValue.length < this.projectMinLength) {
          return false;
        }
    
      if (projectFieldValue !== "") {
          this.projectService
          .checkUniqueProject(
            projectFieldValue,
            this.projectId
          )
          .subscribe({
            next:(result: any) => {
              if (result.status === 1 || result.status === 200) {
                this.projectForm.get("name")!.setErrors(null);
              } else {
                this.projectForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
            }
          },
          error: (error: any) => {
            if (error.status === 400) {
              this.projectForm
                .get("name")!
                .setErrors({ unique: "Name already exists" });
            } else {
              console.log(
                "Something went wrong. Please contact to administrator."
              );
            }
          }
        });
    }
    return null;
  }
}


