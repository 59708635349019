import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class UniqueIdentityService extends CrudService {

  constructor(public override http: HttpClient) {
    super('unique-identity', http);
  }

  /**
   * Check unique identity field is already exists
   * @returns object
   */
  checkUniqueIdentity(uniqueIdentityName: string, id: number) {
    return this.http.get(
      `${this.baseUrl}/check-unique-identity/${uniqueIdentityName}/${id}`
    );
  }
}
