<div class="page-title-box">
    <h1>Manage Lab Data</h1>
    <ul class="bradcrumb">
        <li>Manage Lab Data</li>
        <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
    </ul>
</div>

<section class="container-fluid">
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card-wrape">
                <div class="card-head">
                    <h4 class="left-text">Lab Data Listing </h4>
                    <div class="right-control">
                        <div class="table-search-wrape side-info">
                            <span class="icon"></span>
                            <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control"
                                type="text">
                            <div class="info-pad popup-over">
                                <button class="info-icon"></button>
                                <div class="info-section popupcontent">
                                    New PID, First Name, Last Name, Site Code
                                </div>
                            </div>
                        </div>
                        <button class="primary btn" *ngIf="currentMenuPermission?.create == true && currentMenuPermission?.edit == true" mat-raised-button color="secoundary"
                            routerLink="/lab-data/upload">Upload Lab Data</button>
                    </div>
                </div>
                <div class="card-body table-section">
                    <div class="table-wrape">
                        <mat-table [dataSource]="dataSource" (matSortChange)="SortFilter($event)" matSort>
                            <ng-container matColumnDef="id">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> Lab Data ID
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="site_id" fxFlex="100px"> {{element.id}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="new_pid">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="user-name"> New PID
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" fxFlex="100px" class="user-name">
                                    {{element.new_pid}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="first_name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                                <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.first_name}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="last_name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                                <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.last_name}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="site_code">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Site Code </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.site_code}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                                    <div class="icons-wrape">
                                        <a routerLink='/lab-data/view/{{element.id}}' class="theme-icon view-icon"
                                            title="View Lab Data"></a>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            <div *matNoDataRow>No records found</div>
                        </mat-table>

                        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>