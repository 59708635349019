<div class="page-title-box">
  <h1>Manage Users</h1>
  <ul class="bradcrumb">
    <li>Manage User</li>
    <li><a routerLink="/dashboard" title="Dashboard" class="dashboard">Dashboard</a></li>
  </ul>
</div>

<section class="container-fluid">
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <div class="card-wrape">
        <mat-spinner *ngIf="loading"></mat-spinner>

        <div class="card-head">
          <h4 class="left-text"> Manage Users</h4>
          <div class="right-control">
            <div class="table-search-wrape side-info">
              <span class="icon"></span>
              <input (keyup)="searchFilter($event)" placeholder="Search" #input class="form-control" type="text">
              <div class="info-pad popup-over">
                <button class="info-icon"></button>
                <div class="info-section popupcontent">
                  ID, Name, Email Address, Gender, Created Date, Status, Created By
                </div>
              </div>
            </div>
            <button *ngIf="currentMenuPermission.create == true" mat-raised-button color="secoundary" class="primary btn" mat-raised-button
              routerLink="/manage-user/add" title="Add User">Add User</button>
          </div>
        </div>
        <div class="card-body table-section">
          <div class="table-wrape">
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="SortFilter($event)">
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="site_id"> ID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="site_id" fxFlex="80px"> {{element.id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="user_full_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="user-name"> Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="user-name"> {{element.user_full_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </mat-header-cell>
                <mat-cell *matCellDef="let element" title="{{element.email}}"> <span>{{element.email}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="gender">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.gender | titlecase}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_date_formatted">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_date_formatted}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" class="{{element.status_name}}"> {{element.status_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_by_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Created By </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_by_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="action"> Action </mat-header-cell>
                <mat-cell *matCellDef="let element" class="action" fxFlex="140px">
                  <div class="icons-wrape">
                    <a routerLink='/manage-user/view/{{element.id}}' *ngIf="currentMenuPermission.view == true"
                      class="theme-icon blue-bg-btn view-icon" title="View User"></a>
                    <a routerLink="/manage-user/edit/{{element.id}}" *ngIf="currentMenuPermission.edit == true"
                      class="theme-icon edit-icon" title="Edit User"></a>
                    <a (click)="resendLink(element.id)" *ngIf="!element.status && currentMenuPermission.edit == true"
                      class="theme-icon sky-bg-btn link-icon" title="Resend Link"></a>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
              <div *matNoDataRow>{{matNoDataRowTitle}}</div>
            </mat-table>
            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page"
              showFirstLastButtons>
            </mat-paginator>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>