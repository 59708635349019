<div class="login-wrape">
	<div class="card-wrape">
		<div class="card-head">
			<h4 class="left-text">Create Account</h4>
		</div>
		<form [formGroup]="userForm" #form="ngForm" class="edit-mode" (ngSubmit)="onSubmit(userForm.value)">
			<div class="card-body">
				<div class="row">
					<div class="col-xl-6 col-md-6">
						<div class="input-wrape"
							[ngClass]="{ 'has-error': userForm.controls['firstName'].invalid && (userForm.controls['firstName'].dirty || userForm.controls['firstName'].touched)}">
							<mat-label><i>*</i> First Name</mat-label>
							<mat-form-field appearance="outline">
								<input matInput formControlName="firstName" #input placeholder="Enter first name"
									type="text">
							</mat-form-field>
							<span
								*ngIf="userForm.controls['firstName'].invalid && (userForm.controls['firstName'].dirty || userForm.controls['firstName'].touched)">
								<mat-error *ngIf="userForm.controls['firstName'].errors?.['required']">
									Please Enter First name
								</mat-error>
							</span>
						</div>
						<div class="input-wrape"
							[ngClass]="{ 'has-error': userForm.controls['middleName'].invalid && (userForm.controls['middleName'].dirty || userForm.controls['middleName'].touched)}">
							<mat-label>Middle Name</mat-label>
							<mat-form-field appearance="outline">
								<input matInput formControlName="middleName" #input placeholder="Enter middle name"
									type="text">
							</mat-form-field>
							<span
								*ngIf="userForm.controls['middleName'].invalid && (userForm.controls['middleName'].dirty || userForm.controls['middleName'].touched)">
								<mat-error *ngIf="userForm.controls['middleName'].errors?.['required']">
									Please Enter Middle name
								</mat-error>
							</span>
						</div>
						<div class="input-wrape"
							[ngClass]="{ 'has-error': userForm.controls['lastName'].invalid && (userForm.controls['lastName'].dirty || userForm.controls['lastName'].touched)}">
							<mat-label><i>*</i> Last Name</mat-label>
							<mat-form-field appearance="outline">
								<input matInput formControlName="lastName" #input placeholder="Enter last name" f
									type="text">
							</mat-form-field>
							<span
								*ngIf="userForm.controls['lastName'].invalid && (userForm.controls['lastName'].dirty || userForm.controls['lastName'].touched)">
								<mat-error *ngIf="userForm.controls['lastName'].errors?.['required']">
									Please Enter Last name
								</mat-error>
							</span>
						</div>
						<div class="input-wrape calendar-wrape"
							[ngClass]="{ 'has-error': userForm.controls['dob'].invalid && (userForm.controls['dob'].dirty || userForm.controls['dob'].touched)}">
							<mat-label>Date of Birth</mat-label>
							<mat-form-field class="example-full-width" appearance="outline">
								<input matInput formControlName="dob" [matDatepicker]="picker"
									placeholder="Select date of birth" (click)="picker.open()" [max]="today">
								<!-- <mat-hint>MMM/DD/YYYY</mat-hint> -->
								<mat-datepicker-toggle matSuffix [for]="picker">
									<mat-icon matDatepickerToggleIcon class="calendar-icon"></mat-icon>
								</mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>
							<span
								*ngIf="userForm.controls['dob'].invalid && (userForm.controls['dob'].dirty || userForm.controls['dob'].touched)">
								<mat-error *ngIf="userForm.controls['dob'].errors?.['required']">
									Please Enter Date of Birth
								</mat-error>
							</span>
						</div>
						<div class="input-wrape"
							[ngClass]="{ 'has-error': userForm.controls['gender'].invalid && (userForm.controls['gender'].dirty || userForm.controls['gender'].touched)}">
							<mat-label><i>*</i> Gender</mat-label>
							<mat-radio-group aria-label="Select an option" formControlName="gender">
								<mat-radio-button [value]="option.value" [checked]="option.checked"
									*ngFor="let option of genderOptions" (change)="genderChange($event)">{{
									option.name | titlecase }}</mat-radio-button>
							</mat-radio-group>
							<span
								*ngIf="userForm.controls['gender'].invalid && (userForm.controls['gender'].dirty || userForm.controls['gender'].touched)">
								<mat-error *ngIf="userForm.controls['gender'].errors?.['required']">
									Please Select gender
								</mat-error>
							</span>
						</div>
					</div>
					<div class="col-xl-6 col-md-6">
						<div class="contact-no-pad">
							<div class="input-wrape code"
								[ngClass]="{ 'has-error': userForm.controls['phoneNumberCode'].invalid && (userForm.controls['phoneNumberCode'].dirty || userForm.controls['phoneNumberCode'].touched)}">
								<mat-label>Country Code</mat-label>
								<!-- <mat-form-field appearance="outline">
									<mat-select [(value)]="selectedValue" placeholder="Select option"
										formControlName="phoneNumberCode">
										<mat-option *ngFor="let item of countryCodes" [value]="item.id">
											<span class="c-code">{{ item.phone_code }}</span>
											{{item.name}}
										</mat-option>
									</mat-select>
								</mat-form-field> -->

								<div class="control-pad">
									<ng-select [items]="countryCodes" bindLabel="name" bindValue="id"
										placeholder="Country Code" formControlName="phoneNumberCode">
										<ng-template ng-label-tmp let-item="item">
											{{item.phone_code}}
										</ng-template>
										<ng-template ng-option-tmp let-item="item">
											<span class="c-code">{{ item.phone_code }}</span>
											{{item.name}}
										</ng-template>
									</ng-select>
								</div>
								<span
									*ngIf="userForm.controls['phoneNumberCode'].invalid && (userForm.controls['phoneNumberCode'].dirty || userForm.controls['phoneNumberCode'].touched)">
									<mat-error *ngIf="userForm.controls['phoneNumberCode'].errors?.['required']">
										Please Select Phone Number Code
									</mat-error>
								</span>
							</div>
							<div class="input-wrape number"
								[ngClass]="{ 'has-error': userForm.controls['phoneNumber'].invalid && (userForm.controls['phoneNumber'].dirty || userForm.controls['phoneNumber'].touched)}">
								<mat-label>Phone Number</mat-label>
								<mat-form-field appearance="outline">
									<input matInput formControlName="phoneNumber" #input
										placeholder="Enter phone number" type="number">
								</mat-form-field>
								<span
									*ngIf="userForm.controls['phoneNumber'].invalid && (userForm.controls['phoneNumber'].dirty || userForm.controls['phoneNumber'].touched)">
									<mat-error *ngIf="userForm.controls['phoneNumber'].errors?.['required']">
										Please Select Phone Number
									</mat-error>
								</span>
							</div>
						</div>
						<div class="input-wrape">
							<mat-label>Email ID</mat-label>
							<mat-form-field appearance="outline" class="disabled">
								<input matInput formControlName="email" #input placeholder="Enter email ID" type="text">
							</mat-form-field>
						</div>
						<div class="input-wrape">
							<mat-label>Role Name</mat-label>
							<mat-form-field appearance="outline" class="disabled">
								<input matInput value="{{roleList}}" #input placeholder="Enter role" type="text">
							</mat-form-field>
						</div>
						<div class="input-wrape"
							[ngClass]="{ 'has-error': userForm.controls['mpin'].invalid && (userForm.controls['mpin'].dirty || userForm.controls['mpin'].touched)}">
							<mat-label><i>*</i>M-PIN</mat-label>
							<mat-form-field appearance="outline" >
								<input matInput #input placeholder="Enter M-PIN" minlength="4" maxlength="4"
									type="password" formControlName="mpin" required>
							</mat-form-field>
							<span
								*ngIf="userForm.controls['mpin'].invalid && (userForm.controls['mpin'].dirty || userForm.controls['mpin'].touched)">
								<mat-error *ngIf="userForm.controls['mpin'].errors?.['required']">
									M-PIN is required
								</mat-error>
								<mat-error *ngIf="userForm.controls['mpin'].errors?.['minlength']">
									M-PIN must be 4 digits only
								</mat-error>
								<mat-error *ngIf="userForm.controls['mpin'].errors?.['maxlength']">
									M-PIN must be 4 digits only
								</mat-error>
							</span>
						</div>
						<div class="input-wrape"
							[ngClass]="{ 'has-error': userForm.controls['retypeMpin'].invalid && (userForm.controls['retypeMpin'].dirty || userForm.controls['retypeMpin'].touched)}">
							<mat-label><i>*</i>Re-type M-PIN</mat-label>
							<mat-form-field appearance="outline" >
								<input matInput #input placeholder="Enter Re-type M-PIN" type="password"
									formControlName="retypeMpin" required minlength="4" maxlength="4">
							</mat-form-field>
							<span
								*ngIf="userForm.controls['retypeMpin'].invalid && (userForm.controls['retypeMpin'].dirty || userForm.controls['retypeMpin'].touched)">
								<mat-error *ngIf="userForm.controls['retypeMpin'].errors?.['required']">
									Re-type M-PIN is required
								</mat-error>
								<mat-error *ngIf="userForm.controls['retypeMpin'].errors?.['ConfirmPassword']">
									M-PIN and Re-type M-PIN didn't match.
								</mat-error>
								<mat-error *ngIf="userForm.controls['retypeMpin'].errors?.['minlength']">
									Re-type M-PIN must be 4 digits only
								</mat-error>
								<mat-error *ngIf="userForm.controls['retypeMpin'].errors?.['maxlength']">
									Re-type M-PIN must be 4 digits only
								</mat-error>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<button mat-raised-button class="btn" type="submit" color="secoundary" title="Create Account">Create
					Account</button>
			</div>
		</form>
	</div>
	<div class="login-bg"><img src="../../../../../assets/images/login-bg.svg"></div>
</div>