import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  errorData: {} | undefined;
  public menuList = new BehaviorSubject<any[]>([]);
  public userInfo = new BehaviorSubject<any[]>([]);
  public currentMenu = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {}

  /**
   * @author Dhaval Bera
   */
  getMenu() {
    return this.http.get<any>(`${environment.baseURL}menu/generate-menu`);
  }

  /**
   * It check menu permissions
   * @param formData
   * @author Dhaval Bera
   */
  getMenuInfo(formData: any) {
    return this.http.post(`${environment.baseURL}menu/info`, formData);
  }

  private handleError(error: HttpErrorResponse) {
    // console.log(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops!',
      errorDesc: 'Something bad happened. Please try again later.',
    };
    return throwError(() => this.errorData);
  }
}
