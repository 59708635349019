import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class ParticipantDocumentsService extends CrudService {
  constructor(public override http: HttpClient) {
    super('participant-documents', http);
  }

  /**
   * Get all records
   * @author Dhaval Bera
   * @returns object
   */
  getAllDocuments(pageNo: any, sortField: string, sortOrder: string, globalFilter: string, rows: any, filterData: any) {
    let params: FormData = new FormData();
    if (!Number.isNaN(pageNo) && pageNo != null) {
      params.append("page", pageNo);
    }

    if (sortField !== undefined) {
      params.append("sortField", sortField);
      params.append("sortOrder", sortOrder);
    }

    if (globalFilter !== null && globalFilter !== undefined) {
      params.append("filters[global]", globalFilter);
    }
    if (filterData !== null && filterData !== undefined) {
      params.append("mapped_filter", filterData);
    }

    if (rows !== 0) {
      params.append("perPage", rows);
    }
    return this.http.post(this.baseUrl + '/list', params);
  }

  /**
   * Import Data
   *
   * @returns object
   */
  importParticipantDocuments(formData: any) {
    return this.http.post(this.baseUrl + "/import", formData);
  }

  mapDocumentWithParticipant(formData: any, id: any) {
    return this.http.post(this.baseUrl + '/' + id, formData);
  }
}
