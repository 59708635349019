<div class="login-wrape">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="progress-overlay"></div>
    <div class="inner">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit(resetPasswordForm.value)" #form="ngForm"
            class="form-element" autocomplete="off">
            <a [routerLink]="['/dashboard']" class="logo">
                <img src="../../../../../assets/images/logo-large.png" alt="ggcPHENO">
            </a>
            <h2>Reset M-PIN</h2>
            <h4>Continue to PHENO Admin</h4>

            <div class="form-container" *ngIf="inValidToken">
                <!-- inValidToken -->
                <p>
                    <mat-error class="text-danger">{{ errorMsg }}</mat-error>
                </p>
            </div>
            <div class="form-container" *ngIf="thankYou">
                <p class="login-box-text">
                    <span class="text-success">{{ successMsg }}</span>
                </p>
            </div>
            <div *ngIf="errors && errors.length > 0">
                <mat-error *ngFor="let error of errors">{{ error }}</mat-error>
            </div>
            <div class="input-wrape"
                [ngClass]="{ 'has-error': resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched)}">
                <mat-label>M-PIN</mat-label>
                <mat-form-field appearance="outline" class="pass-icon">
                    <input matInput #input placeholder="Enter M-PIN" minlength="4" maxlength="4" type="password" formControlName="password"
                        required>
                </mat-form-field>
                <span
                    *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched)">
                    <mat-error *ngIf="resetPasswordForm.controls['password'].errors?.['required']">
                        M-PIN is required
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].errors?.['minlength']">
                        M-PIN must be 4 digits only
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['password'].errors?.['maxlength']">
                        M-PIN must be 4 digits only
                    </mat-error>
                </span>
            </div>
            <div class="input-wrape"
                [ngClass]="{ 'has-error': resetPasswordForm.controls['retypePassword'].invalid && (resetPasswordForm.controls['retypePassword'].dirty || resetPasswordForm.controls['retypePassword'].touched)}">
                <mat-label>Re-type M-PIN</mat-label>
                <mat-form-field appearance="outline" class="pass-icon">
                    <input matInput #input placeholder="Enter Re-type M-PIN" type="password"
                        formControlName="retypePassword" required minlength="4" maxlength="4">
                </mat-form-field>
                <span
                    *ngIf="resetPasswordForm.controls['retypePassword'].invalid && (resetPasswordForm.controls['retypePassword'].dirty || resetPasswordForm.controls['retypePassword'].touched)">
                    <mat-error *ngIf="resetPasswordForm.controls['retypePassword'].errors?.['required']">
                        Re-type M-PIN is required
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['retypePassword'].errors?.['ConfirmPassword']">
                        M-PIN and Re-type M-PIN didn't match.
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['retypePassword'].errors?.['minlength']">
                        Re-type M-PIN must be 4 digits only
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls['retypePassword'].errors?.['maxlength']">
                        Re-type M-PIN must be 4 digits only
                    </mat-error>
                </span>
            </div>
            <button mat-raised-button type="submit" class="login-button btn" color="secoundary"
                title="Submit">Submit</button>
        </form>

        <div class="form-container" *ngIf="inValidToken || thankYou">
            <a routerLink="/login" class="other-link">Back to Login</a>
        </div>
    </div>

    <div class="login-bg"><img src="../../../../../assets/images/login-bg.svg"></div>
</div>