<div class="card-wrape m-b-0">
    <div class="card-head with-dropdown">
        <h4 class="left-text">Population Wise Participant Count</h4>
        <div class="right-control">
            <div class="input-wrape">
                <div class="control-pad">
                    <ng-select [items]="percentageOrAbsoluteCounts" [clearable]="false" [dropdownPosition]="'bottom'"
                        [searchable]="false" placeholder="Please Select" bindLabel="value" bindValue="id"
                        [(ngModel)]="PopulationWisePercentOrCount" (change)="
                    showRecordsInPercentOrAbsoluteCount('population_wise', $event)
                  ">
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <mat-spinner *ngIf="populationChartLoader"></mat-spinner>
        <div class="progress-overlay"></div>
        <div *ngIf="totalPopulationChartCounts == 0 && !populationChartLoader;
    else foundPopulationChartData">
            <div class="no-data-found">
                <div class="no-data-container">
                    <div class="no-data-icon"></div>
                    <div class="no-data-text">NO DATA FOUND</div>
                </div>
            </div>
        </div>
        <ng-template #foundPopulationChartData>
            <div echarts [options]="PopulationChartOptions" class="echart"  style="height: 490px;"></div>
            <div class="total-count-piechart" *ngIf="!populationChartLoader;">
                {{ totalPopulationChartCounts }}<span>Total</span>
            </div>
        </ng-template>
    </div>
</div>